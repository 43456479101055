<template>
  <div>
  <ElementyWyboruWST />
  </div>
</template>

<script>
import ElementyWyboruWST from '../komponenty/strona-glowna-wst/Elementy_wyboru_WST.vue'


export default {
  name: 'StronaGlownaWST',
  components: {
    ElementyWyboruWST

  }
}
</script>
