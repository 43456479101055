<template >
  <div>
  <TabelaCelePrzeglady />
  </div>
</template>

<script>
import TabelaCelePrzeglady from '../../komponenty/strona-tabela-komponenty/SYS_cele/tabela_cele_przeglady.vue'


export default {
  name: 'StronaTabelaCelePrzeglady',
  components: {
    TabelaCelePrzeglady,
    
  }

}
</script>

