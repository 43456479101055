<template>
  <div>
  <TabelaBudynkiPrzegladWylacznik/>
  </div>
</template>

<script>
import TabelaBudynkiPrzegladWylacznik from '../../komponenty/strona-tabela-komponenty/WST_budynki/tabela_budynki_przeglady_wylacznik.vue'


export default {
  name: 'StronaTabelaBudynkiPrzegladWylacznik',
  components: {
    TabelaBudynkiPrzegladWylacznik,
  }

}
</script>
