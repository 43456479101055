<template>
  <div class="tabela_Zakupy">
    <div class="laczacy">
      <div class="menu_strony">
        <span>
          <button
            class="button"
            :disabled="!autoryzacja.czyMozeDodawac"
            @click="
              pokazDialog = true;
              typAktywnegoFormularza = 'formularz_dodawanie_dostawcy';
              obiektKonfiguracyjny = {
                Id: null,
                akcja: 'dodawanie',
              };
            "
          >
            DODAWANIE NOWEGO DOSTAWCY
          </button>
          <md-tooltip class="podpowiedz" md-direction="top">{{
            !autoryzacja.czyMozeDodawac
              ? "BRAK UPRAWNIEŃ"
              : "DODAWANIE NOWEGO DOSTAWCY"
          }}</md-tooltip>
        </span>

        <span>
          <button
            class="button"
            :disabled="!autoryzacja.czyMozeDodawac"
            @click="
              pokazDialog = true;
              typAktywnegoFormularza =
                'formularz_dodawanie_przegladu_dostawcow';
              obiektKonfiguracyjny = {
                Id: null,
                akcja: 'dodawanie',
              };
            "
          >
            DODAWANIE PRZEGL.WYKAZU KWAL.DOSTAWCÓW
          </button>
          <md-tooltip class="podpowiedz" md-direction="top">{{
            !autoryzacja.czyMozeDodawac
              ? "BRAK UPRAWNIEŃ"
              : "DODAWANIE PRZEGLĄDÓW WYKAZU KWALIFIKOWANYCH DOSTAWCÓW"
          }}</md-tooltip>
        </span>

        <span>
          <button
            class="button"
            @click="$router.push(`../kwalifikowanie_dostawcy_przeglad`)"
          >
            WYKAZ PRZEGLĄDÓW DOSTAWCÓW
          </button>
        </span>
      </div>

      <div class="menu_strony1">
        <b>
          <b-icon-printer-fill
            class="ikony"
            font-scale="2.1"
            color="white"
            @click="generujPdf"
          >
          </b-icon-printer-fill>
          <md-tooltip class="podpowiedz" md-direction="top"
            >GENERUJ PDF / DRUKUJ</md-tooltip
          >
        </b>

        <b>
          <b-icon-info-circle-fill
            class="ikony"
            font-scale="2.1"
            color="white"
            @click="pokazPDF_INFO"
          >
          </b-icon-info-circle-fill>
          <md-tooltip class="podpowiedz" md-direction="top">INFO</md-tooltip>
        </b>
      </div>
    </div>

    <modalSO
      v-if="pokazDialog"
      :rodzajFormularza="typAktywnegoFormularza"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />

    <md-table
      md-height="45vh"
      @md-selected="wyborElementu"
      v-model="searched"
      md-sort="Nazwa_maszyny"
      md-sort-order="asc"
      md-model-id="Id_maszyny"
      md-card
      md-fixed-header
    >
      <md-table-toolbar>
        <div class="d-flex flex-column w-100">
          <div class="row">
            <div class="col-4"></div>
            <div class="col-4">
              <h1 class="md-title">WYKAZ KWALIFIKOWANYCH DOSTAWCÓW</h1>
            </div>

            <div class="col-4">
              <md-field md-clearable class="ml-auto mr-0">
                <md-input
                  placeholder="Szukaj..."
                  v-model="search"
                  @input="searchOnTable"
                />
              </md-field>
            </div>
          </div>

          <div class="PasekWyboru" v-if="wybranyElementTabeli">
            <div
              style="text-align: right; margin-top: 0.3vh; margin-bottom: 0.3vh"
            >
              <b>
                <b-button
                  :disabled="!autoryzacja.czyMozeUsuwac"
                  @click="usuwanieElementu(wybranyElementTabeli.Id)"
                >
                  <b-icon-trash class="ikony" font-scale="1.8" color="white">
                  </b-icon-trash
                ></b-button>
                <md-tooltip class="podpowiedz" md-direction="top">{{
                  autoryzacja.czyMozeUsuwac
                    ? "USUWANIE ELEMENTU"
                    : "BRAK UPRAWNIEŃ"
                }}</md-tooltip>
              </b>
              <b>
                <b-button
                  :disabled="!autoryzacja.czyMozeEdytowac"
                  @click="
                    pokazDialog = true;
                    typAktywnegoFormularza = 'formularz_dodawanie_dostawcy';
                    obiektKonfiguracyjny = {
                      Id: wybranyElementTabeli.Id,
                      akcja: 'edycja',
                    };
                  "
                >
                  <b-icon-pencil-square
                    class="ikony"
                    font-scale="1.80"
                    color="white"
                  >
                  </b-icon-pencil-square>
                             </b-button>
                <md-tooltip class="podpowiedz" md-direction="top">{{
                  autoryzacja.czyMozeUsuwac
                    ? "EDYCJA ELEMENTU"
                    : "BRAK UPRAWNIEŃ"
                }}</md-tooltip>
              </b>

              <b><b-button>
                <b-icon-arrow-down-right-square
                  class="ikony"
                  font-scale="1.80"
                  color="white"
                  @click="
                    $router.push(`zakupy/${wybranyElementTabeli.Id}`)
                  "
                >
                </b-icon-arrow-down-right-square></b-button>
                <md-tooltip class="podpowiedz" md-direction="top"
                  >WYKAZ ZAKUPÓW DLA DOSTAWCY</md-tooltip
                >
              </b>
           
             
            </div>
          </div>
        </div>
      </md-table-toolbar>

      <md-table-empty-state
        v-if="czyPobranoDane"
        md-label="Nie posiadasz wpisów w wykazie kwalifikowanych dostawców"
      >
        <md-button
          class="md-primary md-raised"
          :disabled="!autoryzacja.czyMozeDodawac"
          @click="
            pokazDialog = true;
            typAktywnegoFormularza = 'formularz_dodawanie_dostawcy';
            obiektKonfiguracyjny = {
              Id: null,
              akcja: 'dodawanie',
            };
          "
          >Dodawanie nowego dostawcy</md-button
        >
        <md-tooltip class="podpowiedz" md-direction="top">{{
          !autoryzacja.czyMozeDodawac
            ? "BRAK UPRAWNIEŃ"
            : "DODAWANIE NOWEGO DOSTAWCY"
        }}</md-tooltip>
      </md-table-empty-state>

      <md-table-row
        slot="md-table-row"
        slot-scope="{ item }"
        md-selectable="single"
        :key="item.id"
      >
        <md-table-cell md-label="Lp">
          {{ item.Lp1 }}
        </md-table-cell>

        <md-table-cell md-label="Nazwa firmy" md-sort-by="Nazwa_firmy">
          {{ item.Nazwa_firmy }}
        </md-table-cell>

        <md-table-cell md-label="Rodzaj dostawy" md-sort-by="Rodzaj_dostaw">
          {{ item.Rodzaj_dostaw }}
        </md-table-cell>

        <md-table-cell md-label="Dostawca" md-sort-by="Dostawca">
          {{ item.Dostawca }}
        </md-table-cell>

        <md-table-cell md-label="W1" md-sort-by="W1">
          {{ item.W1 }}
        </md-table-cell>

        <md-table-cell md-label="W2" md-sort-by="W2">
          {{ item.W2 }}
        </md-table-cell>

        <md-table-cell md-label="W3" md-sort-by="W3">
          {{ item.W3 }}
        </md-table-cell>

        <md-table-cell md-label="W4" md-sort-by="W4">
          {{ item.W4 }}
        </md-table-cell>

        <md-table-cell md-label="W5" md-sort-by="W5">
          {{ item.W5 }}
        </md-table-cell>

        <md-table-cell md-label="W6" md-sort-by="W6">
          {{ item.W6 }}
        </md-table-cell>

        <md-table-cell md-label="Wynik" md-sort-by="Wynik">
          {{ item.Wynik }}
        </md-table-cell>

        <md-table-cell md-label="Kwalifikacja" md-sort-by="Kwalifikacja">
          {{ item.Znaczenie }}
        </md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
import modalSO from "../../strona-formularze/modalSO.vue";
import { api } from "../../../serwisy/api.serwis";
import { authorizationService } from "./../../../serwisy/authorization.servis";
import jsPDF from "jspdf";
import "jspdf-autotable";

const toLower = (text) => {
  return text.toString().toLowerCase();
};

const search = (items, term) => {
  if (term) {
    return items.filter((item) => {
      const elementWyszukiwany = toLower(term);
      const Nazwa_firmy = toLower(item.Nazwa_firmy);
      const Rodzaj_dostaw = toLower(item.Rodzaj_dostaw);
      const Dostawca = toLower(item.Dostawca);
      const Znaczenie = toLower(item.Znaczenie);
      return (
        Nazwa_firmy.includes(elementWyszukiwany) ||
        Rodzaj_dostaw.includes(elementWyszukiwany) ||
        Dostawca.includes(elementWyszukiwany) ||
        Znaczenie.includes(elementWyszukiwany)
      );
    });
  }

  return items;
};

export default {
  name: "tabela_wykaz_procesow",
  data: () => ({
    czyPobranoDane: false,
    search: null,
    searched: [],
    Procesy: [],
    pokazDialog: false,
    typAktywnegoFormularza: null,
    obiektKonfiguracyjny: null,
    wybranyElementTabeli: null,
    autoryzacja: {
      czyMozeDodawac: false,
      czyMozeUsuwac: false,
      czyMozeEdytowac: false,
      czyMozePrzegladac: false,
    },
  }),
  components: {
    modalSO,
  },
  mounted: function() {
    this.pobierzDaneDoTabeli();
    this.pobierzDaneAutoryzacyjne();
  },
  computed: {
    //informacja czy jest w trybie edycji
    czyJestWTrybieEdycji() {
      return this.$router.params.Id ? true : false;
    },
  },
  methods: {
    generujPdf() {
      const doc = new jsPDF("l", "mm", "a4");

      doc.setFont("Aleo-Regular");

      doc.text("Wykaz kwalifikowanych dostawców", 14, 10);
      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "silver",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            "Lp",
            "Nazwa firmy",
            "Rodzaj dostaw",
            "Dostawca - rodzaj materiału/usługi",
            "W1",
            "W2",
            "W3",
            "W4",
            "W5",
            "W6",
            "Wynik",
            "Kwalifikacja",
          ],
        ],
        body: this.searched.map((Procesy) => {
          return [
            Procesy.Lp1,
            Procesy.Nazwa_firmy,
            Procesy.Rodzaj_dostaw,
            Procesy.Dostawca,
            Procesy.W1,
            Procesy.W2,
            Procesy.W3,
            Procesy.W4,
            Procesy.W5,
            Procesy.W6,
            Procesy.Wynik,
            Procesy.Znaczenie,
          ];
        }),
      });

      doc.save("wykaz_dostawcow_kwalifikowanych.pdf");
    },
    pobierzDaneAutoryzacyjne() {
      authorizationService
        .getInfo("StronaTabelaZakupy", 4)

        .then((obiektOdpowiedzi) => {
          const dane = obiektOdpowiedzi.data.dostep;
          this.autoryzacja = {
            czyMozeDodawac: Boolean(
              dane.find(({ Id_operacji }) => Id_operacji == "1")
            ),
            czyMozeUsuwac: Boolean(
              dane.find(({ Id_operacji }) => Id_operacji == "2")
            ),
            czyMozeEdytowac: Boolean(
              dane.find(({ Id_operacji }) => Id_operacji == "3")
            ),
            czyMozePrzegladac: Boolean(
              dane.find(({ Id_operacji }) => Id_operacji == "4")
            ),
          };
        })
        .catch((error) => {
          console.log(error);
        });
    },
    pobierzDaneDoTabeli() {
      const { id } = this.$route.params;

      console.log(this.$route);

      api
        .pobieranieZakupy(id)
        .then((obiektOdpowiedzi) => {
          this.Procesy = obiektOdpowiedzi.data.dane.map((proces,index) => {
            const sum = [
              proces.W1,
              proces.W2,
              proces.W3,
              proces.W4,
              proces.W5,
              proces.W6,
            ]
              .map((el) => +el)
              .reduce((sum, el) => sum + el);
            const Wynik = parseFloat(sum / 6).toFixed(2);
            const Znaczenie =
              Wynik >= 6 ? "Dostawca zakwalifikowany" : "Dostawca odrzucony";
            const Lp1 = index+1;

            return { ...proces, Wynik, Znaczenie, Lp1 };
          });

          this.searched = this.Procesy;
        })

        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.czyPobranoDane = true;
        });
    },
    funkcjaZamykajacaDialog(sukces) {
      this.pokazDialog = false;
      this.typAktywnegoFormularza = null;
      this.obiektKonfiguracyjny = null;
      if (sukces) {
        this.pobierzDaneDoTabeli();
      }
    },
    newUser() {
      window.alert("Noop");
    },
    searchOnTable() {
      this.searched = search(this.Procesy, this.search);
    },
    usuwanieElementu(Id) {
      this.pokazDialog = true;
      this.typAktywnegoFormularza = "usuwanie";
      this.obiektKonfiguracyjny = {
        Id,
        typ: "dostawcyUsuwanie",
      };
    },
    pokazPDF_INFO() {
      //dopisane
      this.pokazDialog = true;
      this.typAktywnegoFormularza = "pokaz_PDF_INFO";
      this.obiektKonfiguracyjny = {
        Nazwa_instrukcji: "INFO-3",
      };
    },

    wyborElementu(element) {
      this.wybranyElementTabeli = element;
    },
  },
};
</script>

<style>
.tabela_Zakupy .md-field {
  max-width: 300px;
}
.tabela_Zakupy .tabela {
  width: 100%;
}
.tabela_Zakupy .md-card {
  box-shadow: none !important;
}
.tabela_Zakupy .md-title {
  font-weight: 900 !important;
  color: black !important;
  font-size: 1.5vh !important;
  padding: 10px;
}
.tabela_Zakupy .md-toolbar {
  height: 17vh;
}
.tabela_Zakupy .md-table-head {
  font-weight: bold;
  color: black !important;
  font-size: 0.9vh !important;
  text-align: left !important;
}
.tabela_Zakupy .md-table-head-label {
  text-align: left !important;
  font-size: 0.9vh !important;
}
.tabela_Zakupy .md-table-cell {
  font-size: 0.9vh !important;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-align: left;
  color: black;
  font-weight: 300;
  margin-top: 200px !important;
}
.tabela_Zakupy .ikony {
  cursor: pointer;
  margin-right: 40px;
}

.tabela_Zakupy .md-table-cell:nth-child(1) {
  width: 5vw;
  text-align: left !important;
}
.tabela_Zakupy .md-table-cell:nth-child(2) {
  width: 13vw;
  text-align: left !important;
}
.tabela_Zakupy .md-table-cell:nth-child(3) {
  width: 13vw;
  text-align: left !important;
}
.tabela_Zakupy .md-table-cell:nth-child(4) {
  width: 13vw;
  text-align: left !important;
}
.tabela_Zakupy .md-table-cell:nth-child(5) {
  width: 5vw;
  text-align: left !important;
}
.tabela_Zakupy .md-table-cell:nth-child(6) {
  width: 5vw;
  text-align: left !important;
}
.tabela_Zakupy .md-table-cell:nth-child(7) {
  width: 5vw;
  text-align: left !important;
}
.tabela_Zakupy .md-table-cell:nth-child(8) {
  width: 5vw;
  text-align: left !important;
}
.tabela_Zakupy .md-table-cell:nth-child(9) {
  width: 5vw;
  text-align: left !important;
}
.tabela_Zakupy .md-table-cell:nth-child(10) {
  width: 5vw;
  text-align: left !important;
}
.tabela_Zakupy .md-table-cell:nth-child(11) {
  width: 5vw;
  text-align: left !important;
}
.tabela_Zakupy .md-table-cell:nth-child(12) {
  width: 12vw;
  text-align: left !important;
}

.tabela_Zakupy .button {
  margin-right: 5px;
  border: 3px white ridge;
  font-weight: bold;
  font-size: 1.1vmin;
  min-width: 9vw;
}
.tabela_Zakupy .button:hover {
  background-color: #727171;
  text-decoration: none;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  color: white;
}
.tabela_Zakupy .menu_strony {
  border: 1px solid;
  margin-top: 5px;
  padding: 3px;
  background: #939393;
  width: 84.5%;
}
.tabela_Zakupy .menu_strony1 {
  border: 1px solid;
  margin-top: 5px;
  padding: 3px;
  background: #939393;
  width: 15%;
  margin-left: 0.5%;
}
.tabela_Zakupy .laczacy {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.md-table.md-theme-default .md-table-row.md-selected,
.md-table.md-theme-default .md-table-row.md-selected-single {
  background-color: #dddddd !important;
}
.PasekWyboru {
  background: #606060;
}
.tabela_Zakupy .ikony {
  margin-right: 4px;
  margin-left: 4px;
  border: 1px solid !important;
  border-radius: 5px;
  padding: 5px;
}
.tabela_Zakupy .ikony:hover {
  background-color: black;
  text-decoration: none;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
.podpowiedz {
  font-size: 1.5vmin !important;
  font-weight: bold;
  background-color: black !important;
}
.btn {
  background-color: #606060 !important;
  padding: 0rem 0rem !important;
  border-color: #606060 !important;
}
</style>
