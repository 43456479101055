<template>
  <div>
  <ElementyWyboruFAK />
  </div>
</template>

<script>
import ElementyWyboruFAK from '../komponenty/strona-glowna-faktury/Elementy_wyboru_FAK.vue'


export default {
  name: 'StronaGlownaFAK',
  components: {
    ElementyWyboruFAK

  }
}
</script>
