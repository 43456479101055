<template>
  <div>
  <TabelaITGeneratorPrzeglad />
  </div>
</template>

<script>
import TabelaITGeneratorPrzeglad from '../../komponenty/strona-tabela-komponenty/WST_wyposazenie_IT/protokol_przeglad.vue'


export default {
  name: 'StronaTabelaITGeneratorPrzeglad',
  components: {
    TabelaITGeneratorPrzeglad,
  }

}
</script>
