<template>
  <iframe
    title="Plik PDF"
    :src="`${podstawaLinku}/funkcje/podglad_PDF_3.php?Id=${obiektKonfiguracyjny.Id}&Klucz=${obiektKonfiguracyjny.Klucz}`"
  >
  </iframe>
</template>

<script>

import {podstawaLinku} from '@/serwisy/api.serwis'
export default {
  name: "formularz_pokaz_PDF_3",
  data: ()=> ({
podstawaLinku
  }), 
  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
};
</script>

<style scoped>
iframe
{
  width: 50vw!important;
    height: 80vh!important;
}
.s {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
}
</style>