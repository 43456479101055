<template>
  <div>
  <TabelaPomiarowkaKoszty />
  </div>
</template>

<script>
import TabelaPomiarowkaKoszty from '../../komponenty/strona-tabela-komponenty/WST_pomiarowka/tabela_pomiarowka_koszty.vue'


export default {
  name: 'StronaTabelaPomiarowkaKoszty',
  components: {
    TabelaPomiarowkaKoszty,
  }

}
</script>
