<template>
  <div class="MDBHP_Obramowanie">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI WYPOSAŻENIA BHP"
                : "FORMULARZ DODAWANIA WYPOSAŻENIA BHP"
            }}
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M1"
                :class="getValidationClass('Id_wyposazenia')"
              >
                <label for="Id_wyposazenia">ID Wyposażenia</label>
                <!--- edycja 7 linia-->
                <md-input
                  type="text"
                  name="Id_wyposazenia"
                  id="Id_wyposazenia"
                  
                  md-counter="10"
                  v-model="form.Id_wyposazenia"
                  :disabled="sending || czyJestWTrybieEdycji"
                  readonly
                />
                <span class="md-error" v-if="!$v.form.Id_wyposazenia.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Id_wyposazenia.maxlength"
                  >Wymagana ilość znaków od 1 do 10</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M2"
                :class="getValidationClass('Nazwa_wyposazenia')"
              >
                <label for="Nazwa_wyposazenia">Nazwa wyposażenia</label>
                <md-input
                  type="text"
                  name="Nazwa_wyposazenia"
                  id="Nazwa_wyposazenia"
                  
                  md-counter="100"
                  v-model="form.Nazwa_wyposazenia"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Nazwa_wyposazenia.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Nazwa_wyposazenia.maxlength"
                  >Wymagana ilość znaków od 1 do 100</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M4"
                :class="getValidationClass('Numer_fabryczny')"
              >
                <label for="Numer_fabryczny">Numer fabryczny</label>
                <md-input
                  type="text"
                  name="Numer_fabryczny"
                  id="Numer_fabryczny"
                  
                  md-counter="50"
                  v-model="form.Numer_fabryczny"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Numer_fabryczny.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Numer_fabryczny.maxlength"
                  >Wymagana ilość znaków od 1 do 50</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field class="M4" :class="getValidationClass('Rok_produkcji')">
                <label for="Rok_produkcji">Rok produkcji</label>
                <md-input
                  type="text"
                  name="Rok_produkcji"
                  id="Rok_produkcji"
                  
                  md-counter="4"
                  v-model="form.Rok_produkcji"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Rok_produkcji.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Rok_produkcji.maxlength"
                  >Wymagana ilość znaków 4</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Rok_produkcji.minlength"
                  >Wymagana ilość znaków 4</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M4"
                :class="getValidationClass('Osoba_odpowiedzialna')"
              >
                <label for="Osoba_odpowiedzialna">Osoba odpowiedzialna</label>
                <md-input
                  type="text"
                  name="Osoba_odpowiedzialna"
                  id="Osoba_odpowiedzialna"
                  md-counter="50"
                  
                  v-model="form.Osoba_odpowiedzialna"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Osoba_odpowiedzialna.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Osoba_odpowiedzialna.maxlength"
                  >Wymagana ilość znaków od 1 do 50</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M4" :class="getValidationClass('Status')">
                <label for="Status">Status wyposażenia</label>

                <md-select
                  v-model="form.Status"
                  :disabled="sending"
                  name="Status"
                  id="Status"
                  autocomplete="Status"
                  md-dense
                >
                  <md-option
                    v-for="(status, index) in Status"
                    :key="index"
                    :value="status.Id"
                    >{{ status.Status_maszyny }}</md-option
                  >
                </md-select>
                <span class="md-error" v-if="!$v.form.Status.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div
              v-if="!czyJestWTrybieEdycji"
              class="md-layout-item md-small-size-100"
            >
              <md-field
                class="M5"
                :class="getValidationClass('Data_przegladu')"
              >
                <label for="Data_przegladu">Data przeglądu</label>
                <md-input
                  type="date"
                  name="Data_przegladu"
                  id="Data_przegladu"
                  autocomplete="Data_przegladu"
                  v-model="form.Data_przegladu"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Data_przegladu.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div
              v-if="!czyJestWTrybieEdycji"
              class="md-layout-item md-small-size-100"
            >
              <md-field
                class="M5"
                :class="getValidationClass('Data_nastepnego_przegladu')"
              >
                <label for="Data_nastepnej_weryfikacji"
                  >Data następnego przeglądu</label
                >
                <md-input
                  type="date"
                  id="Data_nastepnego_przegladu"
                  name="Data_nastepnego_przegladu"
                  autocomplete="Data_nastepnego_przegladu"
                  v-model="form.Data_nastepnego_przegladu"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Data_nastepnego_przegladu.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

          <div v-if="!czyJestWTrybieEdycji" class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M5"
                :class="getValidationClass('Przeglad_wykonal')"
              >
                <label for="Przeglad_wykonal">Przegląd wykonał</label>
                <md-input
                  name="Przeglad_wykonal"
                  id="Przeglad_wykonal"
                  md-counter="100"
                  autocomplete="Przeglad_wykonal"
                  v-model="form.Przeglad_wykonal"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Przeglad_wykonal.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Przeglad_wykonal.maxlength"
                  >Wymagana ilość znaków od 1 do 100</span
                >
              </md-field>
            </div>
          </div>

          <div v-if="!czyJestWTrybieEdycji" class="md-layout md-gutter">
            <md-field class="MP3" :class="plikZwalidowany ? '' : 'md-invalid'">
              <label>Wstaw plik "max-2Mb"
                <span v-if="nazwaPliku">
                  - obecnie wstawiony plik to {{ nazwaPliku }}</span
                ></label
              >
              <md-file
                v-model="form.plik_PDF"
                @md-change="zapamietajPlik"
                accept="application/pdf"
              />
              <span class="md-error" v-if="!plikZwalidowany"
                >Plik moze miec max 2MB</span
              >
            </md-field>
          </div>

        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

        <div style="text-align: center">
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            czyJestWTrybieEdycji
              ? "ZATWIERDŹ - EDYTOWANY WPIS"
              : "ZATWIERDŹ - UTWÓRZ WPIS"
          }}</md-button>
        </div>
      </md-card>

    </form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import minLength from "vuelidate/lib/validators/minLength";
import { api } from "./../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_wyposazeniaBHP",
  mixins: [validationMixin],
  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      Id_wyposazenia: null,
      Nazwa_wyposazenia: null,
      Numer_fabryczny: null,
      Osoba_odpowiedzialna: null,
      Rok_produkcji: null,
      Status: null,
      Data_przegladu: null,
      Data_nastepnego_przegladu: null,
      Przeglad_wykonal: null,
    },
    IdEdytowanegoElementu: null,
    userSaved: false,
    sending: false,
    lastUser: null,
    Status: [],
    generujNumer: null,
  }),
  created: function () {
    if (this.obiektKonfiguracyjny.akcja === "edycja") {
      //edycja
      api
        .pobieranieWyposazeniaBhpEdycja(this.obiektKonfiguracyjny.Id)
        .then((odpowiedz) => {
          const {
            Id,
            Id_wyposazenia,
            Nazwa_wyposazenia,
            Numer_fabryczny,
            Osoba_odpowiedzialna,
            Rok_produkcji,
            StatusWartosc,
            Data_przegladu,
            Data_nastepnego_przegladu,
            Przeglad_wykonal,

          } = odpowiedz.data.dane[0];
          this.IdEdytowanegoElementu = Id;
          this.form.Id_wyposazenia = Id_wyposazenia;
          this.form.Nazwa_wyposazenia = Nazwa_wyposazenia;
          this.form.Numer_fabryczny = Numer_fabryczny;
          this.form.Osoba_odpowiedzialna = Osoba_odpowiedzialna;
          this.form.Rok_produkcji = Rok_produkcji;
          this.form.Status = StatusWartosc;
          this.form.Data_przegladu = Data_przegladu;
          this.form.Data_nastepnego_przegladu = Data_nastepnego_przegladu;
          this.form.Przeglad_wykonal = Przeglad_wykonal;

        })
        .catch((error) => {
          console.log(error);
        });
        
    }

    api
      .pobierzStatus()
      .then((odpowiedz) => {
        this.Status = odpowiedz.data.dane;
      })
      .catch((error) => {
        console.log(error);
      });
      this.pobierzNumerDlaMaszyny();
  },
  validations: {
    form: {
      Id_wyposazenia: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(10),
      },
      Nazwa_wyposazenia: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(100),
      },
      Numer_fabryczny: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(50),
      },
      Status: {
        required,
      },
      Rok_produkcji: {
        required,
        minLenght: minLength(4),
        maxLength: maxLength(4),
      },
      Osoba_odpowiedzialna: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(50),
      },
      Data_przegladu: {
        required,
      },
      Data_nastepnego_przegladu: {
        required,
      },
      Przeglad_wykonal: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(100),
      },
    },
  },
  computed: {
    plikZwalidowany: function () {
      return this.form.plik_dane
        ? this.form.plik_dane.size < 2 * 1024 * 1024
        : true;
    },
    czyJestWTrybieEdycji: function () {
      return this.obiektKonfiguracyjny.akcja === "edycja";
    },
  },

  methods: {
    generujNumerMaszyny(generujNumer) {
      if (generujNumer) {
        const { numer } = generujNumer;
        return `WBHP - ${numer}`;
      } else {
        return "";
      }
    },

    pobierzNumerDlaMaszyny() {
      if (this.czyJestWTrybieEdycji) {
        return;
      }

      api.PobierzLpWBHP().then((odpowiedz) => {
        this.generujNumer = odpowiedz.data.dane;
        this.form.Id_wyposazenia = this.generujNumerMaszyny(odpowiedz.data.dane);
      });
    },
    zapamietajPlik(pliki) {
      if (pliki[0]) {
        this.form.plik_dane = pliki[0];
      }
    },
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.Id_wyposazenia = null;
      this.form.Nazwa_wyposazenia = null;
      this.form.Numer_fabryczny = null;
      this.form.Status = null;
      this.form.Rok_produkcji = null;
      this.form.Osoba_odpowiedzialna = null;
      this.form.Data_przegladu = null;
      this.form.Data_nastepnego_przegladu = null;
      this.form.Przeglad_wykonal = null;
    },

    zapiszFormularz() {
      this.sending = true;

      if (this.czyJestWTrybieEdycji) {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Id_wyposazenia", this.form.Id_wyposazenia);
        dane.append("Nazwa_wyposazenia", this.form.Nazwa_wyposazenia);
        dane.append("Numer_fabryczny", this.form.Numer_fabryczny);
        dane.append("Status", this.form.Status);
        dane.append("Rok_produkcji", this.form.Rok_produkcji);
        dane.append("Osoba_odpowiedzialna", this.form.Osoba_odpowiedzialna);
        // dane.append("Data_przegladu", this.form.Data_przegladu);
        //dane.append(
        //  "Data_nastepnego_przegladu",
        //   this.form.Data_nastepnego_przegladu
        // );
        // dane.append("Przeglad_wykonal", this.form.Przeglad_wykonal);
        // dane.append("plik_PDF", this.form.plik_dane);

        api
          .zapiszWyposazenieBhpUpdate(dane, this.IdEdytowanegoElementu)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Id_wyposazenia", this.form.Id_wyposazenia);
        dane.append("Nazwa_wyposazenia", this.form.Nazwa_wyposazenia);
        dane.append("Numer_fabryczny", this.form.Numer_fabryczny);
        dane.append("Status", this.form.Status);
        dane.append("Rok_produkcji", this.form.Rok_produkcji);
        dane.append("Osoba_odpowiedzialna", this.form.Osoba_odpowiedzialna);
        dane.append("Data_przegladu", this.form.Data_przegladu);
        dane.append(
          "Data_nastepnego_przegladu",
          this.form.Data_nastepnego_przegladu
        );
        dane.append("Przeglad_wykonal", this.form.Przeglad_wykonal);
        dane.append("Lp", this.generujNumer.numer);
        dane.append("plik_PDF", this.form.plik_dane);

        api
          .zapiszWyposazenieBhp(dane)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    naszWalidacjaFormularza() {
      if (this.czyJestWTrybieEdycji) {
        return (
          !this.$v.form.Id_wyposazenia.$invalid &&
          !this.$v.form.Nazwa_wyposazenia.$invalid &&
          !this.$v.form.Numer_fabryczny.$invalid &&
          !this.$v.form.Rok_produkcji.$invalid &&
          !this.$v.form.Status.$invalid &&
          !this.$v.form.Osoba_odpowiedzialna.$invalid &&
          this.plikZwalidowany 
        );
      } else {
        return !this.$v.$invalid && this.plikZwalidowany;
      }
    },
    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>

<style>
.MDBHP_Obramowanie .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.MDBHP_Obramowanie .md-layout .md-gutter {
  min-width: 55vw !important; /* szerokość modala*/
  max-width: 100% !important;
  min-height: 7vh; /* wysokość pomiędzy mierszami w modalu*/
}
.md-layout {
  padding-right: 0px !important;
}
.MDBHP_Obramowanie .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}

.MDBHP_Obramowanie .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  margin: 10px 24px !important;
  padding-top: 35px !important;
}
.MDBHP_Obramowanie .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}


.MDBHP_Obramowanie {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
  overflow: auto;
}
.MDBHP_Obramowanie .md-primary {
  border: 1px solid !important;

  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}
.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important; /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}

.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}
.md-list-item-text {
  font-size: 1.7vmin !important;
}
.MDBHP_Obramowanie .md-card-actions .md-button {
  margin-right: 4vw !important;
}
</style>