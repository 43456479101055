<template>
  <div class="Form_PRACDZATR">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI DANYCH PRACOWNIKA - DANE SZCZEGÓŁOWE ZATRUDNIENIA"
                : "FORMULARZ DODAWANIA DANYCH PRACOWNIKA - DANE SZCZEGÓŁOWE ZATRUDNIENIA"
            }}
          </div>
        </md-card-header>

        <md-card-content>
         <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-autocomplete
                :class="getValidationClass('Nazwa_wybor_pracownika')"
                v-model="form.Nazwa_wybor_pracownika"
                :md-options="zapytaniaPracownicy"
                :disabled="sending || czyJestWTrybieEdycji"
              >
                <label>Wybór pracownika</label>
                <span
                  class="md-error"
                  v-if="!$v.form.Nazwa_wybor_pracownika.required"
                  >Pole jest wymagane</span
                >
              </md-autocomplete>
            </div>
          </div>
          
          <div  style="margin-top: 20px; margin-left: 22px; font-weight: bold">
            DANE DOTYCZĄCE ZATRUDNIENIA
          </div>
          <div class="md-layout md-gutter">
            <div              
              class="md-layout-item md-small-size-100"
            >
              <md-field
                class="M5"
                :class="getValidationClass('Data_zatrudnienia')"
              >
                <label for="Data_zatrudnienia">Data zatrudnienia</label>
                <md-input
                  type="date"
                  name="Data_zatrudnienia"
                  id="Data_zatrudnienia"
                  autocomplete="Data_zatrudnienia"
                  v-model="form.Data_zatrudnienia"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Data_zatrudnienia.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div
              class="md-layout-item md-small-size-100"
            >
              <md-field
                class="M4"
                :class="getValidationClass('Id_rodzaj_umowy')"
              >
                <label for="Id_rodzaj_umowy">Rodzaj zatrudnienia</label>

                <md-select
                  v-model="form.Id_rodzaj_umowy"
                  :disabled="sending"
                  name="Id_rodzaj_umowy"
                  id="Id_rodzaj_umowy"
                  autocomplete="Id_rodzaj_umowy"
                  md-dense
                >
                  <md-option
                    v-for="(rodzaj_umowy, index) in Rodzaj_umow"
                    :key="index"
                    :value="rodzaj_umowy.Id"
                    readonly
                    >{{ ` ${rodzaj_umowy.Rodzaj_zatrudnienia} ` }}</md-option
                  >
                </md-select>
                <span class="md-error" v-if="!$v.form.Id_rodzaj_umowy.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100"
            >
              <md-field
                class="M4"
                :class="getValidationClass('Id_czas_zatrudnienia')"
              >
                <label for="Id_czas_zatrudnienia">Czas zatrudnienia</label>

                <md-select
                  v-model="form.Id_czas_zatrudnienia"
                  :disabled="sending"
                  name="Id_czas_zatrudnienia"
                  id="Id_czas_zatrudnienia"
                  autocomplete="Id_czas_zatrudnienia"
                  md-dense
                >
                  <md-option
                    v-for="(czas, index) in Czasy"
                    :key="index"
                    :value="czas.Id"
                    readonly
                    >{{ ` ${czas.Czas_zatrudnienia} ` }}</md-option
                  >
                </md-select>
                <span
                  class="md-error"
                  v-if="!$v.form.Id_czas_zatrudnienia.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div v-if="form.Id_czas_zatrudnienia !== '1'" class="md-layout-item md-small-size-100"
            >
              <md-field class="M5" :class="getValidationClass('Umowa_do')">
                <label for="Umowa_do">Data końca umowy</label>
                <md-input
                  type="date"
                  name="Umowa_do"
                  id="Umowa_do"
                  autocomplete="Umowa_do"
                  v-model="form.Umowa_do"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Umowa_do.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>
        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

        <div style="text-align: center">
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            czyJestWTrybieEdycji
              ? "ZATWIERDŹ - EDYTOWANY WPIS"
              : "ZATWIERDŹ - UTWÓRZ WPIS"
          }}</md-button>
        </div>
      </md-card>
    </form>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { api } from "./../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_maszyny",

  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      Id: null,
      Nazwa_wybor_pracownika: null,
      Data_zatrudnienia: null,
      Id_rodzaj_umowy: null,
      Id_czas_zatrudnienia: null,
      Umowa_do: null,
    },
    IdEdytowanegoElementu: null,
    userSaved: false,
    sending: false,
    lastUser: null,
    Rodzaj_umow: [],
    Czasy: [],
    Pracownicy: [],
  }),

 async created() {
    try {
      const [
        odpowiedz_Pracownik,
        odpowiedz_rodzaj_umowy,
        odpowiedz_czas_zatrudnienia,

      ] = await Promise.all([
        api.pobierzPracownika(),
        api.pobierzRodzajUmowy(),
        api.pobierzCzasZatrudnienia(),
       
      ]);
      odpowiedz_Pracownik,
        (this.Pracownicy = odpowiedz_Pracownik.data.dane.map((el) => ({
          ...el,
          nazwa: `${el.Nazwisko} ${el.Imie1} - NIP: ${el.NIP} ; PESEL: ${el.Pesel}`,
        })));

      this.Rodzaj_umow = odpowiedz_rodzaj_umowy.data.dane;
      this.Czasy = odpowiedz_czas_zatrudnienia.data.dane;

      if (this.obiektKonfiguracyjny.akcja === "edycja") {
        //edycja
        const odpowiedz = await api.pobieranieDaneZatrudnieniaEdycja(
          this.obiektKonfiguracyjny.Id
        );

          const {
            Id,
            Id_pracownika,
            Data_zatrudnienia,
            Id_rodzaj_umowy,
            Id_czas_zatrudnienia,
            Umowa_do,
          } = odpowiedz.data.dane[0];
          this.IdEdytowanegoElementu = Id;
          this.form.Nazwa_wybor_pracownika = this.Pracownicy.find(
          ({ Pracownicy }) => Pracownicy == Id_pracownika
          ).nazwa;
          this.form.Data_zatrudnienia = Data_zatrudnienia;
          this.form.Id_czas_zatrudnienia = Id_czas_zatrudnienia;
          this.form.Id_rodzaj_umowy = Id_rodzaj_umowy;
          this.form.Umowa_do = Umowa_do;
        }
    } catch (error) {
      console.log(error);
    }
  },

  validations: {
    form: {
      Nazwa_wybor_pracownika: {
        required,
      },
      Data_zatrudnienia: {
        required,
      },
      Id_rodzaj_umowy: {
        required,
      },
      Id_czas_zatrudnienia: {
        required,
      },
      Umowa_do: {
required: false,
      },
    },
  },
  computed: {
    Id_pracownika: function() {
      const { Nazwa_wybor_pracownika } = this.form;
      const znalezionyElement = this.Pracownicy.find(
        ({ nazwa }) => nazwa === Nazwa_wybor_pracownika
      );

      if (znalezionyElement) {
        return znalezionyElement.Id;
      }
      return null;
    },
    zapytaniaPracownicy: function() {
      return this.Pracownicy.map((el) => el.nazwa);
    },
    czyJestWTrybieEdycji: function () {
      return this.obiektKonfiguracyjny.akcja === "edycja";
    },
  },

  methods: {
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.Id = null;
      this.Id_pracownika = null;
      this.form.Data_zatrudnienia = null;
      this.form.Id_rodzaj_umowy = null;
      this.form.Id_czas_zatrudnienia = null;
      this.form.Umowa_do = null;
    },

    zapiszFormularz() {
      this.sending = true;

      if (this.czyJestWTrybieEdycji) {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Id_pracownika", this.Id_pracownika);
        dane.append("Data_zatrudnienia", this.form.Data_zatrudnienia);
        dane.append("Id_rodzaj_umowy", this.form.Id_rodzaj_umowy);
        dane.append("Id_czas_zatrudnienia", this.form.Id_czas_zatrudnienia);
        dane.append("Umowa_do", this.form.Umowa_do);

        api
          .zapiszDaneZatrudnieniaUpdate(dane, this.IdEdytowanegoElementu)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Id_pracownika", this.Id_pracownika);
        dane.append("Data_zatrudnienia", this.form.Data_zatrudnienia);
        dane.append("Id_rodzaj_umowy", this.form.Id_rodzaj_umowy);
        dane.append("Id_czas_zatrudnienia", this.form.Id_czas_zatrudnienia);
        dane.append("Umowa_do", this.form.Umowa_do);

        api
          .zapiszDaneZatrudnienia(dane)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    naszWalidacjaFormularza() {
      if (this.czyJestWTrybieEdycji) {
        return (
          !this.$v.form.Nazwa_wybor_pracownika.$invalid &&
          !this.$v.form.Data_zatrudnienia.$invalid &&
          !this.$v.form.Id_rodzaj_umowy.$invalid &&
          !this.$v.form.Id_czas_zatrudnienia.$invalid &&
          !this.$v.form.Umowa_do.$invalid 

        );
      } else {
        return !this.$v.$invalid;
      }
    },

    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>


<style>
.Form_PRACDZATR .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
.Form_PRACDZATR .md-layout .md-gutter {
  min-width: 55vw !important; /* szerokość modala*/
  max-width: 100% !important;
  min-height: 7vh; /* wysokość pomiędzy mierszami w modalu*/
}
.md-layout {
  padding-right: 0px !important;
}
.Form_PRACDZATR .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}

.Form_PRACDZATR .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  margin: 10px 24px !important;
  padding-top: 35px !important;
}
.Form_PRACDZATR .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}


.Form_PRACDZATR {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
  overflow: auto !important;
}
.Form_PRACDZATR .md-primary {
  border: 1px solid !important;

  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}
.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important; /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}

.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}
.md-list-item-text {
  font-size: 1.7vmin !important;
}
.Form_PRACDZATR .md-card-actions .md-button {
  margin-right: 4vw !important;
}
.Form_PRACDZATR .md-field.md-has-textarea:not(.md-autogrow) label {
  top: 16px;
  left: 2px;
}
</style>