<template>
  <div class="Form_PRACHZ">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI HISTORII ZATRUDNIENIA PRACOWNIKA - POPRZEDNIE ZAKŁADY PRACY"
                : "FORMULARZ DODAWANIA HISTORII ZATRUDNIENIA PRACOWNIKA - POPRZEDNIE ZAKŁADY PRACY"
            }}
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-autocomplete
                :class="getValidationClass('Nazwa_wybor_pracownika')"
                v-model="form.Nazwa_wybor_pracownika"
                :md-options="zapytaniaPracownicy"
                :disabled="sending || czyJestWTrybieEdycji"
              >
                <label>Wybór pracownika</label>
                <span
                  class="md-error"
                  v-if="!$v.form.Nazwa_wybor_pracownika.required"
                  >Pole jest wymagane</span
                >
              </md-autocomplete>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('Firma')">
                <label for="Firma">Nazwa pracodawcy</label>

                <md-input
                  name="Firma"
                  id="Firma"
                  autocomplete="Firma"
                  md-autogrow
                  md-counter="100"
                  v-model="form.Firma"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Firma.required"
                  >Pole jest wymagane</span
                >
                <span class="md-error" v-else-if="!$v.form.Firma.maxlength"
                  >Wymagana ilość znaków od 1 do 100</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('NIP_Z')">
                <label for="NIP_Z">NIP pracodawcy</label>

                <md-input
                  name="NIP_Z"
                  id="NIP_Z"
                  autocomplete="NIP_Z"
                  md-autogrow
                  md-counter="13"
                  v-model="form.NIP_Z"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.NIP_Z.required"
                  >Pole jest wymagane</span
                >
                <span class="md-error" v-else-if="!$v.form.NIP_Z.maxlength"
                  >Wymagana ilość znaków od 11 do 13</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M5"
                :class="getValidationClass('Data_zatrudnienia')"
              >
                <label for="Data_zatrudnienia">Data zatrudnienia</label>
                <md-input
                  type="date"
                  name="Data_zatrudnienia"
                  id="Data_zatrudnienia"
                  autocomplete="Data_zatrudnienia"
                  v-model="form.Data_zatrudnienia"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Data_zatrudnienia.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M5"
                :class="getValidationClass('Data_zwolnienia')"
              >
                <label for="Data_zwolnienia">Data zwolnienia</label>
                <md-input
                  type="date"
                  name="Data_zwolnienia"
                  id="Data_zwolnienia"
                  autocomplete="Data_zwolnienia"
                  v-model="form.Data_zwolnienia"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Data_zwolnienia.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <md-field class="MP3" :class="plikZwalidowany ? '' : 'md-invalid'">
              <label
                >Wstaw plik "max-2Mb"
                <span v-if="nazwaPliku">
                  - obecnie wstawiony plik to {{ nazwaPliku }}</span
                ></label
              >
              <md-file
                v-model="form.plik_PDF"
                @md-change="zapamietajPlik"
                accept="application/pdf"
              />
              <span class="md-error" v-if="!plikZwalidowany"
                >Plik moze miec max 2MB</span
              >
            </md-field>
          </div>
        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

        <div style="text-align: center">
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            czyJestWTrybieEdycji
              ? "ZATWIERDŹ - EDYTOWANY WPIS"
              : "ZATWIERDŹ - UTWÓRZ WPIS"
          }}</md-button>
        </div>
      </md-card>
    </form>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { api } from "../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_maszyny",

  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      Nazwa_wybor_pracownika: null,
      Firma: null,
      NIP_Z: null,
      Data_zatrudnienia: null,
      Data_zwolnienia: null,
      plik_PDF: null,
      plik_dane: null,
    },
    IdEdytowanegoElementu: null,
    userSaved: false,
    nazwaPliku: null,
    sending: false,
    lastUser: null,
    Pracownicy: [],
  }),

  async created() {
    try {
      const [
        odpowiedz_Pracownik,

      ] = await Promise.all([
        api.pobierzPracownika(),
       
      ]);
      odpowiedz_Pracownik,
        (this.Pracownicy = odpowiedz_Pracownik.data.dane.map((el) => ({
          ...el,
          nazwa: `${el.Nazwisko} ${el.Imie1} - NIP: ${el.NIP} ; PESEL: ${el.Pesel}`,
        })));



      if (this.obiektKonfiguracyjny.akcja === "edycja") {
        //edycja
        const odpowiedz = await api.pobieranieHistoriaPracownicyEdycja(
          this.obiektKonfiguracyjny.Id
        );

        const {
          Id,
          Id_pracownika,
          Firma,
          NIP_Z,
          Data_zatrudnienia,
          Data_zwolnienia,
          Nazwa_zalacznika,

        } = odpowiedz.data.dane[0];
        this.IdEdytowanegoElementu = Id;
        this.form.Nazwa_wybor_pracownika = this.Pracownicy.find(
          ({ Id }) => Id == Id_pracownika
        ).nazwa;
        this.form.Firma = Firma;
        this.form.NIP_Z = NIP_Z;
        this.form.Data_zatrudnienia = Data_zatrudnienia;
        this.form.Data_zwolnienia = Data_zwolnienia;
        this.nazwaPliku = Nazwa_zalacznika;
      }
    } catch (error) {
      console.log(error);
    }
  },

  validations: {
    form: {
      Nazwa_wybor_pracownika: {
        required,
      },
      Firma: {
        required,
      },
      NIP_Z: {
        required,
      },
      Data_zatrudnienia: {
        required,
      },
      Data_zwolnienia: {
        required,
      },
    },
  },
  computed: {
    Id_pracownika: function() {
      const { Nazwa_wybor_pracownika } = this.form;
      const znalezionyElement = this.Pracownicy.find(
        ({ nazwa }) => nazwa === Nazwa_wybor_pracownika
      );

      if (znalezionyElement) {
        return znalezionyElement.Id;
      }
      return null;
    },
    zapytaniaPracownicy: function() {
      return this.Pracownicy.map((el) => el.nazwa);
    },
    plikZwalidowany: function () {
      return this.form.plik_dane
        ? this.form.plik_dane.size < 2 * 1024 * 1024
        : true;
    },
    czyJestWTrybieEdycji: function() {
      return this.obiektKonfiguracyjny.akcja === "edycja";
    },
  },

  methods: {
    zapamietajPlik(pliki) {
      if (pliki[0]) {
        this.form.plik_dane = pliki[0];
      }
    },
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.Id_pracownika = null;
      this.form.Firma = null;
      this.form.NIP_Z = null;
      this.form.Data_zatrudnienia = null;
      this.form.Data_zwolnienia = null;
      this.form.plik_PDF = null;
      this.form.plik_dane = null;
      this.nazwaPliku = null
    },

    zapiszFormularz() {
      this.sending = true;

      if (this.czyJestWTrybieEdycji) {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Id_pracownika", this.Id_pracownika);
        dane.append("Firma", this.form.Firma);
        dane.append("NIP_Z", this.form.NIP_Z);
        dane.append("Data_zatrudnienia", this.form.Data_zatrudnienia);
        dane.append("Data_zwolnienia", this.form.Data_zwolnienia);
        dane.append("plik_PDF", this.form.plik_dane);

        api
          .zapiszHistoriaPracownicyUpdate(dane, this.IdEdytowanegoElementu)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Id_pracownika", this.Id_pracownika);
        dane.append("Firma", this.form.Firma);
        dane.append("NIP_Z", this.form.NIP_Z);
        dane.append("Data_zatrudnienia", this.form.Data_zatrudnienia);
        dane.append("Data_zwolnienia", this.form.Data_zwolnienia);
        dane.append("plik_PDF", this.form.plik_dane);

        api
          .zapiszHistoriaPracownicy(dane)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    naszWalidacjaFormularza() {
      if (this.czyJestWTrybieEdycji) {
        return !this.$v.form.Nazwa_wybor_pracownika.$invalid &&
          !this.$v.form.Firma.$invalid &&
          !this.$v.form.NIP_Z.$invalid &&
          !this.$v.form.Data_zatrudnienia.$invalid &&
          !this.$v.form.Data_zwolnienia.$invalid &&
          this.plikZwalidowany
      } else {
        return !this.$v.$invalid && this.plikZwalidowany;
      }
    },

    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>

<style>
.Form_PRACHZ .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
.Form_PRACHZ .md-layout .md-gutter {
  min-width: 55vw !important; /* szerokość modala*/
  max-width: 100% !important;
  min-height: 7vh; /* wysokość pomiędzy mierszami w modalu*/
}
.md-layout {
  padding-right: 0px !important;
}
.Form_PRACHZ .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}

.Form_PRACHZ .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  margin: 10px 24px !important;
  padding-top: 35px !important;
}
.Form_PRACHZ .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}

.Form_PRACHZ {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
  overflow: auto !important;
}
.Form_PRACHZ .md-primary {
  border: 1px solid !important;

  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}
.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important; /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}

.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}
.md-list-item-text {
  font-size: 1.7vmin !important;
}
.Form_PRACHZ .md-card-actions .md-button {
  margin-right: 4vw !important;
}
.Form_PRACHZ .md-field.md-has-textarea:not(.md-autogrow) label {
  top: 16px;
  left: 2px;
}
</style>
