<template >
  <div>
  <TabelaZapytania/>
  </div>
</template>

<script>
import TabelaZapytania from '../../komponenty/strona-tabela-komponenty/SO_zapytania_ofertowe/tabela_wykaz_zapytan_ofertowych.vue'


export default {
  name: 'StronaTabelaZapytania',
  components: {
    TabelaZapytania,
    
  }

}
</script>

