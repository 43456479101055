<template>
  <div class="Faktury_zapytanie">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI ZLECENIA PRODUKCYJNEGO NA MAGAZYN"
                : "FORMULARZ ZLECENIA PRODUKCYJNEGO NA MAGAZYN"
            }}
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M1" :class="getValidationClass('Data_zlecenia')">
                <label for="Data_zlecenia">Data zlecenia</label>
                <md-input
                  type="date"
                  name="Data_zlecenia"
                  id="Data_zlecenia"
                  
                  v-model="form.Data_zlecenia"
                  :disabled="sending"
                  @input="pobierzNumerRaportu"
                  :readonly="czyJestWTrybieEdycji"
                />
                <span class="md-error" v-if="!$v.form.Data_zlecenia.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field class="M1" :class="getValidationClass('Numer')">
                <label for="Numer">Numer wewnętrzny zlecenia</label>
                <md-input
                  name="Numer"
                  id="Numer"
                  
                  v-model="form.Numer"
                  :disabled="sending"
                  readonly
                />
                <span class="md-error" v-if="!$v.form.Numer.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('Id_status_zlecenia')">
                <label for="Id_status_zlecenia">Określ status zlecenia</label>

                <md-select
                  v-model="form.Id_status_zlecenia"
                  :disabled="sending"
                  name="Id_status_zlecenia"
                  id="Id_status_zlecenia"
                  autocomplete="Id_status_zlecenia"
                  md-dense
                >
                  <md-option
                    v-for="(status_zlecenia, index) in Statusy_zlecen"
                    :key="index"
                    :value="status_zlecenia.Id"
                    >{{ ` ${status_zlecenia.Status_zlecenia} ` }}</md-option
                  >
                </md-select>
                <span
                  class="md-error"
                  v-if="!$v.form.Id_status_zlecenia.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
            <div v-if="
                form.Id_status_zlecenia !== '1' && form.Id_status_zlecenia !== null
              "
            class="md-layout-item md-small-size-100">
              <md-field
                class="M1"
                :class="getValidationClass('Data_zakonczenia')"
              >
                <label for="Data_zakonczenia">Data zakończenia</label>
                <md-input
                  type="date"
                  name="Data_zakonczenia"
                  id="Data_zakonczenia"
                  
                  v-model="form.Data_zakonczenia"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Data_zakonczenia.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M1"
                :class="getValidationClass('Osoba_odpowiedzialna')"
              >
                <label for="Osoba_odpowiedzialna"
                  >Osoba odpowiedzialna</label
                >
                <md-input
                  name="Osoba_odpowiedzialna"
                  id="Osoba_odpowiedzialna"
                  
                  md-counter="50"
                  v-model="form.Osoba_odpowiedzialna"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Osoba_odpowiedzialna.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Osoba_odpowiedzialna.maxlength"
                  >Wymagana ilość znaków od 1 do 50</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="plikZwalidowany ? '' : 'md-invalid'">
                <label
                  >Wstaw plik - zlecenia wewnętrznego "max-2Mb"
                  <span v-if="nazwaPliku">
                    - obecnie wstawiony plik to {{ nazwaPliku }}</span
                  ></label
                >
                <md-file
                  v-model="form.plik_PDF"
                  @md-change="zapamietajPlik"
                  accept="application/pdf"
                />
                <span class="md-error" v-if="!plikZwalidowany"
                  >Plik moze miec max 2MB</span
                >
              </md-field>
            </div>
          </div>
          <div
            style="
              margin-top: 40px;
              margin-left: 15px;
              font-weight: bold;
              font-size: 16px;
            "
          >
            SZCZEGÓŁY ZLECENIA WEWNĘTRZNEGO
          </div>
          <div
            v-for="(wiersz, index) in form.wiersze"
            :key="index"
            :set="(v = $v.form.wiersze.$each[index])"
          >
            <div
              style="
                color: blue;
                font-weight: bold;
                font-size: 1.5vh;
                margin-left: 15px;
                margin-top: 10px;
              "
            ></div>

            <div class="md-layout md-gutter">
              <div class="md-layout-item md-small-size-100">
                <md-autocomplete
                  :class="getValidationClass('Nazwa_wybor_wyrobu', index)"
                  v-model="wiersz.Nazwa_wybor_wyrobu"
                  :md-options="zapytaniaWyroby"
                  :key="index"
                  :disabled="sending"
                >
                  <label>Wybór wyrobu / usługi</label>
                  <span class="md-error" v-if="!v.Nazwa_wybor_wyrobu.required"
                    >Pole jest wymagane</span
                  >
                </md-autocomplete>
              </div>
            </div>

            <div class="md-layout md-gutter">
              <div class="md-layout-item md-small-size-100">
                <md-field :class="getValidationClass('Id_jednostka', index)">
                  <label for="Id_jednostka">Jedn.</label>

                  <md-select
                    v-model="wiersz.Id_jednostka"
                    :disabled="sending"
                    name="Id_jednostka"
                    id="Id_jednostka"
                    autocomplete="Id_jednostka"
                    md-dense
                  >
                    <md-option
                      v-for="(jedn_sprzedazy, index) in Jednostki_sprzedazy"
                      :key="index"
                      :value="jedn_sprzedazy.Id"
                      >{{ jedn_sprzedazy.Jedn_sprzedazy }}
                    </md-option>
                  </md-select>
                  <span class="md-error" v-if="!v.Id_jednostka.required"
                    >Pole jest wymagane</span
                  >
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100">
                <md-field :class="getValidationClass('Ilosc', index)">
                  <label for="Ilosc">Ilość</label>
                  <md-input
                    name="Ilosc"
                    id="Ilosc"
                    autocomplete="Ilosc"
                    v-model="wiersz.Ilosc"
                    :disabled="sending"
                  />
                  <span class="md-error" v-if="!v.Ilosc.required"
                    >Pole jest wymagane</span
                  >
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100">
                <md-field :class="getValidationClass('Id_status_uwag', index)">
                  <label for="Id_status_uwag">Czy chcesz wpisać uwagę</label>

                  <md-select
                    v-model="wiersz.Id_status_uwag"
                    :disabled="sending"
                    name="Id_status_uwag"
                    id="Id_status_uwag"
                    autocomplete="Id_status_uwag"
                    md-dense
                  >
                    <md-option
                      v-for="(status_uwag, index) in Statusy_uwag"
                      :key="index"
                      :value="status_uwag.Id"
                      >{{ status_uwag.Status_uwag }}
                    </md-option>
                  </md-select>
                  <span class="md-error" v-if="!v.Id_status_uwag.required"
                    >Pole jest wymagane</span
                  >
                </md-field>
              </div>
            </div>
            <div
              v-if="
                wiersz.Id_status_uwag !== '2' && wiersz.Id_status_uwag !== null
              "
              class="md-layout md-gutter"
            >
              <div class="md-layout-item md-small-size-100">
                <md-field :class="getValidationClass('Uwagi', index)">
                  <label for="Uwagi">Uwagi do pozycji</label>
                  <md-textarea
                    name="Uwagi"
                    id="Uwagi"
                    autocomplete="Uwagi"
                    md-autogrow
                    md-counter="1000"
                    v-model="wiersz.Uwagi"
                    :disabled="sending"
                  />
                  <span class="md-error" v-if="!v.Uwagi.required"
                    >Pole jest wymagane</span
                  >
                  <span class="md-error" v-else-if="!v.Uwagi.maxlength"
                    >Wymagana ilość znaków od 1 do 1000</span
                  >
                </md-field>
              </div>
            </div>
          </div>
        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

        <div style="text-align: center">
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            czyJestWTrybieEdycji
              ? "ZATWIERDŹ - EDYTOWANY WPIS"
              : "ZATWIERDŹ - UTWÓRZ WPIS"
          }}</md-button>
        </div>
      </md-card>
    </form>
  </div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import minLength from "vuelidate/lib/validators/minLength";
import { api } from "../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_zapytania",

  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      Numer: null,
      Data_zlecenia: null,
      Data_zakonczenia: null,
      Id_status_zlecenia: null,
      Osoba_odpowiedzialna: null,
      plik_PDF: null,
      plik_dane: null,
      wiersze: [
        {
          Nazwa_wybor_wyrobu: null,
          Ilosc: null,
          Id_jednostka: null,
          Id_status_uwag: null,
          Uwagi: null,
        },
      ],
    },
    IdEdytowanegoElementu: null,
    userSaved: false,
    sending: false,
    lastUser: null,
    nazwaPliku: null,
    Jednostki_sprzedazy: [],
    Statusy_zlecen: [],
    Statusy_uwag: [],
    Wyroby: [],
    Obiekt_generujacy_numer_zlecenia: null,
  }),
  async created() {
    try {
      const [
        odpowiedz_Jednostki_sprzedazy,
        odpowiedz_Statusy_zlecen,
        odpowiedz_Statusy_uwag,
        odpowiedz_Wyrob,
      ] = await Promise.all([
        api.pobierzJednostki_sprzedazy(),
        api.pobierzStatusZleceniaMazgazyn(),
        api.pobierzStatusUwag(),
        api.pobierzWyroby(),
      ]);
      odpowiedz_Jednostki_sprzedazy,
        (this.Jednostki_sprzedazy = odpowiedz_Jednostki_sprzedazy.data.dane);
      odpowiedz_Statusy_zlecen,
        (this.Statusy_zlecen = odpowiedz_Statusy_zlecen.data.dane);
      odpowiedz_Statusy_uwag,
        (this.Statusy_uwag = odpowiedz_Statusy_uwag.data.dane);
      odpowiedz_Wyrob,
        (this.Wyroby = odpowiedz_Wyrob.data.dane.map((el) => ({
          ...el,
          nazwa1: `${el.Numer} - ${el.Nazwa}`,
        })));

      if (this.obiektKonfiguracyjny.akcja === "edycja") {
        //edycja
        const odpowiedz = await api.pobieranieZleceniaMagazynEdycja(
          this.obiektKonfiguracyjny.Id
        );
        const {
          Id,
          Numer,
          Data_zlecenia,
          Data_zakonczenia,
          Id_status_zlecenia,
          Osoba_odpowiedzialna,
          Nazwa_zalacznika,
          PozycjeZapytania,
        } = odpowiedz.data.dane[0];
        this.IdEdytowanegoElementu = Id;
        this.form.Numer = Numer;
        this.form.Data_zlecenia = Data_zlecenia;
        this.form.Data_zakonczenia = Data_zakonczenia;
        this.form.Id_status_zlecenia = Id_status_zlecenia;
        this.form.Osoba_odpowiedzialna = Osoba_odpowiedzialna;
        this.nazwaPliku = Nazwa_zalacznika;
        if (PozycjeZapytania.length !== 0) {
          this.form.wiersze = PozycjeZapytania.map((wiersz) => {
            const znalezionyElement = this.Wyroby.find(
              ({ Id }) => Id === wiersz.Id_wyrobu
            );
            delete wiersz.Id_wyrobu;
            return { ...wiersz, Nazwa_wybor_wyrobu: znalezionyElement.nazwa1 };
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  },

  validations: {
    form: {
      Numer: {
        required,
      },
      Data_zlecenia: {
        required,
      },
      Data_zakonczenia: {
        required: required(false),
      },
      Id_status_zlecenia: {
        required,
      },
      Osoba_odpowiedzialna: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(50),
      },
      wiersze: {
        $each: {
          Nazwa_wybor_wyrobu: {
            required,
          },
          Ilosc: {
            required,
          },
          Id_jednostka: {
            required,
          },
          Id_status_uwag: {
            required,
          },
          Uwagi: {
            required: required(false),
            maxLength: maxLength(1000),
          },
        },
      },
    },
  },
  computed: {
    zapytaniaWyroby: function() {
      return this.Wyroby.map((el) => el.nazwa1);
    },

    plikZwalidowany: function() {
      return this.form.plik_dane
        ? this.form.plik_dane.size < 2 * 1024 * 1024
        : true;
    },
    
    czyJestWTrybieEdycji: function() {
      return this.obiektKonfiguracyjny.akcja === "edycja";
    },
    numerZapytan: function() {
      if (this.Obiekt_generujacy_numer_zlecenia) {
        const { numer, miesiac, rok } = this.Obiekt_generujacy_numer_zlecenia;
        return `ZL/WEW-${numer}/${miesiac}/${rok}`;
      } else {
        return "";
      }
    },
  },

  methods: {
    zapamietajPlik(pliki) {
      if (pliki[0]) {
        this.form.plik_dane = pliki[0];
      }
    },
    pobierzNumerRaportu() {
      const { Data_zlecenia } = this.form;
      const data = new Date(Data_zlecenia);
      const rok = data.getFullYear();
      const miesiac = data.getMonth() + 1;
      if (this.czyJestWTrybieEdycji) {
        return;
      }

      api.PobierzLpZleceniaMagazyn({ rok, miesiac }).then((odpowiedz) => {
        this.Obiekt_generujacy_numer_zlecenia = odpowiedz.data.dane;
        this.form.Numer = this.numerZapytan;
      });
    },

    getValidationClass(fieldName, index) {
      const field =
        this.$v.form[fieldName] || this.$v.form.wiersze.$each[index][fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.Numer = null;
      this.form.Data_zlecenia = null;
      this.form.Data_zakonczenia = null;
      this.form.Id_status_zlecenia = null;
      this.form.Osoba_odpowiedzialna = null;
      this.form.plik_PDF = null;
      this.form.plik_dane = null;
      this.nazwaPliku = null;
      this.form.Id_wyrobu = null;
      this.form.wiersze = [];
    },

    async zapiszFormularz() {
      this.sending = true;

      let file = null;
      if (this.form.plik_dane) {
        file = await new Promise((resolve) => {
          const fileReader = new FileReader();
          fileReader.onload = () => {
            resolve(fileReader.result);
          };
          fileReader.readAsDataURL(this.form.plik_dane);
        });
      }

      if (this.czyJestWTrybieEdycji) {
        const daneDoWyslania = {
          Numer: this.form.Numer,
          Data_zlecenia: this.form.Data_zlecenia,
          Data_zakonczenia: this.form.Data_zakonczenia,
          Id_status_zlecenia: this.form.Id_status_zlecenia,
          Osoba_odpowiedzialna: this.form.Osoba_odpowiedzialna,
          plik_PDF: file,
          PlikNazwa: this.form.plik_dane ? this.form.plik_dane.name : null,
          PozycjeCzynikow: this.form.wiersze.map((wiersz) => {
            const znalezionyElement = this.Wyroby.find(
              ({ nazwa1 }) => nazwa1 === wiersz.Nazwa_wybor_wyrobu
            );
            const Ilosc = parseFloat(wiersz.Ilosc.replace(",", "."));
            return { ...wiersz, Id_wyrobu: znalezionyElement.Id, Ilosc };
          }),
        };

        api
          .zapiszZleceniaMagazynUpdate(
            daneDoWyslania,
            this.IdEdytowanegoElementu
          )
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const daneDoWyslania = {
          Numer: this.form.Numer,
          Lp: this.Obiekt_generujacy_numer_zlecenia.numer,
          Data_zlecenia: this.form.Data_zlecenia,
          Data_zakonczenia: this.form.Data_zakonczenia,
          Id_status_zlecenia: this.form.Id_status_zlecenia,
          Osoba_odpowiedzialna: this.form.Osoba_odpowiedzialna,
          plik_PDF: file,
          PlikNazwa: this.form.plik_dane ? this.form.plik_dane.name : null,
          PozycjeCzynikow: this.form.wiersze.map((wiersz) => {
            const znalezionyElement = this.Wyroby.find(
              ({ nazwa1 }) => nazwa1 === wiersz.Nazwa_wybor_wyrobu
            );
            const Ilosc = parseFloat(wiersz.Ilosc.replace(",", "."));
            return { ...wiersz, Id_wyrobu: znalezionyElement.Id, Ilosc };
          }),
        };

        api
          .zapiszZleceniaMagazyn(daneDoWyslania)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    naszWalidacjaFormularza() {
      if (this.czyJestWTrybieEdycji) {
        return (
          !this.$v.form.Numer.$invalid &&
          !this.$v.form.Data_zlecenia.$invalid &&
          !this.$v.form.Data_zakonczenia.$invalid &&
          !this.$v.form.Id_status_zlecenia.$invalid &&
          !this.$v.form.Osoba_odpowiedzialna.$invalid &&
          !this.$v.form.wiersze.$invalid &&
          this.plikZwalidowany
        );
      } else {
        return !this.$v.$invalid && this.plikZwalidowany;
      }
    },
    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>
<style>
.Faktury_zapytanie .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.Faktury_zapytanie .md-layout .md-gutter {
  min-width: 73vw !important; /* szerokość modala*/
  max-width: 100% !important;
  min-height: 7vh !important; /* wysokość pomiędzy mierszami w modalu*/
  margin-right: 20px !important;
}
.md-layout {
  padding-right: 0px !important;
}
.Faktury_zapytanie .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}

.Faktury_zapytanie .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  margin: 10px 14px !important;
  padding-top: 35px !important;
}
.Faktury_zapytanie .A3 .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 90px !important;
  margin: 10px 10px 10px 10px !important;
  padding-top: 35px !important;
}
.Faktury_zapytanie .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}

.Faktury_zapytanie {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
  overflow: auto;
}
.Faktury_zapytanie .md-primary {
  border: 1px solid !important;

  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}
.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important; /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}
.md-layout .md-gutter {
  min-width: 1100px !important;
}
.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}
.md-list-item-text {
  font-size: 1.7vmin !important;
}
.Faktury_zapytanie .md-card-actions .md-button {
  margin-right: 50px !important;
}

.Faktury_zapytanie .ikony {
  cursor: pointer;
  margin-right: 5px;
  margin-left: 15px;
}
.md-field.md-has-textarea:not(.md-autogrow):after,
.md-field.md-has-textarea:not(.md-autogrow):before {
  border: none !important;
  border-bottom: 0.5px solid !important;
  height: 90px !important;
  font-size: 12px !important;
}
.md-field.md-has-textarea:not(.md-autogrow) .md-textarea {
  min-height: 40px !important;
  padding: 0 0px !important;
  resize: vertical !important;
}
.md-input {
  width: 100% !important;
}
</style>
