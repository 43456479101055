<template >
  <div>
  <TabelaWykazPracownicySzkoleniaBHP />
  </div>
</template>

<script>
import TabelaWykazPracownicySzkoleniaBHP from '../../komponenty/strona-tabela-komponenty/HR_pracownicy/tabela_szkolenia_BHP.vue'


export default {
  name: 'StronaTabelaWykazSzkoleniaBHP',
  components: {
    TabelaWykazPracownicySzkoleniaBHP,
    
  }

}
</script>

