<template >
  <div>
  <TabelaLogowanieDostepSzczegoly />
  </div>
</template>

<script>
import TabelaLogowanieDostepSzczegoly from '../../komponenty/strona-tabela-komponenty/LOGOWANIE/tabela_wykaz_dostepu_szczegolowy.vue'


export default {
  name: 'StronaTabelaLogowanieDostepSzczegoly',
  components: {
    TabelaLogowanieDostepSzczegoly,
    
  }

}
</script>

