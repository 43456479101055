<template>
  <div>
  <TabelaBudynkiPrzegladKominy/>
  </div>
</template>

<script>
import TabelaBudynkiPrzegladKominy from '../../komponenty/strona-tabela-komponenty/WST_budynki/tabela_budynki_przeglady_kominy.vue'


export default {
  name: 'StronaTabelaBudynkiPrzegladKominy',
  components: {
    TabelaBudynkiPrzegladKominy,
  }

}
</script>
