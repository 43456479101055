<template>

  <TabelaPrzegladAspekty />

</template>

<script>
import TabelaPrzegladAspekty from '../../komponenty/strona-tabela-komponenty/SYS_aspekty/tabela_przeglad_aspekty.vue'



export default {
  name: 'StronaTabelaPrzegladAspekty',
  components: {
    TabelaPrzegladAspekty,

    }

}
</script>
