<template >
  <div>
  <TabelaZadowolenie />
  </div>
</template>

<script>
import TabelaZadowolenie from '../../komponenty/strona-tabela-komponenty/SYS_zadowolenie_klienta/tabela_referencje.vue'


export default {
  name: 'StronaZadowolenie',
  components: {
    TabelaZadowolenie,
    
  }

}
</script>

