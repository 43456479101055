<template>
  <div class="Form_MD_ubezpiecz">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI UBEZPIECZENIA MASZYN I URZĄDZEŃ PODLEGAJĄCYCH POD DOZÓR TECHNICZNY"
                : "FORMULARZ DODAWANIA UBEZPIECZENIA MASZYN I URZĄDZEŃ PODLEGAJĄCYCH POD DOZÓR TECHNICZNY"
            }}
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-autocomplete
                :class="getValidationClass('Nazwa_wybor_maszyny')"
                v-model="form.Nazwa_wybor_maszyny"
                :md-options="zapytaniaMaszyny"
                :disabled="sending || czyJestWTrybieEdycji"
              >
                <label>Wybór maszyny</label>
                <span
                  class="md-error"
                  v-if="!$v.form.Nazwa_wybor_maszyny.required"
                  >Pole jest wymagane</span
                >
              </md-autocomplete>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="MU2"
                :class="getValidationClass('Data_ubezpieczenia')"
              >
                <label for="Data_ubezpieczenia">Data ubezpieczenia</label>
                <md-input
                  type="date"
                  name="Data_ubezpieczenia"
                  id="Data_ubezpieczenia"
                  autocomplete="Data_ubezpieczenia"
                  v-model="form.Data_ubezpieczenia"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Data_ubezpieczenia.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field
                class="MU2"
                :class="getValidationClass('Data_nastepnego_ubezpieczenia')"
              >
                <label for="Data_nastepnego_ubezpieczenia"
                  >Data następnego ubezpieczenia</label
                >
                <md-input
                  type="date"
                  id="Data_nastepnego_ubezpieczenia"
                  name="Data_nastepnego_ubezpieczenia"
                  autocomplete="Data_nastepnego_ubezpieczenia"
                  v-model="form.Data_nastepnego_ubezpieczenia"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Data_nastepnego_ubezpieczenia.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="MU2"
                :class="getValidationClass('Ubezpieczyciel')"
              >
                <label for="Ubezpieczyciel">Ubezpieczyciel</label>
                <md-input
                  id="Ubezpieczyciel"
                  name="Ubezpieczyciel"
                  autocomplete="Ubezpieczyciel"
                  md-counter="250"
                  v-model="form.Ubezpieczyciel"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Ubezpieczyciel.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Ubezpieczyciel.maxlength"
                  >Wymagana ilość znaków od 1 do 250</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field class="MU2" :class="getValidationClass('Nr_polisy')">
                <label for="Nr_polisy">Numer polisy</label>
                <md-input
                  name="Nr_polisy"
                  id="Nr_polisy"
                  autocomplete="Nr_polisy"
                  md-counter="50"
                  v-model="form.Nr_polisy"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Nr_polisy.required"
                  >Pole jest wymagane</span
                >
                <span class="md-error" v-else-if="!$v.form.Nr_polisy.maxlength"
                  >Wymagana ilość znaków od 1 do 50</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <md-field class="MU3" :class="plikZwalidowany ? '' : 'md-invalid'">
              <label
                >Wstaw plik "max-2Mb"
                <span v-if="nazwaPliku">
                  - obecnie wstawiony plik to {{ nazwaPliku }}</span
                ></label
              >
              <md-file
                v-model="form.plik_PDF"
                @md-change="zapamietajPlik"
                accept="application/pdf"
              />
              <span class="md-error" v-if="!plikZwalidowany"
                >Plik moze miec max 2MB</span
              >
            </md-field>
          </div>
        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

        <div style="text-align: center">
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            czyJestWTrybieEdycji
              ? "ZATWIERDŹ - EDYTOWANY WPIS"
              : "ZATWIERDŹ - UTWÓRZ WPIS"
          }}</md-button>
        </div>
      </md-card>
    </form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import minLength from "vuelidate/lib/validators/minLength";
import { api } from "./../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_ubezpieczenia_maszyny",
  mixins: [validationMixin],
  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      Id: null,
      Nazwa_wybor_maszyny: null,
      Data_ubezpieczenia: null,
      Data_nastepnego_ubezpieczenia: null,
      Ubezpieczyciel: null,
      Nr_polisy: null,
      plik_PDF: null,
      plik_dane: null,
    },
    IdEdytowanegoElementu: null,
    nazwaPliku: null,
    userSaved: false,
    sending: false,
    lastUser: null,
    Nazwy_maszyn: [],
  }),

  async created() {
    try {
      const [odpowiedz_Nazwa_maszyny] = await Promise.all([
        api.pobieranieMaszynDozorowych(),
      ]);
      odpowiedz_Nazwa_maszyny,
        (this.Nazwy_maszyn = odpowiedz_Nazwa_maszyny.data.dane.map((el) => ({
          ...el,
          nazwa: `Id maszyny: ${el.Id_maszyny} ; Maszyna: ${el.Nazwa_maszyny}`,
        })));

      if (this.obiektKonfiguracyjny.akcja === "edycja") {
        //edycja

        const odpowiedz = await api.pobieranieMaszynDozorowychUbezpieczeniaEdycja(
          this.obiektKonfiguracyjny.Id
        );

        const {
          Id,
          Id_maszyny,
          Data_ubezpieczenia,
          Data_nastepnego_ubezpieczenia,
          Ubezpieczyciel,
          Nr_polisy,
          Nazwa_zalacznika,
        } = odpowiedz.data.dane[0];
        this.IdEdytowanegoElementu = Id;
        this.form.Nazwa_wybor_maszyny = this.Nazwy_maszyn.find(
          ({ Id }) => Id == Id_maszyny
        ).nazwa;
        this.form.Data_ubezpieczenia = Data_ubezpieczenia;
        this.form.Data_nastepnego_ubezpieczenia = Data_nastepnego_ubezpieczenia;
        this.form.Ubezpieczyciel = Ubezpieczyciel;
        this.form.Nr_polisy = Nr_polisy;
        this.nazwaPliku = Nazwa_zalacznika;
      }
    } catch (error) {
      console.log(error);
    }
  },

  validations: {
    form: {
      Nazwa_wybor_maszyny: {
        required,
      },
      Data_ubezpieczenia: {
        required,
      },
      Data_nastepnego_ubezpieczenia: {
        required,
      },
      Ubezpieczyciel: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(250),
      },
      Nr_polisy: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(50),
      },
    },
  },
  computed: {
    Id_maszyny: function() {
      const { Nazwa_wybor_maszyny } = this.form;
      const znalezionyElement = this.Nazwy_maszyn.find(
        ({ nazwa }) => nazwa === Nazwa_wybor_maszyny
      );

      if (znalezionyElement) {
        return znalezionyElement.Id;
      }
      return null;
    },
    zapytaniaMaszyny: function() {
      return this.Nazwy_maszyn.map((el) => el.nazwa);
    },
    plikZwalidowany: function() {
      return this.form.plik_dane
        ? this.form.plik_dane.size < 2 * 1024 * 1024
        : true;
    },
    czyJestWTrybieEdycji: function() {
      return this.obiektKonfiguracyjny.akcja === "edycja";
    },
  },

  methods: {
    zapamietajPlik(pliki) {
      if (pliki[0]) {
        this.form.plik_dane = pliki[0];
      }
    },
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.Id = null;
      this.form.Data_ubezpieczenia = null;
      this.form.Data_nastepnego_ubezpieczenia = null;
      this.form.Nr_polisy = null;
      this.form.Ubezpieczyciel = null;
      this.form.plik_PDF = null;
      this.form.plik_dane = null;
      this.nazwaPliku = null;
    },

    zapiszFormularz() {
      this.sending = true;

      if (this.czyJestWTrybieEdycji) {
        const dane = new FormData(); // dane typu FormData;

        dane.append("Data_ubezpieczenia", this.form.Data_ubezpieczenia);
        dane.append(
          "Data_nastepnego_ubezpieczenia",
          this.form.Data_nastepnego_ubezpieczenia
        );
        dane.append("Ubezpieczyciel", this.form.Ubezpieczyciel);
        dane.append("Nr_polisy", this.form.Nr_polisy);
        dane.append("plik_PDF", this.form.plik_dane);

        api
          .zapiszMaszyneDozorowaUbezpieczeniaUpdate(
            dane,
            this.IdEdytowanegoElementu
          )
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const dane = new FormData();
        dane.append("Id_maszyny", this.Id_maszyny);
        dane.append("Data_ubezpieczenia", this.form.Data_ubezpieczenia);
        dane.append(
          "Data_nastepnego_ubezpieczenia",
          this.form.Data_nastepnego_ubezpieczenia
        );
        dane.append("Ubezpieczyciel", this.form.Ubezpieczyciel);
        dane.append("Nr_polisy", this.form.Nr_polisy);
        dane.append("plik_PDF", this.form.plik_dane);

        api
          .zapiszMaszyneDozorUbezpieczenia(dane)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    naszWalidacjaFormularza() {
      if (this.czyJestWTrybieEdycji) {
        return (
          !this.$v.form.Nazwa_wybor_maszyny.$invalid &&
          !this.$v.form.Data_ubezpieczenia.$invalid &&
          !this.$v.form.Data_nastepnego_ubezpieczenia.$invalid &&
          !this.$v.form.Ubezpieczyciel.$invalid &&
          !this.$v.form.Nr_polisy.$invalid &&
          this.plikZwalidowany
        );
      } else {
        return !this.$v.$invalid && this.plikZwalidowany;
      }
    },

    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>

<style>
.Form_MD_ubezpiecz .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.Form_MD_ubezpiecz .md-layout .md-gutter {
  min-width: 55vw !important; /* szerokość modala*/
  max-width: 100% !important;
  min-height: 7vh; /* wysokość pomiędzy mierszami w modalu*/
}
.md-layout {
  padding-right: 0px !important;
}
.Form_MD_ubezpiecz .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}

.Form_MD_ubezpiecz .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  margin: 10px 24px !important;
  padding-top: 35px !important;
}
.Form_MD_ubezpiecz .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}
.Form_MD_ubezpiecz {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
  overflow: auto !important;
}
.Form_MD_ubezpiecz .md-primary {
  border: 1px solid !important;

  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}
.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important; /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}

.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}
.Form_MD_ubezpiecz .md-list-item-text {
  font-size: 1.7vmin !important;
}
.Form_MD_ubezpiecz .md-card-actions .md-button {
  margin-right: 50px !important;
}
</style>
