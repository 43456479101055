<template>
  <div class="FORM_NIP">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{ "PODAJ NUMER NIP FIRMY" }}
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('Numer_NIP')">
                <label for="Numer_NIP"></label>
                <md-input
                  style=" border:1px solid;text-align:center"
                  name="Numer_NIP"
                  id="Numer_NIP"
                  md-counter="10"
                  v-model="form.Numer_NIP"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Numer_NIP.required"
                  >Pole jest wymagane</span
                >
                <span class="md-error" v-else-if="!$v.form.Numer_NIP.maxlength"
                  >Wymagana ilość znaków od 1 do 10</span
                >
              </md-field>
            </div>
          </div>

          <p
            style="font-size:1.1vh; color: black; margin-top:30px; text-align:center; font-weight:500"
          >
            Numer NIP nalezy podać bez znaków specjalnych<br />
            w układzie 0001112233
          </p>
        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

        <div style="text-align: center">
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            "ZATWIERDŹ"
          }}</md-button>
        </div>
      </md-card>
    </form>
  </div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import minLength from "vuelidate/lib/validators/minLength";
import { api } from "./../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_maszyny",

  data: () => ({
    form: {
      Numer_NIP: null,
    },
    userSaved: false,
    sending: false,
    lastUser: null,
    Firmy: [],
  }),
  created: {},
  validations: {
    form: {
      Numer_NIP: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(10),
      },
    },
  },

  methods: {
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.Numer_NIP = null;
    },

    zapiszFormularz() {
      this.sending = true;

      /// 140 this.ru
      api
        .pobieranieFirmy(this.form.Numer_NIP)
        .then(() => {
          this.clearForm();
          this.sending = false;
          this.$router.push('/strona_logowania')
        })
        .catch((error) => {
          console.log(error);
        });
    },
 
  walidujFormularz() {
    this.$v.$touch();

    this.zapiszFormularz();
  },
  },
};
</script>

<style>
.FORM_NIP .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
.FORM_NIP .md-layout .md-gutter {
  min-width: 15vw !important; /* szerokość modala*/
  max-width: 100% !important;
  min-height: 7vh; /* wysokość pomiędzy mierszami w modalu*/
}
.md-layout {
  padding-right: 0px !important;
}
.FORM_NIP .M-Tytul {
  min-width: 15vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  text-align: center;
  color: blue;
}

.FORM_NIP .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  margin: 10px 24px !important;
  padding-top: 35px !important;
}
.FORM_NIP .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
}

.FORM_NIP {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
  overflow: auto;
}
.FORM_NIP .md-primary {
  border: 1px solid !important;

  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}
.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important; /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}

.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}
.md-list-item-text {
  font-size: 1.7vmin !important;
}
.FORM_NIP .md-card-actions .md-button {
  margin-right: 4vw !important;
}
</style>
