<template>
  <div class="Form_PRAC">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI DANYCH PRACOWNIKA"
                : "FORMULARZ DODAWANIA DANYCH PRACOWNIKA"
            }}
          </div>
        </md-card-header>

        <md-card-content>
          <div  v-if="
              form.Id_stosunek_pracy !== '2'"
          class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M4"
                :class="getValidationClass('Id_stosunek_pracy')"
              >
                <label for="Id_stosunek_pracy">Wybierz stosunek pracy</label>

                <md-select
                  v-model="form.Id_stosunek_pracy"
                  :disabled="sending"
                  
                  name="Id_stosunek_pracy"
                  id="Id_stosunek_pracy"
                  autocomplete="Id_stosunek_pracy"
                  md-dense
                >
                  <md-option
                    v-for="(stosunek, index) in Stosunki"
                    :key="index"
                    :value="stosunek.Id"
                    readonly
                    >{{ ` ${stosunek.Stosunek_pracy} ` }}</md-option
                  >
                </md-select>
                <span
                  class="md-error"
                  v-if="!$v.form.Id_stosunek_pracy.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('Nazwisko')">
                <label for="Nazwisko">Nazwisko</label>
                <md-input
                  name="Nazwisko"
                  id="Nazwisko"
                  autocomplete="Nazwisko"
                  md-counter="50"
                  v-model="form.Nazwisko"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Nazwisko.required"
                  >Pole jest wymagane</span
                >
                <span class="md-error" v-else-if="!$v.form.Nazwisko.maxlength"
                  >Wymagana ilość znaków od 1 do 50</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('Imie1')">
                <label for="Imie1">Imię - pierwsze</label>
                <md-input
                  name="Imie1"
                  id="Imie1"
                  md-counter="20"
                  autocomplete="Imie1"
                  v-model="form.Imie1"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Imie1.required"
                  >Pole jest wymagane</span
                >
                <span class="md-error" v-else-if="!$v.form.Imie1.maxlength"
                  >Wymagana ilość znaków od 1 do 20</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('Imie2')">
                <label for="Imie2">Imię - drugie</label>
                <md-input
                  name="Imie2"
                  id="Imie2"
                  md-counter="20"
                  autocomplete="Imie2"
                  v-model="form.Imie2"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Imie2.required"
                  >Pole jest wymagane</span
                >
                <span class="md-error" v-else-if="!$v.form.Imie2.maxlength"
                  >Wymagana ilość znaków od 1 do 20</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
             <div class="md-layout-item md-small-size-100">
              <md-field class="M4" :class="getValidationClass('Id_NIP')">
                <label for="Id_NIP">Wpisać NIP?</label>

                <md-select
                  v-model="form.Id_NIP"
                  :disabled="sending"
                  name="Id_NIP"
                  id="Id_NIP"
                  autocomplete="Id_NIP"
                  md-dense
                >
                  <md-option
                    v-for="(nip, index) in NIPY"
                    :key="index"
                    :value="nip.Id"
                    readonly
                    >{{ ` ${nip.Wybor} ` }}</md-option
                  >
                </md-select>
                <span class="md-error" v-if="!$v.form.Id_NIP.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div v-if="
              form.Id_NIP !== '2' && form.Id_NIP !== null
            "
            
            class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('NIP')">
                <label for="NIP">Numer NIP</label>
                <md-input
                  name="NIP"
                  id="NIP"
                  md-counter="13"
                  autocomplete="NIP"
                  v-model="form.NIP"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.NIP.required"
                  >Pole jest wymagane</span
                >
                <span class="md-error" v-else-if="!$v.form.NIP.maxlength"
                  >Wymagana ilość znaków do 13</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('Pesel')">
                <label for="Pesel">Numer PESEL</label>
                <md-input
                  name="Pesel"
                  id="Pesel"
                  md-counter="11"
                  autocomplete="Pesel"
                  v-model="form.Pesel"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Pesel.required"
                  >Pole jest wymagane</span
                >
                <span class="md-error" v-else-if="!$v.form.Pesel.maxlength"
                  >Wymagana ilość znaków od 11 do 11</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M5"
                :class="getValidationClass('Data_urodzenia')"
              >
                <label for="Data_urodzenia">Data urodzenia</label>
                <md-input
                  type="date"
                  name="Data_urodzenia"
                  id="Data_urodzenia"
                  autocomplete="Data_urodzenia"
                  v-model="form.Data_urodzenia"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Data_urodzenia.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M5"
                :class="getValidationClass('Miejsce_urodzenia')"
              >
                <label for="Miejsce_urodzenia">Miejsce urodzenia</label>
                <md-input
                  name="Miejsce_urodzenia"
                  id="Miejsce_urodzenia"
                  md-counter="50"
                  autocomplete="Miejsce_urodzenia"
                  v-model="form.Miejsce_urodzenia"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Miejsce_urodzenia.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Miejsce_urodzenia.maxlength"
                  >Wymagana ilość znaków od 1 do 50</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('Imie_ojca')">
                <label for="Imie_ojca">Imie ojca</label>
                <md-input
                  name="Imie_ojca"
                  id="Imie_ojca"
                  md-counter="20"
                  autocomplete="Imie_ojca"
                  v-model="form.Imie_ojca"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Imie_ojca.required"
                  >Pole jest wymagane</span
                >
                <span class="md-error" v-else-if="!$v.form.Imie_ojca.maxlength"
                  >Wymagana ilość znaków od 1 do 20</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('Imie_matki')">
                <label for="Imie_matki">Imie matki</label>
                <md-input
                  name="Imie_matki"
                  id="Imie_matki"
                  md-counter="20"
                  autocomplete="Imie_matki"
                  v-model="form.Imie_matki"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Imie_matki.required"
                  >Pole jest wymagane</span
                >
                <span class="md-error" v-else-if="!$v.form.Imie_matki.maxlength"
                  >Wymagana ilość znaków od 1 do 20</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field class="M4" :class="getValidationClass('Id_plec')">
                <label for="Id_plec">Płeć</label>

                <md-select
                  v-model="form.Id_plec"
                  :disabled="sending"
                  name="Id_plec"
                  id="Id_plec"
                  autocomplete="Id_plec"
                  md-dense
                >
                  <md-option
                    v-for="(plec, index) in Plcie"
                    :key="index"
                    :value="plec.Id"
                    readonly
                    >{{ ` ${plec.Rodzaj_plci} ` }}</md-option
                  >
                </md-select>
                <span class="md-error" v-if="!$v.form.Id_plec.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

          <div
            v-if="!czyJestWTrybieEdycji"
            style="margin-top: 20px; margin-left: 22px; font-weight: bold"
          >
            ADRES ZAMIESZKANIA
          </div>
          <div v-if="!czyJestWTrybieEdycji" class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('Kod')">
                <label for="Kod">Kod</label>
                <md-input
                  name="Kod"
                  id="Kod"
                  autocomplete="Kod"
                  md-counter="6"
                  v-model="form.Kod"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Kod.required"
                  >Pole jest wymagane</span
                >
                <span class="md-error" v-else-if="!$v.form.Kod.maxlength"
                  >Wymagana ilość znaków od 5 do 6</span
                >
              </md-field>
            </div>

            <div
              v-if="!czyJestWTrybieEdycji"
              class="md-layout-item md-small-size-100"
            >
              <md-field class="M5" :class="getValidationClass('Miejscowosc')">
                <label for="Miejscowosc">Miejscowość</label>
                <md-input
                  name="Miejscowosc"
                  id="Miejscowosc"
                  autocomplete="Miejscowosc"
                  md-counter="50"
                  v-model="form.Miejscowosc"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Miejscowosc.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Miejscowosc.maxlength"
                  >Wymagana ilość znaków od 1 do 50</span
                >
              </md-field>
            </div>

            <div
              v-if="!czyJestWTrybieEdycji"
              class="md-layout-item md-small-size-100"
            >
              <md-field class="M5" :class="getValidationClass('Ulica')">
                <label for="Ulica">Ulica</label>
                <md-input
                  name="Ulica"
                  id="Ulica"
                  autocomplete="Ulica"
                  v-model="form.Ulica"
                  md-counter="50"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Ulica.required"
                  >Pole jest wymagane</span
                >
                <span class="md-error" v-else-if="!$v.form.Ulica.maxlength"
                  >Wymagana ilość znaków od 1 do 50</span
                >
              </md-field>
            </div>

            <div
              v-if="!czyJestWTrybieEdycji"
              class="md-layout-item md-small-size-100"
            >
              <md-field class="M5" :class="getValidationClass('Nr')">
                <label for="Nr">Nr</label>
                <md-input
                  name="Nr"
                  id="Nr"
                  autocomplete="Nr"
                  v-model="form.Nr"
                  md-counter="20"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Nr.required"
                  >Pole jest wymagane</span
                >
                <span class="md-error" v-else-if="!$v.form.Nr.maxlength"
                  >Wymagana ilość znaków od 1 do 20</span
                >
              </md-field>
            </div>
          </div>

          <div v-if="!czyJestWTrybieEdycji" class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M4"
                :class="getValidationClass('Id_wojewodztwo')"
              >
                <label for="Id_wojewodztwo">Województwo</label>

                <md-select
                  v-model="form.Id_wojewodztwo"
                  :disabled="sending || czyJestWTrybieEdycji"
                  name="Id_wojewodztwo"
                  id="Id_wojewodztwo"
                  autocomplete="Id_wojewodztwo"
                  md-dense
                >
                  <md-option
                    v-for="(wojewodztwo, index) in Wojewodztwa"
                    :key="index"
                    :value="wojewodztwo.Id"
                    readonly
                    >{{ ` ${wojewodztwo.Wojewodztwa} ` }}</md-option
                  >
                </md-select>
                <span class="md-error" v-if="!$v.form.Id_wojewodztwo.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

          <div
            v-if="!czyJestWTrybieEdycji"
            style="margin-top: 20px; margin-left: 22px; font-weight: bold"
          >
            ADRES DO KORESPONDENCJI
          </div>
          <div v-if="!czyJestWTrybieEdycji" class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('Kod1')">
                <label for="Kod1">Kod</label>
                <md-input
                  name="Kod1"
                  id="Kod1"
                  autocomplete="Kod1"
                  md-counter="6"
                  v-model="form.Kod1"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Kod1.required"
                  >Pole jest wymagane</span
                >
                <span class="md-error" v-else-if="!$v.form.Kod1.maxlength"
                  >Wymagana ilość znaków od 5 do 6</span
                >
              </md-field>
            </div>

            <div
              v-if="!czyJestWTrybieEdycji"
              class="md-layout-item md-small-size-100"
            >
              <md-field class="M5" :class="getValidationClass('Miejscowosc1')">
                <label for="Miejscowosc1">Miejscowość</label>
                <md-input
                  name="Miejscowosc1"
                  id="Miejscowosc1"
                  autocomplete="Miejscowosc1"
                  md-counter="50"
                  v-model="form.Miejscowosc1"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Miejscowosc1.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Miejscowosc1.maxlength"
                  >Wymagana ilość znaków od 1 do 50</span
                >
              </md-field>
            </div>

            <div
              v-if="!czyJestWTrybieEdycji"
              class="md-layout-item md-small-size-100"
            >
              <md-field class="M5" :class="getValidationClass('Ulica1')">
                <label for="Ulica1">Ulica</label>
                <md-input
                  name="Ulica1"
                  id="Ulica1"
                  autocomplete="Ulica1"
                  v-model="form.Ulica1"
                  md-counter="50"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Ulica1.required"
                  >Pole jest wymagane</span
                >
                <span class="md-error" v-else-if="!$v.form.Ulica1.maxlength"
                  >Wymagana ilość znaków od 1 do 50</span
                >
              </md-field>
            </div>

            <div
              v-if="!czyJestWTrybieEdycji"
              class="md-layout-item md-small-size-100"
            >
              <md-field class="M5" :class="getValidationClass('Nr1')">
                <label for="Nr1">Nr</label>
                <md-input
                  name="Nr1"
                  id="Nr1"
                  autocomplete="Nr1"
                  v-model="form.Nr1"
                  md-counter="20"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Nr1.required"
                  >Pole jest wymagane</span
                >
                <span class="md-error" v-else-if="!$v.form.Nr1.maxlength"
                  >Wymagana ilość znaków od 1 do 20</span
                >
              </md-field>
            </div>
          </div>
          
          <div v-if="!czyJestWTrybieEdycji" class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M4"
                :class="getValidationClass('Id_wojewodztwo1')"
              >
                <label for="Id_wojewodztwo1">Województwo</label>

                <md-select
                  v-model="form.Id_wojewodztwo1"
                  :disabled="sending || czyJestWTrybieEdycji"
                  name="Id_wojewodztwo1"
                  id="Id_wojewodztwo1"
                  autocomplete="Id_wojewodztwo1"
                  md-dense
                >
                  <md-option
                    v-for="(wojewodztwo, index) in Wojewodztwa"
                    :key="index"
                    :value="wojewodztwo.Id"
                    readonly
                    >{{ ` ${wojewodztwo.Wojewodztwa} ` }}</md-option
                  >
                </md-select>
                <span class="md-error" v-if="!$v.form.Id_wojewodztwo1.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

          <div
            v-if="!czyJestWTrybieEdycji"
            style="margin-top: 20px; margin-left: 22px; font-weight: bold"
          >
            DANE DOTYCZĄCE ZATRUDNIENIA
          </div>
          <div v-if="!czyJestWTrybieEdycji" class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M5"
                :class="getValidationClass('Data_zatrudnienia')"
              >
                <label for="Data_zatrudnienia">Data zatrudnienia</label>
                <md-input
                  type="date"
                  name="Data_zatrudnienia"
                  id="Data_zatrudnienia"
                  autocomplete="Data_zatrudnienia"
                  v-model="form.Data_zatrudnienia"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Data_zatrudnienia.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div
              v-if="!czyJestWTrybieEdycji"
              class="md-layout-item md-small-size-100"
            >
              <md-field
                class="M4"
                :class="getValidationClass('Id_rodzaj_umowy')"
              >
                <label for="Id_rodzaj_umowy">Rodzaj zatrudnienia</label>

                <md-select
                  v-model="form.Id_rodzaj_umowy"
                  :disabled="sending || czyJestWTrybieEdycji"
                  name="Id_rodzaj_umowy"
                  id="Id_rodzaj_umowy"
                  autocomplete="Id_rodzaj_umowy"
                  md-dense
                >
                  <md-option
                    v-for="(rodzaj_umowy, index) in Rodzaj_umow"
                    :key="index"
                    :value="rodzaj_umowy.Id"
                    readonly
                    >{{ ` ${rodzaj_umowy.Rodzaj_zatrudnienia} ` }}</md-option
                  >
                </md-select>
                <span class="md-error" v-if="!$v.form.Id_rodzaj_umowy.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div
              v-if="!czyJestWTrybieEdycji"
              class="md-layout-item md-small-size-100"
            >
              <md-field
                class="M4"
                :class="getValidationClass('Id_czas_zatrudnienia')"
              >
                <label for="Id_czas_zatrudnienia">Czas zatrudnienia</label>

                <md-select
                  v-model="form.Id_czas_zatrudnienia"
                  :disabled="sending || czyJestWTrybieEdycji"
                  name="Id_czas_zatrudnienia"
                  id="Id_czas_zatrudnienia"
                  autocomplete="Id_czas_zatrudnienia"
                  md-dense
                >
                  <md-option
                    v-for="(czas, index) in Czasy"
                    :key="index"
                    :value="czas.Id"
                    readonly
                    >{{ ` ${czas.Czas_zatrudnienia} ` }}</md-option
                  >
                </md-select>
                <span
                  class="md-error"
                  v-if="!$v.form.Id_czas_zatrudnienia.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div
              v-if="!czyJestWTrybieEdycji && form.Id_czas_zatrudnienia !== '1'"
              class="md-layout-item md-small-size-100"
            >
              <md-field :class="getValidationClass('Umowa_do')">
                <label for="Umowa_do">Data końca umowy</label>
                <md-input
                  type="date"
                  name="Umowa_do"
                  id="Umowa_do"
                  autocomplete="Umowa_do"
                  v-model="form.Umowa_do"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Umowa_do.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

          <div
            v-if="
              form.Id_stosunek_pracy !== '1' && form.Id_stosunek_pracy !== null
            "
            class="md-layout md-gutter"
          >
            <div class="md-layout-item md-small-size-100">
              <div
                style="text-align: center; font-weight: bold; color: red; font-size:1.5vh; margin-top:30px"
              >
                UWAGA ZWOLNIENIE PRACOWNIKA JEST OPCJĄ NIEODWRACALNĄ.<br />
                PO JEJ ZATWIERDZENIU ZABLOKOWANA JAST MOLIWOŚĆ POWROTU 
              </div>
            </div>
          </div>

          <div
            v-if="
              form.Id_stosunek_pracy !== '1' && form.Id_stosunek_pracy !== null
            "
            class="md-layout md-gutter"
          >
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M5"
                :class="getValidationClass('Data_zwolnienia')"
              >
                <label for="Data_zwolnienia">Data zwolnienia</label>
                <md-input
                  type="date"
                  name="Data_zwolnienia"
                  id="Data_zwolnienia"
                  autocomplete="Data_zwolnienia"
                  v-model="form.Data_zwolnienia"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Data_zwolnienia.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div
              v-if="
                form.Id_stosunek_pracy !== '1' &&
                  form.Id_stosunek_pracy !== null
              "
              class="md-layout-item md-small-size-100"
            >
              <md-field class="M5" :class="getValidationClass('Przyczyna')">
                <label for="Przyczyna">Przyczyna zwolnienia</label>
                <md-input
                  name="Przyczyna"
                  id="Przyczyna"
                  md-counter="300"
                  autocomplete="Przyczyna"
                  v-model="form.Przyczyna"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Przyczyna.required"
                  >Pole jest wymagane</span
                >
                <span class="md-error" v-else-if="!$v.form.Przyczyna.maxlength"
                  >Wymagana ilość znaków od 1 do 300</span
                >
              </md-field>
            </div>
          </div>
        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

        <div style="text-align: center">
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            czyJestWTrybieEdycji
              ? "ZATWIERDŹ - EDYTOWANY WPIS"
              : "ZATWIERDŹ - UTWÓRZ WPIS"
          }}</md-button>
        </div>
      </md-card>
    </form>
  </div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import minLength from "vuelidate/lib/validators/minLength";
import { api } from "../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_maszyny",

  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      Id_stosunek_pracy: null,
      Nazwisko: null,
      Imie1: null,
      Imie2: null,
      Id_NIP: null,
      NIP: null,
      Pesel: null,
      Data_urodzenia: null,
      Miejsce_urodzenia: null,
      Imie_ojca: null,
      Imie_matki: null,
      Id_wojewodztwo: null,
      Id_wojewodztwo1: null,
      Id_rodzaj_umowy: null,
      Id_czas_zatrudnienia: null,
      Id_plec: null,
      Kod: null,
      Miejscowosc: null,
      Ulica: null,
      Nr: null,
      Kod1: null,
      Miejscowosc1: null,
      Ulica1: null,
      Nr1: null,
      Data_zatrudnienia: null,
      Umowa_do: null,
      Data_zwolnienia: null,
      Przyczyna: "",
    },
    IdEdytowanegoElementu: null,
    userSaved: false,
    sending: false,
    lastUser: null,
    Wojewodztwa: [],
    Rodzaj_umow: [],
    Czasy: [],
    Plcie: [],
    Stosunki: [],
    NIPY:[],
  }),
  created: function() {
    if (this.obiektKonfiguracyjny.akcja === "edycja") {
      //edycja
      api
        .pobieranieDaneZatrudnieniaPracownicyEdycja(
          this.obiektKonfiguracyjny.Id
        )
        .then((odpowiedz) => {
          const {
            Id,
            Id_stosunek_pracy,
            Nazwisko,
            Imie1,
            Imie2,
            Id_NIP,
            NIP,
            Pesel,
            Data_urodzenia,
            Miejsce_urodzenia,
            Imie_ojca,
            Imie_matki,
            Id_plec,
            Data_zwolnienia,
            Przyczyna,
          } = odpowiedz.data.dane[0];
          this.IdEdytowanegoElementu = Id;
          this.form.Id_stosunek_pracy = Id_stosunek_pracy;
          this.form.Nazwisko = Nazwisko;
          this.form.Imie1 = Imie1;
          this.form.Imie2 = Imie2;
          this.form.Id_NIP = Id_NIP;
          this.form.NIP = NIP;
          this.form.Pesel = Pesel;
          this.form.Data_urodzenia = Data_urodzenia;
          this.form.Miejsce_urodzenia = Miejsce_urodzenia;
          this.form.Imie_ojca = Imie_ojca;
          this.form.Imie_matki = Imie_matki;
          this.form.Id_plec = Id_plec;
          this.form.Data_zwolnienia = Data_zwolnienia;
          this.form.Przyczyna = Przyczyna;
        })
        .catch((error) => {
          console.log(error);
        });
    }

    Promise.all([
      api.ponierzStosunekPracy(),
      api.pobierzWojewodztwo(),
      api.pobierzRodzajUmowy(),
      api.pobierzCzasZatrudnienia(),
      api.pobierzPlec(),
      api.PobierzCzyJestNIP(),
    ])
      .then(
        ([
          odpowiedz_stosunek,
          odpowiedz_wojewodztwo,
          odpowiedz_rodzaj_umowy,
          odpowiedz_czas_zatrudnienia,
          odpowiedz_plec,
          odpowiedz_NIP,
        ]) => {
          this.Stosunki = odpowiedz_stosunek.data.dane;
          this.Wojewodztwa = odpowiedz_wojewodztwo.data.dane;
          this.Rodzaj_umow = odpowiedz_rodzaj_umowy.data.dane;
          this.Czasy = odpowiedz_czas_zatrudnienia.data.dane;
          this.Plcie = odpowiedz_plec.data.dane;
          this.NIPY = odpowiedz_NIP.data.dane;
        }
      )
      .catch((error) => {
        console.log(error);
      });
  },
  validations: {
    form: {
      Id_stosunek_pracy: {
        required,
      },
      Nazwisko: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(50),
      },
      Imie1: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(20),
      },
      Imie2: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(20),
      },
      Id_NIP: {
        required,
      },
      NIP: {
        required: false,
        maxLength: maxLength(13),
      },
      Pesel: {
        required,
        minLenght: minLength(11),
        maxLength: maxLength(11),
      },
      Data_urodzenia: {
        required,
      },
      Miejsce_urodzenia: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(50),
      },
      Imie_ojca: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(20),
      },
      Imie_matki: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(20),
      },
      Id_wojewodztwo: {
        required,
      },
      Id_wojewodztwo1: {
        required,
      },
      Id_rodzaj_umowy: {
        required,
      },
      Id_czas_zatrudnienia: {
        required,
      },
      Id_plec: {
        required,
      },
      Kod: {
        required,
        minLenght: minLength(5),
        maxLength: maxLength(6),
      },
      Miejscowosc: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(50),
      },
      Ulica: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(50),
      },
      Nr: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(20),
      },
      Kod1: {
        required,
        minLenght: minLength(5),
        maxLength: maxLength(6),
      },
      Miejscowosc1: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(50),
      },
      Ulica1: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(50),
      },
      Nr1: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(20),
      },
      Data_zatrudnienia: {
        required,
      },
      Umowa_do: {
        required: false,
      },
      Data_zwolnienia: {
        required: false,
      },
      Przyczyna: {
        required: false,
        minLenght: minLength(1),
        maxLength: maxLength(300),
      },
    },
  },
  computed: {
    czyJestWTrybieEdycji: function() {
      return this.obiektKonfiguracyjny.akcja === "edycja";
    },
  },

  methods: {
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.Id_stosunek_pracy = null;
      this.form.Nazwisko = null;
      this.form.Imie1 = null;
      this.form.Imie2 = null;
      this.form.Id_NIP = null;
      this.form.NIP = null;
      this.form.Pesel = null;
      this.form.Data_urodzenia = null;
      this.form.Miejsce_urodzenia = null;
      this.form.Imie_ojca = null;
      this.form.Imie_matki = null;
      this.form.Id_wojewodztwo = null;
      this.form.Id_wojewodztwo1 = null;
      this.form.Id_rodzaj_umowy = null;
      this.form.Id_czas_zatrudnienia = null;
      this.form.Id_plec = null;
      this.form.Kod = null;
      this.form.Miejscowosc = null;
      this.form.Ulica = null;
      this.form.Nr = null;
      this.form.Kod1 = null;
      this.form.Miejscowosc1 = null;
      this.form.Ulica1 = null;
      this.form.Nr1 = null;
      this.form.Data_zatrudnienia = null;
      this.form.Umowa_do = null;
      this.form.Data_zwolnienia = null;
      this.form.Przyczyna = null;
    },

    zapiszFormularz() {
      this.sending = true;

      if (this.czyJestWTrybieEdycji) {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Id_stosunek_pracy", this.form.Id_stosunek_pracy);
        dane.append("Nazwisko", this.form.Nazwisko);
        dane.append("Imie1", this.form.Imie1);
        dane.append("Imie2", this.form.Imie2);
        dane.append("Id_NIP", this.form.Id_NIP);
        dane.append("NIP", this.form.NIP);
        dane.append("Pesel", this.form.Pesel);
        dane.append("Data_urodzenia", this.form.Data_urodzenia);
        dane.append("Miejsce_urodzenia", this.form.Miejsce_urodzenia);
        dane.append("Imie_ojca", this.form.Imie_ojca);
        dane.append("Imie_matki", this.form.Imie_matki);
        dane.append("Data_zwolnienia", this.form.Data_zwolnienia);
        dane.append("Przyczyna", this.form.Przyczyna);
        // dane.append("Id_wojewodztwo", this.form.Id_wojewodztwo);
        // dane.append("Id_wojewodztwo1", this.form.Id_wojewodztwo1);
        // dane.append("Id_rodzaj_umowy", this.form.Id_rodzaj_umowy);
        // dane.append("Id_czas_zatrudnienia", this.form.Id_czas_zatrudnienia);
        dane.append("Id_plec", this.form.Id_plec);
        // dane.append("Kod", this.form.Kod);
        // dane.append("Miejscowosc", this.form.Miejscowosc);
        // dane.append("Ulica", this.form.Ulica);
        // dane.append("Nr", this.form.Nr);
        // dane.append("Kod1", this.form.Kod1);
        // dane.append("Miejscowosc1", this.form.Miejscowosc1);
        // dane.append("Ulica1", this.form.Ulica1);
        // dane.append("Nr1", this.form.Nr1);
        // dane.append("Data_zatrudnienia", this.form.Data_zatrudnienia);
        // dane.append("Umowa_do", this.form.Umowa_do);

        api
          .zapiszDaneZatrudnieniaPracownicyUpdate(
            dane,
            this.IdEdytowanegoElementu
          )
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Id_stosunek_pracy", this.form.Id_stosunek_pracy);
        dane.append("Nazwisko", this.form.Nazwisko);
        dane.append("Imie1", this.form.Imie1);
        dane.append("Imie2", this.form.Imie2);
        dane.append("Id_NIP", this.form.Id_NIP);
        dane.append("NIP", this.form.NIP);
        dane.append("Pesel", this.form.Pesel);
        dane.append("Data_urodzenia", this.form.Data_urodzenia);
        dane.append("Miejsce_urodzenia", this.form.Miejsce_urodzenia);
        dane.append("Imie_ojca", this.form.Imie_ojca);
        dane.append("Imie_matki", this.form.Imie_matki);
        dane.append("Id_wojewodztwo", this.form.Id_wojewodztwo);
        dane.append("Id_wojewodztwo1", this.form.Id_wojewodztwo1);
        dane.append("Id_rodzaj_umowy", this.form.Id_rodzaj_umowy);
        dane.append("Id_czas_zatrudnienia", this.form.Id_czas_zatrudnienia);
        dane.append("Id_plec", this.form.Id_plec);
        dane.append("Kod", this.form.Kod);
        dane.append("Miejscowosc", this.form.Miejscowosc);
        dane.append("Ulica", this.form.Ulica);
        dane.append("Nr", this.form.Nr);
        dane.append("Kod1", this.form.Kod1);
        dane.append("Miejscowosc1", this.form.Miejscowosc1);
        dane.append("Ulica1", this.form.Ulica1);
        dane.append("Nr1", this.form.Nr1);
        dane.append("Data_zatrudnienia", this.form.Data_zatrudnienia);
        dane.append("Umowa_do", this.form.Umowa_do);
        dane.append("Data_zwolnienia", this.form.Data_zwolnienia);
        dane.append("Przyczyna", this.form.Przyczyna);

        api
          .zapiszPracownika(dane)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    naszWalidacjaFormularza() {
      if (this.czyJestWTrybieEdycji) {
        return (
          !this.$v.form.Id_stosunek_pracy.$invalid &&
          !this.$v.form.Nazwisko.$invalid &&
          !this.$v.form.Imie1.$invalid &&
          !this.$v.form.Imie2.$invalid &&
          !this.$v.form.Id_NIP.$invalid &&
          !this.$v.form.NIP.$invalid &&
          !this.$v.form.Pesel.$invalid &&
          !this.$v.form.Data_urodzenia.$invalid &&
          !this.$v.form.Miejsce_urodzenia.$invalid &&
          !this.$v.form.Imie_ojca.$invalid &&
          !this.$v.form.Imie_matki.$invalid &&
          !this.$v.form.Id_plec.$invalid &&
          !this.$v.form.Data_zwolnienia.$invalid &&
          !this.$v.form.Przyczyna.$invalid
        );
      } else {
        return !this.$v.$invalid;
      }
    },

    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>

<style>
.Form_PRAC .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.Form_PRAC .md-layout .md-gutter {
  min-width: 55vw !important; /* szerokość modala*/
  max-width: 100% !important;
  min-height: 7vh; /* wysokość pomiędzy mierszami w modalu*/
}
.md-layout {
  padding-right: 0px !important;
}
.Form_PRAC .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}

.Form_PRAC .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  margin: 10px 24px !important;
  padding-top: 35px !important;
}
.Form_PRAC .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 1.2vh !important;
  margin-left: 10px !important;
}

.Form_PRAC {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
  overflow: auto;
}
.Form_PRAC .md-primary {
  border: 1px solid !important;

  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}
.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important; /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}

.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}
.md-list-item-text {
  font-size: 1.7vmin !important;
}
.Form_PRAC .md-card-actions .md-button {
  margin-right: 4vw !important;
}
.Form_PRAC .md-field.md-has-textarea:not(.md-autogrow) label {
  top: 16px;
  left: 2px;
}
</style>
