<template>
  <div class="FORM_CHORZAWOCENA">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI PODJĘTYCH DZIAŁAŃ DO CHOROBY ZAWODOWEJ"
                : "FORMULARZ DODAWANIA PODJĘTYCH DZIAŁAŃ DO CHOROBY ZAWODOWEJ"
            }}
          </div>
        </md-card-header>

        <md-card-content>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-autocomplete
                :class="getValidationClass('Nazwa_wybor_pracownika')"
                v-model="form.Nazwa_wybor_pracownika"
                :md-options="zapytaniaPracownicy"
                :disabled="sending || czyJestWTrybieEdycji"
                readonly 
              >
                <label>Wybor pracownika</label>
                <span
                  class="md-error"
                  v-if="!$v.form.Nazwa_wybor_pracownika.required"
                  >Pole jest wymagane</span
                >
              </md-autocomplete>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-autocomplete
                :class="getValidationClass('Nazwa_wybor_stanowiska')"
                v-model="form.Nazwa_wybor_stanowiska"
                :md-options="zapytaniaStanowiska"
                :disabled="sending || czyJestWTrybieEdycji"
                readonly
              >
                <label>Wybor stanowiska</label>
                <span
                  class="md-error"
                  v-if="!$v.form.Nazwa_wybor_stanowiska.required"
                  >Pole jest wymagane</span
                >
              </md-autocomplete>
            </div>
            
             <div class="md-layout-item md-small-size-100"
            >
              <md-field
                class="M5"
                :class="getValidationClass('Data_skutki')"
              >
                <label for="Data_skutki">Data informacji o skutkach</label>
                <md-input
                  type="date"
                  name="Data_skutki"
                  id="Data_skutki"
                  autocomplete="Data_skutki"
                  v-model="form.Data_skutki"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Data_skutki.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>
            
           
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100"
            >
              <md-field
                class="M5"
                :class="getValidationClass('Skutki')"
              >
                <label for="Skutki">Skutki choroby</label>
                <md-textarea
                  
                  name="Skutki"
                  id="Skutki"
                  autocomplete="Skutki"
                  md-autogrow
                  md-counter="500"
                  v-model="form.Skutki"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Skutki.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Skutki.maxlength"
                  >Wymagana ilość znaków od 1 do 500</span
                >
              </md-field>
            </div>
          </div>

    

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M2"
                :class="getValidationClass('Poprawa')"
              >
                <label for="Poprawa">Opis działań poprawy warunków pracy</label>
                <md-textarea

                  name="Poprawa"
                  id="Poprawa"
                  
                  md-autogrow
                  md-counter="500"
                  v-model="form.Poprawa"
              
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Poprawa.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Poprawa.maxlength"
                  >Wymagana ilość znaków od 1 do 500</span
                >
              </md-field>
            </div>
          </div>

          
        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

        
          <div style="text-align: center">
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            czyJestWTrybieEdycji
              ? "ZATWIERDŹ - EDYTOWANY WPIS"
              : "ZATWIERDŹ - UTWÓRZ WPIS"
          }}</md-button>
        </div>
      </md-card>

    </form>
  </div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import minLength from "vuelidate/lib/validators/minLength";
import { api } from "../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_maszyny",

  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      Nazwa_wybor_pracownika: null,
      Nazwa_wybor_stanowiska: null,
      Data_stwierdzenia: null,
      Data_skutki: null,
      Skutki: null,
      Poprawa: null,


    },
    IdEdytowanegoElementu: null,
    userSaved: false,
    sending: false,
    lastUser: null,
    Pracownicy:[],
    Stanowiska:[],

  }),
  async created() {
    try {
      const [odpowiedz_Pracownik, odpowiedz_Stanowisko] = await Promise.all([
        api.pobierzPracownika(),
        api.pobierzStanowiskoPracy(),
      ]);
      odpowiedz_Pracownik,
        (this.Pracownicy = odpowiedz_Pracownik.data.dane.map((el) => ({
          ...el,
          nazwa: `${el.Nazwisko} ${el.Imie1} - NIP: ${el.NIP} ; PESEL: ${el.Pesel}`,
        })));

      odpowiedz_Stanowisko,
        (this.Stanowiska = odpowiedz_Stanowisko.data.dane.map((el) => ({
          ...el,
          nazwa1: `${el.Stanowisko}`,
        })));


      if (this.obiektKonfiguracyjny.akcja === "edycja") {
        //edycja
        const odpowiedz = await api.pobieranieDokumentacjaSKCHZEdycja(
          this.obiektKonfiguracyjny.Id
        );
        const {
          Id,
          Id_pracownika,
          Id_stanowiska,
          Data_skutki,
          Skutki,
          Poprawa,
        } = odpowiedz.data.dane[0];
        this.IdEdytowanegoElementu = Id;
        this.form.Nazwa_wybor_pracownika = this.Pracownicy.find(
          ({ Id }) => Id == Id_pracownika
        ).nazwa;
        this.form.Nazwa_wybor_stanowiska = this.Stanowiska.find(
          ({ Id }) => Id == Id_stanowiska
        ).nazwa1;
        this.form.Data_skutki = Data_skutki;
        this.form.Skutki = Skutki;
        this.form.Poprawa = Poprawa;

      }
    } catch (error) {
      console.log(error);
    }
  },
  validations: {
    form: {
      Nazwa_wybor_pracownika: {
        required,
      },
      Nazwa_wybor_stanowiska: {
        required,
      },
      Data_skutki: {
        required,
      },
      Skutki: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(500),
      },
      Poprawa: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(500),
      },
    },
  },
  computed: {
    
    Id_pracownika: function() {
      const { Nazwa_wybor_pracownika } = this.form;
      const znalezionyElement = this.Pracownicy.find(
        ({ nazwa }) => nazwa === Nazwa_wybor_pracownika
      );

      if (znalezionyElement) {
        return znalezionyElement.Id;
      }
      return null;
    },
    zapytaniaPracownicy: function() {
      return this.Pracownicy.map((el) => el.nazwa);
    },

    Id_stanowiska: function() {
      const { Nazwa_wybor_stanowiska } = this.form;
      const znalezionyElement = this.Stanowiska.find(
        ({ nazwa1 }) => nazwa1 === Nazwa_wybor_stanowiska
      );

      if (znalezionyElement) {
        return znalezionyElement.Id;
      }
      return null;
    },
    zapytaniaStanowiska: function() {
      return this.Stanowiska.map((el) => el.nazwa1);
    },
    czyJestWTrybieEdycji: function () {
      return this.obiektKonfiguracyjny.akcja === "edycja";
    },
  },

  methods: {

  
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.Id_pracownika = null;
      this.form.Id_stanowiska = null;
      this.form.Data_skutki = null;
      this.form.Skutki = null;
      this.form.Poprawa = null;
    },

    zapiszFormularz() {
      this.sending = true;

      if (this.czyJestWTrybieEdycji) {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Id_pracownika", this.Id_pracownika);
        dane.append("Id_stanowiska", this.Id_stanowiska);
        dane.append("Data_skutki", this.form.Data_skutki);
        dane.append("Skutki", this.form.Skutki);
        dane.append("Poprawa", this.form.Poprawa);

        api
          .zapiszDokumentacjaSKCHZUpdate(dane, this.IdEdytowanegoElementu)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Id_pracownika", this.Id_pracownika);
        dane.append("Id_stanowiska", this.Id_stanowiska);
        dane.append("Data_skutki", this.form.Data_skutki);
        dane.append("Skutki", this.form.Skutki);
        dane.append("Poprawa", this.form.Poprawa);

        api
          .zapiszDokumentacjaCHZAW(dane)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    naszWalidacjaFormularza() {
      if (this.czyJestWTrybieEdycji) {
        return (
          !this.$v.form.Nazwa_wybor_pracownika.$invalid &&
          !this.$v.form.Nazwa_wybor_stanowiska.$invalid &&
          !this.$v.form.Data_skutki.$invalid &&
          !this.$v.form.Skutki.$invalid &&
          !this.$v.form.Poprawa.$invalid 
        );
      } else {
        return !this.$v.$invalid 
      }
    },
    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>


<style>
.FORM_CHORZAWOCENA .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.FORM_CHORZAWOCENA .md-layout .md-gutter {
  min-width: 55vw !important; /* szerokość modala*/
  max-width: 100% !important;
  min-height: 7vh; /* wysokość pomiędzy mierszami w modalu*/
}
.md-layout {
  padding-right: 0px !important;
}
.FORM_CHORZAWOCENA .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}

.FORM_CHORZAWOCENA .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  margin: 10px 24px !important;
  padding-top: 35px !important;
}
.FORM_CHORZAWOCENA .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}
.FORM_CHORZAWOCENA {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
  overflow: auto;
}
.FORM_CHORZAWOCENA .md-primary {
  border: 1px solid !important;
  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}
.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important; /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}

.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}
.md-list-item-text {
  font-size: 1.7vmin !important;
}
.FORM_CHORZAWOCENA .md-card-actions .md-button {
  margin-right: 4vw !important;
}
</style>