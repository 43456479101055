<template >
  <div>
  <TabelaZakupyZrealizowane />
  </div>
</template>

<script>
import TabelaZakupyZrealizowane  from '../../komponenty/strona-tabela-komponenty/SO_zakupy/tabela_zakupy_zrealizowane.vue'


export default {
  name: 'StronaTabelaZakupyZrealizowane ',
  components: {
    TabelaZakupyZrealizowane ,
    
  }

}
</script>

