<template>
  <div class="Form_Oferta">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI OFERTY"
                : "FORMULARZ DODAWANIA OFERTY"
            }}
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-autocomplete :class="getValidationClass('Nazwa_zapytania_ofertowego')"
                v-model="form.Nazwa_zapytania_ofertowego"
                :md-options="zapytaniaWartosc"
                @md-selected="pobierzWyroby"
              >
                <label>Wybór zapytania</label>
                 <span class="md-error" v-if="!$v.form.Nazwa_zapytania_ofertowego.required"
                  >Pole jest wymagane</span
                >
              </md-autocomplete>
            </div>
          </div>

          <div class="md-layout md-gutter">
            
            <div class="md-layout-item md-small-size-100">
              <md-field class="M1" :class="getValidationClass('Data_oferty')">
                <label for="Data_oferty">Data oferty</label>
                <md-input
                  type="date"
                  name="Data_oferty"
                  id="Data_oferty"
                  
                  v-model="form.Data_oferty"
                  :disabled="sending"
                  @input="pobierzNumerOferty"
                  :readonly="czyJestWTrybieEdycji"
                />
                <span class="md-error" v-if="!$v.form.Data_oferty.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Data_oferty.maxlength"
                  >Wymagana ilość znaków od 3 do 100</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field class="M1" :class="getValidationClass('Numer_oferty')">
                <label for="Numer_oferty">Numer oferty</label>
                <md-input
                  name="Numer_oferty"
                  id="Numer_oferty"
                  
                  v-model="form.Numer_oferty"
                  :disabled="sending"
                  readonly
                />
                <span class="md-error" v-if="!$v.form.Numer_oferty.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field class="M1" :class="getValidationClass('Data_waznosci')">
                <label for="Data_waznosci">Data ważnosci oferty</label>
                <md-input
                  type="date"
                  name="Data_waznosci"
                  id="Data_waznosci"
                  
                  v-model="form.Data_waznosci"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Data_waznosci.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Data_waznosci.maxlength"
                  >Wymagana ilość znaków od 3 do 100</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('Id_status_oferty')">
                <label for="Id_status_oferty">Określ status oferty</label>

                <md-select
                  v-model="form.Id_status_oferty"
                  :disabled="sending"
                  name="Id_status_oferty"
                  id="Id_status_oferty"
                  autocomplete="Id_status_oferty"
                  md-dense
                >
                  <md-option
                    v-for="(status_oferty, index) in Statusy_ofert"
                    :key="index"
                    :value="status_oferty.Id"
                    >{{ ` ${status_oferty.Status_ofert} ` }}</md-option
                  >
                </md-select>
                <span class="md-error" v-if="!$v.form.Id_status_oferty.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M1"
                :class="getValidationClass('Osoba_odpowiedzialna')"
              >
                <label for="Osoba_odpowiedzialna">Osoba odpowiedzialna</label>
                <md-input
                  name="Osoba_odpowiedzialna"
                  id="Osoba_odpowiedzialna"
                  
                  md-counter="50"
                  v-model="form.Osoba_odpowiedzialna"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Osoba_odpowiedzialna.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Osoba_odpowiedzialna.maxlength"
                  >Wymagana ilość znaków od 1 do 50</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="plikZwalidowany ? '' : 'md-invalid'">
                <label
                  >Wstaw plik - oferta "max-2Mb"
                  <span v-if="nazwaPliku">
                    - obecnie wstawiony plik to {{ nazwaPliku }}</span
                  ></label
                >
                <md-file
                  v-model="form.plik_PDF"
                  @md-change="zapamietajPlik"
                  accept="application/pdf"
                />
                <span class="md-error" v-if="!plikZwalidowany"
                  >Plik moze miec max 2MB</span
                >
              </md-field>
            </div>
          </div>
          
          <div
            style="
              margin-top: 40px;
              margin-left: 25px;
              font-weight: bold;
              font-size: 16px;
            "
          >
            SZCZEGÓŁY OFERTY
          </div>
          <div
            style="
              margin-top: 10px;
              margin-left: 25px;
              font-weight: bold;
              font-size: 16px;
              color: red;
            "
          >
            WARTOŚĆ CAŁOŚCI OFERTY : {{ sumaWartosci }} zł.
          </div>
          <div
            v-for="(wiersz, index) in form.wiersze"
            :key="index"
            :set="(v = $v.form.wiersze.$each[index])"
          >
            <div
              style="
                color: blue;
                font-weight: bold;
                font-size: 1.5vh;
                margin-left: 25px;
                margin-top: 10px;
              "
            >
              {{ `POZYCJA NR: ${index + 1} ` }}

              <b-icon-plus-square
                class="ikony"
                font-scale="1.4"
                style="border: 1px black"
                color="black"
                @click="dodajWiersz()"
              >
              </b-icon-plus-square>

              <b-icon-trash
                class="ikony"
                font-scale="1.4"
                color="black"
                @click="usunWiersz(wiersz)"
              >
              </b-icon-trash>
            </div>

            <div class="md-layout md-gutter">
              <div class="md-layout-item md-small-size-100">
                <md-field :class="getValidationClass('Id_wyrobu',index)">
                  <label for="Id_wyrobu">Wybór wyrobu/usługi</label>

                  <md-select
                    v-model="wiersz.Id_wyrobu"
                    :disabled="sending"
                    name="Id_wyrobu"
                    id="Id_wyrobu"
                    autocomplete="Id_wyrobu"
                    md-dense
                    @md-selected="
                      UstalSzeczegolyWyrobu(wiersz.Id_wyrobu, index)
                    "
                  >
                    <md-option
                      v-for="(wyrob, index) in dostepneWyroby"
                      :key="index"
                      :value="wyrob.Id_wyrobu"
                      >{{ `${wyrob.Nazwa} - ilość: ${wyrob.Ilosc} ${wyrob.Jedn_sprzedazy}` }}
                    </md-option>
                  </md-select>
                  <span class="md-error" v-if="!v.Id_wyrobu.required"
                    >Pole jest wymagane</span
                  >
                </md-field>
              </div>
            </div>

            <div class="md-layout md-gutter">
              <div class="md-layout-item md-small-size-100">
                <md-field :class="getValidationClass('Id_jednostka',index)">
                  <label for="Id_jednostka">Jedn.</label>

                  <md-select
                    v-model="wiersz.Id_jednostka"
                    :disabled="sending"
                    name="Id_jednostka"
                    id="Id_jednostka"
                    autocomplete="Id_jednostka"
                    md-dense
                  >
                    <md-option
                      v-for="(jedn_sprzedazy, index) in Jednostki_sprzedazy"
                      :key="index"
                      :value="jedn_sprzedazy.Id"
                      >{{ jedn_sprzedazy.Jedn_sprzedazy }}
                    </md-option>
                  </md-select>
                  <span class="md-error" v-if="!v.Id_jednostka.required"
                    >Pole jest wymagane</span
                  >
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100">
                <md-field :class="getValidationClass('Ilosc',index)">
                  <label for="Ilosc">Ilość</label>
                  <md-input
                    style="text-align: right"
                    name="Ilosc"
                    id="Ilosc"
                    autocomplete="Ilosc"
                    @input="ustawCeneNetto(wiersz)"
                    v-model="wiersz.Ilosc"
                    :disabled="sending"
                  />
                  <span class="md-error" v-if="!v.Ilosc.required"
                    >Pole jest wymagane</span
                  >
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100">
                <md-field :class="getValidationClass('Cena_jednostkowa',index)">
                  <label for="Cena_jednostkowa">Cena jedn.</label>
                  <md-input
                    style="text-align: right"
                    name="Cena_jednostkowa"
                    id="Cena_jednostkowa"
                    autocomplete="Cena_jednostkowa"
                    @input="ustawCeneNetto(wiersz)"
                    v-model="wiersz.Cena_jednostkowa"
                    :disabled="sending"
                  />
                  <span class="md-error" v-if="!v.Cena_jednostkowa.required"
                    >Pole jest wymagane</span
                  >
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100">
                <md-field :class="getValidationClass('Wartosc_oferty',index)">
                  <label for="Wartosc_oferty">Wartość netto</label>
                  <md-input
                    style="text-align: right"
                    name="Wartosc_oferty"
                    id="Wartosc_oferty"
                    autocomplete="Wartosc_oferty"
                    v-model="wiersz.Wartosc_oferty"
                    :disabled="sending"
                    readonly
                  />
                  <span class="md-error" v-if="!v.Wartosc_oferty.required"
                    >Pole jest wymagane</span
                  >
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100">
                <md-field :class="getValidationClass('Id_status_uwag',index)">
                  <label for="Id_status_uwag">Czy chcesz wpisać uwagę</label>

                  <md-select
                    v-model="wiersz.Id_status_uwag"
                    :disabled="sending"
                    name="Id_status_uwag"
                    id="Id_status_uwag"
                    autocomplete="Id_status_uwag"
                    md-dense
                  >
                    <md-option
                      v-for="(status_uwag, index) in Statusy_uwag"
                      :key="index"
                      :value="status_uwag.Id"
                      >{{ status_uwag.Status_uwag }}
                    </md-option>
                  </md-select>
                  <span class="md-error" v-if="!v.Id_status_uwag.required"
                    >Pole jest wymagane</span
                  >
                </md-field>
              </div>
            </div>
            <div
              v-if="
                wiersz.Id_status_uwag !== '2' && wiersz.Id_status_uwag !== null
              "
              class="md-layout md-gutter"
            >
              <div class="md-layout-item md-small-size-100">
                <md-field :class="getValidationClass('Uwagi',index)">
                  <label for="Uwagi">Uwagi do pozycji</label>
                  <md-textarea
                    name="Uwagi"
                    id="Uwagi"
                    autocomplete="Uwagi"
                    md-autogrow
                    md-counter="1000"
                    v-model="wiersz.Uwagi"
                    :disabled="sending"
                  />
                  <span class="md-error" v-if="!v.Uwagi.required"
                    >Pole jest wymagane</span
                  >
                  <span class="md-error" v-else-if="!v.Uwagi.maxlength"
                    >Wymagana ilość znaków od 1 do 1000</span
                  >
                </md-field>
              </div>
            </div>
          </div>
        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

        <div style="text-align: center">
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            czyJestWTrybieEdycji
              ? "ZATWIERDŹ - EDYTOWANY WPIS"
              : "ZATWIERDŹ - UTWÓRZ WPIS"
          }}</md-button>
        </div>
      </md-card>
    </form>
  </div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import minLength from "vuelidate/lib/validators/minLength";
import { api } from "../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_faktury",

  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      Nazwa_zapytania_ofertowego: null,
      Numer_oferty: null,
      Data_oferty: null,
      Data_waznosci: null,
      Id_status_oferty: null,
      Status_ofert: null,
      Osoba_odpowiedzialna: null,
      plik_PDF: null,
      plik_dane: null,
      wiersze: [
        {
          Id_wyrobu: null,
          Ilosc: null,
          Id_jednostka: null,
          Cena_jednostkowa: null,
          Wartosc_oferty: null,
          Id_status_uwag: null,
          Uwagi: null,
        },
      ],
    },
    IdEdytowanegoElementu: null,
    userSaved: false,
    sending: false,
    lastUser: null,
    nazwaPliku: null,
    Kontrahenci: [],
    Jednostki_sprzedazy: [],
    Statusy_ofert: [],
    Zapytania: [],
    Wyroby: [],
    Statusy_uwag: [],
    Obiekt_generujacy_numer_oferty: null,
  }),
  async created() {
    try {
      const [
        odpowiedz_Zapytanie_ofertowe,
        odpowiedz_Jednostki_sprzedazy,
        odpowiedz_Statusy_oferty,
        odpowiedz_Statusy_uwag,
      ] = await Promise.all([
        this.czyJestWTrybieEdycji ? api.PobierzZapytanieOfertoweEdycja() : api.PobierzZapytanieOfertowe(),
        api.pobierzJednostki_sprzedazy(),
        api.pobierzStatusOfert(),
        api.pobierzStatusUwag(),
      ]);
      odpowiedz_Zapytanie_ofertowe,
        odpowiedz_Jednostki_sprzedazy,
        odpowiedz_Statusy_oferty,
        odpowiedz_Statusy_uwag,
        (this.Zapytania = odpowiedz_Zapytanie_ofertowe.data.dane.map((el) => ({
          ...el,
          nazwa: `Numer : ${el.Numer}, Nazwa kontr : ${el.Nazwa_kontrahenta}`,
        })));
      this.Numer = odpowiedz_Zapytanie_ofertowe.data.dane;
      this.Jednostki_sprzedazy = odpowiedz_Jednostki_sprzedazy.data.dane;
      this.Statusy_ofert = odpowiedz_Statusy_oferty.data.dane;
      this.Statusy_uwag = odpowiedz_Statusy_uwag.data.dane;

      if (this.obiektKonfiguracyjny.akcja === "edycja") {
        //edycja
        const odpowiedz = await api.pobieranieOfertyEdycja(
          this.obiektKonfiguracyjny.Id
        );
        const {
          Id,
          Id_zapytania_ofertowego,
          Numer_oferty,
          Data_oferty,
          Data_waznosci,
          Id_status_oferty,
          Osoba_odpowiedzialna,
          Nazwa_zalacznika,
          PozycjeZapytania,
        } = odpowiedz.data.dane[0];
        this.IdEdytowanegoElementu = Id;
        this.form.Nazwa_zapytania_ofertowego = this.Zapytania.find(
          ({ Id }) => Id == Id_zapytania_ofertowego
        ).nazwa;
        this.form.Numer_oferty = Numer_oferty;
        this.form.Data_oferty = Data_oferty;
        this.form.Data_waznosci = Data_waznosci;
        this.form.Id_status_oferty = Id_status_oferty;
        this.form.Osoba_odpowiedzialna = Osoba_odpowiedzialna;
        this.nazwaPliku = Nazwa_zalacznika;
        if (PozycjeZapytania.length !== 0) {
          this.form.wiersze = PozycjeZapytania;
        }
        this.pobierzWyroby();
      }
    } catch (error) {
      console.log(error);
    }



    
  },

  validations: {
    form: {
      Nazwa_zapytania_ofertowego: {
        required,
      },
      Numer_oferty: {
        required,
      },
      Data_oferty: {
        required,
      },
      Data_waznosci: {
        required: false,
      },
      Id_status_oferty: {
        required,
      },
      Osoba_odpowiedzialna: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(50),
      },
      wiersze: {
        $each: {
          Id_wyrobu: {
            required,
          },
          Ilosc: {
            required,
          },
          Id_jednostka: {
            required,
          },
          Cena_jednostkowa: {
            required,
          },
          Wartosc_oferty: {
            required,
          },
          Id_status_uwag: {
            required,
          },
          Uwagi: {
            required: required(false),
            maxLength: maxLength(1000),
          },
        },
      },
    },
  },
  computed: {
    Id_zapytania_ofertowego: function () {
      const { Nazwa_zapytania_ofertowego } = this.form;
      const znalezionyElement = this.Zapytania.find(
        ({ nazwa }) => nazwa === Nazwa_zapytania_ofertowego
      );

      if (znalezionyElement) {
        return znalezionyElement.Id;
      }
      return null;
    },
    zapytaniaWartosc: function () {
      return this.Zapytania.map((el) => el.nazwa);
    },
    plikZwalidowany: function () {
      return this.form.plik_dane
        ? this.form.plik_dane.size < 2 * 1024 * 1024
        : true;
    },
    czyJestWTrybieEdycji: function () {
      return this.obiektKonfiguracyjny.akcja === "edycja";
    },

    sumaWartosci: function () {
      return this.form.wiersze.reduce((suma, el) => {
  
        const wartosc =  el.Wartosc_oferty ? parseFloat(el.Wartosc_oferty.replace(",", ".")) : 0;
        
        return suma + wartosc;
      }, 0).toFixed(2);
    },
    dostepneWyroby:  function() {
      return this.Wyroby.filter((wyrob) => {
        const {wiersze} = this.form;
        return !wiersze.some(wiersz => wiersz.Id_wyrobu === wyrob.Id_wyrobu)
      });
    },

    numerOferty: function () {
      if (this.Obiekt_generujacy_numer_oferty) {
        const { numer, miesiac, rok } = this.Obiekt_generujacy_numer_oferty;
        return `OF-${numer}/${miesiac}/${rok}`;
      } else {
        return "";
      }
    },
  },
  methods: {
    zapamietajPlik(pliki) {
      if (pliki[0]) {
        this.form.plik_dane = pliki[0];
      }
    },
    pobierzNumerOferty() {
      const { Data_oferty } = this.form;
      const data = new Date(Data_oferty);
      const rok = data.getFullYear();
      const miesiac = data.getMonth() + 1;
      if(this.czyJestWTrybieEdycji){
        return;
      }

      api.PobierzLpOferty({ rok, miesiac }).then((odpowiedz) => {
        this.Obiekt_generujacy_numer_oferty = odpowiedz.data.dane;
        this.form.Numer_oferty = this.numerOferty;
      });
    },
    dodajWiersz() {
      console.log("robi");
      const nowyWiersz = {};
      Object.keys(this.form.wiersze[0]).forEach((key) => {
        nowyWiersz[key] = null;
      });
      const noweWiersze = [...this.form.wiersze, nowyWiersz];
      this.form.wiersze = noweWiersze;
    },
    usunWiersz(wiersz) {
      if (this.form.wiersze.length <= 1) {
        const nowyWiersz = {};
        Object.keys(this.form.wiersze[0]).forEach((key) => {
          nowyWiersz[key] = null;
        });
        this.form.wiersze = [nowyWiersz];
        return;
      }

      this.form.wiersze = this.form.wiersze.filter((el) => el !== wiersz);
    },
    ustawCeneNetto(wiersz) {
      if (wiersz.Ilosc === null || wiersz.Cena_jednostkowa === null) {
        wiersz.Wartosc_oferty = null;
        return;
      }
      const ilosc = parseFloat(wiersz.Ilosc.replace(",", "."));
      const cena = parseFloat(wiersz.Cena_jednostkowa.replace(",", "."));
      wiersz.Wartosc_oferty = parseFloat(ilosc * cena).toFixed(2);
    },

    pobierzWyroby() {
      const { Id_zapytania_ofertowego } = this;
      api.PobierzWyrob(Id_zapytania_ofertowego).then((Wyroby) => {
        this.Wyroby = Wyroby.data.dane;
      });
    },

    UstalSzeczegolyWyrobu(idWyrobu, index) {
      const wyrob = this.Wyroby.find(({ Id_wyrobu }) => Id_wyrobu == idWyrobu);
      if (wyrob) {
        this.form.wiersze[index].Id_jednostka = wyrob.Id_jednostka;
        this.form.wiersze[index].Ilosc = wyrob.Ilosc;
      }
    },

    getValidationClass(fieldName, index) {
      const field = this.$v.form[fieldName] || this.$v.form.wiersze.$each[index][fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.Id_zapytania_ofertowego = null;
      this.form.Numer_oferty = null;
      this.form.Data_oferty = null;
      this.form.Data_waznosci = null;
      this.form.Id_status_oferty = null;
      this.form.Osoba_odpowiedzialna = null;
      this.form.plik_PDF = null;
      this.form.plik_dane = null;
      this.nazwaPliku = null;
      this.form.wiersze = [];
    },

    async zapiszFormularz() {
      this.sending = true;
      const PozycjeZapytania = this.form.wiersze.map((el) => ({
        ...el,
        Ilosc: parseFloat(el.Ilosc.replace(",", ".")),
        Cena_jednostkowa: parseFloat(el.Cena_jednostkowa.replace(",", ".")),
      }));

      let file = null;
      if (this.form.plik_dane) {
        file = await new Promise((resolve) => {
          const fileReader = new FileReader();
          fileReader.onload = () => {
            resolve(fileReader.result);
          };
          fileReader.readAsDataURL(this.form.plik_dane);
        });
      }

      if (this.czyJestWTrybieEdycji) {
        const daneDoWyslania = {
          Id_zapytania_ofertowego: this.Id_zapytania_ofertowego,
          Numer_oferty: this.form.Numer_oferty,
          Data_oferty: this.form.Data_oferty,
          Data_waznosci: this.form.Data_waznosci,
          Id_status_oferty: this.form.Id_status_oferty,
          Osoba_odpowiedzialna: this.form.Osoba_odpowiedzialna,
          plik_PDF: file,
          PlikNazwa: this.form.plik_dane ? this.form.plik_dane.name : null,
          PozycjeZapytania,
        };

        api
          .zapiszOfertyUpdate(daneDoWyslania, this.IdEdytowanegoElementu)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const daneDoWyslania = {
          Id_zapytania_ofertowego: this.Id_zapytania_ofertowego,
          Numer_oferty: this.form.Numer_oferty,
          Lp: this.Obiekt_generujacy_numer_oferty.numer,
          Data_oferty: this.form.Data_oferty,
          Data_waznosci: this.form.Data_waznosci,
          Id_status_oferty: this.form.Id_status_oferty,
          Osoba_odpowiedzialna: this.form.Osoba_odpowiedzialna,
          plik_PDF: file,
          PlikNazwa: this.form.plik_dane ? this.form.plik_dane.name : null,
          PozycjeZapytania,
        };

        api
          .zapiszOferty(daneDoWyslania)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    naszWalidacjaFormularza() {
      if (this.czyJestWTrybieEdycji) {
        return (
          !this.$v.form.Nazwa_zapytania_ofertowego.$invalid &&
          !this.$v.form.Numer_oferty.$invalid &&
          !this.$v.form.Data_oferty.$invalid &&
          !this.$v.form.Data_waznosci.$invalid &&
          !this.$v.form.Id_status_oferty.$invalid &&
          !this.$v.form.Osoba_odpowiedzialna.$invalid &&
          !this.$v.form.wiersze.$invalid &&
          this.plikZwalidowany
        );
      } else {
        return !this.$v.$invalid && this.plikZwalidowany;
      }
    },
    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>
<style >
.Form_Oferta .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.Form_Oferta .md-layout .md-gutter {
  min-width: 73vw !important; /* szerokość modala*/
  max-width: 100% !important;
  min-height: 7vh !important; /* wysokość pomiędzy mierszami w modalu*/
  margin-right: 20px !important;
}
.md-layout {
  padding-right: 0px !important;
}
.Form_Oferta .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}

.Form_Oferta .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  margin: 10px 24px !important;
  padding-top: 35px !important;
}
.Form_Oferta .A03 .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 90px !important;
  margin: 10px 10px 10px 10px !important;
  padding-top: 35px !important;
}
.Form_Oferta .A02 .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 90px !important;
  margin: 10px 10px 10px 10px !important;
  padding-top: 35px !important;
}
.Form_Oferta .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}

.Form_Oferta {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
  overflow: auto;
}
.Form_Oferta .md-primary {
  border: 1px solid !important;
  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}
.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important; /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}
.md-layout .md-gutter {
  min-width: 1100px !important;
}
.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}
.md-list-item-text {
  font-size: 1.7vmin !important;
}
.Form_Oferta .md-card-actions .md-button {
  margin-right: 50px !important;
}
.aa {
  border-bottom: 1px solid;
  margin-top: 10px;
  padding-bottom: 20px;
  margin-left: 25px;
  margin-right: 20px;
}
.Form_Oferta .ikony {
  cursor: pointer;
  margin-right: 5px;
  margin-left: 15px;
}
.md-field.md-has-textarea:not(.md-autogrow):after,
.md-field.md-has-textarea:not(.md-autogrow):before {
  border: none !important;
  border-bottom: 0.5px solid !important;
  height: 90px !important;
  font-size: 12px !important;
}
.md-field.md-has-textarea:not(.md-autogrow) .md-textarea {
  min-height: 40px !important;
  padding: 0 0px !important;
  resize: vertical !important;
}
.md-input {
  width: 100% !important;
}
.md-menu-content-bottom-start.md-menu-content-small.md-menu-content.md-theme-default {
  z-index: 300;
  color: black;
}
</style>