<template>
  <div class="Form_SPRS">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI SPRAWOZDANIA ŚRODOWISKOWEGO"
                : "FORMULARZ DODAWANIA SPRAWOZDANIA ŚRODOWISKOWEGO"
            }}
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-autocomplete
                :class="getValidationClass('Nazwa_wybor_sprawozdania')"
                v-model="form.Nazwa_wybor_sprawozdania"
                :md-options="zapytaniaSprawozdania"
                :disabled="sending"
              >
                <label>Wybór sprawozdania środowiskowego</label>
                <span
                  class="md-error"
                  v-if="!$v.form.Nazwa_wybor_sprawozdania.required"
                  >Pole jest wymagane</span
                >
              </md-autocomplete>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('Rok')">
                <label for="Rok">Rok</label>
                <md-input
               
                  name="Rok"
                  id="Rok"
                  autocomplete="Rok"
                  md-counter="4"
                  v-model="form.Rok"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Rok.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Rok.maxlength"
                  >Wymagana ilość znaków 4</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Rok.minlength"
                  >Wymagana ilość znaków 4</span
                >
              </md-field>
            </div>
      

            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('Data_zlozenia')">
                <label for="Data_zlozenia">Data złożenia</label>
                <md-input
                  type="date"
                  name="Data_zlozenia"
                  id="Data_zlozenia"
                  autocomplete="Data_zlozenia"
                  v-model="form.Data_zlozenia"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Data_zlozenia.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('Data_ustawowa')">
                <label for="Data_ustawowa">Data ustawowa</label>
                <md-input
                  type="date"
                  name="Data_ustawowa"
                  id="Data_ustawowa"
                  autocomplete="Data_ustawowa"
                  v-model="form.Data_ustawowa"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Data_ustawowa.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>


          <div class="md-layout md-gutter">
            <md-field class="MP3" :class="plikZwalidowany ? '' : 'md-invalid'">
              <label
                >Wstaw plik "max-2Mb"
                <span v-if="nazwaPliku">
                  - obecnie wstawiony plik to {{ nazwaPliku }}</span
                ></label
              >
              <md-file
                v-model="form.plik_PDF"
                @md-change="zapamietajPlik"
                accept="application/pdf"
              />
              <span class="md-error" v-if="!plikZwalidowany"
                >Plik moze miec max 2MB</span
              >
            </md-field>
          </div>
        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

       <div style="text-align: center">
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            czyJestWTrybieEdycji
              ? "ZATWIERDŹ - EDYTOWANY WPIS"
              : "ZATWIERDŹ - UTWÓRZ WPIS"
          }}</md-button>
        </div>
      </md-card>
    </form>
  </div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import minLength from "vuelidate/lib/validators/minLength";
import { api } from "../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_maszyny",

  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      Nazwa_wybor_sprawozdania: null,
      Rok: null,
      Data_zlozenia: null,
      Data_ustawowa: null,
      plik_PDF: null,
      plik_dane: null,
    },
    IdEdytowanegoElementu: null,
    nazwaPliku: null,
    userSaved: false,
    sending: false,
    lastUser: null,
    Procesy: [],
  }),

  async created() {
    try {
      const [odpowiedz] = await Promise.all([
        api.PobierzRodzajSprawozdan(),
      ]);
      odpowiedz,
        (this.Procesy = odpowiedz.data.dane.map((el) => ({
          ...el,
          nazwa: `${el.Rodzaj_sprawozdania}`,
        })));

      if (this.obiektKonfiguracyjny.akcja === "edycja") {
        //edycja
        const odpowiedz = await api.pobieranieSprawozdawczoscEdycja(
          this.obiektKonfiguracyjny.Id
        );
          const {
            Id,
            Id_sprawozdania,
            Rok,
            Data_zlozenia,
            Data_ustawowa,
            Nazwa_zalacznika,
          } = odpowiedz.data.dane[0];
          this.IdEdytowanegoElementu = Id;
          this.form.Nazwa_wybor_sprawozdania = this.Procesy.find(
          ({ Id }) => Id == Id_sprawozdania
          ).nazwa;
          this.form.Rok = Rok;
          this.form.Data_zlozenia = Data_zlozenia;
          this.form.Data_ustawowa = Data_ustawowa;
          this.nazwaPliku = Nazwa_zalacznika;
        }
        }catch(error) {
          console.log(error);
        }
    },

  validations: {
    form: {
      Nazwa_wybor_sprawozdania: {
        required,
      },
      Rok: {
        required,
        minLenght: minLength(4),
        maxLength: maxLength(4),
      },
      Data_zlozenia: {
        required,
      },
      Data_ustawowa: {
        required,
      },
    },
  },
  computed: {
    Id_sprawozdania: function() {
      const { Nazwa_wybor_sprawozdania } = this.form;
      const znalezionyElement = this.Procesy.find(
        ({ nazwa }) => nazwa === Nazwa_wybor_sprawozdania
      );

      if (znalezionyElement) {
        return znalezionyElement.Id;
      }
      return null;
    },
    zapytaniaSprawozdania: function() {
      return this.Procesy.map((el) => el.nazwa);
    },
    plikZwalidowany: function() {
      return this.form.plik_dane
        ? this.form.plik_dane.size < 2 * 1024 * 1024
        : true;
    },
    czyJestWTrybieEdycji: function() {
      return this.obiektKonfiguracyjny.akcja === "edycja";
    },
  },

  methods: {
    zapamietajPlik(pliki) {
      if (pliki[0]) {
        this.form.plik_dane = pliki[0];
      }
    },
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.Id_sprawozdania = null;
      this.form.Rok = null;
      this.form.Data_zlozenia = null;
      this.form.Data_ustawowa = null;
      this.form.plik_PDF = null;
      this.form.plik_dane = null;
      this.nazwaPliku = null;
    },

    zapiszFormularz() {
      this.sending = true;

      if (this.czyJestWTrybieEdycji) {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Id_sprawozdania", this.Id_sprawozdania);
        dane.append("Rok", this.form.Rok);
        dane.append("Data_zlozenia", this.form.Data_zlozenia);
        dane.append("Data_ustawowa", this.form.Data_ustawowa);
        dane.append("plik_PDF", this.form.plik_dane);

        api
          .zapiszSprawozdawczoscUpdate(dane, this.IdEdytowanegoElementu)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Id_sprawozdania", this.Id_sprawozdania);
        dane.append("Rok", this.form.Rok);
        dane.append("Data_zlozenia", this.form.Data_zlozenia);
        dane.append("Data_ustawowa", this.form.Data_ustawowa);
        dane.append("plik_PDF", this.form.plik_dane);

        api
          .zapiszSprawozdawczosc(dane)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    naszWalidacjaFormularza() {
      if (this.czyJestWTrybieEdycji) {
        return (
          !this.$v.form.Nazwa_wybor_sprawozdania.$invalid &&
          !this.$v.form.Rok.$invalid &&
          !this.$v.form.Data_zlozenia.$invalid &&
          !this.$v.form.Data_ustawowa.$invalid &&
          this.plikZwalidowany
        );
      } else {
        return !this.$v.$invalid && this.plikZwalidowany;
      }
    },

    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>

<style>
.Form_SPRS .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
.Form_SPRS {
  overflow: auto;
}
.Form_SPRS .md-layout .md-gutter {
  min-width: 55vw !important; /* szerokość modala*/
  max-width: 100% !important;
  min-height: 7vh; /* wysokość pomiędzy mierszami w modalu*/
}
.md-layout {
  padding-right: 0px !important;
}
.Form_SPRS .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}

.Form_SPRS .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  margin: 10px 24px !important;
  padding-top: 35px !important;
}
.Form_SPRS .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}

.Form_SPRS {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
}
.Form_SPRS .md-primary {
  border: 1px solid !important;
  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}
.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important; /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}

.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
  overflow-x: auto!important;
  overflow-y: auto!important;
  
}
.md-list-item-content {
  font-size: 1.2vmin !important;
}
.md-list-item-container:not(.md-list-item-default):not([disabled])>.md-list-item-content {

    overflow-x: auto;
}
</style>
