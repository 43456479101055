<template>
  <div class="Form_Raport_produk">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI RAPORTU PRODUKCYJNEGO"
                : "FORMULARZ DODAWANIA RAPORTU PRODUKCYJNEGO"
            }}
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-autocomplete :class="getValidationClass('Nazwa_wybor_zamowienia')"
                v-model="form.Nazwa_wybor_zamowienia"
                :md-options="zapytaniaWartosc"
                @md-selected="pobierzWyroby"
              >
                <label>Wybór zamówienia</label>
                 <span class="md-error" v-if="!$v.form.Nazwa_wybor_zamowienia.required"
                  >Pole jest wymagane</span
                >
              </md-autocomplete>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('Id_wyrobu')">
                <label for="Id_wyrobu">Wybór wyrobu/usługi do zamówienia</label>
                <md-select
                  v-model="form.Id_wyrobu"
                  :disabled="sending"
                  name="Id_wyrobu"
                  id="Id_wyrobu"
                  autocomplete="Id_wyrobu"
                  md-dense
                >
                  <md-option
                    v-for="(wyrob, index) in Wyroby"
                    :key="index"
                    :value="wyrob.Id_wyrobu"
                    >{{ `${wyrob.Nazwa} - ilosc zamawiana: ${wyrob.Ilosc}` }}
                  </md-option>
                </md-select>
                <span class="md-error" v-if="!$v.form.Id_wyrobu.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('Data_raportu')">
                <label for="Data_raportu">Data raportu</label>
                <md-input
                  type="date"
                  name="Data_raportu"
                  id="Data_raportu"
                  
                  v-model="form.Data_raportu"
                  @input="pobierzNumerRaportu"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Data_raportu.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('Numer_raportu')">
                <label for="Numer_raportu">Numer raportu</label>
                <md-input
                  name="Numer_raportu"
                  id="Numer_raportu"
                  
                  v-model="form.Numer_raportu"
                  :disabled="sending"
                  readonly
                />
                <span class="md-error" v-if="!$v.form.Numer_raportu.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Numer_raportu.maxlength"
                  >Wymagana ilość znaków od 1 do 50</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('Osoba_odpowiedzialna')">
                <label for="Osoba_odpowiedzialna">Osoba odpowiedzialna</label>
                <md-input
                  name="Osoba_odpowiedzialna"
                  id="Osoba_odpowiedzialna"
                  
                  md-counter="50"
                  v-model="form.Osoba_odpowiedzialna"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Osoba_odpowiedzialna.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Osoba_odpowiedzialna.maxlength"
                  >Wymagana ilość znaków od 1 do 50</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="plikZwalidowany ? '' : 'md-invalid'">
                <label
                  >Wstaw plik - raport produkcyjny "max-2Mb"
                  <span v-if="nazwaPliku">
                    - obecnie wstawiony plik to {{ nazwaPliku }}</span
                  ></label
                >
                <md-file
                  v-model="form.plik_PDF"
                  @md-change="zapamietajPlik"
                  accept="application/pdf"
                />
                <span class="md-error" v-if="!plikZwalidowany"
                  >Plik moze miec max 2MB</span
                >
              </md-field>
            </div>
          </div>
          <div
            style="
              margin-top: 40px;
              margin-left: 15px;
              font-weight: bold;
              font-size: 18px;
            "
          >
            SZCZEGÓŁY RAPORTU PRODUKCYJNEGO
          </div>
          <div
            style="
              margin-top: 10px;
              margin-left: 15px;
              font-weight: bold;
              font-size: 16px;
              color: red;
            "
          ></div>
          <div
            v-for="(wiersz, index) in form.wiersze"
            :key="index"
            :set="(v = $v.form.wiersze.$each[index])"
          >
            <div
              style="
                color: blue;
                font-weight: bold;
                font-size: 1.5vh;
                margin-left: 15px;
                margin-top: 10px;
              "
            >
              {{ `POZYCJA NR: ${index + 1} ` }}

              <b-icon-plus-square
                class="ikony"
                font-scale="1.4"
                style="border: 1px black"
                color="black"
                @click="dodajWiersz()"
              >
              </b-icon-plus-square>

              <b-icon-trash
                class="ikony"
                font-scale="1.4"
                color="black"
                @click="usunWiersz(wiersz)"
              >
              </b-icon-trash>
            </div>

            <div class="md-layout md-gutter">
              <div class="md-layout-item md-small-size-100">
                <md-field :class="getValidationClass('Id_operacji', index)">
                  <label for="Id_operacji">Wybór operacji</label>

                  <md-select
                    v-model="wiersz.Id_operacji"
                    :disabled="sending"
                    name="Id_operacji"
                    id="Id_operacji"
                    autocomplete="Id_operacji"
                    md-dense
                  >
                    <md-option
                      v-for="(operacja, index) in Operacje"
                      :key="index"
                      :value="operacja.Id"
                      >{{ `${operacja.Nazwa_dzialania}` }}
                    </md-option>
                  </md-select>
                  <span class="md-error" v-if="!v.Id_operacji.required"
                    >Pole jest wymagane</span
                  >
                </md-field>
              </div>
            </div>
            <div class="md-layout md-gutter">
              <div class="md-layout-item md-small-size-100">
                <md-field :class="getValidationClass('Id_jednostka', index)">
                  <label for="Id_jednostka">Jedn.</label>

                  <md-select
                    v-model="wiersz.Id_jednostka"
                    :disabled="sending"
                    name="Id_jednostka"
                    id="Id_jednostka"
                    autocomplete="Id_jednostka"
                    md-dense
                  >
                    <md-option
                      v-for="(jedn_sprzedazy, index) in Jednostki_sprzedazy"
                      :key="index"
                      :value="jedn_sprzedazy.Id"
                      >{{ jedn_sprzedazy.Jedn_sprzedazy }}
                    </md-option>
                  </md-select>
                  <span class="md-error" v-if="!v.Id_jednostka.required"
                    >Pole jest wymagane</span
                  >
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100">
                <md-field :class="getValidationClass('Ilosc', index)">
                  <label for="Ilosc">Ilość</label>
                  <md-input
                    style="text-align: right"
                    name="Ilosc"
                    id="Ilosc"
                    autocomplete="Ilosc"
                    v-model="wiersz.Ilosc"
                    :disabled="sending"
                  />
                  <span class="md-error" v-if="!v.Ilosc.required"
                    >Pole jest wymagane</span
                  >
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100">
                <md-field
                  :class="getValidationClass('Osoba_wykonujaca', index)"
                >
                  <label for="Osoba_wykonujaca">Osoba wykonująca</label>
                  <md-input
                    style="text-align: right"
                    name="Osoba_wykonujaca"
                    id="Osoba_wykonujaca"
                    autocomplete="Osoba_wykonujaca"
                    md-counter="50"
                    v-model="wiersz.Osoba_wykonujaca"
                    :disabled="sending"
                  />
                  <span class="md-error" v-if="!v.Osoba_wykonujaca.required"
                    >Pole jest wymagane</span
                  >
                  <span class="md-error" v-else-if="!v.Osoba_wykonujaca.maxlength"
                    >Wymagana ilość znaków od 1 do 50</span
                  >
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100">
                <md-field :class="getValidationClass('Pomiar', index)">
                  <label for="Pomiar">Status kontroli</label>

                  <md-select
                    v-model="wiersz.Pomiar"
                    :disabled="sending"
                    name="Pomiar"
                    id="Pomiar"
                    autocomplete="Pomiar"
                    md-dense
                  >
                    <md-option
                      v-for="(pomiar, index) in Pomiary"
                      :key="index"
                      :value="pomiar.Id"
                      >{{ pomiar.Status_kontroli }}
                    </md-option>
                  </md-select>
                  <span class="md-error" v-if="!v.Pomiar.required"
                    >Pole jest wymagane</span
                  >
                </md-field>
              </div>
            </div>

            <div
              v-if="
                wiersz.Pomiar !== '1' &&
                  wiersz.Pomiar !== '2' &&
                  wiersz.Pomiar !== null
              "
              class="md-layout md-gutter"
            >
              <div class="md-layout-item md-small-size-100">
                <md-field :class="getValidationClass('Wynik_pomiaru', index)">
                  <label for="Wynik_pomiaru">Wyniki pomiarów</label>
                  <md-textarea
                    md-autogrow
                    md-counter="1000"
                    name="Wynik_pomiaru"
                    id="Wynik_pomiaru"
                    autocomplete="Wynik_pomiaru"
                    v-model="wiersz.Wynik_pomiaru"
                    :disabled="sending"
                  />
                  <span class="md-error" v-if="!v.Wynik_pomiaru.required"
                    >Pole jest wymagane</span
                  >
                  <span class="md-error" v-else-if="!v.Wynik_pomiaru.maxlength"
                    >Wymagana ilość znaków od 1 do 1000</span
                  >
                </md-field>
              </div>
            </div>
            <div class="md-layout md-gutter"
            >
             <div class="md-layout-item md-small-size-100">
                <md-field :class="getValidationClass('Id_uwagi', index)">
                  <label for="Id_uwagi">Czy chcesz wpisać uwagę</label>

                  <md-select
                    v-model="wiersz.Id_uwagi"
                    :disabled="sending"
                    name="Id_uwagi"
                    id="Id_uwagi"
                    autocomplete="Id_uwagi"
                    md-dense
                  >
                    <md-option
                      v-for="(uwaga, index) in Uwagi"
                      :key="index"
                      :value="uwaga.Id"
                      >{{ uwaga.Rodzaj_uwag }}
                    </md-option>
                  </md-select>
                  <span class="md-error" v-if="!v.Id_uwagi.required"
                    >Pole jest wymagane</span
                  >
                </md-field>
              </div>
            </div>
            <div v-if="wiersz.Id_uwagi !== '2' && wiersz.Id_uwagi !== null"
            class="md-layout md-gutter">
              <div class="md-layout-item md-small-size-100">
                <md-field :class="getValidationClass('Uwaga', index)">
                  <label for="Uwaga">Uwaga</label>
                  <md-textarea
                    md-autogrow
                    md-counter="1500"
                    name="Uwaga"
                    id="Uwaga"
                    autocomplete="Uwaga"
                    v-model="wiersz.Uwaga"
                    :disabled="sending"
                  />
                  <span class="md-error" v-if="!v.Uwaga.required"
                    >Pole jest wymagane</span
                  >
                  <span class="md-error" v-else-if="!v.Uwaga.maxlength"
                    >Wymagana ilość znaków od 1 do 1500</span
                  >
                </md-field>
              </div>
            </div>
          </div>

          <div
            style="
              margin-top: 40px;
              margin-left: 15px;
              font-weight: bold;
              font-size: 18px;
            "
          >
            ZWOLNIENIE WYROBU / USŁUGI
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('Id_zwolnienie')">
                <label for="Id_zwolnienie"
                  >Czy chcesz dokonać zwolnienia wyrobu/usługi</label
                >
                <md-select
                  v-model="form.Id_zwolnienie"
                  :disabled="sending"
                  name="Id_zwolnienie"
                  id="Id_zwolnienie"
                  autocomplete="Id_zwolnienie"
                  md-dense
                >
                  <md-option
                    v-for="(zwolnienie, index) in Zwolnienia"
                    :key="index"
                    :value="zwolnienie.Id"
                    >{{ `${zwolnienie.Rodzaj_zwolnienia}` }}
                  </md-option>
                </md-select>
                <span class="md-error" v-if="!$v.form.Id_zwolnienie.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

          <div
            v-if="form.Id_zwolnienie !== '2' && form.Id_zwolnienie !== null"
            class="md-layout md-gutter"
          >
            <div
              v-if="form.Id_zwolnienie !== '2' && form.Id_zwolnienie !== null"
              class="md-layout-item md-small-size-100"
            >
              <md-field :class="getValidationClass('Ilosc_zwalniana')">
                <label for="Ilosc_zwalniana">Ilość zwalniana</label>
                <md-input
                  name="Ilosc_zwalniana"
                  id="Ilosc_zwalniana"
                  
                  v-model="form.Ilosc_zwalniana"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Ilosc_zwalniana.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div
              v-if="form.Id_zwolnienie !== '2' && form.Id_zwolnienie !== null"
              class="md-layout-item md-small-size-100"
            >
              <md-field :class="getValidationClass('Data_zwolnienia')">
                <label for="Data_zwolnienia">Data zwolnienia</label>
                <md-input
                  type="date"
                  name="Data_zwolnienia"
                  id="Data_zwolnienia"
                  
                  v-model="form.Data_zwolnienia"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Data_zwolnienia.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div
              v-if="form.Id_zwolnienie !== '2' && form.Id_zwolnienie !== null"
              class="md-layout-item md-small-size-100"
            >
              <md-field :class="getValidationClass('Osoba_zwalniajaca')">
                <label for="Osoba_zwalniajaca">Osoba zwalniająca</label>
                <md-input
                  name="Osoba_zwalniajaca"
                  id="Osoba_zwalniajaca"
                  
                  md-counter="50"
                  v-model="form.Osoba_zwalniajaca"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Osoba_zwalniajaca.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Osoba_zwalniajaca.maxlength"
                  >Wymagana ilość znaków od 1 do 50</span
                >
              </md-field>
            </div>
          </div>
        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

        <div style="text-align: center">
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            czyJestWTrybieEdycji
              ? "ZATWIERDŹ - EDYTOWANY WPIS"
              : "ZATWIERDŹ - UTWÓRZ WPIS"
          }}</md-button>
        </div>
      </md-card>
    </form>
  </div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import minLength from "vuelidate/lib/validators/minLength";
import { api } from "../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_faktury",
  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      Nazwa_wybor_zamowienia: null,
      Id_wyrobu: null,
      Numer_raportu: null,
      Data_raportu: null,
      Data_realizacji: null,
      Osoba_odpowiedzialna: null,
      plik_PDF: null,
      plik_dane: null,
      Id_zwolnienie: null,
      Ilosc_zwalniana: null,
      Data_zwolnienia: null,
      Osoba_zwalniajaca: null,
      wiersze: [
        {
          Id_operacji: null,
          Id_jednostka: null,
          Cena_jednostkowa: null,
          Osoba_wykonujaca: null,
          Pomiar: null,
          Wynik_pomiaru: null,
          Id_uwagi: null,
          Uwaga: null,
        },
      ],
    },
    // obiektKonfiguracyjny: {
    //   akcja: "dodawanie",
    //   Id: null,
    // },
    IdEdytowanegoElementu: null,
    userSaved: false,
    sending: false,
    lastUser: null,
    nazwaPliku: null,
    Jednostki_sprzedazy: [],
    Zamowienia: [],
    Wyroby: [],
    Operacje: [],
    Pomiary: [],
    Zwolnienia: [],
    Uwagi:[],
    Obiekt_generujacy_numer_raportu: null,
  }),

  async created() {
    try {
      const [
        odpowiedz_Zamowienia,
        odpowiedz_Jednostki_sprzedazy,
        odpowiedz_Operacje,
        odpowiedz_Status_pomiary,
        odpowiedz_Zwolnienia,
        odpowiedz_Uwagi,
      ] = await Promise.all([
        api.PobierzZamowienia(),
        api.pobierzJednostki_sprzedazy(),
        api.pobierzOperacje(),
        api.pobierzStatus_pomiary(),
        api.pobierzZwolnieniaWyrobu(),
        api.PobierzRodzajUwag(),
      ]);

      odpowiedz_Zamowienia,
        (this.Zamowienia = odpowiedz_Zamowienia.data.dane.map((el) => ({
          ...el,
          nazwa: `Zamówienie numer: ${el.Numer_zamowienia} - ${el.Nazwa_kontrahenta}`,
        })));
      this.Jednostki_sprzedazy = odpowiedz_Jednostki_sprzedazy.data.dane;
      this.Operacje = odpowiedz_Operacje.data.dane;
      this.Pomiary = odpowiedz_Status_pomiary.data.dane;
      this.Zwolnienia = odpowiedz_Zwolnienia.data.dane;
      this.Uwagi = odpowiedz_Uwagi.data.dane;

      if (this.obiektKonfiguracyjny.akcja === "edycja") {
        //edycja
        const odpowiedz = await api.pobieranieRaportyProdukcyjneEdycja(
          this.obiektKonfiguracyjny.Id
        );
        const {
          Id,
          Id_zamowienia,
          Id_wyrobu,
          Numer_raportu,
          Data_raportu,
          Osoba_odpowiedzialna,
          Id_zwolnienie,
          Ilosc_zwalniana,
          Data_zwolnienia,
          Osoba_zwalniajaca,
          Nazwa_zalacznika,
          PozycjeRaportu,
        } = odpowiedz.data.dane[0];
        this.IdEdytowanegoElementu = Id;
        this.form.Nazwa_wybor_zamowienia = this.Zamowienia.find(
          ({ Id }) => Id == Id_zamowienia
        ).nazwa;
        this.form.Id_wyrobu = Id_wyrobu;
        this.form.Numer_raportu = Numer_raportu;
        this.form.Data_raportu = Data_raportu;
        this.form.Osoba_odpowiedzialna = Osoba_odpowiedzialna;
        this.form.Id_zwolnienie = Id_zwolnienie;
        this.form.Ilosc_zwalniana = Ilosc_zwalniana;
        this.form.Data_zwolnienia = Data_zwolnienia;
        this.form.Osoba_zwalniajaca = Osoba_zwalniajaca;
        this.nazwaPliku = Nazwa_zalacznika;
        if (PozycjeRaportu.length !== 0) {
          this.form.wiersze = PozycjeRaportu;
        }
        this.pobierzWyroby();
      }
    } catch (error) {
      console.log(error);
    }
  },
  validations: {
    form: {
      Nazwa_wybor_zamowienia: {
        required,
      },
      Numer_raportu: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(50),
      },
      Id_wyrobu: {
        required,
      },
      Data_raportu: {
        required,
      },
      Osoba_odpowiedzialna: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(50),
      },
      Id_zwolnienie: {
        required,
      },
      Ilosc_zwalniana: {
        required: required(false),
      },
      Data_zwolnienia: {
        required: required(false),
      },
      Osoba_zwalniajaca: {
        required: required(false),
        maxLength: maxLength(50),
      },
      wiersze: {
        $each: {
          Id_operacji: {
            required,
          },
          Ilosc: {
            required,
          },
          Id_jednostka: {
            required,
          },
          Osoba_wykonujaca: {
            required,
            minLenght: minLength(1),
            maxLength: maxLength(50),
          },
          Pomiar: {
            required,
          },
          Wynik_pomiaru: {
            required: required(false),
            maxLength: maxLength(1000),
          },
          Id_uwagi: {
            required,
          },
          Uwaga: {
            required: required(false),
            maxLength: maxLength(1500),
          },
        },
      },
    },
  },
  computed: {
    Id_zamowienia: function() {
      const { Nazwa_wybor_zamowienia } = this.form;
      const znalezionyElement = this.Zamowienia.find(
        ({ nazwa }) => nazwa === Nazwa_wybor_zamowienia
      );

      if (znalezionyElement) {
        return znalezionyElement.Id;
      }
      return null;
    },
    Id_zapytania_ofertowego: function() {
      const { Nazwa_wybor_zamowienia } = this.form;
      const znalezionyElement = this.Zamowienia.find(
        ({ nazwa }) => nazwa === Nazwa_wybor_zamowienia
      );

      if (znalezionyElement) {
        return znalezionyElement.Id_zapytania_ofertowego;
      }
      return null;
    },
    Id_oferty: function() {
      const { Nazwa_wybor_zamowienia } = this.form;
      const znalezionyElement = this.Zamowienia.find(
        ({ nazwa }) => nazwa === Nazwa_wybor_zamowienia
      );

      if (znalezionyElement) {
        return znalezionyElement.Id_oferty;
      }
      return null;
    },
    zapytaniaWartosc: function() {
      return this.Zamowienia.map((el) => el.nazwa);
    },
    plikZwalidowany: function() {
      return this.form.plik_dane
        ? this.form.plik_dane.size < 2 * 1024 * 1024
        : true;
    },
    czyJestWTrybieEdycji: function() {
      return this.obiektKonfiguracyjny.akcja === "edycja";
    },

    numerRaportu: function() {
      if (this.Obiekt_generujacy_numer_raportu) {
        const { numer, miesiac, rok } = this.Obiekt_generujacy_numer_raportu;
        return `RAP-${numer}/${miesiac}/${rok}`;
      } else {
        return "";
      }
    },
  },
  methods: {
    zapamietajPlik(pliki) {
      if (pliki[0]) {
        this.form.plik_dane = pliki[0];
      }
    },

    pobierzNumerRaportu() {
      const { Data_raportu } = this.form;
      const data = new Date(Data_raportu);
      const rok = data.getFullYear();
      const miesiac = data.getMonth() + 1;
      if (this.czyJestWTrybieEdycji) {
        return;
      }

      api.PobierzLpRaportu({ rok, miesiac }).then((odpowiedz) => {
        this.Obiekt_generujacy_numer_raportu = odpowiedz.data.dane;
        this.form.Numer_raportu = this.numerRaportu;
      });
    },

    dodajWiersz() {
      console.log("robi");
      const nowyWiersz = {};
      Object.keys(this.form.wiersze[0]).forEach((key) => {
        nowyWiersz[key] = null;
      });
      const noweWiersze = [...this.form.wiersze, nowyWiersz];
      this.form.wiersze = noweWiersze;
    },
    usunWiersz(wiersz) {
      if (this.form.wiersze.length <= 1) {
        const nowyWiersz = {};
        Object.keys(this.form.wiersze[0]).forEach((key) => {
          nowyWiersz[key] = null;
        });
        this.form.wiersze = [nowyWiersz];
        return;
      }

      this.form.wiersze = this.form.wiersze.filter((el) => el !== wiersz);
    },

    pobierzWyroby() {
      const { Id_zamowienia } = this;
      api.PobierzWyrob2(Id_zamowienia).then((Wyroby) => {
        this.Wyroby = Wyroby.data.dane;
      });
    },

    getValidationClass(fieldName, index) {
      const field = this.$v.form[fieldName] || this.$v.form.wiersze.$each[index][fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.Id_zamowienia = null;
      this.form.Id_wyrobu = null;
      this.form.Numer_raportu = null;
      this.form.Data_raportu = null;
      this.form.Osoba_odpowiedzialna = null;
      this.form.plik_PDF = null;
      this.form.plik_dane = null;
      this.nazwaPliku = null;
      this.form.Id_zwolnienie = null;
      this.form.Ilosc_zwalniana = null;
      this.form.Data_zwolnienia = null;
      this.form.Osoba_zwalniajaca = null;
      this.form.wiersze = [];
    },

    async zapiszFormularz() {
      this.sending = true;
      const PozycjeRaportu = this.form.wiersze.map((el) => ({
        ...el,
      }));

      let file = null;
      if (this.form.plik_dane) {
        file = await new Promise((resolve) => {
          const fileReader = new FileReader();
          fileReader.onload = () => {
            resolve(fileReader.result);
          };
          fileReader.readAsDataURL(this.form.plik_dane);
        });
      }

      if (this.czyJestWTrybieEdycji) {
        const daneDoWyslania = {
          Id_zamowienia: this.Id_zamowienia,
          Id_wyrobu: this.form.Id_wyrobu,
          Numer_raportu: this.form.Numer_raportu,
          Data_raportu: this.form.Data_raportu,
          Osoba_odpowiedzialna: this.form.Osoba_odpowiedzialna,
          Id_zwolnienie: this.form.Id_zwolnienie,
          Ilosc_zwalniana: this.form.Ilosc_zwalniana,
          Data_zwolnienia: this.form.Data_zwolnienia,
          Osoba_zwalniajaca: this.form.Osoba_zwalniajaca,
          Id_zapytania_ofertowego: this.Id_zapytania_ofertowego,
          Id_oferty: this.Id_oferty,
          plik_PDF: file,
          PlikNazwa: this.form.plik_dane ? this.form.plik_dane.name : null,
          PozycjeRaportu,
        };

        api
          .zapiszRaportyProdukcyjneUpdate(
            daneDoWyslania,
            this.IdEdytowanegoElementu
          )
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {

        const daneDoWyslania = {
          Id_zamowienia: this.Id_zamowienia,
          Id_wyrobu: this.form.Id_wyrobu,
          Numer_raportu: this.form.Numer_raportu,
          Lp: this.Obiekt_generujacy_numer_raportu.numer,
          Data_raportu: this.form.Data_raportu,
          Osoba_odpowiedzialna: this.form.Osoba_odpowiedzialna,
          Id_zwolnienie: this.form.Id_zwolnienie,
          Ilosc_zwalniana: this.form.Ilosc_zwalniana,
          Data_zwolnienia: this.form.Data_zwolnienia,
          Osoba_zwalniajaca: this.form.Osoba_zwalniajaca,
          Id_zapytania_ofertowego: this.Id_zapytania_ofertowego,
          Id_oferty: this.Id_oferty,
          plik_PDF: file,
          PlikNazwa: this.form.plik_dane ? this.form.plik_dane.name : null,
          PozycjeRaportu,
        };

        api
          .zapiszRaportProdukcyjny(daneDoWyslania)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    naszWalidacjaFormularza() {
      if (this.czyJestWTrybieEdycji) {
        return (
          !this.$v.form.Nazwa_wybor_zamowienia.$invalid &&
          !this.$v.form.Id_wyrobu.$invalid &&
          !this.$v.form.Numer_raportu.$invalid &&
          !this.$v.form.Data_raportu.$invalid &&
          !this.$v.form.Osoba_odpowiedzialna.$invalid &&
          !this.$v.form.Id_zwolnienie.$invalid &&
          !this.$v.form.Ilosc_zwalniana.$invalid &&
          !this.$v.form.Data_zwolnienia.$invalid &&
          !this.$v.form.Osoba_zwalniajaca.$invalid &&
          !this.$v.form.wiersze.$invalid &&
          this.plikZwalidowany
        );
      } else {
        return !this.$v.$invalid && this.plikZwalidowany;
      }
    },
    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>
<style>
.Form_Raport_produk .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.Form_Raport_produk .md-layout .md-gutter {
  min-width: 73vw !important; /* szerokość modala*/
  max-width: 100% !important;
  min-height: 7vh !important; /* wysokość pomiędzy mierszami w modalu*/
  margin-right: 20px !important;
}
.md-layout {
  padding-right: 0px !important;
}
.Form_Raport_produk .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}

.Form_Raport_produk .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  margin: 10px 14px !important;
  padding-top: 35px !important;
}
.Form_Raport_produk .A3 .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 90px !important;
  margin: 10px 10px 10px 10px !important;
  padding-top: 35px !important;
}
.Form_Raport_produk .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}

.Form_Raport_produk {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
  overflow: auto;
}
.Form_Raport_produk .md-primary {
  border: 1px solid !important;

  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}
.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important; /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}
.md-layout .md-gutter {
  min-width: 1100px !important;
}
.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}
.md-list-item-text {
  font-size: 1.7vmin !important;
}
.Form_Raport_produk .md-card-actions .md-button {
  margin-right: 50px !important;
}

.Form_Raport_produk .ikony {
  cursor: pointer;
  margin-right: 5px;
  margin-left: 15px;
}
.md-field.md-has-textarea:not(.md-autogrow):after,
.md-field.md-has-textarea:not(.md-autogrow):before {
  border: none !important;
  border-bottom: 0.5px solid !important;
  height: 90px !important;
  font-size: 12px !important;
}
.md-field.md-has-textarea:not(.md-autogrow) .md-textarea {
  min-height: 40px !important;
  padding: 0 0px !important;
  resize: vertical !important;
}
.md-input {
  width: 100% !important;
}
</style>
