<template >
  <div>
  <FormularzDodawanieRaportu />
  </div>
</template>

<script>
import FormularzDodawanieRaportu from '../../komponenty/strona-formularze/SYS_audity/formularz_dodawanie_raportu.vue'


export default {
  name: 'StronaFormularzRaport',
  components: {
    FormularzDodawanieRaportu,
    
  }

}
</script>

