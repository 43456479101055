<template>
  <div>
  <TabelaPomiarowkaUbezpieczenia />
  </div>
</template>

<script>
import TabelaPomiarowkaUbezpieczenia from '../../komponenty/strona-tabela-komponenty/WST_pomiarowka/tabela_pomiarowka_ubezpieczenia.vue'


export default {
  name: 'StronaTabelaPomiarowkaUbezpieczenia',
  components: {
    TabelaPomiarowkaUbezpieczenia,
  }

}
</script>
