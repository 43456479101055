<template>
  <div>
  <TabelaLokalizacje />
  </div>
</template>

<script>
import TabelaLokalizacje from '../../komponenty/strona-tabela-komponenty/SYS_kontekst/tabela_lokalizacje.vue'


export default {
  name: 'StronaTabelaLokalizacje',
  components: {
    TabelaLokalizacje,
    
  }

}
</script>