<template>
  <div>
  <tabelaWykazRealizacji/>

  </div>
</template>

<script>
import tabelaWykazRealizacji from '../../komponenty/strona-tabela-komponenty/HR_szkolenia/tabela_wykaz_realizacji.vue'


export default {
  name: 'StronaTabelaWykazRealizacja',
  components: {
       tabelaWykazRealizacji,
  }

}
</script>
