<template>
  <div>
  <TabelaWyposazenieBHP />
  </div>
</template>

<script>
import TabelaWyposazenieBHP from '../../komponenty/strona-tabela-komponenty/WST_wyposazenieBHP/tabela_wyposazenieBHP.vue'


export default {
  name: 'StronaTabelaWyposazenieBHP',
  components: {
    TabelaWyposazenieBHP,
    
  }

}
</script>
