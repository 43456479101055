<template>
  <table class="table_01">

        <tr>
          <td style="width: 100%; height:70px; background: #adadad; font-size:24px; font-weight: 700" colspan="2">SKIEROWANIE NA BADANIA LEKARSKIE</td>
        </tr>

        <tr>
          <td style="width: 50%; background: #e3e3e3; font-weight: 500">Rodzaj badania</td>
          <td style="width: 50%" ><span>{{ info.Rodzaj_badania }}</span></td>
        </tr>

        <tr>
          <td style="width: 50%; background: #e3e3e3; font-weight: 500">Miejscowość</td>
          <td style="width: 50%" ><span>{{ info.Miejscowosc_sk }}</span></td>
        </tr>

        <tr>
          <td style="width: 50%; background: #e3e3e3; font-weight: 500">Data wystawienia skierowania</td>
          <td style="width: 50%" ><span>{{ info.Data }}</span></td>
        </tr>

        <tr>
          <td style="width: 100%; height:70px; background: #adadad; font-size:14px; font-weight: 700" colspan="2">
            Dzialajac na podstawie art. 229 § 4a ustawy z dnia 26 czerwca 1974
            r. - Kodeks pracy (Dz. U. z 2014 r. poz. 1502, z pozn. zm.),
            kieruję na badania lekarskie:
          </td>
        </tr>

        <tr>
          <td style="width: 50%; background: #e3e3e3; font-weight: 500">Imię i nazwisko</td>
          <td style="width: 50%"><span>{{ info.Imie1}} {{ info.Nazwisko }} </span></td>
        </tr>

        <tr>
          <td style="width: 50%; background: #e3e3e3; font-weight: 500">Numer PESEL</td>
          <td style="width: 50%"><span>{{ info.Pesel}}</span></td>
        </tr>

        <tr>
          <td style="width: 50%; background: #e3e3e3; font-weight: 500">Zamieszkały/Zamieszkała</td>
          <td style="width: 50%"><span>{{ info.Kod}} {{ info.Miejscowosc }} {{ info.Ulica }} {{ info.Nr }} </span></td>
        </tr>

        <tr>
          <td style="width: 50%; background: #e3e3e3; font-weight: 500">Badania dla osoby</td>
          <td style="width: 50%"><span>{{ info.Rodzaj_badania_osoby }}</span></td>
        </tr>

        <tr>
          <td style="width: 50%; background: #e3e3e3; font-weight: 500">Stanowisko pracy</td>
          <td style="width: 50%"><span>{{ info.Stanowisko}}</span></td>
        </tr>

        <tr>
          <td style="width: 50%; background: #e3e3e3; font-weight: 500">Opis stanowiska pracy</td>
          <td style="width: 50%"><span>{{ info.Opis_stanowiska }}</span></td>
        </tr>
      

      
        <tr>
          <td style="width: 100%; height:70px; background: #adadad; font-size:14px; font-weight: 700" colspan="2">
            Opis warunków pracy uwzgledniajacych informacje o występowaniu na
            stanowisku lub stanowiskach pracy, czynników niebezpiecznych,
            szkodliwych dla zdrowia lub czynnikow uciążliwych i innych
            wynikajacych ze sposobu wykonywania pracy, z podaniem wielkości
            narażenia oraz aktualnych wyników badan i pomiarów czynników
            szkodliwych dla zdrowia, wykonanych na tym stanowisku/stanowiskach
            należy wpisać nazwe czynnika/czynników i wielkosc/wielkosci
            narażenia
          </td>
        </tr>

        <tr>
          <td style="width: 50%; background: #e3e3e3; font-weight: 500">Czynnniki fizyczne</td>
          <td style="width: 50%"><p v-for="(czynnik1, index) in info.Czynniki_fizyczne" :key="index">{{ czynnik1}}</p></td>
        </tr>

        <tr>
          <td style="width: 50%; background: #e3e3e3; font-weight: 500">Pyły</td>
          <td style="width: 50%"><p v-for="(czynnik2, index) in info.Pyly" :key="index">{{ czynnik2}}</p></td>
        </tr>

        <tr>
          <td style="width: 50%; background: #e3e3e3; font-weight: 500">Czynniki chemiczne</td>
          <td style="width: 50%;"><p v-for="(czynnik3, index) in info.Czynniki_chemiczne" :key="index">{{ czynnik3}}</p></td>
        </tr>

        <tr>
          <td style="width: 50%; background: #e3e3e3; font-weight: 500">Czynniki biologiczne</td>
          <td style="width: 50%"><p v-for="(czynnik4, index) in info.Czynniki_biologiczne" :key="index">{{ czynnik4}}</p></td>
        </tr>

        <tr>
          <td style="width: 50%; background: #e3e3e3; font-weight: 500">Czynniki inne</td>
          <td style="width: 50%"><p v-for="(czynnik5, index) in info.Czynniki_inne" :key="index">{{ czynnik5}}</p></td>
        </tr>

        <tr>
          <td style="width: 50%; background: #e3e3e3; font-weight: 500">
            Łączna liczba czynników niebezpiecznych, szkodliwych dla zdrowia lub
            czynników uciążliwych i innych wynikających ze sposobu wykonywania
            pracy wskazanych w skierowaniu:
          </td>
          <td style="width: 50%"><input class="textarea"></td>
        </tr>
        <tr>
          <td style="width: 50%; background: #e3e3e3; font-weight: 500">PODPIS PRACODAWCY :</td>
          <td style="width: 50%; height: 40px"><input class="textarea"></td>
        </tr>


  </table>
</template>

<script>
import { api } from "./../../../serwisy/api.serwis";
export default {
  name: "Skierowanie",
  data: () => ({
    info: {
      Data: null,
      Id: null,
      Imie1: null,
      Id_rodzaj_badan: null,
      Rodzaj_badania_osoby: null,
      Opis_stanowiska: null,
      Nazwisko: null,
      Pesel: null,
      Rodzaj_badania: null,
      Czynniki_chemiczne: null,
      Pyly: null,
      Czynniki_biologiczne: null,
      Czynniki_fizyczne: null,
      Czynniki_inne: null,

    },
  }),
  created() {
    this.pobierzSkierowanie();
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  methods: {
    async pobierzSkierowanie() {
      try {
        const {
          data: { dane },
        } = await api.pobieranieSkierowania(this.id);
        const info = dane.map(row => {
          row.Czynniki_fizyczne = row.PozycjeCzynikow.filter(pozycja=>{
            return pozycja.Id_zagrozenia == 1
          }).map(pozycja=>{
            return pozycja.Opis_zagrozenia
          })
          row.Pyly = row.PozycjeCzynikow.filter(pozycja=>{
            return pozycja.Id_zagrozenia == 2
          }).map(pozycja=>{
            return pozycja.Opis_zagrozenia
          })
          row.Czynniki_chemiczne = row.PozycjeCzynikow.filter(pozycja=>{
            return pozycja.Id_zagrozenia == 3
          }).map(pozycja=>{
            return pozycja.Opis_zagrozenia
          })
          row.Czynniki_biologiczne = row.PozycjeCzynikow.filter(pozycja=>{
            return pozycja.Id_zagrozenia == 4
          }).map(pozycja=>{
            return pozycja.Opis_zagrozenia
          })
          row.Czynniki_inne = row.PozycjeCzynikow.filter(pozycja=>{
            return pozycja.Id_zagrozenia == 5
          }).map(pozycja=>{
            return pozycja.Opis_zagrozenia
          })
          return row
        })[0]

        this.info = info;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
.table_01 {
  width: 100%;
  height: 40px;
  font-size: 16px;
  margin-top: 40px;
  color: black;
  vertical-align: middle;
}
.table_01 td{
  border: 1px solid black;
}

.input {
  width: 100%;
  height: 100%;

  text-align: center;
}
.table_01 p {
    margin-top: 0;
    margin-bottom: 0.1rem!important;
}
.table_01 .textarea {
  width: 100%;
  min-height: 30px;
  max-height: 200px;
  text-align: center;
}

</style>