<template>
  <div>
  <TabelaMaszynyPrzeglady />
  </div>
</template>

<script>
import TabelaMaszynyPrzeglady from '../../komponenty/strona-tabela-komponenty/WST_maszyny/tabela_maszyny_przeglady.vue'


export default {
  name: 'StronaTabelaMaszynyPrzeglady',
  components: {
    TabelaMaszynyPrzeglady,
    
  }

}
</script>
