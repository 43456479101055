<template >
  <div>
  <TabelaAspekty />
  </div>
</template>

<script>
import TabelaAspekty from '../../komponenty/strona-tabela-komponenty/SYS_aspekty/tabela_aspekty.vue'


export default {
  name: 'StronaTabelaAspekty',
  components: {
    TabelaAspekty,
    
  }

}
</script>

