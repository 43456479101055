<template >
  <div>
  <TabelaProcesy />
  </div>
</template>

<script>
import TabelaProcesy from '../../komponenty/strona-tabela-komponenty/SYS_procesy/tabela_procesy.vue'


export default {
  name: 'StronaTabelaProcesy',
  components: {
    TabelaProcesy,
    
  }

}
</script>

