<template >
  <div>
  <TabelaRealizacjaZakonczone/>
  </div>
</template>

<script>
import TabelaRealizacjaZakonczone from '../../komponenty/strona-tabela-komponenty/SO_realizacja/tabela_wykaz_realizacja_zakonczone.vue'


export default {
  name: 'StronaTabelaRealizacjaZakonczone',
  components: {
    TabelaRealizacjaZakonczone,
    
  }

}
</script>

