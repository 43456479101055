<template>
  <div>
  <TabelaZakres/>
  </div>
</template>

<script>
import TabelaZakres from '../../komponenty/strona-tabela-komponenty/SYS_kontekst/tabela_zakres.vue'


export default {
  name: 'StronaTabelaZakres',
  components: {
    TabelaZakres,
    
  }

}
</script>