<template >
  <div>
  <TabelaAudityProgram />
  </div>
</template>

<script>
import TabelaAudityProgram from '../../komponenty/strona-tabela-komponenty/SYS_audity/tabela_audity_program.vue'


export default {
  name: 'StronaTabelaAudityProgram',
  components: {
    TabelaAudityProgram,
    
  }

}
</script>

