<template >
  <div>
  <TabelaWykazKontroliWewnetrznychRealizacjaOS />
  </div>
</template>

<script>
import TabelaWykazKontroliWewnetrznychRealizacjaOS from '../../komponenty/strona-tabela-komponenty/BHP_kontrole_wewnetrzne/tabela_dzialania_kolntrola_ocena_skutecznosci.vue'


export default {
  name: 'StronaTabelaWykazKontroliWewnetrznychRealizacjaOS',
  components: {
    TabelaWykazKontroliWewnetrznychRealizacjaOS,
    
  }

}
</script>

