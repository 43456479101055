<template>
  <div class="Stanow_przeglad">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI WYROBRU / USŁUGI"
                : "FORMULARZ DODAWANIA WYROBRU / USŁUGI"
            }}
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-autocomplete
                :class="getValidationClass('Nazwa_wybor_grupy')"
                v-model="form.Nazwa_wybor_grupy"
                :md-options="zapytaniaGrupy"
                :disabled="sending || czyJestWTrybieEdycji"
              >
                <label>Wybór grupy wyrobów/usług</label>
                <span
                  class="md-error"
                  v-if="!$v.form.Nazwa_wybor_grupy.required"
                  >Pole jest wymagane</span
                >
              </md-autocomplete>
            </div>
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="MP2" :class="getValidationClass('Numer')">
                <label for="Numer">Numer katalogowy</label>
                <md-input
                  id="Numer"
                  name="Numer"
                  autocomplete="Numer"
                  md-counter="50"
                  v-model="form.Numer"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Numer.required"
                  >Pole jest wymagane</span
                >
                 <span
                  class="md-error"
                  v-else-if="!$v.form.Numer.maxlength"
                  >Wymagana ilość znaków od 1 do 50</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="MP1" :class="getValidationClass('Nazwa')">
                <label for="Nazwa">Nazwa wyrobu / usługi</label>
                <md-textarea
                  name="Nazwa"
                  id="Nazwa"
                  md-counter="500"
                  md-autogrow
                  autocomplete="Nazwa"
                  v-model="form.Nazwa"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Nazwa.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Nazwa.maxlength"
                  >Wymagana ilość znaków od 1 do 500</span
                >
              </md-field>
            </div>
          </div>
        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

        <div style="text-align: center">
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            czyJestWTrybieEdycji
              ? "ZATWIERDŹ - EDYTOWANY WPIS"
              : "ZATWIERDŹ - UTWÓRZ WPIS"
          }}</md-button>
        </div>
      </md-card>
    </form>
  </div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import minLength from "vuelidate/lib/validators/minLength";
import { api } from "../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_przegladu_maszyny",
  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      Id: null,
      Nazwa_wybor_grupy: null,
      Numer: null,
      Nazwa: null,
    },
    IdEdytowanegoElementu: null,
    nazwaPliku: null,
    userSaved: false,
    sending: false,
    lastUser: null,
    Grupy: [],
  }),

  async created() {
    try {
      const [odpowiedz_Grupy] = await Promise.all([api.pobierzGrupyWyrobow()]);
      odpowiedz_Grupy,
        (this.Grupy = odpowiedz_Grupy.data.dane.map((el) => ({
          ...el,
          nazwa: `${el.Rodzaj} `,
        })));

      if (this.obiektKonfiguracyjny.akcja === "edycja") {
        //edycja
        const odpowiedz = await api.pobieranieWyrobyUslugiEdycja(
          this.obiektKonfiguracyjny.Id
        );
        const { 
          Id, 
          Id_grupa, 
          Numer, 
          Nazwa 
          } = odpowiedz.data.dane[0];
        this.IdEdytowanegoElementu = Id;
        this.form.Nazwa_wybor_grupy = this.Grupy.find(
          ({ Id }) => Id == Id_grupa
        ).nazwa;
        this.form.Numer = Numer;
        this.form.Nazwa = Nazwa;
      }
    } catch (error) {
      console.log(error);
    }
  },
  validations: {
    form: {
      Nazwa_wybor_grupy: {
        required,
      },
      Numer: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(50),
      },
      Nazwa: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(500),
      },
    },
  },
  computed: {
    Id_grupa: function() {
      const { Nazwa_wybor_grupy } = this.form;
      const znalezionyElement = this.Grupy.find(
        ({ nazwa }) => nazwa === Nazwa_wybor_grupy
      );

      if (znalezionyElement) {
        return znalezionyElement.Id;
      }
      return null;
    },
    zapytaniaGrupy: function() {
      return this.Grupy.map((el) => el.nazwa);
    },

    czyJestWTrybieEdycji: function() {
      return this.obiektKonfiguracyjny.akcja === "edycja";
    },
  },

  methods: {
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.Id = null;
      this.form.Id_grupa = null;
      this.form.Numer = null;
      this.form.Nazwa = null;
    },

    zapiszFormularz() {
      this.sending = true;

      if (this.czyJestWTrybieEdycji) {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Id_grupa", this.Id_grupa);
        dane.append("Numer", this.form.Numer);
        dane.append("Nazwa", this.form.Nazwa);

        api
          .zapiszWyrobyUslugiUpdate(dane, this.IdEdytowanegoElementu)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const dane = new FormData();
        dane.append("Id_grupa", this.Id_grupa);
        dane.append("Numer", this.form.Numer);
        dane.append("Nazwa", this.form.Nazwa);

        api
          .zapiszWyrobyUslugi(dane)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    naszWalidacjaFormularza() {
      if (this.czyJestWTrybieEdycji) {
        return (
          !this.$v.form.Nazwa_wybor_grupy.$invalid &&
          !this.$v.form.Numer.$invalid &&
          !this.$v.form.Nazwa.$invalid
        );
      } else {
        return !this.$v.$invalid;
      }
    },
    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>

<style>
.Stanow_przeglad .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.Stanow_przeglad .md-layout .md-gutter {
  min-width: 55vw !important; /* szerokość modala*/
  max-width: 100% !important;
  min-height: 7vh !important; /* wysokość pomiędzy mierszami w modalu*/
}
.md-layout {
  padding-right: 0px !important;
}
.Stanow_przeglad .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}

.Stanow_przeglad .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  margin: 10px 24px !important;
  padding-top: 35px !important;
}
.Stanow_przeglad .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}

.Stanow_przeglad {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
}
.Stanow_przeglad .md-primary {
  border: 1px solid !important;

  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}
.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important; /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}

.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}
.Stanow_przeglad .md-list-item-text {
  font-size: 1.7vmin !important;
}
.Stanow_przeglad .md-card-actions .md-button {
  margin-right: 50px !important;
}
</style>
