<template >
  <div>
  <TabelaDzialaniaAudit />
  </div>
</template>

<script>
import TabelaDzialaniaAudit from '../../komponenty/strona-tabela-komponenty/SYS_dzialania_audit/tabela_dzialania_audit.vue'


export default {
  name: 'StronaTabelaDzialaniaAudit',
  components: {
    TabelaDzialaniaAudit,
    
  }

}
</script>

