<template >
  <div>
  <TabelaOferty/>
  </div>
</template>

<script>
import TabelaOferty from '../../komponenty/strona-tabela-komponenty/SO_oferty/tabela_wykaz_ofert.vue'


export default {
  name: 'StronaTabelaOferty',
  components: {
    TabelaOferty,
    
  }

}
</script>

