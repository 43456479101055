<template >
  <div>
  <TabelaAudityPlan />
  </div>
</template>

<script>
import TabelaAudityPlan from '../../komponenty/strona-tabela-komponenty/SYS_audity/tabela_audity_plan.vue'


export default {
  name: 'StronaTabelaAudityPlan',
  components: {
    TabelaAudityPlan,
    
  }

}
</script>

