<template >
  <div>
  <TabelaWymaganiaPrawne />
  </div>
</template>

<script>
import TabelaWymaganiaPrawne from '../../komponenty/strona-tabela-komponenty/SYS_wymagania_prawne/tabela_wymagania_prawne.vue'


export default {
  name: 'StronaTabelaWymaganiaPrawne',
  components: {
    TabelaWymaganiaPrawne,
    
  }

}
</script>

