var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"border":"1px solid","margin-top":"2%"}},[_c('div',{staticClass:"tabela_przeglady_kontekst"},[_c('div',{staticClass:"laczacy"},[_c('div',{staticClass:"menu_strony"},[_c('span',[_c('button',{staticClass:"button",attrs:{"disabled":!_vm.autoryzacja.czyMozeDodawac},on:{"click":function($event){_vm.pokazDialog = true;
              _vm.typAktywnegoFormularza =
                'formularz_dodawanie_przegladu_kontekstu';
              _vm.obiektKonfiguracyjny = {
                Id: null,
                akcja: 'dodawanie',
              };}}},[_vm._v(" DODAWANIE PRZEGLĄDU KONTEKSTU ")]),_c('md-tooltip',{staticClass:"podpowiedz",attrs:{"md-direction":"top"}},[_vm._v(_vm._s(!_vm.autoryzacja.czyMozeDodawac ? "BRAK UPRAWNIEŃ" : "DODAWANIE PRZEGLĄDU KONTEKSTU"))])],1),_c('button',{staticClass:"button",on:{"click":function($event){return _vm.$router.push("tabela_przeglad_kontekstu_historia/")}}},[_vm._v(" HISTORIA PRZEGLĄDU KONTEKSTU ")])]),_c('div',{staticClass:"menu_strony1"},[_c('b',[_c('b-icon-printer-fill',{staticClass:"ikony",attrs:{"font-scale":"2.1","color":"white"},on:{"click":_vm.generujPdf}}),_c('md-tooltip',{staticClass:"podpowiedz",attrs:{"md-direction":"top"}},[_vm._v("GENERUJ PDF / DRUKUJ")])],1),_c('b',[_c('b-icon-info-circle',{staticClass:"ikony",attrs:{"font-scale":"2.1","color":"white"},on:{"click":_vm.pokazPDF_INFO}}),_c('md-tooltip',{staticClass:"podpowiedz",attrs:{"md-direction":"top"}},[_vm._v("INFO")])],1)])]),(_vm.pokazDialog)?_c('modal',{attrs:{"rodzajFormularza":_vm.typAktywnegoFormularza,"funkcjaZamykajacaDialog":_vm.funkcjaZamykajacaDialog,"obiektKonfiguracyjny":_vm.obiektKonfiguracyjny}}):_vm._e(),(_vm.pokazDialog)?_c('modalKontekst',{attrs:{"rodzajFormularza":_vm.typAktywnegoFormularza,"funkcjaZamykajacaDialog":_vm.funkcjaZamykajacaDialog,"obiektKonfiguracyjny":_vm.obiektKonfiguracyjny}}):_vm._e(),_c('md-table',{attrs:{"md-height":"5vh","md-model-id":"Id_maszyny","md-card":"","md-fixed-header":""},on:{"md-selected":_vm.wyborElementu},scopedSlots:_vm._u([{key:"md-table-row",fn:function(ref){
              var item = ref.item;
return _c('md-table-row',{key:item.id,attrs:{"md-selectable":"single"}},[_c('md-table-cell',{attrs:{"md-label":"Data przeglądu"}},[_vm._v(" "+_vm._s(item.Data_przegladu)+" ")]),_c('md-table-cell',{attrs:{"md-label":"Data nast.przeglądu"}},[_vm._v(" "+_vm._s(item.Data_nastepnego_przegladu)+" ")]),_c('md-table-cell',{attrs:{"md-label":"Osoba odpowiedzialna"}},[_vm._v(" "+_vm._s(item.Osoba_odpowiedzialna)+" ")]),_c('md-table-cell',{attrs:{"md-label":"Ilość dni"}},[_vm._v(" "+_vm._s(_vm.wyliczPozostaleDni(item.Data_nastepnego_przegladu))+" ")])],1)}}]),model:{value:(_vm.searched),callback:function ($$v) {_vm.searched=$$v},expression:"searched"}},[_c('md-table-toolbar',[_c('div',{staticClass:"d-flex flex-column w-100"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h1',{staticClass:"md-title"},[_vm._v(" WYKAZ PRZEGLĄDÓW KONTEKSTU ORGANIZACJI ")])])]),(_vm.wybranyElementTabeli)?_c('div',{staticClass:"PasekWyboru"},[_c('div',{staticStyle:{"text-align":"right","margin-top":"0.3vh","margin-bottom":"0.3vh"}},[_c('b',[_c('b-button',{attrs:{"disabled":!_vm.autoryzacja.czyMozeUsuwac},on:{"click":function($event){return _vm.usuwanieElementu(_vm.wybranyElementTabeli.Id)}}},[_c('b-icon-trash',{staticClass:"ikony",attrs:{"font-scale":"1.8","color":"white"}})],1),_c('md-tooltip',{staticClass:"podpowiedz",attrs:{"md-direction":"top"}},[_vm._v(_vm._s(_vm.autoryzacja.czyMozeUsuwac ? "USUWANIE ELEMENTU" : "BRAK UPRAWNIEŃ"))])],1),_c('b',[_c('b-button',{attrs:{"disabled":!_vm.autoryzacja.czyMozeEdytowac},on:{"click":function($event){_vm.pokazDialog = true;
                    _vm.typAktywnegoFormularza =
                      'formularz_dodawanie_przegladu_kontekstu';
                    _vm.obiektKonfiguracyjny = {
                      Id: _vm.wybranyElementTabeli.Id,
                      akcja: 'edycja',
                    };}}},[_c('b-icon-pencil-square',{staticClass:"ikony",attrs:{"font-scale":"1.8","color":"white"}})],1),_c('md-tooltip',{staticClass:"podpowiedz",attrs:{"md-direction":"top"}},[_vm._v(_vm._s(_vm.autoryzacja.czyMozeUsuwac ? "EDYCJA ELEMENTU" : "BRAK UPRAWNIEŃ"))])],1)])]):_vm._e()])]),(_vm.czyPobranoDane)?_c('md-table-empty-state',{attrs:{"md-label":"Nie posiadasz wpisów w wykazie historii przeglądów kontekstu"}},[_c('md-button',{staticClass:"md-primary md-raised",attrs:{"disabled":!_vm.autoryzacja.czyMozeDodawac},on:{"click":function($event){_vm.pokazDialog = true;
            _vm.typAktywnegoFormularza =
              'formularz_dodawanie_przegladu_kontekstu';
            _vm.obiektKonfiguracyjny = {
              Id: null,
              akcja: 'dodawanie',
            };}}},[_vm._v("Dodawanie nowego przeglądu kontekstu")]),_c('md-tooltip',{staticClass:"podpowiedz",attrs:{"md-direction":"top"}},[_vm._v(_vm._s(!_vm.autoryzacja.czyMozeDodawac ? "BRAK UPRAWNIEŃ" : "DODAWANIE PRZEGLĄDU KONTEKSTU"))])],1):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }