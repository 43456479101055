<template>
  <div class="tabela_KBHPZEW">
    <div class="laczacy">
      <div class="menu_strony">
        <span>
          <button
            class="button"
            :disabled="!autoryzacja.czyMozeDodawac"
            @click="
              pokazDialog = true;
              typAktywnegoFormularza =
                'formularz_dodawanie_kontroli_zewnetrznej';
              obiektKonfiguracyjny = {
                Id: null,
                akcja: 'dodawanie',
                iloscOfert: Oferty.length,
              };
            "
          >
            DODAWANIE NOWEGO RAPORTU Z KONTROLI ZEWNĘTRZNEJ
          </button>
          <md-tooltip class="podpowiedz" md-direction="top">{{
            !autoryzacja.czyMozeDodawac
              ? "BRAK UPRAWNIEŃ"
              : "DODAWANIE NOWEGO RAPORTU Z KONTROLI ZEWNĘTRZNEJ"
          }}</md-tooltip>
        </span>
      </div>
      <div class="menu_strony1">
        <b>
          <b-icon-printer-fill
            class="ikony"
            font-scale="2.1"
            color="white"
            @click="generujPdf"
          >
          </b-icon-printer-fill>
          <md-tooltip class="podpowiedz" md-direction="top"
            >GENERUJ PDF / DRUKUJ</md-tooltip
          >
        </b>

        <b>
          <b-icon-info-circle-fill
            class="ikony"
            font-scale="2.1"
            color="white"
            @click="pokazPDF_INFO"
          >
          </b-icon-info-circle-fill>
          <md-tooltip class="podpowiedz" md-direction="top"
            >GENERUJ PDF / DRUKUJ</md-tooltip
          >
        </b>
      </div>
    </div>

    <modalBHP
      v-if="pokazDialog"
      :rodzajFormularza="typAktywnegoFormularza"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />

    <div>
      <DataTable
        :value="searched"
        :expandedRows.sync="expandedRows"
        dataKey="Id"
        @row-expand="onRowExpand"
        @row-collapse="onRowCollapse"
        :selection.sync="wybranyElementTabeli"
        selectionMode="single"
        :metaKeySelection="false"
      >
        <template #header>
          <div>
            <h4>WYKAZ PRZEPROWADZONYCH KONTROLI ZEWNĘTRZNYCH</h4>
            <md-input
              placeholder="Szukaj..."
              v-model="search"
              @input="searchOnTable"
            />
            <div class="PasekWyboru" v-if="wybranyElementTabeli">
              <div
                style="text-align: right; margin-top: 0.3vh; margin-bottom: 0.3vh"
              >
                <b>
                  <b-button
                    :disabled="!autoryzacja.czyMozeUsuwac"
                    @click="usuwanieElementu(wybranyElementTabeli.Id)"
                  >
                    <b-icon-trash class="ikony" font-scale="1.8" color="white">
                    </b-icon-trash
                  ></b-button>
                  <md-tooltip class="podpowiedz" md-direction="top">{{
                    autoryzacja.czyMozeUsuwac
                      ? "USUWANIE ELEMENTU"
                      : "BRAK UPRAWNIEŃ"
                  }}</md-tooltip>
                </b>
                <b>
                  <b-button
                    :disabled="!autoryzacja.czyMozeEdytowac"
                    @click="
                      pokazDialog = true;
                      typAktywnegoFormularza =
                        'formularz_dodawanie_kontroli_zewnetrznej';
                      obiektKonfiguracyjny = {
                        Id: wybranyElementTabeli.Id,
                        akcja: 'edycja',
                      };
                    "
                  >
                    <b-icon-pencil-square
                      class="ikony"
                      font-scale="1.80"
                      color="white"
                    >
                    </b-icon-pencil-square>
                  </b-button>
                  <md-tooltip class="podpowiedz" md-direction="top">{{
                    autoryzacja.czyMozeUsuwac
                      ? "EDYCJA ELEMENTU"
                      : "BRAK UPRAWNIEŃ"
                  }}</md-tooltip>
                </b>
 <b
                  ><b-button>
                    <b-icon-file-earmark-arrow-down
                      class="ikony"
                      font-scale="1.8"
                      color="white"
                      @click="pokazPDF(wybranyElementTabeli.Id)"
                    >
                    </b-icon-file-earmark-arrow-down
                  ></b-button>
                  <md-tooltip class="podpowiedz" md-direction="top"
                    >PODGLĄD PLIKU</md-tooltip
                  >
                </b>
                <b
                  ><b-button>
                    <b-icon-printer-fill
                      class="ikony"
                      font-scale="1.8"
                      color="white"
                      @click="generujPdf(wybranyElementTabeli.Id)"
                    >
                    </b-icon-printer-fill
                  ></b-button>
                  <md-tooltip class="podpowiedz" md-direction="top"
                    >GENERUJ PDF / DRUKUJ</md-tooltip
                  >
                </b>
                <b>
                <b-button>
                  <b-icon-arrow-down-right-square
                    class="ikony"
                    font-scale="1.8"
                    color="white"
                    @click="
                      $router.push(
  
                        `wykaz_kontroli_zewnetrznych_realizacja_uwag/${wybranyElementTabeli.Id}`
                      )
                    "
                  >
                  </b-icon-arrow-down-right-square
                ></b-button>
                <md-tooltip class="podpowiedz" md-direction="top"
                  >REALIZACJA UWAG STWIERDZONYCH W TRAKCIE KONTROLI</md-tooltip
                >
              </b>
              </div>
            </div>
          </div>
        </template>

        <Column :expander="true" headerStyle="width: 5%" />
        <Column
          field="Lp1"
          headerStyle="width:5%; font-size:1.2vh; text-align:left"
          header="Lp"
        ></Column>
        <Column
          field="Data_kontroli"
          headerStyle="width:15%; font-size:1.2vh; text-align:left"
          header="Data kontroli"
          sortable
        ></Column>
        <Column
          field="Miejsce_kontroli"
          headerStyle="width:24%; font-size:1.2vh; text-align:left"
          header="Miejsce kontroli"
          sortable
        >
        </Column>
        <Column
          field="Kontrole_przeprowadzil"
          headerStyle="width:20%; font-size:1.2vh; text-align:left"
          header="Kontrolę przeprowadził"
          sortable
        ></Column>
        <Column
          field="Zakres_kontroli"
          headerStyle="width:26%; font-size:1.2vh; text-align:left"
          header="Zakres kontroli"
          sortable
        >
        </Column>

        <template #expansion="slotProps">
          <div class="orders-subtable">
            <h6 style="color: blue; text-align: left">
              SZCZEGÓŁOWE DANE PRACOWNIKA
            </h6>
            <DataTable
              :value="slotProps.data.Szczegoly"
              style="height: 21vh; border: 1px solid; overflow:auto"
            >
              <Column
                headerStyle="color:blue; width:10%; font-size:1.1vh; text-align:left"
                header="Lp"
                sortable
              >
                <template #body="slotProps">
                  {{ slotProps.index + 1 }}
                </template>
              </Column>

              <Column
                field="Uwagi"
                headerStyle="color:blue; width:80%; font-size:1.1vh; text-align:left"
                header="Uwagi stwierdzone w trakcie kontroli"
                sortable
              >
              </Column>
         
            </DataTable>
          </div>
        </template>
      </DataTable>
    </div>
  </div>
</template>

<script>
import modalBHP from "../../strona-formularze/modalBHP.vue";
import { api } from "../../../serwisy/api.serwis";
import { authorizationService } from "./../../../serwisy/authorization.servis";
import { pdfSerwis } from "./../../../serwisy/pdf.serwis";
import "jspdf-autotable";

const toLower = (text) => {
  return text.toString().toLowerCase();
};

const search = (items, term) => {
  if (term) {
    return items.filter((item) => {
      const elementWyszukiwany = toLower(term);
      const Data_kontroli = toLower(item.Data_kontroli);
      const Miejsce_kontroli = toLower(item.Miejsce_kontroli);
      const Kontrole_przeprowadzil = toLower(item.Kontrole_przeprowadzil);
      const Zakres_kontroli = toLower(item.Zakres_kontroli);

      return (
        Data_kontroli.includes(elementWyszukiwany) ||
        Miejsce_kontroli.includes(elementWyszukiwany) ||
        Kontrole_przeprowadzil.includes(elementWyszukiwany) ||
        Zakres_kontroli.includes(elementWyszukiwany)
      );
    });
  }

  return items;
};

export default {
  name: "tabela_wykaz_ofert",
  data: () => ({
    czyPobranoDane: false,
    search: null,
    searched: [],
    Oferty: [],
    pokazDialog: false,
    typAktywnegoFormularza: null,
    obiektKonfiguracyjny: null,
    wybranyElementTabeli: null,
    expandedRows: [],
    staraWartoscWybranyegolementTabeli: null,
    autoryzacja: {
      czyMozeDodawac: false,
      czyMozeUsuwac: false,
      czyMozeEdytowac: false,
      czyMozePrzegladac: false,
    },
  }),
  components: {
    modalBHP,
  },
  mounted: function() {
    this.pobierzDaneDoTabeli();
    this.pobierzDaneAutoryzacyjne();
  },
  computed: {
    //informacja czy jest w trybie edycji
    czyJestWTrybieEdycji() {
      return this.$router.params.Id ? true : false;
    },
  },

  methods: {
    onRowExpand() {
      // this.$toast.add({severity: 'info', summary: 'Product Expanded', detail: event.data.name, life: 3000});
    },
    onRowCollapse() {
      // this.$toast.add({severity: 'success', summary: 'Product Collapsed', detail: event.data.name, life: 3000});
    },
    expandAll() {
      this.expandedRows = this.products.filter((p) => p.id);
      // this.$toast.add({severity: 'success', summary: 'All Rows Expanded', life: 3000});
    },
    collapseAll() {
      this.expandedRows = null;
      //this.$toast.add({severity: 'success', summary: 'All Rows Collapsed', life: 3000});
    },
    formatCurrency(value) {
      return value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
        generujPdf() {
      const { Id } = this.wybranyElementTabeli;

      pdfSerwis.generujRaportKontrolaZewnetrzna(Id);
    },
    pobierzDaneAutoryzacyjne() {
      authorizationService
        .getInfo("StronaTabelaWykazKontroliZewnetrznych", 4)

        .then((obiektOdpowiedzi) => {
          const dane = obiektOdpowiedzi.data.dostep;
          this.autoryzacja = {
            czyMozeDodawac: Boolean(
              dane.find(({ Id_operacji }) => Id_operacji == "1")
            ),
            czyMozeUsuwac: Boolean(
              dane.find(({ Id_operacji }) => Id_operacji == "2")
            ),
            czyMozeEdytowac: Boolean(
              dane.find(({ Id_operacji }) => Id_operacji == "3")
            ),
            czyMozePrzegladac: Boolean(
              dane.find(({ Id_operacji }) => Id_operacji == "4")
            ),
          };
        })
        .catch((error) => {
          console.log(error);
        });
    },

    pobierzDaneDoTabeli() {
      const { id } = this.$route.params;

      console.log(this.$route);

      api
        .pobieranieWykazuKontroliZew(id)
        .then((obiektOdpowiedzi) => {
          console.log(obiektOdpowiedzi);
          this.Oferty = obiektOdpowiedzi.data.dane.map((maszyna, index) => ({
            ...maszyna,
            Lp1: index + 1,
          }));
          this.searched = this.Oferty;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.czyPobranoDane = true;
        });
    },
    funkcjaZamykajacaDialog(sukces) {
      this.pokazDialog = false;
      this.typAktywnegoFormularza = null;
      this.obiektKonfiguracyjny = null;
      if (sukces) {
        this.pobierzDaneDoTabeli();
      }
    },
    newUser() {
      window.alert("Noop");
    },
    searchOnTable() {
      this.searched = search(this.Oferty, this.search);
    },
    usuwanieElementu(Id) {
      this.pokazDialog = true;
      this.typAktywnegoFormularza = "usuwanie";
      this.obiektKonfiguracyjny = {
        Id,
        typ: "KontrolaZewnetrzna",
      };
    },
    pokazPDF(Id) {
      //dopisane
      this.pokazDialog = true;
      this.typAktywnegoFormularza = "pokaz_PDF";
      this.obiektKonfiguracyjny = {
        Id,
        Klucz: "ProtokolKZEW",
      };
    },
    pokazPDF_INFO() {
      //dopisane
      this.pokazDialog = true;
      this.typAktywnegoFormularza = "pokaz_PDF_INFO";
      this.obiektKonfiguracyjny = {
        Nazwa_instrukcji: "INFO-3",
      };
    },
    wyborElementu(element) {
      this.wybranyElementTabeli = element;
    },
  },
};
</script>

<style>
.tabela_KBHPZEW {
  height: 78vh;
}
.tab-content mt-3 {
  width: 100%;
  height: 10vh !important;
}
.p-datatable-wrapper {
  overflow: auto !important;
  height: 60vh;
}
.tabela_KBHPZEW .md-card {
  box-shadow: none !important;
}
.tabela_KBHPZEW .md-title {
  font-weight: 900 !important;
  color: black !important;
  font-size: 1.2vh !important;
  padding: 10px;
}
.tabela_KBHPZEW .md-toolbar {
  height: 14vh;
}
.tabela_KBHPZEW .md-table-head {
  font-weight: bold;
  color: black !important;
  font-size: 1.1vh !important;
  text-align: left !important;
}
.tabela_KBHPZEW .md-table-head-label {
  text-align: left !important;
  font-size: 1.1vh !important;
}
.tabela_KBHPZEW .md-table-cell {
  font-size: 1.1vh !important;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-align: left;
  color: black;
  font-weight: 300;
  margin-top: 200px !important;
}
.tabela_KBHPZEW .ikony {
  cursor: pointer;
  margin-right: 40px;
}

.tabela_KBHPZEW .button {
  margin-right: 5px;
  border: 3px white ridge;
  font-weight: bold;
  font-size: 1.1vmin;
  min-width: 9vw;
}
.tabela_KBHPZEW .button:hover {
  background-color: #727171;
  text-decoration: none;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  color: white;
}
.tabela_KBHPZEW .menu_strony {
  border: 1px solid;
  margin-top: 5px;
  padding: 3px;
  background: #939393;
  width: 84.5%;
}
.tabela_KBHPZEW .menu_strony1 {
  border: 1px solid;
  margin-top: 5px;
  padding: 3px;
  background: #939393;
  width: 15%;
  margin-left: 0.5%;
}
.tabela_KBHPZEW .laczacy {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.md-table.md-theme-default .md-table-row.md-selected,
.md-table.md-theme-default .md-table-row.md-selected-single {
  background-color: #dddddd !important;
}
.PasekWyboru {
  background: #606060;
  padding: 2px;
}
.tabela_KBHPZEW .ikony {
  margin-right: 4px;
  margin-left: 4px;
  border: 1px solid !important;
  border-radius: 5px;
  padding: 5px;
}
.tabela_KBHPZEW .ikony:hover {
  background-color: black;
  text-decoration: none;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
.podpowiedz {
  font-size: 1.5vmin !important;
  font-weight: bold;
  background-color: black !important;
}
.tabela_KBHPZEW .nav-item {
  font-weight: bold;
  font-size: 1.2vh;
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: silver !important;
  color: black !important;
}
.tabela_KBHPZEW .md-input {
  width: 20vw !important;
  margin-bottom: 10px;
}
.tabela_KBHPZEW .p-datatable .p-datatable-tbody > tr > td {
  text-align: left !important;
  font-size: 1.2vh !important;
}
.btn {
  background-color: #606060 !important;
  padding: 0rem 0rem !important;
  border-color: #606060 !important;
}
</style>
