<template>
  <div>
  <tabelaWykazOcenaStanuBHP/>

  </div>
</template>

<script>
import tabelaWykazOcenaStanuBHP from '../../komponenty/strona-tabela-komponenty/BHP_ocena_stanu_BHP/tabela_ocena_stanu_BHP.vue'


export default {
  name: 'StronaTabelaWykazOcenaStanuBHP',
  components: {
       tabelaWykazOcenaStanuBHP,

  }

}
</script>
