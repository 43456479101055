<template >
  <div>
  <TabelaDokumentyBSP />
  </div>
</template>

<script>
import TabelaDokumentyBSP from '../../komponenty/strona-tabela-komponenty/BHP_badania_srodowiska_pracy/tabela_dokumentacja_BSP.vue'


export default {
  name: 'StronaTabelaDokumentyBSP',
  components: {
    TabelaDokumentyBSP,
    
  }

}
</script>

