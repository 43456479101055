<template>
  <div>
  <tabelaStanowiskaPrzeglad/>

  </div>
</template>

<script>
import tabelaStanowiskaPrzeglad from '../../komponenty/strona-tabela-komponenty/HR_stanowiska/tabela_przeglad_stanowiska.vue'


export default {
  name: 'StronaTabelaStanowiskaPrzeglad',
  components: {
       tabelaStanowiskaPrzeglad,
  }

}
</script>
