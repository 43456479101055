<template>

  <TabelaPrzegladSubstancjeNiebezpieczne />

</template>

<script>
import TabelaPrzegladSubstancjeNiebezpieczne from '../../komponenty/strona-tabela-komponenty/BHP_substancje_niebezpieczne/tabela_przeglad_substancji_niebezpiecznych.vue'



export default {
  name: 'StronaTabelaPrzegladSubstancjeNiebezpieczne',
  components: {
    TabelaPrzegladSubstancjeNiebezpieczne,

    }

}
</script>
