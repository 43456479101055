<template>
  <div class="FORM_SPRKWOCSK">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI OCENY SKUTECZNOŚCI DZIAŁAŃ DO UWAGI Z KONTROLI WEWNĘTRZNEJ BHP"
                : "FORMULARZ DODAWANIA OCENY SKUTECZNOŚCI DZIAŁAŃ DO UWAGI Z KONTROLI WEWNĘTRZNEJ BHP"
            }}
          </div>
        </md-card-header>

        <md-card-content>

           <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('Uwagi')">
                <label  for="Uwagi">Uwagi</label>
                <md-textarea v-model="form.Uwagi" md-autogrow md-counter="500"
                  name="Uwagi"
                  id="Uwagi"
                  autocomplete="Uwagi"
                  :disabled="sending || czyJestWTrybieEdycji"
                />
                <span class="md-error" v-if="!$v.form.Uwagi.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

 
          <div class="md-layout md-gutter">

                      <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('Data_zrealizowania')">
                <label for="Data_zrealizowania">Data zakończenia działań</label>
                <md-input v-model="form.Data_zrealizowania" 
                  type="date"
                  name="Data_zrealizowania"
                  id="Data_zrealizowania"
                  autocomplete="Data_zrealizowania"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Data_zrealizowania.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>


                      <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('Data_oceny')">
                <label for="Data_oceny">Data oceny</label>
                <md-input v-model="form.Data_oceny"
                 type="date"
                  name="Data_oceny"
                  id="Data_oceny"
                  autocomplete="Data_oceny"
           
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Data_oceny.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>
    
          <div class="md-layout md-gutter">

                      <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('Ocena')">
                <label for="Ocena">Ocena działań</label>
                <md-textarea v-model="form.Ocena" md-autogrow md-counter="200"
                  name="Ocena"
                  id="Ocena"
                  autocomplete="Ocena"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Ocena.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Ocena.maxlength"
                  >Wymagana ilość znaków od 1 do 200</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">

                      <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('Ocene_przeprowadzil')">
                <label for="Ocene_przeprowadzil">Ocene przeprowadzil</label>
                <md-input v-model="form.Ocene_przeprowadzil" md-counter="50"
                  name="Ocene_przeprowadzil"
                  id="Ocene_przeprowadzil"
                  autocomplete="Ocene_przeprowadzil"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Ocene_przeprowadzil.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Ocene_przeprowadzil.maxlength"
                  >Wymagana ilość znaków od 1 do 50</span
                >
              </md-field>
            </div>
          </div>

        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />


        <div style="text-align:center">
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            czyJestWTrybieEdycji
              ? "ZATWIERDŹ - EDYTOWANY WPIS"
              : "ZATWIERDŹ - UTWÓRZ WPIS"
          }}</md-button>
        </div>
      </md-card>
    </form>
  </div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import minLength from "vuelidate/lib/validators/minLength";
import { api } from "../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_maszyny",

  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      Uwagi: null,
      Data_zrealizowania: null,
      Data_oceny: null,
      Ocena: null,
      Ocene_przeprowadzil: null,
    },
    IdEdytowanegoElementu: null,
    userSaved: false,
    sending: false,
    lastUser: null,
    Dzialania: [],
  }),
  created: function () {
    if (this.obiektKonfiguracyjny.akcja === "edycja") {
      //edycja
      api
        .pobieranieKontroliDzialaniaOcenaEdycja(this.obiektKonfiguracyjny.Id)
        .then((odpowiedz) => {
          const {
            Id,
            Uwagi,
            Data_zrealizowania,
            Data_oceny,
            Ocena,
            Ocene_przeprowadzil,
          } = odpowiedz.data.dane[0];
          this.IdEdytowanegoElementu = Id;
          this.form.Id = Uwagi;
          this.form.Uwagi = Uwagi;
          this.form.Data_zrealizowania = Data_zrealizowania;
          this.form.Data_oceny = Data_oceny;
          this.form.Ocena = Ocena;
          this.form.Ocene_przeprowadzil = Ocene_przeprowadzil;
        })
        .catch((error) => {
          console.log(error);
        });
    }

    api
      .PobierzNumerSPW()
      .then((odpowiedz) => {
        this.Dzialania = odpowiedz.data.dane;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  validations: {
    form: {
      Uwagi: {
        required,
      },
      Data_zrealizowania: {
        required,
      },
      Data_oceny: {
        required,
      },
      Ocena: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(200),
      },
      Ocene_przeprowadzil: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(50),
      },
    },
  },
  computed: {
    czyJestWTrybieEdycji: function () {
      return this.obiektKonfiguracyjny.akcja === "edycja";
    },
  },

  methods: {
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },


    clearForm() {
      this.$v.$reset();
      this.form.Uwagi = null;
      this.form.Data_zrealizowania = null;
      this.form.Data_oceny = null;
      this.form.Ocena = null;
      this.form.Ocene_przeprowadzil = null;

    },

    zapiszFormularz() {
      this.sending = true;

      if (this.czyJestWTrybieEdycji) {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Uwagi", this.form.Uwagi);
        dane.append("Data_zrealizowania", this.form.Data_zrealizowania);
        dane.append("Data_oceny", this.form.Data_oceny);
        dane.append("Ocena", this.form.Ocena);
        dane.append("Ocene_przeprowadzil", this.form.Ocene_przeprowadzil);

        api
          .zapiszKontroliDzialaniaOcenaUpdate(dane, this.IdEdytowanegoElementu)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Uwagi", this.form.Uwagi);
        dane.append("Data_zrealizowania", this.form.Data_zrealizowania);
        dane.append("Data_oceny", this.form.Data_oceny);
        dane.append("Ocena", this.form.Ocena);
        dane.append("Ocene_przeprowadzil", this.form.Ocene_przeprowadzil);

        api
          .zapiszDzialaniaPozaAuditOcena(dane)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    naszWalidacjaFormularza() {
      if (this.czyJestWTrybieEdycji) {
        return (
          !this.$v.form.Uwagi.$invalid &&
          !this.$v.form.Data_zrealizowania.$invalid &&
          !this.$v.form.Data_oceny.$invalid &&
          !this.$v.form.Ocena.$invalid &&
          !this.$v.form.Ocene_przeprowadzil.$invalid
        );
      } else {
        return !this.$v.$invalid;
      }
    },

    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>


<style>
.FORM_SPRKWOCSK .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
.FORM_SPRKWOCSK
{
  overflow:auto;
}
.FORM_SPRKWOCSK .md-layout .md-gutter {
  min-width: 400px!important; /* szerokość modala*/
  max-width: 100%;
  min-height: 7vh; /* wysokość pomiędzy mierszami w modalu*/
}
.md-layout {
  padding-right: 0px !important;
}
.FORM_SPRKWOCSK .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}

.FORM_SPRKWOCSK .md-field {
  /*opisy w formularzu*/
  width: 100%!important;
  min-height: 58px !important;
  margin: 10px 24px !important;
  padding-top: 35px !important;
}
.FORM_SPRKWOCSK .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}


.FORM_SPRKWOCSK {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
}
.FORM_SPRKWOCSK .md-primary {
  border: 1px solid !important;

  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}
.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important; /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}

.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}
.FORM_SPRKWOCSK .md-list-item-text {
  font-size: 1.7vmin !important;
}
.FORM_SPRKWOCSK .md-card-actions .md-button {
  margin-right: 50px !important;
}
.FORM_SPRKWOCSK .md-field.md-has-textarea:not(.md-autogrow) label {
    top: 16px;
    left: 0px;
}
</style>