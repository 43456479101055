<template >
  <div>
  <TabelaPrzegladyDokumentyBSP />
  </div>
</template>

<script>
import TabelaPrzegladyDokumentyBSP from '../../komponenty/strona-tabela-komponenty/BHP_badania_srodowiska_pracy/tabela_dokumentacja_BSP_przeglady.vue'


export default {
  name: 'StronaTabelaPrzegladyDokumentyBSP',
  components: {
    TabelaPrzegladyDokumentyBSP,
    
  }

}
</script>

