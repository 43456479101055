import { render, staticRenderFns } from "./Kafelek_wyboru_WST.vue?vue&type=template&id=1740c5e2&scoped=true&"
import script from "./Kafelek_wyboru_WST.vue?vue&type=script&lang=js&"
export * from "./Kafelek_wyboru_WST.vue?vue&type=script&lang=js&"
import style0 from "./Kafelek_wyboru_WST.vue?vue&type=style&index=0&id=1740c5e2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1740c5e2",
  null
  
)

export default component.exports