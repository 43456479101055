<template>
  <div class="tabela_przeglad_zarzadzania">
    <div class="laczacy">
      <div class="menu_strony">
        <button
          class="button"
          font-scale="2.5"
          color="white"
          @click="$router.push(`../przeglad_zarzadzania`)"
        >
          POWRÓT DO WYKAZU PRZEGLĄDÓW ZARZĄDZANIA
        </button>
      </div>
      <div class="menu_strony1">
        <b>
          <b-icon-printer-fill
            class="ikony"
            font-scale="2.1"
            color="white"
            @click="generujPdf"
          >
          </b-icon-printer-fill>
          <md-tooltip class="podpowiedz" md-direction="top"
            >GENERUJ PDF / DRUKUJ</md-tooltip
          >
        </b>

        <b>
          <b-icon-info-circle-fill
            class="ikony"
            font-scale="2.1"
            color="white"
            @click="pokazPDF_INFO"
          >
          </b-icon-info-circle-fill>
          <md-tooltip class="podpowiedz" md-direction="top">INFO</md-tooltip>
        </b>
      </div>
    </div>
    <modal
      v-if="pokazDialog"
      :rodzajFormularza="typAktywnegoFormularza"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />

    <md-table
      md-height="45vh"
      @md-selected="wyborElementu"
      v-model="searched"
      md-sort="Nazwa_maszyny"
      md-sort-order="asc"
      md-model-id="Id_maszyny"
      md-card
      md-fixed-header
    >
      <md-table-toolbar>
        <div class="d-flex flex-column w-100">
          <div class="row">
            <div class="col-4"></div>
            <div class="col-4">
              <h1 class="md-title">PROTOKÓŁ Z PRZEGLĄDU ZARZĄDZANIA</h1>
            </div>

            <div class="col-4">
              <md-field md-clearable class="ml-auto mr-0">
                <md-input
                  placeholder="Szukaj..."
                  v-model="search"
                  @input="searchOnTable"
                />
              </md-field>
            </div>
          </div>

          <div class="PasekWyboru" v-if="wybranyElementTabeli">
            <div
              style="text-align: right; margin-top: 0.3vh; margin-bottom: 0.3vh"
            >
              <b>
                <b-button
                  :disabled="!autoryzacja.czyMozeUsuwac"
                  @click="usuwanieElementu(wybranyElementTabeli.Id)"
                >
                  <b-icon-trash class="ikony" font-scale="1.8" color="white">
                  </b-icon-trash
                ></b-button>
                <md-tooltip class="podpowiedz" md-direction="top">{{
                  autoryzacja.czyMozeUsuwac
                    ? "USUWANIE ELEMENTU"
                    : "BRAK UPRAWNIEŃ"
                }}</md-tooltip>
              </b>
              <b>
                <b-button
                  :disabled="!autoryzacja.czyMozeEdytowac"
                  @click="
                    pokazDialog = true;
                    typAktywnegoFormularza =
                      'formularz_dodawanie_protokolu_przeglad_zarzadzania';
                    obiektKonfiguracyjny = {
                      Id: wybranyElementTabeli.Id,
                      akcja: 'edycja',
                    };
                  "
                >
                  <b-icon-pencil-square
                    class="ikony"
                    font-scale="1.80"
                    color="white"
                  >
                  </b-icon-pencil-square
                ></b-button>
                <md-tooltip class="podpowiedz" md-direction="top">{{
                  autoryzacja.czyMozeUsuwac
                    ? "EDYCJA ELEMENTU"
                    : "BRAK UPRAWNIEŃ"
                }}</md-tooltip>
              </b>
            </div>
          </div>
        </div>
      </md-table-toolbar>

      <md-table-empty-state
        v-if="czyPobranoDane"
        md-label="Nie posiadasz protokołu dla wybranego przeglądu zarządzania"
      >
      <button
        class="button"
        :disabled="!autoryzacja.czyMozeDodawac"
        @click="
          pokazDialog = true;
          typAktywnegoFormularza =
            'formularz_dodawanie_protokolu_przeglad_zarzadzania';
          obiektKonfiguracyjny = {
            Id: null,
            akcja: 'dodawanie',
          };
        "
      >
        DODAWANIE SZCZEGÓŁÓW DO PROTOKOŁU Z PRZEGLĄDU ZARZĄDZANIA
      </button>
        <md-tooltip class="podpowiedz" md-direction="top">{{
            !autoryzacja.czyMozeDodawac
              ? "BRAK UPRAWNIEŃ"
              : "DODAWANIE SZCZEGÓŁÓW DO PROTOKOŁU Z PRZEGLĄDU ZARZĄDZANIA"
          }}</md-tooltip>
      </md-table-empty-state>

      <md-table-row
        slot="md-table-row"
        slot-scope="{ item }"
        md-selectable="single"
        :key="item.id"
      >
        <md-table-row>
          <md-table-cell
            >Informacje o stanie realizacji poprzednich ustaleń</md-table-cell
          >
          <md-table-cell> : </md-table-cell>
          <md-table-cell>{{ item.A1 }}</md-table-cell>
        </md-table-row>

        <md-table-row>
          <md-table-cell
            >Informacje o ewentualnej zmianie czynników zewnętrznych i
            wewnętrznych</md-table-cell
          >
          <md-table-cell> : </md-table-cell>
          <md-table-cell>{{ item.A2 }}</md-table-cell>
        </md-table-row>

        <md-table-row>
          <md-table-cell
            >Informacje o stopniu realizacji polityki systemu zarządzania oraz
            stopnia realizacji celów</md-table-cell
          >
          <md-table-cell> : </md-table-cell>
          <md-table-cell>{{ item.A3 }}</md-table-cell>
        </md-table-row>

        <md-table-row>
          <md-table-cell
            >Ocena działań podjętych w celu uwzględnienia ryzyk i
            szans</md-table-cell
          >
          <md-table-cell> : </md-table-cell>
          <md-table-cell>{{ item.A4 }}</md-table-cell>
        </md-table-row>

        <md-table-row>
          <md-table-cell
            >Wyniki wewnętrznych audytów systemu zarządzania</md-table-cell
          >
          <md-table-cell> : </md-table-cell>
          <md-table-cell>{{ item.A5 }}</md-table-cell>
        </md-table-row>

        <md-table-row>
          <md-table-cell
            >Wyniki zewnętrznych audytów systemu zarządzania: wyniki audytów
            jednostki certyfikującej</md-table-cell
          >
          <md-table-cell> : </md-table-cell>
          <md-table-cell>{{ item.A6 }}</md-table-cell>
        </md-table-row>

        <md-table-row>
          <md-table-cell>Zmiany dotyczących Systemu Zarządzania</md-table-cell>
          <md-table-cell> : </md-table-cell>
          <md-table-cell>{{ item.A7 }}</md-table-cell>
        </md-table-row>

        <md-table-row>
          <md-table-cell
            >Informacje o środowiskowych efektach swojej
            działalności</md-table-cell
          >
          <md-table-cell> : </md-table-cell>
          <md-table-cell>{{ item.A8 }}</md-table-cell>
        </md-table-row>

        <md-table-row>
          <md-table-cell>Adekwatność zasobów</md-table-cell>
          <md-table-cell> : </md-table-cell>
          <md-table-cell>{{ item.A9 }}</md-table-cell>
        </md-table-row>

        <md-table-row>
          <md-table-cell
            >Informacja o komunikacji ze stron zainteresowanych</md-table-cell
          >
          <md-table-cell> : </md-table-cell>
          <md-table-cell>{{ item.A10 }}</md-table-cell>
        </md-table-row>

        <md-table-row>
          <md-table-cell
            >Istotne informacje przekazywane przez strony
            zainteresowane</md-table-cell
          >
          <md-table-cell> : </md-table-cell>
          <md-table-cell>{{ item.A11 }}</md-table-cell>
        </md-table-row>

        <md-table-row>
          <md-table-cell
            >Informacja o szansach ciągłego doskonalenia</md-table-cell
          >
          <md-table-cell> : </md-table-cell>
          <md-table-cell>{{ item.A12 }}</md-table-cell>
        </md-table-row>

        <md-table-row>
          <md-table-cell
            >Reklamacje i uwagi klientów, dane dotyczące zadowolenia klienta
            oraz informacje zwrotne od zewnętrznych stron
            zainteresowanych</md-table-cell
          >
          <md-table-cell> : </md-table-cell>
          <md-table-cell>{{ item.A13 }}</md-table-cell>
        </md-table-row>

        <md-table-row>
          <md-table-cell
            >Dane dotyczące osiągania celów Systemu Zarządzania</md-table-cell
          >
          <md-table-cell> : </md-table-cell>
          <md-table-cell>{{ item.A14 }}</md-table-cell>
        </md-table-row>

        <md-table-row>
          <md-table-cell
            >Informacje o efektach funkcjonowania procesów i dane dotyczące
            zgodności</md-table-cell
          >
          <md-table-cell> : </md-table-cell>
          <md-table-cell>{{ item.A15 }}</md-table-cell>
        </md-table-row>

        <md-table-row>
          <md-table-cell
            >Informacja o ocenie zgodności z wymaganiami prawnymi i innymi
            wymaganiami</md-table-cell
          >
          <md-table-cell> : </md-table-cell>
          <md-table-cell>{{ item.A16 }}</md-table-cell>
        </md-table-row>

        <md-table-row>
          <md-table-cell>Wyniki monitorowania i pomiarów</md-table-cell>
          <md-table-cell> : </md-table-cell>
          <md-table-cell>{{ item.A17 }}</md-table-cell>
        </md-table-row>

        <md-table-row>
          <md-table-cell
            >Analizy jakości zrealizowanych zleceń oraz trendy incydentów,
            niezgodności i ciągłego doskonalenia</md-table-cell
          >
          <md-table-cell> : </md-table-cell>
          <md-table-cell>{{ item.A18 }}</md-table-cell>
        </md-table-row>

        <md-table-row>
          <md-table-cell
            >Dane dotyczące prowadzenia działań korygujących i działań
            odnoszących się do ryzyk i szans</md-table-cell
          >
          <md-table-cell> : </md-table-cell>
          <md-table-cell>{{ item.A19 }}</md-table-cell>
        </md-table-row>

        <md-table-row>
          <md-table-cell
            >Problemy związane z dostawcami oraz wyniki okresowej oceny
            dostawców</md-table-cell
          >
          <md-table-cell> : </md-table-cell>
          <md-table-cell>{{ item.A20 }}</md-table-cell>
        </md-table-row>

        <md-table-row>
          <md-table-cell
            >Wyniki analiz wypadków, chorób zawodowych i zdarzeń potencjalnie
            wypadkowych</md-table-cell
          >
          <md-table-cell> : </md-table-cell>
          <md-table-cell>{{ item.A21 }}</md-table-cell>
        </md-table-row>

        <md-table-row>
          <md-table-cell
            >Informacja dotycząca współudziału pracowników i
            konsultacji</md-table-cell
          >
          <md-table-cell> : </md-table-cell>
          <md-table-cell>{{ item.A22 }}</md-table-cell>
        </md-table-row>

        <md-table-row>
          <md-table-cell
            >Wyniki działalności BHP uzyskane przez Organizację</md-table-cell
          >
          <md-table-cell> : </md-table-cell>
          <md-table-cell>{{ item.A23 }}</md-table-cell>
        </md-table-row>

        <md-table-row>
          <md-table-cell
            >Informacje o realizacji programów szkoleń</md-table-cell
          >
          <md-table-cell> : </md-table-cell>
          <md-table-cell>{{ item.A24 }}</md-table-cell>
        </md-table-row>

        <md-table-row>
          <md-table-cell>Nowe cele Systemu Zarządzania – projekt</md-table-cell>
          <md-table-cell> : </md-table-cell>
          <md-table-cell>{{ item.A25 }}</md-table-cell>
        </md-table-row>

        <md-table-row>
          <md-table-cell
            >Informacje dotyczące planowanych zmian, propozycje zmian do
            polityki Systemu Zarządzania oraz Zintegrowanego Systemu
            Zarządzania</md-table-cell
          >
          <md-table-cell> : </md-table-cell>
          <md-table-cell>{{ item.A26 }}</md-table-cell>
        </md-table-row>

        <md-table-row>
          <md-table-cell>Dane dotyczące doskonalenia</md-table-cell>
          <md-table-cell> : </md-table-cell>
          <md-table-cell>{{ item.A27 }}</md-table-cell>
        </md-table-row>

        <md-table-row>
          <md-table-cell
            >Doskonalenie efektywności Zintegrowanego Systemu Zarządzania oraz
            jego procesów uwzględniając szanse ciągłego
            doskonalenia</md-table-cell
          >
          <md-table-cell> : </md-table-cell>
          <md-table-cell>{{ item.A28 }}</md-table-cell>
        </md-table-row>

        <md-table-row>
          <md-table-cell
            >Doskonalenie wyrobu/usług związanych z wymaganiami
            klienta</md-table-cell
          >
          <md-table-cell> : </md-table-cell>
          <md-table-cell>{{ item.A29 }}</md-table-cell>
        </md-table-row>

        <md-table-row>
          <md-table-cell>Potrzebne środki i zasoby</md-table-cell>
          <md-table-cell> : </md-table-cell>
          <md-table-cell>{{ item.A30 }}</md-table-cell>
        </md-table-row>

        <md-table-row>
          <md-table-cell
            >Podejmowanie działań, jeżeli są potrzebne</md-table-cell
          >
          <md-table-cell> : </md-table-cell>
          <md-table-cell>{{ item.A31 }}</md-table-cell>
        </md-table-row>

        <md-table-row>
          <md-table-cell
            >Wnioski dotyczące stałej przydatności, adekwatności i skuteczności
            Systemu Zarządzania</md-table-cell
          >
          <md-table-cell> : </md-table-cell>
          <md-table-cell>{{ item.A32 }}</md-table-cell>
        </md-table-row>

        <md-table-row>
          <md-table-cell>Wszelkie zmiany w Systemie Zarządzania</md-table-cell>
          <md-table-cell> : </md-table-cell>
          <md-table-cell>{{ item.A33 }}</md-table-cell>
        </md-table-row>

        <md-table-row>
          <md-table-cell
            >Sytuacje, gdy cele Systemu Zarządzania nie zostały
            osiągnięte</md-table-cell
          >
          <md-table-cell> : </md-table-cell>
          <md-table-cell>{{ item.A34 }}</md-table-cell>
        </md-table-row>

        <md-table-row>
          <md-table-cell
            >Możliwość poprawy integracji Systemu Zarządzania z innymi
            procesami</md-table-cell
          >
          <md-table-cell> : </md-table-cell>
          <md-table-cell>{{ item.A35 }}</md-table-cell>
        </md-table-row>

        <md-table-row>
          <md-table-cell
            >Implikacja dla strategicznego kierunku działania
            Organizacji</md-table-cell
          >
          <md-table-cell> : </md-table-cell>
          <md-table-cell>{{ item.A36 }}</md-table-cell>
        </md-table-row>
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
import modal from "../../strona-formularze/modal.vue";
import { api } from "./../../../serwisy/api.serwis";
import { authorizationService } from "./../../../serwisy/authorization.servis";
import jsPDF from "jspdf";
import "jspdf-autotable";

const toLower = (text) => {
  return text.toString().toLowerCase();
};

const search = (items, term) => {
  if (term) {
    return items.filter((item) => {
      const elementWyszukiwany = toLower(term);
      const Nr_procesu = toLower(item.Nr_procesu);
      const Nazwa_procesu = toLower(item.Nazwa_procesu);
      const Osoba_odpowiedzialna = toLower(item.Osoba_odpowiedzialna);
      return (
        Nr_procesu.includes(elementWyszukiwany) ||
        Nazwa_procesu.includes(elementWyszukiwany) ||
        Osoba_odpowiedzialna.includes(elementWyszukiwany)
      );
    });
  }

  return items;
};

export default {
  name: "tabela_wykaz_procesow",
  data: () => ({
    czyPobranoDane: false,
    search: null,
    searched: [],
    Procesy: [],
    pokazDialog: false,
    typAktywnegoFormularza: null,
    obiektKonfiguracyjny: null,
    wybranyElementTabeli: null,
    autoryzacja: {
      czyMozeDodawac: false,
      czyMozeUsuwac: false,
      czyMozeEdytowac: false,
      czyMozePrzegladac: false,
    },
  }),
  components: {
    modal,
  },
  mounted: function() {
    this.pobierzDaneDoTabeli();
    this.pobierzDaneAutoryzacyjne();
  },
  computed: {
    //informacja czy jest w trybie edycji
    czyJestWTrybieEdycji() {
      return this.$router.params.Id ? true : false;
    },
  },
  methods: {
    generujPdf() {
      const doc = new jsPDF("p", "mm", "a4");

      doc.setFont("Aleo-Regular");

      doc.text("Protokół z przeglądu zarządzania", 14, 10);
      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "silver",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "DANE SZCZEGÓŁOWE PROTOKOŁU Z PRZEGLĄDU ZARZĄDZANIA",
              colSpan: 4,
              styles: {
                halign: "center",
                fillColor: "#656565",
                textColor: "white",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Nr przeglądu",
            "Data przeglądu",
            "Osoba odpowiedzialna",
            "Osoba zatwierdzająca",
          ],
        ],
        body: this.searched.map((Procesy) => {
          return [
            Procesy.Nr_przegladu,
            Procesy.Data,
            Procesy.Osoba_odpowiedzialna,
            Procesy.Osoba_zatwierdzajaca,
          ];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "silver",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [["WYMAGANIA NORMOWE", "INFORMACJE"]],
        body: [
          [
            "Informację o stanie realizacji poprzednich ustaleń",
            this.Procesy[0].A1,
          ],
          [
            "Informację o ewentualnej zmianie czynników zewnętrznych i wewnętrznych",
            this.Procesy[0].A2,
          ],
          [
            "Informację o stopniu realizacji polityki systemu zarządzania oraz stopnia realizacji celów",
            this.Procesy[0].A3,
          ],
          [
            "Ocenę działań podjętych w celu uwzględnienia ryzyk i szans",
            this.Procesy[0].A4,
          ],
          [
            "Wyniki wewnętrznych audytów systemu zarządzania",
            this.Procesy[0].A5,
          ],
          [
            "Wyniki zewnętrznych audytów systemu zarządzania: wyniki audytów jednostki certyfikującej",
            this.Procesy[0].A6,
          ],
          ["Zmiany dotyczących Systemu Zarządzania", this.Procesy[0].A7],
          [
            "Informacje o środowiskowych efektach swojej działalności",
            this.Procesy[0].A8,
          ],
          ["Adekwatności zasobów", this.Procesy[0].A9],
          [
            "Informacja o komunikacji ze stron zainteresowanych",
            this.Procesy[0].A10,
          ],
          [
            "Istotnych informacji przekazywanych przez strony zainteresowane",
            this.Procesy[0].A11,
          ],
          ["Informacja o szansach ciągłego doskonalenia", this.Procesy[0].A12],
          [
            "Reklamacje i uwagi klientów, dane dotyczące zadowolenia klienta oraz informacje zwrotne od zewnętrznych stron zainteresowanych",
            this.Procesy[0].A13,
          ],
          [
            "Dane dotyczące osiągania celów Systemu Zarządzania",
            this.Procesy[0].A14,
          ],
          [
            "Informację o efektach funkcjonowania procesów i dane dotyczące zgodności",
            this.Procesy[0].A15,
          ],
          [
            "Informacja o ocenie zgodności z wymaganiami prawnymi i innymi wymaganiami",
            this.Procesy[0].A16,
          ],
          ["Wyniki monitorowania i pomiarów", this.Procesy[0].A17],
          [
            "Analizy jakości zrealizowanych zleceń oraz trendy incydentów,niezgodności i ciągłego doskonalenia",
            this.Procesy[0].A18,
          ],
          [
            "Dane dotyczące prowadzenia działań korygujących i działań odnoszących się do ryzyk i szans",
            this.Procesy[0].A19,
          ],
          [
            "Problemy związane z dostawcami oraz wyniki okresowej oceny dostawców",
            this.Procesy[0].A20,
          ],
          [
            "Wyniki analiz wypadków, chorób zawodowych i zdarzeń potencjalnie wypadkowych",
            this.Procesy[0].A21,
          ],
          [
            "Informacja dotycząca współudziału pracowników i konsultacji",
            this.Procesy[0].A22,
          ],
          [
            "Wyniki działalności BHP uzyskane przez Organizację",
            this.Procesy[0].A23,
          ],
          ["Informacje o realizacji programów szkoleń", this.Procesy[0].A24],
          ["Nowe cele Systemu Zarządzania – projekt", this.Procesy[0].A25],
          [
            "Informacje dotyczące planowanych zmian, propozycje zmian do polityki Systemu Zarządzania oraz Zintegrowanego Systemu Zarządzania",
            this.Procesy[0].A26,
          ],
          ["Dane dotyczące doskonalenia", this.Procesy[0].A27],
          [
            "Doskonalenie efektywności Zintegrowanego Systemu Zarządzania oraz jego procesów uwzględniając szanse ciągłego doskonalenia",
            this.Procesy[0].A28,
          ],
          [
            "Doskonalenie wyrobu/usług związanych z wymaganiami klienta",
            this.Procesy[0].A29,
          ],
          ["Potrzebne środki i zasoby", this.Procesy[0].A30],
          ["Podejmowanie działań, jeżeli są potrzebne", this.Procesy[0].A31],
          [
            "Wnioski dotyczące stałej przydatności, adekwatności i skuteczności Systemu Zarządzania",
            this.Procesy[0].A32,
          ],
          ["Wszelkie zmiany w Systemie Zarządzania", this.Procesy[0].A33],
          [
            "Sytuacje, gdy cele Systemu Zarządzania nie zostały osiągnięte",
            this.Procesy[0].A34,
          ],
          [
            "Możliwość poprawy integracji Systemu Zarządzania z innymi procesami",
            this.Procesy[0].A35,
          ],
          [
            "Implikacja dla strategicznego kierunku działania Organizacji",
            this.Procesy[0].A36,
          ],
        ],
      });

      doc.save("protokol_przeglad_zarzadzania.pdf");
    },

    pobierzDaneAutoryzacyjne() {
      authorizationService
        .getInfo("StronaTabelaPrzegladZarzadzania", 4)

        .then((obiektOdpowiedzi) => {
          const dane = obiektOdpowiedzi.data.dostep;
          this.autoryzacja = {
            czyMozeDodawac: Boolean(
              dane.find(({ Id_operacji }) => Id_operacji == "1")
            ),
            czyMozeUsuwac: Boolean(
              dane.find(({ Id_operacji }) => Id_operacji == "2")
            ),
            czyMozeEdytowac: Boolean(
              dane.find(({ Id_operacji }) => Id_operacji == "3")
            ),
            czyMozePrzegladac: Boolean(
              dane.find(({ Id_operacji }) => Id_operacji == "4")
            ),
          };
        })
        .catch((error) => {
          console.log(error);
        });
    },
    pobierzDaneDoTabeli() {
      const { id } = this.$route.params;

      console.log(this.$route);

      api
        .pobieraniePrzegladZarzadzania(id)
        .then((obiektOdpowiedzi) => {
          console.log(obiektOdpowiedzi);
          this.Procesy = obiektOdpowiedzi.data.dane;
          this.searched = this.Procesy;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.czyPobranoDane = true;
        });
    },
    funkcjaZamykajacaDialog(sukces) {
      this.pokazDialog = false;
      this.typAktywnegoFormularza = null;
      this.obiektKonfiguracyjny = null;
      if (sukces) {
        this.pobierzDaneDoTabeli();
      }
    },
    newUser() {
      window.alert("Noop");
    },
    searchOnTable() {
      this.searched = search(this.Procesy, this.search);
    },
    usuwanieElementu(Id) {
      this.pokazDialog = true;
      this.typAktywnegoFormularza = "usuwanie";
      this.obiektKonfiguracyjny = {
        Id,
        typ: "przeglad_protokol",
      };
    },
    pokazPDF_INFO() {
      //dopisane
      this.pokazDialog = true;
      this.typAktywnegoFormularza = "pokaz_PDF_INFO";
      this.obiektKonfiguracyjny = {
        Nazwa_instrukcji: "INFO-3",
      };
    },
    wyborElementu(element) {
      this.wybranyElementTabeli = element;
    },
  },
};
</script>

<style>
.tabela_przeglad_zarzadzania .md-field {
  max-width: 300px;
}

.tabela_przeglad_zarzadzania .tabela {
  width: 100%;
}

.tabela_przeglad_zarzadzania .md-card {
  box-shadow: none !important;
}

.tabela_przeglad_zarzadzania .md-title {
  font-weight: 900 !important;
  color: black !important;
  font-size: 1.4vh !important;
  padding: 10px;
}

.tabela_przeglad_zarzadzania .md-toolbar {
  height: 14vh;
}

.tabela_przeglad_zarzadzania .md-table-head {
  font-weight: bold;
  color: black !important;
  font-size: 1.1vh !important;
  text-align: left !important;
}

.tabela_przeglad_zarzadzania .md-table-head-label {
  text-align: left !important;
  font-size: 1.1vh !important;
}

.tabela_przeglad_zarzadzania .md-table-cell {
  font-size: 1.1vh !important;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-align: center;
  color: black;
  font-weight: 300;
  margin-top: 200px !important;
  background: white !important;
}

.tabela_przeglad_zarzadzania .ikony {
  cursor: pointer;
  margin-right: 40px;
}

.tabela_przeglad_zarzadzania .md-table-cell:nth-child(1) {
  width: 30vw;
  text-align: left !important;
}

.tabela_przeglad_zarzadzania .md-table-cell:nth-child(2) {
  width: 5vw;
  text-align: left !important;
}

.tabela_przeglad_zarzadzania .md-table-cell:nth-child(3) {
  width: 55vw;
  text-align: left !important;
}

.tabela_przeglad_zarzadzania .button {
  margin-right: 5px;
  border: 3px white ridge;
  font-weight: bold;
  font-size: 1.1vmin;
  min-width: 9vw;
}

.tabela_przeglad_zarzadzania .button:hover {
  background-color: #727171;
  text-decoration: none;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  color: white;
}

.tabela_przeglad_zarzadzania .menu_strony {
  border: 1px solid;
  margin-top: 5px;
  padding: 3px;
  background: #939393;
  width: 84.5%;
}

.tabela_przeglad_zarzadzania .menu_strony1 {
  border: 1px solid;
  margin-top: 5px;
  padding: 3px;
  background: #939393;
  width: 15%;
  margin-left: 0.5%;
}

.tabela_przeglad_zarzadzania .laczacy {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.md-table.md-theme-default .md-table-row.md-selected,
.md-table.md-theme-default .md-table-row.md-selected-single {
  background-color: #dddddd !important;
}

.PasekWyboru {
  background: #606060;
}

.tabela_przeglad_zarzadzania .ikony {
  margin-right: 4px;
  margin-left: 4px;
  border: 1px solid !important;
  border-radius: 5px;
  padding: 5px;
}

.tabela_przeglad_zarzadzania .ikony:hover {
  background-color: black;
  text-decoration: none;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.podpowiedz {
  font-size: 1.5vmin !important;
  font-weight: bold;
  background-color: black !important;
}
.btn {
  background-color: #606060 !important;
  padding: 0rem 0rem !important;
  border-color: #606060 !important;
}
</style>
