<template>
  <div class="form_ZBHP">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI ZAGROŻEŃ BHP"
                : "FORMULARZ DODAWANIA ZAGROŻEŃ BHP"
            }}
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M4" :class="getValidationClass('Id_procesu')">
                <label for="Id_procesu">Wybór procesu</label>

                <md-select
                  v-model="form.Id_procesu"
                  :disabled="sending"
                  name="Id_procesu"
                  id="Id_procesu"
                  autocomplete="Id_procesu"
                  md-dense
                >
                  <md-option
                    v-for="(proces, index) in Procesy"
                    :key="index"
                    :value="proces.Id"
                    >{{
                      ` ${proces.Nr_procesu} - ${proces.Nazwa_procesu} `
                    }}</md-option
                  >
                </md-select>
                <span class="md-error" v-if="!$v.form.Id_procesu.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M4" :class="getValidationClass('Id_poziom')">
                <label for="Id_poziom">Wybór poziomu zagrożenia</label>

                <md-select
                  v-model="form.Id_poziom"
                  :disabled="sending"
                  name="Id_poziom"
                  id="Id_poziom"
                  autocomplete="Id_poziom"
                  md-dense
                >
                  <md-option
                    v-for="(poziom, index) in Poziomy"
                    :key="index"
                    :value="poziom.Id"
                    >{{ ` ${poziom.Poziom_ryzyka} ` }}</md-option
                  >
                </md-select>
                <span class="md-error" v-if="!$v.form.Id_poziom.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field class="M4" :class="getValidationClass('Id_strategia')">
                <label for="Id_strategia">Wybór strategii</label>

                <md-select
                  v-model="form.Id_strategia"
                  :disabled="sending"
                  name="Id_strategia"
                  id="Id_strategia"
                  autocomplete="Id_strategia"
                  md-dense
                >
                  <md-option
                    v-for="(strategia, index) in Strategie"
                    :key="index"
                    :value="strategia.Id"
                    >{{ ` ${strategia.Strategia} ` }}</md-option
                  >
                </md-select>
                <span class="md-error" v-if="!$v.form.Id_strategia.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M5"
                :class="getValidationClass('Opis_zdarzenia')"
              >
                <label for="Opis_zdarzenia">Opis zdarzenia</label>
                <md-textarea
                  name="Opis_zdarzenia"
                  id="Opis_zdarzenia"
                  autocomplete="Opis_zdarzenia"
                  md-autogrow
                  md-counter="500"
                  v-model="form.Opis_zdarzenia"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Opis_zdarzenia.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Opis_zdarzenia.maxlength"
                  >Wymagana ilość znaków od 1 do 500</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('Skutek')">
                <label for="Skutek">Skutek</label>
                <md-textarea
                  name="Skutek"
                  id="Skutek"
                  autocomplete="Skutek"
                  md-autogrow
                  md-counter="500"
                  v-model="form.Skutek"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Skutek.required"
                  >Pole jest wymagane</span
                >
                <span class="md-error" v-else-if="!$v.form.Skutek.maxlength"
                  >Wymagana ilość znaków od 1 do 500</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('Dzialania')">
                <label for="Dzialania">Działania</label>
                <md-textarea
                  name="Dzialania"
                  id="Dzialania"
                  autocomplete="Dzialania"
                  md-autogrow
                  md-counter="500"
                  v-model="form.Dzialania"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Dzialania.required"
                  >Pole jest wymagane</span
                >
                <span class="md-error" v-else-if="!$v.form.Dzialania.maxlength"
                  >Wymagana ilość znaków od 1 do 500</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M5"
                :class="getValidationClass('Osoba_odpowiedzialna')"
              >
                <label for="Osoba_odpowiedzialna">Osoba odpowiedzialna</label>
                <md-input
                  name="Osoba_odpowiedzialna"
                  id="Osoba_odpowiedzialna"
                  
                  md-counter="50"
                  v-model="form.Osoba_odpowiedzialna"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Osoba_odpowiedzialna.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Osoba_odpowiedzialna.maxlength"
                  >Wymagana ilość znaków od 1 do 50</span
                >
              </md-field>
            </div>
          </div>
          <div
            style="
              color: red;
              margin-top: 30px;
              text-align: center;
              font-weight: bold;
            "
          >
            PAMIĘTAJ O USTANOWIENIU DATY PRZEGLĄDU ZAGROŻEŃ BHP
          </div>
        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

        <div style="text-align: center">
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            czyJestWTrybieEdycji
              ? "ZATWIERDŹ - EDYTOWANY WPIS"
              : "ZATWIERDŹ - UTWÓRZ WPIS"
          }}</md-button>
        </div>
      </md-card>
    </form>
  </div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import minLength from "vuelidate/lib/validators/minLength";
import { api } from "./../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_maszyny",

  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      Id_procesu: null,
      Id_poziom: null,
      Id_strategia: null,
      Opis_zdarzenia: null,
      Skutek: null,
      Dzialania: null,
      Osoba_odpowiedzialna: null,
    },
    IdEdytowanegoElementu: null,
    userSaved: false,
    sending: false,
    lastUser: null,
    Procesy: [],
    Poziomy: [],
    Strategie: [],
  }),
  created: function () {
    if (this.obiektKonfiguracyjny.akcja === "edycja") {
      //edycja
      api
        .pobieranieZagrozeniaBHPEdycja(this.obiektKonfiguracyjny.Id)
        .then((odpowiedz) => {
          const {
            Id,
            Id_procesu,
            Id_poziom,
            Id_strategia,
            Opis_zdarzenia,
            Skutek,
            Dzialania,
            Osoba_odpowiedzialna,
          } = odpowiedz.data.dane[0];
          this.IdEdytowanegoElementu = Id;
          this.form.Id = Id_procesu;
          this.form.Id_procesu = Id_procesu;
          this.form.Id_poziom = Id_poziom;
          this.form.Id_strategia = Id_strategia;
          this.form.Opis_zdarzenia = Opis_zdarzenia;
          this.form.Skutek = Skutek;
          this.form.Dzialania = Dzialania;
          this.form.Osoba_odpowiedzialna = Osoba_odpowiedzialna;
        })
        .catch((error) => {
          console.log(error);
        });
    }

    Promise.all([
      api.pobierzProces(),
      api.pobierzPoziom(),
      api.pobierzStrategie(),
    ])
      .then(([odpowiedz_proces, odpowiedz_poziom, odpowiedz_strategia]) => {
        this.Procesy = odpowiedz_proces.data.dane;
        this.Poziomy = odpowiedz_poziom.data.dane;
        this.Strategie = odpowiedz_strategia.data.dane;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  validations: {
    form: {
      Id_procesu: {
        required,
      },
      Id_poziom: {
        required,
      },
      Id_strategia: {
        required,
      },
      Opis_zdarzenia: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(500),
      },
      Skutek: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(500),
      },
      Dzialania: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(500),
      },
      Osoba_odpowiedzialna: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(50),
      },
    },
  },
  computed: {
    czyJestWTrybieEdycji: function () {
      return this.obiektKonfiguracyjny.akcja === "edycja";
    },
  },

  methods: {
    zapamietajPlik(pliki) {
      if (pliki[0]) {
        this.form.plik_dane = pliki[0];
      }
    },
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.Id_procesu = null;
      this.form.Id_poziom = null;
      this.form.Id_strategia = null;
      this.form.Opis_zdarzenia = null;
      this.form.Skutek = null;
      this.form.Dzialania = null;
      this.form.Osoba_odpowiedzialna = null;
    },

    zapiszFormularz() {
      this.sending = true;

      if (this.czyJestWTrybieEdycji) {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Id_procesu", this.form.Id_procesu);
        dane.append("Id_poziom", this.form.Id_poziom);
        dane.append("Id_strategia", this.form.Id_strategia);
        dane.append("Opis_zdarzenia", this.form.Opis_zdarzenia);
        dane.append("Skutek", this.form.Skutek);
        dane.append("Dzialania", this.form.Dzialania);
        dane.append("Osoba_odpowiedzialna", this.form.Osoba_odpowiedzialna);

        api
          .zapiszZagrozeniaBHPUpdate(dane, this.IdEdytowanegoElementu)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Id_procesu", this.form.Id_procesu);
        dane.append("Id_poziom", this.form.Id_poziom);
        dane.append("Id_strategia", this.form.Id_strategia);
        dane.append("Opis_zdarzenia", this.form.Opis_zdarzenia);
        dane.append("Skutek", this.form.Skutek);
        dane.append("Dzialania", this.form.Dzialania);
        dane.append("Osoba_odpowiedzialna", this.form.Osoba_odpowiedzialna);

        api
          .zapiszZagrozeniaBHP(dane)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    naszWalidacjaFormularza() {
      if (this.czyJestWTrybieEdycji) {
        return (
          !this.$v.form.Id_procesu.$invalid &&
          !this.$v.form.Id_poziom.$invalid &&
          !this.$v.form.Id_strategia.$invalid &&
          !this.$v.form.Opis_zdarzenia.$invalid &&
          !this.$v.form.Skutek.$invalid &&
          !this.$v.form.Dzialania.$invalid &&
          !this.$v.form.Osoba_odpowiedzialna.$invalid
        );
      } else {
        return !this.$v.$invalid;
      }
    },

    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>


<style>
.form_ZBHP .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
.form_ZBHP {
  overflow: auto;
}
.form_ZBHP .md-layout .md-gutter {
  min-width: 55vw !important; /* szerokość modala*/
  max-width: 100% !important;
  min-height: 7vh; /* wysokość pomiędzy mierszami w modalu*/
}
.md-layout {
  padding-right: 0px !important;
}
.form_ZBHP .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}

.form_ZBHP .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  margin: 10px 24px !important;
  padding-top: 35px !important;
}
.form_ZBHP .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}

.form_ZBHP {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
}
.form_ZBHP .md-primary {
  border: 1px solid !important;

  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}
.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important; /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}

.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}
.md-list-item-text {
  font-size: 1.7vmin !important;
}
.form_ZBHP .md-card-actions .md-button {
  margin-right: 4vw !important;
}
.form_ZBHP .md-field.md-has-textarea:not(.md-autogrow) label {
  top: 16px;
  left: 0px;
}
</style>