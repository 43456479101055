var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tabela_PracZAKRES"},[_c('div',{staticClass:"laczacy"},[_c('div',{staticClass:"menu_strony"},[_c('button',{staticClass:"button",on:{"click":function($event){return _vm.$router.push("../pracownicy_zatrudnieni")}}},[_vm._v(" POWRÓT DO WYKAZU PRACOWNIKÓW ZATRUDNIONYCH ")]),_c('button',{staticClass:"button",on:{"click":function($event){return _vm.$router.push("../pracownicy_zwolnieni")}}},[_vm._v(" POWRÓT DO WYKAZU PRACOWNIKÓW ZWOLNIONYCH ")]),_c('span',[_c('button',{staticClass:"button",attrs:{"disabled":!_vm.autoryzacja.czyMozeDodawac},on:{"click":function($event){_vm.pokazDialog = true;
            _vm.typAktywnegoFormularza = 'formularz_dodawanie_zakresu';
            _vm.obiektKonfiguracyjny = {
              Id: null,
              akcja: 'dodawanie',
            };}}},[_vm._v(" DODAWANIE NOWEGO ZAKRESU OBOWIĄZKÓW, ODPOWIEDZIALNOŚCI I UPRAWNIEŃ ")]),_c('md-tooltip',{staticClass:"podpowiedz",attrs:{"md-direction":"top"}},[_vm._v(_vm._s(!_vm.autoryzacja.czyMozeDodawac ? "BRAK UPRAWNIEŃ" : "DODAWANIE NOWEGO ZAKRESU OBOWIĄZKÓW, ODPOWIEDZIALNOŚCI I UPRAWNIEŃ"))])],1)]),_c('div',{staticClass:"menu_strony1"},[_c('b',[_c('b-icon-printer-fill',{staticClass:"ikony",attrs:{"font-scale":"2.1","color":"white"},on:{"click":_vm.generujPdf}}),_c('md-tooltip',{staticClass:"podpowiedz",attrs:{"md-direction":"top"}},[_vm._v("GENERUJ PDF / DRUKUJ")])],1),_c('b',[_c('b-icon-info-circle-fill',{staticClass:"ikony",attrs:{"font-scale":"2.1","color":"white"},on:{"click":_vm.pokazPDF_INFO}}),_c('md-tooltip',{staticClass:"podpowiedz",attrs:{"md-direction":"top"}},[_vm._v("INFO")])],1)])]),(_vm.pokazDialog)?_c('modalHR',{attrs:{"rodzajFormularza":_vm.typAktywnegoFormularza,"funkcjaZamykajacaDialog":_vm.funkcjaZamykajacaDialog,"obiektKonfiguracyjny":_vm.obiektKonfiguracyjny}}):_vm._e(),_c('md-table',{attrs:{"md-height":"45vh","md-sort":"Nazwa_maszyny","md-sort-order":"asc","md-model-id":"Id_maszyny","md-card":"","md-fixed-header":""},on:{"md-selected":_vm.wyborElementu},scopedSlots:_vm._u([{key:"md-table-row",fn:function(ref){
            var item = ref.item;
return _c('md-table-row',{key:item.id,attrs:{"md-selectable":"single"}},[_c('md-table-cell',{attrs:{"md-label":"Lp"}},[_vm._v(" "+_vm._s(item.Lp1)+" ")]),_c('md-table-cell',{attrs:{"md-label":"Nazwisko","md-sort-by":"Nazwisko"}},[_vm._v(" "+_vm._s(item.Nazwisko)+" ")]),_c('md-table-cell',{attrs:{"md-label":"Imię-pierwsze","md-sort-by":"Imie1"}},[_vm._v(" "+_vm._s(item.Imie1)+" ")]),_c('md-table-cell',{attrs:{"md-label":"Nr NIP","md-sort-by":"NIP"}},[_vm._v(" "+_vm._s(item.NIP)+" ")]),_c('md-table-cell',{attrs:{"md-label":"Nr PESEL","md-sort-by":"Pesel"}},[_vm._v(" "+_vm._s(item.Pesel)+" ")]),_c('md-table-cell',{attrs:{"md-label":"Data zakresu","md-sort-by":"Data_zakresu"}},[_vm._v(" "+_vm._s(item.Data_zakresu)+" ")]),_c('md-table-cell',{attrs:{"md-label":"Stanowisko","md-sort-by":"Stanowisko"}},[_vm._v(" "+_vm._s(item.Stanowisko)+" ")])],1)}}]),model:{value:(_vm.searched),callback:function ($$v) {_vm.searched=$$v},expression:"searched"}},[_c('md-table-toolbar',[_c('div',{staticClass:"d-flex flex-column w-100"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4"}),_c('div',{staticClass:"col-4"},[_c('h1',{staticClass:"md-title"},[_vm._v(" WYKAZ ZAKRESÓW OBOWIĄZKÓW"),_c('br'),_vm._v("ODPOWIEDZIALNOŚCI I UPRAWNIEŃ PRACOWNIKA ")])])]),(_vm.wybranyElementTabeli)?_c('div',{staticClass:"PasekWyboru"},[_c('div',{staticStyle:{"text-align":"right","margin-top":"0.3vh","margin-bottom":"0.3vh"}},[_c('b',[_c('b-button',{attrs:{"disabled":!_vm.autoryzacja.czyMozeUsuwac},on:{"click":function($event){return _vm.usuwanieElementu(_vm.wybranyElementTabeli.Id)}}},[_c('b-icon-trash',{staticClass:"ikony",attrs:{"font-scale":"1.8","color":"white"}})],1),_c('md-tooltip',{staticClass:"podpowiedz",attrs:{"md-direction":"top"}},[_vm._v(_vm._s(_vm.autoryzacja.czyMozeUsuwac ? "USUWANIE ELEMENTU" : "BRAK UPRAWNIEŃ"))])],1),_c('b',[_c('b-button',{attrs:{"disabled":!_vm.autoryzacja.czyMozeEdytowac},on:{"click":function($event){_vm.pokazDialog = true;
                  _vm.typAktywnegoFormularza = 'formularz_dodawanie_zakresu';
                  _vm.obiektKonfiguracyjny = {
                    Id: _vm.wybranyElementTabeli.Id,
                    akcja: 'edycja',
                  };}}},[_c('b-icon-pencil-square',{staticClass:"ikony",attrs:{"font-scale":"1.80","color":"white"}})],1),_c('md-tooltip',{staticClass:"podpowiedz",attrs:{"md-direction":"top"}},[_vm._v(_vm._s(_vm.autoryzacja.czyMozeUsuwac ? "EDYCJA ELEMENTU" : "BRAK UPRAWNIEŃ"))])],1),_c('b',[_c('b-button',[_c('b-icon-file-earmark-arrow-down',{staticClass:"ikony",attrs:{"font-scale":"1.80","color":"white"},on:{"click":function($event){return _vm.pokazPDF(_vm.wybranyElementTabeli.Id)}}})],1),_c('md-tooltip',{staticClass:"podpowiedz",attrs:{"md-direction":"top"}},[_vm._v("PODGLĄD PLIKU")])],1)])]):_vm._e()])]),(_vm.czyPobranoDane)?_c('md-table-empty-state',{attrs:{"md-label":"Nie posiadasz wpisów w wykazie zakresów obowiązków, odpowiedzialności i uprawnień"}},[_c('md-button',{staticClass:"md-primary md-raised",attrs:{"disabled":!_vm.autoryzacja.czyMozeDodawac},on:{"click":function($event){_vm.pokazDialog = true;
          _vm.typAktywnegoFormularza = 'formularz_dodawanie_zakresu';
          _vm.obiektKonfiguracyjny = {
            Id: null,
            akcja: 'dodawanie',
          };}}},[_vm._v("Dodawanie nowego zakresu obowiązków, odpowiedzialności i uprawnień pracownika")]),_c('md-tooltip',{staticClass:"podpowiedz",attrs:{"md-direction":"top"}},[_vm._v(_vm._s(!_vm.autoryzacja.czyMozeDodawac ? "BRAK UPRAWNIEŃ" : "DODAWANIE NOWEGO ZAKRESU OBOWIĄZKÓW, ODPOWIEDZIALNOŚCI I UPRAWNIEŃ"))])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }