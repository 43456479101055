<template>
  <div class="ElementyWyboruSYS">
    <b-container fluid class="p-0">
      <b-row>
        <b-col sm="12" md="2">
          <KafelekWyboruSYS
            sciezkaZdjecia="Zdjecia/o-kontekst.jpg"
            opisZdjecia="FajnaFotka"
            linkDoStrony="kontekst_organizacji"
            podpisPodKafelkiem="KONTEKST ORGANIZACJI"
          />
        </b-col>
        <b-col sm="12" md="2">
          <KafelekWyboruSYS
            sciezkaZdjecia="Zdjecia/o-procesy.jpg"
            opisZdjecia="FajnaFotka"
            linkDoStrony="procesy"
            podpisPodKafelkiem="PROCESY SYSTEMU"
          />
        </b-col>
        <b-col sm="12" md="2">
          <KafelekWyboruSYS
            sciezkaZdjecia="Zdjecia/o-dokumentacja.jpg"
            opisZdjecia="FajnaFotka"
            linkDoStrony="dokumentacja"
            podpisPodKafelkiem="DOKUMENTACJA"
          />
        </b-col>
        <b-col sm="12" md="2">
          <KafelekWyboruSYS
            sciezkaZdjecia="Zdjecia/o-ip.jpg"
            opisZdjecia="FajnaFotka"
            linkDoStrony="instrukcje_operacyjne"
            podpisPodKafelkiem="INSTRUKCJE OPERACYJNE"
          />
        </b-col>
        <b-col sm="12" md="2">
          <KafelekWyboruSYS
            sciezkaZdjecia="Zdjecia/o-is.jpg"
            opisZdjecia="FajnaFotka"
            linkDoStrony="instrukcje_srodowiskowe"
            podpisPodKafelkiem="INSTRUKCJE ŚRODOWISKOWE"
          />
        </b-col>
        <b-col sm="12" md="2">
          <KafelekWyboruSYS
            sciezkaZdjecia="Zdjecia/o-ibhp.jpg"
            opisZdjecia="FajnaFotka"
            linkDoStrony="instrukcje_BHP"
            podpisPodKafelkiem="INSTRUKCJE BHP"
          />
        </b-col>
        <b-col sm="12" md="2">
          <KafelekWyboruSYS
            sciezkaZdjecia="Zdjecia/o-orz.jpg"
            opisZdjecia="FajnaFotka"
            linkDoStrony="ryzyka_szanse"
            podpisPodKafelkiem="RYZYKA I SZANSA"
          />
        </b-col>
        <b-col sm="12" md="2">
          <KafelekWyboruSYS
            sciezkaZdjecia="Zdjecia/o-bhp.jpg"
            opisZdjecia="FajnaFotka"
            linkDoStrony="zagrozenia_bhp"
            podpisPodKafelkiem="ZAGROŻENIA BHP"
          />
        </b-col>
        <b-col sm="12" md="2">
          <KafelekWyboruSYS
            sciezkaZdjecia="Zdjecia/o-cele.jpg"
            opisZdjecia="FajnaFotka"
            linkDoStrony="cele_okres_realizacji"
            podpisPodKafelkiem="CELE SYSTEMU"
          />
        </b-col>
        <b-col sm="12" md="2">
          <KafelekWyboruSYS
            sciezkaZdjecia="Zdjecia/o-prawne.jpg"
            opisZdjecia="FajnaFotka"
            linkDoStrony="wymagania_prawne"
            podpisPodKafelkiem="WYMAGANIA REGULACYJNE"
          />
        </b-col>
        <b-col sm="12" md="2">
          <KafelekWyboruSYS
            sciezkaZdjecia="Zdjecia/o-aspekty.jpg"
            opisZdjecia="FajnaFotka"
            linkDoStrony="aspekty"
            podpisPodKafelkiem="ASPEKTY ŚRODOWISKOWE"
          />
        </b-col>
        <b-col sm="12" md="2">
          <KafelekWyboruSYS
            sciezkaZdjecia="Zdjecia/o-audity.jpg"
            opisZdjecia="FajnaFotka"
            linkDoStrony="audity"
            podpisPodKafelkiem="AUDITY WEWNĘTRZNE"
          />
        </b-col>
        <b-col sm="12" md="2">
          <KafelekWyboruSYS
            sciezkaZdjecia="Zdjecia/o-dzialania.jpg"
            opisZdjecia="FajnaFotka"
            linkDoStrony="dzialania_audit"
            podpisPodKafelkiem="DZIAŁANIA Z AUDITU"
          />
        </b-col>
        <b-col sm="12" md="2">
          <KafelekWyboruSYS
            sciezkaZdjecia="Zdjecia/o-dzialania.jpg"
            opisZdjecia="FajnaFotka"
            linkDoStrony="dzialania_poza_auditowe"
            podpisPodKafelkiem="DZIAŁANIA POZAAUDITOWE"
          />
        </b-col>

        <b-col sm="12" md="2">
          <KafelekWyboruSYS
            sciezkaZdjecia="Zdjecia/o-przeglad.jpg"
            opisZdjecia="FajnaFotka"
            linkDoStrony="przeglad_zarzadzania"
            podpisPodKafelkiem="PRZEGLĄD ZARZĄDZANIA"
          />
        </b-col>

        <b-col sm="12" md="2">
          <KafelekWyboruSYS
            sciezkaZdjecia="Zdjecia/o-zadowolenie_klienta.jpg"
            opisZdjecia="FajnaFotka"
            linkDoStrony="zadowolenie_klienta"
            podpisPodKafelkiem="ZADOWOLENIE KLIENTA"
          />
        </b-col>

        <b-col sm="12" md="2">
          <KafelekWyboruSYS
            sciezkaZdjecia="Zdjecia/o-sprawozdawczość_środowiskowa.jpg"
            opisZdjecia="FajnaFotka"
            linkDoStrony="sprawozdawczosc_srodowiskowa"
            podpisPodKafelkiem="SPRAWOZDAWCZOŚĆ ŚRODOWISKOWA"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import KafelekWyboruSYS from "./Kafelek_wyboru_SYS.vue";

export default {
  name: "ElementyWyboruSYS",
  components: {
    KafelekWyboruSYS,
  },
};
</script>

<style scoped>
.ElementyWyboruSYS {
  margin-top: 1%;
  display: flex;
  flex-flow: row wrap;
  padding-bottom: 10%;
}
</style>
