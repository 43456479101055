<template>
  <div>
  <tabelaKontrahenciKontakt/>
  </div>
</template>

<script>
import tabelaKontrahenciKontakt from '../../komponenty/strona-tabela-komponenty/SO_kontrahenci/tabela_kontrahenci_kontakt.vue'


export default {
  name: 'StronaTabelaKontrahenciKontakt',
  components: {
      tabelaKontrahenciKontakt,
  }

}
</script>
