<template >
  <div>
  <TabelaWyroby/>
  </div>
</template>

<script>
import TabelaWyroby from '../../komponenty/strona-tabela-komponenty/SO_wyroby/tabela_wyrobow.vue'


export default {
  name: 'StronaTabelaWyroby',
  components: {
    TabelaWyroby,
    
  }

}
</script>

