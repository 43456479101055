<template>
  <div class="FormAudit">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI AUDITU"
                : "FORMULARZ DODAWANIA AUDITU"
            }}
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="MP2" :class="getValidationClass('Rok')">
                <label for="Rok">Rok auditu</label>
                <md-input
                  id="Rok"
                  name="Rok"
                  
                  md-counter="4"
                  v-model="form.Rok"
                  :disabled="sending || czyJestWTrybieEdycji"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Rok.maxlength"
                  >Wymagana ilość znaków 4</span
                >
                <span
                  class="md-error"
                  v-if="!$v.form.Rok.minlength"
                  >Wymagana ilość znaków 4</span
                >
              </md-field>
            </div>

            

            <div class="md-layout-item">
              <md-field :class="getValidationClass('Norma')">
                <label for="Norma">WYBÓR NORM OBJĘTYCH AUDITEM</label>
                <md-select
                  v-model="form.Norma"
                  name="Norma"
                  id="Norma"
                  :disabled="sending || czyJestWTrybieEdycji"
                  multiple
                >
                  <md-option
                  
                    v-for="(norma, index) in Normy"
                    :key="index"
                    :value="norma.Id"
                    >{{
                      ` ${norma.Norma} `
                    }}</md-option
                  >
                </md-select>
                                <span class="md-error" v-if="!$v.form.Norma.required"
                  >Pole jest wymagane</span
                >
              </md-field>

    
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="MP2" :class="getValidationClass('Data_rozpoczecia')">
                <label for="Data_rozpoczecia">Data rozpoczecia</label>
                <md-input
                  type="date"
                  id="Data_rozpoczecia"
                  name="Data_rozpoczecia"
                  autocomplete="Data_rozpoczecia"
                  v-model="form.Data_rozpoczecia"
                  :disabled="sending"
                  @input="pobierzNumerDzialania"
                />
                <span class="md-error" v-if="!$v.form.Data_rozpoczecia.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div> 

            <div class="md-layout-item md-small-size-100">
              <md-field class="MP2" :class="getValidationClass('Data_zakonczenia')">
                <label for="Data_zakonczenia">Data zakonczenia</label>
                <md-input
                  type="date"
                  id="Data_zakonczenia"
                  name="Data_zakonczenia"
                  autocomplete="Data_zakonczenia"
                  v-model="form.Data_zakonczenia"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Data_zakonczenia.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field class="MP2" :class="getValidationClass('Nr_auditu')">
                <label for="Nr_auditu">Numer auditu</label>
                <md-input
                  id="Nr_auditu"
                  name="Nr_auditu"
                  autocomplete="Nr_auditu"
                  v-model="form.Nr_auditu"
                  :disabled="sending"
                  readonly
                />
                <span class="md-error" v-if="!$v.form.Nr_auditu.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>


          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="MP2" :class="getValidationClass('Auditorzy_wewnetrzni')">
                <label for="Auditorzy_wewnetrzni">Planowany skład auditorski</label>
                <md-textarea
                  id="Auditorzy_wewnetrzni"
                  name="Auditorzy_wewnetrzni"
                  autocomplete="Auditorzy_wewnetrzni"
                  md-autogrow
                  md-counter="200"
                  v-model="form.Auditorzy_wewnetrzni"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Auditorzy_wewnetrzni.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Auditorzy_wewnetrzni.maxlength"
                  >Wymagana ilość znaków od 1 do 200</span
                >
              </md-field>
            </div>
          </div>

        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

        <div style="text-align: center">
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            czyJestWTrybieEdycji
              ? "ZATWIERDŹ - EDYTOWANY WPIS"
              : "ZATWIERDŹ - UTWÓRZ WPIS"
          }}</md-button>
        </div>
      </md-card>
    </form>
  </div>
</template>



<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import minLength from "vuelidate/lib/validators/minLength";
import { api } from "./../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_auditu",
  mixins: [validationMixin],
  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      Rok: null,
      Nr_auditu: null,
      Norma: null, 
      Data_rozpoczecia: null,
      Data_zakonczenia: null,
      Auditorzy_wewnetrzni: null,
    },

    IdEdytowanegoElementu: null,
    userSaved: false,
    sending: false,
    lastUser: null,
    Normy: [],
    Lp: null,
  }),
  created: function () {

    if (this.obiektKonfiguracyjny.akcja === "edycja") {
      //edycja
      api
        .pobieranieAudityEdycja(this.obiektKonfiguracyjny.Id)
        .then((odpowiedz) => {
          const {
            Id,
            Rok,
            Nr_auditu,
            Data_rozpoczecia,
            Data_zakonczenia,
            Auditorzy_wewnetrzni,
          } = odpowiedz.data.dane.programAuditu[0];
          this.IdEdytowanegoElementu = Id;
          this.form.Rok = Rok;
          this.form.Nr_auditu = Nr_auditu;
          this.form.Norma = odpowiedz.data.dane.wyborNormy.map(({Id_normy}) => Id_normy);
          this.form.Data_rozpoczecia = Data_rozpoczecia;
          this.form.Data_zakonczenia = Data_zakonczenia;
          this.form.Auditorzy_wewnetrzni = Auditorzy_wewnetrzni;
        })
        .catch((error) => {
          console.log(error);
        });
    }
    api
      .PobierzNormy()
      .then((odpowiedz) => {
        this.Normy = odpowiedz.data.dane;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  validations: {
    form: {
      Rok: {
        required,
        minLenght: minLength(4),
        maxLength: maxLength(4),
      },
      Nr_auditu: {
        required,
      },
      Norma: {
        required,
      },
      Data_rozpoczecia: {
        required,
      },
      Data_zakonczenia: {
        required,
      },
      Auditorzy_wewnetrzni: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(200),
      },
    },
  },
  computed: {
    czyJestWTrybieEdycji: function () {
      return this.obiektKonfiguracyjny.akcja === "edycja";
    },
    numerDzialania: function () {
      if (this.Lp) {
        const { numer, rok } = this.Lp;
        return `AW-${numer}/${rok}`;
      } else {
        return "";
      }
    },
  },

  methods: {
    pobierzNumerDzialania() {
      if(this.czyJestWTrybieEdycji){
        return 
      }
      const { Data_rozpoczecia } = this.form;
      const data = new Date(Data_rozpoczecia);
      const rok = data.getFullYear();

      api.PobierzLpAuditu({ rok }).then((odpowiedz) => {
        this.Lp = odpowiedz.data.dane;
        this.form.Nr_auditu = this.numerDzialania;
      });
    },
    
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.Rok = null;
      this.form.Nr_auditu = null;
      this.form.Norma = null;
      this.form.Data_rozpoczecia = null;
      this.form.Data_zakonczenia = null;
      this.form.Auditorzy_wewnetrzni = null;
    },

    zapiszFormularz() {
      this.sending = true;

      if (this.czyJestWTrybieEdycji) {
        const dane = {};
        dane.Rok = this.form.Rok;
        dane.Nr_auditu = this.form.Nr_auditu;
        dane.Normy = this.form.Norma;
        // dane.Lp = this.Lp.Data;
        dane.Data_rozpoczecia = this.form.Data_rozpoczecia;
        dane.Data_zakonczenia = this.form.Data_zakonczenia;
        dane.Auditorzy_wewnetrzni = this.form.Auditorzy_wewnetrzni;

        api
          .zapiszAudityUpdate(dane, this.IdEdytowanegoElementu)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const dane = {};
        dane.Rok = this.form.Rok;
        dane.Nr_auditu = this.form.Nr_auditu;
        dane.Normy = this.form.Norma;
        dane.Lp = this.Lp.numer;
        dane.Data_rozpoczecia = this.form.Data_rozpoczecia;
        dane.Data_zakonczenia = this.form.Data_zakonczenia;
        dane.Auditorzy_wewnetrzni = this.form.Auditorzy_wewnetrzni;

        api
          .zapiszAudit(dane)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    naszWalidacjaFormularza() {
      if (this.czyJestWTrybieEdycji) {
        return (
          !this.$v.form.Rok.$invalid &&
          !this.$v.form.Nr_auditu.$invalid &&
          !this.$v.form.Norma.$invalid &&
          !this.$v.form.Data_rozpoczecia.$invalid &&
          !this.$v.form.Data_zakonczenia.$invalid &&
          !this.$v.form.Auditorzy_wewnetrzni.$invalid
        );
      } else {
        return !this.$v.$invalid;
      }
    },
    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>

<style>
.FormAudit .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.FormAudit .md-layout .md-gutter {
 min-width: 55vw !important; /* szerokość modala*/
  max-width: 100% !important;
  min-height: 7vh; /* wysokość pomiędzy mierszami w modalu*/
}
.md-layout {
  padding-right: 0px !important;
}
.FormAudit .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}

.FormAudit .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  margin: 10px 24px !important;
  padding-top: 35px !important;
}
.FormAudit .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}

.FormAudit {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
  overflow: auto;
}
.FormAudit .md-primary {
  border: 1px solid !important;

  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}
.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important; /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}

.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}
.FormAudit .md-list-item-text {
  font-size: 1.7vmin !important;
}
.FormAudit .md-card-actions .md-button {
  margin-right: 50px !important;
}
.FormAudit .md-field.md-has-textarea:not(.md-autogrow) label {
  top: 16px;
  left: 0px;
}
</style>