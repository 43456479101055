<template>
  <md-dialog :md-active="true" @md-clicked-outside="funkcjaZamykajacaDialog">

    <FormularzDodawanieDokumentacjiORZ
      v-if="rodzajFormularza === 'formularz_dodawanie_dokumentacji_ORZ'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawaniePrzegladuDokumentacjiORZ
      v-if="rodzajFormularza === 'formularz_dodawanie_przegladu_dokumentacji_ORZ'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieDokumentacjiBSP
      v-if="rodzajFormularza === 'formularz_dodawanie_dokumentacji_BSP'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieSubstancjiNiebezpiecznej
      v-if="rodzajFormularza === 'formularz_dodawanie_substancji'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawaniePrzegladuSubstancjiNiebezpiecznej
      v-if="rodzajFormularza === 'formularz_dodawanie_przegladu_wykazu_substancji'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieOcenyStanuBHP
      v-if="rodzajFormularza === 'formularz_dodawanie_oceny_stanu_BHP'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawaniePosiedzeniaBHP
      v-if="rodzajFormularza === 'formularz_dodawanie_posiedzenia_zespolu'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />


    <FormularzDodawanieWypadkuWPracy
      v-if="rodzajFormularza === 'formularz_dodawanie_wypadku_w_pracy'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieDzialaniaWypadkiOcena
      v-if="rodzajFormularza === 'formularz_dodawanie_dzialania_wypadki_ocena'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieDzialaniaWypadkiOcenaSkutecznosci
      v-if="rodzajFormularza === 'formularz_dodawanie_dzialania_wypadki_ocena_skutecznosci'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieChorobyZawodowej
      v-if="rodzajFormularza === 'formularz_dodawanie_choroby_zawodowej'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieDzialaniaChorobyOcena
      v-if="rodzajFormularza === 'formularz_dodawanie_dzialania_choroby_ocena'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieDzialaniaSytuacja
      v-if="rodzajFormularza === 'formularz_dodawanie_sytuacji'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieDzialaniaSytuacjeOcena
      v-if="rodzajFormularza === 'formularz_dodawanie_dzialania_sytuacje_ocena'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieDzialaniaSytuacjeOcenaSkutecznosci
      v-if="rodzajFormularza === 'formularz_dodawanie_dzialania_sytuacje_ocena_skutecznosci'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieKontroliWewnetrznej
      v-if="rodzajFormularza === 'formularz_dodawanie_kontroli_wewnetrznej'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieKontroliWewnetrznejUwaga
      v-if="rodzajFormularza === 'formularz_dodawanie_uwagi_wewnetrzne'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieKontroliWewnetrznejDzialania
      v-if="rodzajFormularza === 'formularz_dodawanie_kontroli_wewnetrznej_dzialania'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieDzialaniaKontrolaWewnetrznaOcenaSkutecznosci
      v-if="rodzajFormularza === 'formularz_dodawanie_dzialania_kontrola_wewnetrzna_ocena_skutecznosci'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieKontroliZewnetrznej
      v-if="rodzajFormularza === 'formularz_dodawanie_kontroli_zewnetrznej'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieKontroliZewnetrznejUwaga
      v-if="rodzajFormularza === 'formularz_dodawanie_uwagi_zewnetrzne'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieKontroliZewnetrznejDzialania
      v-if="rodzajFormularza === 'formularz_dodawanie_kontroli_zewnetrznej_dzialania'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieDzialaniaKontrolaZewnetrznaOcenaSkutecznosci
      v-if="rodzajFormularza === 'formularz_dodawanie_dzialania_kontrola_zewnetrzna_ocena_skutecznosci'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawaniePracSzczegolnieNiebezpiecznych
      v-if="rodzajFormularza === 'formularz_dodawanie_prac_szczegolnie_niebezpiecznych'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawaniePrzegladuWykazu
      v-if="rodzajFormularza === 'formularz_dodawanie_przegladu_wykazu'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />

    <Usuwanie
      v-else-if="rodzajFormularza === 'usuwanie'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <PokazPDF
      v-else-if="rodzajFormularza === 'pokaz_PDF'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <PokazPDF1
      v-else-if="rodzajFormularza === 'pokaz_PDF_1'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <PokazPDF2
      v-else-if="rodzajFormularza === 'pokaz_PDF_2'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <PokazPDF3
      v-else-if="rodzajFormularza === 'pokaz_PDF_3'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <PokazPDFINFO
      v-else-if="rodzajFormularza === 'pokaz_PDF_INFO'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
  </md-dialog>
</template>

<script>
import FormularzDodawanieDokumentacjiORZ from "./BHP_orz/formularz_dodawanie_dokumentacji_ORZ";
import FormularzDodawaniePrzegladuDokumentacjiORZ from "./BHP_orz/formularz_dodawanie_przegladu_dokumentacji_ORZ";
import FormularzDodawanieDokumentacjiBSP from "./BHP_badania_srodowiska_pracy/formularz_dodawanie_dokumentacji_BSP";
import FormularzDodawanieSubstancjiNiebezpiecznej from "./BHP_substancje_niebezpieczne/formularz_dodawanie_substancji";
import FormularzDodawaniePrzegladuSubstancjiNiebezpiecznej from "./BHP_substancje_niebezpieczne/formularz_dodawanie_przegladu_wykazu_substancji";
import FormularzDodawanieOcenyStanuBHP from "./BHP_ocena_stanu_BHP/formularz_dodawanie_oceny_stanu_BHP";
import FormularzDodawaniePosiedzeniaBHP from "./BHP_ocena_stanu_BHP/formularz_dodawanie_posiedzenia_zespolu"; 
import FormularzDodawanieWypadkuWPracy from "./BHP_wypadki_w_pracy/formularz_dodawanie_wypadku_w_pracy";
import FormularzDodawanieDzialaniaWypadkiOcena from "./BHP_wypadki_w_pracy/formularz_dodawanie_dzialania_wypadki_ocena";
import FormularzDodawanieDzialaniaWypadkiOcenaSkutecznosci from "./BHP_wypadki_w_pracy/formularz_dodawanie_dzialania_wypadki_ocena_skutecznosci";
import FormularzDodawanieChorobyZawodowej from "./BHP_choroby_zawodowe/formularz_dodawanie_choroby_zawodowej";
import FormularzDodawanieDzialaniaChorobyOcena from "./BHP_choroby_zawodowe/formularz_dodawanie_dzialania_choroby_ocena";
import FormularzDodawanieDzialaniaSytuacja from "./BHP_sytuacje_potencjalnie_wypadkowe/formularz_dodawanie_sytuacji";
import FormularzDodawanieDzialaniaSytuacjeOcena from "./BHP_sytuacje_potencjalnie_wypadkowe/formularz_dodawanie_dzialania_sytuacje_ocena";
import FormularzDodawanieDzialaniaSytuacjeOcenaSkutecznosci from "./BHP_sytuacje_potencjalnie_wypadkowe/formularz_dodawanie_dzialania_sytuacje_ocena_skutecznosci";
import FormularzDodawanieKontroliWewnetrznej from "./BHP_kontrole_wewnetrzne/formularz_dodawanie_kontroli_wewnetrznej";
import FormularzDodawanieKontroliWewnetrznejUwaga from "./BHP_kontrole_wewnetrzne/formularz_dodawanie_uwagi_wewnetrzne"; 
import FormularzDodawanieKontroliWewnetrznejDzialania from "./BHP_kontrole_wewnetrzne/formularz_dodawanie_kontroli_wewnetrznej_dzialania";
import FormularzDodawanieDzialaniaKontrolaWewnetrznaOcenaSkutecznosci from "./BHP_kontrole_wewnetrzne/formularz_dodawanie_dzialania_kontrola_wewnetrzna_ocena_skutecznosci";
import FormularzDodawanieKontroliZewnetrznej from "./BHP_kontrole_zewnetrzne/formularz_dodawanie_kontroli_zewnetrznej";
import FormularzDodawanieKontroliZewnetrznejUwaga from "./BHP_kontrole_zewnetrzne/formularz_dodawanie_uwagi_zewnetrzne"; 
import FormularzDodawanieKontroliZewnetrznejDzialania from "./BHP_kontrole_zewnetrzne/formularz_dodawanie_kontroli_zewnetrznej_dzialania";
import FormularzDodawanieDzialaniaKontrolaZewnetrznaOcenaSkutecznosci from "./BHP_kontrole_zewnetrzne/formularz_dodawanie_dzialania_kontrola_zewnetrzna_ocena_skutecznosci";
import FormularzDodawaniePracSzczegolnieNiebezpiecznych from "./BHP_prac_szczegolnie_niebezpiecznych/formularz_dodawanie_prac_szczegolnie_niebezpiecznych";
import FormularzDodawaniePrzegladuWykazu from "./BHP_prac_szczegolnie_niebezpiecznych/formularz_dodawanie_przegladu_wykazu";

import Usuwanie from "./formularz_usuwanie";
import PokazPDF from "./formularz_pokaz_pdf";
import PokazPDF1 from "./formularz_pokaz_pdf_1";
import PokazPDF2 from "./formularz_pokaz_pdf_2";
import PokazPDF3 from "./formularz_pokaz_pdf_3";
import PokazPDFINFO from "./formularz_pokaz_pdf_INFO";

export default {
  name: "modalHR",
  components: {
    FormularzDodawanieDokumentacjiORZ,
    FormularzDodawaniePrzegladuDokumentacjiORZ,
    FormularzDodawanieDokumentacjiBSP,
    FormularzDodawanieSubstancjiNiebezpiecznej,
    FormularzDodawaniePrzegladuSubstancjiNiebezpiecznej,
    FormularzDodawanieOcenyStanuBHP,
    FormularzDodawaniePosiedzeniaBHP,
    FormularzDodawanieWypadkuWPracy,
    FormularzDodawanieDzialaniaWypadkiOcena,
    FormularzDodawanieDzialaniaWypadkiOcenaSkutecznosci,
    FormularzDodawanieChorobyZawodowej,
    FormularzDodawanieDzialaniaChorobyOcena,
    FormularzDodawanieDzialaniaSytuacja,
    FormularzDodawanieDzialaniaSytuacjeOcena,
    FormularzDodawanieDzialaniaSytuacjeOcenaSkutecznosci,
    FormularzDodawanieKontroliWewnetrznej,
    FormularzDodawanieKontroliWewnetrznejUwaga,
    FormularzDodawanieKontroliWewnetrznejDzialania,
    FormularzDodawanieDzialaniaKontrolaWewnetrznaOcenaSkutecznosci,
    FormularzDodawanieKontroliZewnetrznej,
    FormularzDodawanieKontroliZewnetrznejUwaga,
    FormularzDodawanieKontroliZewnetrznejDzialania,
    FormularzDodawanieDzialaniaKontrolaZewnetrznaOcenaSkutecznosci,
    FormularzDodawaniePracSzczegolnieNiebezpiecznych,
    FormularzDodawaniePrzegladuWykazu,

    Usuwanie,
    PokazPDF,
    PokazPDF1,
    PokazPDF2,
    PokazPDF3,
    PokazPDFINFO,
  },
  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    rodzajFormularza: {
      type: String,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
};
</script>



