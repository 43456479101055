<template>
  <div class="ZalogowanyUzytkownik">
    
    <b-icon-person-lines-fill font-scale="4" color="white" class="ikona"></b-icon-person-lines-fill>
    <div class="daneUzytkownik">
    <p>Zalogowany użytkownik : {{user.Imie +' '+ user.Nazwisko}}</p>
    <p>Firma : {{user.Nazwa}}</p>
    <p>Adres e-mail : {{user.Login}}</p>
    </div>
  </div>
</template>

<script>


export default {
  name: 'ZalogowanyUzytkownik',
  props: {
    user: {
      type: Object,
    },
  }
}
</script>

<style scoped>
.ZalogowanyUzytkownik
{
  background-color: #606060;
  color: white;
  line-height: 20%;
  display: flex;
  flex-flow: row nowrap;
}
.ikona
{
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 20px;
}
.daneUzytkownik
{
  text-align: left;
  margin-top: 15px;
  font-weight: bold;
  color: white;
  margin-right: 10px;
}
</style>