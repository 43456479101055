<template>
  <div class="PR_cele_strategiczne">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI CELÓW STRATEGICZNYCH PROCESU SYSTEMU ZARZĄDZANIA"
                : "FORMULARZ DODAWANIA CELÓW STRATEGICZNYCH PROCESU SYSTEMU ZARZĄDZANIA"
            }}
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M4" :class="getValidationClass('Id_procesu')">
                <label for="Id_procesu">Wybór procesu</label>

                <md-select
                  v-model="form.Id_procesu"
                  :disabled="sending"
                  name="Id_procesu"
                  id="Id_procesu"
                  autocomplete="Id_procesu"
                  md-dense
                >
                  <md-option
                    v-for="(proces, index) in Procesy"
                    :key="index"
                    :value="proces.Id"
                    >{{
                      ` ${proces.Nr_procesu} - ${proces.Nazwa_procesu} `
                    }}</md-option
                  >
                </md-select>
                <span class="md-error" v-if="!$v.form.Id_procesu.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('Cel')">
                <label for="Cel">Cel strategiczny</label>
                <md-textarea
                  name="Cel"
                  id="Cel"
                  autocomplete="Cel"
                  md-autogrow
                  md-counter="500"
                  v-model="form.Cel"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Cel.required"
                  >Pole jest wymagane</span
                >
                <span class="md-error" v-else-if="!$v.form.Cel.maxlength"
                  >Wymagana ilość znaków od 1 do 500</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('Opis')">
                <label for="Opis">Opis realizacji celu</label>
                <md-textarea
                  name="Opis"
                  id="Opis"
                  autocomplete="Opis"
                  md-autogrow
                  md-counter="500"
                  v-model="form.Opis"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Opis.required"
                  >Pole jest wymagane</span
                >
                <span class="md-error" v-else-if="!$v.form.Opis.maxlength"
                  >Wymagana ilość znaków od 1 do 500</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('Wskaznik')">
                <label for="Wskaznik">Wskaźnik rozliczeniowy</label>
                <md-textarea
                  name="Wskaznik"
                  id="Wskaznik"
                  autocomplete="Wskaznik"
                  md-autogrow
                  md-counter="500"
                  v-model="form.Wskaznik"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Wskaznik.required"
                  >Pole jest wymagane</span
                >
                <span class="md-error" v-else-if="!$v.form.Wskaznik.maxlength"
                  >Wymagana ilość znaków od 1 do 500</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M5"
                :class="getValidationClass('Wartosc_oczekiwana')"
              >
                <label for="Wartosc_oczekiwana">Wartość oczekiwana</label>
                <md-textarea
                  name="Wartosc_oczekiwana"
                  id="Wartosc_oczekiwana"
                  autocomplete="Wartosc_oczekiwana"
                  md-autogrow
                  md-counter="500"
                  v-model="form.Wartosc_oczekiwana"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Wartosc_oczekiwana.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Wartosc_oczekiwana.maxlength"
                  >Wymagana ilość znaków od 1 do 500</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('Okres')">
                <label for="Okres">Okres rozliczeniowy</label>
                <md-textarea
                  name="Okres"
                  id="Okres"
                  autocomplete="Okres"
                  md-autogrow
                  md-counter="500"
                  v-model="form.Okres"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Okres.required"
                  >Pole jest wymagane</span
                >
                <span class="md-error" v-else-if="!$v.form.Okres.maxlength"
                  >Wymagana ilość znaków od 1 do 500</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('Data_oceny')">
                <label for="Data_oceny">Data planow.oceny</label>
                <md-input
                  type="date"
                  name="Data_oceny"
                  id="Data_oceny"
                  autocomplete="Data_oceny"
                  v-model="form.Data_oceny"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Data_oceny.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>
        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

        <div style="text-align: center">
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            czyJestWTrybieEdycji
              ? "ZATWIERDŹ - EDYTOWANY WPIS"
              : "ZATWIERDŹ - UTWÓRZ WPIS"
          }}</md-button>
        </div>
      </md-card>
    </form>
  </div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import minLength from "vuelidate/lib/validators/minLength";
import { api } from "./../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_maszyny",

  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      Id_procesu: null,
      Cel: null,
      Opis: null,
      Wskaznik: null,
      Wartosc_oczekiwana: null,
      Okres: null,
      Data_oceny: null,
    },
    IdEdytowanegoElementu: null,
    userSaved: false,
    sending: false,
    lastUser: null,
    Procesy: [],
  }),
  created: function() {
    if (this.obiektKonfiguracyjny.akcja === "edycja") {
      //edycja
      api
        .pobieranieProcesuCeleStrategiczneEdycja(this.obiektKonfiguracyjny.Id)
        .then((odpowiedz) => {
          const {
            Id,
            Id_procesu,
            Cel,
            Opis,
            Wskaznik,
            Wartosc_oczekiwana,
            Okres,
            Data_oceny,
          } = odpowiedz.data.dane[0];
          this.IdEdytowanegoElementu = Id;
          this.form.Id = Id_procesu;
          this.form.Id_procesu = Id_procesu;
          this.form.Cel = Cel;
          this.form.Opis = Opis;
          this.form.Wskaznik = Wskaznik;
          this.form.Wartosc_oczekiwana = Wartosc_oczekiwana;
          this.form.Okres = Okres;
          this.form.Data_oceny = Data_oceny;
        })
        .catch((error) => {
          console.log(error);
        });
    }

    api
      .pobierzProces()
      .then((odpowiedz) => {
        this.Procesy = odpowiedz.data.dane;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  validations: {
    form: {
      Id_procesu: {
        required,
      },
      Cel: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(500),
      },
      Opis: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(500),
      },
      Wskaznik: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(500),
      },
      Wartosc_oczekiwana: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(500),
      },
      Okres: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(500),
      },
      Data_oceny: {
        required,
      },
    },
  },
  computed: {
    czyJestWTrybieEdycji: function() {
      return this.obiektKonfiguracyjny.akcja === "edycja";
    },
  },

  methods: {
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.Id_procesu = null;
      this.form.Cel = null;
      this.form.Opis = null;
      this.form.Wskaznik = null;
      this.form.Wartosc_oczekiwana = null;
      this.form.Okres = null;
      this.form.Data_oceny = null;
    },

    zapiszFormularz() {
      this.sending = true;

      if (this.czyJestWTrybieEdycji) {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Id_procesu", this.form.Id_procesu);
        dane.append("Cel", this.form.Cel);
        dane.append("Opis", this.form.Opis);
        dane.append("Wskaznik", this.form.Wskaznik);
        dane.append("Wartosc_oczekiwana", this.form.Wartosc_oczekiwana);
        dane.append("Okres", this.form.Okres);
        dane.append("Data_oceny", this.form.Data_oceny);

        api
          .zapiszProcesCeleStrategiczneUpdate(dane, this.IdEdytowanegoElementu)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Id_procesu", this.form.Id_procesu);
        dane.append("Cel", this.form.Cel);
        dane.append("Opis", this.form.Opis);
        dane.append("Wskaznik", this.form.Wskaznik);
        dane.append("Wartosc_oczekiwana", this.form.Wartosc_oczekiwana);
        dane.append("Okres", this.form.Okres);
        dane.append("Data_oceny", this.form.Data_oceny);

        api
          .zapiszProcesCeleStrategiczne(dane)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    naszWalidacjaFormularza() {
      if (this.czyJestWTrybieEdycji) {
        return (
          !this.$v.form.Id_procesu.$invalid &&
          !this.$v.form.Cel.$invalid &&
          !this.$v.form.Opis.$invalid &&
          !this.$v.form.Wskaznik.$invalid &&
          !this.$v.form.Wartosc_oczekiwana.$invalid &&
          !this.$v.form.Okres.$invalid &&
          !this.$v.form.Data_oceny.$invalid
        );
      } else {
        return !this.$v.$invalid;
      }
    },

    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>

<style>
.PR_cele_strategiczne .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
.PR_cele_strategiczne {
  overflow: auto;
}
.PR_cele_strategiczne .md-layout .md-gutter {
  min-width: 55vw !important; /* szerokość modala*/
  max-width: 100% !important;
  min-height: 7vh; /* wysokość pomiędzy mierszami w modalu*/
}
.md-layout {
  padding-right: 0px !important;
}
.PR_cele_strategiczne .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}

.PR_cele_strategiczne .M1 {
  max-width: 100% !important;
  min-width: 100% !important;
}
.PR_cele_strategiczne .M2 {
  max-width: 100% !important;
  min-width: 100% !important;
}
.PR_cele_strategiczne .M3 {
  min-width: 97%;
  max-width: 97%;
  min-height: 5vh;
  font-size: 2vh !important;
}

.PR_cele_strategiczne .M4 {
  max-width: 100% !important;
  min-width: 100% !important;
}
.PR_cele_strategiczne .M5 {
  max-width: 100% !important;
  min-width: 100% !important;
}
.PR_cele_strategiczne .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  margin: 10px 24px !important;
  padding-top: 35px !important;
}
.PR_cele_strategiczne .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}

.PR_cele_strategiczne {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
}
.PR_cele_strategiczne .md-primary {
  border: 1px solid !important;

  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}
.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important; /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}

.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}
.md-list-item-text {
  font-size: 1.7vmin !important;
}
.PR_cele_strategiczne .md-card-actions .md-button {
  margin-right: 4vw !important;
}
.PR_cele_strategiczne .md-field.md-has-textarea:not(.md-autogrow) label {
  top: 16px;
  left: 0px;
}
</style>
