<template>
  <div>
  <TabelaElektronarzedzia/>
  </div>
</template>

<script>
import TabelaElektronarzedzia from '../../komponenty/strona-tabela-komponenty/WST_elektronarzedzia/tabela_elektronarzedzia.vue'


export default {
  name: 'StronaTabelaElektronarzedzia',
  components: {
    TabelaElektronarzedzia,
  }

}
</script>
