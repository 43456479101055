<template >
  <div>
  <TabelaAudityKompetencje />
  </div>
</template>

<script>
import TabelaAudityKompetencje from '../../komponenty/strona-tabela-komponenty/SYS_audity/tabela_audity_kompetencje.vue'


export default {
  name: 'StronaTabelaAuditKompetencje',
  components: {
    TabelaAudityKompetencje,
    
  }

}
</script>

