<template >
  <div>
  <TabelaZagrozeniaBHP />
  </div>
</template>

<script>
import TabelaZagrozeniaBHP from '../../komponenty/strona-tabela-komponenty/SYS_zagrozenia_bhp/tabela_zagrozenia_bhp.vue'


export default {
  name: 'StronaTabelaZagrozeniaBHP',
  components: {
    TabelaZagrozeniaBHP,
    
  }

}
</script>

