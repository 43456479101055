<template>
  <div>
  <TabelaMaszynyGenerator />
  </div>
</template>

<script>
import TabelaMaszynyGenerator from '../../komponenty/strona-tabela-komponenty/WST_maszyny/tabela_generator.vue'


export default {
  name: 'StronaTabelaMaszynyGenerator',
  components: {
    TabelaMaszynyGenerator,
  }

}
</script>
