import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { MdTable, MdCard, MdContent, MdRipple } from 'vue-material/dist/components'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'

import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
import 'primevue/resources/themes/bootstrap4-light-blue/theme.css'

import Vuelidate from 'vuelidate'

import PrimeVue from 'primevue/config';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';

import './FONTY/AbhayaLibre-Regular-normal.js';
import './FONTY/Aleo-Regular-normal.js';



// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(MdTable)
Vue.use(MdCard)
Vue.use(MdContent)
Vue.use(MdRipple)
Vue.use(VueMaterial)
Vue.use(Vuelidate)
Vue.use(PrimeVue)

Vue.component('DataTable', DataTable);
Vue.component('Column', Column);
Vue.component('Button', Button);


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
