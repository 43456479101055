<template >
  <div>
  <TabelaWykazPracownicyZapoznanieIBHP />
  </div>
</template>

<script>
import TabelaWykazPracownicyZapoznanieIBHP from '../../komponenty/strona-tabela-komponenty/HR_pracownicy/tabela_zapoznanie_IBHP.vue'


export default {
  name: 'StronaTabelaWykazBadan',
  components: {
    TabelaWykazPracownicyZapoznanieIBHP,
    
  }

}
</script>

