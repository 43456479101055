<template >
  <div>
  <TabelaWykazKontroliWewnetrznychRealizacja />
  </div>
</template>

<script>
import TabelaWykazKontroliWewnetrznychRealizacja from '../../komponenty/strona-tabela-komponenty/BHP_kontrole_wewnetrzne/tabela_dzialan_do_uwag.vue'


export default {
  name: 'StronaTabelaWykazKontroliWewnetrznychRealizacja',
  components: {
    TabelaWykazKontroliWewnetrznychRealizacja,
    
  }

}
</script>

