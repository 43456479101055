<template>
  <div>
  <tabelaWykazWypadkiWPracy/>

  </div>
</template>

<script>
import tabelaWykazWypadkiWPracy from '../../komponenty/strona-tabela-komponenty/BHP_wypadki_w_pracy/tabela_wykaz_wypadkow_w_pracy.vue'


export default {
  name: 'StronaTabelaWykazWypadkiWPracy',
  components: {
       tabelaWykazWypadkiWPracy,

  }

}
</script>
