<template>
  <div>
  <TabelaMaszynyPaliwo />
  </div>
</template>

<script>
import TabelaMaszynyPaliwo from '../../komponenty/strona-tabela-komponenty/WST_maszyny/tabela_maszyny_paliwo.vue'


export default {
  name: 'StronaTabelaMaszynyPaliwo',
  components: {
    TabelaMaszynyPaliwo,
    
  }

}
</script>
