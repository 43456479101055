<template>
  <div class="FORM_SPWYPAD">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI SYTUACJI POTENCJALNIE NIEBEZPIECZNEJ"
                : "FORMULARZ DODAWANIA SYTUACJI POTENCJALNIE NIEBEZPIECZNEJ"
            }}
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-gutter">
            
             <div class="md-layout-item md-small-size-100"
            >
              <md-field
                class="M5"
                :class="getValidationClass('Data')"
              >
                <label for="Data_wypadku">Data stwierdzenia sytuacji</label>
                <md-input
                  type="date"
                  name="Data"
                  id="Data"
                  autocomplete="Data"
                  v-model="form.Data"
                  @input="pobierzNumerSytuacji"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Data.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
                   

            <div class="md-layout-item md-small-size-100"
            >
              <md-field
                class="M5"
                :class="getValidationClass('Numer')"
              >
                <label for="Numer">Nr działania</label>
                <md-input
                  
                  name="Numer"
                  id="Numer"
                  autocomplete="Numer"
                  md-counter="15"
                  v-model="form.Numer"
                  :disabled="sending || czyJestWTrybieEdycji"
                  readonly
                />
                <span class="md-error" v-if="!$v.form.Numer.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Numer.maxlength"
                  >Wymagana ilość znaków od 1 do 15</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">

            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M2"
                :class="getValidationClass('Miejsce')"
              >
                <label for="Miejsce">Miejsce wystąpienia</label>
                <md-textarea

                  name="Miejsce"
                  id="Miejsce"
                  
                  md-autogrow
                  md-counter="500"
                  v-model="form.Miejsce"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Miejsce.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Miejsce.maxlength"
                  >Wymagana ilość znaków od 1 do 500</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M2"
                :class="getValidationClass('Opis')"
              >
                <label for="Opis">Opis sytuacji</label>
                <md-textarea

                  name="Opis"
                  id="Opis"
                  
                  md-autogrow
                  md-counter="500"
                  v-model="form.Opis"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Opis.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Opis.maxlength"
                  >Wymagana ilość znaków od 1 do 500</span
                >
              </md-field>
            </div>
          </div>

           <div class="md-layout md-gutter">

            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M2"
                :class="getValidationClass('Osoba_odpowiedzialna')"
              >
                <label for="Osoba_odpowiedzialna">Osoba odpowiedzialna</label>
                <md-input

                  name="Osoba_odpowiedzialna"
                  id="Osoba_odpowiedzialna"
                  
                  md-autogrow
                  md-counter="50"
                  v-model="form.Osoba_odpowiedzialna"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Osoba_odpowiedzialna.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Osoba_odpowiedzialna.maxlength"
                  >Wymagana ilość znaków od 1 do 50</span
                >
              </md-field>
            </div>
            </div>

            <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="plikZwalidowany_1 ? '' : 'md-invalid'">
                <label
                  >Wstaw plik - PROTOKÓŁ Z ZDARZENIA - "max-2Mb"
                  <span v-if="nazwaPliku">
                    - obecnie wstawiony plik to {{ nazwaPliku }}</span
                  ></label
                >
                <md-file
                  v-model="form.plik_PDF"
                  @md-change="zapamietajPlik"
                  accept="application/pdf"
                />
                <span class="md-error" v-if="!plikZwalidowany"
                  >Plik moze miec max 2MB</span
                >
              </md-field>
            </div>
          </div>

   

        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

        
          <div style="text-align: center">
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            czyJestWTrybieEdycji
              ? "ZATWIERDŹ - EDYTOWANY WPIS"
              : "ZATWIERDŹ - UTWÓRZ WPIS"
          }}</md-button>
        </div>
      </md-card>

    </form>
  </div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import minLength from "vuelidate/lib/validators/minLength";
import { api } from "../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_maszyny",

  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      Data: null,
      Numer: null,
      Miejsce: null,
      Opis: null,
      Osoba_odpowiedzialna: null,
      plik_PDF: null,
      plik_dane: null,

    },
    IdEdytowanegoElementu: null,
    userSaved: false,
    nazwaPliku: null,
    sending: false,
    lastUser: null,
    Lp: null,
  }),

  created: function () {
    if (this.obiektKonfiguracyjny.akcja === "edycja") {
      //edycja
      api
        .pobieranieDokumentacjaSPWEdycja(this.obiektKonfiguracyjny.Id)
        .then((odpowiedz) => {
          const {
            Id,
            Data,
            Numer,
            Miejsce,
            Opis,
            Osoba_odpowiedzialna,
            Nazwa_zalacznika,
          } = odpowiedz.data.dane[0];
          this.IdEdytowanegoElementu = Id;
          this.form.Data = Data;
          this.form.Numer = Numer;
          this.form.Miejsce = Miejsce;
          this.form.Opis = Opis;
          this.form.Osoba_odpowiedzialna = Osoba_odpowiedzialna;
          this.nazwaPliku = Nazwa_zalacznika;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  validations: {
    form: {
      Data: {
        required,
      },
      Numer: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(15),
      },
      Miejsce: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(500),
      },
      Opis: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(500),
      },
      Osoba_odpowiedzialna: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(50),
      },
    },
  },
  computed: {
    plikZwalidowany: function () {
      return this.form.plik_dane
        ? this.form.plik_dane.size < 2 * 1024 * 1024
        : true;
    },

    czyJestWTrybieEdycji: function () {
      return this.obiektKonfiguracyjny.akcja === "edycja";
    },
    numerDzialania: function () {
      if (this.Lp) {
        const { numer, miesiac, rok } = this.Lp;
        return `SPW-${numer}/${miesiac}/${rok}`;
      } else {
        return "";
      }
    },
  },

  methods: {
    zapamietajPlik(pliki) {
      if (pliki[0]) {
        this.form.plik_dane = pliki[0];
      }
    },

    pobierzNumerSytuacji() {
      const { Data } = this.form;
      const data = new Date(Data);
      const rok = data.getFullYear();
      const miesiac = data.getMonth() + 1;

      api.PobierzLpDzialaniaSPW({ rok, miesiac }).then((odpowiedz) => {
        this.Lp = odpowiedz.data.dane;
        this.form.Numer = this.numerDzialania;
      });
    },
 
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.Data = null;
      this.form.Numer = null;
      this.form.Miejsce = null;
      this.form.Opis = null;
      this.form.Osoba_odpowiedzialna = null;
      this.form.plik_PDF = null;
      this.form.plik_dane = null;
      
    },

    zapiszFormularz() {
      this.sending = true;

      if (this.czyJestWTrybieEdycji) {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Data", this.form.Data);
        dane.append("Numer", this.form.Numer);
        dane.append("Miejsce", this.form.Miejsce);
        dane.append("Opis", this.form.Opis);
        dane.append("Osoba_odpowiedzialna", this.form.Osoba_odpowiedzialna);
        dane.append("Lp", this.Lp.numer);
        dane.append("plik_PDF", this.form.plik_dane);


        api
          .zapiszDokumentacjaSPWUpdate(dane, this.IdEdytowanegoElementu)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Data", this.form.Data);
        dane.append("Numer", this.form.Numer);
        dane.append("Miejsce", this.form.Miejsce);
        dane.append("Opis", this.form.Opis);
        dane.append("Osoba_odpowiedzialna", this.form.Osoba_odpowiedzialna);
        dane.append("Lp", this.Lp.numer);
        dane.append("plik_PDF", this.form.plik_dane);

        api
          .zapiszDokumentacjaSPW(dane)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    naszWalidacjaFormularza() {
      if (this.czyJestWTrybieEdycji) {
        return (
          !this.$v.form.Data.$invalid &&
          !this.$v.form.Numer.$invalid &&
          !this.$v.form.Miejsce.$invalid &&
          !this.$v.form.Opis.$invalid &&
          !this.$v.form.Osoba_odpowiedzialna.$invalid &&
          this.plikZwalidowany
        );
      } else {
        return !this.$v.$invalid && 
        this.plikZwalidowany;
      }
    },
    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>


<style>
.FORM_SPWYPAD .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.FORM_SPWYPAD .md-layout .md-gutter {
  min-width: 55vw !important; /* szerokość modala*/
  max-width: 100% !important;
  min-height: 7vh; /* wysokość pomiędzy mierszami w modalu*/
}
.md-layout {
  padding-right: 0px !important;
}
.FORM_SPWYPAD .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}

.FORM_SPWYPAD .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  margin: 10px 24px !important;
  padding-top: 35px !important;
}
.FORM_SPWYPAD .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}
.FORM_SPWYPAD {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
  overflow: auto;
}
.FORM_SPWYPAD .md-primary {
  border: 1px solid !important;
  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}
.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important; /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}

.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}
.md-list-item-text {
  font-size: 1.7vmin !important;
}
.FORM_SPWYPAD .md-card-actions .md-button {
  margin-right: 4vw !important;
}
</style>