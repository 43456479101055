<template >
  <div>
  <TabelaOfertOdrzucone/>
  </div>
</template>

<script>
import TabelaOfertOdrzucone from '../../komponenty/strona-tabela-komponenty/SO_oferty/tabela_wykaz_ofert_odrzuconych.vue'


export default {
  name: 'StronaTabelaOfertyOdrzucone',
  components: {
    TabelaOfertOdrzucone,
    
  }

}
</script>

