<template>
  <div>
  <TabelaBudynkiKoszty/>
  </div>
</template>

<script>
import TabelaBudynkiKoszty from '../../komponenty/strona-tabela-komponenty/WST_budynki/tabela_budynki_koszty.vue'


export default {
  name: 'StronaTabelaBudynkiKoszty',
  components: {
    TabelaBudynkiKoszty,
  }

}
</script>
