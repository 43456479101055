import Vue from "vue";
import VueRouter from "vue-router";
import { userService } from "./../serwisy/user.serwis";

//BHP BADANIA SRODOWISKA PRACY
import StronaTabelaStartowa from "../strony/STRONA_STARTOWA/StronaTabelaStartowa.vue";
//BHP BADANIA SRODOWISKA PRACY
import StronaTabelaDokumentyBSP from "../strony/BHP_badania_srodowiska_pracy/StronaTabelaDokumentyBSP.vue";
import StronaTabelaPrzegladyDokumentyBSP from "../strony/BHP_badania_srodowiska_pracy/StronaTabelaPrzegladyDokumentyBSP";
// CHOROBY ZAWODOWE
import StronaTabelaWykazChorobZawodowych from "../strony/BHP_choroby_zawodowe/StronaTabelaWykazChorobZawodowych.vue";
import StronaTabelaWykazDzialanSkutkowChorobyZawodowe from "../strony/BHP_choroby_zawodowe/StronaTabelaWykazDzialanSkutkowChorobyZawodowe.vue";
import StronaTabelaDzialaniaKontroleOcenaSkutecznosci from "../strony/BHP_kontrole_wewnetrzne/StronaTabelaDzialaniaKontroleOcenaSkutecznosci.vue";
// KONTROLE WENĘTRZNE BHP
import StronaTabelaWykazKontroliWewnetrznych from "../strony/BHP_kontrole_wewnetrzne/StronaTabelaWykazKontroliWewnetrznych.vue";
import StronaTabelaWykazKontroliWewnetrznychRealizacja from "../strony/BHP_kontrole_wewnetrzne/StronaTabelaWykazKontroliWewnetrznychRealizacja.vue";
import StronaTabelaDzialaniaKontroleZewnetrzneOcenaSkutecznosci from "../strony/BHP_kontrole_zewnetrzne/StronaTabelaDzialaniaKontroleZewnetrzneOcenaSkutecznosci.vue";
// KONTROLE WENĘTRZNE BHP
import StronaTabelaWykazKontroliZewnetrznych from "../strony/BHP_kontrole_zewnetrzne/StronaTabelaWykazKontroliZewnetrznych.vue";
import StronaTabelaWykazKontroliZewnetrznychRealizacja from "../strony/BHP_kontrole_zewnetrzne/StronaTabelaWykazKontroliZewnetrznychRealizacja.vue";
//OCENA STANU BHP
import StronaTabelaOcenaStanuBHP from "../strony/BHP_ocena_stanu_BHP/StronaTabelaWykazOcenaStanuBHP.vue";
import StronaTabelaWykazZespolBHP from "../strony/BHP_ocena_stanu_BHP/StronaTabelaWykazZespolBHP.vue"; 
//BHP ORZ
import StronaTabelaDokumentyORZ from "../strony/BHP_orz/StronaTabelaDokumentyORZ.vue";
import StronaTabelaPrzegladyDokumentyORZ from "../strony/BHP_orz/StronaTabelaPrzegladyDokumentyORZ";
import StronaTabelaPrzegladySubstancjeNiebezpieczne from "../strony/BHP_substancje_niebezpieczne/StronaTabelaPrzegladSubstancjeNiebezpieczne.vue";
import StronaTabelaSubstancjeNiebezpieczne from "../strony/BHP_substancje_niebezpieczne/StronaTabelaSubstancjeNiebezpieczne.vue";
// SYTUACJE POTENCJALNIE NIEBEZPIECZNE
import StronaTabelaWykazSytuacje from "../strony/BHP_sytuacje_potencjalnie_wypadkowe/StronaTabelaWykazSytuacje.vue";
import StronaTabelaWykazSytuacjeOcena from "../strony/BHP_sytuacje_potencjalnie_wypadkowe/StronaTabelaWykazSytuacjeOcena.vue";
import StronaTabelaWykazSytuacjeOcenaSkutecznosci from "../strony/BHP_sytuacje_potencjalnie_wypadkowe/StronaTabelaWykazSytuacjeOcenaSkutecznosci.vue";
// PRACE SZCZEGÓLNIE NIEBEZPIECZNE
import StronaTabelaPraceSzczegolnieNiebezpieczne from "../strony/BHP_wykaz_prac_szczegolnie_niebezpiecznych/StronaTabelaPraceSzczegolnieNiebezpieczne.vue";
import StronaTabelaPrzegladPraceSzczegolnieNiebezpieczne from "../strony/BHP_wykaz_prac_szczegolnie_niebezpiecznych/StronaTabelaPrzegladPraceSzczegolnieNiebezpieczne.vue";
//WYPADKI W PRACY
import StronaTabelaWykazWypadkowWPracy from "../strony/BHP_wypadki_w_pracy/StronaTabelaWykazWypadkowWPracy.vue";
import StronaTabelaWykazWypadkowWPracyOcena from "../strony/BHP_wypadki_w_pracy/StronaTabelaWykazWypadkowWPracyOcena.vue";
import StronaTabelaWykazWypadkowWPracyOcenaSkutecznosci from "../strony/BHP_wypadki_w_pracy/StronaTabelaWykazWypadkowWPracyOcenaSkutecznosci.vue";
// STRONY FAKTURY
import StronaTabelaFaktury from "../strony/FAK_faktury/StronaTabelaFaktury.vue";
import StronaTabelaWykazAbsencja from "../strony/HR_absencja/StronaTabelaWykazAbsencja.vue";
import StronaTabelaSkierowanie from "../strony/HR_badania/StronaTabelaSkierowanie.vue";
import StronaTabelaWykazSkierowan from "../strony/HR_badania/StronaTabelaWykazSkierowan.vue";
import StronaTabelaMedycynaPracy from "../strony/HR_medycyna_pracy/StronaTabelaWykazMedycyna.vue";
import StronaTabelaDaneZatrudnieniaPracownika from "../strony/HR_pracownicy/StronaTabelaDaneZatrudnieniaPracownika.vue";
import StronaTabelaWykazAdresKorespondencja from "../strony/HR_pracownicy/StronaTabelaWykazAdresKorespondencja.vue";
import StronaTabelaWykazAdresZamieszkania from "../strony/HR_pracownicy/StronaTabelaWykazAdresZamieszkania.vue";
import StronaTabelaWykazBadan from "../strony/HR_pracownicy/StronaTabelaWykazBadan.vue";
import StronaTabelaWykazHistoriaZatrudnienia from "../strony/HR_pracownicy/StronaTabelaWykazHistoriaZatrudnienia.vue";
import StronaTabelaWykazKompetencje from "../strony/HR_pracownicy/StronaTabelaWykazKompetencje.vue";
// PRACOWNICY ZATRUDNIENI
import StronaTabelaWykazPracownikowZatrudnionych from "../strony/HR_pracownicy/StronaTabelaWykazPracownikowZatrudnionych.vue";
import StronaTabelaWykazPracownikowZatrudnionychUzupelnienie from "../strony/HR_pracownicy/StronaTabelaWykazPracownikowZatrudnionychUzupelnienie.vue";
import StronaTabelaWykazPracownikowZwolnionych from "../strony/HR_pracownicy/StronaTabelaWykazPracownikowZwolnionych.vue";
import StronaTabelaWykazSzkoleniaBHP from "../strony/HR_pracownicy/StronaTabelaWykazSzkoleniaBHP.vue";
import StronaTabelaWykazSzkolenStanowiskowych from "../strony/HR_pracownicy/StronaTabelaWykazSzkolenStanowiskowych.vue";
import StronaTabelaWykazZakresowObowiazkow from "../strony/HR_pracownicy/StronaTabelaWykazZakresowObowiazkow.vue";
import StronaTabelaWykazZapoznanieIBHP from "../strony/HR_pracownicy/StronaTabelaWykazZapoznanieIBHP.vue";
import StronaTabelaWykazZapoznanieIO from "../strony/HR_pracownicy/StronaTabelaWykazZapoznanieIO.vue";
import StronaTabelaWykazZapoznanieIS from "../strony/HR_pracownicy/StronaTabelaWykazZapoznanieIS.vue";
import StronaTabelaWykazZapoznanieORZ from "../strony/HR_pracownicy/StronaTabelaWykazZapoznanieORZ.vue";
import StronaTabelaWykazPrzegladStanowiska from "../strony/HR_stanowiska/StronaTabelaWykazPrzegladStanowiska.vue";
import StronaTabelaWykazStanowisk from "../strony/HR_stanowiska/StronaTabelaWykazStanowisk.vue";
import StronaTabelaWykazPlanow from "../strony/HR_szkolenia/StronaTabelaWykazPlanow.vue";
import StronaTabelaWykazRealizacji from "../strony/HR_szkolenia/StronaTabelaWykazRealizacji.vue";
import StronaTabelaWykazSzkolen from "../strony/HR_szkolenia/StronaTabelaWykazSzkolen.vue";
import StronaTabelaWykazUczestnikow from "../strony/HR_szkolenia/StronaTabelaWykazUczestnikow.vue";
import StronaTabelaWykazZakresy from "../strony/HR_zakresy/StronaTabelaWykazZakresy.vue";
import StronaTabelaZakresObowiazkow from "../strony/HR_zakresy/StronaTabelaZakresObowiazkow.vue";
import StronaTabelaZakresObowiazkow1 from "../strony/HR_zakresy/StronaTabelaZakresObowiazkow1.vue";
import StronaTabelaKatalogCzesciZakresu from "../strony/HR_zakresy/StronaTabelaKatalogCzesciZakresu.vue";
// LOGOWANIE
import TabelaLogowanieDostep from "../strony/LOGOWANIE/StronaTabelaLogowanie.vue";
import TabelaLogowanieDostepSzczegoly from "../strony/LOGOWANIE/StronaTabelaLogowanieSzczegoly.vue";
import StronaTabelaWylogowanie from "../strony/LOGOWANIE/StronaTabelaWylogowanie.vue";

// STRONY FAK KONTRAHENCI
import StronaTabelaKontrahenci from "../strony/SO_kontrahenci/StronaTabelaKontrahenci.vue";
import StronaTabelaKontrahenciKontakt from "../strony/SO_kontrahenci/StronaTabelaKontrahenciKontakt.vue";
import StronaTabelaOferty from "../strony/SO_oferty/StronaTabelaOferty.vue";
import StronaTabelaOfertyOdrzucone from "../strony/SO_oferty/StronaTabelaOfertyOdrzucone.vue";
import StronaTabelaOfertyPrzyjete from "../strony/SO_oferty/StronaTabelaOfertyPrzyjete.vue";
import StronaTabelaDzialania from "../strony/SO_realizacja/StronaTabelaDzialania.vue";
import StronaTabelaRaporty from "../strony/SO_realizacja/StronaTabelaRaporty.vue";
import StronaTabelaRaportyZakonczone from "../strony/SO_realizacja/StronaTabelaRaportyZakonczone.vue";
import StronaTabelaRealizacja from "../strony/SO_realizacja/StronaTabelaRealizacja.vue";
import StronaTabelaRealizacjaZakonczone from "../strony/SO_realizacja/StronaTabelaRealizacjaZakonczone.vue";
import StronaTabelaGrupy from "../strony/SO_wyroby/StronaTabelaGrupy.vue";
import StronaTabelaWyroby from "../strony/SO_wyroby/StronaTabelaWyroby.vue";
import StronaTabelaPrzegladZakupy from "../strony/SO_zakupy/StronaTabelaPrzegladZakupy.vue";
import StronaTabelaZakupy from "../strony/SO_zakupy/StronaTabelaZakupy.vue";
import StronaTabelaZakupyZrealizowane from "../strony/SO_zakupy/StronaTabelaZakupyZrealizowane.vue"; 
import StronaTabelaZamowienia from "../strony/SO_zamowienia/StronaTabelaZamowienia.vue";

import StronaTabelaDzialaniaMagazyn from "../strony/SO_realizacja_magazyn/StronaTabelaDzialaniaMagazyn.vue";
import StronaTabelaRaportyMagazyn from "../strony/SO_realizacja_magazyn/StronaTabelaRaportyMagazyn.vue";
import StronaTabelaRaportyMagazynZakonczone from "../strony/SO_realizacja_magazyn/StronaTabelaRaportyMagazynZakonczone.vue";
import StronaTabelaRealizacjaMagazyn from "../strony/SO_realizacja_magazyn/StronaTabelaRealizacjaMagazyn.vue";
import StronaTabelaRealizacjaZakonczoneMagazyn from "../strony/SO_realizacja_magazyn/StronaTabelaRealizacjaZakonczoneMagazyn.vue";
import StronaTabelaRozliczenieWZMagazyn from "../strony/SO_realizacja_magazyn/StronaTabelaRozliczenieWZMagazyn.vue"

//STEROWANIE OPERACYJNE
import StronaTabelaZapytania from "../strony/SO_zapytania_ofertowe/StronaTabelaZapytania.vue";
import StronaTabelaZapytaniaOdrzucone from "../strony/SO_zapytania_ofertowe/StronaTabelaZapytaniaOdrzucone.vue";
import StronaTabelaZapytaniaPrzyjetych from "../strony/SO_zapytania_ofertowe/StronaTabelaZapytaniaPrzyjete.vue";

import StronaTabelaZleceniaMagazyn from "../strony/SO_zlecenia_magazyn/StronaTabelaZleceniaMagazyn.vue";

import StronaTabelaReklamacje from "../strony/SO_reklamacje/StronaTabelaReklamacje.vue";
import StronaTabelaReklamacjeDzialania from "../strony/SO_reklamacje/StronaTabelaReklamacjeDzialania.vue";
// STRONY GLOWANE
import StronaGlowna from "../strony/StronaGlowna.vue";
import StronaGlownaBHP from "../strony/StronaGlownaBHP.vue";
import StronaGlownaFAK from "../strony/StronaGlownaFAK.vue";
import StronaGlownaHRB from "../strony/StronaGlownaHRB.vue";
import StronaGlownaSYS from "../strony/StronaGlownaSYS.vue";
import StronaGlownaWST from "../strony/StronaGlownaWST.vue";
import StronaLogowanie from "../strony/StronaLogowania.vue";
// ASPEKTY
import StronaTabelaAspekty from "../strony/SYS_aspekty/StronaTabelaAspekty.vue";
import StronaTabelaAspektyMonitorowanie from "../strony/SYS_aspekty/StronaTabelaAspektyMonitorowanie.vue";
import StronaTabelaAspektyWymagania from "../strony/SYS_aspekty/StronaTabelaAspektyWymagania.vue";
import StronaTabelaPrzegladAspekty from "../strony/SYS_aspekty/StronaTabelaPrzegladAspekty.vue";
import StronaFormularzAudityRaport from "../strony/SYS_audity/StronaFormularzAudityRaport.vue";
import StronaTabelaAudityPlan from "../strony/SYS_audity/StronaTabelaAudityPlan.vue";
// AUDITY
import StronaTabelaAudityProgram from "../strony/SYS_audity/StronaTabelaAudityProgram.vue";
import StronaTabelaAudityRaporty from "../strony/SYS_audity/StronaTabelaAudityRaporty.vue";
import StronaTabelaAudityKompetencje from "../strony/SYS_audity/StronaTabelaKompetencjeAuditora.vue";
import StronaTabelaCelePrzeglady from "../strony/SYS_cele/StronaTabelaCelePrzeglady.vue";
import StronaTabelaCeleSzczegolowe from "../strony/SYS_cele/StronaTabelaCeleSzczegolowe.vue";
import StronaTabelaCeleZadania from "../strony/SYS_cele/StronaTabelaCeleZadania.vue";
// CELE
import StronaTabelaRokCele from "../strony/SYS_cele/StronaTabelaRokCele.vue";
// STRONY DOKUMENTACJA
import StronaTabelaDokumenty from "../strony/SYS_dokumentacja/StronaTabelaDokumenty.vue";
import StronaTabelaPrzegladyDokumenty from "../strony/SYS_dokumentacja/StronaTabelaPrzegladyDokumenty";
// DZIALANIA AUDIT
import StronaTabelaDzialaniaAudit from "../strony/SYS_dzialania_audit/StronaTabelaDzialaniaAudit.vue";
import StronaTabelaDzialaniaAuditOcena from "../strony/SYS_dzialania_audit/StronaTabelaDzialaniaAuditOcena.vue";
import StronaTabelaDzialaniaAuditOcenaSkutecznosci from "../strony/SYS_dzialania_audit/StronaTabelaDzialaniaAuditOcenaSkutecznosci.vue";
// DZIALANIA POZA AUDIT
import StronaTabelaDzialaniaPozaAudit from "../strony/SYS_dzialania_poza_audit/StronaTabelaDzialaniaPozaAudit.vue";
import StronaTabelaDzialaniaPozaAuditOcena from "../strony/SYS_dzialania_poza_audit/StronaTabelaDzialaniaPozaAuditOcena.vue";
import StronaTabelaDzialaniaPozaAuditOcenaSkutecznosci from "../strony/SYS_dzialania_poza_audit/StronaTabelaDzialaniaPozaAuditOcenaSkutecznosci.vue";
// STRONY INSTRUKCJE BHP
import StronaTabelaInstrukcjeBHP from "../strony/SYS_instrukcje_BHP/StronaTabelaInstrukcjeBHP.vue";
import StronaTabelaPrzegladyInstrukcjeBHP from "../strony/SYS_instrukcje_BHP/StronaTabelaPrzegladyInstrukcjeBHP.vue";
// STRONY INSTRUKCJE OPERACYJNE
import StronaTabelaInstrukcjeOperacyjne from "../strony/SYS_instrukcje_operacyjne/StronaTabelaInstrukcjeOperacyjne.vue";
import StronaTabelaPrzegladyInstrukcjeOperacyjne from "../strony/SYS_instrukcje_operacyjne/StronaTabelaPrzegladyInstrukcjeOperacyjne.vue";
// STRONY INSTRUKCJE SRODOWISKOWE
import StronaTabelaInstrukcjeSrodowiskowe from "../strony/SYS_instrukcje_srodowiskowe/StronaTabelaInstrukcjeSrodowiskowe.vue";
import StronaTabelaPrzegladyInstrukcjeSrodowiskowe from "../strony/SYS_instrukcje_srodowiskowe/StronaTabelaPrzegladyInstrukcjeSrodowiskowe.vue";
import StronaTabelaGranice from "../strony/SYS_kontekst/StronaTabelaGranice";
import StronaTabelaLokalizacje from "../strony/SYS_kontekst/StronaTabelaLokalizacje.vue";
// STRONY KONTEKST
import StronaTabelaPrzegladKontekstu from "../strony/SYS_kontekst/StronaTabelaPrzegladKontekstu.vue";
import StronaTabelaPrzegladKontekstuHistoria from "../strony/SYS_kontekst/StronaTabelaPrzegladKontekstuHistoria.vue";
import StronaTabelaZakres from "../strony/SYS_kontekst/StronaTabelaZakres";
// STRONY PROCESY
import StronaTabelaProcesy from "../strony/SYS_procesy/StronaTabelaProcesy.vue";
import StronaTabelaProcesyDaneWejsciowe from "../strony/SYS_procesy/StronaTabelaProcesyDaneWejsciowe.vue";
import StronaTabelaProcesyPrzeglady from "../strony/SYS_procesy/StronaTabelaProcesyPrzeglady.vue";
import StronaTabelaProcesyRozliczenieCelu from "../strony/SYS_procesy/StronaTabelaProcesyRozliczenieCelu.vue";
import StronaTabelaPrzegladZarzadzania from "../strony/SYS_przeglad_zarzadzania/StronaTabelaPrzegladZarzadzania.vue";
// AUDITY
import StronaTabelaPrzegladZarzadzaniaWykaz from "../strony/SYS_przeglad_zarzadzania/StronaTabelaPrzegladZarzadzaniaWykaz.vue";
import StronaTabelaPrzegladRyzykaSzanse from "../strony/SYS_ryzyka_szanse/StronaTabelaPrzegladRyzykaSzanse.vue";
// STRONY RYZYKA SZAANSE
import StronaTabelaRyzykaSzanse from "../strony/SYS_ryzyka_szanse/StronaTabelaRyzykaSzanse.vue";
// SPRAWOZDAWCZOSC SRODOWISKOWA
import StronaTabelaSprawozdawczosc from "../strony/SYS_sprawozdawczosc_srodowiskowa/StronaTabelaSprawozdawczosc.vue";
import StronaTabelaPrzegladWymaganiaPrawne from "../strony/SYS_wymagania_prawne/StronaTabelaPrzegladWymaganiaPrawne.vue";
// WYMAGANIA PRAWNE
import StronaTabelaWymaganiaPrawne from "../strony/SYS_wymagania_prawne/StronaTabelaWymaganiaPrawne.vue";
// ZADOWOLENIE KLIENTA
import StronaTabelaZadowolenie from "../strony/SYS_zadowolenie_klienta/StronaTabelaZaodowolenie.vue";
import StronaTabelaPrzegladZagrozeniaBHP from "../strony/SYS_zagrozenia_bhp/StronaTabelaPrzegladZagrozeniaBHP.vue";
// STRONY ZAGROŻENIA BHP
import StronaTabelaZagrozeniaBHP from "../strony/SYS_zagrozenia_bhp/StronaTabelaZagrozeniaBHP.vue";
// BUDYNKI
import StronaTabelaBudynki from "../strony/WST_budynki/StronaTabelaBudynki.vue";
import StronaTabelaBudynkiKoszty from "../strony/WST_budynki/StronaTabelaBudynkiKoszty.vue";
import StronaTabelaBudynkiMedia from "../strony/WST_budynki/StronaTabelaBudynkiMedia.vue";
import StronaTabelaBudynkiPrzeglady5lat from "../strony/WST_budynki/StronaTabelaBudynkiPrzeglady5lat.vue";
import StronaTabelaBudynkiPrzegladyElektryka from "../strony/WST_budynki/StronaTabelaBudynkiPrzegladyElektryka.vue";
import StronaTabelaBudynkiPrzegladyGaz from "../strony/WST_budynki/StronaTabelaBudynkiPrzegladyGaz.vue";
import StronaTabelaBudynkiPrzegladyHydrant from "../strony/WST_budynki/StronaTabelaBudynkiPrzegladyHydrant.vue";
import StronaTabelaBudynkiPrzegladyInstrukcja from "../strony/WST_budynki/StronaTabelaBudynkiPrzegladyInstrukcja.vue";
import StronaTabelaBudynkiPrzegladyKominy from "../strony/WST_budynki/StronaTabelaBudynkiPrzegladyKominy.vue";
import StronaTabelaBudynkiPrzegladyPolrocznych from "../strony/WST_budynki/StronaTabelaBudynkiPrzegladyPolrocznych.vue";
import StronaTabelaBudynkiPrzegladyRocznych from "../strony/WST_budynki/StronaTabelaBudynkiPrzegladyRocznych.vue";
import StronaTabelaBudynkiPrzegladyWylacznik from "../strony/WST_budynki/StronaTabelaBudynkiPrzegladyWylacznik.vue";
import StronaTabelaBudynkiUbezpieczenia from "../strony/WST_budynki/StronaTabelaBudynkiUbezpieczenia.vue";
// STRONY WST ELEKTRONARZEDIA
import StronaTabelaElektronarzedzia from "../strony/WST_elektronarzedzia/StronaTabelaElektronarzedzia.vue";
import StronaTabelaElektronarzedziaKoszty from "../strony/WST_elektronarzedzia/StronaTabelaElektronarzedziaKoszty.vue";
import StronaTabelaElektronarzedziaPrzeglady from "../strony/WST_elektronarzedzia/StronaTabelaElektronarzedziaPrzeglady.vue";
import StronaTabelaElektronarzedziaUbezpieczenia from "../strony/WST_elektronarzedzia/StronaTabelaElektronarzedziaUbezpieczenia.vue";
import StronaTabelaElektronarzedziaGenerator from "../strony/WST_elektronarzedzia/StronaTabelaElektronarzedziaGenerator.vue";
import StronaTabelaElektronarzedziaGeneratorPrzeglad from "../strony/WST_elektronarzedzia/StronaTabelaElektronarzedziaGeneratorPrzeglad.vue";
// EMISJE
import StronaTabelaEmisje from "../strony/WST_emisje/StronaTabelaEmisje.vue";
// GENERATOR SPRAWDZEN
import StronaTabelaPomiarowkaGenerator from "../strony/WST_generator_sprawdzen/StronaTabelaPomiarowkaGenerator.vue";
import StronaTabelaSwiadectwoSprawdzenia from "../strony/WST_generator_sprawdzen/StronaTabelaSwiadectwoSprawdzenia.vue";
import StronaTabelaWykazSprawdzen from "../strony/WST_generator_sprawdzen/StronaTabelaWykazSprawdzen.vue";
// STRONY WST MASZYNY
import StronaTabelaMaszyny from "../strony/WST_maszyny/StronaTabelaMaszyny.vue";
import StronaTabelaMaszynyKoszty from "../strony/WST_maszyny/StronaTabelaMaszynyKoszty.vue";
import StronaTabelaMaszynyPaliwo from "../strony/WST_maszyny/StronaTabelaMaszynyPaliwo.vue";
import StronaTabelaMaszynyPrzeglady from "../strony/WST_maszyny/StronaTabelaMaszynyPrzeglady.vue";
import StronaTabelaMaszynyUbezpieczenia from "../strony/WST_maszyny/StronaTabelaMaszynyUbezpieczenia.vue";
import StronaTabelaMaszynyGenerator from "../strony/WST_maszyny/StronaTabelaMaszynyGenerator.vue";
import StronaTabelaMaszynyGenratorPrzeglad from "../strony/WST_maszyny/StronaTabelaMaszynyGenratorPrzeglad.vue"; 
// STRONY WST MASZYNY DOZOROWE
import StronaTabelaMaszynyDozorowe from "../strony/WST_maszyny_dozorowe/StronaTabelaMaszynyDozorowe.vue";
import StronaTabelaMaszynyDozoroweKoszty from "../strony/WST_maszyny_dozorowe/StronaTabelaMaszynyDozoroweKoszty.vue";
import StronaTabelaMaszynyDozorowePaliwo from "../strony/WST_maszyny_dozorowe/StronaTabelaMaszynyDozorowePaliwo.vue";
import StronaTabelaMaszynyDozorowePrzeglady from "../strony/WST_maszyny_dozorowe/StronaTabelaMaszynyDozorowePrzeglady.vue";
import StronaTabelaMaszynyDozoroweUbezpieczenia from "../strony/WST_maszyny_dozorowe/StronaTabelaMaszynyDozoroweUbezpieczenia.vue";
// POMIAROWKA
import StronaTabelaPomiarowka from "../strony/WST_pomiarowka/StronaTabelaPomiarowka.vue";
import StronaTabelaPomiarowkaKoszty from "../strony/WST_pomiarowka/StronaTabelaPomiarowkaKoszty.vue";
import StronaTabelaPomiarowkaPrzeglady from "../strony/WST_pomiarowka/StronaTabelaPomiarowkaPrzeglady.vue";
import StronaTabelaPomiarowkaUbezpieczenia from "../strony/WST_pomiarowka/StronaTabelaPomiarowkaUbezpieczenia.vue";
// STRONY WST SAMOCHODY CIEZAROWE
import StronaTabelaSamochodyCiezarowe from "../strony/WST_samochody_ciezarowe/StronaTabelaSamochodyCiezarowe.vue";
import StronaTabelaSamochodyCiezaroweKoszty from "../strony/WST_samochody_ciezarowe/StronaTabelaSamochodyCiezaroweKoszty.vue";
import StronaTabelaSamochodyCiezarowePaliwo from "../strony/WST_samochody_ciezarowe/StronaTabelaSamochodyCiezarowePaliwo.vue";
import StronaTabelaSamochodyCiezarowePrzeglady from "../strony/WST_samochody_ciezarowe/StronaTabelaSamochodyCiezarowePrzeglady.vue";
import StronaTabelaSamochodyCiezaroweTacho from "../strony/WST_samochody_ciezarowe/StronaTabelaSamochodyCiezaroweTacho.vue";
import StronaTabelaSamochodyCiezaroweChlodnie from "../strony/WST_samochody_ciezarowe/StronaTabelaSamochodyCiezaroweChlodnie.vue";
import StronaTabelaSamochodyCiezaroweTermograf from "../strony/WST_samochody_ciezarowe/StronaTabelaSamochodyCiezaroweTermograf.vue";
import StronaTabelaSamochodyCiezaroweUbezpieczenia from "../strony/WST_samochody_ciezarowe/StronaTabelaSamochodyCiezaroweUbezpieczenia.vue";
// STRONY WST SAMOCHODY OSOBOWE
import StronaTabelaSamochodyOsobowe from "../strony/WST_samochody_osobowe/StronaTabelaSamochodyOsobowe.vue";
import StronaTabelaSamochodyOsoboweKoszty from "../strony/WST_samochody_osobowe/StronaTabelaSamochodyOsoboweKoszty.vue";
import StronaTabelaSamochodyOsobowePaliwo from "../strony/WST_samochody_osobowe/StronaTabelaSamochodyOsobowePaliwo.vue";
import StronaTabelaSamochodyOsobowePrzeglady from "../strony/WST_samochody_osobowe/StronaTabelaSamochodyOsobowePrzeglady.vue";
import StronaTabelaSamochodyOsoboweUbezpieczenia from "../strony/WST_samochody_osobowe/StronaTabelaSamochodyOsoboweUbezpieczenia.vue";
// STRONY WST WYPOSAŻENIE BHP
import StronaTabelaWyposazenieBHP from "../strony/WST_wyposazenieBHP/StronaTabelaWyposazenieBHP.vue";
import StronaTabelaWyposazeniePrzegladyBHP from "../strony/WST_wyposazenieBHP/StronaTabelaWyposazeniePrzegladyBHP.vue";
// STRONY WST WYPOSAŻENIE PPOŻ
import StronaTabelaWyposazeniePPOZ from "../strony/WST_wyposazeniePPOZ/StronaTabelaWyposazeniePPOZ.vue";
import StronaTabelaWyposazeniePrzegladyPPOZ from "../strony/WST_wyposazeniePPOZ/StronaTabelaWyposazeniePrzegladyPPOZ.vue";
// STRONY WST IT
import StronaTabelaIT from "../strony/WST_wyposazenie_IT/StronaTabelaIT.vue";
import StronaTabelaITKoszty from "../strony/WST_wyposazenie_IT/StronaTabelaITKoszty.vue";
import StronaTabelaITPrzeglady from "../strony/WST_wyposazenie_IT/StronaTabelaITPrzeglady.vue";
import StronaTabelaITUbezpieczenia from "../strony/WST_wyposazenie_IT/StronaTabelaITUbezpieczenia.vue";
import StronaTabelaITGeneratorPrzeglad from "../strony/WST_wyposazenie_IT/StronaTabelaITGeneratorPrzeglad.vue";
import StronaTabelaITGenerator from "../strony/WST_wyposazenie_IT/StronaTabelaITGenerator.vue";
// PROJEKTY
import StronaTabelaWykazProjektow from "../strony/SO_projekty/StronaTabelaWykazProjektow.vue";
import StronaTabelaWykazProjektowSzczegoly from "../strony/SO_projekty/StronaTabelaWykazProjektowSzczegoly.vue";


import { api } from "../serwisy/api.serwis";

Vue.use(VueRouter);

export const routes = [
  {
    path: "/strona_logowania",
    name: "StronaLogowanie",
    component: StronaLogowanie,
    meta: {
      guard: {
        asyncGuards: [],
        linealGuards: ["isLoggedOut"],
      },
      ifFalseGoToPath: "/strona_glowna",
    },
  },
  {
    path: "/",
    name: "StronaTabelaStartowa",
    component: StronaTabelaStartowa,
  },

  {
    path: "/strona_glowna",
    name: "StronaGlowna",
    component: StronaGlowna,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
      },
    },
  },
  {
    path: "/strona_glowna_modul_SYS",
    name: "StronaGlownaSYS",
    component: StronaGlownaSYS,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 1,
      },
    },
  },
  {
    path: "/strona_glowna_modul_WST",
    name: "StronaGlownaWST",
    component: StronaGlownaWST,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
      },
    },
  },
  {
    path: "/strona_glowna_modul_HRB",
    name: "StronaGlownaHRB",
    component: StronaGlownaHRB,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 3,
      },
    },
  },
  {
    path: "/strona_glowna_modul_BHP",
    name: "StronaGlownaBHP",
    component: StronaGlownaBHP,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 4,
      },
    },
  },
  {
    path: "/strona_glowna_modul_DOP",
    name: "StronaGlownaFAK",
    component: StronaGlownaFAK,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 5,
      },
    },
  },

  //LOGOWANIE

  {
    path: "/tabela_dostepu/:id?",
    name: "TabelaLogowanieDostep",
    component: TabelaLogowanieDostep,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 6,
        Dostep_szczegolowy: 57,
      },
    },
  },


  {
    path: "/wylogowanie/:id?",
    name: "StronaTabelaWylogowanie",
    component: StronaTabelaWylogowanie,
  },

  // MODUŁ SYS ___________________________________________________________________________________________________________________________________________________________

  //Kontekst

  {
    path: "/kontekst_organizacji/:id?",
    name: "StronaTabelaPrzegladKonstekstu",
    component: StronaTabelaPrzegladKontekstu,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 1,
        Dostep_szczegolowy: 1,
      },
    },
  },
  {
    path: "/tabela_przeglad_kontekstu_historia/:id?",
    name: "StronaTabelaPrzegladKonstekstuHistoria",
    component: StronaTabelaPrzegladKontekstuHistoria,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 1,
        Dostep_szczegolowy: 1,
      },
    },
  },
  {
    path: "/kontekst_organizacji/:id?",
    name: "StronaTabelaLokalizacje",
    component: StronaTabelaLokalizacje,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 1,
        Dostep_szczegolowy: 1,
      },
    },
  },
  {
    path: "/tabela_zakres/:id?",
    name: "StronaTabelaZakres",
    component: StronaTabelaZakres,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 1,
        Dostep_szczegolowy: 1,
      },
    },
  },
  {
    path: "/tabela_granice/:id?",
    name: "StronaTabelaGranice",
    component: StronaTabelaGranice,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 1,
        Dostep_szczegolowy: 1,
      },
    },
  },

  //____________________________________________________

  //Procesy

  {
    path: "/procesy/:id?",
    name: "StronaTabelaProcesy",
    component: StronaTabelaProcesy,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 1,
        Dostep_szczegolowy: 2,
      },
    },
  },
  {
    path: "/procesy_przeglady/:id?",
    name: "StronaTabelaProcesyPrzglady",
    component: StronaTabelaProcesyPrzeglady,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 1,
        Dostep_szczegolowy: 2,
      },
    },
  },
  {
    path: "/procesy_dane_wejsciowe_wyjsciowe/:id?",
    name: "StronaTabelaProcesyDaneWejsciowe",
    component: StronaTabelaProcesyDaneWejsciowe,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 1,
        Dostep_szczegolowy: 2,
      },
    },
  },
  {
    path: "/procesy_rozliczenie_celu/:id?",
    name: "StronaTabelaProcesyRozliczenieCelu",
    component: StronaTabelaProcesyRozliczenieCelu,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 1,
        Dostep_szczegolowy: 2,
      },
    },
  },

  //____________________________________________________

  //DOKUMENTACJA

  {
    path: "/dokumentacja/:id?",
    name: "StronaTabelaDokumenty",
    component: StronaTabelaDokumenty,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 1,
        Dostep_szczegolowy: 3,
      },
    },
  },
  {
    path: "/dokumentacja_przeglady/:id?",
    name: "StronaTabelaPrzegladyDokumenty",
    component: StronaTabelaPrzegladyDokumenty,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 1,
        Dostep_szczegolowy: 3,
      },
    },
  },

  //____________________________________________________

  //INSTRUKCJE OPERACYJNE

  {
    path: "/instrukcje_operacyjne/:id?",
    name: "StronaTabelaInstrukcjeOperacyjne",
    component: StronaTabelaInstrukcjeOperacyjne,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 1,
        Dostep_szczegolowy: 4,
      },
    },
  },

  {
    path: "/instrukcje_operacyjne_przeglady/:id?",
    name: "StronaTabelaPrzegladyInstrukcjeOperacyjne",
    component: StronaTabelaPrzegladyInstrukcjeOperacyjne,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 1,
        Dostep_szczegolowy: 4,
      },
    },
  },

  //____________________________________________________

  //INSTRUKCJE SRODOWISKOWE

  {
    path: "/instrukcje_srodowiskowe/:id?",
    name: "StronaTabelaInstrukcjeSrodowiskowe",
    component: StronaTabelaInstrukcjeSrodowiskowe,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 1,
        Dostep_szczegolowy: 5,
      },
    },
  },

  {
    path: "/instrukcje_srodowiskowe_przeglady/:id?",
    name: "StronaTabelaPrzegladyInstrukcjeSrodowiskowe",
    component: StronaTabelaPrzegladyInstrukcjeSrodowiskowe,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 1,
        Dostep_szczegolowy: 5,
      },
    },
  },

  //____________________________________________________

  //INSTRUKCJE BHP

  {
    path: "/instrukcje_BHP/:id?",
    name: "StronaTabelaInstrukcjeBHP",
    component: StronaTabelaInstrukcjeBHP,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 1,
        Dostep_szczegolowy: 6,
      },
    },
  },

  {
    path: "/instrukcje_BHP_przeglady/:id?",
    name: "StronaTabelaPrzegladyInstrukcjeBHP",
    component: StronaTabelaPrzegladyInstrukcjeBHP,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 1,
        Dostep_szczegolowy: 6,
      },
    },
  },

  //____________________________________________________

  //RYZYKA SZANSE

  {
    path: "/ryzyka_szanse/:id?",
    name: "StronaTabelaRyzykaSzanse",
    component: StronaTabelaRyzykaSzanse,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 1,
        Dostep_szczegolowy: 7,
      },
    },
  },

  {
    path: "/tabela_przeglad_ryzyka_szanse/:id?",
    name: "StronaTabelaPrzegladRyzykaSzanse",
    component: StronaTabelaPrzegladRyzykaSzanse,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 1,
        Dostep_szczegolowy: 7,
      },
    },
  },

  //____________________________________________________

  //ZAGROZENIA BHP

  {
    path: "/zagrozenia_bhp/:id?",
    name: "StronaTabelaZagrozeniaBHP",
    component: StronaTabelaZagrozeniaBHP,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 1,
        Dostep_szczegolowy: 8,
      },
    },
  },

  {
    path: "/tabela_przeglad_zagrozenia_bhp/:id?",
    name: "StronaTabelaPrzegladZagrozeniaBHP",
    component: StronaTabelaPrzegladZagrozeniaBHP,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 1,
        Dostep_szczegolowy: 8,
      },
    },
  },

  //____________________________________________________

  //CELE

  {
    path: "/cele_okres_realizacji/:id?",
    name: "StronaTabelaRokCele",
    component: StronaTabelaRokCele,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 1,
        Dostep_szczegolowy: 9,
      },
    },
  },

  {
    path: "/cele_szczegolowe/:id?",
    name: "StronaTabelaCeleSzczegolowe",
    component: StronaTabelaCeleSzczegolowe,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 1,
        Dostep_szczegolowy: 9,
      },
    },
  },

  {
    path: "/zadania_cele_szczegolowe/:id?",
    name: "StronaTabelaCeleZadania",
    component: StronaTabelaCeleZadania,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 1,
        Dostep_szczegolowy: 9,
      },
    },
  },

  {
    path: "/cele_przeglady/:id?",
    name: "StronaTabelaCelePrzeglady",
    component: StronaTabelaCelePrzeglady,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 1,
        Dostep_szczegolowy: 9,
      },
    },
  },

  //____________________________________________________

  //WYMAGANIA PRAWNE

  {
    path: "/wymagania_prawne/:id?",
    name: "StronaTabelaWymaganiaPrawne",
    component: StronaTabelaWymaganiaPrawne,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 1,
        Dostep_szczegolowy: 10,
      },
    },
  },

  {
    path: "/tabela_przeglad_wymagania_prawne/:id?",
    name: "StronaTabelaPrzegladWymaganiaPrawne",
    component: StronaTabelaPrzegladWymaganiaPrawne,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 1,
        Dostep_szczegolowy: 10,
      },
    },
  },

  //Aspekty

  {
    path: "/aspekty/:id?",
    name: "StronaTabelaAspekty",
    component: StronaTabelaAspekty,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 1,
        Dostep_szczegolowy: 11,
      },
    },
  },

  {
    path: "/aspekty_wymagania/:id?",
    name: "StronaTabelaAspektyWymagania",
    component: StronaTabelaAspektyWymagania,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 1,
        Dostep_szczegolowy: 11,
      },
    },
  },

  {
    path: "/aspekty_monitorowanie/:id?",
    name: "StronaTabelaAspektyMonitorowanie",
    component: StronaTabelaAspektyMonitorowanie,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 1,
        Dostep_szczegolowy: 11,
      },
    },
  },

  {
    path: "/aspekty_przeglady/:id?",
    name: "StronaTabelaPrzegladAspekty",
    component: StronaTabelaPrzegladAspekty,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 1,
        Dostep_szczegolowy: 11,
      },
    },
  },

  //____________________________________________________

  //Audity

  {
    path: "/audity/:id?",
    name: "StronaTabelaAudityProgram",
    component: StronaTabelaAudityProgram,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 1,
        Dostep_szczegolowy: 12,
      },
    },
  },

  {
    path: "/audity_plan/:id?",
    name: "StronaTabelaAudityPlan",
    component: StronaTabelaAudityPlan,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 1,
        Dostep_szczegolowy: 12,
      },
    },
  },

  {
    path: "/audity_raport/dodawanie/",
    name: "StronaFormularzAudityRaportDodawanie",
    component: StronaFormularzAudityRaport,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 1,
        Dostep_szczegolowy: 12,
      },
    },
  },

  {
    path: "/audity_raport/edycja/:id?",
    name: "StronaFormularzAudityRaportEdycja",
    component: StronaFormularzAudityRaport,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 1,
        Dostep_szczegolowy: 12,
      },
    },
  },

  {
    path: "/audity_raporty/:id?",
    name: "StronaTabelaAudityRaporty",
    component: StronaTabelaAudityRaporty,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 1,
        Dostep_szczegolowy: 12,
      },
    },
  },

  {
    path: "/audity_kompetencje/:id?",
    name: "StronaTabelaAudityKompetencje",
    component: StronaTabelaAudityKompetencje,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 1,
        Dostep_szczegolowy: 12,
      },
    },
  },

  //____________________________________________________

  //Dzialania audit

  {
    path: "/dzialania_audit/:id?",
    name: "StronaTabelaDzialaniaAudit",
    component: StronaTabelaDzialaniaAudit,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 1,
        Dostep_szczegolowy: 13,
      },
    },
  },

  {
    path: "/dzialania_audit_ocena/:id?",
    name: "StronaTabelaDzialaniaAuditOcena",
    component: StronaTabelaDzialaniaAuditOcena,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 1,
        Dostep_szczegolowy: 13,
      },
    },
  },

  {
    path: "/dzialania_audit_ocena_skutecznosci/:id?",
    name: "StronaTabelaDzialaniaAuditOcenaSkutecznosci",
    component: StronaTabelaDzialaniaAuditOcenaSkutecznosci,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 1,
        Dostep_szczegolowy: 13,
      },
    },
  },

  //____________________________________________________

  //Dzialania poza audit

  {
    path: "/dzialania_poza_auditowe/:id?",
    name: "StronaTabelaDzialaniaPozaAudit",
    component: StronaTabelaDzialaniaPozaAudit,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 1,
        Dostep_szczegolowy: 14,
      },
    },
  },

  {
    path: "/dzialania_poza_audit_ocena/:id?",
    name: "StronaTabelaDzialaniaPozaAuditOcena",
    component: StronaTabelaDzialaniaPozaAuditOcena,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 1,
        Dostep_szczegolowy: 14,
      },
    },
  },

  {
    path: "/dzialania_poza_audit_ocena_skutecznosci/:id?",
    name: "StronaTabelaDzialaniaPozaAuditOcenaSkutecznosci",
    component: StronaTabelaDzialaniaPozaAuditOcenaSkutecznosci,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 1,
        Dostep_szczegolowy: 14,
      },
    },
  },

  //____________________________________________________

  //Przeglad Zarządzania

  {
    path: "/przeglad_zarzadzania/:id?",
    name: "StronaTabelaPrzegladZarzadzaniaWykaz",
    component: StronaTabelaPrzegladZarzadzaniaWykaz,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 1,
        Dostep_szczegolowy: 15,
      },
    },
  },

  {
    path: "/przeglad_zarzadzania_protokol/:id?",
    name: "StronaTabelaPrzegladZarzadzania",
    component: StronaTabelaPrzegladZarzadzania,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 1,
        Dostep_szczegolowy: 15,
      },
    },
  },

  //____________________________________________________

  //ZADOWOLENIE KLIENTA

  {
    path: "/zadowolenie_klienta/:id?",
    name: "StronaTabelaZadowolenie",
    component: StronaTabelaZadowolenie,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 1,
        Dostep_szczegolowy: 16,
      },
    },
  },

  //____________________________________________________

  //SPRAWOZDAWCZOSC SRODOWISKOWA

  {
    path: "/sprawozdawczosc_srodowiskowa/:id?",
    name: "StronaTabelaSprawozdawczosc",
    component: StronaTabelaSprawozdawczosc,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 1,
        Dostep_szczegolowy: 17,
      },
    },
  },

  //MODUŁ WST___________________________________________________________________________________________________________________________________________________________

  //Maszyny

  {
    path: "/maszyny_i_urzadzenia/:id?",
    name: "StronaTabelaMaszyny",
    component: StronaTabelaMaszyny,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 18,
      },
    },
  },

  {
    path: "/tabela_wykaz_przeglady_maszyn/:id?",
    name: "StronaTabelaMaszynyPrzeglady",
    component: StronaTabelaMaszynyPrzeglady,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 18,
      },
    },
  },

  {
    path: "/tabela_wykaz_ubezpieczen_maszyn/:id?",
    name: "StronaTabelaMaszynyUbezpieczenia",
    component: StronaTabelaMaszynyUbezpieczenia,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 18,
      },
    },
  },

  {
    path: "/tabela_maszyny_paliwo/:id?",
    name: "StronaTabelaMaszynyPaliwo",
    component: StronaTabelaMaszynyPaliwo,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 18,
      },
    },
  },

  {
    path: "/tabela_wykaz_kosztow_maszyn/:id?",
    name: "StronaTabelaMaszynyKoszty",
    component: StronaTabelaMaszynyKoszty,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 18,
      },
    },
  },

  {
    path: "/generator_przegladow_maszyn/:id?",
    name: "StronaTabelaMaszynyGenerator",
    component: StronaTabelaMaszynyGenerator,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 18,
      },
    },
  },
  {
    path: "/generator_protokol/:id?",
    name: "StronaTabelaMaszynyGenratorPrzeglad",
    component: StronaTabelaMaszynyGenratorPrzeglad,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 18,
      },
    },
  },

  //____________________________________________________

  //Maszyny dozorowe

  {
    path: "/maszyny_i_urzadzenia_dozorowe/:id?",
    name: "StronaTabelaMaszynyDozorowe",
    component: StronaTabelaMaszynyDozorowe,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 19,
      },
    },
  },

  {
    path: "/maszyny_i_urzadzenia_dozorowe_przeglady/:id?",
    name: "StronaTabelaMaszynyDozorowePrzeglady",
    component: StronaTabelaMaszynyDozorowePrzeglady,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 19,
      },
    },
  },

  {
    path: "/maszyny_i_urzadzenia_dozorowe_ubezpieczenia/:id?",
    name: "StronaTabelaMaszynyDozoroweUbezpieczenia",
    component: StronaTabelaMaszynyDozoroweUbezpieczenia,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 19,
      },
    },
  },

  {
    path: "/maszyny_i_urzadzenia_dozorowe_paliwo/:id?",
    name: "StronaTabelaMaszynyDozorowePaliwo",
    component: StronaTabelaMaszynyDozorowePaliwo,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 19,
      },
    },
  },

  {
    path: "/maszyny_i_urzadzenia_dozorowe_koszty/:id?",
    name: "StronaTabelaMaszynyDozoroweKoszty",
    component: StronaTabelaMaszynyDozoroweKoszty,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 19,
      },
    },
  },

  //____________________________________________________

  //Elektronarzedzia

  {
    path: "/tabela_elektronarzedzia/:id?",
    name: "StronaTabelaElektronarzedzia",
    component: StronaTabelaElektronarzedzia,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 20,
      },
    },
  },

  {
    path: "/generator_przegladow_elektronarzedzia/:id?",
    name: "StronaTabelaElektronarzedziaGenerator",
    component: StronaTabelaElektronarzedziaGenerator,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 20,
      },
    },
  },
  {
    path: "/generator_protokol_elektronarzedzia/:id?",
    name: "StronaTabelaElektronarzedziaGeneratorPrzeglad",
    component: StronaTabelaElektronarzedziaGeneratorPrzeglad,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 20,
      },
    },
  },

  {
    path: "/tabela_elektronarzedzia_przeglady/:id?",
    name: "StronaTabelaElektronarzedziaPrzeglady",
    component: StronaTabelaElektronarzedziaPrzeglady,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 20,
      },
    },
  },

  {
    path: "/tabela_elektronarzedzia_ubezpieczenia/:id?",
    name: "StronaTabelaElektronarzedziaUbezpieczenia",
    component: StronaTabelaElektronarzedziaUbezpieczenia,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 20,
      },
    },
  },

  {
    path: "/tabela_elektronarzedzia_koszty/:id?",
    name: "StronaTabelaElektronarzedziaKoszty",
    component: StronaTabelaElektronarzedziaKoszty,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 20,
      },
    },
  },

  //____________________________________________________

  //IT

  {
    path: "/tabela_IT/:id?",
    name: "StronaTabelaIT",
    component: StronaTabelaIT,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 21,
      },
    },
  },

  {
    path: "/tabela_IT_przeglady/:id?",
    name: "StronaTabelaITPrzeglady",
    component: StronaTabelaITPrzeglady,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 21,
      },
    },
  },
  {
    path: "/generator_przegladow_IT/:id?",
    name: "StronaTabelaITGenerator",
    component: StronaTabelaITGenerator,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 21,
      },
    },
  },
  {
    path: "/generator_protokol_IT/:id?",
    name: "StronaTabelaITGeneratorPrzeglad",
    component: StronaTabelaITGeneratorPrzeglad,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 21,
      },
    },
  },
  {
    path: "/tabela_IT_ubezpieczenia/:id?",
    name: "StronaTabelaITUbezpieczenia",
    component: StronaTabelaITUbezpieczenia,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 21,
      },
    },
  },

  {
    path: "/tabela_IT_koszty/:id?",
    name: "StronaTabelaITKoszty",
    component: StronaTabelaITKoszty,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 21,
      },
    },
  },

  //____________________________________________________

  //Budynki

  {
    path: "/budynki/:id?",
    name: "StronaTabelaBudynki",
    component: StronaTabelaBudynki,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 22,
      },
    },
  },

  {
    path: "/budynki_ubezpieczenia/:id?",
    name: "StronaTabelaBudynkiUbezpieczenia",
    component: StronaTabelaBudynkiUbezpieczenia,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 22,
      },
    },
  },

  {
    path: "/budynki_koszty/:id?",
    name: "StronaTabelaBudynkiKoszty",
    component: StronaTabelaBudynkiKoszty,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 22,
      },
    },
  },

  {
    path: "/budynki_media/:id?",
    name: "StronaTabelaBudynkiMedia",
    component: StronaTabelaBudynkiMedia,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 22,
      },
    },
  },

  {
    path: "/budynki_przeglady_5lat/:id?",
    name: "StronaTabelaBudynkiPrzeglady5lat",
    component: StronaTabelaBudynkiPrzeglady5lat,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 22,
      },
    },
  },

  {
    path: "/budynki_przeglady_roczne/:id?",
    name: "StronaTabelaBudynkiPrzegladyRocznych",
    component: StronaTabelaBudynkiPrzegladyRocznych,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 22,
      },
    },
  },

  {
    path: "/budynki_przeglady_polroczne/:id?",
    name: "StronaTabelaBudynkiPrzegladyPolrocznych",
    component: StronaTabelaBudynkiPrzegladyPolrocznych,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 22,
      },
    },
  },

  {
    path: "/budynki_przeglady_elektryczne/:id?",
    name: "StronaTabelaBudynkiPrzegladyElektryka",
    component: StronaTabelaBudynkiPrzegladyElektryka,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 22,
      },
    },
  },

  {
    path: "/budynki_przeglady_gazowe/:id?",
    name: "StronaTabelaBudynkiPrzegladyGaz",
    component: StronaTabelaBudynkiPrzegladyGaz,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 22,
      },
    },
  },

  {
    path: "/budynki_przeglady_hydrantow/:id?",
    name: "StronaTabelaBudynkiPrzegladyHydrant",
    component: StronaTabelaBudynkiPrzegladyHydrant,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 22,
      },
    },
  },

  {
    path: "/budynki_przeglady_instrukcja_ppoz/:id?",
    name: "StronaTabelaBudynkiPrzegladyInstrukcja",
    component: StronaTabelaBudynkiPrzegladyInstrukcja,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 22,
      },
    },
  },

  {
    path: "/budynki_przeglady_kominow/:id?",
    name: "StronaTabelaBudynkiPrzegladyKominy",
    component: StronaTabelaBudynkiPrzegladyKominy,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 22,
      },
    },
  },

  {
    path: "/budynki_przeglady_wylacznika/:id?",
    name: "StronaTabelaBudynkiPrzegladyWylacznik",
    component: StronaTabelaBudynkiPrzegladyWylacznik,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 22,
      },
    },
  },

  //____________________________________________________

  //Samochody osobowe

  {
    path: "/samochody_osobowe/:id?",
    name: "StronaTabelaSamochodyOsobowe",
    component: StronaTabelaSamochodyOsobowe,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 23,
      },
    },
  },

  {
    path: "/tabela_wykaz_przeglady_samochody_osobowe/:id?",
    name: "StronaTabelaSamochodyOsobowePrzeglady",
    component: StronaTabelaSamochodyOsobowePrzeglady,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 23,
      },
    },
  },

  {
    path: "/tabela_wykaz_ubezpieczen_samochody_osobowe/:id?",
    name: "StronaTabelaSamochodyOsoboweUbezpieczenia",
    component: StronaTabelaSamochodyOsoboweUbezpieczenia,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 23,
      },
    },
  },

  {
    path: "/tabela_samochody_osobowe_paliwo/:id?",
    name: "StronaTabelaSamochodyOsobowePaliwo",
    component: StronaTabelaSamochodyOsobowePaliwo,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 23,
      },
    },
  },

  {
    path: "/tabela_wykaz_kosztow_samochody_osobowe/:id?",
    name: "StronaTabelaSamochodyOsoboweKoszty",
    component: StronaTabelaSamochodyOsoboweKoszty,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 23,
      },
    },
  },

  //____________________________________________________

  //Samochody ciezarowe

  {
    path: "/samochody_ciezarowe/:id?",
    name: "StronaTabelaSamochodyCiezarowe",
    component: StronaTabelaSamochodyCiezarowe,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 24,
      },
    },
  },

  {
    path: "/tabela_wykaz_przeglady_samochody_ciezarowe/:id?",
    name: "StronaTabelaSamochodyCiezarowePrzeglady",
    component: StronaTabelaSamochodyCiezarowePrzeglady,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 24,
      },
    },
  },

  {
    path: "/tabela_wykaz_ubezpieczen_samochody_ciezarowe/:id?",
    name: "StronaTabelaSamochodyCiezaroweUbezpieczenia",
    component: StronaTabelaSamochodyCiezaroweUbezpieczenia,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 24,
      },
    },
  },

  {
    path: "/tabela_samochody_ciezarowe_paliwo/:id?",
    name: "StronaTabelaSamochodyCiezarowePaliwo",
    component: StronaTabelaSamochodyCiezarowePaliwo,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 24,
      },
    },
  },

  {
    path: "/tabela_wykaz_kosztow_samochody_ciezarowe/:id?",
    name: "StronaTabelaSamochodyCiezaroweKoszty",
    component: StronaTabelaSamochodyCiezaroweKoszty,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 24,
      },
    },
  },

  {
    path: "/tabela_wykaz_tachografow_samochody_ciezarowe/:id?",
    name: "StronaTabelaSamochodyCiezaroweTacho",
    component: StronaTabelaSamochodyCiezaroweTacho,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 24,
      },
    },
  },

  {
    path: "/tabela_wykaz_chlodni_samochody_ciezarowe/:id?",
    name: "StronaTabelaSamochodyCiezaroweChlodnie",
    component: StronaTabelaSamochodyCiezaroweChlodnie,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 24,
      },
    },
  },

  {
    path: "/tabela_wykaz_termografy_samochody_ciezarowe/:id?",
    name: "StronaTabelaSamochodyCiezaroweTermograf",
    component: StronaTabelaSamochodyCiezaroweTermograf,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 24,
      },
    },
  },

  //____________________________________________________

  //Pomiarowka

  {
    path: "/sprzet_pomiarowy/:id?",
    name: "StronaTabelaPomiarowka",
    component: StronaTabelaPomiarowka,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 25,
      },
    },
  },

  {
    path: "/sprzet_pomiarowy_przeglady/:id?",
    name: "StronaTabelaPomiarowkaPrzeglady",
    component: StronaTabelaPomiarowkaPrzeglady,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 25,
      },
    },
  },

  {
    path: "/sprzet_pomiarowy_ubezpieczenia/:id?",
    name: "StronaTabelaPomiarowkaUbezpieczenia",
    component: StronaTabelaPomiarowkaUbezpieczenia,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 25,
      },
    },
  },

  {
    path: "/sprzet_pomiarowy_koszty/:id?",
    name: "StronaTabelaPomiarowkaKoszty",
    component: StronaTabelaPomiarowkaKoszty,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 25,
      },
    },
  },

  //____________________________________________________

  //Wyposazenie BHP

  {
    path: "/wyposazenie_BHP/:id?",
    name: "StronaTabelaWyposazenieBHP",
    component: StronaTabelaWyposazenieBHP,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 26,
      },
    },
  },

  {
    path: "/wyposazenie_BHP_przeglady/:id?",
    name: "StronaTabelaWyposazeniePrzegladyBHP",
    component: StronaTabelaWyposazeniePrzegladyBHP,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 26,
      },
    },
  },

  //____________________________________________________

  //Wyposazenie PPOZ

  {
    path: "/wyposazenie_PPOZ/:id?",
    name: "StronaTabelaWyposazeniePPOZ",
    component: StronaTabelaWyposazeniePPOZ,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 27,
      },
    },
  },

  {
    path: "/wyposazenie_PPOZ_przeglady/:id?",
    name: "StronaTabelaWyposazeniePrzegladyPPOZ",
    component: StronaTabelaWyposazeniePrzegladyPPOZ,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 27,
      },
    },
  },

  //____________________________________________________

  //GENERATOR ŚWIADECT SPRAWDZENIA

  {
    path: "/wykaz_sprzetu_pomiarowego/:id?",
    name: "StronaTabelaPomiarowkaGenerator",
    component: StronaTabelaPomiarowkaGenerator,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 28,
      },
    },
  },

  {
    path: "/wykaz_sprawdzen/:id?",
    name: "StronaTabelaWykazSprawdzen",
    component: StronaTabelaWykazSprawdzen,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 28,
      },
    },
  },

  {
    path: "/swiadectwo_sprawdzenia/:id?",
    name: "StronaTabelaSwiadectwoSprawdzenia",
    component: StronaTabelaSwiadectwoSprawdzenia,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 28,
      },
    },
  },

  //____________________________________________________

  //EMISJE

  {
    path: "/wykaz_emisje/:id?",
    name: "StronaTabelaEmisje",
    component: StronaTabelaEmisje,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 2,
        Dostep_szczegolowy: 29,
      },
    },
  },

  // MODUŁ HRB_HR___________________________________________________________________________________________________________________________________________________________

  //PRACOWNICY ZATRUDNIENI

  {
    path: "/pracownicy_zatrudnieni/:id?",
    name: "StronaTabelaWykazPracownikowZatrudnionych",
    component: StronaTabelaWykazPracownikowZatrudnionych,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 3,
        Dostep_szczegolowy: 30,
      },
    },
  },

  {
    path: "/pracownicy_zwolnieni/:id?",
    name: "StronaTabelaWykazPracownikowZwolnionych",
    component: StronaTabelaWykazPracownikowZwolnionych,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 3,
        Dostep_szczegolowy: 30,
      },
    },
  },

  {
    path: "/pracownicy_zatrudnieni_uzupelnienie/:id?",
    name: "StronaTabelaWykazPracownikowZatrudnionychUzupelnienie",
    component: StronaTabelaWykazPracownikowZatrudnionychUzupelnienie,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 3,
        Dostep_szczegolowy: 30,
      },
    },
  },

  {
    path: "/pracownicy_zatrudnieni_adres_zamieszkania/:id?",
    name: "StronaTabelaWykazAdresZamieszkania",
    component: StronaTabelaWykazAdresZamieszkania,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 3,
        Dostep_szczegolowy: 30,
      },
    },
  },

  {
    path: "/pracownicy_zatrudnieni_adres_korespondencja/:id?",
    name: "StronaTabelaWykazAdresKorespondencja",
    component: StronaTabelaWykazAdresKorespondencja,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 3,
        Dostep_szczegolowy: 30,
      },
    },
  },

  {
    path: "/pracownicy_dane_zatrudnienia/:id?",
    name: "StronaTabelaDaneZatrudnieniaPracownika",
    component: StronaTabelaDaneZatrudnieniaPracownika,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 3,
        Dostep_szczegolowy: 30,
      },
    },
  },

  {
    path: "/pracownicy_zakres_obowiazkow/:id?",
    name: "StronaTabelaWykazZakresowObowiazkow",
    component: StronaTabelaWykazZakresowObowiazkow,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 3,
        Dostep_szczegolowy: 30,
      },
    },
  },

  {
    path: "/pracownicy_badania/:id?",
    name: "StronaTabelaWykazBadan",
    component: StronaTabelaWykazBadan,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 3,
        Dostep_szczegolowy: 30,
      },
    },
  },

  {
    path: "/pracownicy_szkolenia_WS/:id?",
    name: "StronaTabelaWykazSzkolenStanowiskowych",
    component: StronaTabelaWykazSzkolenStanowiskowych,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 3,
        Dostep_szczegolowy: 30,
      },
    },
  },

  {
    path: "/pracownicy_szkolenia_okresowe_BHP/:id?",
    name: "StronaTabelaWykazSzkoleniaBHP",
    component: StronaTabelaWykazSzkoleniaBHP,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 3,
        Dostep_szczegolowy: 30,
      },
    },
  },

  {
    path: "/pracownicy_zapoznanie_ORZ/:id?",
    name: "StronaTabelaWykazZapoznanieORZ",
    component: StronaTabelaWykazZapoznanieORZ,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 3,
        Dostep_szczegolowy: 30,
      },
    },
  },

  {
    path: "/pracownicy_zapoznanie_IO/:id?",
    name: "StronaTabelaWykazZapoznanieIO",
    component: StronaTabelaWykazZapoznanieIO,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 3,
        Dostep_szczegolowy: 30,
      },
    },
  },

  {
    path: "/pracownicy_zapoznanie_IS/:id?",
    name: "StronaTabelaWykazZapoznanieIS",
    component: StronaTabelaWykazZapoznanieIS,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 3,
        Dostep_szczegolowy: 30,
      },
    },
  },

  {
    path: "/pracownicy_zapoznanie_IBHP/:id?",
    name: "StronaTabelaWykazZapoznanieIBHP",
    component: StronaTabelaWykazZapoznanieIBHP,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 3,
        Dostep_szczegolowy: 30,
      },
    },
  },

  {
    path: "/pracownicy_kompetencje/:id?",
    name: "StronaTabelaWykazKompetencje",
    component: StronaTabelaWykazKompetencje,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 3,
        Dostep_szczegolowy: 30,
      },
    },
  },

  {
    path: "/pracownicy_historia_zatrudnienia/:id?",
    name: "StronaTabelaWykazHistoriaZatrudnienia",
    component: StronaTabelaWykazHistoriaZatrudnienia,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 3,
        Dostep_szczegolowy: 30,
      },
    },
  },

  //____________________________________________________

  //GENERATOR ZAKRESÓW

  {
    path: "/wykaz_zakresow/:id?",
    name: "StronaTabelaWykazZakresy",
    component: StronaTabelaWykazZakresy,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 3,
        Dostep_szczegolowy: 31,
      },
    },
  },
  {
    path: "/zakres_pracownik/:id?",
    name: "StronaTabelaZakresObowiazkow1",
    component: StronaTabelaZakresObowiazkow1,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 3,
        Dostep_szczegolowy: 31,
      },
    },
  },
  {
    path: "/zakres_kierujacy_pracownikami/:id?",
    name: "StronaTabelaZakresObowiazkow",
    component: StronaTabelaZakresObowiazkow,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 3,
        Dostep_szczegolowy: 31,
      },
    },
  },

  {
    path: "/zakres_katalog/:id?",
    name: "StronaTabelaKatalogCzesciZakresu",
    component: StronaTabelaKatalogCzesciZakresu,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 3,
        Dostep_szczegolowy: 31,
      },
    },
  },

  //____________________________________________________

  //PRACOWNICY ABSENJCA

  {
    path: "/pracownicy_absencja/:id?",
    name: "StronaTabelaWykazAbsencja",
    component: StronaTabelaWykazAbsencja,
   
  },

  //____________________________________________________

  //STANOWISKA

  {
    path: "/stanowiska/:id?",
    name: "StronaTabelaWykazStanowisk",
    component: StronaTabelaWykazStanowisk,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 3,
        Dostep_szczegolowy: 32,
      },
    },
  },

  {
    path: "/stanowiska_przeglad/:id?",
    name: "StronaTabelaWykazPrzegladStanowiska",
    component: StronaTabelaWykazPrzegladStanowiska,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 3,
        Dostep_szczegolowy: 32,
      },
    },
  },

  //____________________________________________________

  //SZKOLENIA

  {
    path: "/szkolenia/:id?",
    name: "StronaTabelaWykazPlanow",
    component: StronaTabelaWykazPlanow,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 3,
        Dostep_szczegolowy: 33,
      },
    },
  },

  {
    path: "/szkolenia_wykaz/:id?",
    name: "StronaTabelaWykazSzkolen",
    component: StronaTabelaWykazSzkolen,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 3,
        Dostep_szczegolowy: 33,
      },
    },
  },

  {
    path: "/realizacja_szkolenia/:id?",
    name: "StronaTabelaWykazRealizacji",
    component: StronaTabelaWykazRealizacji,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 3,
        Dostep_szczegolowy: 33,
      },
    },
  },

  {
    path: "/wykaz_uczestnikow/:id?",
    name: "StronaTabelaWykazUczestnikow",
    component: StronaTabelaWykazUczestnikow,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 3,
        Dostep_szczegolowy: 33,
      },
    },
  },

  //____________________________________________________

  // SKIEROWANIA MEDYCYNA

  {
    path: "/wykaz_skierowan/:id?",
    name: "StronaTabelaWykazSkierowan",
    component: StronaTabelaWykazSkierowan,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 3,
        Dostep_szczegolowy: 34,
      },
    },
  },

  {
    path: "/skierowanie/:id?",
    name: "StronaTabelaSkierowanie",
    component: StronaTabelaSkierowanie,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 3,
        Dostep_szczegolowy: 34,
      },
    },
  },

  //____________________________________________________

  //DOKEMNTACJA MEDYCYNA

  {
    path: "/medycyna_pracy/:id?",
    name: "StronaTabelaMedycynaPracy",
    component: StronaTabelaMedycynaPracy,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 3,
        Dostep_szczegolowy: 35,
      },
    },
  },

  //MODUŁ BHP ___________________________________________________________________________________________________________________________________________________________

  //BHP ORZ

  {
    path: "/dokumentacja_ORZ/:id?",
    name: "StronaTabelaDokumentyORZ",
    component: StronaTabelaDokumentyORZ,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 4,
        Dostep_szczegolowy: 36,
      },
    },
  },

  {
    path: "/ORZ_dokumentacja_przeglady/:id?",
    name: "StronaTabelaPrzegladyDokumentyORZ",
    component: StronaTabelaPrzegladyDokumentyORZ,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 4,
        Dostep_szczegolowy: 36,
      },
    },
  },

  //____________________________________________________

  //BHP BADANIA ŚRODOWISKA PRACY

  {
    path: "/dokumentacja_BSP/:id?",
    name: "StronaTabelaDokumentyBSP",
    component: StronaTabelaDokumentyBSP,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 4,
        Dostep_szczegolowy: 37,
      },
    },
  },

  {
    path: "/BSP_dokumentacja_szczegoly/:id?",
    name: "StronaTabelaPrzegladyDokumentyBSP",
    component: StronaTabelaPrzegladyDokumentyBSP,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 4,
        Dostep_szczegolowy: 37,
      },
    },
  },

  //____________________________________________________

  //BHP SUBSTANCJE NIEBEZPIECZNE

  {
    path: "/dokumentacja_substancje_niebezpieczne/:id?",
    name: "StronaTabelaSubstancjeNiebezpieczne",
    component: StronaTabelaSubstancjeNiebezpieczne,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 4,
        Dostep_szczegolowy: 38,
      },
    },
  },

  {
    path: "/substancje_niebezpieczne_dokumentacja_przeglady/:id?",
    name: "StronaTabelaPrzegladySubstancjeNiebezpieczne",
    component: StronaTabelaPrzegladySubstancjeNiebezpieczne,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 4,
        Dostep_szczegolowy: 38,
      },
    },
  },

  //____________________________________________________

  //OCENA STANU BHP

  {
    path: "/ocena_stanu_BHP/:id?",
    name: "StronaTabelaOcenaStanuBHP",
    component: StronaTabelaOcenaStanuBHP,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 4,
        Dostep_szczegolowy: 39,
      },
    },
  },

  {
    path: "/zespol_BHP/:id?",
    name: "StronaTabelaWykazZespolBHP",
    component: StronaTabelaWykazZespolBHP,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 4,
        Dostep_szczegolowy: 39,
      },
    },
  },

  //____________________________________________________

  //WYPADKI W PRACY

  {
    path: "/wypadki_w_pracy/:id?",
    name: "StronaTabelaWykazWypadkowWPracy",
    component: StronaTabelaWykazWypadkowWPracy,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 4,
        Dostep_szczegolowy: 40,
      },
    },
  },

  {
    path: "/dzialania_wypadki_w_pracy/:id?",
    name: "StronaTabelaWykazWypadkowWPracyOcena",
    component: StronaTabelaWykazWypadkowWPracyOcena,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 4,
        Dostep_szczegolowy: 40,
      },
    },
  },

  {
    path: "/ocena_skutecznosci_dzialania_wypadki_w_pracy/:id?",
    name: "StronaTabelaWykazWypadkowWPracyOcenaSkutecznosci",
    component: StronaTabelaWykazWypadkowWPracyOcenaSkutecznosci,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 4,
        Dostep_szczegolowy: 40,
      },
    },
  },

  //____________________________________________________

  //CHOROBY ZAWODOWE

  {
    path: "/choroby_zawodowe/:id?",
    name: "StronaTabelaWykazChorobZawodowych",
    component: StronaTabelaWykazChorobZawodowych,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 4,
        Dostep_szczegolowy: 41,
      },
    },
  },

  {
    path: "/skutki_choroby_zawodowe_dzialania/:id?",
    name: "StronaTabelaWykazDzialanSkutkowChorobyZawodowe",
    component: StronaTabelaWykazDzialanSkutkowChorobyZawodowe,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 4,
        Dostep_szczegolowy: 41,
      },
    },
  },

  //____________________________________________________

  //SYTUACJE POTENCJALNIE NIEBEZPIECZNE

  {
    path: "/sytuacje_potencjalnie_niebezpieczne/:id?",
    name: "StronaTabelaWykazSytuacje",
    component: StronaTabelaWykazSytuacje,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 4,
        Dostep_szczegolowy: 42,
    },
  },
  },
  {
    path: "/sytuacje_potencjalnie_niebezpieczne_dzialania/:id?",
    name: "StronaTabelaWykazSytuacjeOcena",
    component: StronaTabelaWykazSytuacjeOcena,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 4,
        Dostep_szczegolowy: 42,
      },
    },
  },

  {
    path: "/ocena_sytuacje_potencjalnie_niebezpieczne_dzialania/:id?",
    name: "StronaTabelaWykazSytuacjeOcenaSkutecznosci",
    component: StronaTabelaWykazSytuacjeOcenaSkutecznosci,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 4,
        Dostep_szczegolowy: 42,
      },
    },
  },

  //____________________________________________________

  //BHP KONTROLE WEWNĘTRZBE

  {
    path: "/wykaz_kontroli_wewnetrznych/:id?",
    name: "StronaTabelaWykazKontroliWewnetrznych",
    component: StronaTabelaWykazKontroliWewnetrznych,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 4,
        Dostep_szczegolowy: 43,
      },
    },
  },

  {
    path: "/wykaz_kontroli_wewnetrznych_realizacja_uwag/:id?",
    name: "StronaTabelaWykazKontroliWewnetrznychRealizacja",
    component: StronaTabelaWykazKontroliWewnetrznychRealizacja,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 4,
        Dostep_szczegolowy: 43,
      },
    },
  },

  {
    path: "/ocena_wykaz_kontroli_wewnetrznych_realizacja_uwag/:id?",
    name: "StronaTabelaDzialaniaKontroleOcenaSkutecznosci",
    component: StronaTabelaDzialaniaKontroleOcenaSkutecznosci,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 4,
        Dostep_szczegolowy: 43,
      },
    },
  },

  //____________________________________________________

  //BHP KONTROLE ZEWNĘTRZBE

  {
    path: "/wykaz_kontroli_zewnetrznych/:id?",
    name: "StronaTabelaWykazKontroliZewnetrznych",
    component: StronaTabelaWykazKontroliZewnetrznych,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 4,
        Dostep_szczegolowy: 44,
      },
    },
  },

  {
    path: "/wykaz_kontroli_zewnetrznych_realizacja_uwag/:id?/:id_uwagi?",
    name: "StronaTabelaWykazKontroliZewnetrznychRealizacja",
    component: StronaTabelaWykazKontroliZewnetrznychRealizacja,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 4,
        Dostep_szczegolowy: 44,
      },
    },
  },

  {
    path: "/ocena_uwai_wykaz_kontroli_zewnetrznych_realizacja/:id?",
    name: "StronaTabelaDzialaniaKontroleZewnetrzneOcenaSkutecznosci",
    component: StronaTabelaDzialaniaKontroleZewnetrzneOcenaSkutecznosci,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 4,
        Dostep_szczegolowy: 44,
      },
    },
  },

  //____________________________________________________

  //BHP PRACE SZCZEGÓLNIE NIEBEZPIECZNE

  {
    path: "/dokumentacja_prace_szczegolnie_niebezpieczne/:id?",
    name: "StronaTabelaPraceSzczegolnieNiebezpieczne",
    component: StronaTabelaPraceSzczegolnieNiebezpieczne,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 4,
        Dostep_szczegolowy: 45,
      },
    },
  },

  {
    path: "/przeglady_dokumentacja_prace_szczegolnie_niebezpieczne/:id?",
    name: "StronaTabelaPrzegladPraceSzczegolnieNiebezpieczne",
    component: StronaTabelaPrzegladPraceSzczegolnieNiebezpieczne,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 4,
        Dostep_szczegolowy: 45,
      },
    },
  },

  // MODUŁ STEROWANIE OPERACYJNE___________________________________________________________________________________________________________________________________________________________

  // WYROBY

  {
    path: "/wykaz_wyrobow/:id?",
    name: "StronaTabelaWyroby",
    component: StronaTabelaWyroby,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 5,
        Dostep_szczegolowy: 46,
      },
    },
  },

  {
    path: "/grupy_wyrobow/:id?",
    name: "StronaTabelaGrupy",
    component: StronaTabelaGrupy,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 5,
        Dostep_szczegolowy: 46,
      },
    },
  },

  //____________________________________________________

  //KONTRAHENCI

  {
    path: "/tabela_kontrahenci/:id?",
    name: "StronaTabelaKontrahenci",
    component: StronaTabelaKontrahenci,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 5,
        Dostep_szczegolowy: 47,
      },
    },
  },

  {
    path: "/tabela_kontrahenci_kontakt/:id?",
    name: "StronaTabelaKontrahenciKontakt",
    component: StronaTabelaKontrahenciKontakt,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 5,
        Dostep_szczegolowy: 47,
      },
    },
  },

  //____________________________________________________

  //ZAKUPY

  {
    path: "/kwalifikowanie_dostawcy/:id?",
    name: "StronaTabelaZakupy",
    component: StronaTabelaZakupy,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 5,
        Dostep_szczegolowy: 48,
      },
    },
  },

  {
    path: "/zakupy/:id?",
    name: "StronaTabelaZakupyZrealizowane",
    component: StronaTabelaZakupyZrealizowane,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 5,
        Dostep_szczegolowy: 48,
      },
    },
  },

 
  {
    path: "/kwalifikowanie_dostawcy_przeglad/:id?",
    name: "StronaTabelaPrzegladZakupy",
    component: StronaTabelaPrzegladZakupy,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 5,
        Dostep_szczegolowy: 48,
      },
    },
  },

  //____________________________________________________

  //PROJEKTOWANIE

  {
    path: "/wykaz_projektow/:id?",
    name: "StronaTabelaWykazProjektow",
    component: StronaTabelaWykazProjektow,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 5,
        Dostep_szczegolowy: 49,
      },
    },
  },
  {
    path: "/wykaz_projektow_szczegoly/:id?",
    name: "StronaTabelaWykazProjektowSzczegoly",
    component: StronaTabelaWykazProjektowSzczegoly,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 5,
        Dostep_szczegolowy: 49,
      },
    },
  },
  //____________________________________________________

  //ZAPYTANIA OFERTOWE

  {
    path: "/zapytania_ofertowe/:id?",
    name: "StronaTabelaZapytania",
    component: StronaTabelaZapytania,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 5,
        Dostep_szczegolowy: 50,
      },
    },
  },

  {
    path: "/zapytania_ofertowe_odrzucone/:id?",
    name: "StronaTabelaZapytaniaOdrzucone",
    component: StronaTabelaZapytaniaOdrzucone,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 5,
        Dostep_szczegolowy: 50,
      },
    },
  },

  {
    path: "/zapytania_ofertowe_przyjete/:id?",
    name: "StronaTabelaZapytaniaPrzyjetych",
    component: StronaTabelaZapytaniaPrzyjetych,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 5,
        Dostep_szczegolowy: 50,
      },
    },
  },

  //____________________________________________________

  // OFERTY

  {
    path: "/oferty/:id?",
    name: "StronaTabelaOferty",
    component: StronaTabelaOferty,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 5,
        Dostep_szczegolowy: 51,
      },
    },
  },

  {
    path: "/oferty_odrzucone/:id?",
    name: "StronaTabelaOfertyOdrzucone",
    component: StronaTabelaOfertyOdrzucone,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 5,
        Dostep_szczegolowy: 51,
      },
    },
  },

  {
    path: "/oferty_przyjete/:id?",
    name: "StronaTabelaOfertyPrzyjete",
    component: StronaTabelaOfertyPrzyjete,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 5,
        Dostep_szczegolowy: 51,
      },
    },
  },

  //____________________________________________________

  // ZAMÓWIENIA

  {
    path: "/zamowienia/:id?",
    name: "StronaTabelaZamowienia",
    component: StronaTabelaZamowienia,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 5,
        Dostep_szczegolowy: 52,
      },
    },
  },

  //____________________________________________________

  // REALIZACJA

  {
    path: "/dzialania_operacyjne/:id?",
    name: "StronaTabelaDzialania",
    component: StronaTabelaDzialania,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 5,
        Dostep_szczegolowy: 53,
      },
    },
  },

  {
    path: "/pr1_raport_produkcyjny/:id?",
    name: "StronaTabelaRealizacja",
    component: StronaTabelaRealizacja,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 5,
        Dostep_szczegolowy: 53,
      },
    },
  },

  {
    path: "/pr3_raport_produkcyjny_zakonczone/:id?",
    name: "StronaTabelaRealizacjaZakonczone",
    component: StronaTabelaRealizacjaZakonczone,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 5,
        Dostep_szczegolowy: 53,
      },
    },
  },

  {
    path: "/pr2_raporty_produkcyjne/:id?/:id_wyrobu?",
    name: "StronaTabelaRaporty",
    component: StronaTabelaRaporty,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 5,
        Dostep_szczegolowy: 53,
      },
    },
  },

  {
    path: "/pr4_raporty_produkcyjne_zakonczone/:id?/:id_wyrobu?",
    name: "StronaTabelaRaportyZakonczone",
    component: StronaTabelaRaportyZakonczone,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 5,
        Dostep_szczegolowy: 53,
      },
    },
  },


  
  //____________________________________________________

  //ZLECENIA NA MAGAZYN

  {
    path: "/zlecenie_magazyn/:id?",
    name: "StronaTabelaZleceniaMagazyn",
    component: StronaTabelaZleceniaMagazyn,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 5,
        Dostep_szczegolowy: 54,
      },
    },
  },

//____________________________________________________

  // REALIZACJA MAGAZYN

  {
    path: "/dzialania_operacyjne_magazyn/:id?",
    name: "StronaTabelaDzialaniaMagazyn",
    component: StronaTabelaDzialaniaMagazyn,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 5,
        Dostep_szczegolowy: 55,
      },
    },
  },

  {
    path: "/rp5_raport_produkcyjny_magazyn/:id?",
    name: "StronaTabelaRealizacjaMagazyn",
    component: StronaTabelaRealizacjaMagazyn,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 5,
        Dostep_szczegolowy: 55,
      },
    },
  },

  {
    path: "/rp7_raport_produkcyjny_zakonczone_magazyn/:id?",
    name: "StronaTabelaRealizacjaZakonczoneMagazyn",
    component: StronaTabelaRealizacjaZakonczoneMagazyn,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 5,
        Dostep_szczegolowy: 55,
      },
    },
  },

  {
    path: "/rp6_raporty_produkcyjne_magazyn/:id?/:id_wyrobu?",
    name: "StronaTabelaRaportyMagazyn",
    component: StronaTabelaRaportyMagazyn,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 5,
        Dostep_szczegolowy: 55,
      },
    },
  },

  {
    path: "/rp8_raporty_produkcyjne_magazyn_zakonczone/:id?/:id_wyrobu?",
    name: "StronaTabelaRaportyMagazynZakonczone",
    component: StronaTabelaRaportyMagazynZakonczone,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 5,
        Dostep_szczegolowy: 55,
      },
    },
  },

  {
    path: "/realizacja_WZ_magazyn/:id?",
    name: "StronaTabelaRozliczenieWZMagazyn",
    component: StronaTabelaRozliczenieWZMagazyn,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 5,
        Dostep_szczegolowy: 55,
      },
    },
  },

  //____________________________________________________


  {
    path: "/tabela_reklamacje/:id?",
    name: "StronaTabelaReklamacje",
    component: StronaTabelaReklamacje,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 5,
        Dostep_szczegolowy: 56,
      },
    },
  },

  {
    path: "/tabela_reklamacje_dzialania/:id?",
    name: "StronaTabelaReklamacjeDzialania",
    component: StronaTabelaReklamacjeDzialania,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 5,
        Dostep_szczegolowy: 56,
      },
    },
  },

  //____________________________________________________


  {
    path: "/tabela_dostepu_szczegoly/:id?/:id_typu?",
    name: "TabelaLogowanieDostepSzczegoly",
    component: TabelaLogowanieDostepSzczegoly,
    meta: {
      guard: {
        asyncGuards: ["isAuthorized"],
        linealGuards: ["isLogged"],
      },
      ifFalseGoToPath: "/",
      params: {
        Typ_uzytkownika: [1, 2],
        Dostep_czesc_systemu: 6,
        Dostep_szczegolowy: 57,
      },
    },
  },
  
  //____________________________________________________________________________________________________________________________________________________________

  //Faktury

  {
    path: "/tabela_faktury/:id?",
    name: "StronaTabelaFaktury",
    component: StronaTabelaFaktury,
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const guards = {
  isLogged: () => {
    return userService.isUserLogged;
  },
  isLoggedOut: () => {
    return !userService.isUserLogged;
  },
  isAuthorized: async (params) => {
    return await getAuthorization(params);
  },
};

const getAuthorization = async (dane) => {
  try {
    const response = await api.sprawdzenieDostepnosci(dane);
    const status = response.data.sukces;
    return status;
  } catch (error) {
    return false;
  }
};

const checkGuards = async (
  {
    guard: { asyncGuards, linealGuards },
    // ifFalseGoToPath,
    params,
  },
  next
) => {
  const isAllLinealGuardsDone = linealGuards.every((validatorName) =>
    guards[validatorName](params)
  );
  let isAllAsyncGuardsDone = asyncGuards.length === 0;
  try {
    const responses = await Promise.all(
      asyncGuards.map((validatorName) => guards[validatorName](params))
    );
    isAllAsyncGuardsDone = responses.every((value) => value);
  } catch (error) {
    // return next(ifFalseGoToPath);
  }

  if (isAllLinealGuardsDone && isAllAsyncGuardsDone) {
    return next();
  } else {
    // return next(ifFalseGoToPath);
  }
};

router.beforeEach(async (to, from, next) => {
  if (to.meta && to.meta.guard) {
    if (userService.isStatusAvailable) {
      return await checkGuards(to.meta, next);
    } else {
      userService.getUserObservable().subscribe(async () => {
        return await checkGuards(to.meta, next);
      });
    }
  } else {
    next();
  }
  return;
});
export default router;
