<template>
  <div class="Form_Raport_produk">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI RAPORTU PRODUKCYJNEGO1"
                : "FORMULARZ DODAWANIA RAPORTU PRODUKCYJNEGO"
            }}
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-autocomplete  :class="getValidationClass('Nazwa_wybor_zamowienia')"
                v-model="form.Nazwa_wybor_zamowienia"
                :md-options="zapytaniaWartosc"
              >
                <label>Wybór zamówienia</label>
               <span class="md-error" v-if="!$v.form.Nazwa_wybor_zamowienia.required"
                  >Pole jest wymagane</span
                > 
              </md-autocomplete>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('Data_WZ')">
                <label for="Data_WZ">Data RW</label>
                <md-input
                  type="date"
                  name="Data_WZ"
                  id="Data_WZ"
                  
                  v-model="form.Data_WZ"
                  @input="pobierzNumerRaportu"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Data_WZ.required"
                  >Pole jest wymagane</span
                >
                <span class="md-error" v-else-if="!$v.form.Data_WZ.maxlength"
                  >Wymagana ilość znaków od 3 do 100</span
                >
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('Numer_WZ')">
                <label for="Numer_WZ">Numer RW</label>
                <md-input
                  name="Numer_WZ"
                  id="Numer_WZ"
                  
                  v-model="form.Numer_WZ"
                  :disabled="sending"
                  readonly
                />
                <span class="md-error" v-if="!$v.form.Numer_WZ.required"
                  >Pole jest wymagane</span
                >
                <span class="md-error" v-else-if="!$v.form.Numer_WZ.maxlength"
                  >Wymagana ilość znaków od 1 do 50</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('Osoba_odpowiedzialna')">
                <label for="Osoba_odpowiedzialna">Osoba wystawiająca</label>
                <md-input
                  name="Osoba_odpowiedzialna"
                  id="Osoba_odpowiedzialna"
                  
                  v-model="form.Osoba_odpowiedzialna"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Osoba_odpowiedzialna.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Osoba_odpowiedzialna.maxlength"
                  >Wymagana ilość znaków od 1 do 50</span
                >
              </md-field>
            </div>
          </div>

          <div
            style="
              margin-top: 40px;
              margin-left: 15px;
              font-weight: bold;
              font-size: 18px;
            "
          >
            SZCZEGÓŁY RAPORTU PRODUKCYJNEGO
          </div>
          <div
            style="
              margin-top: 10px;
              margin-left: 15px;
              font-weight: bold;
              font-size: 16px;
              color: red;
            "
          ></div>
          <div
            v-for="(wiersz, index) in form.wiersze"
            :key="index"
            :set="(v = $v.form.wiersze.$each[index])"
          >
            <div
              style="
                color: blue;
                font-weight: bold;
                font-size: 1.5vh;
                margin-left: 15px;
                margin-top: 10px;
              "
            >
              {{ `POZYCJA NR: ${index + 1} ` }}

              <b-icon-plus-square
                class="ikony"
                font-scale="1.4"
                style="border: 1px black"
                color="black"
                @click="dodajWiersz()"
              >
              </b-icon-plus-square>

              <b-icon-trash
                class="ikony"
                font-scale="1.4"
                color="black"
                @click="usunWiersz(wiersz)"
              >
              </b-icon-trash>
            </div>

            <div class="md-layout md-gutter">
              <div class="md-layout-item md-small-size-100">
                <md-autocomplete :class="getValidationClass('Nazwa_wybor_raportu', index)" 
                  v-model="wiersz.Nazwa_wybor_raportu"
                  :md-options="zapytaniaWartosc1"
                  @input="pobierzDaneDlaWiersza(wiersz)"
                >
                  <label>Wybór WYROBU/USŁUGI</label>
                  <span class="md-error" v-if="!v.Nazwa_wybor_raportu.required"
                    >Pole jest wymagane</span
                  >
                </md-autocomplete>
              </div>
            </div>

            <div class="md-layout md-gutter">
              <div class="md-layout-item md-small-size-100">
                <md-field :class="getValidationClass('Id_jednostka', index)">
                  <label for="Id_jednostka">Jedn.</label>

                  <md-select
                    v-model="wiersz.Id_jednostka"
                    :disabled="true"
                    name="Id_jednostka"
                    id="Id_jednostka"
                    autocomplete="Id_jednostka"
                    md-dense
                  >
                    <md-option
                      v-for="(jedn_sprzedazy, index) in Jednostki_sprzedazy"
                      :key="index"
                      :value="jedn_sprzedazy.Id"
                      >{{ jedn_sprzedazy.Jedn_sprzedazy }}
                    </md-option>
                  </md-select>
                  <span class="md-error" v-if="!v.Id_jednostka.required"
                    >Pole jest wymagane</span
                  >
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100">
                <md-field :class="getValidationClass('Ilosc', index)">
                  <label for="Ilosc">Ilość</label>
                  <md-input
                    type="number"
                    style="text-align: right"
                    name="Ilosc"
                    id="Ilosc"
                    autocomplete="Ilosc"
                    v-model="wiersz.Ilosc"
                    :disabled="sending"
                    :min="0"
                    :max="wiersz.Ilosc_dozwolona"
                    @input="ustawWartoscWyrobuPozostalego(wiersz)"
                  />
                  <span class="md-error" v-if="!v.Ilosc.required"
                    >Pole jest wymagane</span
                  >
                  <span class="md-error" v-if="!v.Ilosc.maxValue"
                    >Podano za dużą wartość</span
                  >
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100">
                <md-field :class="getValidationClass('Ilosc_pozostala', index)">
                  <label for="Ilosc_pozostala">Ilość pozostała</label>
                  <md-input
                    style="text-align: right"
                    name="Ilosc_pozostala"
                    id="Ilosc_pozostala"
                    autocomplete="Ilosc_pozostala"
                    v-model="wiersz.Ilosc_pozostala"
                    :disabled="sending"
                    readonly
                  />
                </md-field>
              </div>
            </div>
          </div>
        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

        <div style="text-align: center">
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            czyJestWTrybieEdycji
              ? "ZATWIERDŹ - EDYTOWANY WPIS"
              : "ZATWIERDŹ - UTWÓRZ WPIS"
          }}</md-button>
        </div>
      </md-card>
    </form>
  </div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import minLength from "vuelidate/lib/validators/minLength";
import { api } from "../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_faktury",

  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      Nazwa_wybor_zamowienia: null,
      Numer_WZ: null,
      Data_WZ: null,
      Osoba_odpowiedzialna: null,

      wiersze: [
        {
          Nazwa_wybor_raportu: null,
          Id_jednostka: null,
          Ilosc: 0,
          Ilosc_zwalniana: 0,
          Ilosc_pozostala: 0,
          Ilosc_dozwolona: 0,
        },
      ],
    },

    IdEdytowanegoElementu: null,
    userSaved: false,
    sending: false,
    lastUser: null,
    Jednostki_sprzedazy: [],
    Zamowienia: [],
    Wyroby: [],
    Raporty: [],
    Obiekt_generujacy_numer_raportu: null,
  }),

  async created() {
    try {
      const [
        odpowiedz_Zamowienia,
        odpowiedz_Jednostki_sprzedazy,
        odpowiedz_Raporty,
      ] = await Promise.all([
        api.pobierzKontrahenta(),
        api.pobierzJednostki_sprzedazy(),
        this.czyJestWTrybieEdycji
          ? api.PobierzRaportyWZEdycja(this.obiektKonfiguracyjny.Id)
          : api.PobierzRaportyWZ(),
      ]);

      odpowiedz_Zamowienia,
        (this.Zamowienia = odpowiedz_Zamowienia.data.dane.map((el) => ({
          ...el,
          nazwa: `NIP: ${el.NIP} ; Kontrahent: ${el.Nazwa_kontrahenta}`,
        })));
      this.Jednostki_sprzedazy = odpowiedz_Jednostki_sprzedazy.data.dane;

      odpowiedz_Raporty,
        (this.Raporty = odpowiedz_Raporty.data.dane.map((el) => ({
          ...el,
          nazwa1: `Numer raportu: ${el.Numer_raportu} - ${el.Nazwa} : maksymalna ilość: ${el.Ilosc_pozostala}`,
        })));
      if (this.obiektKonfiguracyjny.akcja === "edycja") {
        //edycja
        const odpowiedz = await api.pobieranieZamowieniaWZEdycja(
          this.obiektKonfiguracyjny.Id
        );
        const {
          Id,
          Id_kontrahenta,
          Numer_WZ,
          Data_WZ,
          Osoba_odpowiedzialna,
          PozycjeRaportu,
        } = odpowiedz.data.dane[0];
        this.IdEdytowanegoElementu = Id;
        this.form.Nazwa_wybor_zamowienia = this.Zamowienia.find(
          ({ Id }) => Id == Id_kontrahenta
        ).nazwa;
        this.form.Numer_WZ = Numer_WZ;
        this.form.Data_WZ = Data_WZ;
        this.form.Osoba_odpowiedzialna = Osoba_odpowiedzialna;
        if (PozycjeRaportu.length !== 0) {
          this.form.wiersze = PozycjeRaportu.map((wiersz) => {
            const znalezionyElement = this.Raporty.find(
              ({ Id }) => Id === wiersz.Id_raportu
            );
            return {
              ...wiersz,
              Nazwa_wybor_raportu: znalezionyElement.nazwa1,
            };
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  },
  validations: {
    form: {
      Nazwa_wybor_zamowienia: {
        required,
      },
      Numer_WZ: {
        required,
      },
      Data_WZ: {
        required,
      },
      Osoba_odpowiedzialna: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(50),
      },

      wiersze: {
        $each: {
          Nazwa_wybor_raportu: {
            required,
          },
          Ilosc: {
            required,

            maxValue(value, wiersz) {
              return +value <= +wiersz.Ilosc_dozwolona;
            },
          },
          Id_jednostka: {
            required,
          },
        },
      },
    },
  },
  computed: {
    Id_kontrahenta: function() {
      const { Nazwa_wybor_zamowienia } = this.form;
      const znalezionyElement = this.Zamowienia.find(
        ({ nazwa }) => nazwa === Nazwa_wybor_zamowienia
      );

      if (znalezionyElement) {
        return znalezionyElement.Id;
      }
      return null;
    },

    zapytaniaWartosc: function() {
      return this.Zamowienia.map((el) => el.nazwa);
    },

    zapytaniaWartosc1: function() {
      return this.Raporty.map((el) => el.nazwa1);
    },

    plikZwalidowany: function() {
      return this.form.plik_dane
        ? this.form.plik_dane.size < 2 * 1024 * 1024
        : true;
    },
    czyJestWTrybieEdycji: function() {
      return this.obiektKonfiguracyjny.akcja === "edycja";
    },

    numerRaportu: function() {
      if (this.Obiekt_generujacy_numer_raportu) {
        const { numer, miesiac, rok } = this.Obiekt_generujacy_numer_raportu;
        return `RW-${numer}/${miesiac}/${rok}`;
      } else {
        return "";
      }
    },
  },
  methods: {
    zapamietajPlik(pliki) {
      if (pliki[0]) {
        this.form.plik_dane = pliki[0];
      }
    },

    pobierzNumerRaportu() {
      const { Data_WZ } = this.form;
      const data = new Date(Data_WZ);
      const rok = data.getFullYear();
      const miesiac = data.getMonth() + 1;
      if (this.czyJestWTrybieEdycji) {
        return;
      }

      api.PobierzLpWZZamowienia({ rok, miesiac }).then((odpowiedz) => {
        this.Obiekt_generujacy_numer_raportu = odpowiedz.data.dane;
        this.form.Numer_WZ = this.numerRaportu;
      });
    },

    ustawWartoscWyrobuPozostalego(wiersz) {
      const znalezionyElement = this.Raporty.find(
        ({ nazwa1 }) => nazwa1 === wiersz.Nazwa_wybor_raportu
      );
      const Ilosc = parseFloat(wiersz.Ilosc.replace(",", "."));
      const Ilosc_zwalniana = parseFloat(
        znalezionyElement.Ilosc_pozostala.replace(",", ".")
      );
      wiersz.Ilosc_pozostala = parseFloat(Ilosc_zwalniana - Ilosc).toFixed(2);
    },

    pobierzDaneDlaWiersza(wiersz) {
      const znalezionyElement = this.Raporty.find(
        ({ nazwa1 }) => nazwa1 === wiersz.Nazwa_wybor_raportu
      );
      wiersz.Ilosc_dozwolona = znalezionyElement.Ilosc_pozostala;
      wiersz.Id_raportu = znalezionyElement.Id;
      wiersz.Id_jednostka = znalezionyElement.Id_jednostka;
      wiersz.Id_zamowienia = znalezionyElement.Id_zamowienia;
      wiersz.Ilosc_pozostala = znalezionyElement.Ilosc_pozostala;
    },

    dodajWiersz() {
      console.log("robi");
      const nowyWiersz = {};
      Object.keys(this.form.wiersze[0]).forEach((key) => {
        nowyWiersz[key] = null;
      });
      const noweWiersze = [...this.form.wiersze, nowyWiersz];
      this.form.wiersze = noweWiersze;
    },
    usunWiersz(wiersz) {
      if (this.form.wiersze.length <= 1) {
        const nowyWiersz = {};
        Object.keys(this.form.wiersze[0]).forEach((key) => {
          nowyWiersz[key] = null;
        });
        this.form.wiersze = [nowyWiersz];
        return;
      }

      this.form.wiersze = this.form.wiersze.filter((el) => el !== wiersz);
    },

    getValidationClass(fieldName, index) {
      const field =
        this.$v.form[fieldName] || this.$v.form.wiersze.$each[index][fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.Id_kontrahenta = null;
      this.form.Numer_WZ = null;
      this.form.Data_WZ = null;
      this.form.Osoba_odpowiedzialna = null;

      this.form.wiersze = [];
    },

    async zapiszFormularz() {
      this.sending = true;

      if (this.czyJestWTrybieEdycji) {
        const daneDoWyslania = {
          Id_kontrahenta: this.Id_kontrahenta,
          Id_zamowienia: this.Id_zamowienia,
          Numer_WZ: this.form.Numer_WZ,
          Data_WZ: this.form.Data_WZ,
          Osoba_odpowiedzialna: this.form.Osoba_odpowiedzialna,
          PozycjeRaportu: this.form.wiersze.map((wiersz) => {
            const znalezionyElement = this.Raporty.find(
              ({ nazwa1 }) => nazwa1 === wiersz.Nazwa_wybor_raportu
            );
            return {
              ...wiersz,
              Id_raportu: znalezionyElement.Id,
              Id_wyrobu: znalezionyElement.Id_wyrobu,
              Ilosc_zwalniana: znalezionyElement.Ilosc_zwalniana,
            };
          }),
        };

        api
          .zapiszZamowieniaWZUpdate(daneDoWyslania, this.IdEdytowanegoElementu)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const daneDoWyslania = {
          Id_kontrahenta: this.Id_kontrahenta,
          Id_zamowienia: this.Id_zamowienia,
          Numer_WZ: this.form.Numer_WZ,
          Lp: this.Obiekt_generujacy_numer_raportu.numer,
          Data_WZ: this.form.Data_WZ,
          Osoba_odpowiedzialna: this.form.Osoba_odpowiedzialna,
          PozycjeRaportu: this.form.wiersze.map((wiersz) => {
            const znalezionyElement = this.Raporty.find(
              ({ nazwa1 }) => nazwa1 === wiersz.Nazwa_wybor_raportu
            );
            return {
              ...wiersz,
              Id_raportu: znalezionyElement.Id,
              Id_wyrobu: znalezionyElement.Id_wyrobu,
              Ilosc_zwalniana: znalezionyElement.Ilosc_zwalniana,
            };
          }),
        };

        api
          .zapiszZamowieniaWZ(daneDoWyslania)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    naszWalidacjaFormularza() {
      if (this.czyJestWTrybieEdycji) {
        return (
          !this.$v.form.Nazwa_wybor_zamowienia.$invalid &&
          !this.$v.form.Numer_WZ.$invalid &&
          !this.$v.form.Data_WZ.$invalid &&
          !this.$v.form.Osoba_odpowiedzialna.$invalid &&
          !this.$v.form.wiersze.$invalid
        );
      } else {
        return !this.$v.$invalid;
      }
    },
    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>
<style>
.Form_Raport_produk .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.Form_Raport_produk .md-layout .md-gutter {
  min-width: 73vw !important; /* szerokość modala*/
  max-width: 100% !important;
  min-height: 7vh !important; /* wysokość pomiędzy mierszami w modalu*/
  margin-right: 20px !important;
}
.md-layout {
  padding-right: 0px !important;
}
.Form_Raport_produk .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}

.Form_Raport_produk .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  margin: 10px 14px !important;
  padding-top: 35px !important;
}
.Form_Raport_produk .A3 .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 90px !important;
  margin: 10px 10px 10px 10px !important;
  padding-top: 35px !important;
}
.Form_Raport_produk .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}

.Form_Raport_produk {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
  overflow: auto;
}
.Form_Raport_produk .md-primary {
  border: 1px solid !important;

  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}
.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important; /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}
.md-layout .md-gutter {
  min-width: 1100px !important;
}
.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}
.md-list-item-text {
  font-size: 1.7vmin !important;
}
.Form_Raport_produk .md-card-actions .md-button {
  margin-right: 50px !important;
}

.Form_Raport_produk .ikony {
  cursor: pointer;
  margin-right: 5px;
  margin-left: 15px;
}
.md-field.md-has-textarea:not(.md-autogrow):after,
.md-field.md-has-textarea:not(.md-autogrow):before {
  border: none !important;
  border-bottom: 0.5px solid !important;
  height: 90px !important;
  font-size: 12px !important;
}
.md-field.md-has-textarea:not(.md-autogrow) .md-textarea {
  min-height: 40px !important;
  padding: 0 0px !important;
  resize: vertical !important;
}
.md-input {
  width: 100% !important;
}
</style>
