<template>
  <div class="ElementyWyboruFAK">
    <b-container fluid class="p-0">
      <b-row>
        <b-col sm="12" md="2">
          <KafelekWyboruFAK
            sciezkaZdjecia="Zdjecia/o-wykaz_wyrobow_i_uslug.jpg"
            opisZdjecia="FajnaFotka"
            linkDoStrony="wykaz_wyrobow"
            podpisPodKafelkiem="WYROBY/USŁUGI"
          />
        </b-col>
        <b-col sm="12" md="2">
          <KafelekWyboruFAK
            sciezkaZdjecia="Zdjecia/o-procesy.jpg"
            opisZdjecia="FajnaFotka"
            linkDoStrony="tabela_kontrahenci"
            podpisPodKafelkiem="KONTRAHENCI"
          />
        </b-col>
        <b-col sm="12" md="2">
          <KafelekWyboruFAK
            sciezkaZdjecia="Zdjecia/o-zakupy.jpg"
            opisZdjecia="FajnaFotka"
            linkDoStrony="kwalifikowanie_dostawcy"
            podpisPodKafelkiem="ZAKUPY"
          />
        </b-col>
        <b-col sm="12" md="2">
          <KafelekWyboruFAK
            sciezkaZdjecia="Zdjecia/o-projektowanie.jpg"
            opisZdjecia="FajnaFotka"
            linkDoStrony="wykaz_projektow"
            podpisPodKafelkiem="PROJEKTOWANIE"
          />
        </b-col>
        <b-col sm="12" md="2">
          <KafelekWyboruFAK
            sciezkaZdjecia="Zdjecia/o-zapytanie ofertowe.jpg"
            opisZdjecia="FajnaFotka"
            linkDoStrony="zapytania_ofertowe"
            podpisPodKafelkiem="ZAPYTANIA OFERTOWE"
          />
        </b-col>
        <b-col sm="12" md="2">
          <KafelekWyboruFAK
            sciezkaZdjecia="Zdjecia/o-oferty.jpg"
            opisZdjecia="FajnaFotka"
            linkDoStrony="oferty"
            podpisPodKafelkiem="OFERTY"
          />
        </b-col>
        <b-col sm="12" md="2">
          <KafelekWyboruFAK
            sciezkaZdjecia="Zdjecia/o-zamowienia1.jpg"
            opisZdjecia="FajnaFotka"
            linkDoStrony="zamowienia"
            podpisPodKafelkiem="ZAMÓWIENIA"
          />
        </b-col>
        <b-col sm="12" md="2">
          <KafelekWyboruFAK
            sciezkaZdjecia="Zdjecia/o-realizacja1.jpg"
            opisZdjecia="FajnaFotka"
            linkDoStrony="pr1_raport_produkcyjny"
            podpisPodKafelkiem="REALIZACJA DO ZAMÓWIENIA"
          />
        </b-col>
        <b-col sm="12" md="2">
          <KafelekWyboruFAK
            sciezkaZdjecia="Zdjecia/o-realizacja2.jpg"
            opisZdjecia="FajnaFotka"
            linkDoStrony="zlecenie_magazyn"
            podpisPodKafelkiem="ZLECENIE WEWNĘTRZNE NA MAGAZYN"
          />
        </b-col>
        <b-col sm="12" md="2">
          <KafelekWyboruFAK
            sciezkaZdjecia="Zdjecia/o-zamowienia2.jpg"
            opisZdjecia="FajnaFotka"
            linkDoStrony="rp5_raport_produkcyjny_magazyn"
            podpisPodKafelkiem="REALIZACJA NA MAGAZYN"
          />
        </b-col>
        <b-col sm="12" md="2">
          <KafelekWyboruFAK
            sciezkaZdjecia="Zdjecia/o-audity.jpg"
            opisZdjecia="FajnaFotka"
            linkDoStrony="realizacja_WZ_magazyn"
            podpisPodKafelkiem="ROZLICZENIE-WYDANIE Z MAGAZYNU RW"
          />
        </b-col>
        <b-col sm="12" md="2">
          <KafelekWyboruFAK
            sciezkaZdjecia="Zdjecia/o-reklamacje.jpg"
            opisZdjecia="FajnaFotka"
            linkDoStrony="tabela_reklamacje"
            podpisPodKafelkiem="REKLAMACJE WYROBÓW I USŁUG"
          />
        </b-col>

      </b-row>
    </b-container>
  </div>
</template>

<script>
import KafelekWyboruFAK from "./Kafelek_wyboru_FAK.vue";

export default {
  name: "ElementyWyboruFAK",
  components: {
    KafelekWyboruFAK,
  },
};
</script>

<style scoped>
.ElementyWyboruFAK {
  margin-top: 1%;
  display: flex;
  flex-flow: row wrap;
  padding-bottom: 10%;
}
</style>
