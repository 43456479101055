<template >
  <div>
  <TabelaPrzegladyDokumentyORZ />
  </div>
</template>

<script>
import TabelaPrzegladyDokumentyORZ from '../../komponenty/strona-tabela-komponenty/BHP_orz/tabela_dokumentacja_orz_przeglady.vue'


export default {
  name: 'StronaTabelaPrzegladyDokumentyORZ',
  components: {
    TabelaPrzegladyDokumentyORZ,
    
  }

}
</script>

