<template >
  <div>
  <TabelaWykazPracownicyAdresZamieszkania />
  </div>
</template>

<script>
import TabelaWykazPracownicyAdresZamieszkania from '../../komponenty/strona-tabela-komponenty/HR_pracownicy/tabela_adres_zamieszkania_pracownika.vue'


export default {
  name: 'StronaTabelaWykazAdresZamieszkania',
  components: {
    TabelaWykazPracownicyAdresZamieszkania,
    
  }

}
</script>

