<template >
  <div>
  <TabelaZamowienia/>
  </div>
</template>

<script>
import TabelaZamowienia from '../../komponenty/strona-tabela-komponenty/SO_zamowienia/tabela_wykaz_zamowienia.vue'


export default {
  name: 'StronaTabelaZamowienia',
  components: {
    TabelaZamowienia,
    
  }

}
</script>

