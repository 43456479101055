<template>
  <div class="tabela_oferty_przyjete">
    <div class="laczacy">
      <div class="menu_strony">
        <button class="button" @click="$router.push(`../oferty`)">
          WYKAZ WSZYSTKICH OFERT
        </button>

        <button class="button" @click="$router.push(`../oferty_odrzucone`)">
          WYKAZ OFERT ODRZUCONYCH
        </button>
      </div>

      <div class="menu_strony1">
        <b>
          <b-icon-printer-fill
            class="ikony"
            font-scale="2.1"
            color="white"
            @click="generujPdf"
          >
          </b-icon-printer-fill>
          <md-tooltip class="podpowiedz" md-direction="top"
            >GENERUJ PDF / DRUKUJ</md-tooltip
          >
        </b>

        <b>
          <b-icon-info-circle-fill
            class="ikony"
            font-scale="2.1"
            color="white"
            @click="pokazPDF_INFO"
          >
          </b-icon-info-circle-fill>
          <md-tooltip class="podpowiedz" md-direction="top"
            >GENERUJ PDF / DRUKUJ</md-tooltip
          >
        </b>
      </div>
    </div>

    <modalSO
      v-if="pokazDialog"
      :rodzajFormularza="typAktywnegoFormularza"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />

    <div>
      <DataTable
        :value="searched"
        :expandedRows.sync="expandedRows"
        dataKey="Id"
        @row-expand="onRowExpand"
        @row-collapse="onRowCollapse"
        :selection.sync="wybranyElementTabeli"
        selectionMode="single"
        :metaKeySelection="false"
      >
        <template #header>
          <div>
            <h4>WYKAZ WSZYSTKICH OFERT PRZYJĘTYCH DO REALIZACJI</h4>
            <md-input
              placeholder="Szukaj..."
              v-model="search"
              @input="searchOnTable"
            />
            <div class="PasekWyboru" v-if="wybranyElementTabeli">
              <div
                style="text-align: right; margin-top: 0.3vh; margin-bottom: 0.3vh"
              >
                <b>
                  <b-button
                    :disabled="!autoryzacja.czyMozeUsuwac"
                    @click="usuwanieElementu(wybranyElementTabeli.Id)"
                  >
                    <b-icon-trash class="ikony" font-scale="1.8" color="white">
                    </b-icon-trash
                  ></b-button>
                  <md-tooltip class="podpowiedz" md-direction="top">{{
                    autoryzacja.czyMozeUsuwac
                      ? "USUWANIE ELEMENTU"
                      : "BRAK UPRAWNIEŃ"
                  }}</md-tooltip>
                </b>
                <b>
                  <b-button
                    :disabled="!autoryzacja.czyMozeEdytowac"
                    @click="
                      pokazDialog = true;
                      typAktywnegoFormularza = 'formularz_dodawanie_oferty';
                      obiektKonfiguracyjny = {
                        Id: wybranyElementTabeli.Id,
                        akcja: 'edycja',
                      };
                    "
                  >
                    <b-icon-pencil-square
                      class="ikony"
                      font-scale="1.8"
                      padding="10px"
                      color="white"
                    >
                    </b-icon-pencil-square>
                  </b-button>
                  <md-tooltip class="podpowiedz" md-direction="top">{{
                    autoryzacja.czyMozeUsuwac
                      ? "EDYCJA ELEMENTU"
                      : "BRAK UPRAWNIEŃ"
                  }}</md-tooltip>
                </b>

                 <b><b-button>
                <b-icon-printer-fill
                  class="ikony"
                  font-scale="1.8"
                  color="white"
                  @click="generujPdf1(wybranyElementTabeli.Id)"
                >
                </b-icon-printer-fill></b-button>
                <md-tooltip class="podpowiedz" md-direction="top"
                  >GENERUJ PDF / DRUKUJ</md-tooltip
                >
              </b>

                <b
                  ><b-button>
                    <b-icon-file-earmark-arrow-down
                      class="ikony"
                      font-scale="1.8"
                      color="white"
                      @click="pokazPDF(wybranyElementTabeli.Id)"
                    >
                    </b-icon-file-earmark-arrow-down
                  ></b-button>
                  <md-tooltip class="podpowiedz" md-direction="top"
                    >PODGLĄD PLIKU</md-tooltip
                  >
                </b>
              </div>
            </div>
          </div>
        </template>

        <Column :expander="true" headerStyle="width: 3rem" />

      <Column
          field="Numer_oferty"
          headerStyle="width:15%; font-size:1.1vh; text-align:left"
          header="Numer oferty"
          sortable
        ></Column>
        <Column
          field="Data_oferty"
          headerStyle="width:15%; font-size:1.1vh; text-align:left"
          header="Data oferty"
          sortable
        >
        </Column>
        <Column
          field="Numer"
          headerStyle="width:15%; font-size:1.1vh; text-align:left"
          header="Nr zapytania"
          sortable
        >
        </Column>
        <Column
          field="Nazwa_kontrahenta"
          headerStyle="width:22%; font-size:1.1vh; text-align:left"
          header="Kontrahent"
          sortable
        ></Column>
        <Column
          field="Data_waznosci"
          headerStyle="width:15%; font-size:1.1vh; text-align:left"
          header="Data ważności"
          sortable
        >
        </Column>
        <Column
          field="Osoba_odpowiedzialna"
          headerStyle="width:15%; font-size:1.1vh; text-align:left"
          header="Osoba odpow."
          sortable
        >
        </Column>
        <Column
          field="Status_ofert"
          headerStyle="width:15%; font-size:1.1vh; text-align:left"
          header="Status oferty"
          sortable
        >
        </Column>
        <template #expansion="slotProps">
          <div class="orders-subtable">
            <h6 style="color: blue; text-align: left">
              SZCZEGÓŁY OFERTY
            </h6>
            <DataTable
              :value="slotProps.data.Szczegoly"
              style="height: 21vh; overflow: auto; border: 1px solid"
            >
              <Column
                field="Nazwa"
                headerStyle="color:blue; width:20%; font-size:1.1vh; text-align:left"
                header="Nazwa wyrobu/usługi "
                sortable
              ></Column>
              <Column
                field="Jedn_sprzedazy"
                headerStyle="color:blue; width:15%; font-size:1.1vh; text-align:left"
                header="Jednostka"
                sortable
              ></Column>
              <Column
                field="Ilosc"
                headerStyle="color:blue; width:15%; font-size:1.1vh; text-align:left"
                header="Ilość"
                sortable
              >
              </Column>
              <Column
                field="Cena_jednostkowa"
                headerStyle="color:blue; width:15%; font-size:1.1vh; text-align:left"
                header="Cena jedn."
                sortable
              >
              </Column>
              <Column
                field="Wartosc_oferty"
                headerStyle="color:blue; width:15%; font-size:1.1vh; text-align:left"
                header="Wartosc"
                sortable
              >
              </Column>
              <Column
                field="Uwagi"
                headerStyle="color:blue; width:20%; font-size:1.1vh; text-align:left"
                header="Uwagi"
                sortable
              >
              </Column>
            </DataTable>
          </div>
        </template>
      </DataTable>
    </div>
  </div>
</template>

<script>
import modalSO from "../../strona-formularze/modalSO.vue";
import { api } from "./../../../serwisy/api.serwis";
import { authorizationService } from "../../../serwisy/authorization.servis";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { pdfSerwis } from "./../../../serwisy/pdf.serwis";

const toLower = (text) => {
  return text.toString().toLowerCase();
};

const search = (items, term) => {
  if (term) {
    return items.filter((item) => {
      const elementWyszukiwany = toLower(term);
      const Numer_oferty = toLower(item.Numer_oferty);
      const Data_oferty = toLower(item.Data_oferty);
      const Numer = toLower(item.Numer);
      const Nazwa_kontrahenta = toLower(item.Nazwa_kontrahenta);
      const Data_waznosci = toLower(item.Data_waznosci);
      const Osoba_odpowiedzialna = toLower(item.Osoba_odpowiedzialna);
      const Status_ofert = toLower(item.Status_ofert);
      return (
        Numer_oferty.includes(elementWyszukiwany) ||
        Data_oferty.includes(elementWyszukiwany) ||
        Numer.includes(elementWyszukiwany) ||
        Nazwa_kontrahenta.includes(elementWyszukiwany) ||
        Data_waznosci.includes(elementWyszukiwany) ||
        Osoba_odpowiedzialna.includes(elementWyszukiwany) ||
        Status_ofert.includes(elementWyszukiwany) 
      );
    });
  }

  return items;
};

export default {
  name: "tabela_wykaz_procesow",
  data: () => ({
    czyPobranoDane: false,
    search: null,
    searched: [],
    Procesy: [],
    pokazDialog: false,
    typAktywnegoFormularza: null,
    obiektKonfiguracyjny: null,
    wybranyElementTabeli: null,
    expandedRows: [],
    staraWartoscWybranyegolementTabeli: null,
    autoryzacja: {
      czyMozeDodawac: false,
      czyMozeUsuwac: false,
      czyMozeEdytowac: false,
      czyMozePrzegladac: false,
    },
  }),
  components: {
    modalSO,
  },
  mounted: function() {
    this.pobierzDaneDoTabeli();
    this.pobierzDaneAutoryzacyjne();
  },
  computed: {
    //informacja czy jest w trybie edycji
    czyJestWTrybieEdycji() {
      return this.$router.params.Id ? true : false;
    },
  },

  methods: {
    onRowExpand() {
      // this.$toast.add({severity: 'info', summary: 'Product Expanded', detail: event.data.name, life: 3000});
    },
    onRowCollapse() {
      // this.$toast.add({severity: 'success', summary: 'Product Collapsed', detail: event.data.name, life: 3000});
    },
    expandAll() {
      this.expandedRows = this.products.filter((p) => p.id);
      // this.$toast.add({severity: 'success', summary: 'All Rows Expanded', life: 3000});
    },
    collapseAll() {
      this.expandedRows = null;
      //this.$toast.add({severity: 'success', summary: 'All Rows Collapsed', life: 3000});
    },
    formatCurrency(value) {
      return value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    generujPdf1() {
      const { Id } = this.wybranyElementTabeli;

      pdfSerwis.generujOferty(Id);
    },

   generujPdf() {
      const doc = new jsPDF("p", "mm", "a4");

      doc.setFont("Aleo-Regular");

      doc.text("Wykaz ofert przyjętych", 14, 10);
      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "silver",
          fontStyle: "bold",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 8,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            "Lp",
            "Numer wewnętrzny oferty",
            "Data oferty",
            "Numer oferty",
            "Kontrahent",
            "Data ważności",
            "Osoba odpowiedzialna",
            "Status oferty"

          ],
        ],
        body: this.searched.map((Procesy) => {
          return [
            Procesy.Lp1,
            Procesy.Numer,
            Procesy.Data_oferty,
            Procesy.Numer_oferty,
            Procesy.Nazwa_kontrahenta,
            Procesy.Data_waznosci,
            Procesy.Osoba_odpowiedzialna,
            Procesy.Status_ofert,
          ];
          
        }),
      });

      doc.save("wykaz_ofert_przyjetych.pdf");
    },
    pobierzDaneAutoryzacyjne() {
      authorizationService
        .getInfo("StronaTabelaOfertyPrzyjete", 4)

        .then((obiektOdpowiedzi) => {
          const dane = obiektOdpowiedzi.data.dostep;
          this.autoryzacja = {
            czyMozeDodawac: Boolean(
              dane.find(({ Id_operacji }) => Id_operacji == "1")
            ),
            czyMozeUsuwac: Boolean(
              dane.find(({ Id_operacji }) => Id_operacji == "2")
            ),
            czyMozeEdytowac: Boolean(
              dane.find(({ Id_operacji }) => Id_operacji == "3")
            ),
            czyMozePrzegladac: Boolean(
              dane.find(({ Id_operacji }) => Id_operacji == "4")
            ),
          };
        })
        .catch((error) => {
          console.log(error);
        });
    },

    pobierzDaneDoTabeli() {
      const { id } = this.$route.params;

      console.log(this.$route);

      api
        .pobieranieOfertyPrzyjete(id)
        .then((obiektOdpowiedzi) => {
          console.log(obiektOdpowiedzi);
          this.Procesy = obiektOdpowiedzi.data.dane.map((maszyna, index) => ({
            ...maszyna,
            Lp1: index + 1,
          }));
          this.searched = this.Procesy;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.czyPobranoDane = true;
        });
    },
    funkcjaZamykajacaDialog(sukces) {
      this.pokazDialog = false;
      this.typAktywnegoFormularza = null;
      this.obiektKonfiguracyjny = null;
      if (sukces) {
        this.pobierzDaneDoTabeli();
      }
    },
    newUser() {
      window.alert("Noop");
    },
    searchOnTable() {
      this.searched = search(this.Procesy, this.search);
    },
    usuwanieElementu(Id) {
      this.pokazDialog = true;
      this.typAktywnegoFormularza = "usuwanie";
      this.obiektKonfiguracyjny = {
        Id,
        typ: "usuwanieOferty",
      };
    },
    pokazPDF(Id) {
      //dopisane
      this.pokazDialog = true;
      this.typAktywnegoFormularza = "pokaz_PDF";
      this.obiektKonfiguracyjny = {
        Id,
        Klucz: "test_PDF",
      };
    },
    pokazPDF_INFO() {
      //dopisane
      this.pokazDialog = true;
      this.typAktywnegoFormularza = "pokaz_PDF_INFO";
      this.obiektKonfiguracyjny = {
        Nazwa_instrukcji: "INFO-3",
      };
    },
    wyborElementu(element) {
      this.wybranyElementTabeli = element;
    },
  },
};
</script>

<style>
.tabela_oferty_przyjete {
  height: 78vh;
}
.tab-content mt-3 {
  width: 100%;
  height: 10vh !important;
}
.p-datatable-wrapper {
  overflow: auto !important;
  height: 60vh;
}
.tabela_oferty_przyjete .md-card {
  box-shadow: none !important;
}
.tabela_oferty_przyjete .md-title {
  font-weight: 900 !important;
  color: black !important;
  font-size: 1.4vh !important;
  padding: 10px;
}
.tabela_oferty_przyjete .md-toolbar {
  height: 14vh;
}
.tabela_oferty_przyjete .md-table-head {
  font-weight: bold;
  color: black !important;
  font-size: 1.1vh !important;
  text-align: left !important;
}
.tabela_oferty_przyjete .md-table-head-label {
  text-align: left !important;
  font-size: 1.1vh !important;
}
.tabela_oferty_przyjete .md-table-cell {
  font-size: 1.1vh !important;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-align: left;
  color: black;
  font-weight: 300;
  margin-top: 200px !important;
}
.tabela_oferty_przyjete .ikony {
  cursor: pointer;
  margin-right: 40px;
}

.tabela_oferty_przyjete .button {
  margin-right: 5px;
  border: 3px white ridge;
  font-weight: bold;
  font-size: 1.1vmin;
  min-width: 9vw;
}
.tabela_oferty_przyjete .button:hover {
  background-color: #727171;
  text-decoration: none;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  color: white;
}
.tabela_oferty_przyjete .menu_strony {
  border: 1px solid;
  margin-top: 5px;
  padding: 3px;
  background: #939393;
  width: 84.5%;
}
.tabela_oferty_przyjete .menu_strony1 {
  border: 1px solid;
  margin-top: 5px;
  padding: 3px;
  background: #939393;
  width: 15%;
  margin-left: 0.5%;
}
.tabela_oferty_przyjete .laczacy {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.md-table.md-theme-default .md-table-row.md-selected,
.md-table.md-theme-default .md-table-row.md-selected-single {
  background-color: #dddddd !important;
}
.PasekWyboru {
  background: #606060;
  padding: 2px;
}
.tabela_oferty_przyjete .ikony {
  margin-right: 4px;
  margin-left: 4px;
  border: 1px solid !important;
  border-radius: 5px;
  padding: 5px;
}
.tabela_oferty_przyjete .ikony:hover {
  background-color: black;
  text-decoration: none;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
.podpowiedz {
  font-size: 1.5vmin !important;
  font-weight: bold;
  background-color: black !important;
}
.tabela_oferty_przyjete .nav-item {
  font-weight: bold;
  font-size: 1.5vh;
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: silver !important;
  color: black !important;
}
.tabela_oferty_przyjete .md-input {
  width: 20vw !important;
  margin-bottom: 10px;
}
.tabela_oferty_przyjete .p-datatable .p-datatable-tbody > tr > td {
  text-align: left !important;
  font-size: 1.1vh !important;
}
.btn {
  background-color: #606060 !important;
  padding: 0rem 0rem !important;
  border-color: #606060 !important;
}
</style>
