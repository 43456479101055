<template>
  <div>
  <tabelaWykazZakresy/>

  </div>
</template>

<script>
import tabelaWykazZakresy from '../../komponenty/strona-tabela-komponenty/HR_zakresy/tabela_wykaz_zakresow.vue'


export default {
  name: 'StronaTabelaWykazZakresy',
  components: {
       tabelaWykazZakresy,

  }

}
</script>
