<template>
  <md-dialog :md-active="true" @md-clicked-outside="funkcjaZamykajacaDialog">

    <FormularzNIP
      v-if="rodzajFormularza === 'formularz_NIP'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieMaszyny
      v-if="rodzajFormularza === 'formularz_dodawanie_maszyny'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieMaszynyGenerator
      v-if="rodzajFormularza === 'formularz_generuj_przeglad'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawaniePrzegladuMaszyny
      v-else-if="rodzajFormularza === 'formularz_dodawanie_przegladu_maszyny'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieUbezpieczeniaMaszyny
      v-else-if="
        rodzajFormularza === 'formularz_dodawanie_ubezpieczenia_maszyny'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawaniePaliwaMaszyny
      v-else-if="rodzajFormularza === 'formularz_dodawanie_paliwa_maszyny'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieKosztowMaszyny
      v-else-if="rodzajFormularza === 'formularz_dodawanie_kosztow_maszyny'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieMaszynyDozorowe
      v-else-if="rodzajFormularza === 'formularz_dodawanie_maszyny_dozorowe'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />

    <FormularzDodawaniePrzegladuMaszynyDozorowe
      v-else-if="rodzajFormularza === 'formularz_dodawanie_przegladu_maszyny_dozorowe'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />

    <FormularzDodawanieUbezpieczeniaMaszynyDozorowe
      v-else-if="rodzajFormularza === 'formularz_dodawanie_ubezpieczenia_maszyny_dozorowe'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />

    <FormularzDodawaniePaliwaMaszynyDozorowe
      v-else-if="rodzajFormularza === 'formularz_dodawanie_paliwa_maszyny_dozorowe'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />

    <FormularzDodawanieKosztyMaszynyDozorowe
      v-else-if="rodzajFormularza === 'formularz_dodawanie_koszty_maszyny_dozorowe'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />

    <FormularzDodawanieElektronarzedzia
      v-else-if="rodzajFormularza === 'formularz_dodawanie_elektronarzedzia'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieElektronarzedziaGenerator
      v-else-if="rodzajFormularza === 'formularz_generuj_przeglad_elektronarzedzia'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />

    <FormularzDodawaniePrzegladuElektronarzedzia
      v-else-if="rodzajFormularza === 'formularz_dodawanie_przegladu_elektronarzedzia'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />

    <FormularzDodawanieUbezpieczeniaElektronarzedzia
      v-else-if="rodzajFormularza === 'formularz_dodawanie_ubezpieczenia_elektronarzedzia'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />

    <FormularzDodawanieKosztyElektronarzedzia
      v-else-if="rodzajFormularza === 'formularz_dodawanie_koszty_elektronarzedzia'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />

    <FormularzDodawanieIT
      v-else-if="rodzajFormularza === 'formularz_dodawanie_IT'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />

     <FormularzDodawanieITGenerator
      v-else-if="rodzajFormularza === 'formularz_generuj_przeglad_IT'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />

    <FormularzDodawaniePrzegladuIT
      v-else-if="rodzajFormularza === 'formularz_dodawanie_przegladu_IT'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />

    <FormularzDodawanieUbezpieczeniaIT
      v-else-if="rodzajFormularza === 'formularz_dodawanie_ubezpieczenia_IT'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />

    <FormularzDodawanieKosztyIT
      v-else-if="rodzajFormularza === 'formularz_dodawanie_koszty_IT'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />

    <FormularDodawanieBudynku
      v-if="rodzajFormularza === 'formularz_dodawanie_budynku'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />

    <FormularzDodawaniePrzegladuBudynku
      v-if="rodzajFormularza === 'formularz_dodawanie_przegladu_budynku'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />

    <FormularzDodawanieUbezpieczeniaBudynku
      v-if="rodzajFormularza === 'formularz_dodawanie_ubezpieczenia_budynki'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />

    <FormularzDodawanieKosztowBudynku
      v-if="rodzajFormularza === 'formularz_dodawanie_kosztow_budynki'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />

    <FormularzDodawanieMediowBudynku
      v-if="rodzajFormularza === 'formularz_dodawanie_mediow_budynki'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />

    <FormularzDodawaniePomiarowki
      v-if="rodzajFormularza === 'formularz_dodawanie_pomiarowka'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawaniePrzegladuPomiarowka
      v-if="rodzajFormularza === 'formularz_dodawanie_przegladu_pomiarowka'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieUbezpieczeniaPomiarowka
      v-if="rodzajFormularza === 'formularz_dodawanie_ubezpieczenia_pomiarowka'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieKosztyPomiarowka
      v-if="rodzajFormularza === 'formularz_dodawanie_koszty_pomiarowka'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />

    <FormularzDodawanieSpawdzeniaPomiarowka
      v-if="rodzajFormularza === 'formularz_dodawanie_sprawdzenia_pomiarowka'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />



    <FormularzDodawanieSamochodu
      v-if="rodzajFormularza === 'formularz_dodawanie_samochodu'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawaniePrzegladuSamochodu
      v-else-if="rodzajFormularza === 'formularz_dodawanie_przegladu_samochodu'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieUbezpieczeniaSamochodu
      v-else-if="
        rodzajFormularza === 'formularz_dodawanie_ubezpieczenia_samochodu'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawaniePaliwaSamochodu
      v-else-if="rodzajFormularza === 'formularz_dodawanie_paliwa_samochodu'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieKosztowSamochodu
      v-else-if="rodzajFormularza === 'formularz_dodawanie_kosztow_samochodu'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />

    <FormularzDodawanieSamochoduC
      v-if="rodzajFormularza === 'formularz_dodawanie_samochoduC'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawaniePrzegladuSamochoduC
      v-else-if="rodzajFormularza === 'formularz_dodawanie_przegladu_samochoduC'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieUbezpieczeniaSamochoduC
      v-else-if="
        rodzajFormularza === 'formularz_dodawanie_ubezpieczenia_samochoduC'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawaniePaliwaSamochoduC
      v-else-if="rodzajFormularza === 'formularz_dodawanie_paliwa_samochoduC'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieKosztowSamochoduC
      v-else-if="rodzajFormularza === 'formularz_dodawanie_kosztow_samochoduC'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieTachografuSamochoduC
      v-else-if="rodzajFormularza === 'formularz_dodawanie_tachografu_samochoduC'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieChlodniaSamochoduC
      v-else-if="rodzajFormularza === 'formularz_dodawanie_chlodnie_samochoduC'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieTernografuSamochoduC
      v-else-if="rodzajFormularza === 'formularz_dodawanie_termografu_samochoduC'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />

    <FormularzDodawanieWyposazeniaBHP
      v-else-if="rodzajFormularza === 'formularz_dodawanie_wyposazeniaBHP'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />

    <FormularzDodawanieWyposazeniaPrzegladyBHP
      v-else-if="rodzajFormularza === 'formularz_dodawanie_wyposazeniaPrzegladyBHP'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />

    <FormularzDodawanieWyposazeniaPPOZ
      v-else-if="rodzajFormularza === 'formularz_dodawanie_wyposazeniaPPOZ'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />

    <FormularzDodawanieWyposazeniaPrzegladyPPOZ
      v-else-if="rodzajFormularza === 'formularz_dodawanie_wyposazeniaPrzegladyPPOZ'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />

    <FormularzDodawanieProcesu
      v-else-if="rodzajFormularza === 'formularz_dodawanie_procesu'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
     <FormularzDodawaniePrzegladuProcesu
      v-else-if="rodzajFormularza === 'formularz_dodawanie_przegladu_procesu'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieDanychWejsciowychProcesu
      v-else-if="rodzajFormularza === 'formularz_dodawanie_danych_wejsciowych_procesu'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieDanychWyjsciowychProcesu
      v-else-if="rodzajFormularza === 'formularz_dodawanie_danych_wyjsciowych_procesu'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieCelowStrategicznychProcesu
      v-else-if="rodzajFormularza === 'formularz_dodawanie_celow_strategicznych_procesu'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieRozliczeniaCelowStrategicznychProcesu
      v-else-if="rodzajFormularza === 'formularz_dodawanie_rozliczenia_celow_strategicznych_procesu'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieDokumentacji
      v-else-if="rodzajFormularza === 'formularz_dodawanie_dokumentacji'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawaniePrzegladuDokumentacji
      v-else-if="rodzajFormularza === 'formularz_dodawanie_przegladu_dokumentacji'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />

    <FormularzDodawanieInstrukcjiOperacyjnej
      v-else-if="rodzajFormularza === 'formularz_dodawanie_instrukcji_operacyjnej'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawaniePrzegladuInstrukcjiOperacyjnej
      v-else-if="rodzajFormularza === 'formularz_dodawanie_przegladu_instrukcji_operacyjnej'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />

    <FormularzDodawanieInstrukcjiSrodowiskowej
      v-else-if="rodzajFormularza === 'formularz_dodawanie_instrukcji_srodowiskowej'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawaniePrzegladuInstrukcjiSrodowiskowej
      v-else-if="rodzajFormularza === 'formularz_dodawanie_przegladu_instrukcji_srodowiskowej'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />

    <FormularzDodawanieInstrukcjiBhp
      v-else-if="rodzajFormularza === 'formularz_dodawanie_instrukcji_bhp'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawaniePrzegladuInstrukcjiBhp
      v-else-if="rodzajFormularza === 'formularz_dodawanie_przegladu_instrukcji_bhp'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieRyzykSzans
      v-else-if="rodzajFormularza === 'formularz_dodawanie_ryzyk_szans'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawaniePrzegladuRyzykSzans
      v-else-if="rodzajFormularza === 'formularz_dodawanie_przegladu_ryzyk_szans'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieZagrozeniaBhp
      v-else-if="rodzajFormularza === 'formularz_dodawanie_zagrozenia_bhp'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawaniePrzegladuZagrozeniaBhp
      v-else-if="rodzajFormularza === 'formularz_dodawanie_przegladu_zagrozenia_bhp'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieWymaganiaPrawne
      v-else-if="rodzajFormularza === 'formularz_dodawanie_wymagania_prawne'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawaniePrzegladuWymaganiaPrawne
      v-else-if="rodzajFormularza === 'formularz_dodawanie_przegladu_wymagania_prawne'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieRokuCelow
      v-else-if="rodzajFormularza === 'formularz_dodawanie_roku_celow'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawaniePrzegladuCelow
      v-else-if="rodzajFormularza === 'formularz_dodawanie_przegladu_celow'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieCeluSzczegolowego
      v-else-if="rodzajFormularza === 'formularz_dodawanie_celu_szczegolowego'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieZadania
      v-else-if="rodzajFormularza === 'formularz_dodawanie_zadania'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieAspektu
      v-else-if="rodzajFormularza === 'formularz_dodawanie_aspektu'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawaniePrzegladuAspektow
      v-else-if="rodzajFormularza === 'formularz_dodawanie_przegladu_aspektow'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieAuditu
      v-else-if="rodzajFormularza === 'formularz_dodawanie_auditu'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawaniePlanu
      v-else-if="rodzajFormularza === 'formularz_dodawanie_planu'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieRaportu
      v-else-if="rodzajFormularza === 'formularz_dodawanie_raportu'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieKompetencjeAuditor
      v-else-if="rodzajFormularza === 'formularz_dodawanie_kompetencji'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawaniePrzegladuZarzadzania
      v-else-if="rodzajFormularza === 'formularz_dodawanie_przegladu_zarzadzania'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieProtokoluPrzegladZarzadzania
      v-else-if="rodzajFormularza === 'formularz_dodawanie_protokolu_przeglad_zarzadzania'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieDzialaniaAudit
      v-else-if="rodzajFormularza === 'formularz_dodawanie_dzialania_audit'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieDzialaniaAuditOcena
      v-else-if="rodzajFormularza === 'formularz_dodawanie_dzialania_audit_ocena'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieDzialaniaAuditOcenaSkutecznosci
      v-else-if="rodzajFormularza === 'formularz_dodawanie_dzialania_audit_ocena_skutecznosci'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieDzialaniaPozaAudit
      v-else-if="rodzajFormularza === 'formularz_dodawanie_dzialania_poza_audit'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieDzialaniaPozaAuditOcena
      v-else-if="rodzajFormularza === 'formularz_dodawanie_dzialania_poza_audit_ocena'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieDzialaniaPozaAuditOcenaSkutecznosci
      v-else-if="rodzajFormularza === 'formularz_dodawanie_dzialania_poza_audit_ocena_skutecznosci'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieWymaganiaPrawnego
      v-else-if="rodzajFormularza === 'formularz_dodawanie_wymagania_prawnego'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
        <FormularzDodawanieMonitorowaniaAspektu
      v-else-if="rodzajFormularza === 'formularz_dodawanie_monitorowania'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
       <FormularzDodawanieReferencje
      v-else-if="rodzajFormularza === 'formularz_dodawanie_referencji'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieSprawozdawczosciSrodowiskowej
      v-else-if="rodzajFormularza === 'formularz_dodawanie_sprawozdania'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />




    <Usuwanie
      v-else-if="rodzajFormularza === 'usuwanie'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <PokazPDF
      v-else-if="rodzajFormularza === 'pokaz_PDF'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <PokazPDF1
      v-else-if="rodzajFormularza === 'pokaz_PDF_1'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    
    <PokazPDFINFO
      v-else-if="rodzajFormularza === 'pokaz_PDF_INFO'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
  </md-dialog>
</template>

<script>
import FormularzNIP from "./STRONA_STARTOWA/formularz_NIP";

import FormularzDodawanieMaszyny from "./WST_maszyny/formularz_dodawanie_maszyny";
import FormularzDodawanieMaszynyGenerator from "./WST_maszyny/formularz_generuj_przeglad"; 
import FormularzDodawaniePrzegladuMaszyny from "./WST_maszyny/formularz_dodawanie_przegladu_maszyny";
import FormularzDodawanieUbezpieczeniaMaszyny from "./WST_maszyny/formularz_dodawanie_ubezpieczenia_maszyny";
import FormularzDodawaniePaliwaMaszyny from "./WST_maszyny/formularz_dodawanie_paliwa_maszyny";
import FormularzDodawanieKosztowMaszyny from "./WST_maszyny/formularz_dodawanie_kosztow_maszyny";

import FormularzDodawanieMaszynyDozorowe from "./WST_maszyny_dozorowe/formularz_dodawanie_maszyny_dozorowe";
import FormularzDodawaniePrzegladuMaszynyDozorowe from "./WST_maszyny_dozorowe/formularz_dodawanie_przegladu_maszyny_dozorowe";
import FormularzDodawanieUbezpieczeniaMaszynyDozorowe from "./WST_maszyny_dozorowe/formularz_dodawanie_ubezpieczenia_maszyny_dozorowe";
import FormularzDodawaniePaliwaMaszynyDozorowe from "./WST_maszyny_dozorowe/formularz_dodawanie_paliwa_maszyny_dozorowe";
import FormularzDodawanieKosztyMaszynyDozorowe from "./WST_maszyny_dozorowe/formularz_dodawanie_koszty_maszyny_dozorowe";

import FormularzDodawanieElektronarzedzia from "./WST_elektronarzedzia/formularz_dodawanie_elektronarzedzia";
import FormularzDodawanieElektronarzedziaGenerator from "./WST_elektronarzedzia/formularz_generuj_przeglad_elektronarzedzia"; 
import FormularzDodawaniePrzegladuElektronarzedzia from "./WST_elektronarzedzia/formularz_dodawanie_przegladu_elektronarzedzia"
import FormularzDodawanieUbezpieczeniaElektronarzedzia from "./WST_elektronarzedzia/formularz_dodawanie_ubezpieczenia_elektronarzedzia";
import FormularzDodawanieKosztyElektronarzedzia from "./WST_elektronarzedzia/formularz_dodawanie_koszty_elektronarzedzia";

import FormularzDodawanieIT from "./WST_wyposazenie_IT/formularz_dodawanie_IT";
import FormularzDodawaniePrzegladuIT from "./WST_wyposazenie_IT/formularz_dodawanie_przegladu_IT"
import FormularzDodawanieUbezpieczeniaIT from "./WST_wyposazenie_IT/formularz_dodawanie_ubezpieczenia_IT";
import FormularzDodawanieKosztyIT from "./WST_wyposazenie_IT/formularz_dodawanie_koszty_IT";
import FormularzDodawanieITGenerator from "./WST_wyposazenie_IT/formularz_generuj_przeglad_IT";

import FormularDodawanieBudynku from "./WST_budynki/formularz_dodawanie_budynku";
import FormularzDodawaniePrzegladuBudynku from "./WST_budynki/formularz_dodawanie_przegladu_budynku";
import FormularzDodawanieUbezpieczeniaBudynku from "./WST_budynki/formularz_dodawanie_ubezpieczenia_budynki";
import FormularzDodawanieKosztowBudynku from "./WST_budynki/formularz_dodawanie_kosztow_budynki";
import FormularzDodawanieMediowBudynku from "./WST_budynki/formularz_dodawanie_mediow_budynki";

import FormularzDodawaniePomiarowki from "./WST_pomiarowka/formularz_dodawanie_pomiarowka";
import FormularzDodawaniePrzegladuPomiarowka from "./WST_pomiarowka/formularz_dodawanie_przegladu_pomiarowka";
import FormularzDodawanieUbezpieczeniaPomiarowka from "./WST_pomiarowka/formularz_dodawanie_ubezpieczenia_pomiarowka";
import FormularzDodawanieKosztyPomiarowka from "./WST_pomiarowka/formularz_dodawanie_koszty_pomiarowka";

import FormularzDodawanieSpawdzeniaPomiarowka from "./WST_pomiarowka_generator/formularz_dodawanie_sprawdzenia_pomiarowka";

import FormularzDodawanieSamochodu from "./WST_samochody_osobowe/formularz_dodawanie_samochodu";
import FormularzDodawaniePrzegladuSamochodu from "./WST_samochody_osobowe/formularz_dodawanie_przegladu_samochodu";
import FormularzDodawanieUbezpieczeniaSamochodu from "./WST_samochody_osobowe/formularz_dodawanie_ubezpieczenia_samochodu";
import FormularzDodawaniePaliwaSamochodu from "./WST_samochody_osobowe/formularz_dodawanie_paliwa_samochodu";
import FormularzDodawanieKosztowSamochodu from "./WST_samochody_osobowe/formularz_dodawanie_kosztow_samochodu";

import FormularzDodawanieSamochoduC from "./WST_samochody_ciezarowe/formularz_dodawanie_samochoduC";
import FormularzDodawaniePrzegladuSamochoduC from "./WST_samochody_ciezarowe/formularz_dodawanie_przegladu_samochoduC";
import FormularzDodawanieUbezpieczeniaSamochoduC from "./WST_samochody_ciezarowe/formularz_dodawanie_ubezpieczenia_samochoduC";
import FormularzDodawaniePaliwaSamochoduC from "./WST_samochody_ciezarowe/formularz_dodawanie_paliwa_samochoduC";
import FormularzDodawanieKosztowSamochoduC from "./WST_samochody_ciezarowe/formularz_dodawanie_kosztow_samochoduC";
import FormularzDodawanieTachografuSamochoduC from "./WST_samochody_ciezarowe/formularz_dodawanie_tachografu_samochoduC";
import FormularzDodawanieChlodniaSamochoduC from "./WST_samochody_ciezarowe/formularz_dodawanie_chlodnie_samochoduC";
import FormularzDodawanieTernografuSamochoduC from "./WST_samochody_ciezarowe/formularz_dodawanie_termografu_samochoduC"; 

import FormularzDodawanieWyposazeniaBHP from "./WST_wyposazenieBHP/formularz_dodawanie_wyposazeniaBHP";
import FormularzDodawanieWyposazeniaPrzegladyBHP from "./WST_wyposazenieBHP/formularz_dodawanie_wyposazeniaPrzegladyBHP";
import FormularzDodawanieWyposazeniaPPOZ from "./WST_wyposazeniePPOZ/formularz_dodawanie_wyposazeniaPPOZ";
import FormularzDodawanieWyposazeniaPrzegladyPPOZ from "./WST_wyposazeniePPOZ/formularz_dodawanie_wyposazeniaPrzegladyPPOZ";
import FormularzDodawanieProcesu from "./SYS_procesy/formularz_dodawanie_procesu";
import FormularzDodawaniePrzegladuProcesu from "./SYS_procesy/formularz_dodawanie_przegladu_procesu";
import FormularzDodawanieDanychWejsciowychProcesu from "./SYS_procesy/formularz_dodawanie_danych_wejsciowych_procesu"; 
import FormularzDodawanieDanychWyjsciowychProcesu from "./SYS_procesy/formularz_dodawanie_danych_wyjsciowych_procesu"; 
import FormularzDodawanieCelowStrategicznychProcesu from "./SYS_procesy/formularz_dodawanie_celow_strategicznych_procesu";  
import FormularzDodawanieRozliczeniaCelowStrategicznychProcesu from "./SYS_procesy/formularz_dodawanie_rozliczenia_celow_strategicznych_procesu";  

import FormularzDodawanieDokumentacji from "./SYS_dokumentacja/formularz_dodawanie_dokumentacji";
import FormularzDodawaniePrzegladuDokumentacji from "./SYS_dokumentacja/formularz_dodawanie_przegladu_dokumentacji";

import FormularzDodawanieInstrukcjiOperacyjnej from "./SYS_instrukcje_operacyjne/formularz_dodawanie_instrukcji_operacyjnej";
import FormularzDodawaniePrzegladuInstrukcjiOperacyjnej from "./SYS_instrukcje_operacyjne/formularz_dodawanie_przegladu_instrukcji_operacyjnej";

import FormularzDodawanieInstrukcjiSrodowiskowej from "./SYS_instrukcje_srodowiskowe/formularz_dodawanie_instrukcji_srodowiskowej";
import FormularzDodawaniePrzegladuInstrukcjiSrodowiskowej from "./SYS_instrukcje_srodowiskowe/formularz_dodawanie_przegladu_instrukcji_srodowiskowej";

import FormularzDodawanieInstrukcjiBhp from "./SYS_instrukcje_BHP/formularz_dodawanie_instrukcji_bhp";
import FormularzDodawaniePrzegladuInstrukcjiBhp from "./SYS_instrukcje_BHP/formularz_dodawanie_przegladu_instrukcji_bhp";

import FormularzDodawaniePrzegladuRyzykSzans from "./SYS_ryzyka_szanse/formularz_dodawanie_przegladu_ryzyk_szans";
import FormularzDodawanieRyzykSzans from "./SYS_ryzyka_szanse/formularz_dodawanie_ryzyk_szans";

import FormularzDodawaniePrzegladuZagrozeniaBhp from "./SYS_zagrozenia_bhp/formularz_dodawanie_przegladu_zagrozenia_bhp";
import FormularzDodawanieZagrozeniaBhp from "./SYS_zagrozenia_bhp/formularz_dodawanie_zagrozenia_bhp";

import FormularzDodawaniePrzegladuWymaganiaPrawne from "./SYS_wymagania_prawne/formularz_dodawanie_przegladu_wymagania_prawne";
import FormularzDodawanieWymaganiaPrawne from "./SYS_wymagania_prawne/formularz_dodawanie_wymagania_prawne";


import FormularzDodawanieRokuCelow from "./SYS_cele/formularz_dodawanie_roku_celow";
import FormularzDodawaniePrzegladuCelow from "./SYS_cele/formularz_dodawanie_przegladu_celow";
import FormularzDodawanieCeluSzczegolowego from "./SYS_cele/formularz_dodawanie_celu_szczegolowego";
import FormularzDodawanieZadania from "./SYS_cele/formularz_dodawanie_zadania";

import FormularzDodawanieAspektu from "./SYS_aspekty/formularz_dodawanie_aspektu";
import FormularzDodawaniePrzegladuAspektow from "./SYS_aspekty/formularz_dodawanie_przegladu_aspektow";

import FormularzDodawanieAuditu from "./SYS_audity/formularz_dodawanie_auditu";
import FormularzDodawaniePlanu from "./SYS_audity/formularz_dodawanie_planu";
import FormularzDodawanieRaportu from "./SYS_audity/formularz_dodawanie_raportu";
import FormularzDodawanieKompetencjeAuditor from "./SYS_audity/formularz_dodawanie_kompetencji";

import FormularzDodawaniePrzegladuZarzadzania from "./SYS_przeglad_zarzadzania/formularz_dodawanie_przegladu_zarzadzania";
import FormularzDodawanieProtokoluPrzegladZarzadzania from "./SYS_przeglad_zarzadzania/formularz_dodawanie_protokolu_przeglad_zarzadzania";

import FormularzDodawanieDzialaniaAudit from "./SYS_dzialania_audit/formularz_dodawanie_dzialania_audit";
import FormularzDodawanieDzialaniaAuditOcena from "./SYS_dzialania_audit/formularz_dodawanie_dzialania_audit_ocena";
import FormularzDodawanieDzialaniaAuditOcenaSkutecznosci from "./SYS_dzialania_audit/formularz_dodawanie_dzialania_audit_ocena_skutecznosci";

import FormularzDodawanieDzialaniaPozaAudit from "./SYS_dzialania_poza_audit/formularz_dodawanie_dzialania_poza_audit";
import FormularzDodawanieDzialaniaPozaAuditOcena from "./SYS_dzialania_poza_audit/formularz_dodawanie_dzialania_poza_audit_ocena";
import FormularzDodawanieDzialaniaPozaAuditOcenaSkutecznosci from "./SYS_dzialania_poza_audit/formularz_dodawanie_dzialania_poza_audit_ocena_skutecznosci";

import FormularzDodawanieWymaganiaPrawnego from "./SYS_aspekty/formularz_dodawanie_wymagania_prawnego"; 
import FormularzDodawanieMonitorowaniaAspektu from "./SYS_aspekty/formularz_dodawanie_monitorowania";

import FormularzDodawanieReferencje from "./SYS_zadowolenie_klienta/formularz_dodawanie_referencji";

import FormularzDodawanieSprawozdawczosciSrodowiskowej from "./SYS_sprawozdawczosc_srodowiskowa/formularz_dodawanie_sprawozdania";

import Usuwanie from "./formularz_usuwanie";
import PokazPDF from "./formularz_pokaz_pdf";
import PokazPDF1 from "./formularz_pokaz_pdf1";
import PokazPDFINFO from "./formularz_pokaz_pdf_INFO";

export default {
  name: "modal",
  components: {
    FormularzNIP,
    
    FormularzDodawanieMaszyny,
    FormularzDodawanieMaszynyGenerator,
    FormularzDodawaniePrzegladuMaszyny,
    FormularzDodawanieUbezpieczeniaMaszyny,
    FormularzDodawaniePaliwaMaszyny,
    FormularzDodawanieKosztowMaszyny,

    FormularzDodawanieMaszynyDozorowe,
    FormularzDodawaniePrzegladuMaszynyDozorowe,
    FormularzDodawanieUbezpieczeniaMaszynyDozorowe,
    FormularzDodawaniePaliwaMaszynyDozorowe,
    FormularzDodawanieKosztyMaszynyDozorowe,

    FormularzDodawanieElektronarzedzia,
    FormularzDodawanieElektronarzedziaGenerator,
    FormularzDodawaniePrzegladuElektronarzedzia,
    FormularzDodawanieUbezpieczeniaElektronarzedzia,
    FormularzDodawanieKosztyElektronarzedzia,

    FormularzDodawanieIT,
    FormularzDodawanieITGenerator,
    FormularzDodawaniePrzegladuIT,
    FormularzDodawanieUbezpieczeniaIT,
    FormularzDodawanieKosztyIT,

    FormularDodawanieBudynku,
    FormularzDodawaniePrzegladuBudynku,
    FormularzDodawanieUbezpieczeniaBudynku,
    FormularzDodawanieKosztowBudynku,
    FormularzDodawanieMediowBudynku,

    FormularzDodawanieSamochodu,
    FormularzDodawaniePrzegladuSamochodu,
    FormularzDodawanieUbezpieczeniaSamochodu,
    FormularzDodawaniePaliwaSamochodu,
    FormularzDodawanieKosztowSamochodu,

    FormularzDodawanieSamochoduC,
    FormularzDodawaniePrzegladuSamochoduC,
    FormularzDodawanieUbezpieczeniaSamochoduC,
    FormularzDodawaniePaliwaSamochoduC,
    FormularzDodawanieKosztowSamochoduC,
    FormularzDodawanieTachografuSamochoduC,
    FormularzDodawanieChlodniaSamochoduC,
    FormularzDodawanieTernografuSamochoduC,

    FormularzDodawaniePomiarowki,
    FormularzDodawaniePrzegladuPomiarowka,
    FormularzDodawanieUbezpieczeniaPomiarowka,
    FormularzDodawanieKosztyPomiarowka,

    FormularzDodawanieSpawdzeniaPomiarowka,

    FormularzDodawanieWyposazeniaBHP,
    FormularzDodawanieWyposazeniaPrzegladyBHP,
    FormularzDodawanieWyposazeniaPPOZ,
    FormularzDodawanieWyposazeniaPrzegladyPPOZ,
    FormularzDodawanieProcesu,
    FormularzDodawaniePrzegladuProcesu,
    FormularzDodawanieDanychWejsciowychProcesu, 
    FormularzDodawanieDanychWyjsciowychProcesu, 
    FormularzDodawanieCelowStrategicznychProcesu,
    FormularzDodawanieRozliczeniaCelowStrategicznychProcesu,
    FormularzDodawanieDokumentacji,
    FormularzDodawaniePrzegladuDokumentacji,
    FormularzDodawanieInstrukcjiOperacyjnej,
    FormularzDodawaniePrzegladuInstrukcjiOperacyjnej,

    FormularzDodawanieInstrukcjiSrodowiskowej,
    FormularzDodawaniePrzegladuInstrukcjiSrodowiskowej,

    FormularzDodawanieInstrukcjiBhp,
    FormularzDodawaniePrzegladuInstrukcjiBhp,
    FormularzDodawanieRyzykSzans,
    FormularzDodawaniePrzegladuRyzykSzans,
    FormularzDodawanieZagrozeniaBhp,
    FormularzDodawaniePrzegladuZagrozeniaBhp, 
    FormularzDodawanieWymaganiaPrawne,
    FormularzDodawaniePrzegladuWymaganiaPrawne,  

    FormularzDodawanieRokuCelow,
    FormularzDodawaniePrzegladuCelow,
    FormularzDodawanieCeluSzczegolowego,
    FormularzDodawanieZadania,
    FormularzDodawanieAspektu,
    FormularzDodawaniePrzegladuAspektow,
    FormularzDodawanieAuditu, 
    FormularzDodawaniePlanu,
    FormularzDodawanieRaportu,  
    FormularzDodawanieKompetencjeAuditor,
    FormularzDodawaniePrzegladuZarzadzania,
    FormularzDodawanieProtokoluPrzegladZarzadzania,
    FormularzDodawanieDzialaniaAudit,
    FormularzDodawanieDzialaniaAuditOcena,
    FormularzDodawanieDzialaniaAuditOcenaSkutecznosci,
    FormularzDodawanieDzialaniaPozaAudit,
    FormularzDodawanieDzialaniaPozaAuditOcena,
    FormularzDodawanieDzialaniaPozaAuditOcenaSkutecznosci,
    FormularzDodawanieWymaganiaPrawnego,
    FormularzDodawanieMonitorowaniaAspektu,
    FormularzDodawanieReferencje,
    FormularzDodawanieSprawozdawczosciSrodowiskowej,
    Usuwanie,
    PokazPDF,
    PokazPDF1,
    PokazPDFINFO,
  },
  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    rodzajFormularza: {
      type: String,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
};
</script>



