<template >
  <div>
  <TabelaReklamacje/>
  </div>
</template>

<script>
import TabelaReklamacje from '../../komponenty/strona-tabela-komponenty/SO_reklamacje/tabela_reklamacje.vue'


export default {
  name: 'StronaTabelaReklamacje',
  components: {
    TabelaReklamacje,
    
  }

}
</script>

