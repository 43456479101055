<template>
  <div class="tabela_LOG">
    <div class="laczacy">
      <div class="menu_strony">
        <span>
        <button
          class="button"
          @click="
            pokazDialog = true;
            typAktywnegoFormularza = 'formularz_dodawanie_uzytkownika';
            obiektKonfiguracyjny = {
              Id: null,
              akcja: 'dodawanie',
              iloscOfert: Oferty.length,
            };
          "
        >
          DODAWANIE UPRWNIEŃ DLA NOWEGO PRACOWNIKA
        </button>  </span>
      </div>
  
      <div class="menu_strony1">

        <b>
          <b-icon-info-circle-fill
            class="ikony"
            font-scale="2.1"
            color="white"
            @click="pokazPDF_INFO"
          >
          </b-icon-info-circle-fill>
          <md-tooltip class="podpowiedz" md-direction="top"
            >GENERUJ PDF / DRUKUJ</md-tooltip
          >
        </b>
      </div>
    </div>

    <modalHR
      v-if="pokazDialog"
      :rodzajFormularza="typAktywnegoFormularza"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />

    <div>
      <DataTable
        :value="searched"
        :expandedRows.sync="expandedRows"
        dataKey="Id"
        @row-expand="onRowExpand"
        @row-collapse="onRowCollapse"
        :selection.sync="wybranyElementTabeli"
        selectionMode="single"
        :metaKeySelection="false"
      >
        <template #header>
          <div>
            <h4>WYKAZ UPRAWNIEŃ DOSTĘPU</h4>
            <md-input
              placeholder="Szukaj..."
              v-model="search"
              @input="searchOnTable"
            />
            <div class="PasekWyboru" v-if="wybranyElementTabeli">
              <div
                style="text-align: right; margin-top: 0.3vh; margin-bottom: 0.3vh"
              >
                <b>
                  <b-icon-trash
                    class="ikony"
                    font-scale="1.8"
                    color="white"
                    @click="usuwanieElementu(wybranyElementTabeli.Id)"
                  >
                  </b-icon-trash>
                  <md-tooltip class="podpowiedz" md-direction="top"
                    >USUWANIE ELEMENTU</md-tooltip
                  >
                </b>
                <b>
                  <b-icon-pencil-square
                    class="ikony"
                    font-scale="1.8"
                    padding="10px"
                    color="white"
                    @click="
                      pokazDialog = true;
                      typAktywnegoFormularza = 'formularz_dodawanie_uzytkownika';
                      obiektKonfiguracyjny = {
                        Id: wybranyElementTabeli.Id,
                        akcja: 'edycja',
                      };
                    "
                  >
                  </b-icon-pencil-square>
                  <md-tooltip class="podpowiedz" md-direction="top"
                    >EDYCJA ELEMENTU</md-tooltip
                  >
                </b>

              </div>
            </div>
          </div>
        </template>

        <Column :expander="true" headerStyle="width: 5%" />
        <Column
          field="Lp1"
          headerStyle="width:5%; font-size:1.2vh; text-align:left"
          header="Lp"
        ></Column>
        <Column
          field="Nazwisko"
          headerStyle="width:14%; font-size:1.2vh; text-align:left"
          header="Nazwisko"
          sortable
        ></Column>
        <Column
          field="Imie"
          headerStyle="width:14%; font-size:1.2vh; text-align:left"
          header="Imię"
          sortable
        >
        </Column>
        <Column
          field="NIP"
          headerStyle="width:14%; font-size:1.2vh; text-align:left"
          header="Nr NIP"
          sortable
        >
        </Column>
       
        
        <template #expansion="slotProps">
          <div class="orders-subtable">
            <h6 style="color: blue; text-align: left">
              SZCZEGÓŁOWE DANE DOSTĘPU DLA PRACOWNIKA
            </h6>
            <DataTable
              :value="slotProps.data.Szczegoly"
              style="height: 33vh; border: 1px solid; overflow:auto"
            >
              
              
              <Column
                field="Nazwa"
                headerStyle="color:blue; width:15%; font-size:1.1vh; text-align:left"
                header="MODUŁ - CZĘŚĆ APLIKACJI"
                sortable
              >
              </Column>
              <Column
                field="Opis"
                headerStyle="color:blue; width:77%; font-size:1.1vh; text-align:left"
                header="Opis"
                sortable
              >
              </Column>

              <Column
                headerStyle="color:blue; width:8%; font-size:1.1vh; text-align:left"
                header="Opcje"
              >
                <template #body="slotProps">
                  <b>
                    <b-icon-arrow-right-square
                      font-scale="1.8"
                      color="black"
                      cursor="pointer"
                      @click="
                        $router.push(
                          `../tabela_dostepu_szczegoly/${slotProps.data.Id_uzytkownika}/${slotProps.data.Id_typu}`
                        )
                      "
                    >
                    </b-icon-arrow-right-square>
                    <md-tooltip class="podpowiedz" md-direction="top"
                      >MODUŁY</md-tooltip
                    >
                  </b>
                </template>
              </Column>
              
            </DataTable>
          </div>
        </template>
      </DataTable>
    </div>
  </div>
</template>

<script>
import modalHR from "../../strona-formularze/modalHR.vue";
import { api } from "../../../serwisy/api.serwis";

import "jspdf-autotable";

const toLower = (text) => {
  return text.toString().toLowerCase();
};

const search = (items, term) => {
  if (term) {
    return items.filter((item) => {
      const elementWyszukiwany = toLower(term);
      const Nazwisko = toLower(item.Nazwisko);
      const Imie = toLower(item.Imie);
      const NIP = toLower(item.NIP);
     
      return (
        Nazwisko.includes(elementWyszukiwany) ||
        Imie.includes(elementWyszukiwany) ||
        NIP.includes(elementWyszukiwany) 
        
      );
    });
  }

  return items;
};

export default {
  name: "tabela_wykaz_ofert",
  data: () => ({
    czyPobranoDane: false,
    search: null,
    searched: [],
    Oferty: [],
    pokazDialog: false,
    typAktywnegoFormularza: null,
    obiektKonfiguracyjny: null,
    wybranyElementTabeli: null,
    expandedRows: [],
    staraWartoscWybranyegolementTabeli: null,
  }),
  components: {
    modalHR,
  },
  mounted: function() {
    this.pobierzDaneDoTabeli();
  },
  computed: {
    //informacja czy jest w trybie edycji
    czyJestWTrybieEdycji() {
      return this.$router.params.Id ? true : false;
    },
  },

  methods: {
    onRowExpand() {
      // this.$toast.add({severity: 'info', summary: 'Product Expanded', detail: event.data.name, life: 3000});
    },
    onRowCollapse() {
      // this.$toast.add({severity: 'success', summary: 'Product Collapsed', detail: event.data.name, life: 3000});
    },
    expandAll() {
      this.expandedRows = this.products.filter((p) => p.id);
      // this.$toast.add({severity: 'success', summary: 'All Rows Expanded', life: 3000});
    },
    collapseAll() {
      this.expandedRows = null;
      //this.$toast.add({severity: 'success', summary: 'All Rows Collapsed', life: 3000});
    },
    formatCurrency(value) {
      return value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    
    pobierzDaneDoTabeli() {
      const { id } = this.$route.params;

      console.log(this.$route);

      api
        .pobieranieOsobLogowanie(id)
        .then((obiektOdpowiedzi) => {
          console.log(obiektOdpowiedzi);
          this.Oferty = obiektOdpowiedzi.data.dane.map((maszyna, index) => ({
            ...maszyna,
            Lp1: index + 1,
          }));
          this.searched = this.Oferty;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.czyPobranoDane = true;
        });
    },
    funkcjaZamykajacaDialog(sukces) {
      this.pokazDialog = false;
      this.typAktywnegoFormularza = null;
      this.obiektKonfiguracyjny = null;
      if (sukces) {
        this.pobierzDaneDoTabeli();
      }
    },
    newUser() {
      window.alert("Noop");
    },
    searchOnTable() {
      this.searched = search(this.Oferty, this.search);
    },
    usuwanieElementu(Id) {
      this.pokazDialog = true;
      this.typAktywnegoFormularza = "usuwanie";
      this.obiektKonfiguracyjny = {
        Id,
        typ: "usuwanieUzytkownika",
      };
    },

    pokazPDF_INFO() {
      //dopisane
      this.pokazDialog = true;
      this.typAktywnegoFormularza = "pokaz_PDF_INFO";
      this.obiektKonfiguracyjny = {
        Nazwa_instrukcji: 'INFO-3'
      };
    },
    wyborElementu(element) {
      this.wybranyElementTabeli = element;
    },
  },
};
</script>

<style>
.tabela_LOG {
  height: 78vh;
}
.tab-content mt-3 {
  width: 100%;
  height: 10vh !important;
}
.p-datatable-wrapper {
  overflow: auto !important;
  height: 60vh;
}
.tabela_LOG .md-card {
  box-shadow: none !important;
}
.tabela_LOG .md-title {
  font-weight: 900 !important;
  color: black !important;
  font-size: 1.2vh !important;
  padding: 10px;
}
.tabela_LOG .md-toolbar {
  height: 14vh;
}
.tabela_LOG .md-table-head {
  font-weight: bold;
  color: black !important;
  font-size: 1.1vh !important;
  text-align: left !important;
}
.tabela_LOG .md-table-head-label {
  text-align: left !important;
  font-size: 1.1vh !important;
}
.tabela_LOG .md-table-cell {
  font-size: 1.1vh !important;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-align: left;
  color: black;
  font-weight: 300;
  margin-top: 200px !important;
}
.tabela_LOG .ikony {
  cursor: pointer;
  margin-right: 40px;
}

.tabela_LOG .button {
  margin-right: 5px;
  border: 3px white ridge;
  font-weight: bold;
  font-size: 1.1vmin;
  min-width: 9vw;
}
.tabela_LOG .button:hover {
  background-color: #727171;
  text-decoration: none;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  color: white;
}
.tabela_LOG .menu_strony {
  border: 1px solid;
  margin-top: 5px;
  padding: 3px;
  background: #939393;
  width: 84.5%;
}
.tabela_LOG .menu_strony1 {
  border: 1px solid;
  margin-top: 5px;
  padding: 3px;
  background: #939393;
  width: 15%;
  margin-left: 0.5%;
}
.tabela_LOG .laczacy {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  overflow: auto;
}
.md-table.md-theme-default .md-table-row.md-selected,
.md-table.md-theme-default .md-table-row.md-selected-single {
  background-color: #dddddd !important;
}
.PasekWyboru {
  background: #606060;
  padding: 2px;
}
.tabela_LOG .ikony {
  margin-right: 10px;
  border: 1px solid !important;
  border-radius: 5px;
  padding: 5px;
}
.tabela_LOG .ikony:hover {
  background-color: black;
  text-decoration: none;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
.podpowiedz {
  font-size: 1.5vmin !important;
  font-weight: bold;
  background-color: black !important;
}
.tabela_LOG .nav-item {
  font-weight: bold;
  font-size: 1.2vh;
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: silver !important;
  color: black !important;
}
.tabela_LOG .md-input {
  width: 20vw !important;
  margin-bottom: 10px;
}
.tabela_LOG .p-datatable .p-datatable-tbody > tr > td {
  text-align: left !important;
  font-size: 1.2vh !important;
}
</style>
