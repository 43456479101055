<template >
  <div>
  <TabelaRaportyZakonczone/>
  </div>
</template>

<script>
import TabelaRaportyZakonczone from '../../komponenty/strona-tabela-komponenty/SO_realizacja/tabela_wykaz_raportow_zakonczone.vue'


export default {
  name: 'StronaTabelaRaportyZakonczone',
  components: {
    TabelaRaportyZakonczone,
    
  }

}
</script>

