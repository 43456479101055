<template>
  <div>
  <ElementyWyboruBHP />
  </div>
</template>

<script>
import ElementyWyboruBHP from '../komponenty/strona-glowna-bhp/Elementy_wyboru_BHP.vue'


export default {
  name: 'StronaGlownaBHP',
  components: {
    ElementyWyboruBHP

  }
}
</script>
