<template>
  <div class="FORM_BSP">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI DOKUMENTACJI BADANIA ŚRODOWISKA PRACY"
                : "FORMULARZ DODAWANIA DOKUMENTACJI BADANIA ŚRODOWISKA PRACY"
            }}
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M2"
                :class="getValidationClass('Nazwa_dokumentu')"
              >
                <label for="Nazwa_dokumentu">Zakres sprawozdania - badań środowiska pracy</label>
                <md-textarea
                  name="Nazwa_dokumentu"
                  id="Nazwa_dokumentu"
                  
                  md-autogrow
                    md-counter="200"
                  v-model="form.Nazwa_dokumentu"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Nazwa_dokumentu.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Nazwa_dokumentu.maxlength"
                  >Wymagana ilość znaków od 1 do 200</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">

            <div class="md-layout-item md-small-size-100">
              <md-field class="M4" :class="getValidationClass('Id_status')">
                <label for="Id_status">Status badań środowiska pracy</label>

                <md-select
                  v-model="form.Id_status"
                  :disabled="sending"
                  name="Id_status"
                  id="Id_status"
                  autocomplete="Id_status"
                  md-dense
                >
                  <md-option
                    v-for="(status, index) in Statusy"
                    :key="index"
                    :value="status.Id"
                    >{{ status.Status_BSP }}</md-option
                  >
                </md-select>
                <span class="md-error" v-if="!$v.form.Id_status.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>


            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M4"
                :class="getValidationClass('Data_badan')"
              >
                <label for="Data_badan">Data badań</label>
                <md-input
                  type="date"
                  name="Data_badan"
                  id="Data_badan"
                  v-model="form.Data_badan"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Data_badan.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div 
            v-if="form.Id_status !== '3'"
            class="md-layout-item md-small-size-100">
              <md-field
                class="M4"
                :class="getValidationClass('Data_waznosci')"
              >
                <label for="Data_waznosci">Data ważnosci badań</label>
                <md-input
                  type="date"
                  name="Data_waznosci"
                  id="Data_waznosci"
                  v-model="form.Data_waznosci"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Data_waznosci.required"
                  >Pole jest wymagane</span
                >

              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">

            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M4"
                :class="getValidationClass('Nr_sprawozdania')"
              >
                <label for="Nr_sprawozdania">Numer sprawozdania</label>
                <md-input
                  name="Nr_sprawozdania"
                  id="Nr_sprawozdania"
                  
                  md-counter="50"
                  v-model="form.Nr_sprawozdania"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Nr_sprawozdania.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Nr_sprawozdania.maxlength"
                  >Wymagana ilość znaków od 1 do 50</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M4"
                :class="getValidationClass('Osoba_odpowiedzialna')"
              >
                <label for="Osoba_odpowiedzialna">Osoba odpowiedzialna</label>
                <md-input
                  name="Osoba_odpowiedzialna"
                  id="Osoba_odpowiedzialna"
                  
                  md-counter="50"
                  v-model="form.Osoba_odpowiedzialna"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Osoba_odpowiedzialna.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Osoba_odpowiedzialna.maxlength"
                  >Wymagana ilość znaków od 1 do 50</span
                >
              </md-field>
            </div>

            
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                :class="getValidationClass('Firma')"
              >
                <label for="Firma">Laboratorium wykonujące badania</label>
                <md-input

                  name="Firma"
                  id="Firma"
                  
                  v-model="form.Firma"
                  md-counter="100"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Firma.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Firma.maxlength"
                  >Wymagana ilość znaków od 1 do 100</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter"> 
              <div class="md-layout-item md-small-size-100">
              <md-field class="M4" :class="getValidationClass('Id_akredytacja')">
                <label for="Id_akredytacja">Czy laboratorium posiada akredytację</label>

                <md-select
                  v-model="form.Id_akredytacja"
                  :disabled="sending"
                  name="Id_akredytacja"
                  id="Id_akredytacja"
                  autocomplete="Id_akredytacja"
                  md-dense
                >
                  <md-option
                    v-for="(akredytacja, index) in Akredytacje"
                    :key="index"
                    :value="akredytacja.Id"
                    >{{ akredytacja.Akredytacja }}</md-option
                  >
                </md-select>
                <span class="md-error" v-if="!$v.form.Id_akredytacja.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div
            v-if="form.Id_akredytacja !== '2' && form.Id_akredytacja !== null"
              class="md-layout-item md-small-size-100"
            >
              <md-field
                class="M4"
                :class="getValidationClass('Akredytacja')"
              >
                <label for="Akredytacja">Numer akredytacji</label>
                <md-input
                  name="Akredytacja"
                  id="Akredytacja"
                  
                  v-model="form.Akredytacja"
                  md-counter="50"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Akredytacja.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Akredytacja.maxlength"
                  >Wymagana ilość znaków od 1 do 50</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <md-field class="MP3" :class="plikZwalidowany ? '' : 'md-invalid'">
              <label
                >Wstaw plik "max-2Mb"
                <span v-if="nazwaPliku">
                  - obecnie wstawiony plik to {{ nazwaPliku }}</span
                ></label
              >
              <md-file
                v-model="form.plik_PDF"
                @md-change="zapamietajPlik"
                accept="application/pdf"
              />
              <span class="md-error" v-if="!plikZwalidowany"
                >Plik moze miec max 2MB</span
              >
            </md-field>
          </div>
        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

        <div style="text-align: center">
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            czyJestWTrybieEdycji
              ? "ZATWIERDŹ - EDYTOWANY WPIS"
              : "ZATWIERDŹ - UTWÓRZ WPIS"
          }}</md-button>
        </div>
      </md-card>
    </form>
  </div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import minLength from "vuelidate/lib/validators/minLength";
import { api } from "./../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_maszyny",

  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      Nazwa_dokumentu: null,
      Nr_sprawozdania: null,
      Data_badan: null,
      Data_waznosci: '',
      Osoba_odpowiedzialna: null,
      Id_status: null,
      Firma: null,
      Id_akredytacja: null,
      Akredytacja: '',
      plik_PDF: null,
      plik_dane: null,
    },
    IdEdytowanegoElementu: null,
    userSaved: false,
    sending: false,
    nazwaPliku: null,
    lastUser: null,
    Statusy: [],
    Akredytacje: [],
  }),
  created: function () {
    if (this.obiektKonfiguracyjny.akcja === "edycja") {
      //edycja
      api
        .pobieranieDokumentacjaBSPEdycja(this.obiektKonfiguracyjny.Id)
        .then((odpowiedz) => {
          const {
            Id,
            Nazwa_dokumentu,
            Nr_sprawozdania,
            Data_badan,
            Data_waznosci,
            Osoba_odpowiedzialna,
            Id_status,
            Firma,
            Id_akredytacja,
            Akredytacja,
            Nazwa_zalacznika,
          } = odpowiedz.data.dane[0];
          this.IdEdytowanegoElementu = Id;
          this.form.Nazwa_dokumentu = Nazwa_dokumentu;
          this.form.Nr_sprawozdania = Nr_sprawozdania;
          this.form.Data_badan = Data_badan;
          this.form.Data_waznosci = Data_waznosci;
          this.form.Osoba_odpowiedzialna = Osoba_odpowiedzialna;
          this.form.Id_status = Id_status;
          this.form.Firma = Firma;
          this.form.Id_akredytacja = Id_akredytacja;
          this.form.Akredytacja = Akredytacja;
          this.nazwaPliku = Nazwa_zalacznika;
        })
        .catch((error) => {
          console.log(error);
        });
    }



    Promise.all([
    api.pobierzStatusBSP(),
    api.pobierzAkredytacje()])
      .then(([odpowiedz_BSP, odpowiedz_Akredytacja]) => {
        this.Statusy = odpowiedz_BSP.data.dane;
        this.Akredytacje = odpowiedz_Akredytacja.data.dane;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  validations: {
    form: {
      Nazwa_dokumentu: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(200),
      },
      Nr_sprawozdania: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(50),
      },
      Data_badan: {
        required,
      },
      Data_waznosci: {
        required: false,
      },
      Osoba_odpowiedzialna: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(50),
      },
      Id_status: {
        required,
      },
      Firma: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(100),
      },
      Id_akredytacja: {
        required,
      },
      Akredytacja: {
        required: false,
        maxLength: maxLength(50),
      },
    },
  },
  computed: {
    plikZwalidowany: function () {
      return this.form.plik_dane
        ? this.form.plik_dane.size < 2 * 1024 * 1024
        : true;
    },
    czyJestWTrybieEdycji: function () {
      return this.obiektKonfiguracyjny.akcja === "edycja";
    },
  },

  methods: {
    zapamietajPlik(pliki) {
      if (pliki[0]) {
        this.form.plik_dane = pliki[0];
      }
    },
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.Nazwa_dokumentu = null;
      this.form.Nr_sprawozdania = null;
      this.form.Data_badan = null;
      this.form.Data_waznosci = null;
      this.form.Osoba_odpowiedzialna = null;
      this.form.Id_status = null;
      this.form.Id_akredytacja = null;
      this.form.Akredytacja = null;
      this.form.Firma = null;
      this.form.Przeglad_wykonal = null;
      this.form.plik_PDF = null;
      this.form.plik_dane = null;
      this.nazwaPliku = null;
    },

    zapiszFormularz() {
      this.sending = true;

      if (this.czyJestWTrybieEdycji) {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Nazwa_dokumentu", this.form.Nazwa_dokumentu);
        dane.append("Nr_sprawozdania", this.form.Nr_sprawozdania);
        dane.append("Data_badan", this.form.Data_badan);
        dane.append("Data_waznosci", this.form.Data_waznosci);
        dane.append("Osoba_odpowiedzialna", this.form.Osoba_odpowiedzialna);
        dane.append("Id_status", this.form.Id_status);
        dane.append("Firma", this.form.Firma);
        dane.append("Id_akredytacja", this.form.Id_akredytacja);
        dane.append("Akredytacja", this.form.Akredytacja);
        dane.append("plik_PDF", this.form.plik_dane);

        api
          .zapiszDokumentacjaBSPUpdate(dane, this.IdEdytowanegoElementu)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Nazwa_dokumentu", this.form.Nazwa_dokumentu);
        dane.append("Nr_sprawozdania", this.form.Nr_sprawozdania);
        dane.append("Data_badan", this.form.Data_badan);
        dane.append("Data_waznosci", this.form.Data_waznosci);
        dane.append("Osoba_odpowiedzialna", this.form.Osoba_odpowiedzialna);
        dane.append("Id_status", this.form.Id_status);
        dane.append("Firma", this.form.Firma);
        dane.append("Id_akredytacja", this.form.Id_akredytacja);
        dane.append("Akredytacja", this.form.Akredytacja);
        dane.append("plik_PDF", this.form.plik_dane);

        api
          .zapiszDokumentacjaBSP(dane)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    naszWalidacjaFormularza() {
      if (this.czyJestWTrybieEdycji) {
        return (
          !this.$v.form.Nazwa_dokumentu.$invalid &&
          !this.$v.form.Nr_sprawozdania.$invalid &&
          !this.$v.form.Data_badan.$invalid &&
          !this.$v.form.Data_waznosci.$invalid &&
          !this.$v.form.Osoba_odpowiedzialna.$invalid &&
          !this.$v.form.Id_status.$invalid &&
          !this.$v.form.Osoba_odpowiedzialna.$invalid &&
          !this.$v.form.Firma.$invalid &&
          !this.$v.form.Id_akredytacja.$invalid &&
          !this.$v.form.Akredytacja.$invalid &&
          this.plikZwalidowany
        );
      } else {
        return !this.$v.$invalid && this.plikZwalidowany;
      }
    },
    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>


<style>
.FORM_BSP .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
.FORM_BSP .md-layout .md-gutter {
  min-width: 55vw !important; /* szerokość modala*/
  max-width: 100% !important;
  min-height: 7vh; /* wysokość pomiędzy mierszami w modalu*/
}
.md-layout {
  padding-right: 0px !important;
}
.FORM_BSP .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}

.FORM_BSP .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  margin: 10px 24px !important;
  padding-top: 35px !important;
}
.FORM_BSP .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}


.FORM_BSP {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
  overflow: auto;
}
.FORM_BSP .md-primary {
  border: 1px solid !important;

  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}
.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important; /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}

.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}
.md-list-item-text {
  font-size: 1.7vmin !important;
}
.FORM_BSP .md-card-actions .md-button {
  margin-right: 4vw !important;
}
</style>