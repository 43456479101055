<template>
  <div class="Form_PPZ">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI PROTOKOŁU Z PRZEGLĄDU ZARZĄDZANIA"
                : "FORMULARZ DODAWANIA PROTOKOŁU Z PRZEGLĄDU ZARZĄDZANIA"
            }}
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M4" :class="getValidationClass('Id_przegladu')">
                <label for="Id_przegladu">Wybor przeglądu zarządzania</label>

                <md-select
                  v-model="form.Id_przegladu"
                  :disabled="sending || czyJestWTrybieEdycji"
                  name="Id_przegladu"
                  id="Id_przegladu"
                  autocomplete="Id_przegladu"
                  md-dense
                >
                  <md-option
                    v-for="(przeglad, index) in Przeglady"
                    :key="index"
                    :value="przeglad.Id"
                    >{{
                      ` Przegląd zarządzania numer : ${przeglad.Nr_przegladu} z dnia : ${przeglad.Data} `
                    }}</md-option
                  >
                </md-select>
                <span class="md-error" v-if="!$v.form.Id_przegladu.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('A1')">
                <label for="A1"
                  >Informację o stanie realizacji poprzednich ustaleń
                </label>
                <md-textarea
                  name="A1"
                  id="A1"
                  autocomplete="A1"
                  md-autogrow
                  md-counter="1000"
                  v-model="form.A1"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.A1.maxlength">
                  Wymagana ilość znaków od 1 do 1000
                </span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('A2')">
                <label for="A2"
                  >Informację o ewentualnej zmianie czynników zewnętrznych i
                  wewnętrznych
                </label>
                <md-textarea
                  name="A2"
                  id="A2"
                  autocomplete="A2"
                  md-autogrow
                  md-counter="1000"
                  v-model="form.A2"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.A2.maxlength">
                  Wymagana ilość znaków od 1 do 1000
                </span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('A3')">
                <label for="A3"
                  >Informację o stopniu realizacji polityki systemu zarządzania
                  oraz stopnia realizacji celów
                </label>
                <md-textarea
                  name="A3"
                  id="A3"
                  autocomplete="A3"
                  md-autogrow
                  md-counter="1000"
                  v-model="form.A3"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.A3.maxlength">
                  Wymagana ilość znaków od 1 do 1000
                </span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('A4')">
                <label for="A4"
                  >Ocenę działań podjętych w celu uwzględnienia ryzyk i szans
                </label>
                <md-textarea
                  name="A4"
                  id="A4"
                  autocomplete="A4"
                  md-autogrow
                  md-counter="1000"
                  v-model="form.A4"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.A4.maxlength">
                  Wymagana ilość znaków od 1 do 1000
                </span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('A5')">
                <label for="A5"
                  >Wyniki wewnętrznych audytów systemu zarządzania
                </label>
                <md-textarea
                  name="A5"
                  id="A5"
                  autocomplete="A5"
                  md-autogrow
                  md-counter="1000"
                  v-model="form.A5"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.A5.maxlength">
                  Wymagana ilość znaków od 1 do 1000
                </span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('A6')">
                <label for="A6"
                  >Wyniki zewnętrznych audytów systemu zarządzania: wyniki
                  audytów jednostki certyfikującej
                </label>
                <md-textarea
                  name="A6"
                  id="A6"
                  autocomplete="A6"
                  md-autogrow
                  md-counter="1000"
                  v-model="form.A6"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.A6.maxlength">
                  Wymagana ilość znaków od 1 do 1000
                </span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('A7')">
                <label for="A7">Zmiany dotyczących Systemu Zarządzania </label>
                <md-textarea
                  name="A7"
                  id="A7"
                  autocomplete="A7"
                  md-autogrow
                  md-counter="1000"
                  v-model="form.A7"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.A7.maxlength">
                  Wymagana ilość znaków od 1 do 1000
                </span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('A8')">
                <label for="A8"
                  >Informacje o środowiskowych efektach swojej działalności
                </label>
                <md-textarea
                  name="A8"
                  id="A8"
                  autocomplete="A8"
                  md-autogrow
                  md-counter="1000"
                  v-model="form.A8"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.A8.maxlength">
                  Wymagana ilość znaków od 1 do 1000
                </span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('A9')">
                <label for="A9">Adekwatności zasobów </label>
                <md-textarea
                  name="A9"
                  id="A9"
                  autocomplete="A9"
                  md-autogrow
                  md-counter="1000"
                  v-model="form.A9"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.A9.maxlength">
                  Wymagana ilość znaków od 1 do 1000
                </span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('A10')">
                <label for="A10"
                  >Informacja o komunikacji ze stron zainteresowanych</label
                >
                <md-textarea
                  name="A10"
                  id="A10"
                  autocomplete="A10"
                  md-autogrow
                  md-counter="1000"
                  v-model="form.A10"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.A10.maxlength">
                  Wymagana ilość znaków od 1 do 1000
                </span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('A11')">
                <label for="A11"
                  >Istotnych informacji przekazywanych przez strony
                  zainteresowane</label
                >
                <md-textarea
                  name="A11"
                  id="A1"
                  autocomplete="A11"
                  md-autogrow
                  md-counter="1000"
                  v-model="form.A11"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.A11.maxlength">
                  Wymagana ilość znaków od 1 do 1000
                </span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('A12')">
                <label for="A12"
                  >Informacja o szansach ciągłego doskonalenia</label
                >
                <md-textarea
                  name="A12"
                  id="A12"
                  autocomplete="A12"
                  md-autogrow
                  md-counter="1000"
                  v-model="form.A12"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.A12.maxlength">
                  Wymagana ilość znaków od 1 do 1000
                </span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('A13')">
                <label for="A13"
                  >Reklamacje i uwagi klientów, dane dotyczące zadowolenia
                  klienta oraz informacje zwrotne od zewnętrznych stron
                  zainteresowanych</label
                >
                <md-textarea
                  name="A13"
                  id="A13"
                  autocomplete="A13"
                  md-autogrow
                  md-counter="1000"
                  v-model="form.A13"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.A13.maxlength">
                  Wymagana ilość znaków od 1 do 1000
                </span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('A14')">
                <label for="A14"
                  >Dane dotyczące osiągania celów Systemu Zarządzania</label
                >
                <md-textarea
                  name="A14"
                  id="A14"
                  autocomplete="A14"
                  md-autogrow
                  md-counter="1000"
                  v-model="form.A14"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.A14.maxlength">
                  Wymagana ilość znaków od 1 do 1000
                </span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('A15')">
                <label for="A15"
                  >Informację o efektach funkcjonowania procesów i dane
                  dotyczące zgodności</label
                >
                <md-textarea
                  name="A15"
                  id="A15"
                  autocomplete="A15"
                  md-autogrow
                  md-counter="1000"
                  v-model="form.A15"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.A15.maxlength">
                  Wymagana ilość znaków od 1 do 1000
                </span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('A16')">
                <label for="A16"
                  >Informacja o ocenie zgodności z wymaganiami prawnymi i innymi
                  wymaganiami</label
                >
                <md-textarea
                  name="A16"
                  id="A16"
                  autocomplete="A16"
                  md-autogrow
                  md-counter="1000"
                  v-model="form.A16"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.A16.maxlength">
                  Wymagana ilość znaków od 1 do 1000
                </span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('A17')">
                <label for="A17">Wyniki monitorowania i pomiarów</label>
                <md-textarea
                  name="A17"
                  id="A17"
                  autocomplete="A17"
                  md-autogrow
                  md-counter="1000"
                  v-model="form.A17"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.A17.maxlength">
                  Wymagana ilość znaków od 1 do 1000
                </span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('A18')">
                <label for="A18"
                  >Analizy jakości zrealizowanych zleceń oraz trendy incydentów,
                  niezgodności i ciągłego doskonalenia</label
                >
                <md-textarea
                  name="A18"
                  id="A18"
                  autocomplete="A18"
                  md-autogrow
                  md-counter="1000"
                  v-model="form.A18"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.A18.maxlength">
                  Wymagana ilość znaków od 1 do 1000
                </span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('A19')">
                <label for="A19"
                  >Dane dotyczące prowadzenia działań korygujących i działań
                  odnoszących się do ryzyk i szans</label
                >
                <md-textarea
                  name="A19"
                  id="A19"
                  autocomplete="A19"
                  md-autogrow
                  md-counter="1000"
                  v-model="form.A19"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.A19.maxlength">
                  Wymagana ilość znaków od 1 do 1000
                </span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('A20')">
                <label for="A20"
                  >Problemy związane z dostawcami oraz wyniki okresowej oceny
                  dostawców</label
                >
                <md-textarea
                  name="A20"
                  id="A20"
                  autocomplete="A20"
                  md-autogrow
                  md-counter="1000"
                  v-model="form.A20"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.A20.maxlength">
                  Wymagana ilość znaków od 1 do 1000
                </span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('A21')">
                <label for="A21"
                  >Wyniki analiz wypadków, chorób zawodowych i zdarzeń
                  potencjalnie wypadkowych</label
                >
                <md-textarea
                  name="A21"
                  id="A21"
                  autocomplete="A21"
                  md-autogrow
                  md-counter="1000"
                  v-model="form.A21"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.A21.maxlength">
                  Wymagana ilość znaków od 1 do 1000
                </span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('A22')">
                <label for="A22"
                  >Informacja dotycząca współudziału pracowników i
                  konsultacji</label
                >
                <md-textarea
                  name="A22"
                  id="A22"
                  autocomplete="A22"
                  md-autogrow
                  md-counter="1000"
                  v-model="form.A22"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.A22.maxlength">
                  Wymagana ilość znaków od 1 do 1000
                </span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('A23')">
                <label for="A23"
                  >Wyniki działalności BHP uzyskane przez Organizację</label
                >
                <md-textarea
                  name="A23"
                  id="A23"
                  autocomplete="A23"
                  md-autogrow
                  md-counter="1000"
                  v-model="form.A23"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.A23.maxlength">
                  Wymagana ilość znaków od 1 do 1000
                </span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('A24')">
                <label for="A24"
                  >Informacje o realizacji programów szkoleń</label
                >
                <md-textarea
                  name="A24"
                  id="A24"
                  autocomplete="A24"
                  md-autogrow
                  md-counter="1000"
                  v-model="form.A24"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.A24.maxlength">
                  Wymagana ilość znaków od 1 do 1000
                </span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('A25')">
                <label for="A25">Nowe cele Systemu Zarządzania – projekt</label>
                <md-textarea
                  name="A25"
                  id="A25"
                  autocomplete="A25"
                  md-autogrow
                  md-counter="1000"
                  v-model="form.A25"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.A25.maxlength">
                  Wymagana ilość znaków od 1 do 1000
                </span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('A26')">
                <label for="A26"
                  >Informacje dotyczące planowanych zmian, propozycje zmian do
                  polityki Systemu Zarządzania oraz Zintegrowanego Systemu
                  Zarządzania</label
                >
                <md-textarea
                  name="A26"
                  id="A26"
                  autocomplete="A26"
                  md-autogrow
                  md-counter="1000"
                  v-model="form.A26"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.A26.maxlength">
                  Wymagana ilość znaków od 1 do 1000
                </span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('A27')">
                <label for="A27">Dane dotyczące doskonalenia</label>
                <md-textarea
                  name="A27"
                  id="A27"
                  autocomplete="A27"
                  md-autogrow
                  md-counter="1000"
                  v-model="form.A27"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.A27.maxlength">
                  Wymagana ilość znaków od 1 do 1000
                </span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('A28')">
                <label for="A28"
                  >Doskonalenie efektywności Zintegrowanego Systemu Zarządzania
                  oraz jego procesów uwzględniając szanse ciągłego
                  doskonalenia</label
                >
                <md-textarea
                  name="A28"
                  id="A28"
                  autocomplete="A28"
                  md-autogrow
                  md-counter="1000"
                  v-model="form.A28"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.A28.maxlength">
                  Wymagana ilość znaków od 1 do 1000
                </span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('A29')">
                <label for="A29"
                  >Doskonalenie wyrobu/usług związanych z wymaganiami
                  klienta</label
                >
                <md-textarea
                  name="A29"
                  id="A29"
                  autocomplete="A29"
                  md-autogrow
                  md-counter="1000"
                  v-model="form.A29"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.A29.maxlength">
                  Wymagana ilość znaków od 1 do 1000
                </span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('A30')">
                <label for="A30">Potrzebne środki i zasoby</label>
                <md-textarea
                  name="A30"
                  id="A30"
                  autocomplete="A30"
                  md-autogrow
                  md-counter="1000"
                  v-model="form.A30"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.A30.maxlength">
                  Wymagana ilość znaków od 1 do 1000
                </span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('A31')">
                <label for="A31"
                  >Podejmowanie działań, jeżeli są potrzebne</label
                >
                <md-textarea
                  name="A31"
                  id="A31"
                  autocomplete="A31"
                  md-autogrow
                  md-counter="1000"
                  v-model="form.A31"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.A31.maxlength">
                  Wymagana ilość znaków od 1 do 1000
                </span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('A32')">
                <label for="A32"
                  >Wnioski dotyczące stałej przydatności, adekwatności i
                  skuteczności Systemu Zarządzania</label
                >
                <md-textarea
                  name="A32"
                  id="A32"
                  autocomplete="A32"
                  md-autogrow
                  md-counter="1000"
                  v-model="form.A32"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.A32.maxlength">
                  Wymagana ilość znaków od 1 do 1000
                </span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('A33')">
                <label for="A33">Wszelkie zmiany w Systemie Zarządzania</label>
                <md-textarea
                  name="A33"
                  id="A33"
                  autocomplete="A33"
                  md-autogrow
                  md-counter="1000"
                  v-model="form.A33"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.A33.maxlength">
                  Wymagana ilość znaków od 1 do 1000
                </span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('A34')">
                <label for="A34"
                  >Sytuacje, gdy cele Systemu Zarządzania nie zostały
                  osiągnięte</label
                >
                <md-textarea
                  name="A34"
                  id="A34"
                  autocomplete="A34"
                  md-autogrow
                  md-counter="1000"
                  v-model="form.A34"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.A34.maxlength">
                  Wymagana ilość znaków od 1 do 1000
                </span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('A35')">
                <label for="A35"
                  >Możliwość poprawy integracji Systemu Zarządzania z innymi
                  procesami</label
                >
                <md-textarea
                  name="A35"
                  id="A35"
                  autocomplete="A35"
                  md-autogrow
                  md-counter="1000"
                  v-model="form.A35"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.A35.maxlength">
                  Wymagana ilość znaków od 1 do 1000
                </span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('A36')">
                <label for="A36"
                  >Implikacja dla strategicznego kierunku działania
                  Organizacji</label
                >
                <md-textarea
                  name="A36"
                  id="A36"
                  autocomplete="A36"
                  md-autogrow
                  md-counter="1000"
                  v-model="form.A36"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.A36.maxlength">
                  Wymagana ilość znaków od 1 do 1000
                </span>
              </md-field>
            </div>
          </div>
        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

        <div style="text-align: center">
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            czyJestWTrybieEdycji
              ? "ZATWIERDŹ - EDYTOWANY WPIS"
              : "ZATWIERDŹ - UTWÓRZ WPIS"
          }}</md-button>
        </div>
      </md-card>
    </form>
  </div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import { api } from "./../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_maszyny",

  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      Id_przegladu: null,
      A1: null,
      A2: null,
      A3: null,
      A4: null,
      A5: null,
      A6: null,
      A7: null,
      A8: null,
      A9: null,
      A10: null,
      A11: null,
      A12: null,
      A13: null,
      A14: null,
      A15: null,
      A16: null,
      A17: null,
      A18: null,
      A19: null,
      A20: null,
      A21: null,
      A22: null,
      A23: null,
      A24: null,
      A25: null,
      A26: null,
      A27: null,
      A28: null,
      A29: null,
      A30: null,
      A31: null,
      A32: null,
      A33: null,
      A34: null,
      A35: '',
      A36: '',
    },
    IdEdytowanegoElementu: null,
    userSaved: false,
    sending: false,
    lastUser: null,
    Przeglady: [],
  }),
  created: function () {
    if (this.obiektKonfiguracyjny.akcja === "edycja") {
      //edycja
      api
        .pobieranieProtokolPrzegladEdycja(this.obiektKonfiguracyjny.Id)
        .then((odpowiedz) => {
          const {
            Id,
            Id_przegladu,
            A1,
            A2,
            A3,
            A4,
            A5,
            A6,
            A7,
            A8,
            A9,
            A10,
            A11,
            A12,
            A13,
            A14,
            A15,
            A16,
            A17,
            A18,
            A19,
            A20,
            A21,
            A22,
            A23,
            A24,
            A25,
            A26,
            A27,
            A28,
            A29,
            A30,
            A31,
            A32,
            A33,
            A34,
            A35,
            A36,
          } = odpowiedz.data.dane[0];
          this.IdEdytowanegoElementu = Id;
          this.form.Id = Id_przegladu;
          this.form.Id_przegladu = Id_przegladu;
          this.form.A1 = A1;
          this.form.A2 = A2;
          this.form.A3 = A3;
          this.form.A4 = A4;
          this.form.A5 = A5;
          this.form.A6 = A6;
          this.form.A7 = A7;
          this.form.A8 = A8;
          this.form.A9 = A9;
          this.form.A10 = A10;
          this.form.A11 = A11;
          this.form.A12 = A12;
          this.form.A13 = A13;
          this.form.A14 = A14;
          this.form.A15 = A15;
          this.form.A16 = A16;
          this.form.A17 = A17;
          this.form.A18 = A18;
          this.form.A19 = A19;
          this.form.A20 = A20;
          this.form.A21 = A21;
          this.form.A22 = A22;
          this.form.A23 = A23;
          this.form.A24 = A24;
          this.form.A25 = A25;
          this.form.A26 = A26;
          this.form.A27 = A27;
          this.form.A28 = A28;
          this.form.A29 = A29;
          this.form.A30 = A30;
          this.form.A31 = A31;
          this.form.A32 = A32;
          this.form.A33 = A33;
          this.form.A34 = A34;
          this.form.A35 = A35;
          this.form.A36 = A36;
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if(this.czyJestWTrybieEdycji){
      api
      .pobierzPrzegladyWyborEdycja()
      .then((odpowiedz) => {
        this.Przeglady = odpowiedz.data.dane;
      })
      .catch((error) => {
        console.log(error);
      });
    }else{
      api
      .pobierzPrzegladyWybor()
      .then((odpowiedz) => {
        this.Przeglady = odpowiedz.data.dane;
      })
      .catch((error) => {
        console.log(error);
      });
    }
  },
  validations: {
    form: {
      Id_przegladu: {
        required,
      },
      A1: {
        maxLength: maxLength(1000),
      },
      A2: {
        maxLength: maxLength(1000),
      },
      A3: {
        maxLength: maxLength(1000),
      },
      A4: {
        maxLength: maxLength(1000),
      },
      A5: {
        maxLength: maxLength(1000),
      },
      A6: {
        maxLength: maxLength(1000),
      },
      A7: {
        maxLength: maxLength(1000),
      },
      A8: {
        maxLength: maxLength(1000),
      },
      A9: {
        maxLength: maxLength(1000),
      },
      A10: {
        maxLength: maxLength(1000),
      },
      A11: {
        maxLength: maxLength(1000),
      },
      A12: {
        maxLength: maxLength(1000),
      },
      A13: {
        maxLength: maxLength(1000),
      },
      A14: {
        maxLength: maxLength(1000),
      },
      A15: {
        maxLength: maxLength(1000),
      },
      A16: {
        maxLength: maxLength(1000),
      },
      A17: {
        maxLength: maxLength(1000),
      },
      A18: {
        maxLength: maxLength(1000),
      },
      A19: {
        maxLength: maxLength(1000),
      },
      A20: {
        maxLength: maxLength(1000),
      },
      A21: {
        maxLength: maxLength(1000),
      },
      A22: {
        maxLength: maxLength(1000),
      },
      A23: {
        maxLength: maxLength(1000),
      },
      A24: {
        maxLength: maxLength(1000),
      },
      A25: {
        maxLength: maxLength(1000),
      },
      A26: {
        maxLength: maxLength(1000),
      },
      A27: {
        maxLength: maxLength(1000),
      },
      A28: {
        maxLength: maxLength(1000),
      },
      A29: {
        maxLength: maxLength(1000),
      },
      A30: {
        maxLength: maxLength(1000),
      },
      A31: {
        maxLength: maxLength(1000),
      },
      A32: {
        maxLength: maxLength(1000),
      },
      A33: {
        maxLength: maxLength(1000),
      },
      A34: {
        maxLength: maxLength(1000),
      },
      A35: {
        maxLength: maxLength(1000),
      },
      A36: {
        maxLength: maxLength(1000),
      },
    },
  },
  computed: {
    czyJestWTrybieEdycji: function () {
      return this.obiektKonfiguracyjny.akcja === "edycja";
    },
  },

  methods: {
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.Id_przegladu = null;
      this.form.A1 = '';
      this.form.A2 = '';
      this.form.A3 = '';
      this.form.A4 = '';
      this.form.A5 = '';
      this.form.A6 = '';
      this.form.A7 = '';
      this.form.A8 = '';
      this.form.A9 = '';
      this.form.A10 = '';
      this.form.A11 = '';
      this.form.A12 = '';
      this.form.A13 = '';
      this.form.A14 = '';
      this.form.A15 = '';
      this.form.A16 = '';
      this.form.A17 = '';
      this.form.A18 = '';
      this.form.A19 = '';
      this.form.A20 = '';
      this.form.A21 = '';
      this.form.A22 = '';
      this.form.A23 = '';
      this.form.A24 = '';
      this.form.A25 = '';
      this.form.A26 = '';
      this.form.A27 = '';
      this.form.A28 = '';
      this.form.A29 = '';
      this.form.A30 = '';
      this.form.A31 = '';
      this.form.A32 = '';
      this.form.A33 = '';
      this.form.A34 = '';
      this.form.A35 = '';
      this.form.A36 = '';
    },

    zapiszFormularz() {
      this.sending = true;

      if (this.czyJestWTrybieEdycji) {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Id_przegladu", this.form.Id_przegladu);
        dane.append("A1", this.form.A1);
        dane.append("A2", this.form.A2);
        dane.append("A3", this.form.A3);
        dane.append("A4", this.form.A4);
        dane.append("A5", this.form.A5);
        dane.append("A6", this.form.A6);
        dane.append("A7", this.form.A7);
        dane.append("A8", this.form.A8);
        dane.append("A9", this.form.A9);
        dane.append("A10", this.form.A10);
        dane.append("A11", this.form.A11);
        dane.append("A12", this.form.A12);
        dane.append("A13", this.form.A13);
        dane.append("A14", this.form.A14);
        dane.append("A15", this.form.A15);
        dane.append("A16", this.form.A16);
        dane.append("A17", this.form.A17);
        dane.append("A18", this.form.A18);
        dane.append("A19", this.form.A19);
        dane.append("A20", this.form.A20);
        dane.append("A21", this.form.A21);
        dane.append("A22", this.form.A22);
        dane.append("A23", this.form.A23);
        dane.append("A24", this.form.A24);
        dane.append("A25", this.form.A25);
        dane.append("A26", this.form.A26);
        dane.append("A27", this.form.A27);
        dane.append("A28", this.form.A28);
        dane.append("A29", this.form.A29);
        dane.append("A30", this.form.A30);
        dane.append("A31", this.form.A31);
        dane.append("A32", this.form.A32);
        dane.append("A33", this.form.A33);
        dane.append("A34", this.form.A34);
        dane.append("A35", this.form.A35);
        dane.append("A36", this.form.A36);

        api
          .zapiszProtokolPrzegladUpdate(dane, this.IdEdytowanegoElementu)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Id_przegladu", this.form.Id_przegladu);
        dane.append("A1", this.form.A1 || '');
        dane.append("A2", this.form.A2 || '');
        dane.append("A3", this.form.A3 || '');
        dane.append("A4", this.form.A4 || '');
        dane.append("A5", this.form.A5 || '');
        dane.append("A6", this.form.A6 || '');
        dane.append("A7", this.form.A7 || '');
        dane.append("A8", this.form.A8 || '');
        dane.append("A9", this.form.A9 || '');
        dane.append("A10", this.form.A10 || '');
        dane.append("A11", this.form.A11 || '');
        dane.append("A12", this.form.A12 || '');
        dane.append("A13", this.form.A13 || '');
        dane.append("A14", this.form.A14 || '');
        dane.append("A15", this.form.A15 || '');
        dane.append("A16", this.form.A16 || '');
        dane.append("A17", this.form.A17 || '');
        dane.append("A18", this.form.A18 || '');
        dane.append("A19", this.form.A19 || '');
        dane.append("A20", this.form.A20 || '');
        dane.append("A21", this.form.A21 || '');
        dane.append("A22", this.form.A22 || '');
        dane.append("A23", this.form.A23 || '');
        dane.append("A24", this.form.A24 || '');
        dane.append("A25", this.form.A25 || '');
        dane.append("A26", this.form.A26 || '');
        dane.append("A27", this.form.A27 || '');
        dane.append("A28", this.form.A28 || '');
        dane.append("A29", this.form.A29 || '');
        dane.append("A30", this.form.A30 || '');
        dane.append("A31", this.form.A31 || '');
        dane.append("A32", this.form.A32 || '');
        dane.append("A33", this.form.A33 || '');
        dane.append("A34", this.form.A34 || '');
        dane.append("A35", this.form.A35 || '');
        dane.append("A36", this.form.A36 || '');

        api
          .zapiszProtokolPrzeglad(dane)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    naszWalidacjaFormularza() {
      if (this.czyJestWTrybieEdycji) {
        return (
          !this.$v.form.Id_przegladu.$invalid &&
          !this.$v.form.A1.$invalid &&
          !this.$v.form.A2.$invalid &&
          !this.$v.form.A3.$invalid &&
          !this.$v.form.A4.$invalid &&
          !this.$v.form.A5.$invalid &&
          !this.$v.form.A6.$invalid &&
          !this.$v.form.A7.$invalid &&
          !this.$v.form.A8.$invalid &&
          !this.$v.form.A9.$invalid &&
          !this.$v.form.A10.$invalid &&
          !this.$v.form.A11.$invalid &&
          !this.$v.form.A12.$invalid &&
          !this.$v.form.A13.$invalid &&
          !this.$v.form.A14.$invalid &&
          !this.$v.form.A15.$invalid &&
          !this.$v.form.A16.$invalid &&
          !this.$v.form.A17.$invalid &&
          !this.$v.form.A18.$invalid &&
          !this.$v.form.A19.$invalid &&
          !this.$v.form.A20.$invalid &&
          !this.$v.form.A21.$invalid &&
          !this.$v.form.A22.$invalid &&
          !this.$v.form.A23.$invalid &&
          !this.$v.form.A24.$invalid &&
          !this.$v.form.A25.$invalid &&
          !this.$v.form.A26.$invalid &&
          !this.$v.form.A27.$invalid &&
          !this.$v.form.A28.$invalid &&
          !this.$v.form.A29.$invalid &&
          !this.$v.form.A30.$invalid &&
          !this.$v.form.A31.$invalid &&
          !this.$v.form.A32.$invalid &&
          !this.$v.form.A33.$invalid &&
          !this.$v.form.A34.$invalid &&
          !this.$v.form.A35.$invalid &&
          !this.$v.form.A36.$invalid
        );
      } else {
        return !this.$v.$invalid;
      }
    },

    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>


<style>
.Form_PPZ .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
.Form_PPZ {
  overflow: auto;
}
.Form_PPZ .md-layout .md-gutter {
  min-width: 55vw !important; /* szerokość modala*/
  max-width: 100% !important;
  min-height: 7vh; /* wysokość pomiędzy mierszami w modalu*/
}
.md-layout {
  padding-right: 0px !important;
}
.Form_PPZ .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}

.Form_PPZ .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  margin: 10px 24px !important;
  padding-top: 35px !important;
}
.Form_PPZ .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}

.Form_PPZ {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
}
.Form_PPZ .md-primary {
  border: 1px solid !important;

  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}
.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important; /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}

.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}
.md-list-item-text {
  font-size: 1.7vmin !important;
}
.Form_PPZ .md-card-actions .md-button {
  margin-right: 4vw !important;
}
.Form_PPZ .md-field.md-has-textarea:not(.md-autogrow) label {
  top: 16px;
  left: 2px;
}
</style>