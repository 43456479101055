<template >
  <div>
  <TabelaAspektyMonitorowanie />
  </div>
</template>

<script>
import TabelaAspektyMonitorowanie from '../../komponenty/strona-tabela-komponenty/SYS_aspekty/tabela_aspekty_monitorowanie.vue'


export default {
  name: 'StronaTabelaAspektyMonitorowanie',
  components: {
    TabelaAspektyMonitorowanie,
    
  }

}
</script>

