<template>
  <div class="Cala_strona">
    <TabelaPrzegladKontekstu />
    <tabelaLokalizacje />
    <TabelaNormy />
    <TabelaWylaczenia />
    <TabelaProcesyZewnetrzne />
    <TabelaMocneStrony />
    <TabelaSlabeStrony />
    <TabelaWSZ />
    <TabelaZSZ />
  </div>
</template>

<script>
import TabelaPrzegladKontekstu from "../../komponenty/strona-tabela-komponenty/SYS_kontekst/tabela_przeglad_kontekstu.vue";
import TabelaLokalizacje from "../../komponenty/strona-tabela-komponenty/SYS_kontekst/tabela_lokalizacje.vue";
import TabelaNormy from "../../komponenty/strona-tabela-komponenty/SYS_kontekst/tabela_normy.vue";
import TabelaWylaczenia from "../../komponenty/strona-tabela-komponenty/SYS_kontekst/tabela_wylaczenia.vue";
import TabelaProcesyZewnetrzne from "../../komponenty/strona-tabela-komponenty/SYS_kontekst/tabela_procesy_zewnetrzne.vue";
import TabelaMocneStrony from "../../komponenty/strona-tabela-komponenty/SYS_kontekst/tabela_mocne_strony.vue";
import TabelaSlabeStrony from "../../komponenty/strona-tabela-komponenty/SYS_kontekst/tabela_slabe_strony.vue";
import TabelaWSZ from "../../komponenty/strona-tabela-komponenty/SYS_kontekst/tabela_WSZ.vue";
import TabelaZSZ from "../../komponenty/strona-tabela-komponenty/SYS_kontekst/tabela_ZSZ.vue";

export default {
  name: "StronaTabelaPrzegladKontekstu",
  components: {
    TabelaPrzegladKontekstu,
    TabelaLokalizacje,
    TabelaNormy,
    TabelaWylaczenia,
    TabelaProcesyZewnetrzne,
    TabelaMocneStrony,
    TabelaSlabeStrony,
    TabelaWSZ,
    TabelaZSZ,
  },
};
</script>
<style scoped>
.Cala_strona {
  height: 75vh;
  overflow: auto;
}
.a1 {
  display: block;
  float: left;
  height: 67vh;
  overflow: auto;
}
.a2 {
  display: block;
  float: right;
  height: 67vh;
  overflow: auto;
}
.a3 {
  display: block;
  float: left;
  height: 67vh;
  margin-bottom: 4vh;
  overflow: auto;
}
.a4 {
  display: block;
  float: right;
  height: 67vh;
  margin-bottom: 4vh;
  overflow: auto;
}
</style>