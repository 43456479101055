<template>
  <div class="MBHP_ORZ">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI DOKUMENTACJI OCENY RYZYKA ZAWODOWEGO"
                : "FORMULARZ DODAWANIA DOKUMENTACJI OCENY RYZYKA ZAWODOWEGO"
            }}
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M2"
                :class="getValidationClass('Nazwa_dokumentu')"
              >
                <label for="Nazwa_dokumentu">Nazwa dokumentacji Oceny Ryzyka Zawodowego</label>
                <md-input
                  name="Nazwa_dokumentu"
                  id="Nazwa_dokumentu"
                  
                  md-counter="100"
                  v-model="form.Nazwa_dokumentu"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Nazwa_dokumentu.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Nazwa_dokumentu.maxlength"
                  >Wymagana ilość znaków od 1 do 100</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M4"
                :class="getValidationClass('Osoba_odpowiedzialna')"
              >
                <label for="Osoba_odpowiedzialna">Osoba odpowiedzialna</label>
                <md-input
                  name="Osoba_odpowiedzialna"
                  id="Osoba_odpowiedzialna"
                  md-counter="50"
                  
                  v-model="form.Osoba_odpowiedzialna"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Osoba_odpowiedzialna.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Osoba_odpowiedzialna.maxlength"
                  >Wymagana ilość znaków od 1 do 50</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M4" :class="getValidationClass('Id_status')">
                <label for="Id_status">Status dokumentacji ORZ</label>

                <md-select
                  v-model="form.Id_status"
                  :disabled="sending"
                  name="Id_status"
                  id="Id_status"
                  autocomplete="Id_status"
                  md-dense
                >
                  <md-option
                    v-for="(status, index) in Statusy"
                    :key="index"
                    :value="status.Id"
                    >{{ status.Status_ORZ }}</md-option
                  >
                </md-select>
                <span class="md-error" v-if="!$v.form.Id_status.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div
              v-if="form.Id_status !== '2' && form.Id_status !== null"
              class="md-layout-item md-small-size-100"
            >
              <md-field
                class="M4"
                :class="getValidationClass('Data_zatwierdzenia')"
              >
                <label for="Data_zatwierdzenia">Data zatwierdzenia</label>
                <md-input
                  type="date"
                  name="Data_zatwierdzenia"
                  id="Data_zatwierdzenia"
                  
                  v-model="form.Data_zatwierdzenia"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Data_zatwierdzenia.required"
                  >Pole jest wymagane</span
                >
                
              </md-field>
            </div>

            <div
              v-if="form.Id_status !== '1' && form.Id_status !== null"
              class="md-layout-item md-small-size-100"
            >
              <md-field
                class="M4"
                :class="getValidationClass('Data_wycofania')"
              >
                <label for="Data_wycofania">Data wycofania</label>
                <md-input
                  type="date"
                  name="Data_wycofania"
                  id="Data_wycofania"
                  
                  v-model="form.Data_wycofania"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Data_wycofania.required"
                  >Pole jest wymagane</span
                >
               
              </md-field>
            </div>
          
            <div
              v-if="!czyJestWTrybieEdycji && form.Id_status !== '2' && form.Id_status !== null"
              class="md-layout-item md-small-size-100"
            >
              <md-field
                class="M5"
                :class="getValidationClass('Data_nastepnego_przegladu')"
              >
                <label for="Data_nastepnej_weryfikacji"
                  >Data następnego przegladu</label
                >
                <md-input
                  type="date"
                  id="Data_nastepnego_przegladu"
                  name="Data_nastepnego_przegladu"
                  autocomplete="Data_nastepnego_przegladu"
                  v-model="form.Data_nastepnego_przegladu"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Data_nastepnego_przegladu.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

          <div v-if="!czyJestWTrybieEdycji" class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M5"
                :class="getValidationClass('Przeglad_wykonal')"
              >
                <label for="Przeglad_wykonal"
                  >Dokumentację Ocenę Ryzyka Zawodowego zatwierdził / Przegląd wykonał</label
                >
                <md-input
                  name="Przeglad_wykonal"
                  id="Przeglad_wykonal"
                  autocomplete="Przeglad_wykonal"
                  md-counter="100"
                  v-model="form.Przeglad_wykonal"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Przeglad_wykonal.required"
                  >Pole jest wymagane</span
                >
                <span class="md-error" v-else-if="!$v.form.Przeglad_wykonal.maxlength"
                  >Wymagana ilość znaków od 1 do 100</span
                >
              </md-field>
            </div>
          </div>

          <div v-if="!czyJestWTrybieEdycji" class="md-layout md-gutter">
            <md-field class="MP3" :class="plikZwalidowany ? '' : 'md-invalid'">
              <label
                >Wstaw plik "max-2Mb"
                <span v-if="nazwaPliku">
                  - obecnie wstawiony plik to {{ nazwaPliku }}</span
                ></label
              >
              <md-file
                v-model="form.plik_PDF"
                @md-change="zapamietajPlik"
                accept="application/pdf"
              />
              <span class="md-error" v-if="!plikZwalidowany"
                >Plik moze miec max 2MB</span
              >
            </md-field>
          </div>
        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

        <div style="text-align: center">
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            czyJestWTrybieEdycji
              ? "ZATWIERDŹ - EDYTOWANY WPIS"
              : "ZATWIERDŹ - UTWÓRZ WPIS"
          }}</md-button>
        </div>
      </md-card>
    </form>
  </div>
</template>

<script>

import { required, maxLength } from "vuelidate/lib/validators";
import minLength from "vuelidate/lib/validators/minLength";
import { api } from "./../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_maszyny",

  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      Nazwa_dokumentu: null,
      Osoba_odpowiedzialna: null,
      Id_status: null,
      Data_zatwierdzenia: null,
      Data_wycofania: null,
      Data_nastepnego_przegladu: null,
      Przeglad_wykonal: null,
      plik_PDF: null,
      plik_dane: null,
    },
    IdEdytowanegoElementu: null,
    userSaved: false,
    sending: false,
    lastUser: null,
    Statusy: [],
  }),
  created: function () {
    if (this.obiektKonfiguracyjny.akcja === "edycja") {
      //edycja
      api
        .pobieranieDokumentacjaORZEdycja(this.obiektKonfiguracyjny.Id)
        .then((odpowiedz) => {
          const {
            Id,
            Nazwa_dokumentu,
            Osoba_odpowiedzialna,
            Id_status,
            Data_zatwierdzenia,
            Data_wycofania,
            Data_nastepnego_przegladu,
            Przeglad_wykonal,
          } = odpowiedz.data.dane[0];
          this.IdEdytowanegoElementu = Id;
          this.form.Nazwa_dokumentu = Nazwa_dokumentu;
          this.form.Osoba_odpowiedzialna = Osoba_odpowiedzialna;
          this.form.Id_status = Id_status;
          this.form.Data_zatwierdzenia = Data_zatwierdzenia;
          this.form.Data_wycofania = Data_wycofania;
          this.form.Data_nastepnego_przegladu = Data_nastepnego_przegladu;
          this.form.Przeglad_wykonal = Przeglad_wykonal;
        })
        .catch((error) => {
          console.log(error);
        });
    }

    api
      .pobierzStatusORZ()
      .then((odpowiedz) => {
        this.Statusy = odpowiedz.data.dane;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  validations: {
    form: {
      Nazwa_dokumentu: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(100),
      },
      Osoba_odpowiedzialna: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(50),
      },
      Id_status: {
        required,
      },
      Data_zatwierdzenia: {
        required: false,
      },
      Data_wycofania: {
        required: false,
      },
      Data_nastepnego_przegladu: {
        required,
      },
      Przeglad_wykonal: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(100),
      },
    },
  },
  computed: {
    plikZwalidowany: function () {
      return this.form.plik_dane
        ? this.form.plik_dane.size < 2 * 1024 * 1024
        : true;
    },
    czyJestWTrybieEdycji: function () {
      return this.obiektKonfiguracyjny.akcja === "edycja";
    },
  },

  methods: {
    zapamietajPlik(pliki) {
      if (pliki[0]) {
        this.form.plik_dane = pliki[0];
      }
    },
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.Nazwa_dokumentu = null;
      this.form.Osoba_odpowiedzialna = null;
      this.form.Id_status = null;
      this.form.Data_zatwierdzenia = null;
      this.form.Data_wycofania = null;
      this.form.Data_nastepnego_przegladu = null;
      this.form.Przeglad_wykonal = null;
      this.form.plik_PDF = null;
      this.form.plik_dane = null;
    },

    zapiszFormularz() {
      this.sending = true;

      if (this.czyJestWTrybieEdycji) {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Nazwa_dokumentu", this.form.Nazwa_dokumentu);
        dane.append("Osoba_odpowiedzialna", this.form.Osoba_odpowiedzialna);
        dane.append("Id_status", this.form.Id_status);
        dane.append("Data_zatwierdzenia", this.form.Data_zatwierdzenia);
        dane.append("Data_wycofania", this.form.Id_status == 1 ? null : this.form.Data_wycofania);
        //dane.append("Data_nastepnego_przegladu", this.form.Data_nastepnego_przegladu);
        // dane.append("Przeglad_wykonal", this.form.Przeglad_wykonal);
        // dane.append("plik_PDF", this.form.plik_dane);

        api
          .zapiszDokumentacjaORZUpdate(dane, this.IdEdytowanegoElementu)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Nazwa_dokumentu", this.form.Nazwa_dokumentu);
        dane.append("Osoba_odpowiedzialna", this.form.Osoba_odpowiedzialna);
        dane.append("Id_status", this.form.Id_status);
        dane.append("Data_zatwierdzenia", this.form.Data_zatwierdzenia);
        dane.append("Data_wycofania", this.form.Id_status == 1 ? null : this.form.Data_wycofania);
        dane.append(
          "Data_nastepnego_przegladu",
          this.form.Data_nastepnego_przegladu
        );
        dane.append("Przeglad_wykonal", this.form.Przeglad_wykonal);
        dane.append("plik_PDF", this.form.plik_dane);

        api
          .zapiszDokumentacjaORZ(dane)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    naszWalidacjaFormularza() {
      if (this.czyJestWTrybieEdycji) {
        return (
          !this.$v.form.Nazwa_dokumentu.$invalid &&
          !this.$v.form.Osoba_odpowiedzialna.$invalid &&
          !this.$v.form.Id_status.$invalid &&
          !this.$v.form.Data_zatwierdzenia.$invalid &&
          !this.$v.form.Data_wycofania.$invalid
        );
      } else {
        return !this.$v.$invalid && this.plikZwalidowany;
      }
    },
    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>


<style>
.MBHP_ORZ .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
.MBHP_ORZ .md-layout .md-gutter {
  min-width: 55vw !important; /* szerokość modala*/
  max-width: 100% !important;
  min-height: 7vh; /* wysokość pomiędzy mierszami w modalu*/
}
.md-layout {
  padding-right: 0px !important;
}
.MBHP_ORZ .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}

.MBHP_ORZ .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  margin: 10px 24px !important;
  padding-top: 35px !important;
}
.MBHP_ORZ .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}


.MBHP_ORZ {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
  overflow: auto;
}
.MBHP_ORZ .md-primary {
  border: 1px solid !important;

  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}
.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important; /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}

.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}
.md-list-item-text {
  font-size: 1.7vmin !important;
}
.MBHP_ORZ .md-card-actions .md-button {
  margin-right: 4vw !important;
}
</style>