<template>
  <div>
  <ElementyWyboru />
  </div>
</template>

<script>
import ElementyWyboru from '../komponenty/strona-glowna-komponenty/Elementy_wyboru.vue'


export default {
  name: 'StronaGlowna',
  components: {
    ElementyWyboru

  }
}
</script>
