<template>
  <div class="Form_PRACDZAT">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI DANYCH PRACOWNIKA - DANE SZCZEÓŁOWE ZATRUDNIENIA"
                : "FORMULARZ DODAWANIA DANYCH PRACOWNIKA - DANE SZCZEÓŁOWE ZATRUDNIENIA"
            }}
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('Nazwisko')">
                <label for="Nazwisko">Nazwisko</label>
                <md-input
                  name="Nazwisko"
                  id="Nazwisko"
                  autocomplete="Nazwisko"
                  v-model="form.Nazwisko"
                  :disabled="sending"
                  readonly
                />
                <span class="md-error" v-if="!$v.form.Nazwisko.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('Imie1')">
                <label for="Imie1">Imię - pierwsze</label>
                <md-input
                  name="Imie1"
                  id="Imie1"
                  autocomplete="Imie1"
                  v-model="form.Imie1"
                  :disabled="sending"
                  readonly
                />
                <span class="md-error" v-if="!$v.form.Imie1.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('Imie2')">
                <label for="Imie2">Imię - drugie</label>
                <md-input
                  name="Imie2"
                  id="Imie2"
                  autocomplete="Imie2"
                  v-model="form.Imie2"
                  :disabled="sending"
                  readonly
                />
                <span class="md-error" v-if="!$v.form.Imie2.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

           <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('NIP')">
                <label for="NIP">Numer NIP</label>
                <md-input
                  name="NIP"
                  id="NIP"
                  autocomplete="NIP"
                  v-model="form.NIP"
                  :disabled="sending"
                  readonly
                />
                <span class="md-error" v-if="!$v.form.NIP.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('Pesel')">
                <label for="Pesel">Numer PESEL</label>
                <md-input
                  name="Pesel"
                  id="Pesel"
                  autocomplete="Pesel"
                  v-model="form.Pesel"
                  :disabled="sending"
                  readonly 
                />
                <span class="md-error" v-if="!$v.form.Pesel.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

          
          <div  style="margin-top: 20px; margin-left: 22px; font-weight: bold">
            DANE DOTYCZĄCE ZATRUDNIENIA
          </div>
          <div class="md-layout md-gutter">
            <div              
              class="md-layout-item md-small-size-100"
            >
              <md-field
                class="M5"
                :class="getValidationClass('Data_zatrudnienia')"
              >
                <label for="Data_zatrudnienia">Data zatrudnienia</label>
                <md-input
                  type="date"
                  name="Data_zatrudnienia"
                  id="Data_zatrudnienia"
                  autocomplete="Data_zatrudnienia"
                  v-model="form.Data_zatrudnienia"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Data_zatrudnienia.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div
              class="md-layout-item md-small-size-100"
            >
              <md-field
                class="M4"
                :class="getValidationClass('Id_rodzaj_umowy')"
              >
                <label for="Id_rodzaj_umowy">Rodzaj zatrudnienia</label>

                <md-select
                  v-model="form.Id_rodzaj_umowy"
                  :disabled="sending"
                  name="Id_rodzaj_umowy"
                  id="Id_rodzaj_umowy"
                  autocomplete="Id_rodzaj_umowy"
                  md-dense
                >
                  <md-option
                    v-for="(rodzaj_umowy, index) in Rodzaj_umow"
                    :key="index"
                    :value="rodzaj_umowy.Id"
              
                    >{{ ` ${rodzaj_umowy.Rodzaj_zatrudnienia} ` }}</md-option
                  >
                </md-select>
                <span class="md-error" v-if="!$v.form.Id_rodzaj_umowy.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100"
            >
              <md-field
                class="M4"
                :class="getValidationClass('Id_czas_zatrudnienia')"
              >
                <label for="Id_czas_zatrudnienia">Czas zatrudnienia</label>

                <md-select
                  v-model="form.Id_czas_zatrudnienia"
                  :disabled="sending"
                  name="Id_czas_zatrudnienia"
                  id="Id_czas_zatrudnienia"
                  autocomplete="Id_czas_zatrudnienia"
                  md-dense
                >
                  <md-option
                    v-for="(czas, index) in Czasy"
                    :key="index"
                    :value="czas.Id"
            
                    >{{ ` ${czas.Czas_zatrudnienia} ` }}</md-option
                  >
                </md-select>
                <span
                  class="md-error"
                  v-if="!$v.form.Id_czas_zatrudnienia.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div v-if="form.Id_czas_zatrudnienia !== '1'" class="md-layout-item md-small-size-100"
            >
              <md-field class="M5" :class="getValidationClass('Umowa_do')">
                <label for="Umowa_do">Data końca umowy</label>
                <md-input
                  type="date"
                  name="Umowa_do"
                  id="Umowa_do"
                  autocomplete="Umowa_do"
                  v-model="form.Umowa_do"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Umowa_do.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>
        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

        <div style="text-align: center">
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            czyJestWTrybieEdycji
              ? "ZATWIERDŹ - EDYTOWANY WPIS"
              : "ZATWIERDŹ - UTWÓRZ WPIS"
          }}</md-button>
        </div>
      </md-card>
    </form>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { api } from "../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_maszyny",

  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      Nazwisko: null,
      Imie1: null,
      Imie2: null,
      NIP: null,
      Pesel: null,
      Data_zatrudnienia: null,
      Id_rodzaj_umowy: null,
      Id_czas_zatrudnienia: null,
      Umowa_do: null,
    },
    IdEdytowanegoElementu: null,
    userSaved: false,
    sending: false,
    lastUser: null,
    Rodzaj_umow: [],
    Czasy: [],
  }),
  created: function () {
    if (this.obiektKonfiguracyjny.akcja === "edycja") {
      //edycja
      api
        .pobieranieDaneZatrudnieniaEdycja(
          this.obiektKonfiguracyjny.Id
        )
        .then((odpowiedz) => {
          const {
            Id,
            Nazwisko,
            Imie1,
            Imie2,
            NIP,
            Pesel,
            Data_zatrudnienia,
            Id_rodzaj_umowy,
            Id_czas_zatrudnienia,
            Umowa_do,
          } = odpowiedz.data.dane[0];
          this.IdEdytowanegoElementu = Id;
          this.form.Nazwisko = Nazwisko;
          this.form.Imie1 = Imie1;
          this.form.Imie2 = Imie2;
          this.form.NIP = NIP;
          this.form.Data_zatrudnienia = Data_zatrudnienia;
          this.form.Pesel = Pesel;
          this.form.Id_czas_zatrudnienia = Id_czas_zatrudnienia;
          this.form.Id_rodzaj_umowy = Id_rodzaj_umowy;
          this.form.Umowa_do = Umowa_do;

        })
        .catch((error) => {
          console.log(error);
        });
    }

    Promise.all([
      api.pobierzRodzajUmowy(),
      api.pobierzCzasZatrudnienia(),
    ])
      .then(
        ([
          odpowiedz_rodzaj_umowy,
          odpowiedz_czas_zatrudnienia
        ]) => {
          this.Rodzaj_umow = odpowiedz_rodzaj_umowy.data.dane;
          this.Czasy = odpowiedz_czas_zatrudnienia.data.dane;
        }
      )
      .catch((error) => {
        console.log(error);
      });
  },
  validations: {
    form: {
      Nazwisko: {
        required,
      },
      Imie1: {
        required,
      },
      Imie2: {
        required,
      },
      NIP: {
        required,
      },
      Pesel: {
        required,
      },
      Data_zatrudnienia: {
        required,
      },
      Id_rodzaj_umowy: {
        required,
      },
      Id_czas_zatrudnienia: {
        required,
      },
      Umowa_do: {
        required: false
      },
    },
  },
  computed: {
    czyJestWTrybieEdycji: function () {
      return this.obiektKonfiguracyjny.akcja === "edycja";
    },
  },

  methods: {
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.Nazwisko = null;
      this.form.Imie1 = null;
      this.form.Imie2 = null;
      this.form.NIP = null;
      this.form.Pesel = null;
      this.form.Data_zatrudnienia = null;
      this.form.Id_rodzaj_umowy = null;
      this.form.Id_czas_zatrudnienia = null;
      this.form.Umowa_do = null;
    },

    zapiszFormularz() {
      this.sending = true;

      if (this.czyJestWTrybieEdycji) {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Nazwisko", this.form.Nazwisko);
        dane.append("Imie1", this.form.Imie1);
        dane.append("Imie2", this.form.Imie2);
        dane.append("NIP", this.form.NIP);
        dane.append("Pesel", this.form.Pesel);
        dane.append("Data_zatrudnienia", this.form.Data_zatrudnienia);
        dane.append("Id_rodzaj_umowy", this.form.Id_rodzaj_umowy);
        dane.append("Id_czas_zatrudnienia", this.form.Id_czas_zatrudnienia);
        dane.append("Umowa_do", this.form.Umowa_do);

        api
          .zapiszDaneZatrudnieniaUpdate(dane, this.IdEdytowanegoElementu)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Nazwisko", this.form.Nazwisko);
        dane.append("Imie1", this.form.Imie1);
        dane.append("Imie2", this.form.Imie2);
        dane.append("NIP", this.form.NIP);
        dane.append("Pesel", this.form.Pesel);
        dane.append("Data_zatrudnienia", this.form.Data_zatrudnienia);
        dane.append("Id_rodzaj_umowy", this.form.Id_rodzaj_umowy);
        dane.append("Id_czas_zatrudnienia", this.form.Id_czas_zatrudnienia);
        dane.append("Umowa_do", this.form.Umowa_do);

        api
          .zapiszDaneZatrudnienia(dane)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    naszWalidacjaFormularza() {
      if (this.czyJestWTrybieEdycji) {
        return (
          !this.$v.form.Nazwisko.$invalid &&
          !this.$v.form.Imie1.$invalid &&
          !this.$v.form.Imie2.$invalid &&
          !this.$v.form.NIP.$invalid &&
          !this.$v.form.Pesel.$invalid &&
          !this.$v.form.Data_zatrudnienia.$invalid &&
          !this.$v.form.Id_rodzaj_umowy.$invalid &&
          !this.$v.form.Id_czas_zatrudnienia.$invalid &&
          !this.$v.form.Umowa_do.$invalid 

        );
      } else {
        return !this.$v.$invalid;
      }
    },

    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>


<style>
.Form_PRACDZAT .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
.Form_PRACDZAT .md-layout .md-gutter {
  min-width: 55vw !important; /* szerokość modala*/
  max-width: 100% !important;
  min-height: 7vh; /* wysokość pomiędzy mierszami w modalu*/
}
.md-layout {
  padding-right: 0px !important;
}
.Form_PRACDZAT .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}


.Form_PRACDZAT .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  margin: 10px 24px !important;
  padding-top: 35px !important;
}
.Form_PRACDZAT .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}


.Form_PRACDZAT {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
  overflow: auto !important;
}
.Form_PRACDZAT .md-primary {
  border: 1px solid !important;

  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}
.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important; /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}

.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}
.md-list-item-text {
  font-size: 1.7vmin !important;
}
.Form_PRACDZAT .md-card-actions .md-button {
  margin-right: 4vw !important;
}
.Form_PRACDZAT .md-field.md-has-textarea:not(.md-autogrow) label {
  top: 16px;
  left: 2px;
}
</style>