<template>
  <div>
  <TabelaSamochodyCiezaroweKoszty />
  </div>
</template>

<script>
import TabelaSamochodyCiezaroweKoszty from '../../komponenty/strona-tabela-komponenty/WST_samochody_ciezarowe/tabela_samochody_ciezarowe_koszty.vue'


export default {
  name: 'StronaTabelaSamochodyCiezaroweKoszty',
  components: {
    TabelaSamochodyCiezaroweKoszty,
  }

}
</script>
