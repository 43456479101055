<template >
  <div>
  <TabelaRaportyMagazynZakonczone/>
  </div>
</template>

<script>
import TabelaRaportyMagazynZakonczone from '../../komponenty/strona-tabela-komponenty/SO_realizacja_magazyn/tabela_wykaz_raportow_magazyn_zakonczone.vue'


export default {
  name: 'StronaTabelaRaportyMagazynZakonczone',
  components: {
    TabelaRaportyMagazynZakonczone,
    
  }

}
</script>

