<template>
  <div>
  <tabelaWykazSytuacje/>

  </div>
</template>

<script>
import tabelaWykazSytuacje from '../../komponenty/strona-tabela-komponenty/BHP_sytuacje_potencjalnie_wypadkowe/tabela_wykaz_sytuacji.vue'


export default {
  name: 'StronaTabelaWykazSytuacje',
  components: {
       tabelaWykazSytuacje,

  }

}
</script>
