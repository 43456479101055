import jsPDF from "jspdf";
import "jspdf-autotable";
import { api } from "./api.serwis";

export const pdfSerwis = {
  generujKontekst: async (Id) => {
    try {
      const [
        nazwaLokalizacji,
        nazwaZakres,
        nazwaGranice,
        nazwaNorm,
        nazwaPunktow,
        nazwaProcesowZewnetrznych,
        nazwaMocneStrony,
        nazwaSlabeStrony,
        nazwaWSZ,
        nazwaZSZ,
        nazwaPrzeglady,
      ] = await Promise.all([
        api.pobieranieLokalizacjiKontekstu(Id),
        api.pobieranieZakres(Id),
        api.pobieranieGranice(Id),
        api.pobieranieNormy(Id),
        api.pobieranieWylaczenia(Id),
        api.pobieranieProcesowZewnetrznych(Id),
        api.pobieranieMocnychStron(Id),
        api.pobieranieSlabychStron(Id),
        api.pobieranieWSZ(Id),
        api.pobieranieZSZ(Id),
        api.pobieraniePrzegladuKontekstuHistoria(Id),
      ]);

      const doc = new jsPDF("p", "mm", "a4");

      doc.setFont("Aleo-Regular");

      doc.text("Kontekst organizacji", 14, 10);

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "LOKALIZACJE OBJĘTE ZAKRESEM CERTYFIKACJI",
              colSpan: 6,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Numer lokalizacji",
            "Kod",
            "Miejscowość",
            "Ulica",
            "Nr",
            "Województwo",
          ],
        ],
        body: nazwaLokalizacji.data.dane.map((el) => {
          return [
            el.Nazwa_lokalizacji,
            el.Kod,
            el.Miejscowosc,
            el.Ulica,
            el.Nr,
            el.Wojewodztwa,
          ];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "ZAKRES CERTYFIKACJI DLA LOKALIZACJI",
              colSpan: 2,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          ["Nazwa lokalizacji", "Zakres systemu zarządzania dla lokalizacji"],
        ],
        body: nazwaZakres.data.dane.map((el) => {
          return [el.Nazwa_lokalizacji, el.Zakres];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "GRANICE CERTYFIKACJI DLA LOKALIZACJI",
              colSpan: 2,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          ["Nazwa lokalizacji", "Granice systemu zarządzania dla lokalizacji"],
        ],
        body: nazwaGranice.data.dane.map((el) => {
          return [el.Nazwa_lokalizacji, el.Granice];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "WYKAZ NORM OBJĘTYCH CERTYFIKACJĄ",
              colSpan: 2,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          ["Numer normy", "Nazwa normy"],
        ],
        body: nazwaNorm.data.dane.map((el) => {
          return [el.Nr_normy, el.Nazwa_normy];
        }),
      });

      doc.autoTable({
        headStyles: {
          ooverflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "WYKAZ PUNKTÓW NIE MAJĄCYCH ZASTOSOWANIA",
              colSpan: 3,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Punkt normy",
            "Opis punktu normy",
            "Uzasadnienie nie zastosowania punktu normy",
          ],
        ],
        body: nazwaPunktow.data.dane.map((el) => {
          return [el.Punkt, el.Opis, el.Uzasadnienie];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "WYKAZ PROCESÓW ZLECANYCH NA ZEWNĄTRZ",
              colSpan: 1,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          ["Nazwa procesu zlecanego na zewnątrz"],
        ],
        body: nazwaProcesowZewnetrznych.data.dane.map((el) => {
          return [el.Procesy_zewnetrzne];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "MOCNE STRONY",
              colSpan: 1,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          ["Mocne strony organizacji"],
        ],
        body: nazwaMocneStrony.data.dane.map((el) => {
          return [el.Mocne_strony];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "SŁABE STRONY",
              colSpan: 1,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          ["Słabe strony organizacji"],
        ],
        body: nazwaSlabeStrony.data.dane.map((el) => {
          return [el.Slabe_strony];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "ZEWNĘTRZNE STRONY ZAINTERESOWANE",
              colSpan: 1,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          ["Wewnętrzne strony zainteresowane organizacji"],
        ],
        body: nazwaZSZ.data.dane.map((el) => {
          return [el.ZSZ];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "WEWNĘTRZNE STRONY ZAINTERESOWANE",
              colSpan: 1,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          ["Wewnętrzne strony zainteresowane organizacji"],
        ],
        body: nazwaWSZ.data.dane.map((el) => {
          return [el.WSZ];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "PRZEGLĄDY KONTEKSTU ORGANIZACJI",
              colSpan: 4,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Data przeglądu",
            "Data następnego przeglądu",
            "Pozostało dni",
            "Osoba odpowiedzialna",
          ],
        ],
        body: nazwaPrzeglady.data.dane.map((el) => {
          return [
            el.Data_przegladu,
            el.Data_nastepnego_przegladu,
            el.Pozostalo_dni,
            el.Osoba_odpowiedzialna,
          ];
        }),
      });

      doc.save("Kontekst.pdf");
    } catch (error) {
      console.log("Bład", error);
    }
  },
  //--------------------------------------------------------------------------------------------------------------------------

  generujInformacjeOProcesie: async (Id, Idiki_celow) => {
    try {
      const [
        nazwaProcesu,
        daneWejsciowe,
        daneWyjsciowe,
        celeStrategiczne,
        przeglady,
        ...rozliczeniaCele
      ] = await Promise.all([
        api.pobieranieProcesu1(Id),
        api.pobieranieProcesuDaneWejsciowe(Id),
        api.pobieranieProcesuDaneWyjsciowe(Id),
        api.pobieranieProcesuCeleStrategiczne(Id),
        api.pobieranieProcesuPrzeglad(Id),
        ...Idiki_celow.map((id) =>
          api.pobieranieProcesuRozliczenieCeleStrategiczne(id)
        ),
      ]);

      const doc = new jsPDF("l", "mm", "a4");

      doc.setFont("Aleo-Regular");

      doc.text("Dane szczegółowe dla procesu", 14, 10);

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "DANE DLA PROCESU",
              colSpan: 2,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          ["Numer procesu", "Nazwa procesu"],
        ],
        body: nazwaProcesu.data.dane.map((el) => {
          return [el.Nr_procesu, el.Nazwa_procesu];
        }),
      });
      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "DANE WEJŚCIOWE DLA PROCESU",
              colSpan: 2,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          ["Lp", "Dane wejściowe"],
        ],
        body: daneWejsciowe.data.dane.map((el,index) => {
          return [index+1, el.Dane_wejsciowe];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "DANE WYJŚCIOWE DLA PROCESU",
              colSpan: 2,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          ["Lp", "Dane wyjsciowe"],
        ],
        body: daneWyjsciowe.data.dane.map((el,index) => {
          return [index+1, el.Dane_wyjsciowe];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "CELE STRATEGICZNE DLA PROCESU",
              colSpan: 7,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Cel strategiczny",
            "Opis celu",
            "Wskaźnik dla celu",
            "Wartość oczekiwana",
            "Okres rozliczeniowy",
            "Data planowanego przeglądu"
          ],
        ],
        body: celeStrategiczne.data.dane.map((el,index) => {
          return [
            index+1,
            el.Cel,
            el.Opis,
            el.Wskaznik,
            el.Wartosc_oczekiwana,
            el.Okres,
            el.Data_oceny
          ];
        }),
      });

      rozliczeniaCele.forEach((rozliczenieCele) => {
        doc.autoTable({
          headStyles: {
            overflow: "linebreak",
            fontSize: 9,
            font: "Aleo-Regular",
            fillColor: "#e3e7e6",
            textColor: "black",
            lineColor: "black",
            lineWidth: 0.1,
          },
          bodyStyles: {
            overflow: "linebreak",
            fontSize: 9,
            font: "Aleo-Regular",
            fillColor: "#e3e7e6",
            textColor: "black",
            lineColor: "black",
            lineWidth: 0.1,
          },

          head: [
            [
              {
                content: "WYKAZ ROZLICZEŃ DLA CELU STRATEGICZNEGO PROCESU",
                colSpan: 7,
                styles: {
                  halign: "center",
                  fillColor: "silver",
                  fontStyle: "bold",
                },
              },
            ],
            [
              "Lp",
              "Cel strategiczny",
              "Data rozliczenia",
              "Data następnego rozliczenia",
              "Pozostało dni",
              "Rozliczenie wykonał",
              "Uzyskany wynik",
            ],
          ],
          body: rozliczenieCele.data.dane.map((el,index) => {
            return [
              index+1,
              el.Cel,
              el.Data_rozliczenia,
              el.Data_nastepnego_rozliczenia,
              el.Pozostalo_dni,
              el.Rozliczenie_wykonal,
              el.Uzyskany_wynik,
            ];
          }),
        });
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "WYKAZ PRZEGLĄDÓW PROCESU",
              colSpan: 7,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Numer procesu",
            "Nazwa procesu",
            "Data przeglądu",
            "Data następnego przeglądu",
            "Pozostało dni",
            "Przegląd wykonał",
          ],
        ],
        body: przeglady.data.dane.map((el,index) => {
          return [
            index+1,
            el.Nr_procesu,
            el.Nazwa_procesu,
            el.Data_przegladu,
            el.Data_nastepnego_przegladu,
            el.Pozostalo_dni,
            el.Przeglad_wykonal,
          ];
        }),
      });
      doc.save("Dane_procesu.pdf");
    } catch (error) {
      console.log("Bład", error);
    }
  },
  //--------------------------------------------------------------------------------------------------------------------------

  generujInformacjeOCelach: async (Id, Idiki_celow_szcz) => {
    try {
      const [
        nazwaRokCelow,
        nazwaPrzegladyCele,
        nazwaCeleSzczegolowe,
        ...nazwyCeleZadania
      ] = await Promise.all([
        api.pobieranieRokCele1(Id),
        api.pobieranieCelePrzeglady(Id),
        api.pobieranieCeleSzczegolowe(Id),
        ...Idiki_celow_szcz.map((id) => api.pobieranieCeleZadania(id)),
      ]);

      const doc = new jsPDF("l", "mm", "a4");

      doc.setFont("Aleo-Regular");

      doc.text("Dane szczegółowe - cele systemu zarządzania", 14, 10);

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "CELE SYSTEMU ZARZĄDZANIA",
              colSpan: 5,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Rok",
            "Data ustanowienia",
            "Data zakończenia",
            "Osoba odpowiedzialna",
            "Status celów",
          ],
        ],
        body: nazwaRokCelow.data.dane.map((el) => {
          return [
            el.Rok,
            el.Data_ustanowienia,
            el.Data_zakonczenia,
            el.Osoba_odpowiedzialna,
            el.Status_celow,
          ];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "PRZEGLĄDY - CELE SYSTEMU ZARZĄDZANIA",
              colSpan: 6,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Rok",
            "Data przeglądu",
            "Data następnego przeglądu",
            "Pozostalo_dni",
            "Przegląd wykonał",
            "Uwagi",
          ],
        ],
        body: nazwaPrzegladyCele.data.dane.map((el,index) => {
          return [
            index+1,
            el.Rok,
            el.Data_przegladu,
            el.Data_nastepnego_przegladu,
            el.Pozostalo_dni,
            el.Przeglad_wykonal,
            el.Uwagi,
          ];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "WYKAZ CELÓW SZCZEGÓŁOWYCH",
              colSpan: 4,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          ["Lp", "Rok", "Nr celu", "Cel szczegółowy"],
        ],
        body: nazwaCeleSzczegolowe.data.dane.map((el,index) => {
          return [index+1, el.Rok, el.Nr_celu, el.Cel_szczegolowy];
        }),
      });

      nazwyCeleZadania.forEach((nazwaCeleZadania) => {
        doc.autoTable({
          headStyles: {
            overflow: "linebreak",
            fontSize: 9,
            font: "Aleo-Regular",
            fillColor: "#e3e7e6",
            textColor: "black",
            lineColor: "black",
            lineWidth: 0.1,
          },
          bodyStyles: {
            overflow: "linebreak",
            fontSize: 9,
            font: "Aleo-Regular",
            fillColor: "#e3e7e6",
            textColor: "black",
            lineColor: "black",
            lineWidth: 0.1,
          },

          head: [
            [
              {
                content: "WYKAZ ZADAŃ DLA CELU SZCZEGÓŁOWEGO",
                colSpan: 8,
                styles: {
                  halign: "center",
                  fillColor: "silver",
                  fontStyle: "bold",
                },
              },
            ],
            [
              "Lp",
              "Rok",
              "Nr celu",
              "Nr zadania",
              "Zadanie",
              "Miernik",
              "Data rozliczenia",
              "Uwagi",
            ],
          ],

          body: nazwaCeleZadania.data.dane.map((el,index) => {
            return [
              index+1,
              el.Rok,
              el.Nr_celu,
              el.Nr_zadania,
              el.Zadanie,
              el.Miernik,
              el.Data_rozliczenia,
              el.Uwagi,
            ];
          }),
        });
      });
      doc.save("Cele_systemu_zarzadzania.pdf");
    } catch (error) {
      console.log("Bład", error);
    }
  },

  //--------------------------------------------------------------------------------------------------------------------------
  generujDanePracownika: async (Id) => {
    try {
      const [
        nazwaPracownik,
        nazwaAdresZamieszkania,
        nazwaAdresKorespondencja,
        nazwaZatrudnienia,
        nazwaZakresy,
        nazwaBadania,
        nazwaSzkoleniaWS,
        nazwaSzkoleniaOkresowe,
        nazwaZapoznanieORZ,
        nazwaZapoznanieIO,
        nazwaZapoznanieIS,
        nazwaZapoznanieIBHP,
        nazwaKompetencje,
        nazwaHistoriaZatrudnienia,
      ] = await Promise.all([
        api.pobieranieWykazPracownicyZatrudnieniDrukuj(Id),
        api.pobieranieWykazPracownicyZatrudnieniAdresZamieszkania(Id),
        api.pobieranieWykazPracownicyZatrudnieniAdresKorespondencja(Id),
        api.pobieranieDaneZatrudnieniaPracownicy(Id),
        api.pobieranieZakresuPracownicy(Id),
        api.pobieranieBadaniaPracownicy(Id),
        api.pobieranieSzkoleniaWSPracownicy(Id),
        api.pobieranieSzkoleniaBHPPracownicy(Id),
        api.pobieranieZapoznanieORZPracownicy(Id),
        api.pobieranieZapoznanieIOPracownicy(Id),
        api.pobieranieZapoznanieISPracownicy(Id),
        api.pobieranieZapoznanieIBHPPracownicy(Id),
        api.pobieranieKompetencjePracownicy(Id),
        api.pobieranieHistoriaPracownicy(Id),
      ]);

      const doc = new jsPDF("p", "mm", "a4");

      doc.setFont("Aleo-Regular");

      doc.text("DANE PRACOWNIKA", 14, 10);
      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "silver",
          fontStyle: "bold",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "DANE PRACOWNIKA",
              colSpan: 6,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Nazwisko i imię",
            "PESEL",
            "NIP",
            "Data urodzenia",
            "Miejsce urodzenia",
          ],
        ],
        body: nazwaPracownik.data.dane.map((el,index) => {
          return [
            index+1,
            el.Nazwisko + " " + el.Imie1,
            el.Pesel,
            el.NIP,
            el.Data_urodzenia,
            el.Miejsce_urodzenia,
          ];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "silver",
          fontStyle: "bold",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "ADRES ZAMIESZKANIA",
              colSpan: 6,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          ["Lp", "Kod", "Miejscowość", "Ulica", "Nr", "Województwo"],
        ],
        body: nazwaAdresZamieszkania.data.dane.map((el,index) => {
          return [
            index+1,
            el.Kod,
            el.Miejscowosc,
            el.Ulica,
            el.Nr,
            el.Wojewodztwa,
          ];
        }),
      });
      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "silver",
          fontStyle: "bold",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "ADRES DO KORESPONDENCJI",
              colSpan: 6,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          ["Lp", "Kod", "Miejscowość", "Ulica", "Nr", "Województwo"],
        ],
        body: nazwaAdresKorespondencja.data.dane.map((el,index) => {
          return [
            index+1,
            el.Kod1,
            el.Miejscowosc1,
            el.Ulica1,
            el.Nr1,
            el.Wojewodztwa,
          ];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "silver",
          fontStyle: "bold",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "DANE DOTYCZĄCE ZATRUDNIENIA",
              colSpan: 6,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Data zatrudnienia",
            "Czas zatrudnienia",
            "Rodzaj zatrudnienia",
            "Umowa do",
            "Pozostało dni",
          ],
        ],
        body: nazwaZatrudnienia.data.dane.map((el,index) => {
          return [
            index+1,
            el.Data_zatrudnienia,
            el.Czas_zatrudnienia,
            el.Rodzaj_zatrudnienia,
            el.Umowa_do === "0000-00-00" ? "Czas nieokreślony" : el.Umowa_do,
            el.Pozostalo_dni,
          ];
        }),
      });
      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "silver",
          fontStyle: "bold",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "ZAKRESY OBOWIĄZKÓW, ODPOWIEDZIALNOŚCI I UPRAWNIEŃ",
              colSpan: 3,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          ["Lp", "Data zakresu", "Stanowisko"],
        ],
        body: nazwaZakresy.data.dane.map((el,index) => {
          return [index+1, el.Data_zakresu, el.Stanowisko];
        }),
      });
      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "silver",
          fontStyle: "bold",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "BADANIA LEKARSKIE",
              colSpan: 5,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Nr orzeczenia",
            "Data badań",
            "Data waności badań",
            "Ilość dni",
          ],
        ],
        body: nazwaBadania.data.dane.map((el,index) => {
          return [
            index+1,
            el.Nr_orzeczenia,
            el.Data_badan,
            el.Data_waznosci,
            el.Pozostalo_dni,
          ];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "silver",
          fontStyle: "bold",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "SZKOLENIA WSTĘPNE I STANOWISKOWE BHP",
              colSpan: 6,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Stanowisko",
            "Szkolenie wstępne od",
            "Szkolenie wstępne do",
            "Szkolenie stanowiskowe od",
            "Szkolenie stanowiskowe do",
          ],
        ],
        body: nazwaSzkoleniaWS.data.dane.map((el,index) => {
          return [
            index+1,
            el.Stanowisko,
            el.Data_SZW_od,
            el.Data_SZW_do,
            el.Data_STA_od,
            el.Data_STA_do,
          ];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "silver",
          fontStyle: "bold",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "SZKOLENIA OKRESOWE BHP",
              colSpan: 5,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Numer zaświadczenia",
            "Data szkolenia",
            "Termin waności",
            "Poztsało dni",
          ],
        ],
        body: nazwaSzkoleniaOkresowe.data.dane.map((el,index) => {
          return [
            index+1,
            el.Nr_zaswiadczenia,
            el.Data_szkolenia,
            el.Data_waznosci,
            el.Pozostalo_dni,
          ];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "silver",
          fontStyle: "bold",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "ZAPOZNANIE PRACOWNIKA Z OCENĄ RYZYKA ZAWODOWEGO",
              colSpan: 3,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          ["Lp", "Nazwa Oceny Ryzyka Zzawodowego", "Data zapoznania"],
        ],
        body: nazwaZapoznanieORZ.data.dane.map((el,index) => {
          return [index+1, el.Nazwa_dokumentu, el.Data_zapoznania_ORZ];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "silver",
          fontStyle: "bold",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "ZAPOZNANIE PRACOWNIKA Z INSTRUKCJAMI OPERACYJNYMI",
              colSpan: 4,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          ["Lp", "Nr instrukcji", "Nazwa instrukcji", "Data zapoznania"],
        ],
        body: nazwaZapoznanieIO.data.dane.map((el,index) => {
          return [
            index+1,
            el.Nr_dokumentu,
            el.Nazwa_dokumentu,
            el.Data_zapoznania_IO,
          ];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "silver",
          fontStyle: "bold",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "ZAPOZNANIE PRACOWNIKA Z INSTRUKCJAMI ŚRODOWISKOWYMI",
              colSpan: 4,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          ["Lp", "Nr instrukcji", "Nazwa instrukcji", "Data zapoznania"],
        ],
        body: nazwaZapoznanieIS.data.dane.map((el,index) => {
          return [
            index+1,
            el.Nr_dokumentu,
            el.Nazwa_dokumentu,
            el.Data_zapoznania_IS,
          ];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "silver",
          fontStyle: "bold",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "ZAPOZNANIE PRACOWNIKA Z INSTRUKCJAMI BHP",
              colSpan: 4,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          ["Lp", "Nr instrukcji", "Nazwa instrukcji", "Data zapoznania"],
        ],
        body: nazwaZapoznanieIBHP.data.dane.map((el,index) => {
          return [
            index+1,
            el.Nr_dokumentu,
            el.Nazwa_dokumentu,
            el.Data_zapoznania_IBHP,
          ];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "silver",
          fontStyle: "bold",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "WYKAZ KOMPETENCJI PRACOWNIKA",
              colSpan: 4,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          ["Lp", "Kompetencje", "Data uzyskania", "Numer"],
        ],
        body: nazwaKompetencje.data.dane.map((el,index) => {
          return [index+1, el.Kompetencje, el.Data_uzyskania, el.Numer];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "silver",
          fontStyle: "bold",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "HISTORIA ZATRUDNIENIA PRACOWNIKA",
              colSpan: 5,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          ["Lp", "FIRMA", "NIP Firmy", "Data zatrudnienia", "Data zwolnienia"],
        ],
        body: nazwaHistoriaZatrudnienia.data.dane.map((el,index) => {
          return [
            index+1,
            el.Firma,
            el.NIP_Z,
            el.Data_zatrudnienia,
            el.Data_zwolnienia,
          ];
        }),
      });

      doc.save("wykaz_pracownikow_zatrudnionych.pdf");
    } catch (error) {
      console.log("Bład", error);
    }
  },

  //--------------------------------------------------------------------------------------------------------------------------
  generujInformacjeUwagiAudit: async (Id) => {
    try {
      const [nazwaAudit, nazwaUwagi, nazwaRealizacja] = await Promise.all([
        api.pobieranieAudity1(Id),
        api.pobieranieDzialaniaAuditOcena(Id),
        api.pobieranieDzialaniaAuditOcenaSkutecznosci(Id),
      ]);

      const doc = new jsPDF("p", "mm", "a4");

      doc.setFont("Aleo-Regular");

      doc.text(
        "Dane szczegółowe - działania stwierdzone podczas auditów",
        14,
        10
      );

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "AUDIT WEWNĘTRZNY",
              colSpan: 5,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Rok",
            "Nr auditu",
            "Data rozpoczęcia",
            "Data zakończenia",
            "Auditorzy",
          ],
        ],
        body: nazwaAudit.data.dane.map((el) => {
          return [
            el.Rok,
            el.Nr_auditu,
            el.Data_rozpoczecia,
            el.Data_zakonczenia,
            el.Auditorzy_wewnetrzni,
          ];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "STWIERDZONE UWAGI",
              colSpan: 7,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Nr auditu",
            "Nr działania",
            "Rodzaj działania",
            "Przyczyna",
            "Korekcja",
            "Korygujace",
            "Planowane zakończenia",
          ],
        ],
        body: nazwaUwagi.data.dane.map((el) => {
          return [
            el.Nr_auditu,
            el.Nr_dzialania,
            el.Wartosc_wyniky,
            el.Przyczyna,
            el.Korekcja,
            el.Korygujace,
            el.Data_zakonczenia,
          ];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "OCENA DZIAŁAŃ",
              colSpan: 6,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Nr auditu",
            "Nr działania",
            "Data zakończenia",
            "Data oceny",
            "Ocena",
            "Ocenę przeprowadził",
          ],
        ],
        body: nazwaRealizacja.data.dane.map((el) => {
          return [
            el.Nr_auditu,
            el.Nr_dzialania,
            el.Data_zakonczenia,
            el.Data_oceny,
            el.Ocena,
            el.Ocene_przeprowadzil,
          ];
        }),
      });

      doc.save("Cele_systemu_zarzadzania.pdf");
    } catch (error) {
      console.log("Bład", error);
    }
  },
  //_________________________________________________________________________________________________
  generujInformacjeWymaganiaPrawne: async (Id) => {
    try {
      const [wymaganiaPrawne, przegladWymaganiaPrawne] = await Promise.all([
        api.pobieranieWymaganiaPrawne(Id),
        api.pobieranieWymaganiaPrawneBHP(Id),
      ]);

      const doc = new jsPDF("l", "mm", "a4");

      doc.setFont("Aleo-Regular");

      doc.text("WYKAZ WYMAGAŃ PRAWNYCH I INNYCH - OCENA ZGODNOŚCI", 14, 10);

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "WYMAGANIA PRAWNE I INNE",
              colSpan: 7,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Rodzaj wymagania",
            "Wymaganie dotyczy",
            "Wymaganie",
            "Data przeglądu",
            "Data następnego przeglądu",
            "Osoba odpowiedzialna",
          ],
        ],
        body: wymaganiaPrawne.data.dane.map((el,index) => {
          return [
            index+1,
            el.Rodzaj_wymagania,
            el.Wymaganie_dotyczy,
            el.Wymaganie,
            el.Data_przegladu,
            el.Data_nastepnego_przegladu,
            el.Osoba_odpowiedzialna,
          ];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "PRZEGLĄDY - OCENA ZGODNOŚCI",
              colSpan: 6,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Data przeglądu",
            "Data następnego przeglądu",
            "Ilość dni",
            "Stwierdzone uwagi",
            "Ocenę zgodności wykonał",
          ],
        ],
        body: przegladWymaganiaPrawne.data.dane.map((el,index) => {
          return [
            index+1,
            el.Data_przegladu,
            el.Data_nastepnego_przegladu,
            el.Pozostalo_dni || "",
            el.Uwagi,
            el.Osoba_odpowiedzialna,
          ];
        }),
      });

      doc.save("Wymagania_prawne_z_ocena_zgodnosci.pdf");
    } catch (error) {
      console.log("Bład", error);
    }
  },

  //--------------------------------------------------------------------------------------------------------------------------

  generujInformacjeOAspektach: async (Id) => {
    try {
      const [
        wykazAspekty,
        wykazWymaganiaPrawneAspekty,
        monitorowanieAspeky,
        przegladyAspekty,
      ] = await Promise.all([
        api.pobieranieAspekty(Id),
        api.pobieranieAspektyWymagania1(Id),
        api.pobieranieAspektyMonitorowanie1(Id),
        api.pobieranieAspektyprzeglady(Id),
      ]);

      const doc = new jsPDF("l", "mm", "a4");

      doc.setFont("Aleo-Regular");

      doc.text("Dane szczegółowe - aspekty środowiskowe", 14, 10);

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "ASPEKTY ŚRODOWISKOWE",
              colSpan: 13,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Nr procesu",
            "Nazwa procesu",
            "Aspekt",
            "Miejsce występowania",
            "W1",
            "W2",
            "W3",
            "W4",
            "W5",
            "W6",
            "Wynik",
            "Rodzaj aspektu",
          ],
        ],
        body: wykazAspekty.data.dane.map((el,index) => {
          return [
            index+1,
            el.Nr_procesu,
            el.Nazwa_procesu,
            el.Aspekt,
            el.Miejsce,
            el.W1,
            el.W2,
            el.W3,
            el.W4,
            el.W5,
            el.W6,
            el.Wynik,
            el.Znaczenie,
          ];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "WYMAGANIA PRAWNE DO ASPEKTU ŚRODOWISKOWEGO",
              colSpan: 7,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Nr procesu",
            "Nazwa procesu",
            "Aspekt środowiskowy",
            "Wymaganie",
            "Data przeglądu wymagania",
            "Data następnego przeglądu wymagania",
          ],
        ],
        body: wykazWymaganiaPrawneAspekty.data.dane.map((el,index) => {
          return [
            index+1,
            el.Nr_procesu,
            el.Nazwa_procesu,
            el.Aspekt,
            el.Wymaganie,
            el.Data_przegladu,
            el.Data_nastepnego_przegladu,
          ];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "SPOSÓB MONITOROWANIA ASPEKTU ŚRODOWISKOWEGO",
              colSpan: 5,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Nr procesu",
            "Nazwa procesu",
            "Aspekt środowiskowy",
            "Sposób monitorowania",
          ],
        ],
        body: monitorowanieAspeky.data.dane.map((el,index) => {
          return [
            index+1,
            el.Nr_procesu,
            el.Nazwa_procesu,
            el.Aspekt,
            el.Sposob_monitorowania,
          ];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "WYKAZ PRZEGLĄDÓW ASPEKTÓW ŚRODOWISKOWYCH",
              colSpan: 5,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Data przeglądu",
            "Data następnego przeglądu",
            "Ilość dni",
            "Stwierdzone uwagi",
            "Osoba odpowiedzialna",
          ],
        ],
        body: przegladyAspekty.data.dane.map((el,index) => {
          return [
            index+1,
            el.Data_przegladu,
            el.Data_nastepnego_przegladu,
            el.Pozostalo_dni || "",
            el.Uwagi,
            el.Osoba_odpowiedzialna,
          ];
        }),
      });
      doc.save("Aspekty_srodowiskowe_analiza.pdf");
    } catch (error) {
      console.log("Bład", error);
    }
  },
  //------------------------------------------------
  generujRaportZAuditu: async (Id) => {
    try {
      const [audit, pobierzPunkty] = await Promise.all([
        api.pobieranieRaportyAudity(Id),
        api.pobieranieAudityEdycjaPDF(Id),
      ]);

      const doc = new jsPDF("l", "mm", "a4");

      doc.setFont("Aleo-Regular");

      doc.text("RAPORT Z AUDITU WEWNĘTRZNEGO", 14, 10);

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "DANE AUDITU",
              colSpan: 5,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Nr auditu",
            "Data_rozpoczecia",
            "Data_zakonczenia",
            "Auditorzy wewnętrzni",
          ],
        ],
        body: audit.data.dane.map((el,index) => {
          return [
            index+1,
            el.Nr_auditu,
            el.Data_rozpoczecia,
            el.Data_zakonczenia,
            el.Auditorzy_wewnetrzni,
          ];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "silver",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            "Lp",
            "Norma",
            "Punkt normy",
            "Dowody",
            "Wynik",
            "Numer działania",
            "Spostrzezenie/Niezgodność",
          ],
        ],

        body: pobierzPunkty.data.dane.informacjeOPunktach
          .reduce(
            (kolektor, { punkty, nazwa }) => [
              ...kolektor,
              ...punkty.map(
                ({
                  Punkt,
                  Dowod,
                  Id_wynik_auditu,
                  Numer_dzialania,
                  Wartosc_wyniku,
                }) => [
                  nazwa,
                  Punkt,
                  Id_wynik_auditu === "4" ? `PUNKT NIE AUDITOWANY` : Dowod,
                  Id_wynik_auditu === "2"
                    ? `spostrzezenia`
                    : Id_wynik_auditu === "3"
                    ? `niezgodność`
                    : "",
                  Numer_dzialania,
                  Wartosc_wyniku,
                ]
              ),
            ],
            []
          )
          .map((el, index) => [index + 1, ...el]),
      });

      doc.save("Raport.pdf");
    } catch (error) {
      console.log("Bład", error);
    }
  },
  //--------------------------------------------------------------------------------------------------------------------------

  generujDzialaniaAuditowe: async (Id) => {
    try {
      const [
        wykazDzialan,
        wykazOcena,
        wykazOcenaSkutecznosci,
      ] = await Promise.all([
        api.pobieranieDzialaniaAudit(Id),
        api.pobieranieDzialaniaAuditOcena1(Id),
        api.pobieranieDzialaniaAuditOcenaSkutecznosci1(Id),
      ]);

      const doc = new jsPDF("l", "mm", "a4");

      doc.setFont("Aleo-Regular");

      doc.text("Działania stwierdzone w trakcie auditów wewnętrznych", 14, 10);

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "WYKAZ WSZYSTKICH DZIAŁAŃ",
              colSpan: 6,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Nr auditu",
            "Nr działania",
            "Data działania",
            "Rodzaj działania",
            "Działanie",
          ],
        ],
        body: wykazDzialan.data.dane.map((el,index) => {
          return [
            index+1,
            el.Nr_auditu,
            el.Numer_dzialania,
            el.Data_zakonczenia,
            el.Rodzaj_dzialania,
            el.Wartosc_wyniku,
          ];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "WYKAZ PODJĘTYCH DZIAŁAŃ",
              colSpan: 8,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Nr auditu",
            "Nr działania",
            "Stwierdzona uwaga",
            "Przyczyna",
            "Działania korekcyjne",
            "Działania korygujące",
            "Planowana data zakończenia",
          ],
        ],
        body: wykazOcena.data.dane.map((el,index) => {
          return [
            index+1,
            el.Nr_auditu,
            el.Numer_dzialania,
            el.Wartosc_wyniku,
            el.Przyczyna,
            el.Korekcja,
            el.Korygujace,
            el.Data_zakonczenia,
          ];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "OCENA SKUTECZNOŚCI DO PODJĘTYCH DZIAŁAŃ",
              colSpan: 7,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Nr auditu",
            "Nr działania",
            "Data zakończenia",
            "Data oceny",
            "Ocena skuteczności",
            "Ocenę przeprowadził",
          ],
        ],
        body: wykazOcenaSkutecznosci.data.dane.map((el,index) => {
          return [
            index+1,
            el.Nr_auditu,
            el.Numer_dzialania,
            el.Data_zakonczenia,
            el.Data_oceny,
            el.Ocena,
            el.Ocene_przeprowadzil,
          ];
        }),
      });
      doc.save("dzialania_auditowe.pdf");
    } catch (error) {
      console.log("Bład", error);
    }
  },
  //--------------------------------------------------------------------------------------------------------------------------

  generujDzialaniaPoZaAuditowe: async (Id) => {
    try {
      const [
        wykazDzialan,
        wykazOcena,
        wykazOcenaSkutecznosci,
      ] = await Promise.all([
        api.pobieranieDzialaniaPozaAudit(Id),
        api.pobieranieDzialaniaPozaAuditOcena1(Id),
        api.pobieranieDzialaniaPozaAuditOcenaSkutecznosci1(Id),
      ]);

      const doc = new jsPDF("l", "mm", "a4");

      doc.setFont("Aleo-Regular");

      doc.text("Działania stwierdzone po za auditami wewnętrznymi", 14, 10);

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "WYKAZ WSZYSTKICH DZIAŁAŃ",
              colSpan: 5,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Nr działania",
            "Data działania",
            "Rodzaj działania",
            "Działanie",
          ],
        ],
        body: wykazDzialan.data.dane.map((el,index) => {
          return [
            index+1,
            el.Nr_dzialania,
            el.Data,
            el.Rodzaj_dzialania,
            el.Dzialanie,
          ];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "WYKAZ PODJĘTYCH DZIAŁAŃ",
              colSpan: 8,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Nr działania",
            "Rodzaj działania",
            "Działanie",
            "Przyczyna",
            "Działania korekcyjne",
            "Działania korygujące",
            "Planowana data zakończenia",
          ],
        ],
        body: wykazOcena.data.dane.map((el,index) => {
          return [
            index+1,
            el.Nr_dzialania,
            el.Rodzaj_dzialania,
            el.Dzialanie,
            el.Przyczyna,
            el.Korekcja,
            el.Korygujace,
            el.Data_zakonczenia,
          ];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "OCENA SKUTECZNOŚCI DO PODJĘTYCH DZIAŁAŃ",
              colSpan: 8,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Nr działania",
            "Rodzaj działania",
            "Działanie",
            "Data zakończenia",
            "Data oceny",
            "Ocena działań",
            "Ocenę przeprowadził",
          ],
        ],
        body: wykazOcenaSkutecznosci.data.dane.map((el,index) => {
          return [
            index+1,
            el.Nr_dzialania,
            el.Rodzaj_dzialania,
            el.Dzialanie,
            el.Data_zakonczenia,
            el.Data_oceny,
            el.Ocena,
            el.Ocene_przeprowadzil,
          ];
        }),
      });
      doc.save("dzialania_po_za_auditowe.pdf");
    } catch (error) {
      console.log("Bład", error);
    }
  },
  generujRaportPrzegladZarzadzania: async (Id) => {
    try {
      const [
        wykazDzialan,
        wykazOcena,
        wykazOcenaSkutecznosci,
      ] = await Promise.all([
        api.pobieranieDzialaniaPozaAudit(Id),
        api.pobieranieDzialaniaPozaAuditOcena1(Id),
        api.pobieranieDzialaniaPozaAuditOcenaSkutecznosci1(Id),
      ]);

      const doc = new jsPDF("l", "mm", "a4");

      doc.setFont("Aleo-Regular");

      doc.text("Działania stwierdzone w trakcie auditów wewnętrznych", 14, 10);

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "WYKAZ WSZYSTKICH DZIAŁAŃ",
              colSpan: 5,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Nr działania",
            "Data działania",
            "Rodzaj działania",
            "Działanie",
          ],
        ],
        body: wykazDzialan.data.dane.map((el,index) => {
          return [
            index+1,
            el.Nr_dzialania,
            el.Data,
            el.Rodzaj_dzialania,
            el.Dzialanie,
          ];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "WYKAZ PODJĘTYCH DZIAŁAŃ",
              colSpan: 8,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Nr działania",
            "Rodzaj działania",
            "Działanie",
            "Przyczyna",
            "Działania korekcyjne",
            "Działania korygujące",
            "Planowana data zakończenia",
          ],
        ],
        body: wykazOcena.data.dane.map((el,index) => {
          return [
            index+1,
            el.Nr_dzialania,
            el.Rodzaj_dzialania,
            el.Dzialanie,
            el.Przyczyna,
            el.Korekcja,
            el.Korygujace,
            el.Data_zakonczenia,
          ];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "OCENA SKUTECZNOŚCI DO PODJĘTYCH DZIAŁAŃ",
              colSpan: 8,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Nr działania",
            "Rodzaj działania",
            "Działanie",
            "Data zakończenia",
            "Data oceny",
            "Ocena działań",
            "Ocenę przeprowadził",
          ],
        ],
        body: wykazOcenaSkutecznosci.data.dane.map((el,index) => {
          return [
            index+1,
            el.Nr_dzialania,
            el.Rodzaj_dzialania,
            el.Dzialanie,
            el.Data_zakonczenia,
            el.Data_oceny,
            el.Ocena,
            el.Ocene_przeprowadzil,
          ];
        }),
      });
      doc.save("dzialania_po_za_auditowe.pdf");
    } catch (error) {
      console.log("Bład", error);
    }
  },
  //---------------------------------
  generujRaportKontrolaWewnetrzna: async (Id) => {
    try {
      const [wykazKontroli, wykazOcena, wykazSkutecznosc] = await Promise.all([
        api.pobieranieWykazuKontroli1(Id),
        api.pobieranieWykazuKontroliDzialania(Id),
        api.pobieranieWykazuKontroliDzialaniaOcena1(Id),
      ]);

      const doc = new jsPDF("l", "mm", "a4");

      doc.setFont("Aleo-Regular");

      doc.text("Działania do kontroli wewnętrznej", 14, 10);

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "KONTROLA WEWNĘTRZNA",
              colSpan: 5,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Data kontroli",
            "Miejsce kontroli",
            "Kontrolę przeprowadził",
            "Zakres kontroli",
          ],
        ],
        body: wykazKontroli.data.dane.map((el,index) => {
          return [
            index+1,
            el.Data_kontroli,
            el.Miejsce_kontroli,
            el.Kontrole_przeprowadzil,
            el.Zakres_kontroli,
          ];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "WYKAZ STWIERDZONYCH UWAG",
              colSpan: 7,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Data kontroli",
            "Uwagi",
            "Przyczyna",
            "Korekcja",
            "Korygujace",
            "Data_zakonczenia",
          ],
        ],
        body: wykazOcena.data.dane.map((el,index) => {
          return [
            index+1,
            el.Data_kontroli,
            el.Uwagi,
            el.Przyczyna,
            el.Korekcja,
            el.Korygujace,
            el.Data_zakonczenia,
          ];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "WYKAZ OCENA SKUTECZNOŚCI",
              colSpan: 6,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Uwagi",
            "Data_zrealizowania",
            "Data oceny skuteczności",
            "Ocenę przeprowadził",
            "Ocena",
          ],
        ],
        body: wykazSkutecznosc.data.dane.map((el,index) => {
          return [
            index+1,
            el.Uwagi,
            el.Data_zrealizowania,
            el.Data_oceny,
            el.Ocene_przeprowadzil,
            el.Ocena,
          ];
        }),
      });
      doc.save("kontrola_wewnetrzna.pdf");
    } catch (error) {
      console.log("Bład", error);
    }
  },

  //---------------------------------
  generujRaportKontrolaZewnetrzna: async (Id) => {
    try {
      const [wykazKontroli, wykazOcena, wykazSkutecznosc] = await Promise.all([
        api.pobieranieWykazuKontroliZew1(Id),
        api.pobieranieWykazuKontroliZewDzialania(Id),
        api.pobieranieWykazuKontroliZewDzialaniaOcena1(Id),
      ]);

      const doc = new jsPDF("l", "mm", "a4");

      doc.setFont("Aleo-Regular");

      doc.text("Działania do kontroli zewnętrznej", 14, 10);

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "KONTROLA WEWNĘTRZNA",
              colSpan: 5,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Data kontroli",
            "Miejsce kontroli",
            "Kontrolę przeprowadził",
            "Zakres kontroli",
          ],
        ],
        body: wykazKontroli.data.dane.map((el,index) => {
          return [
            index+1,
            el.Data_kontroli,
            el.Miejsce_kontroli,
            el.Kontrole_przeprowadzil,
            el.Zakres_kontroli,
          ];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "WYKAZ STWIERDZONYCH UWAG",
              colSpan: 7,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Data kontroli",
            "Uwagi",
            "Przyczyna",
            "Korekcja",
            "Korygujace",
            "Data_zakonczenia",
          ],
        ],
        body: wykazOcena.data.dane.map((el,index) => {
          return [
            index+1,
            el.Data_kontroli,
            el.Uwagi,
            el.Przyczyna,
            el.Korekcja,
            el.Korygujace,
            el.Data_zakonczenia,
          ];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "WYKAZ OCENA SKUTECZNOŚCI",
              colSpan: 6,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Uwagi",
            "Data_zrealizowania",
            "Data oceny skuteczności",
            "Ocenę przeprowadził",
            "Ocena",
          ],
        ],
        body: wykazSkutecznosc.data.dane.map((el,index) => {
          return [
            index+1,
            el.Uwagi,
            el.Data_zrealizowania,
            el.Data_oceny,
            el.Ocene_przeprowadzil,
            el.Ocena,
          ];
        }),
      });
      doc.save("kontrola_zewnetrzna.pdf");
    } catch (error) {
      console.log("Bład", error);
    }
  },
  //---------------------------------
  generujSzkolenia: async (Id) => {
    try {
      const [
        wykazPlan,
        wykazSzkolenia,
        wykazRealizacja,
        wykazUczestnicy,
      ] = await Promise.all([
        api.pobieraniePlanuSzkolen(Id),
        api.pobieranieWykazSzkolen(Id),
        api.pobieranieRealizacjaSzkolen1(Id),
        api.pobieranieUczestnikaSzkolen1(Id),
      ]);

      const doc = new jsPDF("l", "mm", "a4");

      doc.setFont("Aleo-Regular");

      doc.text("Wykaz szkoleń wraz z oceną skuteczności", 14, 10);

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "PLAN SZKOLEŃ DLA ROKU",
              colSpan: 4,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          ["Lp", "Rok planu szkoleń", "Osoba odpowiedzialna", "Status planu"],
        ],
        body: wykazPlan.data.dane.map((el,index) => {
          return [index+1, el.Rok, el.Osoba_odpowiedzialna, el.Status_planow];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "WYKAZ SZKOLEŃ",
              colSpan: 5,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Rok",
            "Miesiąc",
            "Koszt szkolenia",
            "Temat szkolenia",
            "Status szkolenia",
          ],
        ],
        body: wykazSzkolenia.data.dane.map((el,index) => {
          return [
            index+1,
            el.Rok,
            el.Miesiac_roku,
            el.Koszt,
            el.Temat,
            el.Status_planow,
          ];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "WYKAZ REALIZACJA SZKOLEŃ",
              colSpan: 6,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Rok",
            "Temat szkolenia",
            "Data rozpoczęcia",
            "Data zakończenia",
            "Szkolenie przeprowadzone przez",
          ],
        ],
        body: wykazRealizacja.data.dane.map((el,index) => {
          return [
            index+1,
            el.Rok,
            el.Temat,
            el.Data_od,
            el.Data_do,
            el.Szkolacy,
          ];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "WYKAZ UCZESTNIKÓW",
              colSpan: 8,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Rok",
            "Miesiąc szkolenia",
            "Nazwisko i imię",
            "Temat szkolenia",
            "Data oceny skuteczności",
            "Ocenę skuteczności przeprowadził",
            "Uwagi do szkolenia",
          ],
        ],
        body: wykazUczestnicy.data.dane.map((el,index) => {
          return [
            index+1,
            el.Rok,
            el.Miesiac_roku,
            el.Nazwisko + " " + el.Imie1,
            el.Temat,
            el.Data_oceny === "0000-00-00" ? "" : el.Data_oceny,
            el.Ocene_przeprowadzil,
            el.Uwagi,
          ];
        }),
      });
      doc.save("szkolenia.pdf");
    } catch (error) {
      console.log("Bład", error);
    }
  },
  //------------------------------------------------------------------------------------------------------------------------------------
  generujWypadek: async (Id) => {
    try {
      const [
        wykazWypadek,
        wykazOcena,
        wykazWypadkiSkutecznosc,
      ] = await Promise.all([
        api.pobieranieDokumentacjaWWP1(Id),
        api.pobieranieDokumentacjaOW1(Id),
        api.pobieranieDokumentacjaOWOS1(Id),
      ]);

      const doc = new jsPDF("l", "mm", "a4");

      doc.setFont("Aleo-Regular");

      doc.text("Dane wypadku pracownika", 14, 10);

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "WYKAZ DANYCH WYPADKU",
              colSpan: 9,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Nr wypadku",
            "Nazwisko i imię",
            "Data wypadku",
            "Data protokołu",
            "Skutki wypadku",
            "Status wypadku",
            "Uwagi",
            "Ilość L4",
          ],
        ],
        body: wykazWypadek.data.dane.map((el,index) => {
          return [
            index+1,
            el.Numer,
            el.Nazwisko + " " + el.Imie1,
            el.Data_wypadku,
            el.Data_protokolu,
            el.Skutek,
            el.Status,
            el.Uwagi,
            el.L4,
          ];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "WYKAZ PODJĘTYCH DZIAŁAŃ DO WYPADKU",
              colSpan: 6,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Nr wypadku",
            "Osoba odpowiedzialna",
            "Przyczyna",
            "Działania korekcyjne",
            "Działania korygujące",
            "Planowana data zakończenia",
          ],
        ],
        body: wykazOcena.data.dane.map((el) => {
          return [
            el.Numer,
            el.Osoba_odpowiedzialna,
            el.Przyczyna,
            el.Korekcja,
            el.Korygujace,
            el.Data_zakonczenia,
          ];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content:
                "WYKAZ DANYCH OCENA SKUTECZNOŚCI DO PODJĘTYCH DZIAŁAŃ DLA WYPADKU",
              colSpan: 5,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Nr wypadku",
            "Data zakończenia",
            "Data oceny",
            "Ocena działań",
            "Ocenę przeprowadził",
          ],
        ],
        body: wykazWypadkiSkutecznosc.data.dane.map((el) => {
          return [
            el.Numer,
            el.Data_zakonczenia,
            el.Data_oceny,
            el.Ocena,
            el.Ocene_przeprowadzil,
          ];
        }),
      });

      doc.save("wypadek.pdf");
    } catch (error) {
      console.log("Bład", error);
    }
  },
  //------------------------------------------------------------------------------------------------------------------------------------
  generujChorobaZawodowa: async (Id) => {
    try {
      const [wykazChoroba, wykazDzialania] = await Promise.all([
        api.pobieranieDokumentacjaCHZAW1(Id),
        api.pobieranieDokumentacjaSKCHZ1(Id),
      ]);

      const doc = new jsPDF("l", "mm", "a4");

      doc.setFont("Aleo-Regular");

      doc.text("Dane zachorowania na chorobę zawodową pracownika", 14, 10);

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "WYKAZ DANYCH - ZACHOROWANIE NA CHOROBĘ ZAWODOWĄ",
              colSpan: 6,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Nazwisko i imię",
            "Stanowisko",
            "Data stwierdzenia choroby",
            "Data decyzji PIS",
            "Nr decyzji PIS",
            "Nr choroby",
          ],
        ],
        body: wykazChoroba.data.dane.map((el,index) => {
          return [
            index+1,
            el.Nazwisko + " " + el.Imie1,
            el.Stanowisko,
            el.Data_stwierdzenia,
            el.Data_decyzji,
            el.Nr_decyzji,
            el.Nr_choroby,
          ];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content:
                "WYKAZ PODJĘTYCH DZIAŁAŃ DO STWIERDZONEJ CHOROBY ZAWODOWEJ",
              colSpan: 7,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Nazwisko i imię",
            "Data decyzji PIS",
            "Nr decyzji PIS",
            "Skutki choroby",
            "Data powiadomienia PIS",
            "Działania poprawy",
          ],
        ],
        body: wykazDzialania.data.dane.map((el,index) => {
          return [
            index+1,
            el.Nazwisko + " " + el.Imie1,
            el.Data_decyzji,
            el.Nr_decyzji,
            el.Skutki,
            el.Data_skutki,
            el.Poprawa,
          ];
        }),
      });

      doc.save("chdoroba_zawodowa.pdf");
    } catch (error) {
      console.log("Bład", error);
    }
  },
 //------------------------------------------------------------------------------------------------------------------------------------
 generujPosiedzenie: async (Id) => {
  try {
    const [wykazPosiedzenie, wykazSzczegoly] = await Promise.all([
      api.pobieranieDokumentacjaPosiedzenie1(Id),
      api.pobieranieDokumentacjaPosiedzenieSzczegoly1(Id),
    ]);

    const doc = new jsPDF("p", "mm", "a4");

    doc.setFont("Aleo-Regular");

    doc.text("Ustalenia z posiedzenia zespołu ds BHP", 14, 10);

    doc.autoTable({
      headStyles: {
        overflow: "linebreak",
        fontSize: 9,
        font: "Aleo-Regular",
        fillColor: "#e3e7e6",
        textColor: "black",
        lineColor: "black",
        lineWidth: 0.1,
      },
      bodyStyles: {
        overflow: "linebreak",
        fontSize: 9,
        font: "Aleo-Regular",
        fillColor: "#e3e7e6",
        textColor: "black",
        lineColor: "black",
        lineWidth: 0.1,
      },

      head: [
        [
          {
            content: "WYKAZ DANYCH - POSIEDZENIA ZESPOŁU DS BHP",
            colSpan: 5,
            styles: {
              halign: "center",
              fillColor: "silver",
              fontStyle: "bold",
            },
          },
        ],
        [
          "Lp",
          "Numer posiedzenia",
          "Data posiedzenia",
          "Data nastepnego posiedzenia",
          "Osoba odpowiedzialna",

        ],
      ],
      body: wykazPosiedzenie.data.dane.map((el,index) => {
        return [
          index+1,
          el.Numer,
          el.Data,
          el.Data_nastepnego_posiedzenia,
          el.Osoba_odpowiedzialna,
        ];
      }),
    });

    doc.autoTable({
      headStyles: {
        overflow: "linebreak",
        fontSize: 9,
        font: "Aleo-Regular",
        fillColor: "#e3e7e6",
        textColor: "black",
        lineColor: "black",
        lineWidth: 0.1,
      },
      bodyStyles: {
        overflow: "linebreak",
        fontSize: 9,
        font: "Aleo-Regular",
        fillColor: "#e3e7e6",
        textColor: "black",
        lineColor: "black",
        lineWidth: 0.1,
      },

      head: [
        [
          {
            content:
              "WYKAZ USTALEŃ",
            colSpan: 1,
            styles: {
              halign: "center",
              fillColor: "silver",
              fontStyle: "bold",
            },
          },
        ],
        [
          "Ustalenia",

        ],
      ],
      body: wykazSzczegoly.data.dane.map((el) => {
        return [

          el.Uwagi,

        ];
      }),
    });

    doc.save("posiedzenie_zespolu.pdf");
  } catch (error) {
    console.log("Bład", error);
  }
},
  //------------------------------------------------------------------------------------------------------------------------------------
  generujSytuacjeNiebezpieczne: async (Id) => {
    try {
      const [
        wykazSPZ,
        wykazDzialaniaSPZ,
        wykazOcenaDzialaniaSPZ,
      ] = await Promise.all([
        api.pobieranieDokumentacjaSPW1(Id),
        api.pobieranieDokumentacjaSPWDZ1(Id),
        api.pobieranieDokumentacjaSPWDZO1(Id),
      ]);

      const doc = new jsPDF("l", "mm", "a4");

      doc.setFont("Aleo-Regular");

      doc.text("Dane dla sytuacji potencjalnie niebezpiecznej", 14, 10);

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "WYKAZ DANYCH - SYTUACJA POTENCJALNIE NIEBEZPIECZNA",
              colSpan: 6,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Nr sytuacji",
            "Miejsce wystąpienia",
            "Data",
            "Opis sytuacji",
            "Osoba odpowiedzialna",
          ],
        ],
        body: wykazSPZ.data.dane.map((el,index) => {
          return [
            index+1,
            el.Numer,
            el.Miejsce,
            el.Data,
            el.Opis,
            el.Osoba_odpowiedzialna,
          ];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content:
                "WYKAZ PODJĘTYCH DZIAŁAŃ DLA SYTUACJI POTENCJALNIE NIEBEZPIECZNEJ",
              colSpan: 7,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Numer działania",
            "Osoba odpowiedzialna",
            "Przyczyna",
            "Działania korekcyjne",
            "Działania korygujące",
            "Planowana data zakończenia",
          ],
        ],
        body: wykazDzialaniaSPZ.data.dane.map((el,index) => {
          return [
            index+1,
            el.Numer,
            el.Osoba_odpowiedzialna,
            el.Przyczyna,
            el.Korekcja,
            el.Korygujace,
            el.Data_zakonczenia,
          ];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content:
                "OCENA SKUTECZNOŚCI DO PODJĘTYCH DZIAŁAŃ DLA SYTUACJI POTENCJALNIE NIEBEZPIECZNEJ",
              colSpan: 6,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Numer działania",
            "Data zakończenia",
            "Data oceny",
            "Ocena działań",
            "Ocenę przeprowadził",
          ],
        ],
        body: wykazOcenaDzialaniaSPZ.data.dane.map((el,index) => {
          return [
            index+1,
            el.Numer,
            el.Data_zakonczenia,
            el.Data_oceny,
            el.Ocena,
            el.Ocene_przeprowadzil,
          ];
        }),
      });

      doc.save("SPN.pdf");
    } catch (error) {
      console.log("Bład", error);
    }
  },

  //------------------------------------------------------------------------------------------------------------------------------------
  generujProjekt: async (Id) => {
    try {
      const [wykazProjekt, wykazSzczegolyProjekt] = await Promise.all([
        api.pobieranieWykazuProjektow1(Id),
        api.pobieranieSzczegolyProjektu1(Id),
      ]);

      const doc = new jsPDF("l", "mm", "a4");

      doc.setFont("Aleo-Regular");

      doc.text("Dane dla projektu", 14, 10);

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "WYKAZ DANYCH - PROJEKT",
              colSpan: 7,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Numer projektu",
            "Kontrahent",
            "Nazwa projektu",
            "Data rozpoczęcia",
            "Planowana dana zakończenia",
            "Status projektu",
          ],
        ],
        body: wykazProjekt.data.dane.map((el,index) => {
          return [
            index+1,
            el.Nr_projektu,
            el.Nazwa_kontrahenta,
            el.Nazwa_projektu,
            el.Data_rozpoczecia,
            el.Data_planowana_zakonczenia,
            el.Status,
          ];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "WYKAZ SZCZEGÓŁÓW DLA PROJEKTU",
              colSpan: 7,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Numer projektu",
            "Część projektu",
            "Data relizacji",
            "Realizacja",
            "Uwagi",
            "Osoba odpowiedzialna",
          ],
        ],
        body: wykazSzczegolyProjekt.data.dane.map((el,index) => {
          return [
            index+1,
            el.Nr_projektu,
            el.Czesc_projektu,
            el.Data,
            el.Realizacja_czesci_projektu,
            el.Uwagi,
            el.Osoba_odpowiedzialna,
          ];
        }),
      });

      doc.save("projekt.pdf");
    } catch (error) {
      console.log("Bład", error);
    }
  },

  //------------------------------------------------------------------------------------------------------------------------------------
  generujZapytanieOfertowe: async (Id) => {
    try {
      const [
        wykazZaptanieOfertowe,
        wykazSzczegolyZapytanieOfertowe,
      ] = await Promise.all([
        api.pobieranieZapytaniaOfertowego1(Id),
        api.pobieranieZapytaniaOfertowegoSzczegoly1(Id),
      ]);

      const doc = new jsPDF("l", "mm", "a4");

      doc.setFont("Aleo-Regular");

      doc.text("Dane dla zapytania ofertowego", 14, 10);

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "WYKAZ DANYCH - ZAPYTANIE OFERTOWE",
              colSpan: 8,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Numer wewnętrzny zapytania",
            "Data zapytania ofertowego",
            "Numer zapytania ofertowego",
            "Kontrahent",
            "Data analizy",
            "Osoba odpowiedzialna",
            "Status zapytania",
          ],
        ],
        body: wykazZaptanieOfertowe.data.dane.map((el,index) => {
          return [
            index+1,
            el.Numer,
            el.Data_zapytania,
            el.Nr_zapytania,
            el.Nazwa_kontrahenta,
            el.Data_analizy,
            el.Osoba_odpowiedzialna,
            el.Status_zapytan,
          ];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "WYKAZ SZCZEGÓŁÓW DLA ZAPYTANIA OFERTOWEGO",
              colSpan: 6,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Numer zapytania ofertowego",
            "Nazwa",
            "Jednostka sprzedaży",
            "Ilość",
            "Uwagi",
          ],
        ],
        body: wykazSzczegolyZapytanieOfertowe.data.dane.map((el,index) => {
          return [
            index+1,
            el.Numer,
            el.Nazwa,
            el.Jedn_sprzedazy,
            el.Ilosc,
            el.Uwagi,
          ];
        }),
      });

      doc.save("zapytanie_ofertowe.pdf");
    } catch (error) {
      console.log("Bład", error);
    }
  },

  //------------------------------------------------------------------------------------------------------------------------------------
  generujOferty: async (Id) => {
    try {
      const [wykazOfert, wykazSzczegolyOfert] = await Promise.all([
        api.pobieranieOferty1(Id),
        api.pobieranieSzczegolyOferty1(Id),
      ]);

      const doc = new jsPDF("l", "mm", "a4");

      doc.setFont("Aleo-Regular");

      doc.text("Dane dla oferty", 14, 10);

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "WYKAZ DANYCH - OFERTA",
              colSpan: 9,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Numer oferty",
            "Data oferty",
            "Numer zapytania",
            "Data zapytania",
            "Kontrahent",
            "Data ważności",
            "Osoba odpowiedzialna",
            "Status oferty",
          ],
        ],
        body: wykazOfert.data.dane.map((el,index) => {
          return [
            index+1,
            el.Numer_oferty,
            el.Data_oferty,
            el.Numer,
            el.Data_zapytania,
            el.Nazwa_kontrahenta,
            el.Data_waznosci,
            el.Osoba_odpowiedzialna,
            el.Status_ofert,
          ];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "WYKAZ SZCZEGÓŁÓW DLA OFERTY",
              colSpan: 7,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Numer oferty",
            "Nazwa",
            "Jednostka sprzedaży",
            "Ilość",
            "Cena_jednostkowa",
            "Wartość oferty",
            "Uwagi",
          ],
        ],
        body: wykazSzczegolyOfert.data.dane.map((el,index) => {
          return [
            index+1,
            el.Numer_oferty,
            el.Nazwa,
            el.Jedn_sprzedazy,
            el.Ilosc,
            el.Cena_jednostkowa,
            el.Wartosc_oferty,
            el.Uwagi,
          ];
        }),
      });

      doc.save("oferta.pdf");
    } catch (error) {
      console.log("Bład", error);
    }
  },
  //________________________________________________________________________________________________
  generujZamowienia: async (Id) => {
    try {
      const [wykazZamowienia, wykazSzczegolyZamowienia] = await Promise.all([
        api.pobieranieZamowienia1(Id),
        api.pobieranieSzczegolyZamowienia1(Id),
      ]);

      const doc = new jsPDF("l", "mm", "a4");

      doc.setFont("Aleo-Regular");

      doc.text("Dane dla zamówienia", 14, 10);

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "WYKAZ DANYCH - ZAMÓWIENIA",
              colSpan: 8,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Numer wewnętrzny zamówienia",
            "Numer zamówienia klienta",
            "Data zamówienia",
            "Data realizacji",
            "Kontrahent",
            "Osoba odpowiedzialna",
            "Status zamówienia",
          ],
        ],
        body: wykazZamowienia.data.dane.map((el,index) => {
          return [
            index+1,
            el.Numer_zamowienia,
            el.Numer_zamowienia_klienta,
            el.Data_zamowienia,
            el.Data_realizacji,
            el.Nazwa_kontrahenta,
            el.Osoba_odpowiedzialna,
            el.Status_zamowien,
          ];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "WYKAZ SZCZEGÓŁÓW DLA ZAMÓWIENIA",
              colSpan: 7,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Numer zamówienia",
            "Nazwa",
            "Jednostka sprzedaży",
            "Ilość",
            "Cena jednostkowa",
            "Wartość zamówienia",
          ],
        ],
        body: wykazSzczegolyZamowienia.data.dane.map((el,index) => {
          return [
            index+1,
            el.Numer_zamowienia,
            el.Nazwa,
            el.Jedn_sprzedazy,
            el.Ilosc,
            el.Cena_jednostkowa,
            el.Wartosc_oferty,
          ];
        }),
      });
      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "WYKAZ DANYCH - UZUPEŁNIAJĄCYCH",
              colSpan: 7,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Numer wewnętrzny zamówienia",
            "Data zamówienia",
            "Numer wewnętrzny oferty",
            "Data oferty",
            "Numer wewnętrzny zapytania",
            "Data zapytania",
          ],
        ],
        body: wykazZamowienia.data.dane.map((el,index) => {
          return [
            index+1,
            el.Numer_zamowienia,
            el.Data_zamowienia,
            el.Numer_oferty,
            el.Data_oferty,
            el.Numer,
            el.Data_zapytania,
          ];
        }),
      });

      doc.save("zamowienie.pdf");
    } catch (error) {
      console.log("Bład", error);
    }
  },

  //________________________________________________________________________________________________
  generujRaportyDoZamowienia: async (Id) => {
    try {
      const [wykazZamowienia, wykazSzczegolyZamowienia, wykazRaportowDoZamowienia] = await Promise.all([
        api.pobieranieZamowienia1(Id),
        api.pobieranieSzczegolyZamowienia1(Id),
        api.pobieranieRaportyDoZamowienia1(Id),
      ]);

      const doc = new jsPDF("l", "mm", "a4");

      doc.setFont("Aleo-Regular");

      doc.text("Dane dla zamówienia", 14, 10);

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "WYKAZ DANYCH - ZAMÓWIENIA",
              colSpan: 8,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Numer wewnętrzny zamówienia",
            "Numer zamówienia klienta",
            "Data zamówienia",
            "Data realizacji",
            "Kontrahent",
            "Osoba odpowiedzialna",
            "Status zamówienia",
          ],
        ],
        body: wykazZamowienia.data.dane.map((el,index) => {
          return [
            index+1,
            el.Numer_zamowienia,
            el.Numer_zamowienia_klienta,
            el.Data_zamowienia,
            el.Data_realizacji,
            el.Nazwa_kontrahenta,
            el.Osoba_odpowiedzialna,
            el.Status_zamowien,
          ];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "WYKAZ SZCZEGÓŁÓW DLA ZAMÓWIENIA",
              colSpan: 7,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Numer zamówienia",
            "Nazwa",
            "Jednostka sprzedaży",
            "Ilość",
            "Cena jednostkowa",
            "Wartość zamówienia",
          ],
        ],
        body: wykazSzczegolyZamowienia.data.dane.map((el,index) => {
          return [
            index+1,
            el.Numer_zamowienia,
            el.Nazwa,
            el.Jedn_sprzedazy,
            el.Ilosc,
            el.Cena_jednostkowa,
            el.Wartosc_oferty,
          ];
        }),
      });
      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "WYKAZ DANYCH - UZUPEŁNIAJĄCYCH",
              colSpan: 7,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Numer wewnętrzny zamówienia",
            "Data zamówienia",
            "Numer wewnętrzny oferty",
            "Data oferty",
            "Numer wewnętrzny zapytania",
            "Data zapytania",
          ],
        ],
        body: wykazZamowienia.data.dane.map((el,index) => {
          return [
            index+1,
            el.Numer_zamowienia,
            el.Data_zamowienia,
            el.Numer_oferty,
            el.Data_oferty,
            el.Numer,
            el.Data_zapytania,
          ];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "WYKAZ RAPORTÓW DO ZAMÓWIENIA",
              colSpan: 10,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Numer wewnętrzny raportu",
            "Data raportu",
            "Numer wewnętrzny zamówienia",
            "Data zamówienia",
            "Nazwa wyrobu/usługi",
            "Osoba odpowiedzialna",
            "Ilość zwolniona",
            "Data zwolnienia",
            "Osoba dokonująca zwolnienia",
          ],
        ],
        body: wykazRaportowDoZamowienia.data.dane.map((el,index) => {
          return [
            index+1,
            el.Numer_raportu,
            el.Data_raportu,            
            el.Numer_zamowienia,
            el.Data_zamowienia,
            el.Nazwa,
            el.Osoba_odpowiedzialna,
            el.Ilosc_zwalniana,
            el.Data_zwolnienia === '0000-00-00' ? '' : el.Data_zwolnienia,
            el.Osoba_zwalniajaca,
          ];
        }),
      });

      doc.save("zamowienie.pdf");
    } catch (error) {
      console.log("Bład", error);
    }
  },
 //________________________________________________________________________________________________
 generujRaportDoZamowienia: async (Id) => {
  try {
    const [wykazRaportowDoZamowienia, wykazRaportowSzczegoly] = await Promise.all([

      api.pobieranieRaportyDoZamowienia2(Id),
      api.pobieranieRaportySzczegolyDoZamowienia2(Id)
    ]);

    const doc = new jsPDF("l", "mm", "a4");

    doc.setFont("Aleo-Regular");

    doc.text("RAPORT PRODUKCYJNY", 14, 10);

   

    doc.autoTable({
      headStyles: {
        overflow: "linebreak",
        fontSize: 9,
        font: "Aleo-Regular",
        fillColor: "#e3e7e6",
        textColor: "black",
        lineColor: "black",
        lineWidth: 0.1,
      },
      bodyStyles: {
        overflow: "linebreak",
        fontSize: 9,
        font: "Aleo-Regular",
        fillColor: "#e3e7e6",
        textColor: "black",
        lineColor: "black",
        lineWidth: 0.1,
      },

      head: [
        [
          {
            content: "RAPORT PRODUKCYJNY DO ZAMÓWIENIA",
            colSpan: 10,
            styles: {
              halign: "center",
              fillColor: "silver",
              fontStyle: "bold",
            },
          },
        ],
        [
          "Lp",
          "Numer wewnętrzny raportu",
          "Data raportu",
          "Numer wewnętrzny zamówienia",
          "Data zamówienia",
          "Nazwa wyrobu/usługi",
          "Osoba odpowiedzialna",
          "Ilość zwolniona",
          "Data zwolnienia",
          "Osoba dokonująca zwolnienia",
        ],
      ],
      body: wykazRaportowDoZamowienia.data.dane.map((el,index) => {
        return [
          index+1,
          el.Numer_raportu,
          el.Data_raportu,            
          el.Numer_zamowienia,
          el.Data_zamowienia,
          el.Nazwa,
          el.Osoba_odpowiedzialna,
          el.Ilosc_zwalniana,
          el.Data_zwolnienia === '0000-00-00' ? '' : el.Data_zwolnienia,
          el.Osoba_zwalniajaca,
        ];
      }),
    });

    doc.autoTable({
      headStyles: {
        overflow: "linebreak",
        fontSize: 9,
        font: "Aleo-Regular",
        fillColor: "#e3e7e6",
        textColor: "black",
        lineColor: "black",
        lineWidth: 0.1,
      },
      bodyStyles: {
        overflow: "linebreak",
        fontSize: 9,
        font: "Aleo-Regular",
        fillColor: "#e3e7e6",
        textColor: "black",
        lineColor: "black",
        lineWidth: 0.1,
      },

      head: [
        [
          {
            content: "WYKAZ SZCZEGÓŁÓW DO RAPORTÓW DLA ZAMÓWIENIA",
            colSpan: 8,
            styles: {
              halign: "center",
              fillColor: "silver",
              fontStyle: "bold",
            },
          },
        ],
        [
          "Lp",
          "Nazwa działania",
          "Jednostka",
          "Ilość",
          "Osoba wykonująca",
          "Status kontroli",
          "Wynik pomiaru",
          "Uwaga"

        ],
      ],
      body: wykazRaportowSzczegoly.data.dane.map((el,index) => {
        return [
          index+1,
          el.Nazwa_dzialania,
          el.Jedn_sprzedazy,            
          el.Ilosc,
          el.Osoba_wykonujaca,
          el.Status_kontroli,
          el.Wynik_pomiaru,
          el.Uwaga,

        ];
      }),
    });

    doc.save("raport_produkcyny.pdf");
  } catch (error) {
    console.log("Bład", error);
  }
},
  //________________________________________________________________________________________________
  generujZlecenieWewnetrzne: async (Id) => {
    try {
      const [wykazZlecen, wykazSzczegolyZlecenie] = await Promise.all([
        api.pobieranieZleceniaMagazyn1(Id),
        api.pobieranieZleceniaMagazynSzczegoly1(Id),
      ]);

      const doc = new jsPDF("l", "mm", "a4");

      doc.setFont("Aleo-Regular");

      doc.text("Dane dla zlecenia wewnętrznego", 14, 10);

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "WYKAZ DANYCH - ZLECENIE WEWNĘTRZNE",
              colSpan: 6,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Numer zlecenia",
            "Data zlecenia",
            "Data zakonczenia",
            "Osoba odpowiedzialna",
            "Status zlecenia",
          ],
        ],
        body: wykazZlecen.data.dane.map((el,index) => {
          return [
            index+1,
            el.Numer,
            el.Data_zlecenia,
            el.Data_zakonczenia === "0000-00-00"
              ? ""
              : el.Data_zakonczenia,
            el.Osoba_odpowiedzialna,
            el.Status_zlecenia,
          ];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "WYKAZ SZCZEGÓŁÓW DLA ZLECENIA WEWNĘTRZNEGO",
              colSpan: 6,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Numer zlecenia",
            "Nazwa wyrobu/usługi",
            "Jednostka sprzedaży",
            "Ilość",
            "Uwagi",
          ],
        ],
        body: wykazSzczegolyZlecenie.data.dane.map((el,index) => {
          return [
            index+1,
            el.Numer,
            el.Nazwa,
            el.Jedn_sprzedazy,
            el.Ilosc,
            el.Uwagi,
          ];
        }),
      });
     
      doc.save("zlecenia_wewnetrzne.pdf");
    } catch (error) {
      console.log("Bład", error);
    }
  },
//______________________________________________________________________________________________________
  generujZlecenieWewnetrzneZRaportami: async (Id) => {
    try {
      const [wykazZlecen, wykazSzczegolyZlecenie, wykazRaportyMagazyn] = await Promise.all([
        api.pobieranieZleceniaMagazyn1(Id),
        api.pobieranieZleceniaMagazynSzczegoly1(Id),
        api.pobieranieRaportyMagazyn1(Id),
      ]);

      const doc = new jsPDF("l", "mm", "a4");

      doc.setFont("Aleo-Regular");

      doc.text("Dane dla zlecenia wewnętrznego wraz z raportami", 14, 10);

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "WYKAZ DANYCH - ZLECENIE WEWNĘTRZNE",
              colSpan: 6,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Numer zlecenia",
            "Data zlecenia",
            "Data zakonczenia",
            "Osoba odpowiedzialna",
            "Status zlecenia",
          ],
        ],
        body: wykazZlecen.data.dane.map((el,index) => {
          return [
            index+1,
            el.Numer,
            el.Data_zlecenia,
            el.Data_zakonczenia === "0000-00-00"
              ? ""
              : el.Data_zakonczenia,
            el.Osoba_odpowiedzialna,
            el.Status_zlecenia,
          ];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "WYKAZ SZCZEGÓŁÓW DLA ZLECENIA WEWNĘTRZNEGO",
              colSpan: 6,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Numer zlecenia",
            "Nazwa wyrobu/usługi",
            "Jednostka sprzedaży",
            "Ilość",
            "Uwagi",
          ],
        ],
        body: wykazSzczegolyZlecenie.data.dane.map((el,index) => {
          return [
            index+1,
            el.Numer,
            el.Nazwa,
            el.Jedn_sprzedazy,
            el.Ilosc,
            el.Uwagi,
          ];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "WYKAZ RAPORTÓW PRODUKCYJNYCH DLA ZLECENIA WEWNĘTRZNEGO",
              colSpan: 8,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Numer zlecenia",
            "Numer raportu",
            "Data raportu",
            "Nazwa wyrobu/usługi",
            "Ilość zwolniona",
            "Data zwolnienia",
            "Osoba zwalniająca",

          ],
        ],
        body: wykazRaportyMagazyn.data.dane.map((el,index) => {
          return [
            index+1,
            el.Numer,
            el.Numer_raportu,
            el.Data_raportu,
            el.Nazwa,
            el.Ilosc_zwalniana,
            el.Data_zwolnienia === "0000-00-00"
            ? ""
            : el.Data_zwolnienia,
            el.Osoba_zwalniajaca,

          ];
        }),
      });
     
      doc.save("zlecenia_wewnetrzne.pdf");
    } catch (error) {
      console.log("Bład", error);
    }
  },
  //______________________________________________________________________________
  generujRaportDoZleceniaWewnetrznego: async (Id) => {
    try {
      const [wykazRaportyMagazyn, wykazRaportSzczegoly] = await Promise.all([
        api.pobieranieRaportyMagazyn2(Id),
        api.pobieranieRaportySzczegolyMagazyn2(Id),
      ]);

      const doc = new jsPDF("l", "mm", "a4");

      doc.setFont("Aleo-Regular");

      doc.text("RAPORT PRODUKCYJNY", 14, 10);

 
      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "RAPORT PRODUKCYJNY DLA ZLECENIA WEWNĘTRZNEGO",
              colSpan: 9,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Numer raportu",
            "Data raportu",
            "Numer zlecenia",
            "Data zlecenia",
            "Nazwa wyrobu/usługi",
            "Ilość zwolniona",
            "Data zwolnienia",
            "Osoba zwalniająca",

          ],
        ],
        body: wykazRaportyMagazyn.data.dane.map((el,index) => {
          return [
            index+1,
            el.Numer_raportu,
            el.Data_raportu,
            el.Numer,
            el.Data_zlecenia,
            el.Nazwa,
            el.Ilosc_zwalniana,
            el.Data_zwolnienia === "0000-00-00"
            ? ""
            : el.Data_zwolnienia,
            el.Osoba_zwalniajaca,

          ];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
  
        head: [
          [
            {
              content: "WYKAZ SZCZEGÓŁÓW DO RAPORTÓW DLA ZAMÓWIENIA",
              colSpan: 8,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Nazwa działania",
            "Jednostka",
            "Ilość",
            "Osoba wykonująca",
            "Status kontroli",
            "Wynik pomiaru",
            "Uwagi",
  
          ],
        ],
        body: wykazRaportSzczegoly.data.dane.map((el,index) => {
          return [
            index+1,
            el.Nazwa_dzialania,
            el.Jedn_sprzedazy,            
            el.Ilosc,
            el.Osoba_wykonujaca,
            el.Status_kontroli,
            el.Wynik_pomiaru,
            el.Uwaga,
  
          ];
        }),
      });
  
     
      doc.save("zlecenia_wewnetrzne.pdf");
    } catch (error) {
      console.log("Bład", error);
    }
  },

   //______________________________________________________________________________
   generujRaportRW: async (Id) => {
    try {
      const [wykazRaportyRW, wykazSzczegolyRW] = await Promise.all([
        api.pobieranieZamowieniaWZ1(Id),
        api.pobieranieZamowieniaSzczegolyWZ1(Id),

      ]);

      const doc = new jsPDF("l", "mm", "a4");

      doc.setFont("Aleo-Regular");

      doc.text("RAPORT PRODUKCYJNY", 14, 10);

 
      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "RAPORT PRODUKCYJNY DLA ZLECENIA WEWNĘTRZNEGO",
              colSpan: 5,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Kontrahent",
            "Numer RW",
            "Data RW",
            "Osoba odpowiedzialna",
 
        

          ],
        ],
        body: wykazRaportyRW.data.dane.map((el,index) => {
          return [
            index+1,
            el.Nazwa_kontrahenta,
            el.Numer_WZ,
            el.Data_WZ,
            el.Osoba_odpowiedzialna,


          ];
        }),
      });

      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            {
              content: "RAPORT PRODUKCYJNY DLA ZLECENIA WEWNĘTRZNEGO",
              colSpan: 8,
              styles: {
                halign: "center",
                fillColor: "silver",
                fontStyle: "bold",
              },
            },
          ],
          [
            "Lp",
            "Numer raportu",
            "Data_raportu",
            "Numer zlecenia",
            "Data zlecenia",
            "Nazwa wyrobu/usługi",
            "Jednostka",
            "Ilość",
 
        

          ],
        ],
        body: wykazSzczegolyRW.data.dane.map((el,index) => {
          return [
            index+1,
            el.Numer_raportu,
            el.Data_raportu,
            el.Numer,
            el.Data_zlecenia,
            el.Nazwa,
            el.Jedn_sprzedazy,
            el.Ilosc,


          ];
        }),
      });
  
     
      doc.save("zlecenia_wewnetrzne.pdf");
    } catch (error) {
      console.log("Bład", error);
    }
  },
  //-------------------------------------------------------------

  // generujRaportPrzegladZarzadzania: async (Id) => {
  //   try {
  //     const [wykazDzialan] = await Promise.all([
  //       api.pobieranieDzialaniaPozaAudit(Id),
  //     ]);

  //     const doc = new jsPDF("l", "mm", "a4");

  //     doc.setFont("Aleo-Regular");

  //     const przygotujDane = () => {
  //       const body = [];
  //       this.wykazDzialan.data.dane.forEach((el) => {

  //         body.push(...[
  //           { nazwa: key, wartosc: value },
  //           { nazwa: key, wartosc: value },
  //         ]);

  //       });
  //       return body;
  //     };

  //     doc.text(
  //       "Wykaz maszyn/urządzeń podelgających pod dozór techniczny",
  //       14,
  //       10
  //     );
  //     doc.autoTable({
  //       headStyles: {
  //         overflow: "linebreak",
  //         fontSize: 9,
  //         font: "Aleo-Regular",
  //         lineColor: "black",
  //         lineWidth: 0.1,
  //       },
  //       bodyStyles: {
  //         overflow: "linebreak",
  //         fontSize: 9,
  //         font: "Aleo-Regular",
  //         lineColor: "black",
  //         lineWidth: 0.1,
  //       },

  //       head: [{ nazwa: "Nazwa", wartosc: "Wartosc" }],
  //       body: przygotujDane(),
  //       showHead: false,
  //       columnStyles: {
  //         nazwa: {
  //           fillColor: [41, 128, 185],
  //           textColor: 255,
  //           fontStyle: "bold",
  //         },
  //       },
  //     });
  //     doc.save("dzialania_po_za_auditowe.pdf");
  //   } catch (error) {
  //     console.log("Bład", error);
  //   }
  // },
};
