<template>
  <div style="margin-left:auto; margin-right:auto; margin-top:5vh">
    <div class="ElementyWyboru">
      <b-container fluid class="p-0">
    
            <KafelekWyboru
              sciezkaZdjecia="Zdjecia/o-logowanie.jpg"
              opisZdjecia="FajnaFotka"
              linkDoStrony="formularz_dodawanie_planu_szkolen"
              podpisPodKafelkiem="LOGOWANIE DO SYSTEMU"
              @click="
                pokazDialog = true;
                typAktywnegoFormularza = 'formularz_logowanie';
                obiektKonfiguracyjny = {
                  Id: null,
                  akcja: 'dodawanie',
                };
              "
            />


          <button class="button" @click="pokazPDF_INFO">POLITYKA PRYWATNOŚCI</button>   
          <button class="button" @click="pokazPDF_INFO1">OGÓLNE WARUNKI KORZYSTANIA Z SYSTEMU</button>  

        <modalHR
          v-if="pokazDialog"
          :rodzajFormularza="typAktywnegoFormularza"
          :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
          :obiektKonfiguracyjny="obiektKonfiguracyjny"
        />
      </b-container>
    </div>
  </div>
</template>

<script>
import KafelekWyboru from "./Kafelek_wyboru.vue";
import modalHR from "../strona-formularze/modalHR.vue";
import "jspdf-autotable";
export default {
  name: "ElementyWyboru",
  data: () => ({
    pokazDialog: false,
    typAktywnegoFormularza: null,
    obiektKonfiguracyjny: null,
  }),
  components: {
    KafelekWyboru,
    modalHR,
  },
  methods: {
    funkcjaZamykajacaDialog(czyZmienicHaslo) {
      if (czyZmienicHaslo) {
        this.pokazDialog = true;
        this.typAktywnegoFormularza = 'formularz_logowanie_zmiana_hasla';
        this.obiektKonfiguracyjny = null;
      } else {
        this.pokazDialog = false;
        this.typAktywnegoFormularza = null;
        this.obiektKonfiguracyjny = null;
      }
    },
     pokazPDF_INFO() {
      //dopisane
      this.pokazDialog = true;
      this.typAktywnegoFormularza = "pokaz_PDF_INFO";
      this.obiektKonfiguracyjny = {
        Nazwa_instrukcji: "INFO-1",
      };
    },
    pokazPDF_INFO1() {
      //dopisane
      this.pokazDialog = true;
      this.typAktywnegoFormularza = "pokaz_PDF_INFO";
      this.obiektKonfiguracyjny = {
        Nazwa_instrukcji: "INFO-2",
      };
    },
  },
};
</script>

<style scoped>
.ElementyWyboru {
  margin-top: 1%;
  display: flex;
  flex-flow: row wrap;
}
.button {
  margin-right: 15px;
  margin-top: 4vh;
  border: 3px white ridge;
  font-weight: bold;
  font-size: 1.4vh;
  min-width: 9vw;
}
.button:hover {
  background-color: #727171;
  text-decoration: none;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  color: white;
}
</style>
