<template>
  <div>
  <TabelaIT/>
  </div>
</template>

<script>
import TabelaIT from '../../komponenty/strona-tabela-komponenty/WST_wyposazenie_IT/tabela_IT.vue'


export default {
  name: 'StronaTabelaIT',
  components: {
    TabelaIT,
  }

}
</script>
