<template>
  <md-dialog :md-active="true" @md-clicked-outside="funkcjaZamykajacaDialog">

    <FormularzDodawanieZapytania
      v-if="rodzajFormularza === 'formularz_dodawanie_zapytania'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />

    <FormularzDodawanieOferty
      v-if="rodzajFormularza === 'formularz_dodawanie_oferty'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />

    <FormularzDodawanieZamowienia
      v-if="rodzajFormularza === 'formularz_dodawanie_zamowienia'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieZamowieniaWZ
      v-if="rodzajFormularza === 'formularz_dodawanie_WZ'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />

    <FormularzDodawanieDzialania
      v-if="rodzajFormularza === 'formularz_dodawanie_dzialania'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieRaportu
      v-if="rodzajFormularza === 'formularz_dodawanie_raportu'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieRaportuZakonczone
      v-if="rodzajFormularza === 'formularz_dodawanie_raportu_zakonczone'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
     <FormularzDodawanieRaportuMagazyn
      v-if="rodzajFormularza === 'formularz_dodawanie_raportu_magazyn'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieRaportuMagazynZakonczone
      v-if="rodzajFormularza === 'formularz_dodawanie_raportu_magazyn_zakonczone'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />

    <FormularzDodawanieGrupy
      v-if="rodzajFormularza === 'formularz_dodawanie_grupy'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieWyrobu
      v-if="rodzajFormularza === 'formularz_dodawanie_wyrobu'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieDostawcy
      v-if="rodzajFormularza === 'formularz_dodawanie_dostawcy'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieZakupu
      v-if="rodzajFormularza === 'formularz_dodawanie_zakupu'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawaniePrzegladuDostawcow
      v-if="rodzajFormularza === 'formularz_dodawanie_przegladu_dostawcow'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieZleceniaMagazyn
      v-if="rodzajFormularza === 'formularz_dodawanie_zlecenia_magazyn'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
     <FormularzDodawanieReklamacji
      v-if="rodzajFormularza === 'formularz_dodawanie_reklamcje'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieReklamacjiDzialania
      v-if="rodzajFormularza === 'formularz_dodawanie_reklamcje_dzialania'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <Usuwanie
      v-else-if="rodzajFormularza === 'usuwanie'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <PokazPDF
      v-else-if="rodzajFormularza === 'pokaz_PDF'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <PokazPDFINFO
      v-else-if="rodzajFormularza === 'pokaz_PDF_INFO'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
  </md-dialog>
</template>

<script>
import FormularzDodawanieZapytania from "./SO_zapytania_ofertowe/formularz_dodawanie_zapytania";
import FormularzDodawanieOferty from "./SO_oferty/formularz_dodawanie_oferty";
import FormularzDodawanieZamowienia from "./SO_zamowienia/formularz_dodawanie_zamowienia";
import FormularzDodawanieZamowieniaWZ from "./SO_zlecenia_na_magazyn/formularz_dodawanie_WZ";
import FormularzDodawanieDzialania from "./SO_realizacja/formularz_dodawanie_dzialania";
import FormularzDodawanieRaportu from "./SO_realizacja/formularz_dodawanie_raportu";
import FormularzDodawanieRaportuZakonczone from "./SO_realizacja/formularz_dodawanie_raportu_zakonczone";
import FormularzDodawanieRaportuMagazyn from "./SO_realizacja_magazyn/formularz_dodawanie_raportu_magazyn";
import FormularzDodawanieRaportuMagazynZakonczone from "./SO_realizacja_magazyn/formularz_dodawanie_raportu_magazyn_zakonczone";
import FormularzDodawanieGrupy from "./SO_wyroby/formularz_dodawanie_grupy";
import FormularzDodawanieWyrobu from "./SO_wyroby/formularz_dodawanie_wyrobu";
import FormularzDodawanieDostawcy from "./SO_zakupy/formularz_dodawanie_dostawcy";
import FormularzDodawanieZakupu from "./SO_zakupy/formularz_dodawanie_zakupu";
import FormularzDodawaniePrzegladuDostawcow from "./SO_zakupy/formularz_dodawanie_przegladu_dostawcow";
import FormularzDodawanieZleceniaMagazyn from "./SO_zlecenia_na_magazyn/formularz_dodawanie_zlecenia_magazyn";
import FormularzDodawanieReklamacji from "./SO_reklamacje/formularz_dodawanie_reklamcje";
import FormularzDodawanieReklamacjiDzialania from "./SO_reklamacje/formularz_dodawanie_reklamcje_dzialania";
import Usuwanie from "./formularz_usuwanie";
import PokazPDF from "./formularz_pokaz_pdf";
import PokazPDFINFO from "./formularz_pokaz_pdf_INFO";

export default {
  name: "modalSO",
  components: {
    FormularzDodawanieZapytania,
    FormularzDodawanieOferty,
    FormularzDodawanieZamowienia,
    FormularzDodawanieZamowieniaWZ,
    FormularzDodawanieDzialania,
    FormularzDodawanieRaportu,
    FormularzDodawanieRaportuZakonczone,
    FormularzDodawanieRaportuMagazyn,
    FormularzDodawanieRaportuMagazynZakonczone,
    FormularzDodawanieGrupy,
    FormularzDodawanieWyrobu,
    FormularzDodawanieDostawcy,
    FormularzDodawaniePrzegladuDostawcow,
    FormularzDodawanieReklamacji,
    FormularzDodawanieReklamacjiDzialania,
    FormularzDodawanieZakupu,
    FormularzDodawanieZleceniaMagazyn,
    PokazPDFINFO,

    Usuwanie,
    PokazPDF,
  },
  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    rodzajFormularza: {
      type: String,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
};
</script>



