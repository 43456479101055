<template>
  <div>
  <TabelaSamochodyCiezarowe />
  </div>
</template>

<script>
import TabelaSamochodyCiezarowe from '../../komponenty/strona-tabela-komponenty/WST_samochody_ciezarowe/tabela_samochody_ciezarowe.vue'


export default {
  name: 'StronaTabelaSamochodyCiezarowe',
  components: {
    TabelaSamochodyCiezarowe,
  }

}
</script>
