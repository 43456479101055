<template>
  <div class="pasek">
    <Nawigacja />
    <ZalogowanyUzytkownik v-if="user" :user="user" />

  </div>
</template>

<script>
import Nawigacja from "./pasek-gorny-komponenty/Nawigacja.vue";
import ZalogowanyUzytkownik from "./pasek-gorny-komponenty/Zalogowany_uzytkownik.vue";
import {userService} from "./../serwisy/user.serwis";
export default {
  name: "PasekGorny",
  components: {
    Nawigacja,
    ZalogowanyUzytkownik,
  },
  data:() => ({
    user: null
  }),
  mounted(){
    userService.getUserObservable().subscribe(value => {
      this.user = value;
    })
  }
};
</script>

<style scoped>
.pasek {
  width: 100%;
  height: 70px;
  background-color: #606060;
  margin: 0 auto;
  margin-top: 10px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
@media print {
  .pasek {
    display: none;
  }
}
</style>
