<template >
  <div>
  <TabelaWykazDaneZatrudnieniaPracownika />
  </div>
</template>

<script>
import TabelaWykazDaneZatrudnieniaPracownika from '../../komponenty/strona-tabela-komponenty/HR_pracownicy/tabela_wykaz_danych_zatrudnienia_pracownika.vue'


export default {
  name: 'StronaTabelaDaneZatrudnieniaPracownika',
  components: {
    TabelaWykazDaneZatrudnieniaPracownika,
    
  }

}
</script>

