<template>
  <div class="FORM_USTLOG">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI PRZEGLĄDU DOKUMENTACJI SYSTEMU ZARZĄDZANIA"
                : "FORMULARZ DODAWANIA PRZEGLĄDU DOKUMENTACJI SYSTEMU ZARZĄDZANIA"
            }}
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M4" :class="getValidationClass('Firma')">
                <label for="Firma">UPRAWNIENIA SYSTEMU - FIRMA</label>

                <md-select
                  v-model="form.Firma"
                  :disabled="sending || czyJestWTrybieEdycji"
                  name="Firma"
                  id="Firma"
                  autocomplete="Firma"
                  md-dense
                  readonly
                >
                  <md-option
                    v-for="(firma, index) in Firmy"
                    :key="index"
                    :value="firma.Id"
                    >{{ ` ${firma.NIP} - ${firma.Nazwa} ` }}</md-option
                  >
                </md-select>
                <span class="md-error" v-if="!$v.form.Firma.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M4"
                :class="getValidationClass('Id_typu_uzytkownika')"
              >
                <label for="Id_typu_uzytkownika"
                  >Wybór rodzaju użytkownika</label
                >

                <md-select
                  v-model="form.Id_typu_uzytkownika"
                  :disabled="sending"
                  name="Id_typu_uzytkownika"
                  id="Id_typu_uzytkownika"
                  autocomplete="Id_typu_uzytkownika"
                  md-dense
                >
                  <md-option
                    v-for="(uzytkownik, index) in Typy_uzytkownikow"
                    :key="index"
                    :value="uzytkownik.Id"
                    >{{ ` ${uzytkownik.Nazwa} ` }}</md-option
                  >
                </md-select>
                <span
                  class="md-error"
                  v-if="!$v.form.Id_typu_uzytkownika.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('Imie')">
                <label for="Imie">Imię</label>
                <md-input
                  md-counter="30"
                  name="Imie"
                  id="Imie"
                  v-model="form.Imie"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Imie.required"
                  >Pole jest wymagane</span
                >
                <span class="md-error" v-else-if="!$v.form.Imie.maxlength"
                  >Wymagana ilość znaków od 1 do 30</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('Nazwisko')">
                <label for="Nazwisko">Nazwisko</label>
                <md-input
                  md-counter="30"
                  name="Nazwisko"
                  id="Nazwisko"
                  v-model="form.Nazwisko"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Nazwisko.required"
                  >Pole jest wymagane</span
                >
                <span class="md-error" v-else-if="!$v.form.Nazwisko.maxlength"
                  >Wymagana ilość znaków od 1 do 30</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div v-if="!czyJestWTrybieEdycji" class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('Login')">
                <label for="Login">e-mail</label>
                <md-input
                  md-counter="50"
                  name="Login"
                  id="Login"
                  v-model="form.Login"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Login.required"
                  >Pole jest wymagane</span
                >
                <span class="md-error" v-else-if="!$v.form.Login.maxlength"
                  >Wymagana ilość znaków od 1 do 50</span
                >
              </md-field>
            </div>

            <div v-if="!czyJestWTrybieEdycji" class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('Haslo')">
                <label for="Haslo">Hasło startowe</label>
                <md-input
                  type="password"
                  md-counter="30"
                  name="Haslo"
                  id="Haslo"
                  v-model="form.Haslo"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Haslo.required"
                  >Pole jest wymagane</span
                >
                <span class="md-error" v-else-if="!$v.form.Haslo.maxlength"
                  >Wymagana ilość znaków od 1 do 30</span
                >
              </md-field>
            </div>
          </div>

          <div
            style="padding-left:3vw; margin-top:20px; font-weight:700; color:red; font-size:1.8vh"
          >
            UPRAWNIENIA DLA POSZCZEGLNYCH MODUŁÓW
          </div>

          <div v-for="(modul, index) in Moduly_submoduly" :key="index">
            <div class="md-layout md-gutter">
              <div class="md-layout-item md-small-size-100">
                <td
                  style="width:45vw; padding-left:3vw; font-weight:700; padding-top:1vh; padding-bottom:1vh; color: blue"
                >
                  ROZWIŃ UPRAWNIENIA DLA MODUŁU {{ modul.Nazwa }}
                </td>
                <td style="padding-left:3vw">
                  <b-form-checkbox v-model="modul.czyJestOtwarty"
                    >TAK</b-form-checkbox
                  >
                </td>
              </div>
            </div>

            <div v-if="modul.czyJestOtwarty">
              <div
                class="md-layout md-gutter"
                v-for="(submodul, index) in modul.submoduly"
                :key="index"
              >
                <div class="md-layout-item md-small-size-100">
                  <td style="width:70%; padding-left:3vw">
                    {{ submodul.Nazwa }}
                  </td>

                  <td
                    style="padding-left:1vw"
                    v-for="(akcja, index) in Akcje"
                    :key="index"
                  >
                    <b-form-checkbox
                      v-model="submodul[`czyMoze${akcja.Nazwa_operacji}`]"
                      >{{ akcja.Nazwa_operacji }}</b-form-checkbox
                    >
                  </td>
                </div>
              </div>
            </div>
          </div>
        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

        <div style="text-align: center">
          <md-button type="submit" class="md-primary" :disabled="sending">
            {{
              czyJestWTrybieEdycji
                ? "ZATWIERDŹ - EDYTOWANY WPIS"
                : "ZATWIERDŹ - UTWÓRZ WPIS"
            }}
          </md-button>
        </div>
      </md-card>
    </form>
  </div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import minLength from "vuelidate/lib/validators/minLength";
import { api } from "../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_maszyny",

  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      Firma: null,
      Nazwisko: null,
      Imie: null,
      Login: null,
      Haslo: null,
      Id_typu_uzytkownika: null,
    },

    IdEdytowanegoElementu: null,
    userSaved: false,
    sending: false,
    lastUser: null,
    Typy_uzytkownikow: [],
    Moduly_submoduly: [],
    Akcje: [],
    Firmy: [],
    Uzytkownicy: [],
    objA: { name: "a" },
    objB: { name: "b" },
    radio: false,
  }),

  async created() {
    try {
      const [
        odpowiedz_Firma,
        odpowiedz_Uzytkownik,
        odpowiedz_DIM1,
        odpowiedz_DIM2,
        odpowiedz_DIM3,
        odpowiedz_DIM4,
      ] = await Promise.all([
        api.pobierzNazwa_organizacji(),
        api.pobierzNazwa_uzytkownika(),
        api.pobierzDIM1(),
        api.pobierzDIM2(),
        api.pobierzDIM3(),
        api.pobierzDIM4(),
      ]);

      this.Uzytkownicy = odpowiedz_Uzytkownik.data.dane;
      this.Firmy = odpowiedz_Firma.data.dane;
      this.Typy_uzytkownikow = odpowiedz_DIM1.data.dane;
      this.Akcje = odpowiedz_DIM4.data.dane;
      this.Moduly_submoduly = odpowiedz_DIM2.data.dane.map((modul) => ({
        ...modul,
        czyJestOtwarty: false,
        czyMaDostepDoModulu: false,
        submoduly: odpowiedz_DIM3.data.dane
          .filter(({ Id_czesc_systemu }) => Id_czesc_systemu == modul.Id)
          .map((submodul) => ({
            ...submodul,
            ...this.Akcje.reduce(
              (collector, { Nazwa_operacji }) => ({
                ...collector,
                [`czyMoze${Nazwa_operacji}`]: false,
              }),
              {}
            ),
          })),
      }));

      if (this.obiektKonfiguracyjny.akcja === "edycja") {
        //edycja
        const odpowiedz = await api.pobieranieUprawnieniaEdycja(
          this.obiektKonfiguracyjny.Id
        );

        // dane glowne

        const {
          UserId,
          Id_firma,
          Nazwisko,
          Imie,
        } = odpowiedz.data.daneUzytkownika[0];
        this.IdEdytowanegoElementu = UserId;
        this.form.Firma = Id_firma;
        this.form.Nazwisko = Nazwisko;
        this.form.Imie = Imie;

        // Typ uzytkownika
        const { Id_typu } = odpowiedz.data.daneRodzajUzytkownika[0];

        this.form.Id_typu_uzytkownika = Id_typu;

        // Typ dostep submoduly
        const { daneCzescDostepAkcja } = odpowiedz.data;

        this.Moduly_submoduly = this.Moduly_submoduly.map((modul) => ({
          ...modul,
          submoduly: modul.submoduly.map((submodul) => {
            const dostepyDoSubmodulu = daneCzescDostepAkcja.filter(
              (dostep) => dostep.Id_szczegolowe == submodul.Id
            );
            dostepyDoSubmodulu.forEach((dostep) => {
              const akcja = this.Akcje.find(
                (akcja) => akcja.Id === dostep.Id_typu
              );
              submodul[`czyMoze${akcja.Nazwa_operacji}`] = true;
            });

            return submodul;
          }),
        }));
      }
    } catch (error) {
      console.log(error);
    }
  },

  validations: {
    form: {
      Firma: {
        required,
      },
      Imie: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(30),
      },
      Nazwisko: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(30),
      },
      Haslo: {
        required: minLength(8),
        maxLength: maxLength(50),
      },
      Login: {
        required: false,
        maxLength: maxLength(50),
      },
      Id_typu_uzytkownika: {
        required,
      },
    },
  },
  computed: {
    // Id_kontrahent: function() {
    //   const { Nazwa_wybor_kontrahenta } = this.form;
    //   const znalezionyElement = this.Firmy.find(
    //     ({ nazwa1 }) => nazwa1 === Nazwa_wybor_kontrahenta
    //   );

    //   if (znalezionyElement) {
    //     return znalezionyElement.Id;
    //   }
    //   return null;
    // },
    // zapytaniaKontrahenci: function() {
    //   return this.Firmy.map((el) => el.nazwa1);
    // },

    czyJestWTrybieEdycji: function() {
      return this.obiektKonfiguracyjny.akcja === "edycja";
    },
  },

  methods: {
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();

      this.form.Firma = null;
      this.form.Nazwisko = null;
      this.form.Imie = null;
      this.form.Id_typu_uzytkownika = null;
    },

    async zapiszFormularz() {
      this.sending = true;

      if (this.czyJestWTrybieEdycji) {
        const daneDoWyslania = {
          Firma: this.form.Firma,
          Id_typu_uzytkownika: this.form.Id_typu_uzytkownika,
          Nazwisko: this.form.Nazwisko,
          Imie: this.form.Imie,
          Login: this.form.Login,
          Haslo: this.form.Haslo,
          Moduly: this.Moduly_submoduly.map((modul) => ({
            czyMaDostepDoModulu: modul.submoduly.some((submodul) => {
              let odpowiedz = false;
              Object.entries(submodul).forEach(([klucz, wartosc]) => {
                if (klucz.startsWith("czyMoze") && wartosc === true) {
                  odpowiedz = true;
                }
              });
              return odpowiedz;
            }),
            Id: modul.Id,
            submoduly: modul.submoduly.map((submodul) => {
              const wartosci = {
                Id: submodul.Id,
                Id_czesc_systemu: submodul.Id_czesc_systemu,
              };
              Object.entries(submodul).forEach(([klucz, wartosc]) => {
                if (klucz.startsWith("czyMoze")) {
                  wartosci[klucz] = wartosc;
                }
              });
              return wartosci;
            }),
          })),
        };

        api
          .zapiszUprawnieniaUpdate(daneDoWyslania, this.IdEdytowanegoElementu)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const daneDoWyslania = {
          Firma: this.form.Firma,
          Id_typu_uzytkownika: this.form.Id_typu_uzytkownika,
          Nazwisko: this.form.Nazwisko,
          Imie: this.form.Imie,
          Login: this.form.Login,
          Haslo: this.form.Haslo,
          Moduly: this.Moduly_submoduly.map((modul) => ({
            czyMaDostepDoModulu: modul.submoduly.some((submodul) => {
              let odpowiedz = false;
              Object.entries(submodul).forEach(([klucz, wartosc]) => {
                if (klucz.startsWith("czyMoze") && wartosc === true) {
                  odpowiedz = true;
                }
              });
              return odpowiedz;
            }),
            Id: modul.Id,
            submoduly: modul.submoduly.map((submodul) => {
              const wartosci = {
                Id: submodul.Id,
                Id_czesc_systemu: submodul.Id_czesc_systemu,
              };
              Object.entries(submodul).forEach(([klucz, wartosc]) => {
                if (klucz.startsWith("czyMoze")) {
                  wartosci[klucz] = wartosc;
                }
              });
              return wartosci;
            }),
          })),
        };

        api
          .zapiszNoweUprawnienia(daneDoWyslania)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    naszWalidacjaFormularza() {
      if (this.czyJestWTrybieEdycji) {
        return (
          !this.$v.form.Firma.$invalid &&
          !this.$v.form.Nazwisko.$invalid &&
          !this.$v.form.Imie.$invalid &&
          !this.$v.form.Id_typu_uzytkownika.$invalid
        );
      } else {
        return !this.$v.$invalid;
      }
    },

    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>

<style>
.FORM_USTLOG .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.FORM_USTLOG .md-layout .md-gutter {
  min-width: 55vw !important; /* szerokość modala*/
  max-width: 100% !important;
  min-height: 1vh; /* wysokość pomiędzy mierszami w modalu*/
}

.md-layout {
  padding-right: 0px !important;
}

.FORM_USTLOG .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}

.FORM_USTLOG .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 28px !important;
  margin: 10px 24px !important;
  padding-top: 35px !important;
}

.FORM_USTLOG .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}

.FORM_USTLOG {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
  overflow: auto !important;
}

.FORM_USTLOG .md-primary {
  border: 1px solid !important;

  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}

.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important;
  /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}

.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}

.FORM_USTLOG .md-list-item-text {
  font-size: 1.7vmin !important;
}

.FORM_USTLOG .md-card-actions .md-button {
  margin-right: 50px !important;
}
</style>
