<template>
  <div>
  <TabelaSamochodyOsobowePaliwo />
  </div>
</template>

<script>
import TabelaSamochodyOsobowePaliwo from '../../komponenty/strona-tabela-komponenty/WST_samochody_osobowe/tabela_samochody_osobowe_paliwo.vue'


export default {
  name: 'StronaTabelaSamochodyOsobowePaliwo',
  components: {
    TabelaSamochodyOsobowePaliwo,
  }

}
</script>
