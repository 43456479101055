export const elementyNawigacyjne = {

  menuStartowe: [
    {
      nazwa: "Wylogowanie z systemu",
      sciezka: "/wylogowanie",
    },
  ], 
  
  menuGlowne: [
    /*MENU STRONA GŁWNA*/
    {
      nazwa: "Strona glowna modułu QMD_SYS",
      sciezka: "/strona_glowna_modul_SYS",
    },
    {
      nazwa: "Strona glowna modułu QMD_WST",
      sciezka: "/strona_glowna_modul_WST",
    },
    {
      nazwa: "Strona glowna modułu QMD_HR",
      sciezka: "/strona_glowna_modul_HRB",
    },
    {
      nazwa: "Strona glowna modułu QMD_BHP",
      sciezka: "/strona_glowna_modul_BHP",
    },

    {
      nazwa: "Strona glowna modułu QMD_DOP",
      sciezka: "/strona_glowna_modul_DOP",
    },
    {
      nazwa: "Strona glowna modułu ustawień",
      sciezka: "/tabela_dostepu",
    },
    {
      nazwa: "Wylogowanie z systemu",
      sciezka: "/wylogowanie",
    },
  ],

  menuFAK: [
    {
      nazwa: "Wykaz wyrobów i usług",
      sciezka: "/wykaz_wyrobow",
    },
    {
      nazwa: "Wykaz kontrahentów",
      sciezka: "/tabela_kontrahenci",
    },
    {
      nazwa: "Zakupy",
      sciezka: "/kwalifikowanie_dostawcy",
    },
    {
      nazwa: "Projektowanie",
      sciezka: "/wykaz_projektow",
    },
    {
      nazwa: "Zapytania ofertowe",
      sciezka: "/zapytania_ofertowe",
    },
    {
      nazwa: "Oferty",
      sciezka: "/oferty",
    },
    {
      nazwa: "Zamówienia",
      sciezka: "/zamowienia",
    },
    {
      nazwa: "Realizacja do zamówienia",
      sciezka: "/raport_produkcyjny",
    },
    {
      nazwa: "Zlecenia wewnętrzne na magazyn",
      sciezka: "/zlecenie_magazyn",
    },
    {
      nazwa: "Realizacja na magazyn",
      sciezka: "/raport_produkcyjny_magazyn",
    },
    {
      nazwa: "Rozliczenie-wydanie z magazynu RW",
      sciezka: "/realizacja_WZ_magazyn",
    },
    {
      nazwa: "Reklamacje",
      sciezka: "/tabela_reklamacje",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],
  menuSYS: [
    {
      nazwa: "Kontekst organizacji",
      sciezka: "/kontekst_organizacji",
    },
    {
      nazwa: "Procesy systemu",
      sciezka: "/procesy",
    },
    {
      nazwa: "Dokumentacja",
      sciezka: "/dokumentacja",
    },
    {
      nazwa: "Instrukcje operacyjne",
      sciezka: "/instrukcje_operacyjne",
    },
    {
      nazwa: "Instrukcje środowiskowe",
      sciezka: "/instrukcje_srodowiskowe",
    },
    {
      nazwa: "Instrukcje BHP",
      sciezka: "/instrukcje_BHP",
    },
    {
      nazwa: "Ryzyka i szansa",
      sciezka: "/ryzyka_szanse",
    },
    {
      nazwa: "Zagrożenia BHP",
      sciezka: "/zagrozenia_bhp",
    },
    {
      nazwa: "Cele systemu",
      sciezka: "/cele_okres_realizacji",
    },
    {
      nazwa: "Wymagania regulacyjne",
      sciezka: "/wymagania_prawne",
    },
    {
      nazwa: "Aspekty środowiskowe",
      sciezka: "/aspekty",
    },
    {
      nazwa: "Audity wewnętrzne",
      sciezka: "/audity",
    },
    {
      nazwa: "Działania na niezgodności z auditów",
      sciezka: "/dzialania_audit",
    },
    {
      nazwa: "Działania na niezgodności po za auditowe",
      sciezka: "/dzialania_poza_auditowe",
    },
    {
      nazwa: "Przegląd zarządzania",
      sciezka: "/przeglad_zarzadzania",
    },
    {
      nazwa: "Zadowolenie klineta",
      sciezka: "/zadowolenie_klienta",
    },
    {
      nazwa: "Sprawozdawczość środowiskowa",
      sciezka: "/sprawozdawczosc_srodowiskowa",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuWST: [
    {
      nazwa: "Maszyny i urządzenia",
      sciezka: "/maszyny_i_urzadzenia",
    },
    {
      nazwa: "Maszyny i urządzenia dozorowane",
      sciezka: "/maszyny_i_urzadzenia_dozorowe",
    },
    {
      nazwa: "Wykaz elektronarzedzi",
      sciezka: "/tabela_elektronarzedzia",
    },
    {
      nazwa: "Elektronarzędzia",
      sciezka: "/tabela_elektronarzedzia",
    },
    {
      nazwa: "Wyposażenie IT",
      sciezka: "/tabela_IT",
    },
    {
      nazwa: "Budynki",
      sciezka: "/budynki",
    },
    {
      nazwa: "Samochody osobowe",
      sciezka: "/samochody_osobowe",
    },
    {
      nazwa: "Samochody ciężarowe",
      sciezka: "/samochody_ciezarowe",
    },
    {
      nazwa: "Wyposażenie kontrolno-pomiarowe",
      sciezka: "/sprzet_pomiarowy",
    },
    {
      nazwa: "Wyposażenie BHP",
      sciezka: "/wyposazenie_BHP",
    },
    {
      nazwa: "Wyposażenie P-POŻ",
      sciezka: "/wyposazenie_PPOZ",
    },
    {
      nazwa: "Generator świadectw sprawdzenia",
      sciezka: "/wykaz_sprzetu_pomiarowego",
    },
    {
      nazwa: "Emisja spalin",
      sciezka: "/wykaz_emisje",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],
  menuHRB: [
    {
      nazwa: "Pracownicy zatrudnieni",
      sciezka: "/pracownicy_zatrudnieni",
    },
    {
      nazwa: "Pracownicy zwolnienie",
      sciezka: "/pracownicy_zwolnieni",
    },
    // {
    //     nazwa: 'Ewidencja absencji',
    //     sciezka: '/pracownicy_absencja'
    // },
    {
      nazwa: "Generator zakresów OOiU",
      sciezka: "/wykaz_zakresow",
    },

    {
      nazwa: "Stanowiska pracy",
      sciezka: "/stanowiska",
    },
    {
      nazwa: "Szkolenia",
      sciezka: "/szkolenia",
    },
    {
      nazwa: "Skierowania na badania lekarskie",
      sciezka: "/wykaz_skierowan",
    },
    {
      nazwa: "Medycyna pracy",
      sciezka: "/medycyna_pracy",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],
  menuBHP: [
    {
      nazwa: "Ocena ryzyka zawodowego",
      sciezka: "/dokumentacja_ORZ",
    },
    {
      nazwa: "Badania środowiska pracy",
      sciezka: "/dokumentacja_BSP",
    },
    {
      nazwa: "Substancje niebezpieczne",
      sciezka: "/dokumentacja_substancje_niebezpieczne",
    },
    {
      nazwa: "Ocena stanu BHP",
      sciezka: "/ocena_stanu_BHP",
    },
    {
      nazwa: "Wypadki",
      sciezka: "/wypadki_w_pracy",
    },
    {
      nazwa: "Choroby zawodowe",
      sciezka: "/choroby_zawodowe",
    },
    {
      nazwa: "Zdarzenia potencjalnie niebezpieczne",
      sciezka: "/sytuacje_potencjalnie_niebezpieczne",
    },
    {
      nazwa: "Kontrole wewnętrzne BHP",
      sciezka: "/wykaz_kontroli_wewnetrznych",
    },
    {
      nazwa: "Kontrole zewnętrzne BHP",
      sciezka: "/wykaz_kontroli_zewnetrznych",
    },
    {
      nazwa: "Prace szczególnie niebezpieczne",
      sciezka: "/dokumentacja_prace_szczegolnie_niebezpieczne",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuHRBPZATRUD: [
    {
      nazwa: "Pracownicy zatrudnieni",
      sciezka: "/pracownicy_zatrudnieni",
    },
    {
      nazwa: "Pracownicy zwolnieni",
      sciezka: "/pracownicy_zwolnieni",
    },
    {
      nazwa: "Strona glowna modułu QMD_HRB",
      sciezka: "/strona_glowna_modul_HRB",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuHRBPZWOL: [
    {
      nazwa: "Pracownicy zatrudnieni",
      sciezka: "/pracownicy_zatrudnieni",
    },
    {
      nazwa: "Pracownicy zwolnieni",
      sciezka: "/pracownicy_zwolnieni",
    },
    {
      nazwa: "Strona glowna modułu QMD_HRB",
      sciezka: "/strona_glowna_modul_HRB",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuHRBPracownik: [
    {
      nazwa: "Pracownicy zatrudnieni",
      sciezka: "/pracownicy_zatrudnieni",
    },
    {
      nazwa: "Pracownicy zwolnieni",
      sciezka: "/pracownicy_zwolnieni",
    },

    {
      nazwa: "Strona glowna modułu QMD_HRB",
      sciezka: "/strona_glowna_modul_HRB",
    },

    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuHRBGeneratorZakresow: [
    {
      nazwa: "Wykaz zakresów OOiU",
      sciezka: "/wykaz_zakresow",
    },
    {
      nazwa: "Katalog części stałych zakresów",
      sciezka: "/zakres_katalog",
    },

    {
      nazwa: "Strona glowna modułu QMD_HRB",
      sciezka: "/strona_glowna_modul_HRB",
    },

    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuHRBStanowiska: [
    {
      nazwa: "Wykaz stanowisk pracy",
      sciezka: "/stanowiska",
    },
    {
      nazwa: "Przeglądy stanowisk pracy",
      sciezka: "/stanowiska_przeglad",
    },

    {
      nazwa: "Strona glowna modułu QMD_HRB",
      sciezka: "/strona_glowna_modul_HRB",
    },

    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuHRBSzkolenia: [
    {
      nazwa: "Plan szkoleń",
      sciezka: "/szkolenia",
    },
    {
      nazwa: "Strona glowna modułu QMD_HRB",
      sciezka: "/strona_glowna_modul_HRB",
    },

    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuHRBSkierowania: [
    {
      nazwa: "Wykaz skierowań na badania lekarskie",
      sciezka: "/wykaz_skierowan",
    },
    {
      nazwa: "Strona glowna modułu QMD_HRB",
      sciezka: "/strona_glowna_modul_HRB",
    },

    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuHRBMedycynaPracy: [
    {
      nazwa: "Wykaz umów z medycyną pracy",
      sciezka: "/medycyna_pracy",
    },
    {
      nazwa: "Strona glowna modułu QMD_HRB",
      sciezka: "/strona_glowna_modul_HRB",
    },

    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],
  //--------------------------------------------------
  menuBHPORZ: [
    {
      nazwa: "Ocena Ryzyka Zawodowego",
      sciezka: "/dokumentacja_ORZ",
    },
    {
      nazwa: "Przeglądy Oceny Ryzyka Zawodowego",
      sciezka: "/ORZ_dokumentacja_przeglady",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_BHP",
      sciezka: "/strona_glowna_modul_BHP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuBHPORZszczegoly: [
    {
      nazwa: "Ocena Ryzyka Zawodowego",
      sciezka: "/dokumentacja_ORZ",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_BHP",
      sciezka: "/strona_glowna_modul_BHP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuBHPBSP: [
    {
      nazwa: "Powrót do strona glownej modułu QMD_BHP",
      sciezka: "/strona_glowna_modul_BHP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuBHPBSPszczegoly: [
    {
      nazwa: "Badania środowiska pracy",
      sciezka: "/dokumentacja_BSP",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_BHP",
      sciezka: "/strona_glowna_modul_BHP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuBHPSN: [
    {
      nazwa: "Wykaz przeglądów substancji niebezpiecznych",
      sciezka: "/substancje_niebezpieczne_dokumentacja_przeglady",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_BHP",
      sciezka: "/strona_glowna_modul_BHP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuBHPSNszczegoly: [
    {
      nazwa: "Wykaz substancji niebezpiecznych",
      sciezka: "/dokumentacja_substancje_niebezpieczne",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_BHP",
      sciezka: "/strona_glowna_modul_BHP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuBHPOSB: [
    {
      nazwa: "Powrót do strona glownej modułu QMD_BHP",
      sciezka: "/strona_glowna_modul_BHP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuBHPWYPADKI: [
    {
      nazwa: "Podjęte działania dla wypadków",
      sciezka: "/dzialania_wypadki_w_pracy",
    },
    {
      nazwa: "Ocena podjętych działań dla wypadków",
      sciezka: "/ocena_skutecznosci_dzialania_wypadki_w_pracy",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_BHP",
      sciezka: "/strona_glowna_modul_BHP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuBHPWYPDZIAŁANIA: [
    {
      nazwa: "Powrót do wykazu wypadków",
      sciezka: "/wypadki_w_pracy",
    },
    {
      nazwa: "Ocena podjętych działań dla wypadków",
      sciezka: "/ocena_skutecznosci_dzialania_wypadki_w_pracy",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_BHP",
      sciezka: "/strona_glowna_modul_BHP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuBHPWYPDZIAŁANIAOCENA: [
    {
      nazwa: "Powrót do wykazu wypadków",
      sciezka: "/wypadki_w_pracy",
    },
    {
      nazwa: "Podjęte działania dla wypadków",
      sciezka: "/dzialania_wypadki_w_pracy",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_BHP",
      sciezka: "/strona_glowna_modul_BHP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuBHPCHOROBY: [
    {
      nazwa: "Skutki chorób zawodowych",
      sciezka: "/skutki_choroby_zawodowe_dzialania",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_BHP",
      sciezka: "/strona_glowna_modul_BHP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuBHPCHORDZIAŁANIA: [
    {
      nazwa: "Powrót do wykazu chorób zawodowych",
      sciezka: "/choroby_zawodowe",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_BHP",
      sciezka: "/strona_glowna_modul_BHP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuBHPZPN: [
    {
      nazwa: "Podjęte działania dla sytuacji potencjalnie niebezpiecznych",
      sciezka: "/sytuacje_potencjalnie_niebezpieczne_dzialania",
    },
    {
      nazwa:
        "Ocena podjętych działań dla sytuacji potencjalnie niebezpiecznych",
      sciezka: "/ocena_sytuacje_potencjalnie_niebezpieczne_dzialania",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_BHP",
      sciezka: "/strona_glowna_modul_BHP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuBHPZPNDZIAŁANIA: [
    {
      nazwa: "Powrót do wykazu sytuacji potencjalnie niebezpiecznych",
      sciezka: "/sytuacje_potencjalnie_niebezpieczne",
    },
    {
      nazwa:
        "Ocena podjętych działań dla sytuacji potencjalnie niebezpiecznych",
      sciezka: "/ocena_sytuacje_potencjalnie_niebezpieczne_dzialania",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_BHP",
      sciezka: "/strona_glowna_modul_BHP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuBHPZPNDZIAŁANIAOCENA: [
    {
      nazwa: "Powrót do wykazu sytuacji potencjalnie niebezpiecznych",
      sciezka: "/sytuacje_potencjalnie_niebezpieczne",
    },
    {
      nazwa: "Podjęte działania dla sytuacji potencjalnie niebezpiecznych",
      sciezka: "/sytuacje_potencjalnie_niebezpieczne_dzialania",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_BHP",
      sciezka: "/strona_glowna_modul_BHP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuBHKW: [
    {
      nazwa: "Podjęte działania do uwag z kontroli wewnętrznych",
      sciezka: "/wykaz_kontroli_wewnetrznych_realizacja_uwag",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_BHP",
      sciezka: "/strona_glowna_modul_BHP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuBHPKWDZIAŁANIA: [
    {
      nazwa: "Powrót do wykazu kontroli wewnętrznych",
      sciezka: "/wykaz_kontroli_wewnetrznych",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_BHP",
      sciezka: "/strona_glowna_modul_BHP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuBHPKWOCENA: [
    {
      nazwa: "Powrót do wykazu kontroli wewnętrznych",
      sciezka: "/wykaz_kontroli_wewnetrznych",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_BHP",
      sciezka: "/strona_glowna_modul_BHP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuBHKZ: [
    {
      nazwa: "Podjęte działania do uwag z kontroli zewnętrznych",
      sciezka: "/wykaz_kontroli_zewnetrznych_realizacja_uwag",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_BHP",
      sciezka: "/strona_glowna_modul_BHP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuBHPKZDZIAŁANIA: [
    {
      nazwa: "Powrót do wykazu kontroli zewnętrznych",
      sciezka: "/wykaz_kontroli_zewnetrznych",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_BHP",
      sciezka: "/strona_glowna_modul_BHP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuBHPKZOCENA: [
    {
      nazwa: "Powrót do wykazu kontroli zewnętrznych",
      sciezka: "/wykaz_kontroli_zewnetrznych",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_BHP",
      sciezka: "/strona_glowna_modul_BHP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuBHPWPSN: [
    {
      nazwa: "Przeglądy wykazu prac szczególnie niebezpiecznych",
      sciezka: "/przeglady_dokumentacja_prace_szczegolnie_niebezpieczne",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_BHP",
      sciezka: "/strona_glowna_modul_BHP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuBHPWPSNszczegoly: [
    {
      nazwa: "Wykaz prac szczególnie niebezpiecznych",
      sciezka: "/dokumentacja_prace_szczegolnie_niebezpieczne",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_BHP",
      sciezka: "/strona_glowna_modul_BHP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuSYSkontekst: [
    {
      nazwa: "Kontekst_organizacji",
      sciezka: "/kontekst_organizacji",
    },
    {
      nazwa: "Wykaz kolalizacji objętej certyfikacją",
      sciezka: "/tabela_zakres",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_SYS",
      sciezka: "/strona_glowna_modul_SYS",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuSYSkontekstLokalizacja: [
    {
      nazwa: "Kontekst organizacji",
      sciezka: "/kontekst_organizacji",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_SYS",
      sciezka: "/strona_glowna_modul_SYS",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuSYSkontekstLokalizacja1: [
    {
      nazwa: "Kontekst organizacji",
      sciezka: "/kontekst_organizacji",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_SYS",
      sciezka: "/strona_glowna_modul_SYS",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuSYSprocesy: [
    {
      nazwa: "Wykaz procesów",
      sciezka: "/procesy",
    },
    {
      nazwa: "Wykaz przeglądów procesów",
      sciezka: "/procesy_przeglady",
    },
    {
      nazwa: "Wykaz szczegółów dla procesów",
      sciezka: "/procesy_dane_wejsciowe_wyjsciowe",
    },
    {
      nazwa: "Wykaz rozliczeń celów szczegółowych dla procesów",
      sciezka: "/procesy_rozliczenie_celu",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_SYS",
      sciezka: "/strona_glowna_modul_SYS",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuSYSprocesyPrzeglady: [
    {
      nazwa: "Wykaz procesów",
      sciezka: "/procesy",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_SYS",
      sciezka: "/strona_glowna_modul_SYS",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuSYSprocesySzczegolyProcesu: [
    {
      nazwa: "Wykaz procesów",
      sciezka: "/procesy",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_SYS",
      sciezka: "/strona_glowna_modul_SYS",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuSYSprocesySzczegolyRozliczenieProces: [
    {
      nazwa: "Wykaz procesów",
      sciezka: "/procesy",
    },
    {
      nazwa: "Wykaz szczegółów dla procesów",
      sciezka: "/procesy_dane_wejsciowe_wyjsciowe",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_SYS",
      sciezka: "/strona_glowna_modul_SYS",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuSYSdokumentacja: [
    {
      nazwa: "Wykaz dokumentacji",
      sciezka: "/dokumentacja",
    },
    {
      nazwa: "Wykaz przeglądów dokumentacji",
      sciezka: "/dokumentacja_przeglady",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_SYS",
      sciezka: "/strona_glowna_modul_SYS",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuSYSdokumentacjaPrzeglady: [
    {
      nazwa: "Wykaz dokumentacji",
      sciezka: "/dokumentacja",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_SYS",
      sciezka: "/strona_glowna_modul_SYS",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuSYSisntrukcjeoperacyjne: [
    {
      nazwa: "Wykaz instrukcji operacyjnych",
      sciezka: "/instrukcje_operacyjne",
    },
    {
      nazwa: "Wykaz przeglądów instrukcji operacyjnych",
      sciezka: "/instrukcje_operacyjne_przeglady",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_SYS",
      sciezka: "/strona_glowna_modul_SYS",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuSYSinstrukcjeoperacyjnePrzeglady: [
    {
      nazwa: "Wykaz instrukcji operacyjnych",
      sciezka: "/instrukcje_operacyjne",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_SYS",
      sciezka: "/strona_glowna_modul_SYS",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuSYSinstrukcjesrodowiskowe: [
    {
      nazwa: "Wykaz instrukcji środowiskowych",
      sciezka: "/instrukcje_srodowiskowe",
    },
    {
      nazwa: "Wykaz przeglądów instrukcji środowiskowych",
      sciezka: "/instrukcje_srodowiskowe_przeglady",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_SYS",
      sciezka: "/strona_glowna_modul_SYS",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuSYSinstrukcjesrodowiskowePrzeglady: [
    {
      nazwa: "Wykaz instrukcji środowiskowych",
      sciezka: "/instrukcje_srodowiskowe",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_SYS",
      sciezka: "/strona_glowna_modul_SYS",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuSYSinstrukcjebhp: [
    {
      nazwa: "Wykaz instrukcji BHP",
      sciezka: "/instrukcje_BHP",
    },
    {
      nazwa: "Wykaz przeglądów instrukcji BHP",
      sciezka: "/instrukcje_BHP_przeglady",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_SYS",
      sciezka: "/strona_glowna_modul_SYS",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuSYSinstrukcjebhpPrzeglady: [
    {
      nazwa: "Wykaz instrukcji BHP",
      sciezka: "/instrukcje_BHP",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_SYS",
      sciezka: "/strona_glowna_modul_SYS",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuSYSRyzykaSzanse: [
    {
      nazwa: "Wykaz - historia przeglądów ryzyk i szans",
      sciezka: "/tabela_przeglad_ryzyka_szanse",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_SYS",
      sciezka: "/strona_glowna_modul_SYS",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuSYSRyzykaSzansePrzeglady: [
    {
      nazwa: "Wykaz ryzyk i szans",
      sciezka: "/ryzyka_szanse",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_SYS",
      sciezka: "/strona_glowna_modul_SYS",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuSYSZagrozeniaBHP: [
    {
      nazwa: "Wykaz - historia przeglądów zagrożeń BHP",
      sciezka: "/tabela_przeglad_zagrozenia_bhp",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_SYS",
      sciezka: "/strona_glowna_modul_SYS",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuSYSZagrozeniaBHPPrzeglady: [
    {
      nazwa: "Wykaz zagrożeń BHP",
      sciezka: "/zagrozenia_bhp",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_SYS",
      sciezka: "/strona_glowna_modul_SYS",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuSYSWymaganiaPrawne: [
    {
      nazwa:
        "Wykaz - historia przeglądów, oceny zgodności wymagań prawnych i innych",
      sciezka: "/tabela_przeglad_wymagania_prawne",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_SYS",
      sciezka: "/strona_glowna_modul_SYS",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuSYSAspektySrodowiskowe: [
    {
      nazwa: "Wykaz - aspektów środowiskowych",
      sciezka: "/aspekty",
    },
    {
      nazwa: "Wykaz - przeglądów aspektów środowiskowych",
      sciezka: "/aspekty_przeglady",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_SYS",
      sciezka: "/strona_glowna_modul_SYS",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuSYSPrzegladyAspektySrodowiskowe: [
    {
      nazwa: "Wykaz - aspektów środowiskowych",
      sciezka: "/aspekty",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_SYS",
      sciezka: "/strona_glowna_modul_SYS",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuSYSPrzegladZarzadzania: [
    {
      nazwa: "Powrót do strona glownej modułu QMD_SYS",
      sciezka: "/strona_glowna_modul_SYS",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuSYSPrzegladZarzadzaniaProtokol: [
    {
      nazwa: "Wykaz - przeglądów zarządzania",
      sciezka: "/przeglad_zarzadzania",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_SYS",
      sciezka: "/strona_glowna_modul_SYS",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuSYSDzaialaniaAudit: [
    {
      nazwa: "Powrót do strona glownej modułu QMD_SYS",
      sciezka: "/strona_glowna_modul_SYS",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuSYSDzaialaniaAuditOcena: [
    {
      nazwa: "Wykaz - działań stwierdzonych w trakcie auditów",
      sciezka: "/dzialania_audit",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_SYS",
      sciezka: "/strona_glowna_modul_SYS",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuSYSDzaialaniaAuditOcenaSkutecznosci: [
    {
      nazwa: "Wykaz - działań stwierdzonych w trakcie auditów",
      sciezka: "/dzialania_audit",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_SYS",
      sciezka: "/strona_glowna_modul_SYS",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuSYSDzaialaniaPozaAudit: [
    {
      nazwa: "Powrót do strona glownej modułu QMD_SYS",
      sciezka: "/strona_glowna_modul_SYS",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuSYSDzaialaniaPozaAuditOcena: [
    {
      nazwa: "Wykaz - działań stwierdzonych poza auditami wewnętrznymi",
      sciezka: "/dzialania_poza_auditowe",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_SYS",
      sciezka: "/strona_glowna_modul_SYS",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuSYSDzaialaniaPozaAuditOcenaSkutecznosci: [
    {
      nazwa: "Wykaz - działań stwierdzonych poza auditami wewnętrznymi",
      sciezka: "/dzialania_poza_auditowe",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_SYS",
      sciezka: "/strona_glowna_modul_SYS",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuSYSWymaganiaPrawnePrzeglady: [
    {
      nazwa: "Wykaz wymagań prawnych i innych",
      sciezka: "/wymagania_prawne",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_SYS",
      sciezka: "/strona_glowna_modul_SYS",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuSYSCeleOkresy: [
    {
      nazwa: "Wykaz celów szczególowych",
      sciezka: "/cele_szczegolowe",
    },
    {
      nazwa: "Wykaz zadań dla celów szczególowych",
      sciezka: "/zadania_cele_szczegolowe",
    },
    {
      nazwa: "Wykaz przeglądów celów",
      sciezka: "/cele_przeglady",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_SYS",
      sciezka: "/strona_glowna_modul_SYS",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuSYSCelePrzeglady: [
    {
      nazwa: "Wykaz okresów dla ustanowionych celów",
      sciezka: "/cele_okres_realizacji",
    },
    {
      nazwa: "Wykaz celów szczególowych",
      sciezka: "/cele_szczegolowe",
    },
    {
      nazwa: "Wykaz zadań dla celów szczególowych",
      sciezka: "/zadania_cele_szczegolowe",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_SYS",
      sciezka: "/strona_glowna_modul_SYS",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuSYSCeleSzczegolowe: [
    {
      nazwa: "Wykaz okresów dla ustanowionych celów",
      sciezka: "/cele_okres_realizacji",
    },
    {
      nazwa: "Wykaz przeglądów celów",
      sciezka: "/cele_przeglady",
    },
    {
      nazwa: "Wykaz zadań dla celów szczególowych",
      sciezka: "/zadania_cele_szczegolowe",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_SYS",
      sciezka: "/strona_glowna_modul_SYS",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuSYSCeleZadaniaDlaCelow: [
    {
      nazwa: "Wykaz okresów dla ustanowionych celów",
      sciezka: "/cele_okres_realizacji",
    },
    {
      nazwa: "Wykaz celów szczególowych",
      sciezka: "/cele_szczegolowe",
    },
    {
      nazwa: "Wykaz przeglądów celów",
      sciezka: "/cele_przeglady",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_SYS",
      sciezka: "/strona_glowna_modul_SYS",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuSYSSprawozdawczoscSrodowiskowa: [
    {
      nazwa: "Powrót do strona glownej modułu QMD_SYS",
      sciezka: "/strona_glowna_modul_SYS",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuSYSZadowolenieKlienta: [
    {
      nazwa: "Powrót do strona glownej modułu QMD_SYS",
      sciezka: "/strona_glowna_modul_SYS",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuSYSAudityWewnetrzne: [
    {
      nazwa: "Powrót do strona glownej modułu QMD_SYS",
      sciezka: "/strona_glowna_modul_SYS",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuSYSAudityWewnetrzneRaporty: [
    {
      nazwa: "Wykaz auditów wewnętrznych",
      sciezka: "/audity",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_SYS",
      sciezka: "/strona_glowna_modul_SYS",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuSYSAudityWewnetrzneRaportyDodawanie: [
    {
      nazwa: "Wykaz auditów wewnętrznych",
      sciezka: "/audity",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_SYS",
      sciezka: "/strona_glowna_modul_SYS",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuSYSAudityWewnetrzneRaportyDodawanieEdycja: [
    {
      nazwa: "Wykaz auditów wewnętrznych",
      sciezka: "/audity",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_SYS",
      sciezka: "/strona_glowna_modul_SYS",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuSYSAudityWewnetrzneKompetencje: [
    {
      nazwa: "Wykaz auditów wewnętrznych",
      sciezka: "/audity",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_SYS",
      sciezka: "/strona_glowna_modul_SYS",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuSYSAudityPlan: [
    {
      nazwa: "Wykaz auditów wewnętrznych",
      sciezka: "/audity",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_SYS",
      sciezka: "/strona_glowna_modul_SYS",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuWSTmaszyny: [
    {
      nazwa: "Wykaz maszyn i urządzeń",
      sciezka: "/maszyny_i_urzadzenia",
    },
    {
      nazwa: "Wykaz przeglądów maszyn i urządzeń",
      sciezka: "/tabela_wykaz_przeglady_maszyn",
    },
    {
      nazwa: "Wykaz ubezpieczen maszyn i urządzeń",
      sciezka: "/tabela_wykaz_ubezpieczen_maszyn",
    },
    {
      nazwa: "Wykaz kart pracy maszyn i urządzeń",
      sciezka: "/tabela_maszyny_paliwo",
    },
    {
      nazwa: "Wykaz kosztów maszyn i urządzeń",
      sciezka: "/tabela_wykaz_kosztow_maszyn",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_WST",
      sciezka: "/strona_glowna_modul_WST",
    },

    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],
  menuWSTGeneratorMaszyny: [
    {
      nazwa: "Wykaz maszyn i urządzeń",
      sciezka: "/maszyny_i_urzadzenia",
    },
    
    {
      nazwa: "Powrót do strona glownej modułu QMD_WST",
      sciezka: "/strona_glowna_modul_WST",
    },

    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],
  menuWSTmaszynyPrzeglady: [
    {
      nazwa: "Wykaz maszyn i urządzeń",
      sciezka: "/maszyny_i_urzadzenia",
    },

    {
      nazwa: "Powrót do strona glownej modułu QMD_WST",
      sciezka: "/strona_glowna_modul_WST",
    },

    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuWSTmaszynyUbezpieczenia: [
    {
      nazwa: "Wykaz maszyn i urządzeń",
      sciezka: "/maszyny_i_urzadzenia",
    },

    {
      nazwa: "Powrót do strona glownej modułu QMD_WST",
      sciezka: "/strona_glowna_modul_WST",
    },

    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuWSTmaszynyPaliwo: [
    {
      nazwa: "Wykaz maszyn i urządzeń",
      sciezka: "/maszyny_i_urzadzenia",
    },

    {
      nazwa: "Powrót do strona glownej modułu QMD_WST",
      sciezka: "/strona_glowna_modul_WST",
    },

    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],
  menuWSTmaszynyKoszty: [
    {
      nazwa: "Wykaz maszyn i urządzeń",
      sciezka: "/maszyny_i_urzadzenia",
    },

    {
      nazwa: "Powrót do strona glownej modułu QMD_WST",
      sciezka: "/strona_glowna_modul_WST",
    },

    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuWSTmaszynydozorowe: [
    {
      nazwa: "Wykaz maszyn i urządzeń podlegających pod dozór techniczny",
      sciezka: "/maszyny_i_urzadzenia_dozorowe",
    },
    {
      nazwa:
        "Wykaz przeglądów maszyn i urządzeń podlegających pod dozór techniczny",
      sciezka: "/maszyny_i_urzadzenia_dozorowe_przeglady",
    },
    {
      nazwa:
        "Wykaz ubezpieczen maszyn i urządzeń podlegających pod dozór techniczny",
      sciezka: "/maszyny_i_urzadzenia_dozorowe_ubezpieczenia",
    },
    {
      nazwa: "Wykaz kart pracy maszyn i urządzeń",
      sciezka: "/maszyny_i_urzadzenia_dozorowe_paliwo",
    },
    {
      nazwa: "Wykaz kosztów maszyn i urządzeń",
      sciezka: "/maszyny_i_urzadzenia_dozorowe_koszty",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_WST",
      sciezka: "/strona_glowna_modul_WST",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuWSTmaszynyPrzegladyDozorowe: [
    {
      nazwa: "Wykaz maszyn i urządzeń podlegających pod dozór techniczny",
      sciezka: "/maszyny_i_urzadzenia_dozorowe",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_WST",
      sciezka: "/strona_glowna_modul_WST",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuWSTmaszynyUbezpieczeniaDozorowe: [
    {
      nazwa: "Wykaz maszyn i urządzeń podlegających pod dozór techniczny",
      sciezka: "/maszyny_i_urzadzenia_dozorowe",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_WST",
      sciezka: "/strona_glowna_modul_WST",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuWSTmaszynyDozorowePaliwa: [
    {
      nazwa: "Wykaz maszyn i urządzeń podlegających pod dozór techniczny",
      sciezka: "/maszyny_i_urzadzenia_dozorowe",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_WST",
      sciezka: "/strona_glowna_modul_WST",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuWSTmaszynyDozoroweKoszty: [
    {
      nazwa: "Wykaz maszyn i urządzeń podlegających pod dozór techniczny",
      sciezka: "/maszyny_i_urzadzenia_dozorowe",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_WST",
      sciezka: "/strona_glowna_modul_WST",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuWSTsamochodyosobowe: [
    {
      nazwa: "Wykaz samochodów osobowych",
      sciezka: "/samochody_osobowe",
    },
    {
      nazwa: "Wykaz przeglądów samochodów osobowych",
      sciezka: "/tabela_wykaz_przeglady_samochody_osobowe",
    },
    {
      nazwa: "Wykaz ubezpieczeń samochodów osobowych",
      sciezka: "/tabela_wykaz_ubezpieczen_samochody_osobowe",
    },
    {
      nazwa: "Wykaz kart pracy samochody osobowe",
      sciezka: "/tabela_samochody_osobowe_paliwo",
    },
    {
      nazwa: "Wykaz kosztów samochody osobowe",
      sciezka: "/tabela_wykaz_kosztow_samochody_osobowe",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_WST",
      sciezka: "/strona_glowna_modul_WST",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuWSTsamoachodyosobowePrzeglady: [
    {
      nazwa: "Wykaz samochodów osobowych",
      sciezka: "/samochody_osobowe",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_WST",
      sciezka: "/strona_glowna_modul_WST",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuWSTsamoachodyosoboweUbezpieczenia: [
    {
      nazwa: "Wykaz samochodów osobowych",
      sciezka: "/samochody_osobowe",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_WST",
      sciezka: "/strona_glowna_modul_WST",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuWSTsamoachodyosobowePaliwo: [
    {
      nazwa: "Wykaz samochodów osobowych",
      sciezka: "/samochody_osobowe",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_WST",
      sciezka: "/strona_glowna_modul_WST",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuWSTsamoachodyosoboweKoszty: [
    {
      nazwa: "Wykaz samochodów osobowych",
      sciezka: "/samochody_osobowe",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_WST",
      sciezka: "/strona_glowna_modul_WST",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuWSTsamochodyciezarowe: [
    {
      nazwa: "Wykaz samochodów cieżarowych",
      sciezka: "/samochody_ciezarowe",
    },
    {
      nazwa: "Wykaz przeglądów samochodów cieżarowych",
      sciezka: "/tabela_wykaz_przeglady_samochody_ciezarowe",
    },
    {
      nazwa: "Wykaz tachografów samochody cieżarowych",
      sciezka: "/tabela_wykaz_tachografow_samochody_ciezarowe",
    },
    {
      nazwa: "Wykaz ubezpieczeń samochodów cieżarowych",
      sciezka: "/tabela_wykaz_ubezpieczen_samochody_ciezarowe",
    },
    {
      nazwa: "Wykaz kart pracy samochody cieżarowe",
      sciezka: "/tabela_samochody_ciezarowe_paliwo",
    },
    {
      nazwa: "Wykaz kosztów samochody cieżarowych",
      sciezka: "/tabela_wykaz_kosztow_samochody_ciezarowe",
    },
    {
      nazwa: "Wykaz chłodni dla samochodów cieżarowych",
      sciezka: "/tabela_wykaz_chlodni_samochody_ciezarowe",
    },
    {
      nazwa: "Wykaz termografów dla samochodów cieżarowych",
      sciezka: "/tabela_wykaz_termografy_samochody_ciezarowe",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_WST",
      sciezka: "/strona_glowna_modul_WST",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuWSTsamoachodyciezarowePrzeglady: [
    {
      nazwa: "Wykaz samochodów cieżarowych",
      sciezka: "/samochody_ciezarowe",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_WST",
      sciezka: "/strona_glowna_modul_WST",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuWSTsamoachodyciezaroweTachografy: [
    {
      nazwa: "Wykaz samochodów cieżarowych",
      sciezka: "/samochody_ciezarowe",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_WST",
      sciezka: "/strona_glowna_modul_WST",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuWSTsamoachodyciezaroweChlodnie: [
    {
      nazwa: "Wykaz samochodów cieżarowych",
      sciezka: "/samochody_ciezarowe",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_WST",
      sciezka: "/strona_glowna_modul_WST",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuWSTsamoachodyciezaroweTermografy: [
    {
      nazwa: "Wykaz samochodów cieżarowych",
      sciezka: "/samochody_ciezarowe",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_WST",
      sciezka: "/strona_glowna_modul_WST",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuWSTsamoachodyciezaroweUbezpieczenia: [
    {
      nazwa: "Wykaz samochodów cieżarowych",
      sciezka: "/samochody_ciezarowe",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_WST",
      sciezka: "/strona_glowna_modul_WST",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuWSTsamoachodyciezarowePaliwo: [
    {
      nazwa: "Wykaz samochodów cieżarowych",
      sciezka: "/samochody_ciezarowe",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_WST",
      sciezka: "/strona_glowna_modul_WST",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuWSTsamoachodyciezaroweKoszty: [
    {
      nazwa: "Wykaz samochodów cieżarowych",
      sciezka: "/samochody_ciezarowe",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_WST",
      sciezka: "/strona_glowna_modul_WST",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuWSTelektronarzedzia: [
    {
      nazwa: "Wykaz elektronarzędzi",
      sciezka: "/tabela_elektronarzedzia",
    },
    {
      nazwa: "Wykaz przeglądów elektronarzędzi",
      sciezka: "/tabela_elektronarzedzia_przeglady",
    },
    {
      nazwa: "Wykaz ubezpieczen elektronarzędzi",
      sciezka: "/tabela_elektronarzedzia_ubezpieczenia",
    },
    {
      nazwa: "Wykaz kosztów elektronarzedzia",
      sciezka: "/tabela_elektronarzedzia_koszty",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_WST",
      sciezka: "/strona_glowna_modul_WST",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],
  menuWSTGeneratorElektronarzedzia: [
    {
      nazwa: "Wykaz elektronarzędzi",
      sciezka: "/tabela_elektronarzedzia",
    },
    
    {
      nazwa: "Powrót do strona glownej modułu QMD_WST",
      sciezka: "/strona_glowna_modul_WST",
    },

    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],
  menuWSTelektronarzedziaPrzeglady: [
    {
      nazwa: "Wykaz elektronarzędzi",
      sciezka: "/tabela_elektronarzedzia",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_WST",
      sciezka: "/strona_glowna_modul_WST",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuWSTelektronarzedziaUbezpieczenia: [
    {
      nazwa: "Wykaz elektronarzędzi",
      sciezka: "/tabela_elektronarzedzia",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_WST",
      sciezka: "/strona_glowna_modul_WST",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuWSTelektronarzedziaKoszty: [
    {
      nazwa: "Wykaz elektronarzędzi",
      sciezka: "/tabela_elektronarzedzia",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_WST",
      sciezka: "/strona_glowna_modul_WST",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuWSTIT: [
    {
      nazwa: "Wykaz wyposażenia IT",
      sciezka: "/tabela_IT",
    },
    {
      nazwa: "Wykaz przeglądów wyposażenia IT",
      sciezka: "/tabela_IT_przeglady",
    },
    {
      nazwa: "Wykaz ubezpieczen wyposażenia IT",
      sciezka: "/tabela_IT_ubezpieczenia",
    },
    {
      nazwa: "Wykaz kosztów wyposażenia IT",
      sciezka: "/tabela_IT_koszty",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_WST",
      sciezka: "/strona_glowna_modul_WST",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuWSTGeneratorIT: [
    {
      nazwa: "Wykaz wyposażenia IT",
      sciezka: "/tabela_IT",
    },
    
    {
      nazwa: "Powrót do strona glownej modułu QMD_WST",
      sciezka: "/strona_glowna_modul_WST",
    },

    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuWSTITPrzeglady: [
    {
      nazwa: "Wykaz wyposażenia IT",
      sciezka: "/tabela_IT",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_WST",
      sciezka: "/strona_glowna_modul_WST",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuWSTITUbezpieczenia: [
    {
      nazwa: "Wykaz wyposażenia IT",
      sciezka: "/tabela_IT",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_WST",
      sciezka: "/strona_glowna_modul_WST",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuWSTITKoszty: [
    {
      nazwa: "Wykaz wyposażenia IT",
      sciezka: "/tabela_IT",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_WST",
      sciezka: "/strona_glowna_modul_WST",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuWSTPomiarowka: [
    {
      nazwa: "Wykaz wyposażenia kontrolno-pomiarowego",
      sciezka: "/sprzet_pomiarowy",
    },
    {
      nazwa: "Wykaz przeglądów wyposażenia kontrolno-pomiarowego",
      sciezka: "/sprzet_pomiarowy_przeglady",
    },
    {
      nazwa: "Wykaz ubezpieczen wyposażenia kontrolno-pomiarowego",
      sciezka: "/sprzet_pomiarowy_ubezpieczenia",
    },
    {
      nazwa: "Wykaz kosztów wyposażenia kontrolno-pomiarowego",
      sciezka: "/sprzet_pomiarowy_koszty",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_WST",
      sciezka: "/strona_glowna_modul_WST",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuWSTPomiarowkaPrzeglady: [
    {
      nazwa: "Wykaz wyposażenia kontrolno-pomiarowego",
      sciezka: "/sprzet_pomiarowy",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_WST",
      sciezka: "/strona_glowna_modul_WST",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuWSTPomiarowkaUbezpieczenia: [
    {
      nazwa: "Wykaz wyposażenia kontrolno-pomiarowego",
      sciezka: "/sprzet_pomiarowy",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_WST",
      sciezka: "/strona_glowna_modul_WST",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuWSTPomiarowkaKoszty: [
    {
      nazwa: "Wykaz wyposażenia kontrolno-pomiarowego",
      sciezka: "/sprzet_pomiarowy",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_WST",
      sciezka: "/strona_glowna_modul_WST",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuWSTGeneratorPomiarowka: [
    {
      nazwa:
        "Wykaz wyposażenia kontrolno-pomiarowego podlegającego pod sprawdzenie",
      sciezka: "/wykaz_sprzetu_pomiarowego",
    },
    {
      nazwa: "Wykaz sprawdzeń wyposażenia kontrolno-pomiarowego",
      sciezka: "/wykaz_sprawdzen",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_WST",
      sciezka: "/strona_glowna_modul_WST",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuWSTGeneratorPomiarowkaSwiadectwa: [
    {
      nazwa:
        "Wykaz wyposażenia kontrolno-pomiarowego podlegającego pod sprawdzenie",
      sciezka: "/wykaz_sprzetu_pomiarowego",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_WST",
      sciezka: "/strona_glowna_modul_WST",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuWSTGeneratorPomiarowkaSwiadectwo: [
    {
      nazwa:
        "Wykaz wyposażenia kontrolno-pomiarowego podlegającego pod sprawdzenie",
      sciezka: "/wykaz_sprzetu_pomiarowego",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_WST",
      sciezka: "/strona_glowna_modul_WST",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuWSTEmisjaZPaliw: [
    {
      nazwa: "Wykaz emisji z zakupionych paliw",
      sciezka: "/wykaz_emisje",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_WST",
      sciezka: "/strona_glowna_modul_WST",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuWSTwyposazenieBHP: [
    {
      nazwa: "Wykaz wyposażenia BHP",
      sciezka: "/wyposazenie_BHP",
    },
    {
      nazwa: "Wykaz przeglądów wyposażenia BHP",
      sciezka: "/wyposazenie_BHP_przeglady",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_WST",
      sciezka: "/strona_glowna_modul_WST",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuWSTwyposazeniePrzegladyBHP: [
    {
      nazwa: "Wykaz wyposażenia BHP",
      sciezka: "/wyposazenie_BHP",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_WST",
      sciezka: "/strona_glowna_modul_WST",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuWSTwyposazeniePPOZ: [
    {
      nazwa: "Wykaz wyposażenia PPOZ",
      sciezka: "/wyposazenie_PPOZ",
    },
    {
      nazwa: "Wykaz przeglądów wyposażenia PPOZ",
      sciezka: "/wyposazenie_PPOZ_przeglady",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_WST",
      sciezka: "/strona_glowna_modul_WST",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuWSTwyposazeniePrzegladyPPOZ: [
    {
      nazwa: "Wykaz wyposażenia PPOZ",
      sciezka: "/wyposazenie_PPOZ",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_WST",
      sciezka: "/strona_glowna_modul_WST",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuWSTbudynki: [
    {
      nazwa: "Wykaz budynków",
      sciezka: "/budynki",
    },
    {
      nazwa: "Wykaz przeglądów 5-letnich budynków",
      sciezka: "/budynki_przeglady_5lat",
    },
    {
      nazwa: "Wykaz przeglądów rocznych budynków",
      sciezka: "/budynki_przeglady_roczne",
    },
    {
      nazwa: "Wykaz przeglądów półrocznych budynków",
      sciezka: "/budynki_przeglady_polroczne",
    },
    {
      nazwa: "Wykaz przeglądów instalacji elektrycznych budynków",
      sciezka: "/budynki_przeglady_elektryczne",
    },
    {
      nazwa: "Wykaz przeglądów instalacji gazowych budynków",
      sciezka: "/budynki_przeglady_gazowe",
    },
    {
      nazwa: "Wykaz przeglądów instalacji hydrantowych budynków",
      sciezka: "/budynki_przeglady_hydrantow",
    },
    {
      nazwa: "Instrukcje bezpieczeństwa poarowego budynków",
      sciezka: "/budynki_przeglady_instrukcja_ppoz",
    },

    {
      nazwa: "Wykaz przeglądów przewodów kominowych budynków",
      sciezka: "/budynki_przeglady_kominow",
    },
    {
      nazwa: "Wykaz przeglądów głównych wyłączników prądu budynków",
      sciezka: "/budynki_przeglady_wylacznika",
    },
    {
      nazwa: "Wykaz ubezpieczen budynku",
      sciezka: "/budynki_ubezpieczenia",
    },
    {
      nazwa: "Wykaz kosztów budynku",
      sciezka: "/budynki_koszty",
    },
    {
      nazwa: "Wykaz zużycia mediów dla budynku",
      sciezka: "/budynki_media",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_WST",
      sciezka: "/strona_glowna_modul_WST",
    },

    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],
  menuWSTbudynkiPrzeglady: [
    {
      nazwa: "Wykaz budynków",
      sciezka: "/budynki",
    },

    {
      nazwa: "Powrót do strona glownej modułu QMD_WST",
      sciezka: "/strona_glowna_modul_WST",
    },

    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuWSTbudynkiUbezpieczenia: [
    {
      nazwa: "Wykaz budynków",
      sciezka: "/budynki",
    },

    {
      nazwa: "Powrót do strona glownej modułu QMD_WST",
      sciezka: "/strona_glowna_modul_WST",
    },

    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuWSTbudynkiMedia: [
    {
      nazwa: "Wykaz budynków",
      sciezka: "/budynki",
    },

    {
      nazwa: "Powrót do strona glownej modułu QMD_WST",
      sciezka: "/strona_glowna_modul_WST",
    },

    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],
  menuWSTbudynkiKoszty: [
    {
      nazwa: "Wykaz budynków",
      sciezka: "/budynki",
    },

    {
      nazwa: "Powrót do strona glownej modułu QMD_WST",
      sciezka: "/strona_glowna_modul_WST",
    },

    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuFAKkontrahenci: [
    {
      nazwa: "Wykaz kontaktów do kontarhentów",
      sciezka: "/tabela_kontrahenci_kontakt",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_DOP",
      sciezka: "/strona_glowna_modul_DOP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuFAKkontrahenciKontakt: [
    {
      nazwa: "Wykaz kontrahentów",
      sciezka: "/tabela_kontrahenci",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_DOP",
      sciezka: "/strona_glowna_modul_DOP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuFAKWyroby: [
    {
      nazwa: "Wykaz grup wyrobów",
      sciezka: "/grupy_wyrobow",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_DOP",
      sciezka: "/strona_glowna_modul_DOP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuFAKGrupyWyrobow: [
    {
      nazwa: "Wykaz wyrobów",
      sciezka: "/wykaz_wyrobow",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_DOP",
      sciezka: "/strona_glowna_modul_DOP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuFAKKwalifikowanieDostawcow: [
    {
      nazwa: "Wykaz przeglądów kwalifikowanych dostawców",
      sciezka: "/kwalifikowanie_dostawcy_przeglad",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_DOP",
      sciezka: "/strona_glowna_modul_DOP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuFAKZakupy: [
    {
      nazwa: "Wykaz kwalifikowanych dostawców",
      sciezka: "/kwalifikowanie_dostawcy",
    },
    {
      nazwa: "Wykaz przeglądów lysty kwalifikowanych dostawców",
      sciezka: "/kwalifikowanie_dostawcy_przeglad",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_DOP",
      sciezka: "/strona_glowna_modul_DOP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuFAKPrzegladyZakupy: [
    {
      nazwa: "Wykaz kwalifikowanych dostawców",
      sciezka: "/kwalifikowanie_dostawcy",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_DOP",
      sciezka: "/strona_glowna_modul_DOP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuFAKProjektowania: [
    {
      nazwa: "Powrót do strona glownej modułu QMD_DOP",
      sciezka: "/strona_glowna_modul_DOP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuFAKSzczegolyProjektowania: [
    {
      nazwa: "Wykaz projektów",
      sciezka: "/wykaz_projektow",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_DOP",
      sciezka: "/strona_glowna_modul_DOP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuFAKWszystkieZapytaniaOfertowe: [
    {
      nazwa: "Zapytania ofertowe - przyjęte",
      sciezka: "/zapytania_ofertowe_przyjete",
    },
    {
      nazwa: "Zapytania ofertowe - odrzucone",
      sciezka: "/zapytania_ofertowe_odrzucone",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_DOP",
      sciezka: "/strona_glowna_modul_DOP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuFAKPrzyjeteZapytaniaOfertowe: [
    {
      nazwa: "Zapytania ofertowe - wszystkie",
      sciezka: "/zapytania_ofertowe",
    },
    {
      nazwa: "Zapytania ofertowe - odrzucone",
      sciezka: "/zapytania_ofertowe_odrzucone",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_DOP",
      sciezka: "/strona_glowna_modul_DOP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuFAKOdrzuconeZapytaniaOfertowe: [
    {
      nazwa: "Zapytania ofertowe - wszystkie",
      sciezka: "/zapytania_ofertowe",
    },
    {
      nazwa: "Zapytania ofertowe - przyjęte",
      sciezka: "/zapytania_ofertowe_przyjete",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_DOP",
      sciezka: "/strona_glowna_modul_DOP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuFAKWszystkieOferty: [
    {
      nazwa: "Oferty - przyjęte",
      sciezka: "/oferty_przyjete",
    },
    {
      nazwa: "Oferty - odrzucone",
      sciezka: "/oferty_odrzucone",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_DOP",
      sciezka: "/strona_glowna_modul_DOP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuFAKPrzyjeteOferty: [
    {
      nazwa: "Oferty - wszystkie",
      sciezka: "/oferty",
    },
    {
      nazwa: "Oferty - odrzucone",
      sciezka: "/oferty_odrzucone",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_DOP",
      sciezka: "/strona_glowna_modul_DOP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuFAKOdrzuconeOferty: [
    {
      nazwa: "Oferty - wszystkie",
      sciezka: "/oferty",
    },
    {
      nazwa: "Oferty - przyjęte",
      sciezka: "/oferty_przyjete",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_DOP",
      sciezka: "/strona_glowna_modul_DOP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuFAKZamowienia: [
    {
      nazwa: "Powrót do strona glownej modułu QMD_DOP",
      sciezka: "/strona_glowna_modul_DOP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuFAKWTrakcieProdukcjaZamowienia: [
    {
      nazwa: "Zamówienia produkcyjne - zakończone",
      sciezka: "/pr3_raport_produkcyjny_zakonczone",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_DOP",
      sciezka: "/strona_glowna_modul_DOP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuFAKZakonczoneProdukcjaZamowienia: [
    {
      nazwa: "Zamówienia produkcyjne - w trakcie produkcji",
      sciezka: "/pr1_raport_produkcyjny",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_DOP",
      sciezka: "/strona_glowna_modul_DOP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuFAKRaportyProdukcyjne: [
    {
      nazwa: "Zamówienia produkcyjne - w trakcie realizacji",
      sciezka: "/pr1_raport_produkcyjny",
    },
    {
      nazwa: "Zamówienia produkcyjne - zakończone",
      sciezka: "/pr3_raport_produkcyjny_zakonczone",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_DOP",
      sciezka: "/strona_glowna_modul_DOP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuFAKRaportyZakonczoneProdukcyjne: [
    {
      nazwa: "Zamówienia produkcyjne - zakończone",
      sciezka: "/pr3_raport_produkcyjny_zakonczone",
    },
    {
      nazwa: "Zamówienia produkcyjne - w trakcie realizacji",
      sciezka: "/pr1_raport_produkcyjny",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_DOP",
      sciezka: "/strona_glowna_modul_DOP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuFAKZleceniaWewnetrzne: [
    {
      nazwa: "Powrót do strona glownej modułu QMD_DOP",
      sciezka: "/strona_glowna_modul_DOP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuFAKRWZleceniaWewnetrzne: [
    {
      nazwa: "Powrót do strona glownej modułu QMD_DOP",
      sciezka: "/strona_glowna_modul_DOP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuFAKWTrakcieZleceniaWewnetrzne: [
    {
      nazwa: "Zlecenia wewnetrzne - zakończone",
      sciezka: "/rp7_raport_produkcyjny_zakonczone_magazyn",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_DOP",
      sciezka: "/strona_glowna_modul_DOP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuFAKZakonczoneZleceniaWewnetrzne: [
    {
      nazwa: "Zlecenia wewnetrzne - w trakcie produkcji",
      sciezka: "/rp5_raport_produkcyjny_magazyn",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_DOP",
      sciezka: "/strona_glowna_modul_DOP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuFAKMagazynRaportyProdukcyjne: [
    {
      nazwa: "Zlecenia wewnetrzne - w trakcie produkcji",
      sciezka: "/rp5_raport_produkcyjny_magazyn",
    },
    {
      nazwa: "Zlecenia wewnetrzne - zakończone",
      sciezka: "/rp7_raport_produkcyjny_zakonczone_magazyn",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_DOP",
      sciezka: "/strona_glowna_modul_DOP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuFAKMagazynRaportyZakonczoneProdukcyjne: [
    {
      nazwa: "Zlecenia wewnetrzne - zakończone",
      sciezka: "/rp7_raport_produkcyjny_zakonczone_magazyn",
    },
    {
      nazwa: "Zlecenia wewnetrzne - w trakcie produkcji",
      sciezka: "/rp5_raport_produkcyjny_magazyn",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_DOP",
      sciezka: "/strona_glowna_modul_DOP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuFAKDzialaniaOperacyjne: [
    {
      nazwa: "Zamówienia produkcyjne - w trakcie produkcji",
      sciezka: "/pr1_raport_produkcyjny",
    },
    {
      nazwa: "Zamówienia produkcyjne - zakończone",
      sciezka: "/pr3_raport_produkcyjny_zakonczone",
    },
    {
      nazwa: "Zlecenia wewnetrzne - w trakcie produkcji",
      sciezka: "/rp5_raport_produkcyjny_magazyn",
    },
    {
      nazwa: "Zlecenia wewnetrzne - zakończone",
      sciezka: "/rp7_raport_produkcyjny_zakonczone_magazyn",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_DOP",
      sciezka: "/strona_glowna_modul_DOP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuFAKWydanieZMagazynu: [
    {
      nazwa: "Powrót do strona glownej modułu QMD_DOP",
      sciezka: "/strona_glowna_modul_DOP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuFAKReklamacjeWykaz: [
    {
      nazwa: "Powrót do strona glownej modułu QMD_DOP",
      sciezka: "/strona_glowna_modul_DOP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuFAKReklamacjeDzialania: [
    {
      nazwa: "Wykaz reklamacji",
      sciezka: "/tabela_reklamacje",
    },
    {
      nazwa: "Powrót do strona glownej modułu QMD_DOP",
      sciezka: "/strona_glowna_modul_DOP",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuUstawienia: [

    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],

  menuUstawieniaSzczegoly: [
    {
      nazwa: "Wykaz użytkowników",
      sciezka: "/tabela_dostepu",
    },
    {
      nazwa: "Powrót do strony glównej",
      sciezka: "/strona_glowna",
    },
  ],
};

