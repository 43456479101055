import { BehaviorSubject } from "rxjs";
import { api } from "./api.serwis";

class UserService {

  _isStatusAvailable = null;
  userSubject$ = new BehaviorSubject(null);

  get isStatusAvailable() {
    return this._isStatusAvalilable;
  }

  set isStatusAvailable(value) {
    this._isStatusAvailable = value;
  }

  get isUserLogged() {
    return this.userSubject$.getValue() !== null;
  }

  get user() {
    return this.userSubject$.getValue();
  }

  getUserObservable() {
    return this.userSubject$.asObservable();
  }

  async getUserInfo() {
    try {
      const response = await api.logowanieStatus();
      if (response.data.sukces) {
        this.userSubject$.next(response.data.user);
      }
    } catch (error) {
      this.userSubject$.next(null);
    } finally {
      this.isStatusAvailable = true
    }
  }
}

export const userService = new UserService();
