<template>
  <div>
  <TabelaProcesyRozliczenieCelow />
  </div>
</template>

<script>
import TabelaProcesyRozliczenieCelow from '../../komponenty/strona-tabela-komponenty/SYS_procesy/tabela_procesy_rozliczenie_celow.vue'


export default {
  name: 'StronaTabelaProcesyRozliczenieCelu',
  components: {
    TabelaProcesyRozliczenieCelow,
    
  }

}
</script>
