<template>
  <div class="Faktury_form">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI FAKTURY"
                : "FORMULARZ DODAWANIA FAKTURY"
            }}
          </div>
        </md-card-header>

        <md-card-content>
          <div class="aa">
            <div class="md-layout md-gutter">
              <div class="md-layout-item md-small-size-100">
                <md-field class="M1" :class="getValidationClass('Id_faktury')">
                  <label for="Id_faktury">Numer faktury</label>
                  <!--- edycja 7 linia-->
                  <md-input
                    type="text"
                    name="Id_faktury"
                    id="Id_faktury"
                    
                    v-model="form.Id_faktury"
                    :disabled="sending"
                  />
                  <span class="md-error" v-if="!$v.form.Id_faktury.required"
                    >Pole jest wymagane</span
                  >
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100">
                <md-field
                  class="M1"
                  :class="getValidationClass('Data_wystawienia')"
                >
                  <label for="Data_wystawienia">Data wystawienia</label>
                  <md-input
                    type="date"
                    name="Data_wystawienia"
                    id="Data_wystawienia"
                    
                    v-model="form.Data_wystawienia"
                    :disabled="sending"
                  />
                  <span
                    class="md-error"
                    v-if="!$v.form.Data_wystawienia.required"
                    >Pole jest wymagane</span
                  >
                  <span
                    class="md-error"
                    v-else-if="!$v.form.Data_wystawienia.maxlength"
                    >Wymagana ilość znaków od 3 do 100</span
                  >
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100">
                <md-field
                  class="M1"
                  :class="getValidationClass('Data_sprzedazy')"
                >
                  <label for="Data_sprzedazy">Data sprzedaży</label>
                  <md-input
                    type="date"
                    name="Data_sprzedazy"
                    id="Data_sprzedazy"
                    
                    v-model="form.Data_sprzedazy"
                    :disabled="sending"
                  />
                  <span class="md-error" v-if="!$v.form.Data_sprzedazy.required"
                    >Pole jest wymagane</span
                  >
                  <span
                    class="md-error"
                    v-else-if="!$v.form.Data_sprzedazy.maxlength"
                    >Wymagana ilość znaków od 1 do 30</span
                  >
                </md-field>
              </div>
            </div>

            <div class="md-layout md-gutter">
              <div class="md-layout-item md-small-size-100">
                <md-field
                  class="M4"
                  :class="getValidationClass('Id_kontrahent')"
                >
                  <label for="Id_kontrahent">Kontrahent</label>

                  <md-select
                    v-model="form.Id_kontrahent"
                    :disabled="sending"
                    name="Id_kontrahent"
                    id="Id_kontrahent"
                    autocomplete="Id_kontrahent"
                    md-dense
                  >
                    <md-option
                      v-for="(kontrahent, index) in Kontrahenci"
                      :key="index"
                      :value="kontrahent.Id"
                      >{{
                        `NIP: ${kontrahent.NIP} - ${kontrahent.Nazwa_kontrahenta}, ${kontrahent.Kod} ${kontrahent.Miejscowosc} ${kontrahent.Ulica} ${kontrahent.Nr}`
                      }}
                    </md-option>
                  </md-select>
                  <span class="md-error" v-if="!$v.form.Id_kontrahent.required"
                    >Pole jest wymagane</span
                  >
                </md-field>
              </div>
            </div>

            <div class="md-layout md-gutter">
              <div class="md-layout-item md-small-size-100">
                <md-field
                  class="M2"
                  :class="getValidationClass('Id_podzielna_platnosc')"
                >
                  <label for="Id_podzielna_platnosc">Podzielna płatność</label>

                  <md-select
                    v-model="form.Id_podzielna_platnosc"
                    :disabled="sending"
                    name="Id_podzielna_platnosc"
                    id="Id_podzielna_platnosc"
                    autocomplete="Id_podzielna_platnosc"
                    md-dense
                  >
                    <md-option
                      v-for="(
                        podzielna_platnosc, index
                      ) in Podzielnosc_platnosci"
                      :key="index"
                      :value="podzielna_platnosc.Id"
                      >{{ podzielna_platnosc.Podzielnosc }}
                    </md-option>
                  </md-select>
                  <span
                    class="md-error"
                    v-if="!$v.form.Id_podzielna_platnosc.required"
                    >Pole jest wymagane</span
                  >
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100">
                <md-field
                  class="M2"
                  :class="getValidationClass('Id_sposob_zaplaty')"
                >
                  <label for="Id_sposob_zaplaty">Rodzaj płatności</label>

                  <md-select
                    v-model="form.Id_sposob_zaplaty"
                    :disabled="sending"
                    name="Id_sposob_zaplaty"
                    id="Id_sposob_zaplaty"
                    autocomplete="Id_sposob_zaplaty"
                    md-dense
                  >
                    <md-option
                      v-for="(platnosc, index) in Platnosci"
                      :key="index"
                      :value="platnosc.Id"
                      >{{ platnosc.Rodzaj_platnosci }}
                    </md-option>
                  </md-select>
                  <span
                    class="md-error"
                    v-if="!$v.form.Id_sposob_zaplaty.required"
                    >Pole jest wymagane</span
                  >
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100">
                <md-field class="M1" :class="getValidationClass('Ilosc_dni')">
                  <label for="Ilosc_dni">Ilość dni</label>
                  <md-input
                    type="text"
                    name="Ilosc_dni"
                    id="Ilosc_dni"
                    
                    v-model="form.Ilosc_dni"
                    :disabled="sending"
                  />
                  <span class="md-error" v-if="!$v.form.Ilosc_dni.required"
                    >Pole jest wymagane</span
                  >
                  <span
                    class="md-error"
                    v-else-if="!$v.form.Ilosc_dni.maxlength"
                    >Wymagana ilość znaków od 1 do 10</span
                  >
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100">
                <md-field
                  class="M1"
                  :class="getValidationClass('Termin_zaplaty')"
                >
                  <label for="Termin_zaplaty">Termin płatności</label>
                  <md-input
                    type="float"
                    name="Termin_zaplaty"
                    id="Termin_zaplaty"
                    
                    v-model="form.Termin_zaplaty"
                    :disabled="sending || czyJestWTrybieEdycji"
                  />
                </md-field>
              </div>
            </div>

            <div class="md-layout md-gutter">
              <div class="md-layout-item md-small-size-100">
                <md-field class="M2" :class="getValidationClass('Id_rachunek')">
                  <label for="Id_rachunek">Rachunek bankowy</label>

                  <md-select
                    v-model="form.Id_rachunek"
                    :disabled="sending"
                    name="Id_rachunek"
                    id="Id_rachunek"
                    autocomplete="Id_rachunek"
                    md-dense
                  >
                    <md-option
                      v-for="(rachunek_bankowy, index) in Rachunki_bankowe"
                      :key="index"
                      :value="rachunek_bankowy.Id"
                      >{{
                        `Numer konto: ${rachunek_bankowy.Rachunek} - Nazwa banku: ${rachunek_bankowy.Nazwa_banku} `
                      }}
                    </md-option>
                  </md-select>
                  <span class="md-error" v-if="!$v.form.Id_rachunek.required"
                    >Pole jest wymagane</span
                  >
                </md-field>
              </div>
            </div>
          </div>

          <div
            class="aa"
            v-for="(wiersz, index) in form.wiersze"
            :key="index"
            :set="(v = $v.form.wiersze.$each[index])"
          >
            <div v-if="!czyJestWTrybieEdycji" class="md-layout md-gutter">
              <div class="A1">
               <span style="margin-top:30px">{{index+1}}</span>
              </div>

              <div class="A2">
                <md-field :class="getValidationClass('Usluga')">
                  <label for="Usluga">Towar/usługa</label>
                  <md-textarea
                    name="Usluga"
                    id="Usluga"
                    autocomplete="Usluga"
                    v-model="wiersz.Usluga"
                    :disabled="sending"
                  />
                  <span class="md-error" v-if="!v.Usluga.required"
                    >Pole jest wymagane</span
                  >
                </md-field>
              </div>

              <div class="A3">
                <md-field :class="getValidationClass('Id_jednostka_sprzedazy')">
                  <label for="Id_jednostka_sprzedazy">Jedn.</label>

                  <md-select
                    v-model="wiersz.Id_jednostka_sprzedazy"
                    :disabled="sending"
                    name="Id_jednostka_sprzedazy"
                    id="Id_jednostka_sprzedazy"
                    autocomplete="Id_jednostka_sprzedazy"
                    md-dense
                  >
                    <md-option
                      v-for="(jedn_sprzedazy, index) in Jednostki_sprzedazy"
                      :key="index"
                      :value="jedn_sprzedazy.Id"
                      >{{ jedn_sprzedazy.Jedn_sprzedazy }}
                    </md-option>
                  </md-select>
                  <span
                    class="md-error"
                    v-if="!v.Id_jednostka_sprzedazy.required"
                    >Pole jest wymagane</span
                  >
                </md-field>
              </div>

              <div class="A3">
                <md-field :class="getValidationClass('Ilosc')">
                  <label for="Ilosc">Ilość</label>
                  <md-input
                    name="Ilosc"
                    id="Ilosc"
                    autocomplete="Ilosc"
                    @input="ustawCeneNetto(wiersz)"
                    v-model="wiersz.Ilosc"
                    :disabled="sending"
                  />
                  <span class="md-error" v-if="!v.Ilosc.required"
                    >Pole jest wymagane</span
                  >
                </md-field>
              </div>

              <div class="A3">
                <md-field :class="getValidationClass('Cena_jedn')">
                  <label for="Cena_jedn">Cena jedn</label>
                  <md-input
                    name="Cena_jedn"
                    id="Cena_jedn"
                    autocomplete="Cena_jedn"
                    @input="ustawCeneNetto(wiersz)"
                    v-model="wiersz.Cena_jedn" 
                    :disabled="sending"
                  />
                  <span class="md-error" v-if="!v.Cena_jedn.required"
                    >Pole jest wymagane</span
                  >
                </md-field>
              </div>

              <div class="A3">
                <md-field :class="getValidationClass('Netto')">
                  <label for="Netto">Kwota netto</label>
                  <md-input
                    name="Netto"
                    id="Netto"
                    autocomplete="Netto"
                    v-model="wiersz.Netto"
                    readonly
                  />
                  <span class="md-error" v-if="!v.Netto.required"
                    >Pole jest wymagane</span
                  >
                </md-field>
              </div>

              <div class="A3">
                <md-field :class="getValidationClass('VAT')">
                  <label for="VAT">VAT</label>

                  <md-select
                    v-model="wiersz.VAT"
                    :disabled="sending"
                    name="VAT"
                    id="VAT"
                    @input="ustawCeneBrutto(wiersz)"
                    autocomplete="VAT"
                    md-dense
                  >
                    <md-option
                      v-for="(stawka, index) in Stawki_VAT"
                      :key="index"
                      :value="stawka.VAT"
                      >{{ stawka.VAT }}
                    </md-option>
                  </md-select>
       
                </md-field>
              </div>
              <div class="A3">
                <md-field :class="getValidationClass('Brutto')">
                  <label for="Brutto">Kwota brutto</label>
                  <md-input style="width:8%"
                    name="Brutto"
                    id="Brutto"
                    autocomplete="Brutto"
                    v-model="wiersz.Brutto"
                    readonly
                  />
                  <span class="md-error" v-if="!v.Brutto.required"
                    >Pole jest wymagane</span
                  >
                </md-field>
              </div>

              <div class="A0">
                <b-icon-plus-square
                  class="ikony"
                  font-scale="1.7"
                  style="border: 1px black"
                  color="black"
                  @click="dodajWiersz()"
                >
                </b-icon-plus-square>

                <b-icon-trash
                  class="ikony"
                  font-scale="1.7"
                  color="black"
                  @click="usunWiersz(wiersz)"
                >
                </b-icon-trash>
              </div>
            </div>
          </div>
        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

        <md-card-actions>
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            czyJestWTrybieEdycji
              ? "ZATWIERDŹ - EDYTOWANY WPIS"
              : "ZATWIERDŹ - UTWÓRZ WPIS"
          }}</md-button>
        </md-card-actions>
      </md-card>
    </form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { api } from "./../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_faktury",
  mixins: [validationMixin],
  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      Id_faktury: null,
      Data_wystawienia: null,
      Data_sprzedazy: null,
      Id_kontrahent: null,
      Id_sposob_zaplaty: null,
      Id_rachunek: null,
      Id_podzielna_platnosc: null,
      Ilosc_dni: null,
      wiersze: [
        {
          Usluga: null,
          Ilosc: null,
          Id_jednostka_sprzedazy: null,
          Cena_jedn: null,
          Netto: null,
          VAT: null,
          Brutto: null,
        },
      ],
    },
    IdEdytowanegoElementu: null,
    userSaved: false,
    sending: false,
    lastUser: null,
    Kontrahenci: [],
    Platnosci: [],
    Podzielnosc_platnosci: [],
    Rachunki_bankowe: [],
    Jednostki_sprzedazy: [],
    Stawki_VAT: [],
  }),
  created: function () {
    if (this.obiektKonfiguracyjny.akcja === "edycja") {
      //edycja
      api
        .pobieranieFakturyEdycja(this.obiektKonfiguracyjny.Id)
        .then((odpowiedz) => {
          const {
            Id,
            Id_faktury,
            Data_wystawienia,
            Data_sprzedazy,
            Id_kontrahent,
            Id_sposob_zaplaty,
            Id_rachunek,
            Id_podzielna_platnosc,
            Ilosc_dni,
            Usluga,
            Ilosc,
            Id_jednostka_sprzedazy,
            Cena_jedn,
            Netto,
            VAT,
            Brutto,
          } = odpowiedz.data.dane[0];
          this.IdEdytowanegoElementu = Id;
          this.form.Id_faktury = Id_faktury;
          this.form.Data_wystawienia = Data_wystawienia;
          this.form.Data_sprzedazy = Data_sprzedazy;
          this.form.Id_kontrahent = Id_kontrahent;
          this.form.Id_sposob_zaplaty = Id_sposob_zaplaty;
          this.form.Id_rachunek = Id_rachunek;
          this.form.Id_podzielna_platnosc = Id_podzielna_platnosc;
          this.form.Ilosc_dni = Ilosc_dni;
          this.form.Usluga = Usluga;
          this.form.Ilosc = Ilosc;
          this.form.Id_jednostka_sprzedazy = Id_jednostka_sprzedazy;
          this.form.Cena_jedn = Cena_jedn;
          this.form.Netto = Netto;
          this.form.VAT = VAT;
          this.form.Brutto = Brutto;
        })
        .catch((error) => {
          console.log(error);
        });
    }

    Promise.all([
      api.pobierzKontrahenta(),
      api.pobierzRodzajPlatnosc(),
      api.pobierzRodzajPodzielnoscuPlatnosci(),
      api.pobierzRachunekBankowy(),
      api.pobierzJednostki_sprzedazy(),
      api.pobierzStawkiVAT(),
    ])
      .then(
        ([
          odpowiedz_kontahenci,
          odpowiedz_platnosci,
          odpowiedz_Podzielnosc_platnosci,
          odpowiedz_Rachunki_bankowe,
          odpowiedz_Jednostki_sprzedazy,
          odpowiedz_Stawki_VAT,
        ]) => {
          this.Kontrahenci = odpowiedz_kontahenci.data.dane;
          this.Platnosci = odpowiedz_platnosci.data.dane;
          this.Podzielnosc_platnosci =
            odpowiedz_Podzielnosc_platnosci.data.dane;
          this.Rachunki_bankowe = odpowiedz_Rachunki_bankowe.data.dane;
          this.Jednostki_sprzedazy = odpowiedz_Jednostki_sprzedazy.data.dane;
          this.Stawki_VAT = odpowiedz_Stawki_VAT.data.dane;
        }
      )
      .catch((error) => {
        console.log(error);
      });
  },
  validations: {
    form: {
      Id_faktury: {
        required,
      },
      Data_wystawienia: {
        required,
      },
      Data_sprzedazy: {
        required,
      },
      Id_kontrahent: {
        required,
      },
      Id_sposob_zaplaty: {
        required,
      },
      Id_rachunek: {
        required,
      },
      Id_podzielna_platnosc: {
        required,
      },
      Ilosc_dni: {
        required,
      },
      wiersze: {
        $each: {
          Usluga: {
            required,
          },
          Ilosc: {
            required,
          },
          Id_jednostka_sprzedazy: {
            required,
          },
          Cena_jedn: {
            required,
          },
          Netto: {
            required,
          },
          VAT: {
            required,
          },
          Brutto: {
            required,
          },
        },
      },
    },
  },
  computed: {
    czyJestWTrybieEdycji: function () {
      return this.obiektKonfiguracyjny.akcja === "edycja";
    },
  },

  methods: {
    dodajWiersz() {
      console.log("robi");
      const nowyWiersz = {};
      Object.keys(this.form.wiersze[0]).forEach((key) => {
        nowyWiersz[key] = null;
      });
      const noweWiersze = [...this.form.wiersze, nowyWiersz];
      this.form.wiersze = noweWiersze;
    },
    usunWiersz(wiersz) {
      if (this.form.wiersze.length <= 1) {
        const nowyWiersz = {};
        Object.keys(this.form.wiersze[0]).forEach((key) => {
          nowyWiersz[key] = null;
        });
        this.form.wiersze = [nowyWiersz];
        return;
      }

      this.form.wiersze = this.form.wiersze.filter((el) => el !== wiersz);
    },
    ustawCeneNetto(wiersz) {
      
      if (wiersz.Ilosc === null || wiersz.Cena_jedn === null) {
        wiersz.Netto = null;
        return;
      }
      wiersz.Netto = parseFloat((wiersz.Ilosc || 0) * (wiersz.Cena_jedn || 0)).toFixed(2) ;
      this.ustawCeneBrutto(wiersz);
    },
    ustawCeneBrutto(wiersz) {
      if (wiersz.Netto === null) {
        wiersz.Brutto = null;
        return;
      }
      wiersz.Brutto = parseFloat((wiersz.Netto || 0) * (1 + (wiersz.VAT || 0) / 100)).toFixed(2);
    },
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.Id_faktury = null;
      this.form.Data_wystawienia = null;
      this.form.Data_sprzedazy = null;
      this.form.Id_kontrahent = null;
      this.form.Id_sposob_zaplaty = null;
      this.form.Id_rachunek = null;
      this.form.Id_podzielna_platnosc = null;
      this.form.Ilosc_dni = null;
      this.form.wiersze = [];
    },

    zapiszFormularz() {
      this.sending = true;

      if (this.czyJestWTrybieEdycji) {
        const daneDoWyslania = {
        Id_faktury: this.form.Id_faktury,
        Data_wystawienia: this.form.Data_wystawienia,
        Data_sprzedazy: this.form.Data_sprzedazy,
        Id_kontrahent: this.form.Id_kontrahent,
        Id_sposob_zaplaty: this.form.Id_sposob_zaplaty,
        Id_rachunek: this.form.Id_rachunek,
        Id_podzielna_platnosc: this.form.Id_podzielna_platnosc,
        Ilosc_dni: this.form.Ilosc_dni,
        PozycjeFaktury: this.form.wiersze,
        }


        api
          .zapiszFaktureUpdate(daneDoWyslania, this.IdEdytowanegoElementu)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {

               const daneDoWyslania = {
        Id_faktury: this.form.Id_faktury,
        Data_wystawienia: this.form.Data_wystawienia,
        Data_sprzedazy: this.form.Data_sprzedazy,
        Id_kontrahent: this.form.Id_kontrahent,
        Id_sposob_zaplaty: this.form.Id_sposob_zaplaty,
        Id_rachunek: this.form.Id_rachunek,
        Id_podzielna_platnosc: this.form.Id_podzielna_platnosc,
        Ilosc_dni: this.form.Ilosc_dni,
        PozycjeFaktury: this.form.wiersze,
        }

        api
          .zapiszFakture(daneDoWyslania)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    naszWalidacjaFormularza() {
      if (this.czyJestWTrybieEdycji) {
        return (
          !this.$v.form.Id_faktury.$invalid &&
          !this.$v.form.Data_wystawienia.$invalid &&
          !this.$v.form.Data_sprzedazy.$invalid &&
          !this.$v.form.Rok_produkcji.$invalid &&
          !this.$v.form.Id_kontrahent.$invalid &&
          !this.$v.form.Id_sposob_zaplaty.$invalid &&
          !this.$v.form.Id_rachunek.$invalid &&
          !this.$v.form.Id_podzielna_platnosc.$invalid &&
          !this.$v.form.Ilosc_dni.$invalid &&
          !this.$v.form.wiersze.$invalid
        );
      } else {
        return !this.$v.$invalid;
      }
    },
    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>
<style>
.Faktury_form .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.Faktury_form .md-layout .md-gutter {
  min-width: 55vw; /* szerokość modala*/
  max-width: 100%;
  min-height: 7vh; /* wysokość pomiędzy mierszami w modalu*/
}
.md-layout {
  padding-right: 0px !important;
}
.Faktury_form .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}

.Faktury_form .M1 {
  max-width: 100% !important;
  min-width: 100% !important;
}
.Faktury_form .M2 {
  max-width: 100% !important;
  min-width: 100% !important;
}
.Faktury_form .M3 {
  min-width: 97%;
  max-width: 97%;
  min-height: 5vh;
  font-size: 2vh !important;
}

.Faktury_form .M4 {
  max-width: 100% !important;
  min-width: 100% !important;
}
.Faktury_form .M5 {
  max-width: 90% !important;
  min-width: 90% !important;
}
.Faktury_form .A1 {
  width: 2% !important;
  margin-right: 1% !important;
  margin-left: 1vw !important;
  margin-top:52px
}
.Faktury_form .A2 {
  width: 27% !important;
  margin-right: 1% !important;
}
.Faktury_form .A3 {
  width: 8% !important;
  margin-right: 1% !important;
  min-width: 80px !important;
}
.Faktury_form .A0 {
  width: 9% !important;
  margin-right: 1% !important;
  margin-left: 20px;
  min-width: 90px !important;
}

.Faktury_form .A4 {
  width: 6% !important;
  margin-right: 1% !important;
}
.A1 .md-input {
  font-size: 14px !important;
}
.A2 .md-textarea {
  font-size: 14px !important;
}
.A3 .md-input {
  font-size: 14px !important;
}
.A4 .md-input {
  font-size: 14px !important;
}
.A0 {
  margin-top: 50px;
  margin-left: 10px;
}
.Faktury_form .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  margin: 10px 10px 10px 10px !important;
  padding-top: 35px !important;
}
.Faktury_form .A3 .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 90px !important;
  margin: 10px 10px 10px 10px !important;
  padding-top: 35px !important;
}
.Faktury_form .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}

.Faktury_form .md-input {
  font-weight: 600 !important;
}

.Faktury_form {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
  overflow: auto;
}
.Faktury_form .md-primary {
  border: 1px solid !important;

  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}
.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important; /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}
.md-layout .md-gutter
{
  min-width: 1000px!important;
}
.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}
.md-list-item-text {
  font-size: 1.7vmin !important;
}
.Faktury_form .md-card-actions .md-button {
  margin-right: 50px !important;
}
.aa {
  border-bottom: 1px solid;
  margin-top: 10px;
  padding-bottom: 20px;
}
.Faktury_form .ikony {
  cursor: pointer;
  margin-right: 5px;
  margin-left: 15px;
}
.md-field.md-has-textarea:not(.md-autogrow):after, .md-field.md-has-textarea:not(.md-autogrow):before 
{
  border: none!important;
  border-bottom: 0.5px solid!important;
  height: 90px!important;
  font-size: 12px !important;;
}
.md-field.md-has-textarea:not(.md-autogrow) .md-textarea {
    min-height: 40px!important;
    padding: 0 0px!important;
    resize: vertical!important;
}
</style>