<template >
  <div>
  <TabelaWykazPracownicyZapoznanieORZ />
  </div>
</template>

<script>
import TabelaWykazPracownicyZapoznanieORZ from '../../komponenty/strona-tabela-komponenty/HR_pracownicy/tabela_zapoznanie_ORZ.vue'


export default {
  name: 'StronaTabelaWykazBadan',
  components: {
    TabelaWykazPracownicyZapoznanieORZ,
    
  }

}
</script>

