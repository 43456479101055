<template>
  <div class="tabela_DZAUDO">
    <div class="laczacy">
      <div class="menu_strony">
         <span>
        <button class="button" @click="$router.push(`../dzialania_audit`)">
          POWRÓT DO WYKAZU NIEZGODNOŚCI/SPOSTRZEŻEŃ
        </button>
         <button
            class="button"
            :disabled="!autoryzacja.czyMozeDodawac"
                   @click="
              pokazDialog = true;
              typAktywnegoFormularza = 'formularz_dodawanie_dzialania_audit_ocena';
              obiektKonfiguracyjny = {
                Id: null,
                akcja: 'dodawanie',
              };
            "
          >
            DODAWANIE DZIAŁAŃ DO NIEZGODNOŚCI/SPOSTRZEŻENIA
          </button>
          <md-tooltip class="podpowiedz" md-direction="top">{{
            !autoryzacja.czyMozeDodawac
              ? "BRAK UPRAWNIEŃ"
              : "DODAWANIE DZIAŁANIA DO NIEZGODNOŚCI/SPOSTRZEŻENIA"
          }}</md-tooltip>
           </span>
      </div>

      <div class="menu_strony1">
        <b>
          <b-icon-printer-fill
            class="ikony"
            font-scale="2.1"
            color="white"
            @click="generujPdf"
          >
          </b-icon-printer-fill>
          <md-tooltip class="podpowiedz" md-direction="top"
            >GENERUJ PDF / DRUKUJ</md-tooltip
          >
        </b>

        <b>
          <b-icon-info-circle-fill
            class="ikony"
            font-scale="2.1"
            color="white"
            @click="pokazPDF_INFO"
          >
          </b-icon-info-circle-fill>
          <md-tooltip class="podpowiedz" md-direction="top">INFO</md-tooltip>
        </b>
      </div>
    </div>

    <modal
      v-if="pokazDialog"
      :rodzajFormularza="typAktywnegoFormularza"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />

    <md-table
      md-height="45vh"
      @md-selected="wyborElementu"
      v-model="searched"
      md-sort="Nazwa_maszyny"
      md-sort-order="asc"
      md-model-id="Id_maszyny"
      md-card
      md-fixed-header
    >
      <md-table-toolbar>
        <div class="d-flex flex-column w-100">
          <div class="row">
            <div class="col-4"></div>
            <div class="col-4">
              <h1 class="md-title">
                PODJĘTE DZIAŁANIA DO STWIERDZONYCH<br />
                UWAG W TRAKCIE AUDITÓW WEWNĘTRZNYCH
              </h1>
            </div>

            <div class="col-4">
              <md-field md-clearable class="ml-auto mr-0">
                <md-input
                  placeholder="Szukaj..."
                  v-model="search"
                  @input="searchOnTable"
                />
              </md-field>
            </div>
          </div>

          <div class="PasekWyboru" v-if="wybranyElementTabeli">
            <div
              style="text-align: right; margin-top: 0.3vh; margin-bottom: 0.3vh"
            >
              <b>
                <b-button
                  :disabled="!autoryzacja.czyMozeEdytowac"
                  @click="
                    pokazDialog = true;
                    typAktywnegoFormularza =
                      'formularz_dodawanie_dzialania_audit_ocena';
                    obiektKonfiguracyjny = {
                      Id: wybranyElementTabeli.Id,
                      akcja: 'edycja',
                    };
                  "
                >
                  <b-icon-pencil-square
                    class="ikony"
                    font-scale="1.80"
                    color="white"
                  >
                  </b-icon-pencil-square
                ></b-button>
                <md-tooltip class="podpowiedz" md-direction="top">{{
                  autoryzacja.czyMozeUsuwac
                    ? "EDYCJA ELEMENTU"
                    : "BRAK UPRAWNIEŃ"
                }}</md-tooltip>
              </b>
              <b
                ><b-button>
                  <b-icon-bounding-box
                    class="ikony"
                    font-scale="1.80"
                    color="white"
                    @click="
                      $router.push(
                        `../dzialania_audit_ocena_skutecznosci/${wybranyElementTabeli.Id}`
                      )
                    "
                  >
                  </b-icon-bounding-box
                ></b-button>
                <md-tooltip class="podpowiedz" md-direction="top"
                  >OCENA SKUTECZNOŚCI</md-tooltip
                >
              </b>
            </div>
          </div>
        </div>
      </md-table-toolbar>

      <md-table-empty-state
        v-if="czyPobranoDane"
        md-label="Nie posiadasz wpisów w wykazie podjętych działań do uwagi"
      >
     
      </md-table-empty-state>

      <md-table-row
        slot="md-table-row"
        slot-scope="{ item }"
        md-selectable="single"
        :key="item.id"
      >
        <md-table-cell md-label="Lp">
          {{ item.Lp1 }}
        </md-table-cell>

        <md-table-cell md-label="Nr_auditu" md-sort-by="Nr_auditu">
          {{ item.Nr_auditu }}
        </md-table-cell>

        <md-table-cell md-label="Nr N/S" md-sort-by="Numer_dzialania">
          {{ item.Numer_dzialania }}
        </md-table-cell>

        <md-table-cell md-label="Treść uwagi N/S" md-sort-by="Wartosc_wyniku">
          {{ item.Wartosc_wyniku }}
        </md-table-cell>

        <md-table-cell
          style="word-wrap:break-word"
          md-label="Przyczna"
          md-sort-by="Przyczyna"
        >
          {{ item.Przyczyna }}
        </md-table-cell>

        <md-table-cell md-label="Dz.korekcyjne" md-sort-by="Korekcja">
          {{ item.Korekcja }}
        </md-table-cell>

        <md-table-cell md-label="Dz.korygujące" md-sort-by="Korygujace">
          {{ item.Korygujace }}
        </md-table-cell>

        <md-table-cell
          md-label="Planowe zakończ."
          md-sort-by="Data_zakonczenia"
        >
          {{ item.Data_zakonczenia }}
        </md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
import modal from "../../strona-formularze/modal.vue";
import { api } from "./../../../serwisy/api.serwis";
import { authorizationService } from "./../../../serwisy/authorization.servis";
import jsPDF from "jspdf";
import "jspdf-autotable";

const toLower = (text) => {
  return text.toString().toLowerCase();
};

const search = (items, term) => {
  if (term) {
    return items.filter((item) => {
      const elementWyszukiwany = toLower(term);
      const Nr_auditu = toLower(item.Nr_auditu);
      const Numer_dzialania = toLower(item.Numer_dzialania);
      const Wartosc_wyniku = toLower(item.Wartosc_wyniku);
      const Przyczyna = toLower(item.Przyczyna);
      const Korekcja = toLower(item.Korekcja);
      const Korygujace = toLower(item.Korygujace);
      const Data_zakonczenia = toLower(item.Data_zakonczenia);
      return (
        Nr_auditu.includes(elementWyszukiwany) ||
        Numer_dzialania.includes(elementWyszukiwany) ||
        Wartosc_wyniku.includes(elementWyszukiwany) ||
        Przyczyna.includes(elementWyszukiwany) ||
        Korekcja.includes(elementWyszukiwany) ||
        Korygujace.includes(elementWyszukiwany) ||
        Data_zakonczenia.includes(elementWyszukiwany)
      );
    });
  }

  return items;
};

export default {
  name: "tabela_wykaz_procesow",
  data: () => ({
    czyPobranoDane: false,
    search: null,
    searched: [],
    Procesy: [],
    pokazDialog: false,
    typAktywnegoFormularza: null,
    obiektKonfiguracyjny: null,
    wybranyElementTabeli: null,
    autoryzacja: {
      czyMozeDodawac: false,
      czyMozeUsuwac: false,
      czyMozeEdytowac: false,
      czyMozePrzegladac: false,
    },
  }),
  components: {
    modal,
  },
  mounted: function() {
    this.pobierzDaneDoTabeli();
    this.pobierzDaneAutoryzacyjne();
  },
  computed: {
    //informacja czy jest w trybie edycji
    czyJestWTrybieEdycji() {
      return this.$router.params.Id ? true : false;
    },
  },
  methods: {
    generujPdf() {
      const doc = new jsPDF("l", "mm", "a4");

      doc.setFont("Aleo-Regular");

      doc.text(
        "Wykaz podjętych działań do uwag stwierdzonych w trakcie auditów",
        14,
        10
      );
      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "silver",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            "Lp",
            "Nr auditu",
            "Nr działania",
            "Rodzaj działania",
            "Przyczyna",
            "Korekcja",
            "Korygujace",
            "Data_zakonczenia",
          ],
        ],
        body: this.searched.map((Procesy) => {
          return [
            Procesy.Lp1,
            Procesy.Nr_auditu,
            Procesy.Numer_dzialania,
            Procesy.Wartosc_wyniku,
            Procesy.Przyczyna,
            Procesy.Korekcja,
            Procesy.Korygujace,
            Procesy.Data_zakonczenia,
          ];
        }),
      });

      doc.save("wykaz_podjetych_dzialan_auditowych.pdf");
    },
    pobierzDaneAutoryzacyjne() {
      authorizationService
        .getInfo("StronaTabelaDzialaniaAuditOcena", 4)

        .then((obiektOdpowiedzi) => {
          const dane = obiektOdpowiedzi.data.dostep;
          this.autoryzacja = {
            czyMozeDodawac: Boolean(
              dane.find(({ Id_operacji }) => Id_operacji == "1")
            ),
            czyMozeUsuwac: Boolean(
              dane.find(({ Id_operacji }) => Id_operacji == "2")
            ),
            czyMozeEdytowac: Boolean(
              dane.find(({ Id_operacji }) => Id_operacji == "3")
            ),
            czyMozePrzegladac: Boolean(
              dane.find(({ Id_operacji }) => Id_operacji == "4")
            ),
          };
        })
        .catch((error) => {
          console.log(error);
        });
    },
    pobierzDaneDoTabeli() {
      const { id } = this.$route.params;

      console.log(this.$route);

      api
        .pobieranieDzialaniaAuditOcena(id)
        .then((obiektOdpowiedzi) => {
          console.log(obiektOdpowiedzi);
           this.Procesy = obiektOdpowiedzi.data.dane.map((proces, index) => ({
            ...proces,
            Lp1: index + 1,
          }));
          this.searched = this.Procesy;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.czyPobranoDane = true;
        });
    },
    funkcjaZamykajacaDialog(sukces) {
      this.pokazDialog = false;
      this.typAktywnegoFormularza = null;
      this.obiektKonfiguracyjny = null;
      if (sukces) {
        this.pobierzDaneDoTabeli();
      }
    },
    newUser() {
      window.alert("Noop");
    },
    searchOnTable() {
      this.searched = search(this.Procesy, this.search);
    },
    usuwanieElementu(Id) {
      this.pokazDialog = true;
      this.typAktywnegoFormularza = "usuwanie";
      this.obiektKonfiguracyjny = {
        Id,
        typ: "dzialania_audit_ocena",
      };
    },
    pokazPDF_INFO() {
      //dopisane
      this.pokazDialog = true;
      this.typAktywnegoFormularza = "pokaz_PDF_INFO";
      this.obiektKonfiguracyjny = {
        Nazwa_instrukcji: "INFO-3",
      };
    },

    wyborElementu(element) {
      this.wybranyElementTabeli = element;
    },
  },
};
</script>

<style>
.tabela_DZAUDO .md-field {
  max-width: 300px;
}
.tabela_DZAUDO .tabela {
  width: 100%;
}
.tabela_DZAUDO .md-card {
  box-shadow: none !important;
}
.tabela_DZAUDO .md-title {
  font-weight: 900 !important;
  color: black !important;
  font-size: 1.5vh !important;
  padding: 10px;
}
.tabela_DZAUDO .md-toolbar {
  height: 14vh;
}
.tabela_DZAUDO .md-table-head {
  font-weight: bold;
  color: black !important;
  font-size: 1vh !important;
  text-align: left !important;
}
.tabela_DZAUDO .md-table-head-label {
  text-align: left !important;
  font-size: 1vh !important;
}
.tabela_DZAUDO .md-table-cell {
  font-size: 1vh !important;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-align: left;
  color: black;
  font-weight: 300;
  margin-top: 200px !important;
}
.tabela_DZAUDO .ikony {
  cursor: pointer;
  margin-right: 40px;
}
.tabela_DZAUDO .md-table-cell:nth-child(1) {
  width: 7vw;
  text-align: left !important;
}
.tabela_DZAUDO .md-table-cell:nth-child(2) {
  width: 10vw;
  text-align: left !important;
}
.tabela_DZAUDO .md-table-cell:nth-child(3) {
  width: 13vw;
  text-align: left !important;
}
.tabela_DZAUDO .md-table-cell:nth-child(4) {
  width: 13vw;
  text-align: left !important;
}
.tabela_DZAUDO .md-table-cell:nth-child(5) {
  width: 13vw;
  text-align: left !important;
  word-wrap: break-word !important;
}
.tabela_DZAUDO .md-table-cell:nth-child(6) {
  width: 13vw;
  text-align: left !important;
}
.tabela_DZAUDO .md-table-cell:nth-child(7) {
  width: 13vw;
  text-align: left !important;
}
.tabela_DZAUDO .md-table-cell:nth-child(8) {
  width: 13vw;
  text-align: left !important;
}
.tabela_DZAUDO .button {
  margin-right: 3px;
  border: 3px white ridge;
  font-weight: bold;
  font-size: 1vmin;
  min-width: 7vw;
}
.tabela_DZAUDO .button:hover {
  background-color: #727171;
  text-decoration: none;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  color: white;
}
.tabela_DZAUDO .menu_strony {
  border: 1px solid;
  margin-top: 5px;
  padding: 3px;
  background: #939393;
  width: 84.5%;
}
.tabela_DZAUDO .menu_strony1 {
  border: 1px solid;
  margin-top: 5px;
  padding: 3px;
  background: #939393;
  width: 15%;
  margin-left: 0.5%;
}
.tabela_DZAUDO .laczacy {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.md-table.md-theme-default .md-table-row.md-selected,
.md-table.md-theme-default .md-table-row.md-selected-single {
  background-color: #dddddd !important;
}
.PasekWyboru {
  background: #606060;
}
.tabela_DZAUDO .ikony {
  margin-right: 4px;
  margin-left: 4px;
  border: 1px solid !important;
  border-radius: 5px;
  padding: 5px;
}
.tabela_DZAUDO .ikony:hover {
  background-color: black;
  text-decoration: none;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
.podpowiedz {
  font-size: 1.5vmin !important;
  font-weight: bold;
  background-color: black !important;
}
.btn {
  background-color: #606060 !important;
  padding: 0rem 0rem !important;
  border-color: #606060 !important;
}
</style>
