import { render, staticRenderFns } from "./StronaTabelaWyposazeniePrzegladyPPOZ.vue?vue&type=template&id=129a3452&"
import script from "./StronaTabelaWyposazeniePrzegladyPPOZ.vue?vue&type=script&lang=js&"
export * from "./StronaTabelaWyposazeniePrzegladyPPOZ.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports