<template>
  <div class="tabela_EMISJA">
    <div class="laczacy">
      <div class="menu_strony">
        <button

       >-
        </button>


      </div>
      <div class="menu_strony1">
          <b>
          <b-icon-printer-fill
            class="ikony"
            font-scale="2.1"
            color="white"
            @click="generujPdf"
          >
          </b-icon-printer-fill>
          <md-tooltip class="podpowiedz" md-direction="top"
            >GENERUJ PDF / DRUKUJ</md-tooltip
          >
        </b>

        <b>
          <b-icon-info-circle-fill
            class="ikony"
            font-scale="2.1"
            color="white"
            @click="pokazPDF_INFO"
          >
          </b-icon-info-circle-fill>
          <md-tooltip class="podpowiedz" md-direction="top"
            >GENERUJ PDF / DRUKUJ</md-tooltip
          >
        </b>
      </div>
    </div>

    <modal
      v-if="pokazDialog"
      :rodzajFormularza="typAktywnegoFormularza"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field>
                <label for="Rok">Wybór roku </label>

                <md-select id="Rok" v-model="Rok" @input="pobierzDaneDoTabeli">
                  <md-option
                    v-for="(Rok, index) in Lata"
                    :key="index"
                    :value="Rok.Rok"
                    >{{ ` ${Rok.Rok} ` }}</md-option
                  >
                </md-select>
              </md-field>
            </div>
          </div>
    <md-table
      md-height="45vh"
      @md-selected="wyborElementu"
      v-model="searched"
      md-sort="Nazwa_maszyny"
      md-sort-order="asc"
      md-model-id="Id"
      md-card
      md-fixed-header
    >
      <md-table-toolbar>
        <div class="d-flex flex-column w-100">
          <div class="row">
            <div class="col-4"></div>
            <div class="col-4">
              <h1 class="md-title">WYKAZ - ZESTAWIENIE EMISJE</h1>
            </div>

            <div class="col-4">
              <md-field md-clearable class="ml-auto mr-0">
                <md-input
                  placeholder="Szukaj..."
                  v-model="search"
                  @input="searchOnTable"
                />
              </md-field>
            </div>
          </div>


        
        </div>
      </md-table-toolbar>

      <md-table-empty-state
        v-if="czyPobranoDane"
        md-label="Nie posiadasz wpisów w wykazie wymagań prawnych i innych"
      >
        <md-button
          class="md-primary md-raised"
          @click="
            pokazDialog = true;
            typAktywnegoFormularza = 'formularz_dodawanie_wymagania_prawne';
            obiektKonfiguracyjny = {
              Id: null,
              akcja: 'dodawanie',
            };
          "
          >Dodawanie nowego wymagania prawnego lub innego</md-button
        >
      </md-table-empty-state>

      <md-table-row
        slot="md-table-row"
        slot-scope="{ item }"
        md-selectable="single"
        :key="item.id"
      >
        <md-table-cell md-label="Id" md-sort-by="Id">
          {{ item.Id }}
        </md-table-cell>

        <md-table-cell md-label="Rodzaj emisji" md-sort-by="Rodzaj_emisji">
          {{ item.Rodzaj_emisji }}
        </md-table-cell>

        <md-table-cell md-label="Paliwo" md-sort-by="Rodzaj">
          {{ item.Rodzaj }}
        </md-table-cell>

        <md-table-cell md-label="Ilosc" md-sort-by="Ilosc">
           {{ item.Ilosc }}
        </md-table-cell>

      </md-table-row>
    </md-table>
  </div>
</template>

<script>
import modal from "../../strona-formularze/modal.vue";
import { api } from "../../../serwisy/api.serwis";
import jsPDF from "jspdf";
import "jspdf-autotable";

const toLower = (text) => {
  return text.toString().toLowerCase();
};

const search = (items, term) => {
  if (term) {
    return items.filter((item) => {
      const elementWyszukiwany = toLower(term);
      const Rodzaj_emisji = toLower(item.Rodzaj_emisji);
      return (
        Rodzaj_emisji.includes(elementWyszukiwany)
      );
    });
  }

  return items;
};

export default {
  name: "tabela_wykaz_procesow",
  data: () => ({
    czyPobranoDane: false,
    search: null,
    searched: [],
    Procesy: [],
    pokazDialog: false,
    typAktywnegoFormularza: null,
    obiektKonfiguracyjny: null,
    wybranyElementTabeli: null,
    Rok: null,
    Lata: [],
  }),
  components: {
    modal,
  },
  mounted: function() {
    this.pobierzDaneLat();
  },
  computed: {
    //informacja czy jest w trybie edycji
    czyJestWTrybieEdycji() {
      return this.$router.params.Id ? true : false;
    },
  },
  methods: {
    generujPdf() {
      const doc = new jsPDF("p", "mm", "a4");

      doc.setFont("Aleo-Regular");

      doc.text("Wykaz - ilość paliw do sprawozdanie w zakresie korzystania ze środowiska", 14, 10);
      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "silver",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            "Lp",
            "Rodzaj emisji",
            "Rodzaj paliwa",
            "Ilość",
          ],
        ],
        body: this.searched.map((Procesy) => {
          return [
            Procesy.Lp1,
            Procesy.Rodzaj_emisji,
            Procesy.Rodzaj,
            Procesy.Ilosc,
          ];
        }),
      });

      doc.save("wykaz_sprawozdanie_emisyjne.pdf");
    },
    pobierzDaneDoTabeli() {

        api
        .pobieranieEmisje(this.Rok)
        .then((obiektOdpowiedzi) => {
          this.Procesy = obiektOdpowiedzi.data.dane.map((maszyna, index) => ({
            ...maszyna,
            Lp1: index + 1,
          }));
          this.searched = this.Procesy;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.czyPobranoDane = true;
        });
    },
    pobierzDaneLat() {

        api
        .PobierzLataEmisje()
        .then((obiektOdpowiedzi) => {
          this.Lata = obiektOdpowiedzi.data.dane;
        })
        .catch((error) => {
          console.log(error);
        })
    },
    funkcjaZamykajacaDialog(sukces) {
      this.pokazDialog = false;
      this.typAktywnegoFormularza = null;
      this.obiektKonfiguracyjny = null;
      if (sukces) {
        this.pobierzDaneDoTabeli();
      }
    },
    newUser() {
      window.alert("Noop");
    },
    searchOnTable() {
      this.searched = search(this.Procesy, this.search);
    },
    usuwanieElementu(Id) {
      this.pokazDialog = true;
      this.typAktywnegoFormularza = "usuwanie";
      this.obiektKonfiguracyjny = {
        Id,
        typ: "wymagania_prawne",
      };
    },
    pokazPDF_INFO() {
      //dopisane
      this.pokazDialog = true;
      this.typAktywnegoFormularza = "pokaz_PDF_INFO";
      this.obiektKonfiguracyjny = {
        Nazwa_instrukcji: "INFO-3",
      };
    },

    wyborElementu(element) {
      this.wybranyElementTabeli = element;
    },
  },
};
</script>

<style>
.tabela_EMISJA .md-field {
  max-width: 300px;
}
.tabela_EMISJA .tabela {
  width: 100%;
}
.tabela_EMISJA .md-card {
  box-shadow: none !important;
}
.tabela_EMISJA .md-title {
  font-weight: 900 !important;
  color: black !important;
  font-size: 1.5vh !important;
  padding: 10px;
}
.tabela_EMISJA .md-toolbar {
  height: 17vh;
}
.tabela_EMISJA .md-table-head {
  font-weight: bold;
  color: black !important;
  font-size: 1.2vh !important;
  text-align: left !important;
}
.tabela_EMISJA .md-table-head-label {
  text-align: left !important;
  font-size: 1.2vh !important;
}
.tabela_EMISJA .md-table-cell {
  font-size: 1.2vh !important;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-align: left;
  color: black;
  font-weight: 300;
  margin-top: 200px !important;
}
.tabela_EMISJA .ikony {
  cursor: pointer;
  margin-right: 40px;
}

.tabela_EMISJA .md-table-cell:nth-child(1) {
  width: 6vw;
  text-align: left !important;
}
.tabela_EMISJA .md-table-cell:nth-child(2) {
  width: 54vw;
  text-align: left !important;
}
.tabela_EMISJA .md-table-cell:nth-child(3) {
  width: 20vw;
  text-align: left !important;
}
.tabela_EMISJA .md-table-cell:nth-child(4) {
  width: 20vw;
  text-align: left !important;
}

.tabela_EMISJA .button {
  margin-right: 5px;
  border: 3px white ridge;
  font-weight: bold;
  font-size: 1.1vmin;
  min-width: 9vw;
}
.tabela_EMISJA .button:hover {
  background-color: #727171;
  text-decoration: none;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  color: white;
}
.tabela_EMISJA .menu_strony {
  border: 1px solid;
  margin-top: 5px;
  padding: 3px;
  background: #939393;
  width: 84.5%;
}
.tabela_EMISJA .menu_strony1 {
  border: 1px solid;
  margin-top: 5px;
  padding: 3px;
  background: #939393;
  width: 15%;
  margin-left: 0.5%;
}
.tabela_EMISJA .laczacy {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.md-table.md-theme-default .md-table-row.md-selected,
.md-table.md-theme-default .md-table-row.md-selected-single {
  background-color: #dddddd !important;
}
.PasekWyboru {
  background: #606060;
}
.tabela_EMISJA .ikony {
  margin-right: 5px;
  margin-left: 5px;
  border: 1px solid !important;
  border-radius: 5px;
  padding: 5px;
}
.tabela_EMISJA .ikony:hover {
  background-color: black;
  text-decoration: none;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
.podpowiedz {
  font-size: 1.5vmin !important;
  font-weight: bold;
  background-color: black !important;
}
</style>
