<template >
  <div>
  <TabelaDokumenty />
  </div>
</template>

<script>
import TabelaDokumenty from '../../komponenty/strona-tabela-komponenty/SYS_dokumentacja/tabela_dokumentacja.vue'


export default {
  name: 'StronaTabelaDokumenty',
  components: {
    TabelaDokumenty,
    
  }

}
</script>

