<template >
  <div>
  <TabelaZleceniaMagazyn/>
  </div>
</template>

<script>
import TabelaZleceniaMagazyn from '../../komponenty/strona-tabela-komponenty/SO_zlecenia_na_magazyn/tabela_wykaz_zlecen_magazyn.vue'


export default {
  name: 'StronaTabelaZleceniaMagazyn',
  components: {
    TabelaZleceniaMagazyn,
    
  }

}
</script>

