<template >
  <div>
  <TabelaWylogowanie />
  </div>
</template>

<script>
import TabelaWylogowanie from '../../komponenty/strona-tabela-komponenty/LOGOWANIE/tabela_wylogowanie.vue'


export default {
  name: 'StronaTabelaWylogowanie',
  components: {
    TabelaWylogowanie,
    
  }

}
</script>

