<template>
  <div>
  <TabelaElektronarzedziaUbezpieczenia />
  </div>
</template>

<script>
import TabelaElektronarzedziaUbezpieczenia from '../../komponenty/strona-tabela-komponenty/WST_elektronarzedzia/tabela_elektronarzedzia_ubezpieczenia.vue'


export default {
  name: 'StronaTabelaElektronarzedziaUbezpieczenia',
  components: {
    TabelaElektronarzedziaUbezpieczenia,
  }

}
</script>
