<template>
  <div>
  <TabelaGranice/>
  </div>
</template>

<script>
import TabelaGranice from '../../komponenty/strona-tabela-komponenty/SYS_kontekst/tabela_granice.vue'


export default {
  name: 'StronaTabelaGranice',
  components: {
    TabelaGranice,
    
  }

}
</script>