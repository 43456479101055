<template>
  <div>
  <TabelaPomiarowkaSwiadectwo />
  </div>
</template>

<script>
import TabelaPomiarowkaSwiadectwo from '../../komponenty/strona-tabela-komponenty/WST_generator_sprawdzen/swiadectwo_sprawdzenia.vue'


export default {
  name: 'StronaTabelaPomiarowkaSwiadectwo',
  components: {
    TabelaPomiarowkaSwiadectwo,
  }

}
</script>
