<template>
  <div class="Form_SCSZCZZ">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI ZADANIA DLA CELU SZCZEGÓLOWEGO"
                : "FORMULARZ DODAWANIA ZADANIA DLA CELU SZCZEGÓLOWEGO"
            }}
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="MP1" :class="getValidationClass('Id_rok')">
                <label for="Id_rok">Wybór roku celów</label>
                <!--- edycja 3 linia-->
                
                <md-select
                  v-model="form.Id_rok"
                  :disabled="sending || czyJestWTrybieEdycji"
                  name="Id_rok"
                  id="Id_rok"
                  autocomplete="Id_rok"
                  md-dense
                  @input="pobierzCel()"
                >
                
                  <md-option
                  
                    v-for="(Rok, index) in Lata"
                    :key="index"
                    :value="Rok.Id"
                    >{{
                      ` ${Rok.Rok} `
                    }}</md-option
                  >
                  
                </md-select>
                <span class="md-error" v-if="!$v.form.Id_rok.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field class="MP1" :class="getValidationClass('Id_cel_szczegolowy')">
                <label for="Id_cel_szczegolowy">Wybór celu szczegółowego</label>
                <!--- edycja 3 linia-->
                
                <md-select
                  v-model="form.Id_cel_szczegolowy"
                  :disabled="sending || czyJestWTrybieEdycji"
                  name="Id_cel_szczegolowy"
                  id="Id_cel_szczegolowy"
                  autocomplete="Id_cel_szczegolowy"
                  @input="pobierzNumerZadaniaDlaCeluSzczegolowego"
                  md-dense
                >
                
                  <md-option
                  
                    v-for="(Cel_szczegolowy, index) in Cele_szczegolowe"
                    :key="index"
                    :value="Cel_szczegolowy.Id"
                    >{{
                      ` ${Cel_szczegolowy.Nr_celu} - ${Cel_szczegolowy.Cel_szczegolowy} `
                    }}</md-option
                  >
                  
                </md-select>
                <span class="md-error" v-if="!$v.form.Id_cel_szczegolowy.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

         <div class="md-layout md-gutter"> 
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="MP2"
                :class="getValidationClass('Nr_zadania')"
              >
                <label for="Nr_zadania">Numer zadania</label>
                <md-input

                  name="Nr_zadania"
                  id="Nr_zadania"
                  autocomplete="Nr_zadania"
                  md-counter="10"
                  v-model="form.Nr_zadania"
                  :disabled="sending"
                  readonly
                />
                <span class="md-error" v-if="!$v.form.Nr_zadania.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Nr_zadania.maxlength"
                  >Wymagana ilość znaków od 1 do 20</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="MP1"
                :class="getValidationClass('Zadanie')"
              >
                <label for="Zadanie">Zadanie</label>
                <md-textarea
                  name="Zadanie"
                  id="Zadanie"
                  autocomplete="Zadanie"
                  md-autogrow
                  md-counter="500"
                  v-model="form.Zadanie"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Zadanie.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Zadanie.maxlength"
                  >Wymagana ilość znaków od 1 do 500</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="MP1"
                :class="getValidationClass('Miernik')"
              >
                <label for="Miernik">Miernik</label>
                <md-textarea
                  name="Miernik"
                  id="Miernik"
                  autocomplete="Miernik"
                  md-autogrow
                  md-counter="500"
                  v-model="form.Miernik"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Miernik.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Miernik.maxlength"
                  >Wymagana ilość znaków od 1 do 500</span
                >
              </md-field>
            </div>
          </div>

            <div v-if="czyJestWTrybieEdycji" class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M5"
                :class="getValidationClass('Data_rozliczenia')"
              >
                <label for="Data_rozliczenia">Data rozliczenia celu</label>
                <md-input
                  type="date"
                  name="Data_rozliczenia"
                  id="Data_rozliczenia"
                  autocomplete="Data_rozliczenia"
                  v-model="form.Data_rozliczenia"
                  :disabled="sending"
                />

              </md-field>
            </div>
          </div>

          <div v-if="czyJestWTrybieEdycji" class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M5"
                :class="getValidationClass('Uwagi')"
              >
                <label for="Uwagi">Uwagi</label>
                <md-textarea
                  name="Uwagi"
                  id="Uwagi"
                  autocomplete="Uwagi"
                  md-autogrow
                  md-counter="500"
                  v-model="form.Uwagi"
                  :disabled="sending"
                />
                
                <span
                  class="md-error"
                  v-if="!$v.form.Uwagi.maxlength"
                  >Wymagana ilość znaków od 1 do 500</span
                >
              </md-field>
            </div>
          </div> 

        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

        <div style="text-align: center">
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            czyJestWTrybieEdycji
              ? "ZATWIERDŹ - EDYTOWANY WPIS"
              : "ZATWIERDŹ - UTWÓRZ WPIS"
          }}</md-button>
        </div>
      </md-card>
    </form>
  </div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import minLength from "vuelidate/lib/validators/minLength";
import { api } from "./../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_przegladu_maszyny", 
  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      Id_rok: null,
      Id_cel_szczegolowy: null,
      Nr_zadania: null,
      Zadanie: null,
      Miernik: null,
      Data_rozliczenia: null,
      Uwagi: '',
    },
    IdEdytowanegoElementu: null,
    nazwaPliku: null,
    userSaved: false,
    sending: false,
    lastUser: null,
    Lata: [],
    Cele_szczegolowe: [],
    Obiekt_generujacy_numer_zadania_dla_celu_szczegolowego: null,
  }),

  created: function () {
    if (this.obiektKonfiguracyjny.akcja === "edycja") {
      //edycja
      api
        .pobieranieZadaniaEdycja(this.obiektKonfiguracyjny.Id)
        .then((odpowiedz) => {
          const {
            Id,
            Id_rok,
            Id_cel_szczegolowy,
            Nr_zadania,
            Zadanie,
            Miernik,
            Data_rozliczenia,
            Uwagi,
          } = odpowiedz.data.dane[0];
          this.IdEdytowanegoElementu = Id;
          this.form.Id_rok = Id_rok;
          this.form.Id_cel_szczegolowy = Id_cel_szczegolowy;
          this.form.Nr_zadania = Nr_zadania;
          this.form.Zadanie = Zadanie;
          this.form.Miernik = Miernik;
          this.form.Data_rozliczenia = Data_rozliczenia;
          this.form.Uwagi = Uwagi;

        })
        .catch((error) => {
          console.log(error);
        });
    }
    api
      .pobierzWybórRoku()
      .then((odpowiedz) => {
        this.Lata = odpowiedz.data.dane;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  validations: {
    form: {
      Id_rok: {
        required,
      },
      Id_cel_szczegolowy: {
        required,
      },
      Nr_zadania: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(20),
      },
      Zadanie: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(500),
      },
      Miernik: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(500),
      },
      Data_rozliczenia: {
        required: required(false),
      },
      Uwagi: {
required: required(false),
        minLenght: minLength(1),
        maxLength: maxLength(500),
      },
    },
  },
  computed: {

    czyJestWTrybieEdycji: function () {
      return this.obiektKonfiguracyjny.akcja === "edycja";
    },
     numerZadaniaDlaCeluSzczegolowego: function () {
      if (this.Obiekt_generujacy_numer_zadania_dla_celu_szczegolowego) {
        const { numer } = this.Obiekt_generujacy_numer_zadania_dla_celu_szczegolowego;
        return `ZADANIE NR - ${numer}`;
      } else {
        return "";
      }
    },
  },
  
  methods: {
    pobierzNumerZadaniaDlaCeluSzczegolowego() {
      if(this.czyJestWTrybieEdycji){
        return 
      }
      const { Id_rok } = this.form;
      const { Id_cel_szczegolowy } = this.form;

    

      api.PobierzLpZadaniaDlaCeluSzczegolowego({ Id_rok, Id_cel_szczegolowy }).then((odpowiedz) => {
    
        this.Obiekt_generujacy_numer_zadania_dla_celu_szczegolowego = odpowiedz.data.dane;
        this.form.Nr_zadania = this.numerZadaniaDlaCeluSzczegolowego;
      });
    },

    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    pobierzCel() {
        const { Id_rok } = this.form;
      api.pobierzCeleSzczegolowe(Id_rok).then((Cele_szczegolowe) => {
        this.Cele_szczegolowe = Cele_szczegolowe.data.dane;
      });
    },


    clearForm() {
      this.$v.$reset();
      this.form.Id_rok = null;
      this.form.Id_cel_szczegolowy = null;
      this.form.Nr_zadania = null;
      this.form.Zadanie = null;
      this.form.Miernik = null;
      this.form.Data_rozliczenia = null;
      this.form.Uwagi = null;

    },

    zapiszFormularz() {
      this.sending = true;

      if (this.czyJestWTrybieEdycji) {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Id_rok", this.form.Id_rok);
        dane.append("Id_cel_szczegolowy", this.form.Id_cel_szczegolowy);
        dane.append("Nr_zadania", this.form.Nr_zadania);
        dane.append("Zadanie", this.form.Zadanie);
        dane.append("Miernik", this.form.Miernik);
        dane.append("Data_rozliczenia", this.form.Data_rozliczenia);
        dane.append("Uwagi", this.form.Uwagi);

        api
          .zapiszZadaniaeUpdate(dane, this.IdEdytowanegoElementu)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const dane = new FormData();
        dane.append("Id_rok", this.form.Id_rok);
        dane.append("Id_cel_szczegolowy", this.form.Id_cel_szczegolowy);
        dane.append("Nr_zadania", this.form.Nr_zadania);
        dane.append("Zadanie", this.form.Zadanie);
        dane.append("Miernik", this.form.Miernik);
        dane.append("Lp", this.Obiekt_generujacy_numer_zadania_dla_celu_szczegolowego.numer);

        api
        .zapiszCeleZadania(dane)
        .then(() => {
          this.clearForm();
          this.sending = false;
          this.funkcjaZamykajacaDialog(true);
        })
        .catch((error) => {
          console.log(error);
        });
      }
     },

    naszWalidacjaFormularza() {
      if (this.czyJestWTrybieEdycji) {
        return !this.$v.form.Id_rok.$invalid &&
         !this.$v.form.Id_cel_szczegolowy.$invalid &&
         !this.$v.form.Nr_zadania.$invalid &&
         !this.$v.form.Zadanie.$invalid &&
         !this.$v.form.Miernik.$invalid &&
         !this.$v.form.Uwagi.$invalid
      } else {
        return !this.$v.$invalid;
      }
    },
    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>

<style>
.Form_SCSZCZZ .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.Form_SCSZCZZ .md-layout .md-gutter {
  min-width: 55vw !important; /* szerokość modala*/
  max-width: 100% !important;
  min-height: 7vh; /* wysokość pomiędzy mierszami w modalu*/
}
.md-layout {
  padding-right: 0px !important;
}
.Form_SCSZCZZ .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}


.Form_SCSZCZZ .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  margin: 10px 24px !important;
  padding-top: 35px !important;
}
.Form_SCSZCZZ .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}

.Form_SCSZCZZ {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
  overflow: auto!important;
}
.Form_SCSZCZZ .md-primary {
  border: 1px solid !important;

  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}
.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important; /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}

.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}
.Form_SCSZCZZ .md-list-item-text {
  font-size: 1.7vmin !important;
}
.Form_SCSZCZZ .md-card-actions .md-button {
  margin-right: 50px !important;
}
.Form_SCSZCZZ .md-field.md-has-textarea:not(.md-autogrow) label {
    top: 16px;
    left: 0px;
}
</style>