<template >
  <div>
  <TabelaWykazKontroliZewnetrznychRealizacjaOS />
  </div>
</template>

<script>
import TabelaWykazKontroliZewnetrznychRealizacjaOS from '../../komponenty/strona-tabela-komponenty/BHP_kontrole_zewnetrzne/tabela_dzialania_kolntroli_zewnetrznych_ocena_skutecznosci.vue'


export default {
  name: 'StronaTabelaWykazKontroliZewnetrznychRealizacjaOS',
  components: {
    TabelaWykazKontroliZewnetrznychRealizacjaOS,
    
  }

}
</script>

