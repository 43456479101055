<template>
  <div>
  <TabelaElektronarzedziaPrzeglady />
  </div>
</template>

<script>
import TabelaElektronarzedziaPrzeglady from '../../komponenty/strona-tabela-komponenty/WST_elektronarzedzia/tabela_elektronarzedzia_przeglady.vue'


export default {
  name: 'StronaElektronarzedziaPrzeglady',
  components: {
    TabelaElektronarzedziaPrzeglady,
  }

}
</script>
