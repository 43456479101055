import { routes } from "../router";
import { api } from "./api.serwis";

class AuthorizationService {


  async getInfo(viewName, level) {
    try {
      const {meta: {params}} = routes.find(({name}) => name === viewName)
      switch (level) {
        case 1:
          return await api.autoryzacjaPoziom1(params);
        case 2:
          return await api.autoryzacjaPoziom2(params);
        case 3:
          return await api.autoryzacjaPoziom3(params);
        case 4:
          return await api.autoryzacjaPoziom4(params);
      }
    } catch (error) {
      console.log(error);
    }
  }
}

export const authorizationService = new AuthorizationService();
