<template>
  <div>
  <TabelaMaszynyUbezpieczenia />
  </div>
</template>

<script>
import TabelaMaszynyUbezpieczenia from '../../komponenty/strona-tabela-komponenty/WST_maszyny/tabela_maszyny_ubezpieczenia.vue'


export default {
  name: 'StronaTabelaMaszynyUbezpieczenia',
  components: {
    TabelaMaszynyUbezpieczenia,
    
  }

}
</script>
