<template>

  <TabelaPrzegladZakupy />

</template>

<script>
import TabelaPrzegladZakupy from '../../komponenty/strona-tabela-komponenty/SO_zakupy/tabela_przeglad_zakupy.vue'



export default {
  name: 'StronaTabelaPrzegladZakupy',
  components: {
    TabelaPrzegladZakupy,

    }

}
</script>
