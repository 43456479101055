<template>
  <div>
  <tabelaWykazKatalogZakresy/>

  </div>
</template>

<script>
import tabelaWykazKatalogZakresy from '../../komponenty/strona-tabela-komponenty/HR_zakresy/tabela_katalog_czesci_zakresu.vue'


export default {
  name: 'StronaTabelaWykazKatalogZakresy',
  components: {
       tabelaWykazKatalogZakresy,

  }

}
</script>
