var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"a3_cele_strategiczne",staticStyle:{"border":"1px solid","margin-top":"1vh"}},[_c('div',{staticClass:"tabela_CS"},[_c('div',{staticClass:"laczacy"},[_c('div',{staticClass:"menu_strony"},[_c('span',[_c('button',{staticClass:"button",attrs:{"disabled":!_vm.autoryzacja.czyMozeDodawac},on:{"click":function($event){_vm.pokazDialog = true;
              _vm.typAktywnegoFormularza =
                'formularz_dodawanie_celow_strategicznych_procesu';
              _vm.obiektKonfiguracyjny = {
                Id: null,
                akcja: 'dodawanie',
              };}}},[_vm._v(" DODAWANIE CELÓW STRATEGICZNYCH DLA PROCESU ")]),_c('md-tooltip',{staticClass:"podpowiedz",attrs:{"md-direction":"top"}},[_vm._v(_vm._s(!_vm.autoryzacja.czyMozeDodawac ? "BRAK UPRAWNIEŃ" : "DODAWANIE CELÓW STRATEGICZNYCH DLA PROCESU"))])],1)]),_c('div',{staticClass:"menu_strony1"},[_c('b',[_c('b-icon-printer-fill',{staticClass:"ikony",attrs:{"font-scale":"2.1","color":"white"},on:{"click":_vm.generujPdf}}),_c('md-tooltip',{staticClass:"podpowiedz",attrs:{"md-direction":"top"}},[_vm._v("GENERUJ PDF / DRUKUJ")])],1),_c('b',[_c('b-icon-info-circle-fill',{staticClass:"ikony",attrs:{"font-scale":"2.1","color":"white"},on:{"click":_vm.pokazPDF_INFO}}),_c('md-tooltip',{staticClass:"podpowiedz",attrs:{"md-direction":"top"}},[_vm._v("GENERUJ PDF / DRUKUJ")])],1)])]),(_vm.pokazDialog)?_c('modal',{attrs:{"rodzajFormularza":_vm.typAktywnegoFormularza,"funkcjaZamykajacaDialog":_vm.funkcjaZamykajacaDialog,"obiektKonfiguracyjny":_vm.obiektKonfiguracyjny}}):_vm._e(),(_vm.pokazDialog)?_c('modalKontekst',{attrs:{"rodzajFormularza":_vm.typAktywnegoFormularza,"funkcjaZamykajacaDialog":_vm.funkcjaZamykajacaDialog,"obiektKonfiguracyjny":_vm.obiektKonfiguracyjny}}):_vm._e(),_c('md-table',{attrs:{"md-height":"20vh","md-model-id":"Id_maszyny","md-card":"","md-fixed-header":""},on:{"md-selected":_vm.wyborElementu},scopedSlots:_vm._u([{key:"md-table-row",fn:function(ref){
              var item = ref.item;
return _c('md-table-row',{key:item.id,attrs:{"md-selectable":"single"}},[_c('md-table-cell',{attrs:{"md-label":"Lp"}},[_vm._v(" "+_vm._s(item.Lp)+" ")]),_c('md-table-cell',{attrs:{"md-label":"Nr procesu"}},[_vm._v(" "+_vm._s(item.Nr_procesu)+" ")]),_c('md-table-cell',{attrs:{"md-label":"Cel"}},[_vm._v(" "+_vm._s(item.Cel)+" ")]),_c('md-table-cell',{attrs:{"md-label":"Opis realizacji"}},[_vm._v(" "+_vm._s(item.Opis)+" ")]),_c('md-table-cell',{attrs:{"md-label":"Wskaźnik"}},[_vm._v(" "+_vm._s(item.Wskaznik)+" ")]),_c('md-table-cell',{attrs:{"md-label":"Wartosc oczekiwana"}},[_vm._v(" "+_vm._s(item.Wartosc_oczekiwana)+" ")]),_c('md-table-cell',{attrs:{"md-label":"Okres rozliczeniowy"}},[_vm._v(" "+_vm._s(item.Okres)+" ")]),_c('md-table-cell',{attrs:{"md-label":"Okres rozliczeniowy"}},[_vm._v(" "+_vm._s(item.Data_oceny)+" ")])],1)}}]),model:{value:(_vm.searched),callback:function ($$v) {_vm.searched=$$v},expression:"searched"}},[_c('md-table-toolbar',[_c('div',{staticClass:"d-flex flex-column w-100"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h1',{staticClass:"md-title"},[_vm._v(" CELE STRATEGICZNE DLA PROCESU ")])])]),(_vm.wybranyElementTabeli)?_c('div',{staticClass:"PasekWyboru"},[_c('div',{staticStyle:{"text-align":"right","margin-top":"0.5vh","margin-bottom":"0.5vh"}},[_c('b',[_c('b-button',{attrs:{"disabled":!_vm.autoryzacja.czyMozeUsuwac},on:{"click":function($event){return _vm.usuwanieElementu(_vm.wybranyElementTabeli.Id)}}},[_c('b-icon-trash',{staticClass:"ikony",attrs:{"font-scale":"1.8","color":"white"}})],1),_c('md-tooltip',{staticClass:"podpowiedz",attrs:{"md-direction":"top"}},[_vm._v(_vm._s(_vm.autoryzacja.czyMozeUsuwac ? "USUWANIE ELEMENTU" : "BRAK UPRAWNIEŃ"))])],1),_c('b',[_c('b-button',{attrs:{"disabled":!_vm.autoryzacja.czyMozeEdytowac},on:{"click":function($event){_vm.pokazDialog = true;
                    _vm.typAktywnegoFormularza =
                      'formularz_dodawanie_celow_strategicznych_procesu';
                    _vm.obiektKonfiguracyjny = {
                      Id: _vm.wybranyElementTabeli.Id,
                      akcja: 'edycja',
                    };}}},[_c('b-icon-pencil-square',{staticClass:"ikony",attrs:{"font-scale":"1.8","color":"white"}}),_c('md-tooltip',{staticClass:"podpowiedz",attrs:{"md-direction":"top"}},[_vm._v("EDYCJA ELEMENTU")])],1),_c('md-tooltip',{staticClass:"podpowiedz",attrs:{"md-direction":"top"}},[_vm._v(_vm._s(_vm.autoryzacja.czyMozeUsuwac ? "EDYCJA ELEMENTU" : "BRAK UPRAWNIEŃ"))])],1),_c('b',[_c('b-button',[_c('b-icon-bounding-box',{staticClass:"ikony",attrs:{"font-scale":"1.8","color":"white"},on:{"click":function($event){return _vm.$router.push(
                        ("../procesy_rozliczenie_celu/" + (_vm.wybranyElementTabeli.Id))
                      )}}})],1),_c('md-tooltip',{staticClass:"podpowiedz",attrs:{"md-direction":"top"}},[_vm._v("ROZLICZENIE CELU STRATEGICZNEGO")])],1)])]):_vm._e()])]),(_vm.czyPobranoDane)?_c('md-table-empty-state',{attrs:{"md-label":"Nie posiadasz wpisów w wykazie celów strategicznych dla procesu"}},[_c('md-button',{staticClass:"md-primary md-raised",attrs:{"disabled":!_vm.autoryzacja.czyMozeDodawac},on:{"click":function($event){_vm.pokazDialog = true;
            _vm.typAktywnegoFormularza =
              'formularz_dodawanie_celow_strategicznych_procesu';
            _vm.obiektKonfiguracyjny = {
              Id: null,
              akcja: 'dodawanie',
            };}}},[_vm._v("Dodawanie celu strategicznego dla procesu")]),_c('md-tooltip',{staticClass:"podpowiedz",attrs:{"md-direction":"top"}},[_vm._v(_vm._s(!_vm.autoryzacja.czyMozeDodawac ? "BRAK UPRAWNIEŃ" : "DODAWANIECELÓW STRATEGICZNYCH DLA PROCESU"))])],1):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }