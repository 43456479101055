<template>
  <div>
  <TabelaPomiarowkaPrzeglady />
  </div>
</template>

<script>
import TabelaPomiarowkaPrzeglady from '../../komponenty/strona-tabela-komponenty/WST_pomiarowka/tabela_pomiarowka_przeglady.vue'


export default {
  name: 'StronaTabelaPomiarowkaPrzeglady',
  components: {
    TabelaPomiarowkaPrzeglady,
  }

}
</script>
