<template >
  <div>
  <TabelaRyzykaSzanse />
  </div>
</template>

<script>
import TabelaRyzykaSzanse from '../../komponenty/strona-tabela-komponenty/SYS_ryzyka_szanse/tabela_ryzyka_szanse.vue'


export default {
  name: 'StronaTabelaRyzykaSzanse',
  components: {
    TabelaRyzykaSzanse,
    
  }

}
</script>

