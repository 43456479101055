<template>
  <div
    class="a1_dane_wejsciowe"
    style="border:1px solid;margin-top:1vh;margin-bottom:10vh;width: 49.75%;"
  >
    <div class="tabela_DWEJ">
      <div class="menu_strony">
        <span>
          <button
            class="button"
            :disabled="!autoryzacja.czyMozeDodawac"
            @click="
              pokazDialog = true;
              typAktywnegoFormularza =
                'formularz_dodawanie_danych_wejsciowych_procesu';
              obiektKonfiguracyjny = {
                Id: null,
                akcja: 'dodawanie',
              };
            "
          >
            DODAWANIE DANYCH WEJŚCIOWYCH PROCESU
          </button>
          <md-tooltip class="podpowiedz" md-direction="top">{{
            !autoryzacja.czyMozeDodawac
              ? "BRAK UPRAWNIEŃ"
              : "DODAWANIE DANYCH WEJŚCIOWYCH PROCESU"
          }}</md-tooltip>
        </span>
      </div>

      <modal
        v-if="pokazDialog"
        :rodzajFormularza="typAktywnegoFormularza"
        :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
        :obiektKonfiguracyjny="obiektKonfiguracyjny"
      />
      <modalKontekst
        v-if="pokazDialog"
        :rodzajFormularza="typAktywnegoFormularza"
        :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
        :obiektKonfiguracyjny="obiektKonfiguracyjny"
      />
      <md-table
        md-height="33vh"
        @md-selected="wyborElementu"
        v-model="searched"
        md-model-id="Id_maszyny"
        md-card
        md-fixed-header
      >
        <md-table-toolbar>
          <div class="d-flex flex-column w-100">
            <div class="row">
              <div class="col-12">
                <h1 class="md-title">
                  DANE WEJŚCIOWE PROCESU
                </h1>
              </div>
            </div>

            <div class="PasekWyboru" v-if="wybranyElementTabeli">
              <div
                style="text-align: right; margin-top: 0.5vh; margin-bottom: 0.5vh"
              >
                <b>
                  <b-button
                    :disabled="!autoryzacja.czyMozeUsuwac"
                    @click="usuwanieElementu(wybranyElementTabeli.Id)"
                  >
                    <b-icon-trash class="ikony" font-scale="1.8" color="white">
                    </b-icon-trash
                  ></b-button>
                  <md-tooltip class="podpowiedz" md-direction="top">{{
                    autoryzacja.czyMozeUsuwac
                      ? "USUWANIE ELEMENTU"
                      : "BRAK UPRAWNIEŃ"
                  }}</md-tooltip>
                </b>
                <b>
                  <b-button
                    :disabled="!autoryzacja.czyMozeEdytowac"
                    @click="
                      pokazDialog = true;
                      typAktywnegoFormularza =
                        'formularz_dodawanie_danych_wejsciowych_procesu';
                      obiektKonfiguracyjny = {
                        Id: wybranyElementTabeli.Id,
                        akcja: 'edycja',
                      };
                    "
                  >
                    <b-icon-pencil-square
                      class="ikony"
                      font-scale="1.8"
                      color="white"
                    >
                    </b-icon-pencil-square
                  ></b-button>
                  <md-tooltip class="podpowiedz" md-direction="top">{{
                    autoryzacja.czyMozeUsuwac
                      ? "EDYCJA ELEMENTU"
                      : "BRAK UPRAWNIEŃ"
                  }}</md-tooltip>
                </b>
              </div>
            </div>
          </div>
        </md-table-toolbar>

        <md-table-empty-state
          v-if="czyPobranoDane"
          md-label="Nie posiadasz wpisów w wykazie danych wejściowych procesu"
        >
          <md-button
            class="md-primary md-raised"
            :disabled="!autoryzacja.czyMozeDodawac"
            @click="
              pokazDialog = true;
              typAktywnegoFormularza =
                'formularz_dodawanie_danych_wejsciowych_procesu';
              obiektKonfiguracyjny = {
                Id: null,
                akcja: 'dodawanie',
              };
            "
            >Dodawanie danych wejściowych</md-button
          >
          <md-tooltip class="podpowiedz" md-direction="top">{{
            !autoryzacja.czyMozeDodawac
              ? "BRAK UPRAWNIEŃ"
              : "DODAWANIE DANYCH WEJŚCIOWYCH PROCESU"
          }}</md-tooltip>
        </md-table-empty-state>

        <md-table-row
          slot="md-table-row"
          slot-scope="{ item }"
          md-selectable="single"
          :key="item.id"
        >
          <md-table-cell md-label="Lp">
            {{ item.Lp }}
          </md-table-cell>

          <md-table-cell md-label="Nr procesu">
            {{ item.Nr_procesu }}
          </md-table-cell>

          <md-table-cell md-label="Dane wejściowe">
            {{ item.Dane_wejsciowe }}
          </md-table-cell>
        </md-table-row>
      </md-table>
    </div>
  </div>
</template>

<script>
import modal from "../../strona-formularze/modal.vue";
import modalKontekst from "../../strona-formularze/modalKontekst.vue";
import { authorizationService } from "./../../../serwisy/authorization.servis";
import { api } from "./../../../serwisy/api.serwis";

export default {
  name: "tabela_elektronarzedzia",
  data: () => ({
    czyPobranoDane: false,
    search: null,
    searched: [],
    Dane_wejsciowe: [],
    pokazDialog: false,
    typAktywnegoFormularza: null,
    obiektKonfiguracyjny: null,
    wybranyElementTabeli: null,
    autoryzacja: {
      czyMozeDodawac: false,
      czyMozeUsuwac: false,
      czyMozeEdytowac: false,
      czyMozePrzegladac: false,
    },
  }),
  components: {
    modal,
    modalKontekst,
  },

  mounted: function() {
    this.pobierzDaneDoTabeli();
    this.pobierzDaneAutoryzacyjne();
  },
  computed: {
    //informacja czy jest w trybie edycji
    czyJestWTrybieEdycji() {
      return this.$router.params.Id ? true : false;
    },
  },
  methods: {
    pobierzDaneAutoryzacyjne() {
      authorizationService
        .getInfo("StronaTabelaProcesyDaneWejsciowe", 4)

        .then((obiektOdpowiedzi) => {
          const dane = obiektOdpowiedzi.data.dostep;
          this.autoryzacja = {
            czyMozeDodawac: Boolean(
              dane.find(({ Id_operacji }) => Id_operacji == "1")
            ),
            czyMozeUsuwac: Boolean(
              dane.find(({ Id_operacji }) => Id_operacji == "2")
            ),
            czyMozeEdytowac: Boolean(
              dane.find(({ Id_operacji }) => Id_operacji == "3")
            ),
            czyMozePrzegladac: Boolean(
              dane.find(({ Id_operacji }) => Id_operacji == "4")
            ),
          };
        })
        .catch((error) => {
          console.log(error);
        });
    },
    pobierzDaneDoTabeli() {
      const { id } = this.$route.params;

      console.log(this.$route);
      api
        .pobieranieProcesuDaneWejsciowe(id)
        .then((obiektOdpowiedzi) => {
          console.log(obiektOdpowiedzi);
         this.Dane_wejsciowe = obiektOdpowiedzi.data.dane.map((maszyna, index) => ({
            ...maszyna,
            Lp: index + 1,
          }));
          this.searched = this.Dane_wejsciowe;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.czyPobranoDane = true;
        });
    },
    funkcjaZamykajacaDialog(sukces) {
      this.pokazDialog = false;
      this.typAktywnegoFormularza = null;
      this.obiektKonfiguracyjny = null;
      if (sukces) {
        this.pobierzDaneDoTabeli();
      }
    },
    newUser() {
      window.alert("Noop");
    },

    usuwanieElementu(Id) {
      this.pokazDialog = true;
      this.typAktywnegoFormularza = "usuwanie";
      this.obiektKonfiguracyjny = {
        Id,
        typ: "Dane_wejsciowe",
      };
    },

    wyborElementu(element) {
      this.wybranyElementTabeli = element;
    },
  },
};
</script>

<style>
.tabela_DWEJ .md-field {
  max-width: 300px;
}
.tabela_DWEJ {
  width: 98%;
  margin-left: 1%;
  margin-right: 1%;
}
.tabela_DWEJ .md-card {
  box-shadow: none !important;
}
.tabela_DWEJ .md-title {
  font-weight: 900 !important;
  color: black !important;
  font-size: 1.8vh !important;
}
.tabela_DWEJ .md-toolbar {
  height: 6vh;
}
.tabela_DWEJ .md-table-head {
  font-weight: bold;
  color: black !important;
  font-size: 1.2vh !important;
  text-align: left !important;
}
.tabela_DWEJ .md-table-head-label {
  text-align: left !important;
  font-size: 1.2vh !important;
}
.tabela_DWEJ .md-table-cell {
  font-size: 1.2vh !important;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-align: left;
  color: black;
  font-weight: 300;
  margin-top: 200px !important;
}
.tabela_DWEJ .ikony {
  cursor: pointer;
  margin-right: 40px;
}

.tabela_DWEJ .md-table-cell:nth-child(1) {
  width: 7vw;
  text-align: left !important;
}
.tabela_DWEJ .md-table-cell:nth-child(2) {
  width: 14vw;
  text-align: left !important;
}
.tabela_DWEJ .md-table-cell:nth-child(3) {
  width: 69vw;
  text-align: left !important;
}

.tabela_DWEJ .button {
  margin-right: 3px;
  border: 3px white ridge;
  font-weight: bold;
  font-size: 1vmin;
  min-width: 7vw;
}
.tabela_DWEJ .button:hover {
  background-color: #727171;
  text-decoration: none;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  color: white;
}
.tabela_DWEJ .menu_strony {
  border: 1px solid;
  margin-top: 5px;
  padding: 3px;
  background: #939393;
}
.md-table.md-theme-default .md-table-row.md-selected,
.md-table.md-theme-default .md-table-row.md-selected-single {
  background-color: #dddddd !important;
}
.PasekWyboru {
  background: #606060;
}
.tabela_DWEJ .ikony {
  margin-right: 4px;
  margin-left: 4px;
  border: 1px solid !important;
  border-radius: 5px;
  padding: 5px;
}
.tabela_DWEJ .ikony:hover {
  background-color: black;
  text-decoration: none;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
.podpowiedz {
  font-size: 1.5vmin !important;
  font-weight: bold;
  background-color: black !important;
}
.tabela_DWEJ .md-empty-state-label {
  font-size: 1.5vh !important;
  padding: 0 !important;
}
.tabela_DWEJ .md-empty-state {
  padding: 3px !important;
}
.tabela_DWEJ .md-button-content {
  font-size: 1.5vh !important;
}
.tabela_DWEJ .md-button {
  height: auto !important;
  padding: 2px !important;
}
.btn {
  background-color: #606060 !important;
  padding: 0rem 0rem !important;
  border-color: #606060 !important;
}
</style>
