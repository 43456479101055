<template>
  <div>
  <TabelaBudynkiMedia/>
  </div>
</template>

<script>
import TabelaBudynkiMedia from '../../komponenty/strona-tabela-komponenty/WST_budynki/tabela_budynki_media.vue'


export default {
  name: 'StronaTabelaBudynkiMedia',
  components: {
    TabelaBudynkiMedia,
  }

}
</script>
