<template>
  <div class="FORM_CHORZAW">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI CHOROBY ZAWODOWEJ"
                : "FORMULARZ DODAWANIA CHOROBY ZAWODOWEJ"
            }}
          </div>
        </md-card-header>

        <md-card-content>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-autocomplete
                :class="getValidationClass('Nazwa_wybor_pracownika')"
                v-model="form.Nazwa_wybor_pracownika"
                :md-options="zapytaniaPracownicy"
                :disabled="sending"
              >
                <label>Wybór pracownika</label>
                <span
                  class="md-error"
                  v-if="!$v.form.Nazwa_wybor_pracownika.required"
                  >Pole jest wymagane</span
                >
              </md-autocomplete>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-autocomplete
                :class="getValidationClass('Nazwa_wybor_stanowiska')"
                v-model="form.Nazwa_wybor_stanowiska"
                :md-options="zapytaniaStanowiska"
                :disabled="sending"
              >
                <label>Wybór stanowiska</label>
                <span
                  class="md-error"
                  v-if="!$v.form.Nazwa_wybor_stanowiska.required"
                  >Pole jest wymagane</span
                >
              </md-autocomplete>
            </div>
          </div>
          <div class="md-layout md-gutter">
            
             <div class="md-layout-item md-small-size-100"
            >
              <md-field
                class="M5"
                :class="getValidationClass('Data_stwierdzenia')"
              >
                <label for="Data_stwierdzenia">Data stwierdzenia choroby</label>
                <md-input
                  type="date"
                  name="Data_stwierdzenia"
                  id="Data_stwierdzenia"
                  autocomplete="Data_stwierdzenia"
                  v-model="form.Data_stwierdzenia"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Data_stwierdzenia.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
            
            <div class="md-layout-item md-small-size-100"
            >
              <md-field
                class="M5"
                :class="getValidationClass('Data_decyzji')"
              >
                <label for="Data_decyzji">Data decyzji PIS</label>
                <md-input
                  type="date"
                  name="Data_decyzji"
                  id="Data_decyzji"
                  autocomplete="Data_decyzji"
                  v-model="form.Data_decyzji"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Data_decyzji.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

           

            <div class="md-layout-item md-small-size-100"
            >
              <md-field
                class="M5"
                :class="getValidationClass('Nr_decyzji')"
              >
                <label for="Nr_decyzji">Nr decyzji PIS</label>
                <md-input
                  
                  name="Nr_decyzji"
                  id="Nr_decyzji"
                  autocomplete="Nr_decyzji"
                  md-counter="50"
                  v-model="form.Nr_decyzji"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Nr_decyzji.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Nr_decyzji.maxlength"
                  >Wymagana ilość znaków od 1 do 50</span
                >
              </md-field>
            </div>
          </div>

    

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M2"
                :class="getValidationClass('Nr_choroby')"
              >
                <label for="Nr_choroby">Nr statystyczny i nazwa choroby</label>
                <md-textarea

                  name="Nr_choroby"
                  id="Nr_choroby"
                  
                  md-autogrow
                  md-counter="200"
                  v-model="form.Nr_choroby"
              
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Nr_choroby.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Nr_choroby.maxlength"
                  >Wymagana ilość znaków od 1 do 200</span
                >
              </md-field>
            </div>
          </div>

          

            <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="plikZwalidowany ? '' : 'md-invalid'">
                <label
                  >Wstaw plik - DECYZJA PIS  "max-2Mb"
                  <span v-if="nazwaPliku">
                    - obecnie wstawiony plik to {{ nazwaPliku }}</span
                  ></label
                >
                <md-file
                  v-model="form.plik_PDF"
                  @md-change="zapamietajPlik"
                  accept="application/pdf"
                />
                <span class="md-error" v-if="!plikZwalidowany"
                  >Plik moze miec max 2MB</span
                >
              </md-field>
            </div>
          </div>

          
        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

        
          <div style="text-align: center">
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            czyJestWTrybieEdycji
              ? "ZATWIERDŹ - EDYTOWANY WPIS"
              : "ZATWIERDŹ - UTWÓRZ WPIS"
          }}</md-button>
        </div>
      </md-card>

    </form>
  </div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import minLength from "vuelidate/lib/validators/minLength";
import { api } from "../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_maszyny",

  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      Nazwa_wybor_pracownika: null,
      Nazwa_wybor_stanowiska: null,
      Data_stwierdzenia: null,
      Data_decyzji: null,
      Nr_decyzji: null,
      Nr_choroby: null,
      plik_PDF: null,
      plik_dane: null,


    },
    IdEdytowanegoElementu: null,
    userSaved: false,
    nazwaPliku: null,
    sending: false,
    lastUser: null,
    Pracownicy:[],
    Stanowiska:[],

  }),
  async created() {
    try {
      const [odpowiedz_Pracownik, odpowiedz_Stanowisko] = await Promise.all([
        api.pobierzPracownika(),
        api.pobierzStanowiskoPracy(),
      ]);
      odpowiedz_Pracownik,
        (this.Pracownicy = odpowiedz_Pracownik.data.dane.map((el) => ({
          ...el,
          nazwa: `${el.Nazwisko} ${el.Imie1} - NIP: ${el.NIP} ; PESEL: ${el.Pesel}`,
        })));

      odpowiedz_Stanowisko,
        (this.Stanowiska = odpowiedz_Stanowisko.data.dane.map((el) => ({
          ...el,
          nazwa1: `${el.Stanowisko}`,
        })));


      if (this.obiektKonfiguracyjny.akcja === "edycja") {
        //edycja
        const odpowiedz = await api.pobieranieDokumentacjaCHZAWEdycja(
          this.obiektKonfiguracyjny.Id
        );
        const {
          Id,
          Id_pracownika,
          Id_stanowiska,
          Data_stwierdzenia,
          Data_decyzji,
          Nr_decyzji,
          Nr_choroby,
          Nazwa_zalacznika,
        } = odpowiedz.data.dane[0];
        this.IdEdytowanegoElementu = Id;
        this.form.Nazwa_wybor_pracownika = this.Pracownicy.find(
          ({ Id }) => Id == Id_pracownika
        ).nazwa;
        this.form.Nazwa_wybor_stanowiska = this.Stanowiska.find(
          ({ Id }) => Id == Id_stanowiska
        ).nazwa1;
        this.form.Data_stwierdzenia = Data_stwierdzenia;
        this.form.Data_decyzji = Data_decyzji;
        this.form.Nr_decyzji = Nr_decyzji;
        this.form.Nr_choroby = Nr_choroby;
        this.nazwaPliku = Nazwa_zalacznika;

      }
    } catch (error) {
      console.log(error);
    }
  },
  validations: {
    form: {
      Nazwa_wybor_pracownika: {
        required,
      },
      Nazwa_wybor_stanowiska: {
        required,
      },
      Data_stwierdzenia: {
        required,
      },
      Data_decyzji: {
        required,
      },
      Nr_decyzji: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(50),
      },
      Nr_choroby: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(200),
      },
    },
  },
  computed: {
    plikZwalidowany: function () {
      return this.form.plik_dane
        ? this.form.plik_dane.size < 2 * 1024 * 1024
        : true;
    },
    
    Id_pracownika: function() {
      const { Nazwa_wybor_pracownika } = this.form;
      const znalezionyElement = this.Pracownicy.find(
        ({ nazwa }) => nazwa === Nazwa_wybor_pracownika
      );

      if (znalezionyElement) {
        return znalezionyElement.Id;
      }
      return null;
    },
    zapytaniaPracownicy: function() {
      return this.Pracownicy.map((el) => el.nazwa);
    },

    Id_stanowiska: function() {
      const { Nazwa_wybor_stanowiska } = this.form;
      const znalezionyElement = this.Stanowiska.find(
        ({ nazwa1 }) => nazwa1 === Nazwa_wybor_stanowiska
      );

      if (znalezionyElement) {
        return znalezionyElement.Id;
      }
      return null;
    },
    zapytaniaStanowiska: function() {
      return this.Stanowiska.map((el) => el.nazwa1);
    },
    czyJestWTrybieEdycji: function () {
      return this.obiektKonfiguracyjny.akcja === "edycja";
    },
  },

  methods: {
    zapamietajPlik(pliki) {
      if (pliki[0]) {
        this.form.plik_dane = pliki[0];
      }
    },
  
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.Id_pracownika = null;
      this.form.Id_stanowiska = null;
      this.form.Data_stwierdzenia= null;
      this.form.Data_decyzji = null;
      this.form.Nr_decyzji = null;
      this.form.Nr_choroby = null;
      this.form.plik_PDF = null;
      this.form.plik_dane = null;
    },

    zapiszFormularz() {
      this.sending = true;

      if (this.czyJestWTrybieEdycji) {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Id_pracownika", this.Id_pracownika);
        dane.append("Id_stanowiska", this.Id_stanowiska);
        dane.append("Data_stwierdzenia", this.form.Data_stwierdzenia);
        dane.append("Nr_decyzji", this.form.Nr_decyzji);
        dane.append("Data_decyzji", this.form.Data_decyzji);
        dane.append("Nr_choroby", this.form.Nr_choroby);
        dane.append("plik_PDF", this.form.plik_dane);

        api
          .zapiszDokumentacjaCHZAWUpdate(dane, this.IdEdytowanegoElementu)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Id_pracownika", this.Id_pracownika);
        dane.append("Id_stanowiska", this.Id_stanowiska);
        dane.append("Data_stwierdzenia", this.form.Data_stwierdzenia);
        dane.append("Nr_decyzji", this.form.Nr_decyzji);
        dane.append("Data_decyzji", this.form.Data_decyzji);
        dane.append("Nr_choroby", this.form.Nr_choroby);
        dane.append("plik_PDF", this.form.plik_dane);

        api
          .zapiszDokumentacjaCHZAW(dane)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    naszWalidacjaFormularza() {
      if (this.czyJestWTrybieEdycji) {
        return (
          !this.$v.form.Nazwa_wybor_pracownika.$invalid &&
          !this.$v.form.Nazwa_wybor_stanowiska.$invalid &&
          !this.$v.form.Data_stwierdzenia.$invalid &&
          !this.$v.form.Nr_decyzji.$invalid &&
          !this.$v.form.Data_decyzji.$invalid &&
          !this.$v.form.Nr_choroby.$invalid &&
          this.plikZwalidowany
        );
      } else {
        return !this.$v.$invalid && 
        this.plikZwalidowany
      }
    },
    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>


<style>
.FORM_CHORZAW .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.FORM_CHORZAW .md-layout .md-gutter {
  min-width: 55vw !important; /* szerokość modala*/
  max-width: 100% !important;
  min-height: 7vh; /* wysokość pomiędzy mierszami w modalu*/
}
.md-layout {
  padding-right: 0px !important;
}
.FORM_CHORZAW .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}

.FORM_CHORZAW .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  margin: 10px 24px !important;
  padding-top: 35px !important;
}
.FORM_CHORZAW .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}
.FORM_CHORZAW {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
  overflow: auto;
}
.FORM_CHORZAW .md-primary {
  border: 1px solid !important;
  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}
.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important; /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}

.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}
.md-list-item-text {
  font-size: 1.7vmin !important;
}
.FORM_CHORZAW .md-card-actions .md-button {
  margin-right: 4vw !important;
}
</style>