<template>
  <div>
  <ElementyWyboruLogowanie />
  </div>
</template>

<script>
import ElementyWyboruLogowanie from '../komponenty/strona-glowna-logowanie/Elementy_wyboru.vue'


export default {
  name: 'StronaGlownaLogowanie',
  components: {
    ElementyWyboruLogowanie

  }
}
</script>
