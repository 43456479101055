<template>
  <div>
  <ElementyWyboruHRB />
  </div>
</template>

<script>
import ElementyWyboruHRB from '../komponenty/strona-glowna-hrb/Elementy_wyboru_HRB.vue'


export default {
  name: 'StronaGlownaHRB',
  components: {
    ElementyWyboruHRB

  }
}
</script>
