<template >
  <div>
  <TabelaLogowanieDostep />
  </div>
</template>

<script>
import TabelaLogowanieDostep from '../../komponenty/strona-tabela-komponenty/LOGOWANIE/tabela_wykaz_dostepu.vue'


export default {
  name: 'StronaTabelaLogowanieDostep',
  components: {
    TabelaLogowanieDostep,
    
  }

}
</script>

