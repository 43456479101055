<template >
  <div>
  <TabelaDzialaniaAuditOcena />
  </div>
</template>

<script>
import TabelaDzialaniaAuditOcena from '../../komponenty/strona-tabela-komponenty/SYS_dzialania_audit/tabela_dzialania_audit_ocena.vue'


export default {
  name: 'StronaTabelaDzialaniaAudit',
  components: {
    TabelaDzialaniaAuditOcena,
    
  }

}
</script>

