<template>
  <div class="style_sumowanie">
  <md-table-toolbar>
        <div>
              <h1 class="md-title">WYKAZ ZUŻYCIA PALIWA</h1>
            </div>
            </md-table-toolbar>

    <md-table>
      <md-table-row>
        <md-table-head>Typ</md-table-head>
        <md-table-head>Suma paliwa</md-table-head>
        <md-table-head>Suma pracy</md-table-head>
        <md-table-head>Średnie zużycie</md-table-head>
      </md-table-row>

      <md-table-row v-for="(klucz, index) of klucze" :key="index">
        <md-table-head>{{ klucz }}</md-table-head>
        <md-table-cell>{{ parseFloat(sumy.SumaIlosciPaliwa[klucz]).toFixed(2)  }}</md-table-cell>
        <md-table-cell>{{ parseFloat(sumy.SumaIlosciPracy[klucz]).toFixed(2)  }}</md-table-cell>
        <md-table-cell>{{ parseFloat((sumy.SumaIlosciPaliwa[klucz] / sumy.SumaIlosciPracy[klucz])*100).toFixed(2) }}</md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
import { api } from "../../../serwisy/api.serwis";
import moment from 'moment';
export default {
  name: "Formularz_sumowania_paliwa",
  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },

  data: () => ({
    sumy: null,
    klucze: null,
  }),
  created(){
  const dodawanie_od = this.obiektKonfiguracyjny.data_od ? moment(this.obiektKonfiguracyjny.data_od).format('YYYY-MM-DD') : null;
   const dodawanie_do = this.obiektKonfiguracyjny.data_do ? moment(this.obiektKonfiguracyjny.data_do).format('YYYY-MM-DD') : null;
    api
      .sumowaniePaliwaSamochodyCiezarowe(this.obiektKonfiguracyjny.Id, dodawanie_od, dodawanie_do)
      .then((odpowiedz) => {
        console.log(odpowiedz)
        this.sumy = odpowiedz.data.dane;
        this.klucze = Object.keys(odpowiedz.data.dane.SumaIlosciPracy);
        
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style>
.style_sumowanie {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
}
.style_sumowanie .md-table-head
{
  font-size: 1.6vh!important;
  color: black!important;
}
.style_sumowanie .md-table-cell
{
  font-size: 1.5vh!important;
  text-align: right;
}
.style_sumowanie .md-title
{
  font-weight: bold!important;
  color: blue!important;
  text-align: center;
  font-size: 2vh!important;
}
</style>