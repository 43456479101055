<template>
  <div>
  <tabelaWykazSytuacjeDzialania/>

  </div>
</template>

<script>
import tabelaWykazSytuacjeDzialania from '../../komponenty/strona-tabela-komponenty/BHP_sytuacje_potencjalnie_wypadkowe/tabela_dzialania_sytuacje.vue'


export default {
  name: 'StronaTabelaWykazSytuacjeDzialania',
  components: {
       tabelaWykazSytuacjeDzialania,

  }

}
</script>
