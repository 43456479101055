<template>
  <div>
  <TabelaWyposazeniePrzegladyPPOZ />
  </div>
</template>

<script>
import TabelaWyposazeniePrzegladyPPOZ from '../../komponenty/strona-tabela-komponenty/WST_wyposazeniePPOZ/tabela_wyposazeniePPOZ_przeglady.vue'


export default {
  name: 'StronaTabelaWyposazeniePrzegladyPPOZ',
  components: {
    TabelaWyposazeniePrzegladyPPOZ,
    
  }

}
</script>
