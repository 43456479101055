<template>
  <div>
  <tabelaWykazWypadkiWPracyOcena/>

  </div>
</template>

<script>
import tabelaWykazWypadkiWPracyOcena from '../../komponenty/strona-tabela-komponenty/BHP_wypadki_w_pracy/tabela_dzialania_wypadki_ocena.vue'


export default {
  name: 'StronaTabelaWykazWypadkiWPracyOcena',
  components: {
       tabelaWykazWypadkiWPracyOcena,

  }

}
</script>
