<template >
  <div>
  <TabelaWykazPracownicyAdresKorespondencja />
  </div>
</template>

<script>
import TabelaWykazPracownicyAdresKorespondencja from '../../komponenty/strona-tabela-komponenty/HR_pracownicy/tabela_adres_korespondencja_pracownika.vue'


export default {
  name: 'StronaTabelaWykazAdresKorespondencja',
  components: {
    TabelaWykazPracownicyAdresKorespondencja,
    
  }

}
</script>

