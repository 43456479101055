<template>
  <div class="FormPlanAuditu">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI PLANU AUDITU"
                : "FORMULARZ DODAWANIA PLANU AUDITU"
            }}
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="MP1" :class="getValidationClass('Id_auditu')">
                <label for="Id_auditu">Wybór auditu</label>
                <!--- edycja 3 linia-->

                <md-select
                  v-model="form.Id_auditu"
                  :disabled="sending || czyJestWTrybieEdycji"
                  name="Id_auditu"
                  id="Id_auditu"
                  autocomplete="Id_auditu"
                  md-dense
                >
                  <md-option
                    v-for="(Audit, index) in Audity"
                    :key="index"
                    :value="Audit.Id"
                    >{{ ` ${Audit.Nr_auditu} ` }}</md-option
                  >
                </md-select>
                <span class="md-error" v-if="!$v.form.Id_auditu.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="MP2" :class="getValidationClass('Data')">
                <label for="Data">Data</label>
                <md-input
                  type="date"
                  name="Data"
                  id="Data"
                  autocomplete="Data"
                  v-model="form.Data"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Data.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field
                class="MP2"
                :class="getValidationClass('Godzina_rozpoczecia')"
              >
                <label for="Godzina_rozpoczecia">Godzina rozpoczęcia</label>
                <md-input
                  type="time"
                  name="Godzina_rozpoczecia"
                  id="Godzina_rozpoczecia"
                  autocomplete="Godzina_rozpoczecia"
                  v-model="form.Godzina_rozpoczecia"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Godzina_rozpoczecia.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field
                class="MP2"
                :class="getValidationClass('Godzina_zakonczenia')"
              >
                <label for="Godzina_zakonczenia">Godzina zakończenia</label>
                <md-input
                  type="time"
                  id="Godzina_zakonczenia"
                  name="Godzina_zakonczenia"
                  autocomplete="Godzina_zakonczenia"
                  v-model="form.Godzina_zakonczenia"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Godzina_zakonczenia.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item">
              <md-field>
                <label for="Norma9001"
                  >WYBÓR PUNKTÓW NORMY PN-EN ISO 9001:2015</label
                >

                <md-select
                  v-model="form.Norma9001"
                  name="Norma9001"
                  id="Norma9001"
                  multiple
                >
                  <md-option
                    v-for="(norma9001, index) in Normy9001"
                    :key="index"
                    :value="norma9001.Id"
                    >{{
                      ` ${norma9001.Punkt9001} - ${norma9001.Wymaganie9001} `
                    }}</md-option
                  >
                </md-select>
              </md-field>

            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item">
              <md-field>
                <label for="Norma14001"
                  >WYBÓR PUNKTÓW NORMY PN-EN ISO 14001:2015</label
                >

                <md-select
                  class="select"
                  v-model="form.Norma14001"
                  name="Norma14001"
                  id="Norma14001"
                  multiple
                >
                  <md-option
                    v-for="(norma14001, index) in Normy14001"
                    :key="index"
                    :value="norma14001.Id"
                    >{{
                      ` ${norma14001.Punkt14001} - ${norma14001.Wymaganie14001} `
                    }}</md-option
                  >
                </md-select>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item">
              <md-field>
                <label for="Norma45001"
                  >WYBÓR PUNKTÓW NORMY PN-ISO 45001:2018</label
                >

                <md-select
                  class="select"
                  v-model="form.Norma45001"
                  name="Norma45001"
                  id="Norma45001"
                  multiple
                >
                  <md-option
                    v-for="(norma45001, index) in Normy45001"
                    :key="index"
                    :value="norma45001.Id"
                    >{{
                      ` ${norma45001.Punkt45001} - ${norma45001.Wymaganie45001} `
                    }}</md-option
                  >
                </md-select>
              </md-field>
     
            </div>
          </div>
        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

        <div style="text-align: center">
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            czyJestWTrybieEdycji
              ? "ZATWIERDŹ - EDYTOWANY WPIS"
              : "ZATWIERDŹ - UTWÓRZ WPIS"
          }}</md-button>
        </div>
      </md-card>
    </form>
  </div>
</template>



<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { api } from "./../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_przegladu_maszyny",
  mixins: [validationMixin],
  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      Id_auditu: null,
      Data: null,
      Godzina_rozpoczecia: null,
      Godzina_zakonczenia: null,
      Norma9001: null,
      Norma14001: null,
      Norma45001: null,
    },

    userSaved: false,
    sending: false,
    lastUser: null,
    Audity: [],
    Normy9001: [],
    Normy14001: [],
    Normy45001: [],
  }),

  created: function () {
    if (this.obiektKonfiguracyjny.akcja === "edycja") {
      //edycja
      api
        .pobieraniePlanAudityEdycja(this.obiektKonfiguracyjny.Id)
        .then((odpowiedz) => {
          const {
            Norma9001,
            Norma14001,
            Norma45001,
            plan_auditu,

            

          } = odpowiedz.data.dane;

          this.form.Id_auditu = plan_auditu[0].Id_auditu;
          this.form.Data = plan_auditu[0].Data;
          this.form.Godzina_rozpoczecia = plan_auditu[0].Godzina_rozpoczecia;
          this.form.Godzina_zakonczenia = plan_auditu[0].Godzina_zakonczenia;
          this.form.Norma9001 = Norma9001.map(({Id_normy_9001})=>Id_normy_9001);
          this.form.Norma14001 = Norma14001.map(({Id_normy_14001})=>Id_normy_14001);
          this.form.Norma45001 = Norma45001.map(({Id_normy_45001})=>Id_normy_45001);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    Promise.all([
      api.pobierzNrAuditu(),
      api.pobierzNorme9001(),
      api.pobierzNorme14001(),
      api.pobierzNorme45001(),
    ])
      .then(
        ([
          odpowiedz_NrAuditu,
          odpowiedz_9001,
          odpowiedz_14001,
          odpowiedz_45001,
        ]) => {
          this.Audity = odpowiedz_NrAuditu.data.dane;
          this.Normy9001 = odpowiedz_9001.data.dane;
          this.Normy14001 = odpowiedz_14001.data.dane;
          this.Normy45001 = odpowiedz_45001.data.dane;
        }
      )
      .catch((error) => {
        console.log(error);
      });
  },
  validations: {
    form: {
      Id_auditu: {
        required,
      },
      Data: {
        required,
      },
      Godzina_rozpoczecia: {
        required,
      },
      Godzina_zakonczenia: {
        required,
      },
    },
  },
  computed: {
    czyJestWTrybieEdycji: function () {
      return this.obiektKonfiguracyjny.akcja === "edycja";
    },
  },

  methods: {
    pokazTooltipDlaNormy(elementy, nazwaZboruNorm){
      return (elementy || []).map(el => {
        const znalezionyPunktNormy = this[nazwaZboruNorm].find(({Id}) => el === Id);
        return "<p>"+znalezionyPunktNormy.Punkt9001+ " "+ znalezionyPunktNormy.Wymaganie9001+"</p>";
      })
    },
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.Id_auditu = null;
      this.form.Data = null;
      this.form.Godzina_rozpoczecia = null;
      this.form.Godzina_zakonczenia = null;
      this.form.Norma9001 = null;
      this.form.Norma14001 = null;
      this.form.Norma45001 = null;
    },
    wyslijFormularzZNowymiDanymi() {
      const dane = {};
      dane.Id_auditu = this.form.Id_auditu;
      dane.Data = this.form.Data;
      dane.Godzina_rozpoczecia = this.form.Godzina_rozpoczecia;
      dane.Godzina_zakonczenia = this.form.Godzina_zakonczenia;
      dane.Norma9001 = this.form.Norma9001 || [];
      dane.Norma14001 = this.form.Norma14001 || [];
      dane.Norma45001 = this.form.Norma45001 || [];

      api
        .zapiszPlanAudity(dane)
        .then(() => {
          this.clearForm();
          this.sending = false;
          this.funkcjaZamykajacaDialog(true);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    wyslijFormularzEdycji() {
      const dane = {};
      dane.Id_auditu = this.form.Id_auditu;
      dane.Data = this.form.Data;
      dane.Godzina_rozpoczecia = this.form.Godzina_rozpoczecia;
      dane.Godzina_zakonczenia = this.form.Godzina_zakonczenia;
      dane.Norma9001 = this.form.Norma9001 || [];
      dane.Norma14001 = this.form.Norma14001 || [];
      dane.Norma45001 = this.form.Norma45001 || [];

      api
        .zapiszPlanAudityUpdate(dane, this.obiektKonfiguracyjny.Id)
        .then(() => {
          this.clearForm();
          this.sending = false;
          this.funkcjaZamykajacaDialog(true);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    zapiszFormularz() {
      this.sending = true;

      this.czyJestWTrybieEdycji
        ? this.wyslijFormularzEdycji()
        : this.wyslijFormularzZNowymiDanymi();
    },

    naszWalidacjaFormularza() {
      if (this.czyJestWTrybieEdycji) {
        return (
          !this.$v.form.Id_auditu.$invalid &&
          !this.$v.form.Data.$invalid &&
          !this.$v.form.Godzina_rozpoczecia.$invalid &&
          !this.$v.form.Godzina_zakonczenia.$invalid 
        );
      } else {
        return !this.$v.$invalid;
      }
    },
    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>

<style>
.FormPlanAuditu .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.FormPlanAuditu .md-layout .md-gutter {
 min-width: 55vw !important; /* szerokość modala*/
  max-width: 100% !important;
  min-height: 7vh; /* wysokość pomiędzy mierszami w modalu*/
}
.md-layout {
  padding-right: 0px !important;
}
.FormPlanAuditu .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}

.FormPlanAuditu .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  margin: 10px 24px !important;
  padding-top: 35px !important;
}
.FormPlanAuditu .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}

.FormPlanAuditu {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
  overflow: auto;
}
.FormPlanAuditu .md-primary {
  border: 1px solid !important;

  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}
.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important; /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}

.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}
.FormPlanAuditu .md-list-item-text {
  font-size: 1.7vmin !important;
}
.FormPlanAuditu .md-card-actions .md-button {
  margin-right: 50px !important;
}
.FormPlanAuditu .md-field.md-has-textarea:not(.md-autogrow) label {
  top: 16px;
  left: 0px;
}

.lewastrona {
  float: left;
}

.prawastrona {
  float: right;
}

</style>