<template>
  <div class="Form_RaportKontrola">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI RAPORTU Z KONTROLI WEWNĘTRZNEJ BHP"
                : "FORMULARZ DODAWANIA RAPORTU Z KONTROLI WEWNĘTRZNEJ BHP"
            }}
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('Data_kontroli')">
                <label for="Data_kontroli">Data raportu z kontroli</label>
                <md-input
                  type="date"
                  name="Data_kontroli"
                  id="Data_kontroli"
                  
                  v-model="form.Data_kontroli"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Data_kontroli.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('Miejsce_kontroli')">
                <label for="Miejsce_kontroli">Miejsce kontroli</label>
                <md-textarea
                  name="Miejsce_kontroli"
                  id="Miejsce_kontroli"
                  
                  md-autogrow
                  md-counter="200"
                  v-model="form.Miejsce_kontroli"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Miejsce_kontroli.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Miejsce_kontroli.maxlength"
                  >Wymagana ilość znaków od 1 do 200</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('Kontrole_przeprowadzil')">
                <label for="Kontrole_przeprowadzil"
                  >Kontrole przeprowadzil</label
                >
                <md-textarea
                  name="Kontrole_przeprowadzil"
                  id="Kontrole_przeprowadzil"
                  
                  md-autogrow
                  md-counter="200"
                  v-model="form.Kontrole_przeprowadzil"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Kontrole_przeprowadzil.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Kontrole_przeprowadzil.maxlength"
                  >Wymagana ilość znaków od 1 do 200</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('Zakres_kontroli')">
                <label for="Zakres_kontroli">Zakres_kontroli</label>
                <md-textarea
                  name="Zakres_kontroli"
                  id="Zakres_kontroli"
                  
                  md-autogrow
                  md-counter="200"
                  v-model="form.Zakres_kontroli"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Zakres_kontroli.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Zakres_kontroli.maxlength"
                  >Wymagana ilość znaków od 1 do 200</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="plikZwalidowany ? '' : 'md-invalid'">
                <label
                  >Wstaw plik - protokół z kontroli "max-2Mb"
                  <span v-if="nazwaPliku">
                    - obecnie wstawiony plik to {{ nazwaPliku }}</span
                  ></label
                >
                <md-file
                  v-model="form.plik_PDF"
                  @md-change="zapamietajPlik"
                  accept="application/pdf"
                />
                <span class="md-error" v-if="!plikZwalidowany"
                  >Plik moze miec max 2MB</span
                >
              </md-field>
            </div>
          </div>
          <div v-if="!czyJestWTrybieEdycji">
            <div
              style="
              margin-top: 40px;
              margin-left: 25px;
              font-weight: bold;
              font-size: 16px;
            "
            >
              SZCZEGÓŁY RAPORTU - STWIERDZONE UWAGI
            </div>

            <div
              v-for="(wiersz, index) in form.wiersze"
              :key="index"
              :set="(v = $v.form.wiersze.$each[index])"
            >
              <div
                style="
                color: blue;
                font-weight: bold;
                font-size: 1.5vh;
                margin-left: 25px;
                margin-top: 20px;
        
              "
              >
                {{ `POZYCJA NR: ${index + 1} ` }}

                <b-icon-plus-square
                  class="ikony"
                  font-scale="1.4"
                  style="border: 1px black"
                  color="black"
                  @click="dodajWiersz()"
                >
                </b-icon-plus-square>

                <b-icon-trash
                  class="ikony"
                  font-scale="1.4"
                  color="black"
                  @click="usunWiersz(wiersz)"
                >
                </b-icon-trash>
              </div>

              <div class="md-layout md-gutter">
                <div class="md-layout-item md-small-size-100">
                  <md-field :class="getValidationClass('Uwagi', index)">
                    <label for="Uwagi">Uwagi</label>
                    <md-textarea
                      name="Uwagi"
                      id="Uwagi"
                      md-autogrow
                      md-counter="500"
                      autocomplete="Uwagi"
                      v-model="wiersz.Uwagi"
                      :disabled="sending"
                    />
                    <span class="md-error" v-if="!v.Uwagi.required"
                      >Pole jest wymagane</span
                    >
                    <span class="md-error" v-else-if="!v.Uwagi.maxlength"
                      >Wymagana ilość znaków od 1 do 500</span
                    >
                  </md-field>
                </div>
              </div>
            </div>
          </div>
        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

        <div style="text-align: center">
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            czyJestWTrybieEdycji
              ? "ZATWIERDŹ - EDYTOWANY WPIS"
              : "ZATWIERDŹ - UTWÓRZ WPIS"
          }}</md-button>
        </div>
      </md-card>
    </form>
  </div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import minLength from "vuelidate/lib/validators/minLength";
import { api } from "../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_faktury",

  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      Data_kontroli: null,
      Miejsce_kontroli: null,
      Kontrole_przeprowadzil: null,
      Zakres_kontroli: null,
      plik_PDF: null,
      plik_dane: null,
      wiersze: [
        {
          Uwagi: null,
        },
      ],
    },
    IdEdytowanegoElementu: null,
    userSaved: false,
    sending: false,
    lastUser: null,
    nazwaPliku: null,
  }),
  created: function() {
    if (this.obiektKonfiguracyjny.akcja === "edycja") {
      //edycja
      api
        .pobieranieKontroliEdycja(this.obiektKonfiguracyjny.Id)
        .then((odpowiedz) => {
          const {
            Id,
            Data_kontroli,
            Miejsce_kontroli,
            Kontrole_przeprowadzil,
            Zakres_kontroli,
            Nazwa_zalacznika,
            PozycjeZapytania,
          } = odpowiedz.data.dane[0];
          this.IdEdytowanegoElementu = Id;
          this.form.Data_kontroli = Data_kontroli;
          this.form.Miejsce_kontroli = Miejsce_kontroli;
          this.form.Kontrole_przeprowadzil = Kontrole_przeprowadzil;
          this.form.Zakres_kontroli = Zakres_kontroli;
          this.nazwaPliku = Nazwa_zalacznika;
          if (PozycjeZapytania.length !== 0) {
            this.form.wiersze = PozycjeZapytania;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },

  validations: {
    form: {
      Data_kontroli: {
        required,
      },
      Miejsce_kontroli: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(200),
      },
      Kontrole_przeprowadzil: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(200),
      },
      Zakres_kontroli: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(200),
      },
      wiersze: {
        $each: {
          Uwagi: {
            required,
            minLenght: minLength(1),
            maxLength: maxLength(500),
          },
        },
      },
    },
  },
  computed: {
    plikZwalidowany: function() {
      return this.form.plik_dane
        ? this.form.plik_dane.size < 2 * 1024 * 1024
        : true;
    },
    czyJestWTrybieEdycji: function() {
      return this.obiektKonfiguracyjny.akcja === "edycja";
    },
  },

  methods: {
    zapamietajPlik(pliki) {
      if (pliki[0]) {
        this.form.plik_dane = pliki[0];
      }
    },

    dodajWiersz() {
      console.log("robi");
      const nowyWiersz = {};
      Object.keys(this.form.wiersze[0]).forEach((key) => {
        nowyWiersz[key] = null;
      });
      const noweWiersze = [...this.form.wiersze, nowyWiersz];
      this.form.wiersze = noweWiersze;
    },
    usunWiersz(wiersz) {
      if (this.form.wiersze.length <= 1) {
        const nowyWiersz = {};
        Object.keys(this.form.wiersze[0]).forEach((key) => {
          nowyWiersz[key] = null;
        });
        this.form.wiersze = [nowyWiersz];
        return;
      }

      this.form.wiersze = this.form.wiersze.filter((el) => el !== wiersz);
    },

    getValidationClass(fieldName, index) {
      const field =
        this.$v.form[fieldName] || this.$v.form.wiersze.$each[index][fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.Data_kontroli = null;
      this.form.Miejsce_kontroli = null;
      this.form.Kontrole_przeprowadzil = null;
      this.form.Zakres_kontroli = null;
      this.form.plik_PDF = null;
      this.form.plik_dane = null;
      this.nazwaPliku = null;
      this.form.wiersze = [];
    },

    async zapiszFormularz() {
      this.sending = true;
      const PozycjeZapytania = this.form.wiersze.map((el) => ({
        ...el,
      }));
      let file = null;
      if (this.form.plik_dane) {
        file = await new Promise((resolve) => {
          const fileReader = new FileReader();
          fileReader.onload = () => {
            resolve(fileReader.result);
          };
          fileReader.readAsDataURL(this.form.plik_dane);
        });
      }

      if (this.czyJestWTrybieEdycji) {
        const daneDoWyslania = {
          Data_kontroli: this.form.Data_kontroli,
          Miejsce_kontroli: this.form.Miejsce_kontroli,
          Kontrole_przeprowadzil: this.form.Kontrole_przeprowadzil,
          Zakres_kontroli: this.form.Zakres_kontroli,
          plik_PDF: file,
          PlikNazwa: this.form.plik_dane ? this.form.plik_dane.name : null,
          PozycjeZapytania: this.form.wiersze,
        };

        api
          .zapiszKontroliUpdate(daneDoWyslania, this.IdEdytowanegoElementu)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const daneDoWyslania = {
          Data_kontroli: this.form.Data_kontroli,
          Miejsce_kontroli: this.form.Miejsce_kontroli,
          Kontrole_przeprowadzil: this.form.Kontrole_przeprowadzil,
          Zakres_kontroli: this.form.Zakres_kontroli,
          plik_PDF: file,
          PlikNazwa: this.form.plik_dane ? this.form.plik_dane.name : null,
          PozycjeZapytania,
        };

        api
          .zapiszDokumentacjaKontroli(daneDoWyslania)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    naszWalidacjaFormularza() {
      if (this.czyJestWTrybieEdycji) {
        return (
          !this.$v.form.Data_kontroli.$invalid &&
          !this.$v.form.Miejsce_kontroli.$invalid &&
          !this.$v.form.Kontrole_przeprowadzil.$invalid &&
          !this.$v.form.Zakres_kontroli.$invalid &&
          !this.$v.form.wiersze.$invalid &&
          this.plikZwalidowany
        );
      } else {
        return !this.$v.$invalid && this.plikZwalidowany;
      }
    },
    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>
<style>
.Form_RaportKontrola .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.Form_RaportKontrola .md-layout .md-gutter {
  min-width: 55vw !important; /* szerokość modala*/
  max-width: 100% !important;
  min-height: 7vh; /* wysokość pomiędzy mierszami w modalu*/
}
.md-layout {
  padding-right: 0px !important;
}
.Form_RaportKontrola .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}

.Form_RaportKontrola .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  margin: 10px 24px !important;
  padding-top: 35px !important;
}

.Form_RaportKontrola .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
  opacity: 0;
  transform: translate3d(0, -8px, 0);
}

.Form_RaportKontrola {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
  overflow: auto;
}
.Form_RaportKontrola .md-primary {
  border: 1px solid !important;
  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}
.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important; /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}
.md-layout .md-gutter {
  min-width: 1100px !important;
}
.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}
.md-list-item-text {
  font-size: 1.7vmin !important;
}
.Form_RaportKontrola .md-card-actions .md-button {
  margin-right: 50px !important;
}

.Form_RaportKontrola .ikony {
  cursor: pointer;
  margin-right: 5px;
  margin-left: 15px;
}
.md-field.md-has-textarea:not(.md-autogrow):after,
.md-field.md-has-textarea:not(.md-autogrow):before {
  border: none !important;
  border-bottom: 0.5px solid !important;
  height: 90px !important;
  font-size: 12px !important;
}
.md-field.md-has-textarea:not(.md-autogrow) .md-textarea {
  min-height: 40px !important;
  padding: 0 0px !important;
  resize: vertical !important;
}
.md-input {
  width: 100% !important;
}
</style>
