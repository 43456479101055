<template>
  <div>
  <tabelaUczestnicy/>

  </div>
</template>

<script>
import tabelaUczestnicy from '../../komponenty/strona-tabela-komponenty/HR_szkolenia/tabela_wykaz_uczestnikow.vue'


export default {
  name: 'StronaTabelaUczestnicy',
  components: {
       tabelaUczestnicy,
  }

}
</script>
