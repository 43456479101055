<template>
  <div>
  <tabelaZakresObowiazkow/>

  </div>
</template>

<script>
import tabelaZakresObowiazkow from '../../komponenty/strona-tabela-komponenty/HR_zakresy/zakres.vue'


export default {
  name: 'StronaTabelaZakresObowiazkow',
  components: {
       tabelaZakresObowiazkow,

  }

}
</script>
