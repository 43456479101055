<template>
  <div>
  <tabelaWykazChorobyZawodowe/>

  </div>
</template>

<script>
import tabelaWykazChorobyZawodowe from '../../komponenty/strona-tabela-komponenty/BHP_choroby_zawodowe/tabela_zachorowan_choroby_zawodowe.vue'


export default {
  name: 'StronaTabelaWykazChorobyZawodowe',
  components: {
       tabelaWykazChorobyZawodowe,

  }

}
</script>
