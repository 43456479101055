<template>
  <div>
  <TabelaMaszynyDozorowePrzeglady />
  </div>
</template>

<script>
import TabelaMaszynyDozorowePrzeglady from '../../komponenty/strona-tabela-komponenty/WST_maszyny_dozorowe/tabela_maszyny_dozorowe_przeglady.vue'


export default {
  name: 'StronaTabelaMaszynyDozorowePrzeglady',
  components: {
    TabelaMaszynyDozorowePrzeglady,
  }

}
</script>
