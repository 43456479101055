<template>
  <div class="PR_dane_wyjsciowe">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI DANYCH WYJŚCIOWYCH PROCESU SYSTEMU ZARZĄDZANIA"
                : "FORMULARZ DODAWANIA DANYCH WYJŚCIOWYCH PROCESU SYSTEMU ZARZĄDZANIA"
            }}
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-gutter">
           <div class="md-layout-item md-small-size-100">
              <md-field class="M4" :class="getValidationClass('Id_procesu')">
                <label for="Id_procesu">Wybór procesu</label>

                <md-select
                  v-model="form.Id_procesu"
                  :disabled="sending"
                  name="Id_procesu"
                  id="Id_procesu"
                  autocomplete="Id_procesu"
                  md-dense
                >
                  <md-option
                    v-for="(proces, index) in Procesy"
                    :key="index"
                    :value="proces.Id"
                    >{{ 
                      ` ${proces.Nr_procesu} - ${proces.Nazwa_procesu} ` 
                      }}</md-option
                  >
                </md-select>
                <span class="md-error" v-if="!$v.form.Id_procesu.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

               <div class="md-layout md-gutter">
            <div
              
              class="md-layout-item md-small-size-100"
            >
              <md-field
                class="M5"
                :class="getValidationClass('Dane_wyjsciowe')"
              >
                <label for="Dane_wyjsciowe">Dane wyjściowe</label>
                <md-textarea
                  name="Dane_wyjsciowe"
                  id="Dane_wyjsciowe"
                  autocomplete="Dane_wyjsciowe"
                  md-autogrow
                  md-counter="300"
                  v-model="form.Dane_wyjsciowe"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Dane_wyjsciowe.required"
                  >Pole jest wymagane</span
                >
                <span class="md-error" v-else-if="!$v.form.Dane_wyjsciowe.maxlength"
                  >Wymagana ilość znaków od 1 do 300</span
                >
              </md-field>
            </div>
          </div>

        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

       <div style="text-align: center">
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            czyJestWTrybieEdycji
              ? "ZATWIERDŹ - EDYTOWANY WPIS"
              : "ZATWIERDŹ - UTWÓRZ WPIS"
          }}</md-button>
        </div>
      </md-card>

    </form>
  </div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import minLength from "vuelidate/lib/validators/minLength";
import { api } from "./../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_maszyny",

  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      Id_procesu: null,
      Dane_wyjsciowe: null,
    },
    IdEdytowanegoElementu: null,
    userSaved: false,
    sending: false,
    lastUser: null,
    Procesy: [],
  }),
  created: function () {
    if (this.obiektKonfiguracyjny.akcja === "edycja") {
      //edycja
      api
        .pobieranieProcesuDaneWyjscioweEdycja(this.obiektKonfiguracyjny.Id)
        .then((odpowiedz) => {
          const {
            Id,
            Id_procesu,
            Dane_wyjsciowe,
          } = odpowiedz.data.dane[0];
          this.IdEdytowanegoElementu = Id;
          this.form.Id = Id_procesu;
          this.form.Id_procesu = Id_procesu;
          this.form.Dane_wyjsciowe = Dane_wyjsciowe;

        })
        .catch((error) => {
          console.log(error);
        });
    }

    api
      .pobierzProces()
      .then((odpowiedz) => {
        this.Procesy = odpowiedz.data.dane;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  validations: {
    form: {
      Id_procesu: {
        required,
      },
      Dane_wyjsciowe: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(300),
      },

    },
  },
  computed: {

    czyJestWTrybieEdycji: function () {
      return this.obiektKonfiguracyjny.akcja === "edycja";
    },
  },

  methods: {
 
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.Id_procesu = null;
      this.form.Dane_wyjsciowe = null;
    },

    zapiszFormularz() {
      this.sending = true;

      if (this.czyJestWTrybieEdycji) {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Id_procesu", this.form.Id_procesu);
        dane.append("Dane_wyjsciowe", this.form.Dane_wyjsciowe);

        api
          .zapiszProcesDaneWyjscioweUpdate(dane, this.IdEdytowanegoElementu)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Id_procesu", this.form.Id_procesu);
        dane.append("Dane_wyjsciowe", this.form.Dane_wyjsciowe);

        api
          .zapiszProcesDaneWyjsciowe(dane)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    naszWalidacjaFormularza() {
      if (this.czyJestWTrybieEdycji) {
        return !this.$v.form.Id_procesu.$invalid &&
         !this.$v.form.Dane_wyjsciowe.$invalid 
      } else {
        return !this.$v.$invalid;
      }
    },

    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>


<style>
.PR_dane_wyjsciowe .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
.PR_dane_wyjsciowe
{
  overflow: auto;
}
.PR_dane_wyjsciowe .md-layout .md-gutter {
  min-width: 55vw !important; /* szerokość modala*/
  max-width: 100% !important;
  min-height: 7vh; /* wysokość pomiędzy mierszami w modalu*/
}
.md-layout {
  padding-right: 0px !important;
}
.PR_dane_wyjsciowe .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}

.PR_dane_wyjsciowe .M1 {
  max-width: 100% !important;
  min-width: 100% !important;
}
.PR_dane_wyjsciowe .M2 {
  max-width: 100% !important;
  min-width: 100% !important;
}
.PR_dane_wyjsciowe .M3 {
  min-width: 97%;
  max-width: 97%;
  min-height: 5vh;
  font-size: 2vh !important;
}

.PR_dane_wyjsciowe .M4 {
  max-width: 100% !important;
  min-width: 100% !important;
}
.PR_dane_wyjsciowe .M5 {
  max-width: 100% !important;
  min-width: 100% !important;
}
.PR_dane_wyjsciowe .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  margin: 10px 24px !important;
  padding-top: 35px !important;
}
.PR_dane_wyjsciowe .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}



.PR_dane_wyjsciowe {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
}
.PR_dane_wyjsciowe .md-primary {
  border: 1px solid !important;

  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}
.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important; /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}

.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}
.md-list-item-text {
  font-size: 1.7vmin !important;
}
.PR_dane_wyjsciowe .md-card-actions .md-button {
  margin-right: 4vw !important;
}
</style>