<template>
  <div>
  <TabelaMaszynyKoszty />
  </div>
</template>

<script>
import TabelaMaszynyKoszty from '../../komponenty/strona-tabela-komponenty/WST_maszyny/tabela_maszyny_koszty.vue'


export default {
  name: 'StronaTabelaMaszynyKoszty',
  components: {
    TabelaMaszynyKoszty,
    
  }

}
</script>
