<template>
  <div class="Form_ZOOIU">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI ZAKRESU OBOWIĄZKÓW, ODPOWIEDZIALNOŚCI I UPRAWNIEŃ PRACOWNIKA"
                : "FORMULARZ DODAWANIA ZAKRESU OBOWIĄZKÓW, ODPOWIEDZIALNOŚCI I UPRAWNIEŃ PRACOWNIKA"
            }}
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-autocomplete
                :class="getValidationClass('Nazwa_wybor_pracownika')"
                v-model="form.Nazwa_wybor_pracownika"
                :md-options="zapytaniaPracownicy"
                :disabled="sending || czyJestWTrybieEdycji"
              >
                <label>Wybor pracownika</label>
                <span
                  class="md-error"
                  v-if="!$v.form.Nazwa_wybor_pracownika.required"
                  >Pole jest wymagane</span
                >
              </md-autocomplete>
            </div>
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-autocomplete
                :class="getValidationClass('Nazwa_wybor_stanowiska')"
                v-model="form.Nazwa_wybor_stanowiska"
                :md-options="zapytaniaStanowiska"
                :disabled="sending"
              >
                <label>Wybor stanowiska pracy</label>
                <span
                  class="md-error"
                  v-if="!$v.form.Nazwa_wybor_stanowiska.required"
                  >Pole jest wymagane</span
                >
              </md-autocomplete>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('Data_zakresu')">
                <label for="Data_zakresu">Data zakresu</label>
                <md-input
                  type="date"
                  name="Data_zakresu"
                  id="Data_zakresu"
                  autocomplete="Data_zakresu"
                  v-model="form.Data_zakresu"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Data_zakresu.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M5"
                :class="getValidationClass('Bezposredni_przelozony')"
              >
                <label for="Bezposredni_przelozony"
                  >Bezpośredni przełożony
                </label>
                <md-input
                  name="Bezposredni_przelozony"
                  id="Bezposredni_przelozony"
                  autocomplete="Bezposredni_przelozony"
                  v-model="form.Bezposredni_przelozony"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Bezposredni_przelozony.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-autocomplete
                :class="getValidationClass('Nazwa_wybor_zakresu')"
                v-model="form.Nazwa_wybor_zakresu"
                :md-options="zapytaniaZakres"
                :disabled="sending"
              >
                <label>Wybor rodzaju zakresu</label>
                <span
                  class="md-error"
                  v-if="!$v.form.Nazwa_wybor_zakresu.required"
                  >Pole jest wymagane</span
                >
              </md-autocomplete>
            </div>
          </div>

          <div
            style="
              margin-left: 23px;
              margin-top: 20px;
              font-weight: bold;
              font-size: 16px;
            "
          >
            SZCZEGÓŁY ZAKRESU
          </div>
          <div
            style="
              margin-left: 23px;
              margin-top: 10px;
              font-weight: bold;
              color: red;
            "
          >
          </div>

          <div
            class="aa"
            v-for="(wiersz, index) in form.wiersze"
            :key="index"
            :set="(v = $v.form.wiersze.$each[index])"
          >
            <div
              style="
                color: blue;
                font-weight: bold;
                font-size: 1.5vh;
                margin-left: 25px;
                margin-top: 10px;
              "
            >
              {{ `POZYCJA NR: ${index + 1} ` }}

              <b-icon-plus-square
                class="ikony"
                font-scale="1.4"
                style="border: 1px black"
                color="black"
                @click="dodajWiersz()"
              >
              </b-icon-plus-square>

              <b-icon-trash
                class="ikony"
                font-scale="1.4"
                color="black"
                @click="usunWiersz(wiersz)"
              >
              </b-icon-trash>
            </div>

            <div class="md-layout md-gutter">
              <div class="md-layout-item md-small-size-100">
                <md-autocomplete
                  :class="
                    getValidationClass('Nazwa_wybor_czesci_zakresu', index)
                  "
                  v-model="wiersz.Nazwa_wybor_czesci_zakresu"
                  :md-options="zapytaniaCzesciZakresu"
                  :key="index"
                  :disabled="sending"
                >
                  <label>Wybor części zakresu do dodania wpisu</label>
                  <span
                    class="md-error"
                    v-if="!v.Nazwa_wybor_czesci_zakresu.required"
                    >Pole jest wymagane</span
                  >
                </md-autocomplete>
              </div>
            </div>

            <div class="md-layout md-gutter">
              <div class="md-layout-item md-small-size-100">
                <md-autocomplete
                  :class="
                    getValidationClass('Nazwa_wybor_rodzaju_wpisu', index)
                  "
                  v-model="wiersz.Nazwa_wybor_rodzaju_wpisu"
                  @input="zerujWartosc(wiersz)"
                  :md-options="zapytaniaRodzajWpisu"
                  :key="index"
                  :disabled="sending"
                >
                  <label>Wybor rodzaju wpisu</label>
                  <span
                    class="md-error"
                    v-if="!v.Nazwa_wybor_rodzaju_wpisu.required"
                    >Pole jest wymagane</span
                  >
                </md-autocomplete>
              </div>
            </div>

            <div
              v-if="
                wiersz.Nazwa_wybor_rodzaju_wpisu !== 'Chcę wybrać z katalogu' &&
                  wiersz.Nazwa_wybor_rodzaju_wpisu !== '' &&
                  wiersz.Nazwa_wybor_rodzaju_wpisu !== null
              "
              class="md-layout md-gutter"
            >
              <div class="md-layout-item md-small-size-100">
                <md-field
                  class="M5"
                  :class="getValidationClass('Opis_zakresu_1',index)"
                >
                  <label for="Opis_zakresu_1"
                    >Opcja dodania części zakresu ręcznie - DODAJ WPIS</label
                  >
                  <md-textarea
                    md-autogrow
                    md-counter="1000"
                    name="Opis_zakresu_1"
                    id="Opis_zakresu_1"
                    autocomplete="Opis_zakresu_1"
                    v-model="wiersz.Opis_zakresu_1"
                    :disabled="sending"
                  />
                  <span class="md-error" v-if="!v.Opis_zakresu_1.required"
                    >Pole jest wymagane</span
                  >
                </md-field>
              </div>
            </div>

            <div
              v-if="
                wiersz.Nazwa_wybor_rodzaju_wpisu !== 'Chcę wpisać ręcznie' &&
                  wiersz.Nazwa_wybor_rodzaju_wpisu !== '' &&
                  wiersz.Nazwa_wybor_rodzaju_wpisu !== null
              "
              class="md-layout md-gutter"
            >
              <div class="md-layout-item md-small-size-100">
                <md-autocomplete
                  :class="getValidationClass('Nazwa_wybor_katalog',index)"
                  v-model="wiersz.Nazwa_wybor_katalog"
                  :md-options="zapytaniaRodzajWpisuKatalog"
                  :key="index"
                  :disabled="sending"
                >
                  <label>Wybór wpisu</label>
                  <span class="md-error" v-if="!v.Nazwa_wybor_katalog.required"
                    >Pole jest wymagane</span
                  >
                </md-autocomplete>
              </div>
            </div>
          </div>
        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

        <div style="text-align: center; margin-top:50px">
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            czyJestWTrybieEdycji
              ? "ZATWIERDŹ - EDYTOWANY WPIS"
              : "ZATWIERDŹ - UTWÓRZ WPIS"
          }}</md-button>
        </div>
      </md-card>
    </form>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { api } from "../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_maszyny",

  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: function() {
    return {
      form: {
        Nazwa_wybor_pracownika: null,
        Nazwa_wybor_stanowiska: null,
        Data_zakresu: null,
        Bezposredni_przelozony: null,
        Nazwa_wybor_zakresu: null,
        wiersze: [
          {
            Nazwa_wybor_czesci_zakresu: '',
            Nazwa_wybor_rodzaju_wpisu: '',
            Opis_zakresu_1: '',
            Nazwa_wybor_katalog: '',
          },
        ],
      },
      IdEdytowanegoElementu: null,
      userSaved: false,
      sending: false,
      lastUser: null,
      Pracownicy: [],
      Stanowiska: [],
      Zakresy: [],
      ZakresCzesciZakresu: [],
      WyborCzesciWpisu: [],
      WyborCzesciKatalog: [],
      CzyMozeCzyscic: true,
    };
  },

  async created() {
    try {
      const [
        odpowiedz_Pracownik,
        odpowiedz_Stanowisko,
        odpowiedz_Zakresy,
        odpowiedz_RodzajCzesciZakresu,
        odpowiedz_RodzajWpisuZakresu,
        odpowiedz_katalog,
      ] = await Promise.all([
        api.pobierzPracownika(),
        api.pobierzStanowiskoPracy(),
        api.pobierzRodzajZakresu(),
        api.pobierzCzescZakresu(),
        api.pobierzWyborZakresu(),
        api.pobierzKatalogZakres(),
      ]);
      odpowiedz_Pracownik,
        (this.Pracownicy = odpowiedz_Pracownik.data.dane.map((el) => ({
          ...el,
          nazwa: `${el.Nazwisko} ${el.Imie1} - NIP: ${el.NIP} ; PESEL: ${el.Pesel}`,
        })));

      this.Stanowiska = odpowiedz_Stanowisko.data.dane.map((el) => ({
        ...el,
        nazwa1: `${el.Stanowisko}`,
      }));
      this.Zakresy = odpowiedz_Zakresy.data.dane.map((el) => ({
        ...el,
        nazwa2: `${el.Rodzaj_zakresu}`,
      }));
      this.ZakresCzesciZakresu = odpowiedz_RodzajCzesciZakresu.data.dane.map(
        (el) => ({
          ...el,
          nazwa3: `${el.Czesc_zakresu}`,
        })
      );
      this.WyborCzesciWpisu = odpowiedz_RodzajWpisuZakresu.data.dane.map(
        (el) => ({
          ...el,
          nazwa4: `${el.Wybor}`,
        })
      );
      this.WyborCzesciKatalog = odpowiedz_katalog.data.dane.map((el) => ({
        ...el,
        nazwa5: `${el.Opis}`,
      }));

      if (this.obiektKonfiguracyjny.akcja === "edycja") {
        //edycja
        this.CzyMozeCzyscic = false
        const odpowiedz = await api.pobieranieZOOIUEdycja(
          this.obiektKonfiguracyjny.Id
        );
        const {
          Id,
          Id_pracownika,
          Data_zakresu,
          Id_stanowisko,
          Id_rodzaj_zakresu,
          Bezposredni_przelozony,
          PozycjeZapytania,
        } = odpowiedz.data.dane[0];
        this.IdEdytowanegoElementu = Id;
        this.form.Nazwa_wybor_pracownika = this.Pracownicy.find(
          ({ Id }) => Id == Id_pracownika
        ).nazwa;
        this.form.Data_zakresu = Data_zakresu;
        this.form.Nazwa_wybor_stanowiska = this.Stanowiska.find(
          ({ Id }) => Id == Id_stanowisko
        ).nazwa1;
        this.form.Nazwa_wybor_zakresu = this.Zakresy.find(
          ({ Id }) => Id == Id_rodzaj_zakresu
        ).nazwa2;
        this.form.Bezposredni_przelozony = Bezposredni_przelozony;
        if (PozycjeZapytania.length !== 0) {
          this.form.wiersze = PozycjeZapytania.map(
            ({
              Id_rodzaj_czesci_zakresu,
              // Id_pozycja_zakresu,
              Opis_zakresu_1,
              Opis_zakresu_2,
              // Id_rodzaj_wpisu,
            }) => ({
              Nazwa_wybor_czesci_zakresu: this.ZakresCzesciZakresu.find(
                ({ Id }) => Id == Id_rodzaj_czesci_zakresu
              ).Czesc_zakresu,
              Nazwa_wybor_rodzaju_wpisu:
                Opis_zakresu_1 === ""
                  ? "Chcę wybrać z katalogu"
                  : "Chcę wpisać ręcznie",
              Opis_zakresu_1: Opis_zakresu_1 || '',
              Nazwa_wybor_katalog: this.WyborCzesciKatalog.find(
                ({ Id }) => Id == Opis_zakresu_2
              )?.Opis || '',
            })
          );
        }

        setTimeout(() => {
          this.CzyMozeCzyscic = true;
        }, 100);
      }
    } catch (error) {
      console.log(error);
    }
  },

  validations: {
    form: {
      Nazwa_wybor_pracownika: {
        required,
      },
      Nazwa_wybor_stanowiska: {
        required,
      },
      Data_zakresu: {
        required,
      },
      Bezposredni_przelozony: {
        required,
      },
      Nazwa_wybor_zakresu: {
        required,
      },
      wiersze: {
        $each: {
          Nazwa_wybor_czesci_zakresu: {
            required,
          },
          Nazwa_wybor_rodzaju_wpisu: {
            required,
          },
          Opis_zakresu_1: {
            required: false,
          },
          Nazwa_wybor_katalog: {
            required: false,
          },
        },
      },
    },
  },
  computed: {
    Id_pracownika: function() {
      const { Nazwa_wybor_pracownika } = this.form;
      const znalezionyElement = this.Pracownicy.find(
        ({ nazwa }) => nazwa === Nazwa_wybor_pracownika
      );

      if (znalezionyElement) {
        return znalezionyElement.Id;
      }
      return null;
    },
    zapytaniaPracownicy: function() {
      return this.Pracownicy.map((el) => el.nazwa);
    },

    Id_stanowisko: function() {
      const { Nazwa_wybor_stanowiska } = this.form;
      const znalezionyElement = this.Stanowiska.find(
        ({ nazwa1 }) => nazwa1 === Nazwa_wybor_stanowiska
      );

      if (znalezionyElement) {
        return znalezionyElement.Id;
      }
      return null;
    },
    zapytaniaStanowiska: function() {
      return this.Stanowiska.map((el) => el.nazwa1);
    },

    Id_rodzaj_zakresu: function() {
      const { Nazwa_wybor_zakresu } = this.form;
      const znalezionyElement = this.Zakresy.find(
        ({ nazwa2 }) => nazwa2 === Nazwa_wybor_zakresu
      );

      if (znalezionyElement) {
        return znalezionyElement.Id;
      }
      return null;
    },
    zapytaniaZakres: function() {
      return this.Zakresy.map((el) => el.nazwa2);
    },

    Id_rodzaj_czesci_zakresu: function() {
      const { Nazwa_wybor_czesci_zakresu } = this.form;
      const znalezionyElement = this.ZakresCzesciZakresu.find(
        ({ nazwa3 }) => nazwa3 === Nazwa_wybor_czesci_zakresu
      );

      if (znalezionyElement) {
        return znalezionyElement.Id;
      }
      return null;
    },
    zapytaniaCzesciZakresu: function() {
      return this.ZakresCzesciZakresu.map((el) => el.nazwa3);
    },

    Id_rodzaj_wpisu: function() {
      const { Nazwa_wybor_rodzaju_wpisu } = this.form;
      const znalezionyElement = this.WyborCzesciWpisu.find(
        ({ nazwa4 }) => nazwa4 === Nazwa_wybor_rodzaju_wpisu
      );

      if (znalezionyElement) {
        return znalezionyElement.Id;
      }
      return null;
    },
    zapytaniaRodzajWpisu: function() {
      return this.WyborCzesciWpisu.map((el) => el.nazwa4);
    },

    Id_wpisu_katalog: function() {
      const { Nazwa_wybor_katalog } = this.form;
      const znalezionyElement = this.WyborCzesciKatalog.find(
        ({ nazwa5 }) => nazwa5 === Nazwa_wybor_katalog
      );

      if (znalezionyElement) {
        return znalezionyElement.Id;
      }
      return null;
    },
    zapytaniaRodzajWpisuKatalog: function() {
      return this.WyborCzesciKatalog.map((el) => el.nazwa5);
    },

    czyJestWTrybieEdycji: function() {
      return this.obiektKonfiguracyjny.akcja === "edycja";
    },
  },

  methods: {
    zerujWartosc(wiersz) {
      if (this.CzyMozeCzyscic) {
        wiersz.Nazwa_wybor_katalog = null;
        wiersz.Opis_zakresu_1 = null;
      }
      console.log(wiersz);
    },
    dodajWiersz() {
      console.log("robi");
      const nowyWiersz = {};
      Object.keys(this.form.wiersze[0]).forEach((key) => {
        nowyWiersz[key] = null;
      });
      const noweWiersze = [...this.form.wiersze, nowyWiersz];
      this.form.wiersze = noweWiersze;
    },
    usunWiersz(wiersz) {
      if (this.form.wiersze.length <= 1) {
        const nowyWiersz = {};
        Object.keys(this.form.wiersze[0]).forEach((key) => {
          nowyWiersz[key] = null;
        });
        this.form.wiersze = [nowyWiersz];
        return;
      }

      this.form.wiersze = this.form.wiersze.filter((el) => el !== wiersz);
    },
    getValidationClass(fieldName,index) {
      const field = this.$v.form[fieldName] || this.$v.form.wiersze.$each[index][fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.Id_pracownika = null;
      this.form.Id_stanowisko = null;
      this.form.Data_zakresu = null;
      this.form.Bezposredni_przelozony = null;
      this.form.Id_rodzaj_zakresu = null;
      this.form.Id_wpisu_katalog = null;
      this.form.wiersze = [];
    },

    zapiszFormularz() {
      this.sending = true;

      if (this.czyJestWTrybieEdycji) {
        const daneDoWyslania = {
          Id_pracownika: this.Id_pracownika,
          Id_stanowisko: this.Id_stanowisko,
          Data_zakresu: this.form.Data_zakresu,
          Bezposredni_przelozony: this.form.Bezposredni_przelozony,
          Id_rodzaj_zakresu: this.Id_rodzaj_zakresu,
          PozycjeCzynikow: this.form.wiersze.map((wiersz) => {
            const znalezionyElement = this.ZakresCzesciZakresu.find(
              ({ Czesc_zakresu }) =>
                Czesc_zakresu === wiersz.Nazwa_wybor_czesci_zakresu
            );
            const znalezionyElement2 = this.WyborCzesciWpisu.find(
              ({ Wybor }) => Wybor === wiersz.Nazwa_wybor_rodzaju_wpisu
            );
            const znalezionyElement3 = this.WyborCzesciKatalog.find(
              ({ Opis }) =>
                Opis === wiersz.Nazwa_wybor_katalog
            );

            wiersz.Id_rodzaj_czesci_zakresu = znalezionyElement
              ? znalezionyElement.Id
              : null;
            wiersz.Id_rodzaj_wpisu = znalezionyElement2
              ? znalezionyElement2.Id
              : null;

            wiersz.Id_wpisu_katalog = znalezionyElement3
              ? znalezionyElement3.Id
              : null;

            return wiersz;
          }),
        };

        api
          .zapiszZOOIUUpdate(daneDoWyslania, this.IdEdytowanegoElementu)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const daneDoWyslania = {
         Id_pracownika: this.Id_pracownika,
          Id_stanowisko: this.Id_stanowisko,
          Data_zakresu: this.form.Data_zakresu,
          Bezposredni_przelozony: this.form.Bezposredni_przelozony,
          Id_rodzaj_zakresu: this.Id_rodzaj_zakresu,
          PozycjeCzynikow: this.form.wiersze.map((wiersz) => {
            const znalezionyElement = this.ZakresCzesciZakresu.find(
              ({ Czesc_zakresu }) =>
                Czesc_zakresu === wiersz.Nazwa_wybor_czesci_zakresu
            );
            const znalezionyElement2 = this.WyborCzesciWpisu.find(
              ({ Wybor }) => Wybor === wiersz.Nazwa_wybor_rodzaju_wpisu
            );
            const znalezionyElement3 = this.WyborCzesciKatalog.find(
              ({ Opis }) =>
                Opis === wiersz.Nazwa_wybor_katalog
            );

            wiersz.Id_rodzaj_czesci_zakresu = znalezionyElement
              ? znalezionyElement.Id
              : null;
            wiersz.Id_rodzaj_wpisu = znalezionyElement2
              ? znalezionyElement2.Id
              : null;

            wiersz.Id_wpisu_katalog = znalezionyElement3
              ? znalezionyElement3.Id
              : null;

            return wiersz;
          }),
        };

        api
          .zapiszZOOIU(daneDoWyslania)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    naszWalidacjaFormularza() {
      if (this.czyJestWTrybieEdycji) {
        return (
          !this.$v.form.Nazwa_wybor_pracownika.$invalid &&
          !this.$v.form.Nazwa_wybor_stanowiska.$invalid &&
          !this.$v.form.Data_zakresu.$invalid &&
          !this.$v.form.Bezposredni_przelozony.$invalid &&
          !this.$v.form.Nazwa_wybor_zakresu.$invalid &&
          !this.$v.form.wiersze.$invalid
        );
      } else {
        return !this.$v.$invalid;
      }
    },

    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>

<style>
.Form_ZOOIU .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.Form_ZOOIU .md-layout .md-gutter {
  min-width: 55vw !important; /* szerokość modala*/
  max-width: 100% !important;
  min-height: 7vh; /* wysokość pomiędzy mierszami w modalu*/
}
.md-layout {
  padding-right: 0px !important;
}
.Form_ZOOIU .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}

.Form_ZOOIU .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  margin: 10px 24px !important;
  padding-top: 35px !important;
}
.Form_ZOOIU .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}

.Form_ZOOIU {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
  overflow: auto !important;
}
.Form_ZOOIU .md-primary {
  border: 1px solid !important;

  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}
.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important; /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}

.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}
.md-list-item-text {
  font-size: 1.7vmin !important;
}
.Form_ZOOIU .md-card-actions .md-button {
  margin-right: 4vw !important;
}
.Form_ZOOIU .md-field.md-has-textarea:not(.md-autogrow) label {
  top: 16px;
  left: 2px;
}
</style>
