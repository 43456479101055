var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tabela_KBHPWEWREA"},[_c('div',{staticClass:"laczacy"},[_c('div',{staticClass:"menu_strony"},[_c('button',{staticClass:"button",on:{"click":function($event){return _vm.$router.push("../wykaz_kontroli_wewnetrznych")}}},[_vm._v(" POWRÓT DO WYKAZU RAPORTÓW Z KONTROLI ")]),_c('button',{staticClass:"button",attrs:{"disabled":!_vm.autoryzacja.czyMozeDodawac},on:{"click":function($event){_vm.pokazDialog = true;
            _vm.typAktywnegoFormularza =
              'formularz_dodawanie_uwagi_wewnetrzne';
            _vm.obiektKonfiguracyjny = {
              Id: null,
              akcja: 'dodawanie',
        
            };}}},[_vm._v(" DODAWANIE UWAGI DO KONTROLI ")])]),_c('div',{staticClass:"menu_strony1"},[_c('b',[_c('b-icon-info-circle-fill',{staticClass:"ikony",attrs:{"font-scale":"2.1","color":"white"},on:{"click":_vm.pokazPDF_INFO}}),_c('md-tooltip',{staticClass:"podpowiedz",attrs:{"md-direction":"top"}},[_vm._v("GENERUJ PDF / DRUKUJ")])],1)])]),(_vm.pokazDialog)?_c('modalBHP',{attrs:{"rodzajFormularza":_vm.typAktywnegoFormularza,"funkcjaZamykajacaDialog":_vm.funkcjaZamykajacaDialog,"obiektKonfiguracyjny":_vm.obiektKonfiguracyjny}}):_vm._e(),_c('md-table',{attrs:{"md-height":"45vh","md-sort":"Nazwa_maszyny","md-sort-order":"asc","md-model-id":"Id_maszyny","md-card":"","md-fixed-header":""},on:{"md-selected":_vm.wyborElementu},scopedSlots:_vm._u([{key:"md-table-row",fn:function(ref){
            var item = ref.item;
return _c('md-table-row',{key:item.id,attrs:{"md-selectable":"single"}},[_c('md-table-cell',{attrs:{"md-label":"Lp"}},[_vm._v(" "+_vm._s(item.Lp1)+" ")]),_c('md-table-cell',{attrs:{"md-label":"Uwaga stwierdzona w trakcie kontroli","md-sort-by":"Uwagi"}},[_vm._v(" "+_vm._s(item.Uwagi)+" ")]),_c('md-table-cell',{attrs:{"md-label":"Przyczyna","md-sort-by":"Przyczyna"}},[_vm._v(" "+_vm._s(item.Przyczyna)+" ")]),_c('md-table-cell',{attrs:{"md-label":"Korekcja","md-sort-by":"Korekcja"}},[_vm._v(" "+_vm._s(item.Korekcja)+" ")]),_c('md-table-cell',{attrs:{"md-label":"Korygujace","md-sort-by":"Korygujace"}},[_vm._v(" "+_vm._s(item.Korygujace)+" ")]),_c('md-table-cell',{attrs:{"md-label":"Plan.data zakoń.","md-sort-by":"Data_zakonczenia"}},[_vm._v(" "+_vm._s(item.Data_zakonczenia)+" ")])],1)}}]),model:{value:(_vm.searched),callback:function ($$v) {_vm.searched=$$v},expression:"searched"}},[_c('md-table-toolbar',[_c('div',{staticClass:"d-flex flex-column w-100"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4"}),_c('div',{staticClass:"col-4"},[_c('h1',{staticClass:"md-title"},[_vm._v(" PODJĘTE DZIAŁANIA DO UWAG"),_c('br'),_vm._v(" STWIERDZONYCH W TRAKCIE KONTROLI"),_c('br'),_vm._v(" WEWNĘTRZNYCH ")])])]),(_vm.wybranyElementTabeli)?_c('div',{staticClass:"PasekWyboru"},[_c('div',{staticStyle:{"text-align":"right","margin-top":"0.3vh","margin-bottom":"0.3vh"}},[_c('b',[_c('b-button',{attrs:{"disabled":!_vm.autoryzacja.czyMozeEdytowac},on:{"click":function($event){_vm.pokazDialog = true;
                  _vm.typAktywnegoFormularza =
                    'formularz_dodawanie_kontroli_wewnetrznej_dzialania';
                  _vm.obiektKonfiguracyjny = {
                    Id: _vm.wybranyElementTabeli.Id,
                    akcja: 'edycja',
                  };}}},[_c('b-icon-pencil-square',{staticClass:"ikony",attrs:{"font-scale":"1.80","color":"white"}})],1),_c('md-tooltip',{staticClass:"podpowiedz",attrs:{"md-direction":"top"}},[_vm._v(_vm._s(_vm.autoryzacja.czyMozeUsuwac ? "EDYCJA ELEMENTU" : "BRAK UPRAWNIEŃ"))])],1),_c('b',[_c('b-button',[_c('b-icon-bounding-box',{staticClass:"ikony",attrs:{"font-scale":"1.80","color":"white"},on:{"click":function($event){return _vm.$router.push(
                      ("../../ocena_wykaz_kontroli_wewnetrznych_realizacja_uwag/" + (_vm.wybranyElementTabeli.Id))
                    )}}})],1),_c('md-tooltip',{staticClass:"podpowiedz",attrs:{"md-direction":"top"}},[_vm._v("OCENA SKUTECZNOŚCI")])],1)])]):_vm._e()])]),(_vm.czyPobranoDane)?_c('md-table-empty-state',{attrs:{"md-label":"Nie posiadasz wpisów w wykazie "}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }