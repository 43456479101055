<template>
  <div class="FormRaportAuditu">
    <div v-if="pokazDialog">
      <Elementtt
        :rodzajFormularza="typAktywnegoFormularza"
        :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
        :obiektKonfiguracyjny="obiektKonfiguracyjny"
      />
    </div>

    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI RAPORTU Z AUDITU"
                : "FORMULARZ DODAWANIA RAPORTU Z AUDITU"
            }}
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass2('Id_auditu')">
                <label for="Id_auditu">Wybór auditu</label>
                <!--- edycja 3 linia-->

                <md-select
                  :disabled="sending || czyJestWTrybieEdycji"
                  name="Id_auditu"
                  id="Id_auditu"
                  v-model="form.Id_auditu"
                  autocomplete="Id_auditu"
                  md-dense
                  @input="pobierzNormyDoAudytu()"
                >
                  <md-option
                    v-for="(Audit, index) in dane.Audity"
                    :key="index"
                    :value="Audit.Id"
                    >{{
                      ` Audit numer: ${Audit.Nr_auditu} - Data auditu: ${Audit.Data_rozpoczecia} - ${Audit.Data_zakonczenia} ; Skład auditorski: ${Audit.Auditorzy_wewnetrzni}`
                    }}</md-option
                  >
                </md-select>
                <span class="md-error">Pole jest wymagane</span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div
              style="
                margin-left: 50px;
                margin-top: 40px;
                font-weight: bold;
                font-size: 20px;
              "
            ></div>
          </div>

          <!-- Poczatek punktow -->
          <div v-if="dane.Punkty">
            <div
              v-for="(punktyNormy, indexNormy) in dane.Punkty"
              :key="indexNormy"
            >
              <h4>{{ punktyNormy.nazwa }}</h4>
              <div
                v-for="(punkt, indexPunktu) in punktyNormy.punkty"
                :key="indexPunktu"
                :set="
                  (v =
                    $v.form.typyNormy.$each[indexNormy].punkty.$each[
                      indexPunktu
                    ])
                "
              >
                <div
                  class="md-layout-item md-small-size-100"
                  style="text-align: left; color: blue; margin-top: 30px"
                >
                  <span
                    style="
                      font-weight: bold;
                      font-size: 20px;
                      margin-left: 20px;
                    "
                    >{{ `PUNKT NORMY: ${punkt.Punkt}` }}</span
                  >
                </div>

                <div
                  v-if="
                    form.typyNormy[indexNormy].punkty[indexPunktu].wynik !== '4'
                  "
                  class="md-layout md-gutter"
                >
                  <div class="md-layout-item md-small-size-100">
                    <md-field
                      :class="
                        getValidationClass('dowod', indexNormy, indexPunktu)
                      "
                    >
                      <label>Dowody z auditu</label>
                      <md-textarea
                        md-autogrow
                        md-counter="1000"
                        name="dowod"
                        v-model="
                          form.typyNormy[indexNormy].punkty[indexPunktu].dowod
                        "
                        autocomplete="dowod"
                        :disabled="sending"
                      />
                      <span class="md-error" v-if="!v.dowod.maxLength"
                        >Wymagana ilość znaków od 1 do 1000</span
                      >
                    </md-field>
                  </div>
                </div>
                <div class="md-layout md-gutter">
                  <div class="md-layout-item md-small-size-100">
                    <md-field
                      :class="
                        getValidationClass('wynik', indexNormy, indexPunktu)
                      "
                    >
                      <label>Wynik</label>
                      <!--- edycja 3 linia-->

                      <md-select
                        :disabled="sending"
                        name="wynik"
                        autocomplete="wynik"
                        @input="generujNumeracje()"
                        md-dense
                        v-model="
                          form.typyNormy[indexNormy].punkty[indexPunktu].wynik
                        "
                      >
                        <md-option
                          v-for="(Wynik, index) in dane.Wyniki"
                          :key="index"
                          :value="Wynik.Id"
                          >{{ ` ${Wynik.Wynik_auditu} ` }}</md-option
                        >
                      </md-select>

                      <span class="md-error" v-if="!v.wynik.requred"
                        >Pole jest wymagane</span
                      >
                    </md-field>
                  </div>
                </div>

                <div
                  v-if="
                    form.typyNormy[indexNormy].punkty[indexPunktu].wynik !==
                      '1' &&
                      form.typyNormy[indexNormy].punkty[indexPunktu].wynik !==
                        '3' &&
                      form.typyNormy[indexNormy].punkty[indexPunktu].wynik !==
                        '4' &&
                      form.typyNormy[indexNormy].punkty[indexPunktu].wynik !==
                        null
                  "
                  class="md-layout md-gutter"
                >
                  <div class="md-layout-item md-small-size-100">
                    <md-field
                      :class="
                        getValidationClass(
                          'spostrzezenie',
                          indexNormy,
                          indexPunktu
                        )
                      "
                    >
                      <label>SPOSTRZEŻENIE</label>
                      <md-textarea
                        md-autogrow
                        md-counter="1000"
                        name="spostrzezenie"
                        v-model="
                          form.typyNormy[indexNormy].punkty[indexPunktu]
                            .spostrzezenie
                        "
                        autocomplete="spostrzezenie"
                        :disabled="sending"
                      />
                      <span class="md-error" v-if="!v.spostrzezenie.maxLength"
                        >Pole jest za długie</span
                      >
                    </md-field>
                  </div>
                </div>

                <div
                  v-if="
                    form.typyNormy[indexNormy].punkty[indexPunktu].wynik !==
                      '1' &&
                      form.typyNormy[indexNormy].punkty[indexPunktu].wynik !==
                        '2' &&
                      form.typyNormy[indexNormy].punkty[indexPunktu].wynik !==
                        '4' &&
                      form.typyNormy[indexNormy].punkty[indexPunktu].wynik !==
                        null
                  "
                  class="md-layout md-gutter"
                >
                  <div class="md-layout-item md-small-size-100">
                    <md-field
                      :class="
                        getValidationClass(
                          'niezgodnosc',
                          indexNormy,
                          indexPunktu
                        )
                      "
                    >
                      <label>NIEZGODNOŚĆ</label>
                      <md-textarea
                        md-autogrow
                        md-counter="1000"
                        name="niezgodnosc"
                        v-model="
                          form.typyNormy[indexNormy].punkty[indexPunktu]
                            .niezgodnosc
                        "
                        autocomplete="niezgodnosc"
                        :disabled="sending"
                      />
                      <span class="md-error" v-if="!v.niezgodnosc.maxLength"
                        >Pole jest za długie</span
                      >
                    </md-field>
                  </div>
                </div>
                <div
                  v-if="
                    form.typyNormy[indexNormy].punkty[indexPunktu].wynik !==
                      '1' &&
                      form.typyNormy[indexNormy].punkty[indexPunktu].wynik !==
                        '4' &&
                      form.typyNormy[indexNormy].punkty[indexPunktu].wynik !==
                        null
                  "
                  class="md-layout md-gutter"
                >
                  <div class="md-layout-item md-small-size-100">
                    <md-field
                      :class="
                        getValidationClass(
                          'numer_dzialania',
                          indexNormy,
                          indexPunktu
                        )
                      "
                    >
                      <label>NUMER DZIAŁANIA</label>
                      <md-textarea
                        md-autogrow
                        md-counter="100"
                        name="numer_dzialania"
                        v-model="
                          form.typyNormy[indexNormy].punkty[indexPunktu]
                            .numer_dzialania
                        "
                        autocomplete="numer_dzialania"
                        :disabled="sending"
                        readonly
                      />
                    </md-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

        <div style="text-align: center">
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            czyJestWTrybieEdycji
              ? "ZATWIERDŹ - EDYTOWANY WPIS"
              : "ZATWIERDŹ - UTWÓRZ WPIS"
          }}</md-button>
        </div>
      </md-card>
    </form>
  </div>
</template>

<script>
import modal from "./../../strona-formularze/modal.vue";
import { validationMixin } from "vuelidate";
import minLength from "vuelidate/lib/validators/minLength";
import { required, maxLength } from "vuelidate/lib/validators";
import { api } from "./../../../serwisy/api.serwis";


export default {
  name: "Formularz_dodawanie_raportu",
  mixins: [validationMixin],
  components: {
    Elementtt: modal,
  },
  data: () => ({
    pokazDialog: false,
    typAktywnegoFormularza: null,
    czyPobranoDane: false,
    obiektKonfiguracyjny: null,
    form: {
      Id_auditu: null,
      typyNormy: null,
    },
    dane: {
      Audity: null,
      Wyniki: null,
      Punkty: null,
    },
    sending: null,
  }),
  validations: {
    form: {
      typyNormy: {
        $each: {
          punkty: {
            $each: {
              dowod: {
                required: required(false),
                minLenght: minLength(1),
                maxLength: maxLength(1000),
              },
              wynik: {
                required: required(false),
              },
              spostrzezenie: {
                required: required(false),
              },
              niezgodnosc: {
                required: required(false),
              },
              numer_dzialania: {
                required: required(false),
              },
            },
          },
        },
      },
    },
  },

  created() {
    // if (this.obiektKonfiguracyjny.akcja === "edycja") {
    // pobierzDaneDoEdycji()
    // }

    Promise.all([api.pobierzNrAuditu(), api.pobierzWynikAuditu()])
      .then(([odpowiedz_NrAuditu, odpowiedz_Wynik]) => {
        this.dane.Audity = odpowiedz_NrAuditu.data.dane;
        this.dane.Wyniki = odpowiedz_Wynik.data.dane;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted() {
    const { id } = this.$route.params;
    if (id) {
      this.pobierzNormyDoAudituEdycja(id);
    }
  },

  computed: {
    czyJestWTrybieEdycji: function() {
      return this.$route.params.id !== undefined;
    },
  },
  methods: {

    generujNumeracje() {
      console.log("dskmdksmdksmdks");
      let spostrzezenia = 0;
      let niezgodnosci = 0;
      const nazwaAuditu = this.dane.Audity.find(
        ({ Id }) => Id === this.form.Id_auditu
      ).Nr_auditu;
      this.form.typyNormy = this.form.typyNormy.map((typ) => ({
        ...typ,
        punkty: typ.punkty.map((punkt) => {
          return {
            ...punkt,
            numer_dzialania:
              punkt.wynik === "2" || punkt.wynik === "3"
                ? punkt.wynik === "2"
                  ? `${++spostrzezenia}/S/${nazwaAuditu}`
                  : `${++niezgodnosci}/N/${nazwaAuditu}`
                : null,
          };
        }),
      }));
    },
    getValidationClass(fieldName, indexNormy, indexPunktu) {
      if (!this.$v.form.typyNormy[indexNormy]) {
        return "";
      }
      const field = this.$v.form.typyNormy[indexNormy].punkty[indexPunktu][
        fieldName
      ];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    funkcjaZamykajacaDialog() {
      this.pokazDialog = false;
      this.typAktywnegoFormularza = null;
      this.obiektKonfiguracyjny = null;
    },
    getValidationClass2(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    pobierzNormyDoAudituEdycja(id) {
      api
        .pobieranieRaportAudityEdycja(id)
        .then((odpowiedz_Punkty) => {
          this.dane.Audity = odpowiedz_Punkty.data.dane.audit.lista_auditow;
          this.form.Id_auditu = odpowiedz_Punkty.data.dane.audit.id_auditu;
          this.dane.Punkty = odpowiedz_Punkty.data.dane.informacjeOPunktach;

          const typy = [];
          this.dane.Punkty.forEach((typ) => {
            const punkty = [];
            typ.punkty.forEach((punkt) => {
              punkty.push({
                dowod: punkt.Dowod,
                wynik: punkt.Id_wynik_auditu,
                numer_dzialania: punkt.Numer_dzialania,
                spostrzezenie:
                  punkt.Id_wynik_auditu == 2 ? punkt.Wartosc_wyniku : "",
                niezgodnosc:
                  punkt.Id_wynik_auditu == 3 ? punkt.Wartosc_wyniku : "",

                id: punkt.Id,
              });
            });
            typy.push({
              id_normy: typ.id_normy,
              nazwa: typ.nazwa,
              punkty: punkty,
            });
          });

          this.form.typyNormy = typy;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    pobierzNormyDoAudytu() {
      if (this.czyJestWTrybieEdycji) {
        return;
      }
      const { Id_auditu } = this.form;
      api
        .pobierzPunktyNormy(Id_auditu)
        .then((odpowiedz_Punkty) => {
          this.dane.Punkty = odpowiedz_Punkty.data.dane;

          const typy = [];
          this.dane.Punkty.forEach((typ) => {
            const punkty = [];
            typ.punkty.forEach((punkt) => {
              punkty.push({
                dowod: null,
                wynik: null,
                spostrzezenie: null,
                niezgodnosc: null,
                numer_dzialania: null,
                id: punkt.Id,
              });
            });
            typy.push({
              id_normy: typ.id_normy,
              nazwa: typ.nazwa,
              punkty: punkty,
            });
          });

          this.form.typyNormy = typy;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    wyslijFormularzZNowymiDanymi() {
      const dane = {
        normy: this.form.typyNormy.map((norma) => ({
          ...norma,
          punkty: norma.punkty.map((punkt) => {
            let wartosc_wyniku;
            const { wynik } = punkt;
            if (wynik == 2) {
              wartosc_wyniku = punkt.spostrzezenie;
            } else if (wynik == 3) {
              wartosc_wyniku = punkt.niezgodnosc;
            } else {
              wartosc_wyniku = null;
            }

            delete punkt.niezgodnosc;
            delete punkt.spostrzezenie;

            return {
              ...punkt,
              wartosc_wyniku,
            };
          }),
        })),
      };
      const { Id_auditu } = this.form;

      api
        .zapiszRaport(dane, Id_auditu)
        .then(() => {
          this.sending = false;
          this.$router.push(`../../audity`);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    wyslijFormularzEdycji() {
      const dane = {
        normy: this.form.typyNormy.map((norma) => ({
          ...norma,
          punkty: norma.punkty.map((punkt) => {
            let wartosc_wyniku;
            const { wynik } = punkt;
            if (wynik == 2) {
              wartosc_wyniku = punkt.spostrzezenie;
            } else if (wynik == 3) {
              wartosc_wyniku = punkt.niezgodnosc;
            } else {
              wartosc_wyniku = null;
            }

            delete punkt.niezgodnosc;
            delete punkt.spostrzezenie;

            return {
              ...punkt,
              wartosc_wyniku,
            };
          }),
        })),
      };

      api
        .zapiszRaportUpdate(dane, this.form.Id_auditu)
        .then(() => {
          this.sending = false;
          this.$router.push(`../../audity_raporty/${this.form.Id_auditu}`);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    zapiszFormularz() {
      this.sending = true;

      this.czyJestWTrybieEdycji
        ? this.wyslijFormularzEdycji()
        : this.wyslijFormularzZNowymiDanymi();
    },

    naszWalidacjaFormularza() {
      return !this.$v.$invalid;
    },
    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>

<style>
.FormRaportAuditu .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
.FormRaportAuditu {
  overflow: auto;
  width: 100%;
  height: 80vh;
}
.FormRaportAuditu .md-layout .md-gutter {
  min-width: 55vw !important; /* szerokość modala*/
  max-width: 100% !important;
  min-height: 7vh; /* wysokość pomiędzy mierszami w modalu*/
}

.FormRaportAuditu .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 30px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}

.FormRaportAuditu .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  margin: 10px 10px 10px 20px !important;
  padding-top: 35px !important;
}
.FormRaportAuditu .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}

.FormRaportAuditu .md-input {
  font-weight: 600 !important;
}

.FormRaportAuditu {
  border: 1px solid;
  margin: 5px 0px 0px 0px;
}
.FormRaportAuditu .md-primary {
  border: 1px solid !important;

  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}
.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important; /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}

.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}
.FormRaportAuditu .md-list-item-text {
  font-size: 1.7vmin !important;
}
.FormRaportAuditu .md-card-actions .md-button {
  margin-right: 50px !important;
}
.FormRaportAuditu .md-field.md-has-textarea:not(.md-autogrow) label {
  top: 16px;
  left: 0px;
}
</style>
