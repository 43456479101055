<template>
  <div>
  <TabelaElektronarzedziaGenerator/>
  </div>
</template>

<script>
import TabelaElektronarzedziaGenerator from '../../komponenty/strona-tabela-komponenty/WST_elektronarzedzia/tabela_generator.vue'


export default {
  name: 'StronaTabelaElektronarzedziaGenerator',
  components: {
    TabelaElektronarzedziaGenerator,
  }

}
</script>
