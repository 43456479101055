<template >
  <div>
  <TabelaSubstancjeNiebezpieczne />
  </div>
</template>

<script>
import TabelaSubstancjeNiebezpieczne from '../../komponenty/strona-tabela-komponenty/BHP_substancje_niebezpieczne/tabela_substancje_niebezpieczne.vue'


export default {
  name: 'StronaTabelaSubstancjeNiebezpieczne',
  components: {
    TabelaSubstancjeNiebezpieczne,
    
  }

}
</script>

