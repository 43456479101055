<template>
  <div class="faktury">
    <div class="menu_strony">
      <button
        class="button"
        @click="
          pokazDialog = true;
          typAktywnegoFormularza = 'formularz_dodawanie_faktury';
          obiektKonfiguracyjny = {
            Id: null,
            akcja: 'dodawanie',
          };
        "
      >
        DODAWANIE FAKTURY
      </button>
      <button
        class="button"
        @click="
          pokazDialog = true;
          typAktywnegoFormularza =
            'formularz_dodawanie_przegladu_elektronarzedzia';
          obiektKonfiguracyjny = {
            Id: null,
            akcja: 'dodawanie',
          };
        "
      >
        DODAWANIE KOREKTY
      </button>
      <button
        class="button"
        @click="
          pokazDialog = true;
          typAktywnegoFormularza =
            'formularz_dodawanie_ubezpieczenia_elektronarzedzia';
          obiektKonfiguracyjny = {
            Id: null,
            akcja: 'dodawanie',
          };
        "
      >
        DODAWANIE UBEZPIECZENIA
      </button>

      <button
        class="button"
        @click="
          pokazDialog = true;
          typAktywnegoFormularza =
            'formularz_dodawanie_koszty_elektronarzedzia';
          obiektKonfiguracyjny = {
            Id: null,
            akcja: 'dodawanie',
          };
        "
      >
        DODAWANIE KOSZTÓW
      </button>
    </div>

    <modalKontrahenci
      v-if="pokazDialog"
      :rodzajFormularza="typAktywnegoFormularza"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />

    <md-table
      md-height="53vh"
      @md-selected="wyborElementu"
      v-model="searched"
      md-sort="Nazwa_maszyny"
      md-sort-order="asc"
      md-model-id="Id_maszyny"
      md-card
      md-fixed-header
    >
      <md-table-toolbar>
        <div class="d-flex flex-column w-100">
          <div class="row">
            <div class="col-4"></div>
            <div class="col-4">
              <h1 class="md-title">WYKAZ WYSTAWIONYCH FAKTUR</h1>
            </div>

            <div class="col-4">
              <md-field md-clearable class="ml-auto mr-0">
                <md-input
                  placeholder="Szukaj..."
                  v-model="search"
                  @input="searchOnTable"
                />
              </md-field>
            </div>
          </div>

          <div class="PasekWyboru" v-if="wybranyElementTabeli">
            <div style="text-align: right; margin-top: 1vh; margin-bottom: 1vh">
              <b>
                <b-icon-trash
                  class="ikony"
                  font-scale="2.5"
                  color="white"
                  @click="usuwanieElementu(wybranyElementTabeli.Id)"
                >
                </b-icon-trash>
                <md-tooltip class="podpowiedz" md-direction="top"
                  >USUWANIE ELEMENTU</md-tooltip
                >
              </b>
              <b>
                <b-icon-pencil-square
                  class="ikony"
                  font-scale="2.5"
                  color="white"
                  @click="
                    pokazDialog = true;
                    typAktywnegoFormularza =
                      'formularz_dodawanie_elektronarzedzia';
                    obiektKonfiguracyjny = {
                      Id: wybranyElementTabeli.Id,
                      akcja: 'edycja',
                    };
                  "
                >
                </b-icon-pencil-square>
                <md-tooltip class="podpowiedz" md-direction="top"
                  >EDYCJA ELEMENTU</md-tooltip
                >
              </b>

              <b>
                <b-icon-arrow-down-right-square
                  class="ikony"
                  font-scale="2.5"
                  color="white"
                  @click="
                    $router.push(
                      `tabela_elektronarzedzia_przeglady/${wybranyElementTabeli.Id}`
                    )
                  "
                >
                </b-icon-arrow-down-right-square>
                <md-tooltip class="podpowiedz" md-direction="top"
                  >PRZEGLĄDY MASZYNY</md-tooltip
                >
              </b>

              <b>
                <b-icon-box-arrow-down-right
                  class="ikony"
                  font-scale="2.5"
                  color="white"
                  @click="
                    $router.push(
                      `tabela_elektronarzedzia_ubezpieczenia/${wybranyElementTabeli.Id}`
                    )
                  "
                >
                </b-icon-box-arrow-down-right>
                <md-tooltip class="podpowiedz" md-direction="top"
                  >UBEZPIECZENIA MASZYNY</md-tooltip
                >
              </b>

              <b>
                <b-icon-cart-plus
                  class="ikony"
                  font-scale="2.5"
                  color="white"
                  @click="
                    $router.push(
                      `tabela_elektronarzedzia_koszty/${wybranyElementTabeli.Id}`
                    )
                  "
                >
                </b-icon-cart-plus>
                <md-tooltip class="podpowiedz" md-direction="top"
                  >KOSZTY ZWIĄZANE Z NAPRAWAMI</md-tooltip
                >
              </b>
            </div>
          </div>
        </div>
      </md-table-toolbar>

      <md-table-empty-state
        v-if="czyPobranoDane"
        md-label="Nie posiadasz wpisów w wykazie elektronarzędzi"
      >
        <md-button
          class="md-primary md-raised"
          @click="
            pokazDialog = true;
            typAktywnegoFormularza = 'formularz_dodawanie_elektronarzedzia';
            obiektKonfiguracyjny = {
              Id: null,
              akcja: 'dodawanie',
            };
          "
          >Dodawanie nowej maszyny-elektronarzędzia</md-button
        >
      </md-table-empty-state>

      <md-table-row
        slot="md-table-row"
        slot-scope="{ item }"
        md-selectable="single"
        :key="item.id"
      >
        <md-table-cell md-label="Nr faktury" md-sort-by="Id_faktury">
          {{ item.Id_faktury }}
        </md-table-cell>

        <md-table-cell md-label="Data wystaw." md-sort-by="Data_wystawienia">
          {{ item.Data_wystawienia }}
        </md-table-cell>

        <md-table-cell md-label="Data sprzed." md-sort-by="Data_sprzedazy">
          {{ item.Data_sprzedazy }}
        </md-table-cell>

        <md-table-cell md-label="Kwota Netto" md-sort-by="Netto">
          {{ parseFloat(item.Netto).toFixed(2) }}
        </md-table-cell>

        <md-table-cell md-label="Kwota VAT" md-sort-by="VAT">
          {{ parseFloat(item.VAT).toFixed(2) }}
        </md-table-cell>

        <md-table-cell md-label="Kwota Brutto" md-sort-by="Brutto">
          {{ parseFloat(item.Brutto).toFixed(2) }}
        </md-table-cell>

        <md-table-cell md-label="Nr NIP" md-sort-by="NIP">
          {{ item.NIP }}
        </md-table-cell>

        <md-table-cell md-label="Kontrahent" md-sort-by="Nazwa_kontrahenta">
          {{ item.Nazwa_kontrahenta }}
        </md-table-cell>


      </md-table-row>
    </md-table>
  </div>
</template>

<script>
import modalKontrahenci from "../../strona-formularze/modalKontrahenci.vue";
import { api } from "./../../../serwisy/api.serwis";

const toLower = (text) => {
  return text.toString().toLowerCase();
};

const search = (items, term) => {
  if (term) {
    return items.filter((item) => {
      const elementWyszukiwany = toLower(term);
      const Id_faktury = toLower(item.Id_faktury);
      const NIP = toLower(item.NIP);

      return (
        Id_faktury.includes(elementWyszukiwany) ||
        NIP.includes(elementWyszukiwany)
      );
    });
  }

  return items;
};

export default {
  name: "tabela_faktury",
  data: () => ({
    czyPobranoDane: false,
    search: null,
    searched: [],
    Maszyny: [],
    pokazDialog: false,
    typAktywnegoFormularza: null,
    obiektKonfiguracyjny: null,
    wybranyElementTabeli: null,
  }),
  components: {
    modalKontrahenci,
  },

  mounted: function () {
    this.pobierzDaneDoTabeli();
  },
  computed: {
    //informacja czy jest w trybie edycji
    czyJestWTrybieEdycji() {
      return this.$router.params.Id ? true : false;
    },
  },
  methods: {
    pobierzDaneDoTabeli() {
      const { id } = this.$route.params;

      console.log(this.$route);
      api
        .pobieranieFaktury(id)
        .then((obiektOdpowiedzi) => {
          console.log(obiektOdpowiedzi);
          this.Maszyny = obiektOdpowiedzi.data.dane;
          this.searched = this.Maszyny;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.czyPobranoDane = true;
        });
    },
    funkcjaZamykajacaDialog(sukces) {
      this.pokazDialog = false;
      this.typAktywnegoFormularza = null;
      this.obiektKonfiguracyjny = null;
      if (sukces) {
        this.pobierzDaneDoTabeli();
      }
    },
    newUser() {
      window.alert("Noop");
    },
    searchOnTable() {
      this.searched = search(this.Maszyny, this.search);
    },
    usuwanieElementu(Id) {
      this.pokazDialog = true;
      this.typAktywnegoFormularza = "usuwanie";
      this.obiektKonfiguracyjny = {
        Id,
        typ: "elektronarzedzia",
      };
    },
    pokazPDF(Id) {
      //dopisane
      this.pokazDialog = true;
      this.typAktywnegoFormularza = "pokaz_PDF";
      this.obiektKonfiguracyjny = {
        Id,
        Klucz: "elektronarzedzia",
      };
    },
    wyborElementu(element) {
      this.wybranyElementTabeli = element;
    },
  },
};
</script>

<style>
.faktury .md-field {
  max-width: 300px;
}
.faktury{
  width: 100%;
}
.faktury .md-card {
  box-shadow: none !important;
}
.faktury .md-title {
  font-weight: 900 !important;
  color: black !important;
  font-size: 1.8vh !important;
  padding: 10px;
}
.faktury .md-toolbar {
  height: 14vh;
}
.faktury .md-table-head {
  font-weight: bold;
  color: black !important;
  font-size: 1.4vh !important;
  text-align: right !important;
}
.faktury .md-table-head-label {
  text-align: right !important;
  font-size: 1.4vh !important;
}
.faktury .md-table-cell {
  font-size: 1.4vh !important;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-align: right;
  color: black;
  font-weight: 300;
  margin-top: 200px !important;
}
.faktury .ikony {
  cursor: pointer;
  margin-right: 40px;
}

.faktury .md-table-cell:nth-child(1) {
  width: 10vw;
  text-align: right !important;
}
.faktury .md-table-cell:nth-child(2) {
  width: 10vw;
  text-align: right !important;
}
.faktury .md-table-cell:nth-child(3) {
  width: 10vw;
  text-align: right !important;
}
.faktury .md-table-cell:nth-child(4) {
  width: 10vw;
  text-align: right !important;
}
.faktury .md-table-cell:nth-child(5) {
  width: 10vw;
  text-align: right !important;
}
.faktury .md-table-cell:nth-child(6) {
  width: 10vw;
  text-align: right !important;
}
.faktury .md-table-cell:nth-child(7) {
  width: 10vw;
  text-align: right !important;
}
.faktury .md-table-cell:nth-child(8) {
  width: 20vw;
  text-align: right !important;
}

.faktury .button {
  margin-right: 10px;
  border: 3px white ridge;
  font-weight: bold;
  font-size: 1.4vmin;
  min-width: 12vw;
}
.faktury .button:hover {
  background-color: #606060; /* Green */
  color: white;
}
.faktury .menu_strony {
  border: 1px solid;
  margin-top: 5px;
  padding: 3px;
  background: #939393;
}
.md-table.md-theme-default .md-table-row.md-selected,
.md-table.md-theme-default .md-table-row.md-selected-single {
  background-color: #dddddd !important;
}
.PasekWyboru {
  background: #606060;
}
.faktury .ikony {
  margin-right: 15px;
  border: 1px solid !important;
  border-radius: 5px;
  padding: 5px;
}
.podpowiedz {
  font-size: 1.5vmin !important;
  font-weight: bold;
  background-color: black !important;
}
</style>