<template>
  <div class="tabelaPrzegladyIS">
    <div class="laczacy">
      <div class="menu_strony">
        <span>
          <button
            class="button"
            :disabled="!autoryzacja.czyMozeDodawac"
            @click="
              pokazDialog = true;
              typAktywnegoFormularza =
                'formularz_dodawanie_przegladu_instrukcji_srodowiskowej';
              obiektKonfiguracyjny = {
                Id: null,
                akcja: 'dodawanie',
              };
            "
          >
            DODAWANIE NOWEGO PRZEGLĄDU INSTRUKCJI ŚRODOWISKOWEJ
          </button>
          <md-tooltip class="podpowiedz" md-direction="top">{{
            !autoryzacja.czyMozeDodawac
              ? "BRAK UPRAWNIEŃ"
              : "DODAWANIE NOWEGO PRZEGLĄDU INSTRUKCJI ŚRODOWISKOWEJ"
          }}</md-tooltip>
        </span>
      </div>

      <div class="menu_strony1">
        <b>
          <b-icon-printer-fill
            class="ikony"
            font-scale="2.1"
            color="white"
            @click="generujPdf"
          >
          </b-icon-printer-fill>
          <md-tooltip class="podpowiedz" md-direction="top"
            >GENERUJ PDF / DRUKUJ</md-tooltip
          >
        </b>

        <b>
          <b-icon-info-circle-fill
            class="ikony"
            font-scale="2.1"
            color="white"
            @click="pokazPDF_INFO"
          >
          </b-icon-info-circle-fill>
          <md-tooltip class="podpowiedz" md-direction="top"
            >GENERUJ PDF / DRUKUJ</md-tooltip
          >
        </b>
      </div>
    </div>

    <modal
      v-if="pokazDialog"
      :rodzajFormularza="typAktywnegoFormularza"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />

    <md-table
      md-height="45vh"
      @md-selected="wyborElementu"
      v-model="searched"
      md-sort="Nazwa_maszyny"
      md-sort-order="asc"
      md-model-id="Id_maszyny"
      md-card
      md-fixed-header
    >
      <md-table-toolbar>
        <div class="d-flex flex-column w-100">
          <div class="row">
            <div class="col-4"></div>
            <div class="col-4">
              <h1 class="md-title">
                WYKAZ PRZEGLĄDÓW INSTRUKCJI ŚRODOWISKOWYCH
              </h1>
            </div>
            <div class="col-4">
              <md-field md-clearable class="ml-auto mr-0">
                <md-input
                  placeholder="Szukaj..."
                  v-model="search"
                  @input="searchOnTable"
                />
              </md-field>
            </div>
          </div>

          <div class="PasekWyboru" v-if="wybranyElementTabeli">
            <div
              style="text-align: right; margin-top: 0.3vh; margin-bottom: 0.3vh"
            >
              <b>
                <b-button
                  :disabled="!autoryzacja.czyMozeUsuwac"
                  @click="usuwanieElementu(wybranyElementTabeli.Id)"
                >
                  <b-icon-trash class="ikony" font-scale="1.8" color="white">
                  </b-icon-trash
                ></b-button>
                <md-tooltip class="podpowiedz" md-direction="top">{{
                  autoryzacja.czyMozeUsuwac
                    ? "USUWANIE ELEMENTU"
                    : "BRAK UPRAWNIEŃ"
                }}</md-tooltip>
              </b>

              <b>
                <b-button
                  :disabled="!autoryzacja.czyMozeEdytowac"
                  @click="
                    pokazDialog = true;
                    typAktywnegoFormularza =
                      'formularz_dodawanie_przegladu_instrukcji_srodowiskowej';
                    obiektKonfiguracyjny = {
                      Id: wybranyElementTabeli.Id,
                      akcja: 'edycja',
                    };
                  "
                >
                  <b-icon-pencil-square
                    class="ikony"
                    font-scale="1.80"
                    color="white"
                  >
                  </b-icon-pencil-square>
                </b-button>
                <md-tooltip class="podpowiedz" md-direction="top">{{
                  autoryzacja.czyMozeUsuwac
                    ? "EDYCJA ELEMENTU"
                    : "BRAK UPRAWNIEŃ"
                }}</md-tooltip>
              </b>

              <b
                ><b-button>
                  <b-icon-file-earmark-arrow-down
                    class="ikony"
                    font-scale="1.80"
                    color="white"
                    @click="pokazPDF(wybranyElementTabeli.Id)"
                  >
                  </b-icon-file-earmark-arrow-down
                ></b-button>
                <md-tooltip class="podpowiedz" md-direction="top"
                  >PODGLĄD PLIKU</md-tooltip
                >
              </b>
            </div>
          </div>
        </div>
      </md-table-toolbar>

      <md-table-empty-state
        v-if="czyPobranoDane"
        md-label="Nie posiadasz wpisów dla wybranej instrukcji środowiskowej"
      >
        <md-button
          class="md-primary md-raised"
          :disabled="!autoryzacja.czyMozeDodawac"
          @click="
            pokazDialog = true;
            typAktywnegoFormularza =
              'formularz_dodawanie_przegladu_instrukcji_srodowiskowej';
            obiektKonfiguracyjny = {
              Id: null,
              akcja: 'dodawanie',
            };
          "
          >Dodawanie nowego przeglądu dla instrukcji środowiskowej</md-button
        >
        <md-tooltip class="podpowiedz" md-direction="top">{{
          !autoryzacja.czyMozeDodawac
            ? "BRAK UPRAWNIEŃ"
            : "DODAWANIE NOWEGO PRZEGLĄDU INSTRUKCJI ŚRODOWISKOWEJ"
        }}</md-tooltip>
      </md-table-empty-state>

      <md-table-row
        slot="md-table-row"
        slot-scope="{ item }"
        md-selectable="single"
        :key="item.id"
      >
        <md-table-cell md-label="Lp">
          {{ item.Lp }}
        </md-table-cell>

        <md-table-cell md-label="Nr instrukcji" md-sort-by="Nr_dokumentu">
          {{ item.Nr_dokumentu }}
        </md-table-cell>

        <md-table-cell md-label="Nazwa instrukcji" md-sort-by="Nazwa_dokumentu">
          {{ item.Nazwa_dokumentu }}
        </md-table-cell>

        <md-table-cell
          md-label="Data zatw./przegl."
          md-sort-by="Data_przegladu"
        >
          {{ item.Data_przegladu }}
        </md-table-cell>

        <md-table-cell
          md-label="Data nast.przegl."
          md-sort-by="Data_nastepnego_przegladu"
        >
          {{ item.Data_nastepnego_przegladu }}
        </md-table-cell>

        <md-table-cell md-label="Ilość dni" md-sort-by="Pozostalo_dni">
          {{ item.Pozostalo_dni }}
        </md-table-cell>

        <md-table-cell
          md-label="Przegląd wykonał"
          md-sort-by="Przeglad_wykonal"
        >
          {{ item.Przeglad_wykonal }}
        </md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
import modal from "../../strona-formularze/modal.vue";
import { api } from "./../../../serwisy/api.serwis";
import { authorizationService } from "./../../../serwisy/authorization.servis";
import jsPDF from "jspdf";
import "jspdf-autotable";

const toLower = (text) => {
  return text.toString().toLowerCase();
};

const search = (items, term) => {
  if (term) {
    return items.filter((item) => {
      const elementWyszukiwany = toLower(term);
      const Nr_dokumentu = toLower(item.Nr_dokumentu);
      const Nazwa_dokumentu = toLower(item.Nazwa_dokumentu);
      const Data_przegladu = toLower(item.Data_przegladu);
      const Data_nastepnego_przegladu = toLower(item.Data_nastepnego_przegladu);
      const Przeglad_wykonal = toLower(item.Przeglad_wykonal);
      return (
        Nr_dokumentu.includes(elementWyszukiwany) ||
        Nazwa_dokumentu.includes(elementWyszukiwany) ||
        Data_przegladu.includes(elementWyszukiwany) ||
        Data_nastepnego_przegladu.includes(elementWyszukiwany) ||
        Przeglad_wykonal.includes(elementWyszukiwany)
      );
    });
  }

  return items;
};

export default {
  name: "tabela_wykaz_przeglady_maszyn_dozorowych",
  data: () => ({
    czyPobranoDane: false,
    search: null,
    searched: [],
    MaszynyPrzeglady: [],
    pokazDialog: false,
    typAktywnegoFormularza: null,
    obiektKonfiguracyjny: null,
    wybranyElementTabeli: null,
    autoryzacja: {
      czyMozeDodawac: false,
      czyMozeUsuwac: false,
      czyMozeEdytowac: false,
      czyMozePrzegladac: false,
    },
  }),
  components: {
    modal,
  },
  mounted: function() {
    this.pobierzDaneDoTabeli();
    this.pobierzDaneAutoryzacyjne();
  },
  computed: {
    //informacja czy jest w trybie edycji
    czyJestWTrybieEdycji() {
      return this.$router.params.Id ? true : false;
    },
  },
  methods: {
    generujPdf() {
      const doc = new jsPDF("p", "mm", "a4");

      doc.setFont("Aleo-Regular");

      doc.text(
        "Wykaz przeglądów instrukcji środowiskowych systemu zarządzania",
        14,
        10
      );
      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "silver",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            "Lp",
            "Nr instrukcji",
            "Nazwa instrukcji",
            "Data przeglądu",
            "Data następnego przeglądu",
            "Ilość dni",
            "Przegląd wykonał",
          ],
        ],
        body: this.searched.map((MaszynyPrzeglady) => {
          return [
            MaszynyPrzeglady.Lp,
            MaszynyPrzeglady.Nr_dokumentu,
            MaszynyPrzeglady.Nazwa_dokumentu,
            MaszynyPrzeglady.Data_przegladu,
            MaszynyPrzeglady.Data_nastepnego_przegladu,
            MaszynyPrzeglady.Pozostalo_dni || "",
            MaszynyPrzeglady.Przeglad_wykonal,
          ];
        }),
      });

      doc.save("wykaz_przegladow_instrukcji_srodowiskowych.pdf");
    },
    pobierzDaneAutoryzacyjne() {
      authorizationService
        .getInfo("StronaTabelaPrzegladyInstrukcjeSrodowiskowe", 4)

        .then((obiektOdpowiedzi) => {
          const dane = obiektOdpowiedzi.data.dostep;
          this.autoryzacja = {
            czyMozeDodawac: Boolean(
              dane.find(({ Id_operacji }) => Id_operacji == "1")
            ),
            czyMozeUsuwac: Boolean(
              dane.find(({ Id_operacji }) => Id_operacji == "2")
            ),
            czyMozeEdytowac: Boolean(
              dane.find(({ Id_operacji }) => Id_operacji == "3")
            ),
            czyMozePrzegladac: Boolean(
              dane.find(({ Id_operacji }) => Id_operacji == "4")
            ),
          };
        })
        .catch((error) => {
          console.log(error);
        });
    },
    pobierzDaneDoTabeli() {
      const { id } = this.$route.params;

      console.log(this.$route);

      api
        .pobieranieISPrzeglad(id)
        .then((obiektOdpowiedzi) => {
          this.MaszynyPrzeglady = obiektOdpowiedzi.data.dane.map(
            (proces, index) => ({ ...proces, Lp: index + 1 })
          );
          this.searched = this.MaszynyPrzeglady;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.czyPobranoDane = true;
        });
    },
    funkcjaZamykajacaDialog(sukces) {
      this.pokazDialog = false;
      this.typAktywnegoFormularza = null;
      this.obiektKonfiguracyjny = null;
      if (sukces) {
        this.pobierzDaneDoTabeli();
      }
    },
    newUser() {
      window.alert("Noop");
    },
    searchOnTable() {
      this.searched = search(this.MaszynyPrzeglady, this.search);
    },
    usuwanieElementu(Id) {
      this.pokazDialog = true;
      this.typAktywnegoFormularza = "usuwanie";
      this.obiektKonfiguracyjny = {
        Id,
        typ: "instrukcje_IS_przeglady",
      };
    },
    pokazPDF(Id) {
      //dopisane
      this.pokazDialog = true;
      this.typAktywnegoFormularza = "pokaz_PDF";
      this.obiektKonfiguracyjny = {
        Id,
        Klucz: "instrukcje_IS_przeglady",
      };
    },
    pokazPDF_INFO() {
      //dopisane
      this.pokazDialog = true;
      this.typAktywnegoFormularza = "pokaz_PDF_INFO";
      this.obiektKonfiguracyjny = {
        Nazwa_instrukcji: "INFO-3",
      };
    },
    wyborElementu(element) {
      this.wybranyElementTabeli = element;
    },
  },
};
</script>

<style>
.tabelaPrzegladyIS .md-field {
  max-width: 300px;
}
.tabelaPrzegladyIS .tabela {
  width: 100%;
}
.tabelaPrzegladyIS .md-card {
  box-shadow: none !important;
}
.tabelaPrzegladyIS .md-title {
  font-weight: 900 !important;
  color: black !important;
  font-size: 1.8vh !important;
  padding: 10px;
}
.tabelaPrzegladyIS .md-toolbar {
  height: 14vh;
}
.tabelaPrzegladyIS .md-table-head {
  font-weight: bold;
  color: black !important;
  font-size: 1.1vh !important;
  text-align: left !important;
}
.tabelaPrzegladyIS .md-table-head-label {
  text-align: left !important;
  font-size: 1.1vh !important;
}
.tabelaPrzegladyIS .md-table-cell {
  font-size: 1.1vh !important;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-align: left;
  color: black;
  font-weight: 300;
  margin-top: 200px !important;
}
.tabelaPrzegladyIS .ikony {
  cursor: pointer;
  margin-right: 40px;
}
.tabelaPrzegladyIS .md-table-cell:nth-child(1) {
  width: 7vw;
  text-align: left !important;
}
.tabelaPrzegladyIS .md-table-cell:nth-child(2) {
  width: 12vw;
  text-align: left !important;
}
.tabelaPrzegladyIS .md-table-cell:nth-child(3) {
  width: 19vw;
  text-align: left !important;
}
.tabelaPrzegladyIS .md-table-cell:nth-child(4) {
  width: 13vw;
  text-align: left !important;
}
.tabelaPrzegladyIS .md-table-cell:nth-child(5) {
  width: 13vw;
  text-align: left !important;
}
.tabelaPrzegladyIS .md-table-cell:nth-child(6) {
  width: 13vw;
  text-align: left !important;
}
.tabelaPrzegladyIS .md-table-cell:nth-child(7) {
  width: 13vw;
  text-align: left !important;
}

.tabelaPrzegladyIS .button {
  margin-right: 5px;
  border: 3px white ridge;
  font-weight: bold;
  font-size: 1.1vmin;
  min-width: 9vw;
}
.tabelaPrzegladyIS .button:hover {
  background-color: #727171;
  text-decoration: none;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  color: white;
}
.tabelaPrzegladyIS .menu_strony {
  border: 1px solid;
  margin-top: 5px;
  padding: 3px;
  background: #939393;
  width: 84.5%;
}
.tabelaPrzegladyIS .menu_strony1 {
  border: 1px solid;
  margin-top: 5px;
  padding: 3px;
  background: #939393;
  width: 15%;
  margin-left: 0.5%;
}
.tabelaPrzegladyIS .laczacy {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.md-table.md-theme-default .md-table-row.md-selected,
.md-table.md-theme-default .md-table-row.md-selected-single {
  background-color: #dddddd !important;
}
.PasekWyboru {
  background: #606060;
}
.tabelaPrzegladyIS .ikony {
  margin-right: 4px;
  margin-left: 4px;
  border: 1px solid !important;
  border-radius: 5px;
  padding: 5px;
}
.tabelaPrzegladyIS .ikony:hover {
  background-color: black;
  text-decoration: none;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
.podpowiedz {
  font-size: 1.5vmin !important;
  font-weight: bold;
  background-color: black !important;
}
.btn {
  background-color: #606060 !important;
  padding: 0rem 0rem !important;
  border-color: #606060 !important;
}
</style>
