<template>
  <div class="Form_masz_paliwo">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI KARTY PRACY MASZYN I URZĄDZEŃ"
                : "FORMULARZ DODAWANIA KARTY PRACY MASZYN I URZĄDZEŃ"
            }}
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-autocomplete
                :class="getValidationClass('Nazwa_wybor_maszyny')"
                v-model="form.Nazwa_wybor_maszyny"
                :md-options="zapytaniaMaszyny"
                :disabled="sending || czyJestWTrybieEdycji"
              >
                <label>Wybór maszyny</label>
                <span
                  class="md-error"
                  v-if="!$v.form.Nazwa_wybor_maszyny.required"
                  >Pole jest wymagane</span
                >
              </md-autocomplete>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="MU2"
                :class="getValidationClass('Data_tankowania')"
              >
                <label for="Data_tankowania">Data karty pracy</label>
                <md-input
                  type="date"
                  name="Data_tankowania"
                  id="Data_tankowania"
                  autocomplete="Data_tankowania"
                  v-model="form.Data_tankowania"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Data_tankowania.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field
                class="MU2"
                :class="getValidationClass('Osoba_odpowiedzialna')"
              >
                <label for="Osoba_odpowiedzialna">Osoba odpowiedzialna</label>
                <md-input
                  type="text"
                  name="Osoba_odpowiedzialna"
                  id="Osoba_odpowiedzialna"
                  autocomplete="Osoba_odpowiedzialna"
                  md-counter="50"
                  v-model="form.Osoba_odpowiedzialna"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Osoba_odpowiedzialna.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Osoba_odpowiedzialna.maxlength"
                  >Wymagana ilość znaków od 1 do 50</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="MU2"
                :class="getValidationClass('Licznik_poczatkowy')"
              >
                <label for="Licznik_poczatkowy">Stan początkowy licznika</label>
                <md-input
                  type="text"
                  id="Licznik_poczatkowy"
                  name="Licznik_poczatkowy"
                  autocomplete="Licznik_poczatkowy"
                  md-counter="30"
                  v-model="form.Licznik_poczatkowy"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Licznik_poczatkowy.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Licznik_poczatkowy.maxlength"
                  >Wymagana ilość znaków od 1 do 30</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field
                class="MU2"
                :class="getValidationClass('Licznik_koncowy')"
              >
                <label for="Licznik_koncowy">Stan końcowy licznika</label>
                <md-input
                  type="text"
                  id="Licznik_koncowy"
                  name="Licznik_koncowy"
                  autocomplete="Licznik_koncowy"
                  md-counter="30"
                  v-model="form.Licznik_koncowy"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Licznik_koncowy.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Licznik_koncowy.maxlength"
                  >Wymagana ilość znaków od 1 do 30</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="MU2" :class="getValidationClass('Ilosc')">
                <label for="Ilosc">Ilość zatankowanego paliwa</label>
                <md-input
                  type="text"
                  id="Ilosc"
                  name="Ilosc"
                  autocomplete="Ilosc"
                  md-counter="30"
                  v-model="form.Ilosc"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Ilosc.required"
                  >Pole jest wymagane</span
                >
                <span class="md-error" v-else-if="!$v.form.Ilosc.maxlength"
                  >Wymagana ilość znaków od 1 do 30</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field class="M4" :class="getValidationClass('Id_paliwo')">
                <label for="Id_paliwo">Rodzaj paliwa</label>

                <md-select
                  v-model="form.Id_paliwo"
                  :disabled="sending"
                  name="Id_paliwo"
                  id="Id_paliwo"
                  autocomplete="Id_paliwo"
                  md-dense
                >
                  <md-option
                    v-for="(rodzaj_paliwa, index) in Rodzaje_paliwa"
                    :key="index"
                    :value="rodzaj_paliwa.Id"
                    >{{ rodzaj_paliwa.Rodzaj }}</md-option
                  >
                </md-select>
                <span class="md-error" v-if="!$v.form.Id_paliwo.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field class="M4" :class="getValidationClass('Id_jednostka')">
                <label for="Id_jednostka">Jednostka</label>

                <md-select
                  v-model="form.Id_jednostka"
                  :disabled="sending"
                  name="Id_jednostka"
                  id="Id_jednostka"
                  autocomplete="Id_jednostka"
                  md-dense
                >
                  <md-option
                    v-for="(jednostka, index) in Jednostki"
                    :key="index"
                    :value="jednostka.Id"
                    >{{ jednostka.Jednostka_paliwo }}</md-option
                  >
                </md-select>
                <span class="md-error" v-if="!$v.form.Id_jednostka.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>
        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

        <div style="text-align: center">
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            czyJestWTrybieEdycji
              ? "ZATWIERDŹ - EDYTOWANY WPIS"
              : "ZATWIERDŹ - UTWÓRZ WPIS"
          }}</md-button>
        </div>
      </md-card>
    </form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import minLength from "vuelidate/lib/validators/minLength";
import { api } from "./../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_paliwa_maszyny",
  mixins: [validationMixin],
  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      Id: null,
      Nazwa_wybor_maszyny: null,
      Data_tankowania: null,
      Osoba_odpowiedzialna: null,
      Licznik_poczatkowy: null,
      Licznik_koncowy: null,
      Ilosc: null,
      Id_paliwo: null,
      Id_jednostka: null,
    },
    IdEdytowanegoElementu: null,
    userSaved: false,
    sending: false,
    lastUser: null,
    Nazwy_maszyn: [],
    Rodzaje_paliwa: [],
    Jednostki: [],
  }),

  async created() {
    try {
      const [
        odpowiedz_Nazwa_maszyny,
        odpowiedz_rodzaje_paliwa,
        odpowiedz_jednostki,
      ] = await Promise.all([
        api.pobierzNazwa_maszyny(),
        api.pobierzRodzajPaliwa(),
        api.pobierzJednostki(),
      ]);
      odpowiedz_Nazwa_maszyny,
        odpowiedz_rodzaje_paliwa,
        odpowiedz_jednostki,
        (this.Nazwy_maszyn = odpowiedz_Nazwa_maszyny.data.dane.map((el) => ({
          ...el,
          nazwa: `Id maszyny: ${el.Id_maszyny} ; Maszyna: ${el.Nazwa_maszyny}`,
        })));
      this.Rodzaje_paliwa = odpowiedz_rodzaje_paliwa.data.dane;
      this.Jednostki = odpowiedz_jednostki.data.dane;
      if (this.obiektKonfiguracyjny.akcja === "edycja") {
        //edycja

        const odpowiedz = await api.pobieranieMaszynyPaliwoEdycja(
          this.obiektKonfiguracyjny.Id
        );
        const {
          Id,
          Id_maszyny,
          Data_tankowania,
          Osoba_odpowiedzialna,
          Licznik_poczatkowy,
          Licznik_koncowy,
          Ilosc,
          Id_paliwo,
          Id_jednostka,
        } = odpowiedz.data.dane[0];
        this.IdEdytowanegoElementu = Id;
        this.form.Nazwa_wybor_maszyny = this.Nazwy_maszyn.find(
          ({ Id }) => Id == Id_maszyny
        ).nazwa;
        this.form.Data_tankowania = Data_tankowania;
        this.form.Osoba_odpowiedzialna = Osoba_odpowiedzialna;
        this.form.Licznik_poczatkowy = Licznik_poczatkowy;
        this.form.Licznik_koncowy = Licznik_koncowy;
        this.form.Ilosc = Ilosc;
        this.form.Id_paliwo = Id_paliwo;
        this.form.Id_jednostka = Id_jednostka;
      }
    } catch (error) {
      console.log(error);
    }
  },
  validations: {
    form: {
      Nazwa_wybor_maszyny: {
        required,
      },
      Data_tankowania: {
        required,
      },
      Osoba_odpowiedzialna: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(50),
      },
      Licznik_poczatkowy: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(30),
      },
      Licznik_koncowy: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(30),
      },
      Ilosc: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(30),
      },
      Id_paliwo: {
        required,
      },
      Id_jednostka: {
        required,
      },
    },
  },
  computed: {
    Id_maszyny: function () {
      const { Nazwa_wybor_maszyny } = this.form;
      const znalezionyElement = this.Nazwy_maszyn.find(
        ({ nazwa }) => nazwa === Nazwa_wybor_maszyny
      );

      if (znalezionyElement) {
        return znalezionyElement.Id;
      }
      return null;
    },
    Rok_produkcji: function () {
      const { Nazwa_wybor_maszyny } = this.form;
      const znalezionyElement = this.Nazwy_maszyn.find(
        ({ nazwa }) => nazwa === Nazwa_wybor_maszyny
      );

      if (znalezionyElement) {
        return znalezionyElement.Rok_produkcji;
      }
      return null;
    },
    zapytaniaMaszyny: function () {
      return this.Nazwy_maszyn.map((el) => el.nazwa);
    },
    czyJestWTrybieEdycji: function () {
      return this.obiektKonfiguracyjny.akcja === "edycja";
    },
  },

  methods: {
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.Id = null;
      this.form.Data_tankowania = null;
      this.form.Osoba_odpowiedzialna = null;
      this.form.Licznik_poczatkowy = null;
      this.form.Licznik_koncowy = null;
      this.form.Ilosc = null;
      this.form.Id_paliwo = null;
      this.form.Id_jednostka = null;
    },

    zapiszFormularz() {
      this.sending = true;

      if (this.czyJestWTrybieEdycji) {
        const dane = new FormData(); // dane typu FormData;
        
        dane.append("Data_tankowania", this.form.Data_tankowania);
        dane.append("Osoba_odpowiedzialna", this.form.Osoba_odpowiedzialna);
        dane.append("Licznik_poczatkowy", this.form.Licznik_poczatkowy);
        dane.append("Licznik_koncowy", this.form.Licznik_koncowy);
        dane.append("Ilosc", this.form.Ilosc);
        dane.append("Id_paliwo", this.form.Id_paliwo);
        dane.append("Id_jednostka", this.form.Id_jednostka);

        api
          .zapiszPaliwoMaszynyUpdate(dane, this.IdEdytowanegoElementu)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const dane = new FormData();
        dane.append("Id_maszyny", this.Id_maszyny);
        dane.append("Rok_produkcji", this.Rok_produkcji);
        dane.append("Data_tankowania", this.form.Data_tankowania);
        dane.append("Osoba_odpowiedzialna", this.form.Osoba_odpowiedzialna);
        dane.append("Licznik_poczatkowy", this.form.Licznik_poczatkowy);
        dane.append("Licznik_koncowy", this.form.Licznik_koncowy);
        dane.append("Ilosc", this.form.Ilosc);
        dane.append("Id_paliwo", this.form.Id_paliwo);
        dane.append("Id_jednostka", this.form.Id_jednostka);

        api
          .zapiszPaliwoMaszyny(dane)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    naszWalidacjaFormularza() {
      if (this.czyJestWTrybieEdycji) {
        return (
          !this.$v.form.Nazwa_wybor_maszyny.$invalid &&
          !this.$v.form.Data_tankowania.$invalid &&
          !this.$v.form.Osoba_odpowiedzialna.$invalid &&
          !this.$v.form.Licznik_poczatkowy.$invalid &&
          !this.$v.form.Licznik_koncowy.$invalid &&
          !this.$v.form.Ilosc.$invalid &&
          !this.$v.form.Id_paliwo.$invalid &&
          !this.$v.form.Id_jednostka.$invalid
        );
      } else {
        return !this.$v.$invalid;
      }
    },

    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>

<style>
.Form_masz_paliwo .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.Form_masz_paliwo .md-layout .md-gutter {
  min-width: 55vw !important; /* szerokość modala*/
  max-width: 100% !important;
  min-height: 7vh; /* wysokość pomiędzy mierszami w modalu*/
}
.md-layout {
  padding-right: 0px !important;
}
.Form_masz_paliwo .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}

.Form_masz_paliwo .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  margin: 10px 24px !important;
  padding-top: 35px !important;
}
.Form_masz_paliwo .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}

.Form_masz_paliwo {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
  overflow: auto !important;
}
.Form_masz_paliwo .md-primary {
  border: 1px solid !important;

  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}
.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important; /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}

.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}
.Form_masz_paliwo .md-list-item-text {
  font-size: 1.7vmin !important;
}
.Form_masz_paliwo .md-card-actions .md-button {
  margin-right: 50px !important;
}
</style>