<template>
  <md-dialog :md-active="true" @md-clicked-outside="funkcjaZamykajacaDialog">

    <FormularzDodawanieFaktury
      v-if="rodzajFormularza === 'formularz_dodawanie_faktury'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />

    <FormularzDodawanieKontrahenta
      v-if="rodzajFormularza === 'formularz_dodawanie_kontrahenta'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />

    <FormularzDodawanieOsobDoKontaktu
      v-if="rodzajFormularza === 'formularz_dodawanie_osoób_do_kontaktu'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
  </md-dialog>
</template>

<script>
import FormularzDodawanieFaktury from "./FAK_faktury/formularz_dodawanie_faktury";
import FormularzDodawanieKontrahenta from "./SO_kontrahenci/formularz_dodawanie_kontrahenta";
import FormularzDodawanieOsobDoKontaktu from "./SO_kontrahenci/formularz_dodawanie_osoób_do_kontaktu"

export default {
  name: "modalKontrahenci",
  components: {
    FormularzDodawanieFaktury,
    FormularzDodawanieKontrahenta,
    FormularzDodawanieOsobDoKontaktu,

  },
  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    rodzajFormularza: {
      type: String,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
};
</script>



