<template >
  <div>
  <TabelaPraceSzczegolnieNiebezpieczne />
  </div>
</template>

<script>
import TabelaPraceSzczegolnieNiebezpieczne from '../../komponenty/strona-tabela-komponenty/BHP_prace_szczegolnie_niebezpieczne/tabela_wykaz_prac_szczegolnie_niebezpiecznych.vue'


export default {
  name: 'StronaTabelaPraceSzczegolnieNiebezpieczne',
  components: {
    TabelaPraceSzczegolnieNiebezpieczne,
    
  }

}
</script>

