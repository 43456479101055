<template>
  <div>
  <TabelaBudynkiPrzegladPolroczny/>
  </div>
</template>

<script>
import TabelaBudynkiPrzegladPolroczny from '../../komponenty/strona-tabela-komponenty/WST_budynki/tabela_budynki_przeglady_polrocznych.vue'


export default {
  name: 'StronaTabelaBudynkiPrzegladPolroczny',
  components: {
    TabelaBudynkiPrzegladPolroczny,
  }

}
</script>
