<template>
  <button @click="$emit('click')" class="kafelek">
    <img id="zdjecie" :src="sciezkaZdjecia" :alt="opisZdjecia" />
    <p id="tytul">{{ podpisPodKafelkiem }}</p>
  </button>

    
</template>

<script>
export default {
  name: "KafelekWyboru",
  props: {
    sciezkaZdjecia: {
      type: String,
      required: true,
    },
    opisZdjecia: {
      type: String,
      required: true,
    },
    podpisPodKafelkiem: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.kafelek {
  max-width: 80vw;
  max-height: 65vh;
  padding-top: 5vh;
  display: block;
  border: 3px ridge silver;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  background-color: #f5f5f5;
  float: center;
  flex: auto;

}
.kafelek:hover {
  background-color: #eee4e4;
  text-decoration: none;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
#zdjecie {
  padding: 3%;
  width: 70%;
  height: 50%;
}
#tytul {
  font-size: 2.5vh;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: black;
  font-weight: bold;
  margin-top: 1vh;
  padding-bottom: 3vh;
}
</style>
