<template>
  <div class="FORM_WYPADKI">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI WYPADKU W PRACY"
                : "FORMULARZ DODAWANIA WYPADKU W PRACY"
            }}
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-gutter">
            
             <div class="md-layout-item md-small-size-100"
            >
              <md-field
                class="M5"
                :class="getValidationClass('Data_wypadku')"
              >
                <label for="Data_wypadku">Data wypadku</label>
                <md-input
                  type="date"
                  name="Data_wypadku"
                  id="Data_wypadku"
                  autocomplete="Data_wypadku"
                  v-model="form.Data_wypadku"
                  @input="pobierzNumerWypadku"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Data_wypadku.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
            
            <div class="md-layout-item md-small-size-100"
            >
              <md-field
                class="M5"
                :class="getValidationClass('Data_protokolu')"
              >
                <label for="Data_protokolu">Data protokołu</label>
                <md-input
                  type="date"
                  name="Data_protokolu"
                  id="Data_protokolu"
                  autocomplete="Data_protokolu"
                  v-model="form.Data_protokolu"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Data_protokolu.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

           

            <div class="md-layout-item md-small-size-100"
            >
              <md-field
                class="M5"
                :class="getValidationClass('Numer')"
              >
                <label for="Numer">Nr protokołu</label>
                <md-input
                  
                  name="Numer"
                  id="Numer"
                  autocomplete="Numer"
                  v-model="form.Numer"
                  :disabled="sending || czyJestWTrybieEdycji"
                  readonly
                />
                <span class="md-error" v-if="!$v.form.Numer.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Numer.maxlength"
                  >Wymagana ilość znaków od 1 do 15</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-autocomplete
                :class="getValidationClass('Nazwa_wybor_pracownika')"
                v-model="form.Nazwa_wybor_pracownika"
                :md-options="zapytaniaPracownicy"
                :disabled="sending"
              >
                <label>Wybór pracownika</label>
                <span
                  class="md-error"
                  v-if="!$v.form.Nazwa_wybor_pracownika.required"
                  >Pole jest wymagane</span
                >
              </md-autocomplete>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M2"
                :class="getValidationClass('Skutek')"
              >
                <label for="Skutek">Skutek wypadku</label>
                <md-textarea

                  name="Skutek"
                  id="Skutek"
                  
                  md-autogrow
                  md-counter="500"
                  v-model="form.Skutek"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Skutek.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Skutek.maxlength"
                  >Wymagana ilość znaków od 1 do 500</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M2"
                :class="getValidationClass('Uwagi')"
              >
                <label for="Uwagi">Uwagi</label>
                <md-textarea

                  name="Uwagi"
                  id="Uwagi"
                  
                  md-autogrow
                  md-counter="500"
                  v-model="form.Uwagi"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Uwagi.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Uwagi.maxlength"
                  >Wymagana ilość znaków od 1 do 500</span
                >
              </md-field>
            </div>
          </div>

           <div class="md-layout md-gutter">
          <div class="md-layout-item md-small-size-100">
              <md-field class="M4" :class="getValidationClass('Id_status')">
                <label for="Id_status">Status wypadku</label>

                <md-select
                  v-model="form.Id_status"
                  :disabled="sending"
                  name="Id_status"
                  id="Id_status"
                  autocomplete="Id_status"
                  md-dense
                >
                  <md-option
                    v-for="(status, index) in Statusy"
                    :key="index"
                    :value="status.Id"
                    >{{ status.Status }}</md-option
                  >
                </md-select>
                <span class="md-error" v-if="!$v.form.Id_status.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M2"
                :class="getValidationClass('Osoba_odpowiedzialna')"
              >
                <label for="Osoba_odpowiedzialna">Osoba odpowiedzialna</label>
                <md-input

                  name="Osoba_odpowiedzialna"
                  id="Osoba_odpowiedzialna"
                  
                  md-autogrow
                  md-counter="50"
                  v-model="form.Osoba_odpowiedzialna"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Osoba_odpowiedzialna.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Osoba_odpowiedzialna.maxlength"
                  >Wymagana ilość znaków od 1 do 50</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M2"
                :class="getValidationClass('L4')"
              >
                <label for="L4">Ilość dni L4</label>
                <md-input

                  name="L4"
                  id="L4"
                  
                  md-autogrow
                  md-counter="10"
                  v-model="form.L4"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.L4.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.L4.maxlength"
                  >Wymagana ilość znaków od 1 do 10</span
                >
              </md-field>
            </div>
            </div>

            <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="plikZwalidowany_1 ? '' : 'md-invalid'">
                <label
                  >Wstaw plik - PROTOKÓŁ WYPADKOWY - "max-2Mb"
                  <span v-if="nazwaPliku_1">
                    - obecnie wstawiony plik to {{ nazwaPliku_1 }}</span
                  ></label
                >
                <md-file
                  v-model="form.plik_PDF_1"
                  @md-change="zapamietajPlik_1"
                  accept="application/pdf"
                />
                <span class="md-error" v-if="!plikZwalidowany_1"
                  >Plik moze miec max 2MB</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="plikZwalidowany_2 ? '' : 'md-invalid'">
                <label
                  >Wstaw plik - PRZESŁUCHANIE ŚWIADKÓW - "max-2Mb"
                  <span v-if="nazwaPliku_2">
                    - obecnie wstawiony plik to {{ nazwaPliku_2 }}</span
                  ></label
                >
                <md-file
                  v-model="form.plik_PDF_2"
                  @md-change="zapamietajPlik_2"
                  accept="application/pdf"
                />
                <span class="md-error" v-if="!plikZwalidowany_2"
                  >Plik moze miec max 2MB</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="plikZwalidowany_3 ? '' : 'md-invalid'">
                <label
                  >Wstaw plik - PRZESŁUCHANIE POSZKODOWANEGO - "max-2Mb"
                  <span v-if="nazwaPliku_3">
                    - obecnie wstawiony plik to {{ nazwaPliku_3 }}</span
                  ></label
                >
                <md-file
                  v-model="form.plik_PDF_3"
                  @md-change="zapamietajPlik_3"
                  accept="application/pdf"
                />
                <span class="md-error" v-if="!plikZwalidowany_3"
                  >Plik moze miec max 2MB</span
                >
              </md-field>
            </div>
          </div>

        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

        
          <div style="text-align: center">
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            czyJestWTrybieEdycji
              ? "ZATWIERDŹ - EDYTOWANY WPIS"
              : "ZATWIERDŹ - UTWÓRZ WPIS"
          }}</md-button>
        </div>
      </md-card>

    </form>
  </div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import minLength from "vuelidate/lib/validators/minLength";
import { api } from "../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_maszyny",

  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      Numer: null,
      Data_wypadku: null,
      Data_protokolu: null,
      Nazwa_wybor_pracownika: null,
      Skutek: null,
      Uwagi: null,
      Id_status: null,
      L4: null,
      Osoba_odpowiedzialna: null,
      plik_PDF_1: null,
      plik_dane_1: null,
      plik_PDF_2: null,
      plik_dane_2: null,
      plik_PDF_3: null,
      plik_dane_3: null,

    },
    IdEdytowanegoElementu: null,
    userSaved: false,
    nazwaPliku: null,
    sending: false,
    lastUser: null,
    Pracownicy:[],
    Statusy:[],
    Lp: null,
    nazwaPliku_1: null,
    nazwaPliku_2: null,
    nazwaPliku_3: null,
  }),
  async created() {
    try {
      const [odpowiedz_Pracownik, odpowiedz_Status] = await Promise.all([
        api.pobierzPracownika(),
        api.PobierzStatusWypadekWPracy(),
      ]);
      odpowiedz_Pracownik,
        (this.Pracownicy = odpowiedz_Pracownik.data.dane.map((el) => ({
          ...el,
          nazwa: `${el.Nazwisko} ${el.Imie1} - NIP: ${el.NIP} ; PESEL: ${el.Pesel}`,
        })));

       this.Statusy = odpowiedz_Status.data.dane;


      if (this.obiektKonfiguracyjny.akcja === "edycja") {
        //edycja
        const odpowiedz = await api.pobieranieDokumentacjaWWPEdycja(
          this.obiektKonfiguracyjny.Id
        );
        const {
          Id,
          Numer,
          Data_wypadku,
          Data_protokolu,
          Id_pracownika,
          Skutek,
          Uwagi,
          Id_status,
          L4,
          Osoba_odpowiedzialna,
          Nazwa_zalacznika_1,
          Nazwa_zalacznika_2,
          Nazwa_zalacznika_3,
        } = odpowiedz.data.dane[0];
        this.IdEdytowanegoElementu = Id;
        this.form.Numer = Numer;
        this.form.Data_wypadku = Data_wypadku;
        this.form.Data_protokolu = Data_protokolu;
        this.form.Nazwa_wybor_pracownika = this.Pracownicy.find(
          ({ Id }) => Id == Id_pracownika
        ).nazwa;
        this.form.Skutek = Skutek;
        this.form.Uwagi = Uwagi;
        this.form.Id_status = Id_status;
        this.form.L4 = L4;
        this.form.Osoba_odpowiedzialna = Osoba_odpowiedzialna;
        this.nazwaPliku_1 = Nazwa_zalacznika_1;
        this.nazwaPliku_2 = Nazwa_zalacznika_2;
        this.nazwaPliku_3 = Nazwa_zalacznika_3;
      }
    } catch (error) {
      console.log(error);
    }
  },
  validations: {
    form: {
      Nazwa_wybor_pracownika: {
        required,
      },
      Numer: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(15),
      },
      Data_wypadku: {
        required,
      },
      Data_protokolu: {
        required,
      },
      Skutek: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(500),
      },
      Uwagi: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(500),
      },
      Id_status: {
        required,
      },
      L4: {
        required: false,
        minLenght: minLength(1),
        maxLength: maxLength(10),
      },
      Osoba_odpowiedzialna: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(50),
      },
    },
  },
  computed: {
    plikZwalidowany_1: function () {
      return this.form.plik_dane_1
        ? this.form.plik_dane_1.size < 2 * 1024 * 1024
        : true;
    },
    plikZwalidowany_2: function () {
      return this.form.plik_dane_2
        ? this.form.plik_dane_2.size < 2 * 1024 * 1024
        : true;
    },
    plikZwalidowany_3: function () {
      return this.form.plik_dane_3
        ? this.form.plik_dane_3.size < 2 * 1024 * 1024
        : true;
    },
    Id_pracownika: function() {
      const { Nazwa_wybor_pracownika } = this.form;
      const znalezionyElement = this.Pracownicy.find(
        ({ nazwa }) => nazwa === Nazwa_wybor_pracownika
      );

      if (znalezionyElement) {
        return znalezionyElement.Id;
      }
      return null;
    },
    zapytaniaPracownicy: function() {
      return this.Pracownicy.map((el) => el.nazwa);
    },
    czyJestWTrybieEdycji: function () {
      return this.obiektKonfiguracyjny.akcja === "edycja";
    },
    numerWypadku: function () {
      if (this.Lp) {
        const { numer, miesiac, rok } = this.Lp;
        return `W-${numer}/${miesiac}/${rok}`;
      } else {
        return "";
      }
    },
  },

  methods: {
    zapamietajPlik_1(pliki) {
      if (pliki[0]) {
        this.form.plik_dane_1 = pliki[0];
      }
    },
    zapamietajPlik_2(pliki) {
      if (pliki[0]) {
        this.form.plik_dane_2 = pliki[0];
      }
    },
    zapamietajPlik_3(pliki) {
      if (pliki[0]) {
        this.form.plik_dane_3 = pliki[0];
      }
    },
    pobierzNumerWypadku() {
      const { Data_wypadku } = this.form;
      const data = new Date(Data_wypadku);
      const rok = data.getFullYear();
      const miesiac = data.getMonth() + 1;

      api.PobierzLpWypadku({ rok, miesiac }).then((odpowiedz) => {
        this.Lp = odpowiedz.data.dane;
        this.form.Numer = this.numerWypadku;
      });
    },
 
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.Numer = null;
      this.form.Data_wypadku = null;
      this.form.Data_protokolu = null;
      this.form.Id_pracownika = null;
      this.form.Skutek = null;
      this.form.Uwagi = null;
      this.form.Id_status = null;
      this.form.L4 = null;
      this.form.Osoba_odpowiedzialna = null;
      this.form.plik_PDF_1 = null;
      this.form.plik_dane_1 = null;
      this.nazwaPliku_1 = null;
      this.form.plik_PDF_2 = null;
      this.form.plik_dane_2 = null;
      this.nazwaPliku_2 = null;
      this.form.plik_PDF_3 = null;
      this.form.plik_dane_3 = null;
      this.nazwaPliku_3 = null;
    },

    zapiszFormularz() {
      this.sending = true;

      if (this.czyJestWTrybieEdycji) {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Numer", this.form.Numer);
        dane.append("Data_wypadku", this.form.Data_wypadku);
        dane.append("Data_protokolu", this.form.Data_protokolu);
        dane.append("Id_pracownika", this.Id_pracownika);
        dane.append("Skutek", this.form.Skutek);
        dane.append("Uwagi", this.form.Uwagi);
        dane.append("Id_status", this.form.Id_status);
        dane.append("L4", this.form.L4);
        dane.append("Osoba_odpowiedzialna", this.form.Osoba_odpowiedzialna);
        dane.append("Lp", this.Lp.numer);
        dane.append("plik_PDF_1", this.form.plik_dane_1);
        dane.append("plik_PDF_2", this.form.plik_dane_2);
        dane.append("plik_PDF_3", this.form.plik_dane_3);

        api
          .zapiszDokumentacjaWWPUpdate(dane, this.IdEdytowanegoElementu)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Numer", this.form.Numer);
        dane.append("Data_wypadku", this.form.Data_wypadku);
        dane.append("Data_protokolu", this.form.Data_protokolu);
        dane.append("Id_pracownika", this.Id_pracownika);
        dane.append("Skutek", this.form.Skutek);
        dane.append("Uwagi", this.form.Uwagi);
        dane.append("Id_status", this.form.Id_status);
        dane.append("L4", this.form.L4);
        dane.append("Lp", this.Lp.numer);
        dane.append("Osoba_odpowiedzialna", this.form.Osoba_odpowiedzialna);
        dane.append("plik_PDF_1", this.form.plik_dane_1);
        dane.append("plik_PDF_2", this.form.plik_dane_2);
        dane.append("plik_PDF_3", this.form.plik_dane_3);

        api
          .zapiszDokumentacjaWWP(dane)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    naszWalidacjaFormularza() {
      if (this.czyJestWTrybieEdycji) {
        return (
          !this.$v.form.Numer.$invalid &&
          !this.$v.form.Data_wypadku.$invalid &&
          !this.$v.form.Data_protokolu.$invalid &&
          !this.$v.form.Nazwa_wybor_pracownika.$invalid &&
          !this.$v.form.Skutek.$invalid &&
          !this.$v.form.Uwagi.$invalid &&
          !this.$v.form.Id_status.$invalid &&
          !this.$v.form.L4.$invalid &&
          !this.$v.form.Osoba_odpowiedzialna.$invalid &&
          this.plikZwalidowany_1 &&
          this.plikZwalidowany_2 &&
          this.plikZwalidowany_3
        );
      } else {
        return !this.$v.$invalid && 
        this.plikZwalidowany_1 && 
        this.plikZwalidowany_2 && 
        this.plikZwalidowany_3;
      }
    },
    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>


<style>
.FORM_WYPADKI .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.FORM_WYPADKI .md-layout .md-gutter {
  min-width: 55vw !important; /* szerokość modala*/
  max-width: 100% !important;
  min-height: 7vh; /* wysokość pomiędzy mierszami w modalu*/
}
.md-layout {
  padding-right: 0px !important;
}
.FORM_WYPADKI .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}

.FORM_WYPADKI .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  margin: 10px 24px !important;
  padding-top: 35px !important;
}
.FORM_WYPADKI .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}
.FORM_WYPADKI {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
  overflow: auto;
}
.FORM_WYPADKI .md-primary {
  border: 1px solid !important;
  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}
.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important; /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}

.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}
.md-list-item-text {
  font-size: 1.7vmin !important;
}
.FORM_WYPADKI .md-card-actions .md-button {
  margin-right: 4vw !important;
}
</style>