<template >
  <div>
  <TabelaDzialaniaPozaAuditOcenaSkutecznosci />
  </div>
</template>

<script>
import TabelaDzialaniaPozaAuditOcenaSkutecznosci from '../../komponenty/strona-tabela-komponenty/SYS_dzialania_poza_audit/tabela_dzialania_poza_auditi_ocena_skutecznosci.vue'


export default {
  name: 'StronaTabelaDzialaniaPozaAuditSkutecznosci',
  components: {
    TabelaDzialaniaPozaAuditOcenaSkutecznosci,
    
  }

}
</script>

