<template >
  <div>
  <TabelaOfertyPrzyjete/>
  </div>
</template>

<script>
import TabelaOfertyPrzyjete from '../../komponenty/strona-tabela-komponenty/SO_oferty/tabela_wykaz_ofert_przyjetych.vue'


export default {
  name: 'StronaTabelaOfertyPrzyjete',
  components: {
    TabelaOfertyPrzyjete,
    
  }

}
</script>

