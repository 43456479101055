<template >
  <div>
  <TabelaZapytaniaOdrzucone/>
  </div>
</template>

<script>
import TabelaZapytaniaOdrzucone from '../../komponenty/strona-tabela-komponenty/SO_zapytania_ofertowe/tabela_wykaz_zapytan_ofertowych_odrzuconych.vue'


export default {
  name: 'StronaTabelaZapytaniaOdrzucone',
  components: {
    TabelaZapytaniaOdrzucone,
    
  }

}
</script>

