<template>
  <div>
  <tabelaKontrahenci/>
  </div>
</template>

<script>
import TabelaKontrahenci from '../../komponenty/strona-tabela-komponenty/SO_kontrahenci/tabela_kontrahenci.vue'


export default {
  name: 'StronaTabelaKontrahenci',
  components: {
      TabelaKontrahenci,
  }

}
</script>
