<template>
  <div class="FORM_RSP_zak">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI ZAKUPU WYROBU/USŁUGI"
                : "FORMULARZ DODAWANIA ZAKUPU WYROBU/USŁUGI"
            }}
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-autocomplete
                v-model="form.Nazwa_wybor_konrahenta"
                :md-options="zapytaniaWartosc"
              >
                <label>Wybór zakwalifikowanego kontrahenta</label>
              </md-autocomplete>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="MP2" :class="getValidationClass('Data_zakupu')">
                <label for="Data_zakupu">Data zakupu</label>
                <md-input
                  type="date"
                  id="Data_zakupu"
                  name="Data_zakupu"
                  autocomplete="Data_zakupu"
                  v-model="form.Data_zakupu"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Data_zakupu.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

             <div class="md-layout-item md-small-size-100">
              <md-field class="MP1" :class="getValidationClass('Nr_WZ')">
                <label for="Nr_WZ">Numer WZ</label>
                <md-input
                  name="Nr_WZ"
                  id="Nr_WZ"
                  
                  md-counter="50"
                  v-model="form.Nr_WZ"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Nr_WZ.required"
                  >Pole jest wymagane</span
                >
                <span class="md-error" v-else-if="!$v.form.Nr_WZ.maxlength"
                  >Wymagana ilość znaków od 1 do 50</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field class="MP1" :class="getValidationClass('Nr_faktury')">
                <label for="Nr_faktury">Numer faktury</label>
                <md-input
                  name="Nr_faktury"
                  id="Nr_faktury"
                  
                  md-counter="50"
                  v-model="form.Nr_faktury"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Nr_faktury.required"
                  >Pole jest wymagane</span
                >
                <span class="md-error" v-else-if="!$v.form.Nr_faktury.maxlength"
                  >Wymagana ilość znaków od 1 do 50</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="MP1" :class="getValidationClass('Asortyment')">
                <label for="Asortyment">Asortyment</label>
                <md-textarea
                  name="Asortyment"
                  id="Asortyment"
                  autocomplete="Asortyment"
                  md-autogrow
                  md-counter="500"
                  v-model="form.Asortyment"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Asortyment.required"
                  >Pole jest wymagane</span
                >
                <span class="md-error" v-else-if="!$v.form.Asortyment.maxlength"
                  >Wymagana ilość znaków od 1 do 500</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="MP2"
                :class="getValidationClass('Data_certyfikatu')"
              >
                <label for="Data_zakupu">Data certyfikatu/protokołu</label>
                <md-input
                  type="date"
                  id="Data_certyfikatu"
                  name="Data_certyfikatu"
                  autocomplete="Data_certyfikatu"
                  v-model="form.Data_certyfikatu"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Data_certyfikatu.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field
                class="MP1"
                :class="getValidationClass('Nr_certyfikatu')"
              >
                <label for="Nr_faktury">Numer certyfikatu/protokołu</label>
                <md-input
                  name="Nr_certyfikatu"
                  id="Nr_certyfikatu"
                  
                  md-counter="50"
                  v-model="form.Nr_certyfikatu"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Nr_certyfikatu.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Nr_certyfikatu.maxlength"
                  >Wymagana ilość znaków od 1 do 50</span
                >
              </md-field>
            </div>
          </div>

           <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="MP2"
                :class="getValidationClass('Osoba_odpowiedzialna')"
              >
                <label for="Osoba_odpowiedzialna">Osoba odpowiedzialna</label>
                <md-input

                  id="Osoba_odpowiedzialna"
                  name="Osoba_odpowiedzialna"
                  autocomplete="Osoba_odpowiedzialna"
                  v-model="form.Osoba_odpowiedzialna"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Osoba_odpowiedzialna.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Osoba_odpowiedzialna.maxlength"
                  >Wymagana ilość znaków od 1 do 50</span
                >
              </md-field>
            </div>
           </div>
        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

        <div style="text-align: center">
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            czyJestWTrybieEdycji
              ? "ZATWIERDŹ - EDYTOWANY WPIS"
              : "ZATWIERDŹ - UTWÓRZ WPIS"
          }}</md-button>
        </div>
      </md-card>
    </form>
  </div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import minLength from "vuelidate/lib/validators/minLength";
import { api } from "../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_przegladu_maszyny",

  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      Id: null,
      Nazwa_wybor_konrahenta: null,
      Data_zakupu: null,
      Nr_WZ: null,
      Nr_faktury: null,
      Asortyment: null,
      Nr_certyfikatu: null,
      Data_certyfikatu: null,
      Osoba_odpowiedzialna: null,
    },
    IdEdytowanegoElementu: null,
    userSaved: false,
    sending: false,
    lastUser: null,
    Kontrahenci: [],
  }),

  async created() {
    try {
      const [
        odpowiedz_Kontrahenci,
      ] = await Promise.all([
        api.PobierzZakwalifikowanegoDostawce(),

      ]);

      odpowiedz_Kontrahenci,
        (this.Kontrahenci = odpowiedz_Kontrahenci.data.dane.map((el) => ({
          ...el,
          nazwa: `${el.Nazwa_firmy}`,
        })));


      if (this.obiektKonfiguracyjny.akcja === "edycja") {
        //edycja
        const odpowiedz = await api.pobieranieZakupyEdycjaZrealizowane(
          this.obiektKonfiguracyjny.Id
        );
          const {
            Id,
            Id_kontrahenta,
            Data_zakupu,
            Nr_WZ,
            Nr_faktury,
            Asortyment,
            Nr_certyfikatu,
            Data_certyfikatu,
            Osoba_odpowiedzialna,
          } = odpowiedz.data.dane[0];
          this.IdEdytowanegoElementu = Id;
          this.form.Nazwa_wybor_konrahenta = this.Kontrahenci.find(
            ({ Id }) => Id == Id_kontrahenta
          ).nazwa;
          this.form.Data_zakupu = Data_zakupu;
          this.form.Nr_WZ = Nr_WZ;
          this.form.Nr_faktury = Nr_faktury;
          this.form.Asortyment = Asortyment;
          this.form.Nr_certyfikatu = Nr_certyfikatu;
          this.form.Data_certyfikatu = Data_certyfikatu;
          this.form.Osoba_odpowiedzialna = Osoba_odpowiedzialna;
 
      }
    } catch (error) {
      console.log(error);
    }
  },
  validations: {
    form: {
      Nazwa_wybor_konrahenta: {
        required,
      },
      Data_zakupu: {
        required,
      },
      Nr_WZ: {
        minLenght: minLength(1),
        maxLength: maxLength(50),
      },
      Nr_faktury: {
        required: false,
        maxLength: maxLength(50),
      },
      Data_certyfikatu: {
        required,
      },
      Asortyment: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(500),
      },
      Nr_certyfikatu: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(50),
      },
      Osoba_odpowiedzialna: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(50),
      },
    },
  },
  computed: {
    Id_kontrahenta: function() {
      const { Nazwa_wybor_konrahenta } = this.form;
      const znalezionyElement = this.Kontrahenci.find(
        ({ nazwa }) => nazwa === Nazwa_wybor_konrahenta
      );

      if (znalezionyElement) {
        return znalezionyElement.Id;
      }
      return null;
    },
    zapytaniaWartosc: function() {
      return this.Kontrahenci.map((el) => el.nazwa);
    },
    czyJestWTrybieEdycji: function() {
      return this.obiektKonfiguracyjny.akcja === "edycja";
    },
  },

  methods: {
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.Id_kontrahenta = null;
      this.form.Data_zakupu = null;
      this.form.Nr_WZ = null;
      this.form.Nr_faktury = null;
      this.form.Asortyment = null;
      this.form.Nr_certyfikatu = null;
      this.form.Data_certyfikatu = null;
      this.form.Osoba_odpowiedzialna = null;
    },

    zapiszFormularz() {
      this.sending = true;

      if (this.czyJestWTrybieEdycji) {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Id_kontrahenta", this.Id_kontrahenta);
        dane.append("Data_zakupu", this.form.Data_zakupu);
        dane.append("Nr_WZ", this.form.Nr_WZ);
        dane.append("Nr_faktury", this.form.Nr_faktury);
        dane.append("Asortyment", this.form.Asortyment);
        dane.append("Nr_certyfikatu", this.form.Nr_certyfikatu);
        dane.append("Data_certyfikatu", this.form.Data_certyfikatu);
        dane.append("Osoba_odpowiedzialna", this.form.Osoba_odpowiedzialna);

        api
          .zapiszZakupyUpdateZrealizowane(dane, this.IdEdytowanegoElementu)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const dane = new FormData();
        dane.append("Id_kontrahenta", this.Id_kontrahenta);
        dane.append("Data_zakupu", this.form.Data_zakupu);
        dane.append("Nr_WZ", this.form.Nr_WZ);
        dane.append("Nr_faktury", this.form.Nr_faktury);
        dane.append("Asortyment", this.form.Asortyment);
        dane.append("Nr_certyfikatu", this.form.Nr_certyfikatu);
        dane.append("Data_certyfikatu", this.form.Data_certyfikatu);
        dane.append("Osoba_odpowiedzialna", this.form.Osoba_odpowiedzialna);

        api
          .zapiszZakupyZrealizowane(dane)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    naszWalidacjaFormularza() {
      if (this.czyJestWTrybieEdycji) {
        return (
          !this.$v.form.Nazwa_wybor_konrahenta.$invalid &&
          !this.$v.form.Data_zakupu.$invalid &&
          !this.$v.form.Nr_WZ.$invalid &&
          !this.$v.form.Nr_faktury.$invalid &&
          !this.$v.form.Asortyment.$invalid &&
          !this.$v.form.Nr_certyfikatu.$invalid &&
          !this.$v.form.Data_certyfikatu.$invalid &&
          !this.$v.form.Osoba_odpowiedzialna.$invalid
        );
      } else {
        return !this.$v.$invalid;
      }
    },
    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>

<style>
.FORM_RSP_zak .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.FORM_RSP_zak .md-layout .md-gutter {
  min-width: 55vw !important; /* szerokość modala*/
  max-width: 100% !important;
  min-height: 7vh; /* wysokość pomiędzy mierszami w modalu*/
}
.md-layout {
  padding-right: 0px !important;
}
.FORM_RSP_zak .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}

.FORM_RSP_zak .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  margin: 10px 24px !important;
  padding-top: 35px !important;
}
.FORM_RSP_zak .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}

.FORM_RSP_zak {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
}
.FORM_RSP_zak .md-primary {
  border: 1px solid !important;

  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}
.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important; /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}

.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}
.FORM_RSP_zak .md-list-item-text {
  font-size: 1.7vmin !important;
}
.FORM_RSP_zak .md-card-actions .md-button {
  margin-right: 50px !important;
}
</style>
