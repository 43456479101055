<template>
  <div>
  <tabelaWykazWypadkiWPracyOcenaSkutecznosci/>

  </div>
</template>

<script>
import tabelaWykazWypadkiWPracyOcenaSkutecznosci from '../../komponenty/strona-tabela-komponenty/BHP_wypadki_w_pracy/tabela_dzialania_wypadki_ocena_skutecznosci.vue'


export default {
  name: 'StronaTabelaWykazWypadkiWPracyOcenaSkutecznosci',
  components: {
       tabelaWykazWypadkiWPracyOcenaSkutecznosci,

  }

}
</script>
