<template>
  <div class="Form_ZOOIU">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI CZĘSCI ZAKRESU - KATALOG"
                : "FORMULARZ DODAWANIA CZĘSCI ZAKRESU - KATALOG"
            }}
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-autocomplete
                :class="getValidationClass('Nazwa_wybor_czesci_zakresu')"
                v-model="form.Nazwa_wybor_czesci_zakresu"
                :md-options="zapytaniaCzesciZakresu"
                :disabled="sending || czyJestWTrybieEdycji"
              >
                <label>Wybór części zakresu do dodania wpisu</label>
                <span
                  class="md-error"
                  v-if="!$v.form.Nazwa_wybor_czesci_zakresu.required"
                  >Pole jest wymagane</span
                >
              </md-autocomplete>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('Opis')">
                <label for="Opis"
                  >Dodaj wpis do katalogu</label
                >
                <md-textarea
                  md-autogrow
                  md-counter="1000"
                  name="Opis"
                  id="Opis"
                  autocomplete="Opis"
                  v-model="form.Opis"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Opis.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>
        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

        <div style="text-align: center">
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            czyJestWTrybieEdycji
              ? "ZATWIERDŹ - EDYTOWANY WPIS"
              : "ZATWIERDŹ - UTWÓRZ WPIS"
          }}</md-button>
        </div>
      </md-card>
    </form>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { api } from "../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_maszyny",

  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      Id: null,
      Nazwa_wybor_czesci_zakresu: null,
      Opis: null,
    },
    IdEdytowanegoElementu: null,
    userSaved: false,
    sending: false,
    lastUser: null,
    ZakresCzesciZakresu: [],
  }),

  async created() {
    try {
      const [odpowiedz_RodzajCzesciZakresu] = await Promise.all([
        api.pobierzCzescZakresu(),
      ]);
      this.ZakresCzesciZakresu = odpowiedz_RodzajCzesciZakresu.data.dane.map(
        (el) => ({
          ...el,
          nazwa: `${el.Czesc_zakresu}`,
        })
      );

      if (this.obiektKonfiguracyjny.akcja === "edycja") {
        //edycja
        const odpowiedz = await api.pobieranieZOOIUKatalogEdycja(
          this.obiektKonfiguracyjny.Id
        );
        const { Id, Id_rodzaj_czesci_zakresu, Opis } = odpowiedz.data.dane[0];
        this.IdEdytowanegoElementu = Id;
        this.form.Nazwa_wybor_czesci_zakresu = this.ZakresCzesciZakresu.find(
          ({ Id }) => Id == Id_rodzaj_czesci_zakresu
        ).nazwa;
        this.form.Opis = Opis;
      }
    } catch (error) {
      console.log(error);
    }
  },

  validations: {
    form: {
      Nazwa_wybor_czesci_zakresu: {
        required,
      },
      Opis: {
        required,
      },
    },
  },

  computed: {
    Id_rodzaj_czesci_zakresu: function() {
      const { Nazwa_wybor_czesci_zakresu } = this.form;
      const znalezionyElement = this.ZakresCzesciZakresu.find(
        ({ nazwa }) => nazwa === Nazwa_wybor_czesci_zakresu
      );

      if (znalezionyElement) {
        return znalezionyElement.Id;
      }
      return null;
    },
    zapytaniaCzesciZakresu: function() {
      return this.ZakresCzesciZakresu.map((el) => el.nazwa);
    },

    czyJestWTrybieEdycji: function() {
      return this.obiektKonfiguracyjny.akcja === "edycja";
    },
  },

  methods: {
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.Id = null;
      this.Id_rodzaj_czesci_zakresu = null;
      this.form.Opis = null;
    },

    zapiszFormularz() {
      this.sending = true;

      if (this.czyJestWTrybieEdycji) {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Id_rodzaj_czesci_zakresu", this.Id_rodzaj_czesci_zakresu);
        dane.append("Opis", this.form.Opis);


        api
          .zapiszZOOIUKatalogUpdate(dane, this.IdEdytowanegoElementu)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Id_rodzaj_czesci_zakresu", this.Id_rodzaj_czesci_zakresu);
        dane.append("Opis", this.form.Opis);


        api
          .zapiszKatalogZOOIU(dane)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    naszWalidacjaFormularza() {
      if (this.czyJestWTrybieEdycji) {
        return (
          !this.$v.form.Nazwa_wybor_czesci_zakresu.$invalid &&
          !this.$v.form.Opis.$invalid
        );
      } else {
        return !this.$v.$invalid;
      }
    },

    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>

<style>
.Form_ZOOIU .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.Form_ZOOIU .md-layout .md-gutter {
  min-width: 55vw !important; /* szerokość modala*/
  max-width: 100% !important;
  min-height: 7vh; /* wysokość pomiędzy mierszami w modalu*/
}
.md-layout {
  padding-right: 0px !important;
}
.Form_ZOOIU .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}

.Form_ZOOIU .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  margin: 10px 24px !important;
  padding-top: 35px !important;
}
.Form_ZOOIU .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}

.Form_ZOOIU {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
  overflow: auto !important;
}
.Form_ZOOIU .md-primary {
  border: 1px solid !important;

  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}
.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important; /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}

.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}
.md-list-item-text {
  font-size: 1.7vmin !important;
}
.Form_ZOOIU .md-card-actions .md-button {
  margin-right: 4vw !important;
}
.Form_ZOOIU .md-field.md-has-textarea:not(.md-autogrow) label {
  top: 16px;
  left: 2px;
}
</style>
