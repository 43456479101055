<template>
  <TabelaPrzegladZagrozeniaBHP />
</template>

<script>
import TabelaPrzegladZagrozeniaBHP from '../../komponenty/strona-tabela-komponenty/SYS_zagrozenia_bhp/tabela_przeglad_zagrozenia_bhp.vue'



export default {
  name: 'StronaTabelaPrzegladZagrozeniaBHP',
  components: {
    TabelaPrzegladZagrozeniaBHP,


    

  }

}
</script>
