<template >
  <div>
  <TabelaAudityRaporty />
  </div>
</template>

<script>
import TabelaAudityRaporty from '../../komponenty/strona-tabela-komponenty/SYS_audity/tabela_audity_raporty.vue'


export default {
  name: 'StronaTabelaAudityRaporty',
  components: {
    TabelaAudityRaporty,
    
  }

}
</script>

