<template>
  <div>
  <TabelaBudynki/>
  </div>
</template>

<script>
import TabelaBudynki from '../../komponenty/strona-tabela-komponenty/WST_budynki/tabela_budynki.vue'


export default {
  name: 'StronaTabelaBudynki',
  components: {
    TabelaBudynki,
  }

}
</script>
