<template>
  <div>
  <TabelaWyposazeniePPOZ />
  </div>
</template>

<script>
import TabelaWyposazeniePPOZ from '../../komponenty/strona-tabela-komponenty/WST_wyposazeniePPOZ/tabela_wyposazeniePPOZ.vue'


export default {
  name: 'StronaTabelaWyposazeniePPOZ',
  components: {
    TabelaWyposazeniePPOZ,
    
  }

}
</script>
