<template>
  <div class="Form_RC">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI OKRESÓW CELÓW"
                : "FORMULARZ DODAWANIA OKRESÓW CELÓW"
            }}
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M1" :class="getValidationClass('Rok')">
                <label for="Rok">Rok celów</label>
                <!--- edycja 7 linia-->
                <md-input
                  type="text"
                  name="Rok"
                  id="Rok"
                  
                  md-counter="4"
                  v-model="form.Rok"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Rok.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Rok.maxlength"
                  >Wymagana ilość znaków 4</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Rok.minlength"
                  >Wymagana ilość znaków 4</span
                >

              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field class="M2" :class="getValidationClass('Data_ustanowienia')">
                <label for="Data_ustanowienia">Data ustanow.celów</label>
                <md-input
                  type="date"
                  name="Data_ustanowienia"
                  id="Data_ustanowienia"
                  
                  v-model="form.Data_ustanowienia"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Data_ustanowienia.required"
                  >Pole jest wymagane</span
                >

              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M4"
                :class="getValidationClass('Data_zakonczenia')"
              >
                <label for="Data_zakonczenia">Data zakoń.realiz.celów</label>
                <md-input
                  type="date"
                  name="Data_zakonczenia"
                  id="Data_zakonczenia"
                  
                  v-model="form.Data_zakonczenia"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Data_zakonczenia.required"
                  >Pole jest wymagane</span
                >

              </md-field>
            </div>
          </div>


          <div class="md-layout md-gutter"> 
            <div class="md-layout-item md-small-size-100">
              <md-field class="M4" :class="getValidationClass('Osoba_odpowiedzialna')">
                <label for="Osoba_odpowiedzialna">Osoba odpowiedzialna</label>
                <md-input
                  type="text"
                  name="Osoba_odpowiedzialna"
                  id="Osoba_odpowiedzialna"
                  
                  md-counter="50"
                  v-model="form.Osoba_odpowiedzialna"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Osoba_odpowiedzialna.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Osoba_odpowiedzialna.maxlength"
                  >Wymagana ilość znaków od 1 do 50</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field class="M4" :class="getValidationClass('Id_status_celow')">
                <label for="Id_status_celow">Status celów</label>

                <md-select
                  v-model="form.Id_status_celow"
                  :disabled="sending"
                  name="Id_status_celow"
                  id="Id_status_celow"
                  autocomplete="Id_status_celow"
                  md-dense
                >
                  <md-option
                    v-for="(status, index) in Statusy"
                    :key="index"
                    :value="status.Id"
                    >{{  
                      ` ${status.Status_celow} ` 
                      }}</md-option
                  >
                </md-select>
                <span class="md-error" v-if="!$v.form.Id_status_celow.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>


          <div v-if="!czyJestWTrybieEdycji" class="md-layout md-gutter"> 
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M5"
                :class="getValidationClass('Data_przegladu')"
              >
                <label for="Data_przegladu">Data zatwier./przegladu</label>
                <md-input
                  type="date"
                  name="Data_przegladu"
                  id="Data_przegladu"
                  autocomplete="Data_przegladu"
                  v-model="form.Data_przegladu"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Data_przegladu.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div
              v-if="!czyJestWTrybieEdycji"
              class="md-layout-item md-small-size-100"
            >
              <md-field
                class="M5"
                :class="getValidationClass('Data_nastepnego_przegladu')"
              >
                <label for="Data_nastepnej_weryfikacji"
                  >Data następ.przegladu</label
                >
                <md-input
                  type="date"
                  id="Data_nastepnego_przegladu"
                  name="Data_nastepnego_przegladu"
                  autocomplete="Data_nastepnego_przegladu"
                  v-model="form.Data_nastepnego_przegladu"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Data_nastepnego_przegladu.required"
                  >Pole jest wymagane</span
                >
              </md-field>
           </div>

           <div v-if="!czyJestWTrybieEdycji" class="md-layout-item md-small-size-100">
              <md-field
                class="M5"
                :class="getValidationClass('Przeglad_wykonal')"
              >
                <label for="Przeglad_wykonal">Zatwier./Przegląd wykonal</label>
                <md-input
                  name="Przeglad_wykonal"
                  id="Przeglad_wykonal"
                  
                  md-counter="50"
                  v-model="form.Przeglad_wykonal"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Przeglad_wykonal.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Przeglad_wykonal.maxlength"
                  >Wymagana ilość znaków od 1 do 50</span
                >
              </md-field>
            </div>
          </div>

          <div v-if="!czyJestWTrybieEdycji" class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M5"
                :class="getValidationClass('Uwagi')"
              >
                <label for="Uwagi">Uwagi</label>
                <md-textarea
                  name="Uwagi"
                  id="Uwagi"
                  autocomplete="Uwagi"
                  md-autogrow
                  md-counter="500"
                  v-model="form.Uwagi"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Uwagi.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Uwagi.maxlength"
                  >Wymagana ilość znaków od 1 do 500</span
                >
              </md-field>
            </div>
          </div> 
        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

        <div style="text-align: center">
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            czyJestWTrybieEdycji
              ? "ZATWIERDŹ - EDYTOWANY WPIS"
              : "ZATWIERDŹ - UTWÓRZ WPIS"
          }}</md-button>
        </div>
      </md-card>


    </form>
  </div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import minLength from "vuelidate/lib/validators/minLength";
import { api } from "./../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_maszyny",
  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      Rok: null,
      Data_ustanowienia: null,
      Data_zakonczenia: null,
      Osoba_odpowiedzialna: null,
      Id_status_celow: null,
      Data_przegladu: null,
      Data_nastepnego_przegladu: null,
      Przeglad_wykonal: null,
      Uwagi: null,
    },
    IdEdytowanegoElementu: null,
    userSaved: false,
    sending: false,
    lastUser: null,
    Statusy: [],
  }),
  created: function () {
    if (this.obiektKonfiguracyjny.akcja === "edycja") {
      //edycja
      api
        .pobieranieRokCeleEdycja(this.obiektKonfiguracyjny.Id)
        .then((odpowiedz) => {
          const {
            Id,
            Rok,
            Data_ustanowienia,
            Data_zakonczenia,
            Osoba_odpowiedzialna,
            Id_status_celow,
            Data_przegladu,
            Data_nastepnego_przegladu,
            Przeglad_wykonal,
            Uwagi,
          } = odpowiedz.data.dane[0];
          this.IdEdytowanegoElementu = Id;
          this.form.Rok = Rok;
          this.form.Data_ustanowienia = Data_ustanowienia;
          this.form.Data_zakonczenia = Data_zakonczenia;
          this.form.Osoba_odpowiedzialna = Osoba_odpowiedzialna;
          this.form.Id_status_celow = Id_status_celow;
          this.form.Data_przegladu = Data_przegladu;
          this.form.Data_nastepnego_przegladu = Data_nastepnego_przegladu;
          this.form.Przeglad_wykonal = Przeglad_wykonal;
          this.form.Uwagi = Uwagi;
        })
        .catch((error) => {
          console.log(error);
        });
    }

      Promise.all([
      api.pobierzStatusCelow()])
      .then(([odpowiedz_status]) => {
        this.Statusy = odpowiedz_status.data.dane;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  validations: {
    form: {
      Rok: {
        required,
        minLenght: minLength(4),
        maxLength: maxLength(4),
      },
      Data_ustanowienia: {
        required,

      },
      Data_zakonczenia: {
        required,

      },      
      Osoba_odpowiedzialna: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(50),
      },
      Id_status_celow: {
        required,
      },

      Data_przegladu: {
        required,
      },
      Data_nastepnego_przegladu: {
        required,
      },
      Przeglad_wykonal: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(50),
      },
      Uwagi: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(500),
      },
    },
  },
  computed: {

    czyJestWTrybieEdycji: function () {
      return this.obiektKonfiguracyjny.akcja === "edycja";
    },
  },

  methods: {


    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.Rok = null;
      this.form.Data_ustanowienia = null;
      this.form.Data_zakonczenia = null;
      this.form.Osoba_odpowiedzialna = null;
      this.form.Id_status_celow = null;
      this.form.Data_przegladu = null;
      this.form.Data_nastepnego_przegladu = null;
      this.form.Przeglad_wykonal = null;
      this.form.Uwagi = null;
    },

    zapiszFormularz() {
      this.sending = true;

      if (this.czyJestWTrybieEdycji) {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Rok", this.form.Rok);
        dane.append("Data_ustanowienia", this.form.Data_ustanowienia);
        dane.append("Data_zakonczenia", this.form.Data_zakonczenia);
        dane.append("Osoba_odpowiedzialna", this.form.Osoba_odpowiedzialna);
        dane.append("Id_status_celow", this.form.Id_status_celow);
        // dane.append("Data_przegladu", this.form.Data_przegladu);
        // dane.append("Data_nastepnego_przegladu", this.form.Data_nastepnego_przegladu);
        // dane.append("Przeglad_wykonal", this.form.Przeglad_wykonal);
        // dane.append("Uwagi", this.form.Uwagi);
        api
          .zapiszRokCeleUpdate(dane, this.IdEdytowanegoElementu)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Rok", this.form.Rok);
        dane.append("Data_ustanowienia", this.form.Data_ustanowienia);
        dane.append("Data_zakonczenia", this.form.Data_zakonczenia);
        dane.append("Osoba_odpowiedzialna", this.form.Osoba_odpowiedzialna);
        dane.append("Id_status_celow", this.form.Id_status_celow);
        dane.append("Data_przegladu", this.form.Data_przegladu);
        dane.append("Data_nastepnego_przegladu", this.form.Data_nastepnego_przegladu);
        dane.append("Przeglad_wykonal", this.form.Przeglad_wykonal);
        dane.append("Uwagi", this.form.Uwagi);

        api
          .zapiszRokCele(dane)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    naszWalidacjaFormularza() {
      if (this.czyJestWTrybieEdycji) {
        return (
          !this.$v.form.Rok.$invalid &&
          !this.$v.form.Data_ustanowienia.$invalid &&
          !this.$v.form.Data_zakonczenia.$invalid &&
          !this.$v.form.Id_status_celow.$invalid &&
          !this.$v.form.Osoba_odpowiedzialna.$invalid
        );
      } else {
        return !this.$v.$invalid;
      }
    },
    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>

<style>
.Form_RC .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.Form_RC .md-layout .md-gutter {
   min-width: 55vw !important; /* szerokość modala*/
  max-width: 100% !important;
  min-height: 7vh; /* wysokość pomiędzy mierszami w modalu*/
  
}
.md-layout {
  padding-right: 0px !important;

}
.Form_RC .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}
.Form_RC .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  margin: 10px 24px !important;
  padding-top: 35px !important;
}
.Form_RC .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}

.Form_RC {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
  overflow: auto!important;
}
.Form_RC .md-primary {
  border: 1px solid !important;

  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}
.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important; /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}

.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}
.md-list-item-text {
  font-size: 1.7vmin !important;
}
.Form_RC .md-card-actions .md-button {
  margin-right: 50px !important;
}
.Form_RC .md-field.md-has-textarea:not(.md-autogrow) label {
    top: 16px;
    left: 0px;
}
</style>