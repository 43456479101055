<template>
  <div>
  <TabelaSamochodyCiezaroweChlodnie />
  </div>
</template>

<script>
import TabelaSamochodyCiezaroweChlodnie from '../../komponenty/strona-tabela-komponenty/WST_samochody_ciezarowe/tabela_samochody_ciezarowe_chlodnie.vue'


export default {
  name: 'StronaTabelaSamochodyCiezaroweChlodnie',
  components: {
    TabelaSamochodyCiezaroweChlodnie,
  }

}
</script>
