<template>
  <div>
  <TabelaMaszynyDozorowe />
  </div>
</template>

<script>
import TabelaMaszynyDozorowe from '../../komponenty/strona-tabela-komponenty/WST_maszyny_dozorowe/tabela_maszyny_dozorowe.vue'


export default {
  name: 'StronaTabelaMaszynyDozorowe',
  components: {
    TabelaMaszynyDozorowe,
  }

}
</script>
