<template>
  <div class="KONTEKST_zakres">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI PROCESÓW ZLECANYCH NA ZEWNĄTRZ"
                : "FORMULARZ DODAWANIA PROCESÓW ZLECANYCH NA ZEWNĄTRZ"
            }}
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="MP2"
                :class="getValidationClass('Procesy_zewnetrzne')"
              >
                <label for="Procesy_zewnetrzne"
                  >Procesy zlecane na zewnątrz</label
                >
                <md-textarea
                  id="Procesy_zewnetrzne"
                  name="Procesy_zewnetrzne"
                  autocomplete="Procesy_zewnetrzne"
                  md-autogrow
                  md-counter="2000"
                  v-model="form.Procesy_zewnetrzne"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Procesy_zewnetrzne.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Procesy_zewnetrzne.maxlength"
                  >Wymagana ilość znaków od 1 do 500</span
                >
              </md-field>
            </div>
          </div>
        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

        <div style="text-align: center">
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            czyJestWTrybieEdycji
              ? "ZATWIERDŹ - EDYTOWANY WPIS"
              : "ZATWIERDŹ - UTWÓRZ WPIS"
          }}</md-button>
        </div>
      </md-card>
    </form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import minLength from "vuelidate/lib/validators/minLength";
import { api } from "./../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_zakresu",
  mixins: [validationMixin],
  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      Procesy_zewnetrzne: null,
    },
    IdEdytowanegoElementu: null,
    userSaved: false,
    sending: false,
    lastUser: null,
  }),

  created: function () {
    if (this.obiektKonfiguracyjny.akcja === "edycja") {
      //edycja
      api
        .pobieranieProcesyZewnetrzneEdycja(this.obiektKonfiguracyjny.Id)
        .then((odpowiedz) => {
          const { Id, Procesy_zewnetrzne } = odpowiedz.data.dane[0];
          this.IdEdytowanegoElementu = Id;
          this.form.Procesy_zewnetrzne = Procesy_zewnetrzne;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  validations: {
    form: {
      Procesy_zewnetrzne: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(500),
      },
    },
  },
  computed: {
    czyJestWTrybieEdycji: function () {
      return this.obiektKonfiguracyjny.akcja === "edycja";
    },
  },

  methods: {
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.Procesy_zewnetrzne = null;
    },

    zapiszFormularz() {
      this.sending = true;

      if (this.czyJestWTrybieEdycji) {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Procesy_zewnetrzne", this.form.Procesy_zewnetrzne);

        api
          .zapiszProcesyZewnetrzneUpdate(dane, this.IdEdytowanegoElementu)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const dane = new FormData();
        dane.append("Procesy_zewnetrzne", this.form.Procesy_zewnetrzne);

        api
          .zapiszProcesyZewnetrzne(dane)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    naszWalidacjaFormularza() {
      if (this.czyJestWTrybieEdycji) {
        return !this.$v.form.Procesy_zewnetrzne.$invalid;
      } else {
        return !this.$v.$invalid;
      }
    },
    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>

<style>
.KONTEKST_zakres .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.KONTEKST_zakres .md-layout .md-gutter {
  min-width: 55vw!important; /* szerokość modala*/
  max-width: 100%!important;
  min-height: 7vh!important; /* wysokość pomiędzy mierszami w modalu*/
}
.md-layout {
  padding-right: 0px !important;
}
.KONTEKST_zakres .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}

.KONTEKST_zakres .MP1 {
  max-width: 100% !important;
  min-width: 100% !important;
}
.KONTEKST_zakres .MP2 {
  max-width: 100% !important;
  min-width: 100% !important;
}
.KONTEKST_zakres .MP3 {
  min-width: 97%;
  max-width: 97%;
  min-height: 5vh;
  font-size: 2vh !important;
}

.KONTEKST_zakres .MP4 {
  max-width: 100% !important;
  min-width: 100% !important;
}
.KONTEKST_zakres .MP5 {
  max-width: 100% !important;
  min-width: 100% !important;
}
.KONTEKST_zakres .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  margin: 10px 24px !important;
  padding-top: 35px !important;
}
.KONTEKST_zakres .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}


.KONTEKST_zakres {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
}
.KONTEKST_zakres .md-primary {
  border: 1px solid !important;

  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}
.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important; /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}

.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
  min-width: 400px !important;
  max-height: 300px !important;
  overflow: auto !important;
}
.KONTEKST_zakres .md-list-item-text {
  font-size: 1.7vmin !important;
}
.KONTEKST_zakres .md-card-actions .md-button {
  margin-right: 50px !important;
}
</style>