<template>
  <div>
  <TabelaMaszynyGeneratorPrzeglad />
  </div>
</template>

<script>
import TabelaMaszynyGeneratorPrzeglad from '../../komponenty/strona-tabela-komponenty/WST_maszyny/protokol_przeglad.vue'


export default {
  name: 'StronaTabelaMaszynyGeneratorPrzeglad',
  components: {
    TabelaMaszynyGeneratorPrzeglad,
  }

}
</script>
