<template>
  <div>
  <tabelaWykazMedycyna/>

  </div>
</template>

<script>
import tabelaWykazMedycyna from '../../komponenty/strona-tabela-komponenty/HR_medycyna_pracy/tabela_medycyna_pracy.vue'


export default {
  name: 'StronaTabelaWykazMedycyna',
  components: {
       tabelaWykazMedycyna,

  }

}
</script>
