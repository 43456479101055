<template>
  <div>
  <tabelaZakresObowiazkow1/>

  </div>
</template>

<script>
import tabelaZakresObowiazkow1 from '../../komponenty/strona-tabela-komponenty/HR_zakresy/zakres1.vue'


export default {
  name: 'StronaTabelaZakresObowiazkow1',
  components: {
       tabelaZakresObowiazkow1,

  }

}
</script>
