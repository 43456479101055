<template>
  <div class="FORM_aspekty">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI ASPEKTU ŚRODOWISKOWEGO"
                : "FORMULARZ DODAWANIA ASPEKTU ŚRODOWISKOWEGO"
            }}
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('Id_procesu')">
                <label for="Id_procesu">Wybór procesu</label>

                <md-select
                  v-model="form.Id_procesu"
                  :disabled="sending"
                  name="Id_procesu"
                  id="Id_procesu"
                  autocomplete="Id_procesu"
                  md-dense
                >
                  <md-option
                    v-for="(proces, index) in Procesy"
                    :key="index"
                    :value="proces.Id"
                    >{{
                      ` ${proces.Nr_procesu} - ${proces.Nazwa_procesu} `
                    }}</md-option
                  >
                </md-select>
                <span class="md-error" v-if="!$v.form.Id_procesu.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('Aspekt')">
                <label for="Aspekt">Aspekt środowiskowy</label>
                <md-textarea
                  name="Aspekt"
                  id="Aspekt"
                  autocomplete="Aspekt"
                  md-autogrow
                  md-counter="1000"
                  v-model="form.Aspekt"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Aspekt.required"
                  >Pole jest wymagane</span
                >
                <span class="md-error" v-else-if="!$v.form.Aspekt.maxlength"
                  >Wymagana ilość znaków od 1 do 1000</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('Miejsce')">
                <label for="Miejsce">Miejsce występowania</label>
                <md-textarea
                  name="Miejsce"
                  id="Miejsce"
                  autocomplete="Miejsce"
                  md-autogrow
                  md-counter="1000"
                  v-model="form.Miejsce"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Miejsce.required"
                  >Pole jest wymagane</span
                >
                <span class="md-error" v-else-if="!$v.form.Miejsce.maxlength"
                  >Wymagana ilość znaków od 1 do 1000</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="A3" :class="getValidationClass('W1')">
                <label for="W1">W1</label>

                <md-select
                  v-model="form.W1"
                  :disabled="sending"
                  name="W1"
                  id="W1"
                  autocomplete="W1"
                  md-dense
                >
                <div
                    readolny
                    style="text-align: center; font-weight:bold; color:blue"
                  >
                    W1 - Sytuacja formalno-prawna
                  </div>
                  <md-option :value="1">1</md-option>
                  <md-option :value="2">2</md-option>
                  <md-option :value="3">3</md-option>
                  <md-option :value="4">4</md-option>
                  <md-option :value="5">5</md-option>
                  <md-option :value="6">6</md-option>
                  <md-option :value="7">7</md-option>
                  <md-option :value="8">8</md-option>
                  <md-option :value="9">9</md-option>
                  <md-option :value="10">10</md-option>
                </md-select>
                <span class="md-error" v-if="!$v.form.W1.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field class="A3" :class="getValidationClass('W2')">
                <label for="W2">W2</label>

                <md-select
                  v-model="form.W2"
                  :disabled="sending"
                  name="W2"
                  id="W2"
                  autocomplete="W2"
                  md-dense
                >
                <div
                    readolny
                    style="text-align: center; font-weight:bold; color:blue"
                  >
                    W2 - Szkodliwość oddziaływania
                  </div>
                  <md-option :value="1">1</md-option>
                  <md-option :value="2">2</md-option>
                  <md-option :value="3">3</md-option>
                  <md-option :value="4">4</md-option>
                  <md-option :value="5">5</md-option>
                  <md-option :value="6">6</md-option>
                  <md-option :value="7">7</md-option>
                  <md-option :value="8">8</md-option>
                  <md-option :value="9">9</md-option>
                  <md-option :value="10">10</md-option>
                </md-select>
                <span class="md-error" v-if="!$v.form.W2.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100">
              <md-field class="A3" :class="getValidationClass('W3')">
                <label for="W3">W3</label>

                <md-select
                  v-model="form.W3"
                  :disabled="sending"
                  name="W3"
                  id="W3"
                  autocomplete="W3"
                  md-dense
                >
                <div
                    readolny
                    style="text-align: center; font-weight:bold; color:blue"
                  >
                    W3 - Częstotliwość oddziaływania
                  </div>
                  <md-option :value="1">1</md-option>
                  <md-option :value="2">2</md-option>
                  <md-option :value="3">3</md-option>
                  <md-option :value="4">4</md-option>
                  <md-option :value="5">5</md-option>
                  <md-option :value="6">6</md-option>
                  <md-option :value="7">7</md-option>
                  <md-option :value="8">8</md-option>
                  <md-option :value="9">9</md-option>
                  <md-option :value="10">10</md-option>
                </md-select>
                <span class="md-error" v-if="!$v.form.W3.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100">
              <md-field class="A3" :class="getValidationClass('W4')">
                <label for="W4">W4</label>

                <md-select
                  v-model="form.W4"
                  :disabled="sending"
                  name="W4"
                  id="W4"
                  autocomplete="W4"
                  md-dense
                >
                <div
                    readolny
                    style="text-align: center; font-weight:bold; color:blue"
                  >
                    W4 - Częstotliwość występowania zdarzenia
                  </div>
                  <md-option :value="1">1</md-option>
                  <md-option :value="2">2</md-option>
                  <md-option :value="3">3</md-option>
                  <md-option :value="4">4</md-option>
                  <md-option :value="5">5</md-option>
                  <md-option :value="6">6</md-option>
                  <md-option :value="7">7</md-option>
                  <md-option :value="8">8</md-option>
                  <md-option :value="9">9</md-option>
                  <md-option :value="10">10</md-option>
                </md-select>
                <span class="md-error" v-if="!$v.form.W4.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field class="A3" :class="getValidationClass('W5')">
                <label for="W5">W5</label>

                <md-select
                  v-model="form.W5"
                  :disabled="sending"
                  name="W5"
                  id="W5"
                  autocomplete="W5"
                  md-dense
                >
                <div
                    readolny
                    style="text-align: center; font-weight:bold; color:blue"
                  >
                    W5 - Skutki środowiskowe
                  </div>
                  <md-option :value="1">1</md-option>
                  <md-option :value="2">2</md-option>
                  <md-option :value="3">3</md-option>
                  <md-option :value="4">4</md-option>
                  <md-option :value="5">5</md-option>
                  <md-option :value="6">6</md-option>
                  <md-option :value="7">7</md-option>
                  <md-option :value="8">8</md-option>
                  <md-option :value="9">9</md-option>
                  <md-option :value="10">10</md-option>
                </md-select>
                <span class="md-error" v-if="!$v.form.W5.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field class="A3" :class="getValidationClass('W6')">
                <label for="W6">W6</label>

                <md-select
                  v-model="form.W6"
                  :disabled="sending"
                  name="W6"
                  id="W6"
                  autocomplete="W6"
                  md-dense
                >
                <div
                    readolny
                    style="text-align: center; font-weight:bold; color:blue"
                  >
                    W6 - Koszty środowiskowe
                  </div>
                  <md-option :value="1">1</md-option>
                  <md-option :value="2">2</md-option>
                  <md-option :value="3">3</md-option>
                  <md-option :value="4">4</md-option>
                  <md-option :value="5">5</md-option>
                  <md-option :value="6">6</md-option>
                  <md-option :value="7">7</md-option>
                  <md-option :value="8">8</md-option>
                  <md-option :value="9">9</md-option>
                  <md-option :value="10">10</md-option>
                </md-select>
                <span class="md-error" v-if="!$v.form.W6.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field for="Wynik" :class="getValidationClass('Wynik')">
                {{ wynik }}
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field for="Wynik1" :class="getValidationClass('Wynik1')">
                {{ znaczenieAspektu }}
              </md-field>
            </div>
          </div>
        </md-card-content>
        <div style="text-align: center; font-weight: bold; color: red">
          OZNACZENIE WSKAŹNIKÓW<br />
          W1-Sytuacja formalno prawna; W2-Szkodliwość oddziaływania;
          W3-Częstotliwość oddziaływania; <br />
          W4-Częstotliwość występowania zdarzenia; W5-Skutki środowiskowe;
          W6-Koszty środowiskowe<br />
          PUNKTACJA: od 1 do 10 - aspekt środowiskowy uznaje się za znaczący od
          6 punktów <br />
          WYLICZENIE WG WZORU (W1+W2+w3+W4+W5+W6)/6
        </div>
        <md-progress-bar md-mode="indeterminate" v-if="sending" />

        <div style="text-align: center">
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            czyJestWTrybieEdycji
              ? "ZATWIERDŹ - EDYTOWANY WPIS"
              : "ZATWIERDŹ - UTWÓRZ WPIS"
          }}</md-button>
        </div>
      </md-card>
    </form>
  </div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import minLength from "vuelidate/lib/validators/minLength";
import { api } from "./../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_maszyny",

  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      Id_procesu: null,
      Aspekt: null,
      Miejsce: null,
      W1: null,
      W2: null,
      W3: null,
      W4: null,
      W5: null,
      W6: null,
    },
    IdEdytowanegoElementu: null,
    userSaved: false,
    sending: false,
    lastUser: null,
    Procesy: [],
  }),
  created: function () {
    if (this.obiektKonfiguracyjny.akcja === "edycja") {
      //edycja
      api
        .pobieranieAspektyEdycja(this.obiektKonfiguracyjny.Id)
        .then((odpowiedz) => {
          const {
            Id,
            Id_procesu,
            Aspekt,
            Miejsce,
            W1,
            W2,
            W3,
            W4,
            W5,
            W6,
            // Wynik,
          } = odpowiedz.data.dane[0];
          this.IdEdytowanegoElementu = Id;
          this.form.Id = Id_procesu;
          this.form.Id_procesu = Id_procesu;
          this.form.Aspekt = Aspekt;
          this.form.Miejsce = Miejsce;
          this.form.W1 = parseInt(W1);
          this.form.W2 = parseInt(W2);
          this.form.W3 = parseInt(W3);
          this.form.W4 = parseInt(W4);
          this.form.W5 = parseInt(W5);
          this.form.W6 = parseInt(W6);
          // this.form.Wynik = Wynik;
        })
        .catch((error) => {
          console.log(error);
        });
    }

    api
      .pobierzProces()
      .then((odpowiedz_procesy) => {
        this.Procesy = odpowiedz_procesy.data.dane;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  validations: {
    form: {
      Id_procesu: {
        required,
      },
      Aspekt: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(1000),
      },
      Miejsce: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(1000),
      },
      W1: {
        required,
      },
      W2: {
        required,
      },
      W3: {
        required,
      },
      W4: {
        required,
      },
      W5: {
        required,
      },
      W6: {
        required,
      },
    },
  },
  computed: {
    czyJestWTrybieEdycji: function () {
      return this.obiektKonfiguracyjny.akcja === "edycja";
    },
    wynik() {
      const { W1, W2, W3, W4, W5, W6 } = this.form;
      const tablica = [W1, W2, W3, W4, W5, W6];
      if (this.czyIstniejeNiewprowadzonyElement(tablica)) {
        return null;
      } else {
        return parseFloat(
          ((W1 || 0) +
            (W2 || 0) +
            (W3 || 0) +
            (W4 || 0) +
            (W5 || 0) +
            (W6 || 0)) /
            6
        ).toFixed(2);
      }
    },
    znaczenieAspektu() {
      const { W1, W2, W3, W4, W5, W6 } = this.form;
      const tablica = [W1, W2, W3, W4, W5, W6];
      if (this.czyIstniejeNiewprowadzonyElement(tablica)) {
        return "";
      } else {
        return this.wynik >= 6 ? "Aspekt znaczacy" : "Aspekt nieznaczacy";
      }
    },
  },

  methods: {
    czyIstniejeNiewprowadzonyElement(tablica) {
      return tablica.find((el) => el === null) === null ? true : false;
    },
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },

    clearForm() {
      this.$v.$reset();
      this.form.Id_procesu = null;
      this.form.Aspekt = null;
      this.form.Miejsce = null;
      this.form.W1 = null;
      this.form.W2 = null;
      this.form.W3 = null;
      this.form.W4 = null;
      this.form.W5 = null;
      this.form.W6 = null;
      this.form.Wynik = null;
    },

    zapiszFormularz() {
      this.sending = true;

      if (this.czyJestWTrybieEdycji) {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Id_procesu", this.form.Id_procesu);
        dane.append("Aspekt", this.form.Aspekt);
        dane.append("Miejsce", this.form.Miejsce);
        dane.append("W1", this.form.W1);
        dane.append("W2", this.form.W2);
        dane.append("W3", this.form.W3);
        dane.append("W4", this.form.W4);
        dane.append("W5", this.form.W5);
        dane.append("W6", this.form.W6);
        //dane.append("Wynik", this.form.Wynik);

        api
          .zapiszAspektyUpdate(dane, this.IdEdytowanegoElementu)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Id_procesu", this.form.Id_procesu);
        dane.append("Aspekt", this.form.Aspekt);
        dane.append("Miejsce", this.form.Miejsce);
        dane.append("W1", this.form.W1);
        dane.append("W2", this.form.W2);
        dane.append("W3", this.form.W3);
        dane.append("W4", this.form.W4);
        dane.append("W5", this.form.W5);
        dane.append("W6", this.form.W6);
        //dane.append("Wynik", this.form.Wynik);

        api
          .zapiszAspekty(dane)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    naszWalidacjaFormularza() {
      if (this.czyJestWTrybieEdycji) {
        return (
          !this.$v.form.Id_procesu.$invalid &&
          !this.$v.form.Aspekt.$invalid &&
          !this.$v.form.Miejsce.$invalid &&
          !this.$v.form.W1.$invalid &&
          !this.$v.form.W2.$invalid &&
          !this.$v.form.W3.$invalid &&
          !this.$v.form.W4.$invalid &&
          !this.$v.form.W5.$invalid &&
          !this.$v.form.W6.$invalid
        );
      } else {
        return !this.$v.$invalid;
      }
    },

    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>


<style>
.FORM_aspekty .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
.FORM_aspekty {
  overflow: auto;
}
.FORM_aspekty .md-layout .md-gutter {
  min-width: 55vw !important; /* szerokość modala*/
  max-width: 100% !important;
  min-height: 7vh; /* wysokość pomiędzy mierszami w modalu*/
}
.md-layout {
  padding-right: 0px !important;
}
.FORM_aspekty .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}

.FORM_aspekty .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  margin: 10px 24px !important;
  padding-top: 35px !important;
}
.FORM_aspekty .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}

.FORM_aspekty {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
  overflow: auto !important;
}
.FORM_aspekty .md-primary {
  border: 1px solid !important;

  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}
.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important; /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}

.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}
.md-list-item-text {
  font-size: 1.7vmin !important;
}
.FORM_aspekty .md-card-actions .md-button {
  margin-right: 4vw !important;
}
.FORM_aspekty .md-field.md-has-textarea:not(.md-autogrow) label {
  top: 16px;
  left: 0px;
}

.FORM_aspekty .A3 {
  width: 5% !important;
  max-width: 60px !important;
  min-width: 100% !important;
  padding-right: 2px !important;
  padding-left: 2px !important;
}
.FORM_aspekty .A3 .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 90px !important;
  margin: 10px 10px 10px 10px !important;
  padding-top: 35px !important;
}
</style>