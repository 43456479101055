<template>
  <div class="Form_IT_generator">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI PRZEGLĄDU ELEKTRONARZĘDZIA"
                : "FORMULARZ DODAWANIA PRZEGLĄDU ELEKTRONARZĘDZIA"
            }}
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-autocomplete
                :class="getValidationClass('Nazwa_wybor_maszyny')"
                v-model="form.Nazwa_wybor_maszyny"
                :md-options="zapytaniaMaszyny"
                :disabled="sending || czyJestWTrybieEdycji"
              >
                <label>Wybór wyposażenia IT</label>
                <span
                  class="md-error"
                  v-if="!$v.form.Nazwa_wybor_maszyny.required"
                  >Pole jest wymagane</span
                >
              </md-autocomplete>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="MP2"
                :class="getValidationClass('Data_przegladu')"
              >
                <label for="Data_przegladu">Data przeglądu</label>
                <md-input
                  type="date"
                  name="Data_przegladu"
                  id="Data_przegladu"
                  autocomplete="Data_przegladu"
                  v-model="form.Data_przegladu"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Data_przegladu.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field
                class="MP2"
                :class="getValidationClass('Data_nastepnego_przegladu')"
              >
                <label for="Data_nastepnego_przegladu"
                  >Data następnego przeglądu</label
                >
                <md-input
                  type="date"
                  id="Data_nastepnego_przegladu"
                  name="Data_nastepnego_przegladu"
                  autocomplete="Data_nastepnego_przegladu"
                  v-model="form.Data_nastepnego_przegladu"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Data_nastepnego_przegladu.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="MP1"
                :class="getValidationClass('Przeglad_wykonal')"
              >
                <label for="Przeglad_wykonal">Przegląd wykonał</label>
                <md-input
                  name="Przeglad_wykonal"
                  id="Przeglad_wykonal"
                  autocomplete="Przeglad_wykonal"
                  md-counter="100"
                  v-model="form.Przeglad_wykonal"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Przeglad_wykonal.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Przeglad_wykonal.maxlength"
                  >Wymagana ilość znaków od 1 do 100</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('W1')">
                <label for="W1">Wybierz opcję dla - Oględziny zewnętrzne</label>

                <md-select
                  v-model="form.W1"
                  :disabled="sending"
                  name="W1"
                  id="W1"
                  autocomplete="W1"
                  md-dense
                >
                  <md-option
                    v-for="(dana1, index) in Dane1"
                    :key="index"
                    :value="dana1.Id"
                    >{{ dana1.Czynnosc }}</md-option
                  >
                </md-select>
                <span class="md-error" v-if="!$v.form.W1.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

            <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('W2')">
                <label for="W2">Wybierz opcję dla - Weryfikacja legalności oraz autoryzacji oprogramowania</label>

                <md-select
                  v-model="form.W2"
                  :disabled="sending"
                  name="W2"
                  id="W2"
                  autocomplete="W2"
                  md-dense
                >
                  <md-option
                    v-for="(dana1, index) in Dane1"
                    :key="index"
                    :value="dana1.Id"
                    >{{ dana1.Czynnosc }}</md-option
                  >
                </md-select>
                <span class="md-error" v-if="!$v.form.W2.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

            <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('W3')">
                <label for="W3">Wybierz opcję dla - Weryfikacja antywirusowa</label>

                <md-select
                  v-model="form.W3"
                  :disabled="sending"
                  name="W3"
                  id="W3"
                  autocomplete="W3"
                  md-dense
                >
                  <md-option
                    v-for="(dana1, index) in Dane1"
                    :key="index"
                    :value="dana1.Id"
                    >{{ dana1.Czynnosc }}</md-option
                  >
                </md-select>
                <span class="md-error" v-if="!$v.form.W3.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

            <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('W4')">
                <label for="W4">Wybierz opcję dla - Weryfikacja zabezpieczeń</label>

                <md-select
                  v-model="form.W4"
                  :disabled="sending"
                  name="W4"
                  id="W4"
                  autocomplete="W4"
                  md-dense
                >
                  <md-option
                    v-for="(dana1, index) in Dane1"
                    :key="index"
                    :value="dana1.Id"
                    >{{ dana1.Czynnosc }}</md-option
                  >
                </md-select>
                <span class="md-error" v-if="!$v.form.W4.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

            <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('W5')">
                <label for="W5">Wybierz opcję dla - Sprawdzenie przewodów zasilających</label>

                <md-select
                  v-model="form.W5"
                  :disabled="sending"
                  name="W5"
                  id="W5"
                  autocomplete="W5"
                  md-dense
                >
                  <md-option
                    v-for="(dana1, index) in Dane1"
                    :key="index"
                    :value="dana1.Id"
                    >{{ dana1.Czynnosc }}</md-option
                  >
                </md-select>
                <span class="md-error" v-if="!$v.form.W5.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

             <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M1"
                :class="getValidationClass('W6')"
              >
                <label for="W6">Inne wykonane czynności</label>
                <md-textarea
                  name="W6"
                  id="W6"
                  
                  md-autogrow
                  md-counter="1000"
                  v-model="form.W6"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.W6.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.W6.maxlength"
                  >Wymagana ilość znaków od 1 do 1000</span
                >
              </md-field>
            </div>
          </div>

                      <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('W7')">
                <label for="W5">Wybierz opcję podsumowującą przegląd</label>

                <md-select
                  v-model="form.W7"
                  :disabled="sending"
                  name="W7"
                  id="W7"
                  autocomplete="W7"
                  md-dense
                >
                  <md-option
                    v-for="(dana2, index) in Dane2"
                    :key="index"
                    :value="dana2.Id"
                    >{{ dana2.Czynnosc }}</md-option
                  >
                </md-select>
                <span class="md-error" v-if="!$v.form.W7.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>


        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

        <div style="text-align: center">
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            czyJestWTrybieEdycji
              ? "ZATWIERDŹ - EDYTOWANY WPIS"
              : "ZATWIERDŹ - UTWÓRZ WPIS"
          }}</md-button>
        </div>
      </md-card>
    </form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import minLength from "vuelidate/lib/validators/minLength";
import { api } from "../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_przegladu_maszyny",
  mixins: [validationMixin],
  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      Id: null,
      Nazwa_wybor_maszyny: null,
      Data_przegladu: null,
      Data_nastepnego_przegladu: null,
      Przeglad_wykonal: null,
      W1: null,
      W2: null,
      W3: null,
      W4: null,
      W5: null,
      W6: null,
      W7: null,
      Wybrane_maszyny: null,
    },
    IdEdytowanegoElementu: null,
    userSaved: false,
    sending: false,
    lastUser: null,
    Nazwy_maszyn: [],
    Dane1:[],
    Dane2:[],
  }),

  async created() {
    try {
      const [
        odpowiedz_Nazwa_maszyny,
        odpowiedz_czynnosc,
        odpowiedz_odbior
        ] = await Promise.all([
        api.pobierzNazwa_IT(),
        api.pobierzCzynnoscMaszyny(),
        api.pobierzCzynnoscOdbioruIT(),
      ]);
      
      odpowiedz_Nazwa_maszyny,
        (this.Nazwy_maszyn = odpowiedz_Nazwa_maszyny.data.dane.map((el) => ({
          ...el,
          nazwa: `Id maszyny: ${el.Id_maszyny} ; Wyposażenia IT: ${el.Nazwa_maszyny} `,
        })));
      this.Dane1 = odpowiedz_czynnosc.data.dane;
      this.Dane2 = odpowiedz_odbior.data.dane;

      if (this.obiektKonfiguracyjny.akcja === "edycja") {
        //edycja
        const odpowiedz = await api.pobieranieITEdycjaGenerator(
          this.obiektKonfiguracyjny.Id
        );

        const {
          Id,
          Id_maszyny,
          Data_przegladu,
          Data_nastepnego_przegladu,
          Przeglad_wykonal,
          W1,
          W2,
          W3,
          W4,
          W5,
          W6,
          W7,
        } = odpowiedz.data.dane[0];
        this.IdEdytowanegoElementu = Id;
        this.form.Nazwa_wybor_maszyny = this.Nazwy_maszyn.find(
          ({ Id }) => Id == Id_maszyny
        ).nazwa;
        this.form.Data_przegladu = Data_przegladu;
        this.form.Data_nastepnego_przegladu = Data_nastepnego_przegladu;
        this.form.Przeglad_wykonal = Przeglad_wykonal;
        this.form.W1 = W1;
        this.form.W2 = W2;
        this.form.W3 = W3;
        this.form.W4 = W4;
        this.form.W5 = W5;
        this.form.W6 = W6;
        this.form.W7 = W7;
      }
    } catch (error) {
      console.log(error);
    }
  },

  validations: {
    form: {
      Nazwa_wybor_maszyny: {
        required,
      },
      Data_przegladu: {
        required,
      },
      Data_nastepnego_przegladu: {
        required,
      },
      Przeglad_wykonal: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(100),
      },
      W1: {
        required,
      },
      W2: {
        required,
      },
      W3: {
        required,
      },
      W4: {
        required,
      },
      W5: {
        required,
      },
      W6: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(1000),
      },
      W7: {
        required,
      },
    },
  },
  computed: {
    Id_maszyny: function() {
      const { Nazwa_wybor_maszyny } = this.form;
      const znalezionyElement = this.Nazwy_maszyn.find(
        ({ nazwa }) => nazwa === Nazwa_wybor_maszyny
      );

      if (znalezionyElement) {
        return znalezionyElement.Id;
      }
      return null;
    },
    zapytaniaMaszyny: function() {
      return this.Nazwy_maszyn.map((el) => el.nazwa);
    },

    czyJestWTrybieEdycji: function() {
      return this.obiektKonfiguracyjny.akcja === "edycja";
    },
  },

  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toUpperCase()
          .indexOf(input.toUpperCase()) >= 0
      );
    },

    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.Id = null;
      this.form.Data_przegladu = null;
      this.form.Data_nastepnego_przegladu = null;
      this.form.Przeglad_wykonal = null;
      this.form.W1 = null;
      this.form.W2 = null;
      this.form.W3 = null;
      this.form.W4 = null;
      this.form.W5 = null;
      this.form.W6 = null;
      this.form.W7 = null;
    },

    zapiszFormularz() {
      this.sending = true;

      if (this.czyJestWTrybieEdycji) {
        const dane = new FormData(); // dane typu FormData;

        dane.append("Data_przegladu", this.form.Data_przegladu);
        dane.append("Data_nastepnego_przegladu", this.form.Data_nastepnego_przegladu);
        dane.append("Przeglad_wykonal", this.form.Przeglad_wykonal);
        dane.append("W1", this.form.W1);
        dane.append("W2", this.form.W2);
        dane.append("W3", this.form.W3);
        dane.append("W4", this.form.W4);
        dane.append("W5", this.form.W5);
        dane.append("W6", this.form.W6);
        dane.append("W7", this.form.W7);

        api
          .zapiszITUpdateGenerator(dane, this.IdEdytowanegoElementu)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const dane = new FormData();
        dane.append("Id_maszyny", this.Id_maszyny);
        dane.append("Data_przegladu", this.form.Data_przegladu);
        dane.append("Data_nastepnego_przegladu", this.form.Data_nastepnego_przegladu);
        dane.append("Przeglad_wykonal", this.form.Przeglad_wykonal);
        dane.append("W1", this.form.W1);
        dane.append("W2", this.form.W2);
        dane.append("W3", this.form.W3);
        dane.append("W4", this.form.W4);
        dane.append("W5", this.form.W5);
        dane.append("W6", this.form.W6);
        dane.append("W7", this.form.W7);

        api
          .zapiszITGenerator(dane)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    naszWalidacjaFormularza() {
      if (this.czyJestWTrybieEdycji) {
        return (
          !this.$v.form.Nazwa_wybor_maszyny.$invalid &&
          !this.$v.form.Data_przegladu.$invalid &&
          !this.$v.form.Data_nastepnego_przegladu.$invalid &&
          !this.$v.form.Przeglad_wykonal.$invalid &&
          !this.$v.form.W1.$invalid &&
          !this.$v.form.W2.$invalid &&
          !this.$v.form.W3.$invalid &&
          !this.$v.form.W4.$invalid &&
          !this.$v.form.W5.$invalid &&
          !this.$v.form.W6.$invalid &&
          !this.$v.form.W7.$invalid  
        );
      } else {
        return !this.$v.$invalid ;
      }
    },
    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>

<style>
.Form_IT_generator .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.Form_IT_generator .md-layout .md-gutter {
  min-width: 55vw !important; /* szerokość modala*/
  max-width: 100% !important;
  min-height: 7vh; /* wysokość pomiędzy mierszami w modalu*/
}
.md-layout {
  padding-right: 0px !important;
}
.Form_IT_generator .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}

.Form_IT_generator .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  margin: 10px 24px !important;
  padding-top: 35px !important;
}
.Form_IT_generator .ant-select {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  margin: 10px 24px !important;
  padding-top: 35px !important;
}

.Form_IT_generator .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}

.Form_IT_generator {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
  overflow: auto !important;
}
.Form_IT_generator .md-primary {
  border: 1px solid !important;

  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}
.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important; /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}

.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}

.Form_IT_generator .md-list-item-text {
  font-size: 1.7vmin !important;
}
.Form_IT_generator .md-card-actions .md-button {
  margin-right: 50px !important;
}
.md-list-item-content {
  min-height: 35px !important;
  font-size: 1.7vmin !important;
}
</style>
