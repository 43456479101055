<template >
  <div>
  <TabelaCeleZadania />
  </div>
</template>

<script>
import TabelaCeleZadania from '../../komponenty/strona-tabela-komponenty/SYS_cele/tabela_cele_zadania.vue'


export default {
  name: 'StronaTabelaCeleZadania',
  components: {
    TabelaCeleZadania,
    
  }

}
</script>

