<template>
  <div>
  <tabelaWykazSzkolen/>

  </div>
</template>

<script>
import tabelaWykazSzkolen from '../../komponenty/strona-tabela-komponenty/HR_szkolenia/tabela_wykaz_szkolen.vue'


export default {
  name: 'StronaTabelaWykazSzkolen',
  components: {
       tabelaWykazSzkolen,
  }

}
</script>
