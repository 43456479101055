<template >
  <div>
  <TabelaRokCele />
  </div>
</template>

<script>
import TabelaRokCele from '../../komponenty/strona-tabela-komponenty/SYS_cele/tabela_rok_cele.vue'


export default {
  name: 'StronaTabelaRokCele',
  components: {
    TabelaRokCele,
    
  }

}
</script>

