<template>
  <div>
    <modal
      v-if="pokazDialog"
      :rodzajFormularza="typAktywnegoFormularza"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <md-app style="margin-top:1px">
      <md-app-content>
        <md-card>
          <div
            style="width:60%; height:50%; margin-left: 20%; margin-reight:20%; padding-top:1vh; text-align:center"
          >
            <img src="/Zdjecia/logowanie1.jpg" alt="People" />
          </div>

          <div
            style="padding-top:5vh; text-align:center; font-weight:900; font-size:2vw"
          >
            Zarządzanie systemami ISO nigdy nie było tak proste
          </div>

          <div style="padding-top:2vh; text-align:center">
            <button
              class="button"
              @click="
                pokazDialog = true;
                typAktywnegoFormularza = 'formularz_NIP';
                obiektKonfiguracyjny = {
                  Id: null,
                  akcja: 'dodawanie',
                };
              "
              style="font-size:0.85vw; font-weight:400; padding: 10px; background-color: #eee4e4; text-decoration: none; box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);"
            >
              PRZEJDŹ DO PANELU LOGOWANIE DO SYSTEMU
            </button>
           
          </div>
          <div
            style="text-align:center; width:98%; padding-top:3vh; padding-bottom:5vh"
          >
            <md-button
              href="#P1"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Logowanie</md-button
            >
            <md-button
              href="#P2"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Strona główna</md-button
            >
            <md-button
              href="#P3"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Część systemowa</md-button
            >
            <md-button
              href="#P4"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Zarządzanie infrastrukturą</md-button
            >
            <md-button
              href="#P5"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Zarządzanie BHP</md-button
            >
            <md-button
              href="#P6"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Zarządzanie pracownikami</md-button
            >
            <md-button
              href="#P7"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Zarządzanie operacyjne</md-button
            >
            <md-button
              href="#P8"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Ustawienia</md-button
            >
          </div>
        </md-card>
        <!-- //__________________________________________________________________________________________________________________ -->
        <a id="P1"></a>
        <md-card style="margin-top:10px; height:83vh;">
          <div
            style="text-align:center; width:98%; padding-top:4vh; padding-bottom:3vh"
          >
            <md-button
              href="#P1"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw; background:#606060; color: white"
              >Logowanie</md-button
            >
            <md-button
              href="#P2"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Strona główna</md-button
            >
            <md-button
              href="#P3"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Część systemowa</md-button
            >
            <md-button
              href="#P4"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Zarządzanie infrastrukturą</md-button
            >
            <md-button
              href="#P5"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw; "
              >Zarządzanie BHP</md-button
            >
            <md-button
              href="#P6"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Zarządzanie pracownikami</md-button
            >
            <md-button
              href="#P7"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Zarządzanie operacyjne</md-button
            >
            <md-button
              href="#P8"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Ustawienia</md-button
            >
          </div>

          <div class="P1d">
            <div
              style="width:90%; height:50%; margin-left: 10%; margin-reight:5%; padding-top:3vh; text-align:center"
            >
              <img src="/Zdjecia/a1.jpg" alt="People" />
            </div>
          </div>
          <div class="P1e">
            <div
              style="width:90%; height:50%; margin-left: 5%; margin-reight:5%; padding-top:3vh; text-align:center"
            >
              <img src="/Zdjecia/a1a.jpg" alt="People" />
            </div>
          </div>

          <div class="P1f" style=" margin-top:4vh">
            <div style="text-align:center;font-weight: 700">
              STRONA LOGOWANIA
            </div>
            Proces logowania jest procesem wieloetapowym. Po wciśnięciu przycisku "PRZEJDŹ DO PANELU LOGOWANIA DO SYSTEMU" system 
            poprosi o podanie numeru NIP klienta. Numer NIP należy podać w jednym ciągu bez stosowania myślników i przerw. 
            Po poprawnym podaniu numeru NIP zostaną Państwo przekierowani do procesu właściwego logowania. Należy nacisnąć w dowolne miejsce na grafice. W przypadku pierwszego logowania system wymusza zmianę hasła startowego. Po podaniu właściwego loginu i hasła następuje przekierowanie do aplikacji. W trakcie logowania system sprawdza zarówno login, hasło oraz datę wykupionej subskrypcji. W przypadku podania błędnego loginu lub hasła wyświetlony zostanie odpowiedni komunikat. Należy pamiętać, że wymagane każdorazowo jest odznaczenie oświadczenia o zapoznaniu się z polityką prywatności. Sprawdzenie terminu ważności subskrypcji następuje automatycznie. W przypadku przekroczenia terminu system wyświetli odpowiedni komunikat, a zalogowanie się do systemu nie będzie możliwe. Odblokowanie następnie automatycznie po opłaceniu abonamentu. 
          </div>
        </md-card>

        <!-- //__________________________________________________________________________________________________________________ -->
        <a id="P2"></a>
        <md-card style="margin-top:10px; height:83vh;">
          <div
            style="text-align:center; width:98%; padding-top:4vh; padding-bottom:3vh"
          >
            <md-button
              href="#P1"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Logowanie</md-button
            >
            <md-button
              href="#P2"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw; background:#606060; color: white"
              >Strona główna</md-button
            >
            <md-button
              href="#P3"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Część systemowa</md-button
            >
            <md-button
              href="#P4"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Zarządzanie infrastrukturą</md-button
            >
            <md-button
              href="#P5"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw; "
              >Zarządzanie BHP</md-button
            >
            <md-button
              href="#P6"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Zarządzanie pracownikami</md-button
            >
            <md-button
              href="#P7"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Zarządzanie operacyjne</md-button
            >
            <md-button
              href="#P8"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Ustawienia</md-button
            >
          </div>

          <div class="P1a">
            <div
              style="width:100%;  margin-left: 4%; margin-reight:2%; padding-top:3vh; text-align:center; display: block "
            >
              <img src="/Zdjecia/a2.jpg" alt="People" />
            </div>
          </div>

          <div class="P1b">
            <p style="text-align:center;font-weight: 700">STRONA GŁÓWNA</p>
            Czym jest System Zarządzania? Uogólniając, jest to zestaw powiązanych ze sobą procesów zachodzących w firmie wspomagających jej zarządzania. Wiele osób nie zdaje sobie sprawy, że systemy ISO to tak naprawdę usystematyzowanie wszystkiego co robimy na co dzień, czyli bieżącego zarządzania firmą. Patrząc z perspektywy języka normowego, główne założenia Systemów Zarządzania oparte są na cyklu Deminga. Co to takiego? To cykl „ZAPLANUJ-WYKONAJ-SPRAWDŹ-POPRAW”. System Zarządzania powinien być dopasowany pod konkretną organizację, jej wielkość oraz profil działalności. 

Aplikacja webowa QMD_SYSTEM jest pomysłem autorskim opartym na wieloletniej praktyce audytorskiej. Jest odpowiedzią na intuicyjny i usprawniony sposób zarządzania firmą, zrozumienie zagadnień związanych z ISO, a jednocześnie platformą, która ma ułatwiać życie użytkownikowi. System oparto na intuicyjnym interfejsie, przejrzystości oraz prostocie w obsłudze. Zbudowano go tak, aby sam przypominał użytkownikowi o koniecznych działaniach (system przypomnień e-mail).

Całość systemu podzielono na 6 części, ściśle ze sobą powiązanych. Decydując się na aplikację QMD_SYSTEM zbudujesz swój system w sposób intuicyjny, przejrzysty i efektywny, zapominając przy tym o przeglądaniu stosu segregatorów z dokumentami. Zapisy w nim zawarte w pełni spełniają wymagania norm PN-EN ISO 9001:2015 – Systemu Zarządzania Jakością, PN-EN ISO 14001:2015 – Systemu Zarządzania Środowiskowego oraz PN-ISO 45001:2018 – Systemu Zarządzania Bezpieczeństwem i Higieną pracy.

          </div>
        </md-card>

        <!-- //__________________________________________________________________________________________________________________ -->

        <a id="P3"></a>
        <md-card style="margin-top:10px; height:83vh;">
          <div
            style="text-align:center; width:98%; padding-top:4vh; padding-bottom:3vh"
          >
            <md-button
              href="#P1"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Logowanie</md-button
            >
            <md-button
              href="#P2"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Strona główna</md-button
            >
            <md-button
              href="#P3"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw; background:#606060; color: white"
              >Część systemowa</md-button
            >
            <md-button
              href="#P4"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Zarządzanie infrastrukturą</md-button
            >
            <md-button
              href="#P5"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw; "
              >Zarządzanie BHP</md-button
            >
            <md-button
              href="#P6"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Zarządzanie pracownikami</md-button
            >
            <md-button
              href="#P7"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Zarządzanie operacyjne</md-button
            >
            <md-button
              href="#P8"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Ustawienia</md-button
            >
          </div>

          <div class="P1a">
            <div
              style="width:100%;  margin-left: 4%; margin-reight:2%; padding-top:3vh; text-align:center; display: block "
            >
              <img src="/Zdjecia/a3.jpg" alt="People" />
            </div>
          </div>

          <div class="P1b">
            <p style="text-align:center;font-weight: 700">CZĘŚĆ SYSTEMOWA</p>
            Część systemowa aplikacji to szereg elementów wymaganych normami odniesienia tj. PN-EN ISO 9001:2015 – 
            System Zarządzania Jakością, PN-EN ISO 14001:2015 – System Zarządzania Środowiskowego oraz PN-ISO 45001:2018 – 
            System Zarządzania Bezpieczeństwem i Higieną Pracy. To Ty decydujesz czy będziesz używał wszystkich dostępnych modułów. 
            Należy tutaj zaznaczyć, że nawet jeśli nie posiadasz np. Systemu Zarządzania Bezpieczeństwem i Higieną Pracy to wcale nie znaczy, 
            że nie jesteś zobowiązany przepisami prawa do posiadania odpowiednich dokumentów. Przykładem może być tu np. wykaz prac 
            szczególnie niebezpiecznych czy wykaz substancji niebezpiecznych. W natłoku pracy firmy często zapominają o tych wymaganych dokumentach. 
            Aplikacja jest tak zbudowana, że nie jest narzędziem tylko dla ISO, ale również wspomaga firmę w trakcie różnych kontroli np. 
            Państwowej Inspekcji Sanitarnej, Państwowej Inspekcji Pracy, Wojewódzkiego Inspektora Ochrony Środowiska itp.
            Ważnym elementem oraz atutem całej aplikacji jest fakt, że całość została starannie zaprojektowanie na bazie 
            doświadczeń audytorskich przez wykwalifikowanych audytorów pracujących na co dzień jako audytorzy wiodący w 
            Jednostkach Certyfikujących. Decydując się na współpracę z nami masz w ramach abonamentu zapewniony stały ciąg aktualizacji 
            z szczególnym uwzględnieniem zmian normowych. Jeżeli posiadasz już system zarządzania sam wiesz jak czasochłonny oraz 
            kosztowny jest proces przejścia na nowe wydanie normy. U nas jest to wliczone w koszt abonamentu bez dodatkowych ukrytych opłat.
            Świadczymy również usługę dodatkową wycenianą indywidualnie w zakresie nadzorowania Państwa systemu zarządzania oraz 
            zdalnego przygotowania całości dokumentów do audytów. Regularność usługi zależy tylko od Państwa.

          </div>
        </md-card>

        <!-- //__________________________________________________________________________________________________________________ -->
        <a id="P4"></a>

        <md-card style="margin-top:10px; height:83vh">
          <div
            style="text-align:center; width:98%; padding-top:4vh; padding-bottom:3vh"
          >
            <md-button
              href="#P1"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Logowanie</md-button
            >
            <md-button
              href="#P2"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Strona główna</md-button
            >
            <md-button
              href="#P3"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Część systemowa</md-button
            >
            <md-button
              href="#P4"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw; background:#606060; color: white"
              >Zarządzanie infrastrukturą</md-button
            >
            <md-button
              href="#P5"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw; "
              >Zarządzanie BHP</md-button
            >
            <md-button
              href="#P6"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Zarządzanie pracownikami</md-button
            >
            <md-button
              href="#P7"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Zarządzanie operacyjne</md-button
            >
            <md-button
              href="#P8"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Ustawienia</md-button
            >
          </div>
          <div class="P1a">
            <div
              style="width:100%;  margin-left: 4%; margin-reight:2%; padding-top:3vh; text-align:center; display: block "
            >
              <img src="/Zdjecia/a4.jpg" alt="People" />
            </div>
          </div>
          <div class="P1b">
            <p style="text-align:center;font-weight: 700">
              CZĘŚĆ-NADZOROWANIE INFRASTRUKTURY
            </p>
            Część związana z nadzorowaniem infrastruktury jest jednym w kluczowych elementów zarządzania zarówno dla ISO jak i firmy. 
            Pojęcie infrastruktura jest bardzo rozbudowane, a nadzorowanie jej czasochłonne oraz wymagające znajomości odpowiednich przepisów prawnych. 
            Infrastruktura to np. budynki, maszyny i urządzenia, maszyny i urządzenia podlegające pod dozór techniczny, elektronarzędzia, IT, 
            samochody osobowe oraz ciężarowe, naczepy oraz przyczepy, wyposażenie pomiarowe, wyposażenie BHP np. szelki bezpieczeństwa, drabiny, 
            wyposażenie P-POŻ np. gaśnice, hydranty itp.<br /><br />
            Dla każdego z wymienionych aplikacja umożliwia prowadzenie bieżącego nadzoru w zakresie ewidencji, przeglądów technicznych, 
            ubezpieczeń, kosztów dla danego wyposażenia oraz tam, gdzie ma to zastosowanie rozliczanie czasu pracy oraz zużytego paliwa. 
            Elementem dodatkowym jest wyciąg do sprawozdania środowiskowego w zakresie należnych opłat za korzystanie ze środowiska, czyli tzw. 
            emisje do atmosfery. Paliwa do sprawozdania generują się automatycznie do właściwego wiersza w podziale tam, gdzie ma to zastosowanie. 
            Narzędziem dodatkowym jest generator świadectw sprawdzenia dla przyrządów pomiarowych. Sprawozdania generują się automatycznie. 
            W zakresie nadzorowania budynków uwzględniono wszystkie elementy przeglądów wymagane prawem budowlanym oraz koszty i zużycie wszystkich mediów. <br /><br />
            System zbudowano tak aby sam przypominał użytkownika o koniecznych działaniach (wbudowano system przypomnień e-mail) dla każdego wyposażenia.
          </div>
        </md-card>

        <!-- //__________________________________________________________________________________________________________________ -->
        <a id="P5"></a>
        <md-card style="margin-top:10px; height:83vh">
          <div
            style="text-align:center; width:98%; padding-top:4vh; padding-bottom:3vh"
          >
            <md-button
              href="#P1"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Logowanie</md-button
            >
            <md-button
              href="#P2"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Strona główna</md-button
            >
            <md-button
              href="#P3"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Część systemowa</md-button
            >
            <md-button
              href="#P4"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Zarządzanie infrastrukturą</md-button
            >
            <md-button
              href="#P5"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw; background:#606060; color: white "
              >Zarządzanie BHP</md-button
            >
            <md-button
              href="#P6"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Zarządzanie pracownikami</md-button
            >
            <md-button
              href="#P7"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Zarządzanie operacyjne</md-button
            >
            <md-button
              href="#P8"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Ustawienia</md-button
            >
          </div>
          <div class="P1a">
            <div
              style="width:100%;  margin-left: 4%; margin-reight:2%; padding-top:3vh; text-align:center; display: block "
            >
              <img src="/Zdjecia/a6.jpg" alt="People" />
            </div>
          </div>

          <div class="P1b">
            <p style="text-align:center;font-weight: 700">
              CZĘŚĆ-NADZOROWANIE BHP
            </p>
            Zarządzanie Bezpieczeństwem i Higieną Pracy to jeden z najważniejszych aspektów działalności firmy. Niestety 
            często zaniedbywany co może nieść ze sobą fatalne skutki. Pracodawco pamiętaj! To Ty odpowiadasz w firmie za stan BHP 
            i bezpieczeństwo pracowników. Na rynku panuje błędne myślenie, że zatrudniając pracowników służby BHP to oni odpowiadają 
            za prawidłową realizację zadań związanych z Bezpieczeństwem i Higieną pracy. Pracownicy służby BHP są jedynie organem doradczym. 
            Dlatego tak istotna jest możliwość sprawowania właściwej kontroli nad wszystkimi aspektami Bezpieczeństwa i Higieny Pracy. 
            <br /><br />Zarządzanie BHP jest jednym z priorytetowych systemów zarządzania. Dodatkowo elementy BHP przenikają się 
            przez wiele wymagań. Należy tu pamiętać, że same opracowanie dokumentacji nie jest wystarczające. 
            Całość należy odpowiednio nadzorować, przeglądać lub aktualizować. W ramach części związanej z BHP zapewniono nadzór nad wieloma 
            najważniejszymi aspektami takimi jak dokumentacja oceny ryzyka zawodowego, badaniami środowiska pracy, substancjami niebezpiecznymi, 
            ocenami stanu BHP, wypadkami, chorobami zawodowymi, sytuacjami potencjalnie niebezpiecznymi, pracami szczególnie niebezpiecznymi, 
            zarządzanie kontrolami BHP wewnętrznymi oraz zewnętrznymi. <br /><br />System zbudowano tak aby sam przypominał użytkownika o 
            koniecznych działaniach (wbudowano system przypomnień e-mail) dla każdego elementu.
          </div>
        </md-card>

        <!-- //__________________________________________________________________________________________________________________ -->
        <a id="P6"></a>
        <md-card style="margin-top:10px; height:83vh">
          <div
            style="text-align:center; width:98%; padding-top:4vh; padding-bottom:3vh"
          >
            <md-button
              href="#P1"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Logowanie</md-button
            >
            <md-button
              href="#P2"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Strona główna</md-button
            >
            <md-button
              href="#P3"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Część systemowa</md-button
            >
            <md-button
              href="#P4"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Zarządzanie infrastrukturą</md-button
            >
            <md-button
              href="#P5"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw "
              >Zarządzanie BHP</md-button
            >
            <md-button
              href="#P6"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw; background:#606060; color: white"
              >Zarządzanie pracownikami</md-button
            >
            <md-button
              href="#P7"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Zarządzanie operacyjne</md-button
            >
            <md-button
              href="#P8"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Ustawienia</md-button
            >
          </div>
          <div class="P1a">
            <div
              style="width:100%;  margin-left: 4%; margin-reight:2%; padding-top:3vh; text-align:center; display: block "
            >
              <img src="/Zdjecia/a5.jpg" alt="People" />
            </div>
          </div>

          <div class="P1b">
            <p style="text-align:center;font-weight: 700">
              CZĘŚĆ-HR PRACOWNICY
            </p>
            Moduł ,,HR’’ jest częścią aplikacji wspomagającej zarządzanie pracownikami oraz szeregiem elementów z nimi związanych. 
            System dzieli pracowników na zatrudnionych oraz zwolnionych. W ramach modułu nadzorowany jest szereg elementów związanych 
            z pracownikami takie jak np. badania lekarskie, szkolenia wstępne, stanowiskowe oraz okresowe BHP, zakresy obowiązków, 
            odpowiedzialności i uprawnień, okresów umów, historii zatrudnienia, zapoznania pracowników z dokumentacją oceny ryzyka 
            zawodowego oraz instrukcjami operacyjnymi, środowiskowymi oraz BHP, wykazem kompetencji pracownika, umów z medycyną pracy.
            <br /><br />Dodatkowo moduł zawiera nadzorowanie stanowisk pracy z wykazem minimalnych wymagań, planów szkoleń wraz z ich 
            rozliczeniem oraz oceną kompetencji po szkoleniach. Wbudowano moduł wystawiania skierowań na badania lekarskie oraz generator 
            zakresów obowiązków, odpowiedzialności i uprawnień w podziale na pracowników oraz pracowników zarządzających innymi pracownikami.<br /><br />
            System zbudowano tak aby sam przypominał użytkownika o koniecznych działaniach (wbudowano system przypomnień e-mail) dla każdego pracownika.
          </div>
        </md-card>
        <!-- //__________________________________________________________________________________________________________________ -->
        <a id="P7"></a>
        <md-card style="margin-top:10px; height:83vh">
          <div
            style="text-align:center; width:98%; padding-top:4vh; padding-bottom:3vh"
          >
            <md-button
              href="#P1"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Logowanie</md-button
            >
            <md-button
              href="#P2"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Strona główna</md-button
            >
            <md-button
              href="#P3"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Część systemowa</md-button
            >
            <md-button
              href="#P4"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Zarządzanie infrastrukturą</md-button
            >
            <md-button
              href="#P5"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw "
              >Zarządzanie BHP</md-button
            >
            <md-button
              href="#P6"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Zarządzanie pracownikami</md-button
            >
            <md-button
              href="#P7"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw; background:#606060; color: white"
              >Zarządzanie operacyjne</md-button
            >
            <md-button
              href="#P8"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Ustawienia</md-button
            >
          </div>
          <div class="P1a">
            <div
              style="width:100%;  margin-left: 4%; margin-reight:2%; padding-top:3vh; text-align:center; display: block "
            >
              <img src="/Zdjecia/a7.jpg" alt="People" />
            </div>
          </div>

          <div class="P1b">
            <p style="text-align:center;font-weight: 700">
              CZĘŚĆ-DZIAŁANIA OPERACYJNE
            </p>
            Moduł ,,działania operacyjne’’ z punktu widzenia działalności firmy to podstawa dla jej istnienia. Zawiera informacje o kluczowych aspektach 
            poprzez zakupy wraz z kwalifikowaniem dostawców materiałów i dostawców usług, projektowanie oraz realizację. <br /><br />

            Realizację podzielono na dwie grupy. Pierwsza grupa to ścieżka pełna zawierająca dane w podziale na zapytanie ofertowe, oferta, 
            zamówienia oraz realizację. Natomiast druga grupa to realizacja na magazyn z możliwością przyporządkowania sprzedaży do wyrobu.
            <br /><br />

            System zbudowano w taki sposób, aby na każdym etapie istniała możliwość pełnego odtworzenia pełnej ścieżki wyrobu lub usługi.
            <br /><br />

            Wbudowano moduł reklamacji z możliwością przyporządkowania konkretnego raportu produkcyjnego którego dotyczy reklamacja. 
            Ma to za zadanie ułatwienia całkowitego odtworzenia procesu produkcji oraz określenia osób odpowiedzialnych. <br /><br />

            W module uwzględniono katalogi kontrahentów oraz wyrobów i usług w podziale na dowolnie zidentyfikowane grupy. System zbudowano 
            tak aby sam przypominał użytkownika o koniecznych działaniach (wbudowano system przypomnień e-mail) dla każdego pracownika.
          </div>
        </md-card>
        <!-- //__________________________________________________________________________________________________________________ -->
        <a id="P8"></a>
        <md-card style="margin-top:10px; height:83vh">
          <div
            style="text-align:center; width:98%; padding-top:4vh; padding-bottom:3vh"
          >
            <md-button
              href="#P1"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Logowanie</md-button
            >
            <md-button
              href="#P2"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Strona główna</md-button
            >
            <md-button
              href="#P3"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Część systemowa</md-button
            >
            <md-button
              href="#P4"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Zarządzanie infrastrukturą</md-button
            >
            <md-button
              href="#P5"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw "
              >Zarządzanie BHP</md-button
            >
            <md-button
              href="#P6"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Zarządzanie pracownikami</md-button
            >
            <md-button
              href="#P7"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw"
              >Zarządzanie operacyjne</md-button
            >
            <md-button
              href="#P8"
              style="width: 9vw; border: 1px solid; margin-right:5px; font-size:0.5vw; background:#606060; color: white"
              >Ustawienia</md-button
            >
          </div>

          <div class="P1c">
            <p style="text-align:center;font-weight: 700">
              CZĘŚĆ-USTAWIENIA
            </p>
            Moduł ,,ustawienia’’ służy do nadawania uprawnień poszczególnym pracownikom. W module widnieją możliwe działania użytkownika 
            tj. DODAWANIE, USUWANIE, EDYCJA, PRZEGLĄDANIE. Uprawnienia nadawane są poprzez przycisk "DODAWANIE UPRAWNIEŃ DLA NOWEGO PRACOWNIKA". 
            Nadawanie pierwszych uprawnień dla pierwszego użytkownika następuje przez Administratora systemu - uwaga jest to komplet możliwych uprawnień. 
            Z punktu widzenia każda firma powinna wyznaczyć osobę do zarządzania panelem administracyjnym (ustawieniami).
            Należy pamiętać, że nie każdy pracownik powinien posiadać uprawnienia do wszystkiego.

          </div>
        </md-card>
      </md-app-content>
    </md-app>
  </div>
</template>

<script>
import modal from "../../strona-formularze/modal.vue";
import { api } from "../../../serwisy/api.serwis";

export default {
  name: "tabela_wykaz_procesow",
  data: () => ({
    czyPobranoDane: false,

    searched: [],
    Procesy: [],
    pokazDialog: false,
    typAktywnegoFormularza: null,
    obiektKonfiguracyjny: null,
    wybranyElementTabeli: null,
    autoryzacja: {
      czyMozeDodawac: false,
      czyMozeUsuwac: false,
      czyMozeEdytowac: false,
      czyMozePrzegladac: false,
    },
  }),
  components: {
    modal,
  },
  mounted: function() {
    this.pobierzDaneDoTabeli();
  },
  computed: {
    czyJestWTrybieEdycji() {
      return this.$router.params.Id ? true : false;
    },
  },
  methods: {
    pobierzDaneDoTabeli() {
      const { id } = this.$route.params;

      console.log(this.$route);

      api
        .pobieranieFirmy(id)
        .then((obiektOdpowiedzi) => {
          console.log(obiektOdpowiedzi);
          this.Procesy = obiektOdpowiedzi.data.dane;
          this.searched = this.Procesy;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.czyPobranoDane = true;
        });
    },
    funkcjaZamykajacaDialog(sukces) {
      this.pokazDialog = false;
      this.typAktywnegoFormularza = null;
      this.obiektKonfiguracyjny = null;
      if (sukces) {
        this.pobierzDaneDoTabeli();
      }
    },
    newUser() {
      window.alert("Noop");
    },

    wyborElementu(element) {
      this.wybranyElementTabeli = element;
    },
  },
};
</script>

<style lang="scss" scoped>
.md-app {
  max-height: 85vh;
  border: 1px solid rgba(#000, 0.12);
  overflow: auto !important;
}

// Demo purposes only
.md-drawer {
  width: 230px;
  max-width: calc(100vw - 125px);
  overflow: auto;
}
.md-card {
  background-color: #eee4e4;
  text-decoration: none;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
.P1a {
  display: block;
  float: left;
  width: 60%;
}
.P1b {
  display: block;
  margin-right: 3%;
  float: right;
  width: 30%;
  overflow: auto;
  padding-top: 3vh;
  font-size: 0.65vw;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-align: justify;
  line-height: 1.2;
  height: 60vh;
}
.P1c {
  margin-right: 5%;
  overflow: auto;
  margin-left: 5%;
  font-size: 0.65vw;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-align: justify;
  line-height: 1.2;
  height: 60vh;
}
.P1d {
  display: block;
  float: left;
  width: 48%;
}
.P1e {
  display: block;
  margin-right: 4%;
  float: right;
  width: 48%;
}
.P1f {
  display: block;

  float: right;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
}
.md-app-content {
    padding: 16px;
}
</style>
