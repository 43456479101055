<template >
  <div>
  <TabelaDzialaniaAuditOcenaSkutecznosci />
  </div>
</template>

<script>
import TabelaDzialaniaAuditOcenaSkutecznosci from '../../komponenty/strona-tabela-komponenty/SYS_dzialania_audit/tabela_dzialania_audit_ocena_skutecznosci.vue'


export default {
  name: 'StronaTabelaDzialaniaAuditSkutecznosci',
  components: {
    TabelaDzialaniaAuditOcenaSkutecznosci,
    
  }

}
</script>

