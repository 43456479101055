<template>
  <div>
  <tabelaWykazSkierowanie/>

  </div>
</template>

<script>
import tabelaWykazSkierowanie from '../../komponenty/strona-tabela-komponenty/HR_badania/tabela_skierowanie.vue'


export default {
  name: 'StronaTabelaWykazSkierowanie',
  components: {
       tabelaWykazSkierowanie,

  }

}
</script>
