<template >
  <div>
  <TabelaPrzegladyInstrukcjeBHP />
  </div>
</template>

<script>
import TabelaPrzegladyInstrukcjeBHP from '../../komponenty/strona-tabela-komponenty/SYS_instrukcje_BHP/tabela_instrukcje_BHP_przeglady.vue'


export default {
  name: 'StronaTabelaPrzegladyInstrukcjeBHP',
  components: {
    TabelaPrzegladyInstrukcjeBHP,
    
  }

}
</script>

