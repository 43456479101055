<template >
  <div>
  <TabelaInstrukcjeOperacyjne />
  </div>
</template>

<script>
import TabelaInstrukcjeOperacyjne from '../../komponenty/strona-tabela-komponenty/SYS_instrukcje_operacyjne/tabela_instrukcje_operacyjne.vue'


export default {
  name: 'StronaTabelaInstrukcjeOperacyjne',
  components: {
    TabelaInstrukcjeOperacyjne,
    
  }

}
</script>

