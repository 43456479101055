<template >
  <div>
  <TabelaRealizacjaZakonczoneMagazyn/>
  </div>
</template>

<script>
import TabelaRealizacjaZakonczoneMagazyn from '../../komponenty/strona-tabela-komponenty/SO_realizacja_magazyn/tabela_wykaz_realizacja_magazyn_zakonczone.vue'


export default {
  name: 'StronaTabelaRealizacjaZakonczoneMagazyn',
  components: {
    TabelaRealizacjaZakonczoneMagazyn,
    
  }

}
</script>

