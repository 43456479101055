<template>
  <div class="FORM_MD_przeglad">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI SPRAWDZENIA WYPOSAŻENIA KONTROLNO-POMIAROWEGO"
                : "FORMULARZ DODAWANIA SPRAWDZENIA WYPOSAŻENIA KONTROLNO-POMIAROWEGO"
            }}
          </div>
        </md-card-header>

        <md-card-content>
          <div
            style="font-weight: bold; margin-top:30px; margin-left:25px; text-decoration: underline; font-size: 15px; color: red"
          >
            DANE SZCZEGÓŁOWE DOTYCZĄCE ŚWIADECTWA SPRAWDZENIA
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="MP2"
                :class="getValidationClass('Data_przegladu')"
              >
                <label for="Data_przegladu">Data sprawdzenia</label>
                <md-input
                  type="date"
                  name="Data_przegladu"
                  id="Data_przegladu"
                  
                  v-model="form.Data_przegladu"
                  :disabled="sending"
                  @input="pobierzNumerSwiadectwa"
                  :readonly="czyJestWTrybieEdycji"
                />
                <span class="md-error" v-if="!$v.form.Data_przegladu.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
            
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('Numer_swiadectwa')">
                <label for="Numer_swiadectwa">Numer świadectwa</label>
                <md-input
                  name="Numer_swiadectwa"
                  id="Numer_swiadectwa"
                  
                  v-model="form.Numer_swiadectwa"
                  :disabled="sending"
                  readonly
                />
                <span class="md-error" v-if="!$v.form.Numer_swiadectwa.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field
                class="MP2"
                :class="getValidationClass('Data_nastepnego_przegladu')"
              >
                <label for="Data_nastepnego_przegladu"
                  >Data następnego sprawdzenia</label
                >
                <md-input
                  type="date"
                  id="Data_nastepnego_przegladu"
                  name="Data_nastepnego_przegladu"
                  autocomplete="Data_nastepnego_przegladu"
                  v-model="form.Data_nastepnego_przegladu"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Data_nastepnego_przegladu.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field
                class="MP1"
                :class="getValidationClass('Przeglad_wykonal')"
              >
                <label for="Przeglad_wykonal">Sprawdzenie wykonał</label>
                <md-input
                  name="Przeglad_wykonal"
                  id="Przeglad_wykonal"
                  
                  md-counter="100"
                  v-model="form.Przeglad_wykonal"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Przeglad_wykonal.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Przeglad_wykonal.maxlength"
                  >Wymagana ilość znaków od 1 do 100</span
                >
              </md-field>
            </div>
          </div>

          <div
            style="font-weight: bold; margin-top:30px; margin-left:25px; text-decoration: underline; font-size: 15px; color: red"
          >
            DANE DOTYCZĄCE WYPOSAŻENIA PODLEGAJĄCEGO SPRAWDZENIU
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-autocomplete
                :class="getValidationClass('Nazwa_wybor_maszyny')"
                v-model="form.Nazwa_wybor_maszyny"
                :md-options="zapytaniaMaszyny"
                :disabled="sending || czyJestWTrybieEdycji"
              >
                <label>Wybór wyposażenia podlegającego sprawdzeniu</label>
                <span
                  class="md-error"
                  v-if="!$v.form.Nazwa_wybor_maszyny.required"
                  >Pole jest wymagane</span
                >
              </md-autocomplete>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('Id_maszyny')">
                <label for="Id_maszyny">Id wyposażenia</label>
                <md-input
                  :value="Id_maszyny"
                  type="text"
                  name="Id_maszyny"
                  id="Id_maszyny"
                  :disabled="sending"
                  readonly
                />
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('Numer_fabryczny')">
                <label for="Numer_fabryczny">Numer fabryczny</label>
                <md-input
                  :value="Numer_fabryczny"
                  type="text"
                  name="Numer_fabryczny"
                  id="Numer_fabryczny"
                  :disabled="sending"
                  readonly
                />
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('Rok_produkcji')">
                <label for="Rok_produkcji">Rok produkcji</label>
                <md-input
                  :value="Rok_produkcji"
                  type="text"
                  name="Rok_produkcji"
                  id="Rok_produkcji"
                  :disabled="sending"
                  readonly
                />
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('Osoba_odpowiedzialna')">
                <label for="Osoba_odpowiedzialna">Osoba odpowiedzialna</label>
                <md-input
                  :value="Osoba_odpowiedzialna"
                  type="text"
                  name="Osoba_odpowiedzialna"
                  id="Osoba_odpowiedzialna"
                  :disabled="sending"
                  readonly
                />
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('Dokladnosc_plus')">
                <label for="Dokladnosc_plus">Dokładność(+)</label>
                <md-input
                  :value="Dokladnosc_plus"
                  type="text"
                  name="Dokladnosc_plus"
                  id="Dokladnosc_plus"
                  :disabled="sending"
                  readonly
                />
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('Dokladnosc_minus')">
                <label for="Dokladnosc_minus">Dokładność(-)</label>
                <md-input
                  :value="Dokladnosc_minus"
                  type="text"
                  name="Dokladnosc_minus"
                  id="Dokladnosc_minus"
                  :disabled="sending"
                  readonly
                />
              </md-field>
            </div>
          </div>

          <div
            style="font-weight: bold; margin-top:30px; margin-left:25px; text-decoration: underline; font-size: 15px; color: red"
          >
            DANE DOTYCZĄCE WYPOSAŻENIA WZORCOWEGO
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-autocomplete
                :class="getValidationClass('Nazwa_wybor_maszyny_wzorzec')"
                v-model="form.Nazwa_wybor_maszyny_wzorzec"
                :md-options="zapytaniaMaszynyWzorzec"
                :disabled="sending || czyJestWTrybieEdycji"
              >
                <label>Wybór wyposażenia wzorcowego</label>
                <span
                  class="md-error"
                  v-if="!$v.form.Nazwa_wybor_maszyny_wzorzec.required"
                  >Pole jest wymagane</span
                >
              </md-autocomplete>
            </div>
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('Id_maszyny')">
                <label for="Id_maszyny">Id wyposażenia</label>
                <md-input
                  :value="Id_maszyny1"
                  type="text"
                  name="Id_maszyny"
                  id="Id_maszyny"
                  :disabled="sending"
                  readonly
                />
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('Numer_fabryczny')">
                <label for="Numer_fabryczny">Numer fabryczny</label>
                <md-input
                  :value="Numer_fabryczny1"
                  type="text"
                  name="Numer_fabryczny"
                  id="Numer_fabryczny"
                  :disabled="sending"
                  readonly
                />
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('Rok_produkcji')">
                <label for="Rok_produkcji">Rok produkcji</label>
                <md-input
                  :value="Rok_produkcji1"
                  type="text"
                  name="Rok_produkcji"
                  id="Rok_produkcji"
                  :disabled="sending"
                  readonly
                />
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('Osoba_odpowiedzialna')">
                <label for="Osoba_odpowiedzialna">Osoba odpowiedzialna</label>
                <md-input
                  :value="Osoba_odpowiedzialna1"
                  type="text"
                  name="Osoba_odpowiedzialna"
                  id="Osoba_odpowiedzialna"
                  :disabled="sending"
                  readonly
                />
              </md-field>
            </div>
          </div>

          
          <div
            style="font-weight: bold; margin-top:30px; margin-left:25px; text-decoration: underline; font-size: 15px; color: blue"
          >
            Wynik pomiaru wyposażeniem wzorcowym
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="MP2" :class="getValidationClass('Wynik_1_W')">
                <label for="Wynik_1_W">Wynik 1</label>
                <md-input
                  type="text"
                  name="Wynik_1_W"
                  id="Wynik_1_W"
                  autocomplete="Wynik_1_W"
                  md-counter="50"
                  v-model="form.Wynik_1_W"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Wynik_1_W.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field class="MP2" :class="getValidationClass('Wynik_2_W')">
                <label for="Wynik_2_W">Wynik 2</label>
                <md-input
                  type="text"
                  name="Wynik_2_W"
                  id="Wynik_2_W"
                  md-counter="50"
                  autocomplete="Wynik_2_W"
                  v-model="form.Wynik_2_W"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Wynik_2_W.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field class="MP2" :class="getValidationClass('Wynik_3_W')">
                <label for="Wynik_3_W">Wynik 3</label>
                <md-input
                  type="text"
                  name="Wynik_3_W"
                  id="Wynik_3_W"
                  md-counter="50"
                  autocomplete="Wynik_3_W"
                  v-model="form.Wynik_3_W"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Wynik_3_W.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

          <div
            style="font-weight: bold; margin-top:30px; margin-left:25px; text-decoration: underline; font-size: 15px; color: blue"
          >
            Wynik pomiaru wyposażeniem sprawdzanym
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="MP2" :class="getValidationClass('Wynik_1_WS')">
                <label for="Wynik_1_WS">Wynik 1</label>
                <md-input
                  type="text"
                  name="Wynik_1_WS"
                  id="Wynik_1_WS"
                  md-counter="50"
                  autocomplete="Wynik_1_WS"
                  v-model="form.Wynik_1_WS"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Wynik_1_WS.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field class="MP2" :class="getValidationClass('Wynik_2_WS')">
                <label for="Wynik_2_WS">Wynik 2</label>
                <md-input
                  type="text"
                  name="Wynik_2_WS"
                  id="Wynik_2_WS"
                  md-counter="50"
                  autocomplete="Wynik_2_WS"
                  v-model="form.Wynik_2_WS"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Wynik_2_WS.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field class="MP2" :class="getValidationClass('Wynik_3_WS')">
                <label for="Wynik_3_WS">Wynik 3</label>
                <md-input
                  type="text"
                  name="Wynik_3_WS"
                  id="Wynik_3_WS"
                  md-counter="50"
                  autocomplete="Wynik_3_WS"
                  v-model="form.Wynik_3_WS"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Wynik_3_WS.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

          <div
            style="font-weight: bold; margin-top:30px; margin-left:25px; text-decoration: underline; font-size: 15px; color: blue"
          >
            OCENA UZSKANYCH WYNIKÓW
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="MP2" :class="getValidationClass('Wynik_1_WSO')">
                <label for="Wynik_1_WSO">Wynik sprawdzenia (%) - 1</label>
                <md-input
                  type="text"
                  name="Wynik_1_WSO"
                  id="Wynik_1_WSO"
                  autocomplete="Wynik_1_WSO"
                  v-model="Wynik_1_WSO"
                  :disabled="sending"
                  readonly
                />
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field class="MP2" :class="getValidationClass('Wynik_2_WSO')">
                <label for="Wynik_2_WSO">Wynik sprawdzenia (%) - 2</label>
                <md-input
                  type="text"
                  name="Wynik_2_WSO"
                  id="Wynik_2_WSO"
                  autocomplete="Wynik_2_WSO"
                  v-model="Wynik_2_WSO"
                  :disabled="sending"
                  readonly
                />
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field class="MP2" :class="getValidationClass('Wynik_3_WSO')">
                <label for="Wynik_3_WSO">Wynik sprawdzenia (%) - 3</label>
                <md-input
                  type="text"
                  name="Wynik_3_WSO"
                  id="Wynik_3_WSO"
                  autocomplete="Wynik_3_WSO"
                  v-model="Wynik_3_WSO"
                  :disabled="sending"
                  readonly
                />
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="MP2" :class="getValidationClass('Wynik_1_UD')">
                <label for="Wynik_1_UD">Wynik dokładności (%) - 1</label>
                <md-input
                  type="text"
                  name="Wynik_1_UD"
                  id="Wynik_1_UD"
                  autocomplete="Wynik_1_UD"
                  v-model="Wynik_1_UD"
                  :disabled="sending"
                  readonly
                />
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field class="MP2" :class="getValidationClass('Wynik_2_UD')">
                <label for="Wynik_2_UD">Wynik dokładności (%) - 2</label>
                <md-input
                  type="text"
                  name="Wynik_2_UD"
                  id="Wynik_2_UD"
                  autocomplete="Wynik_2_UD"
                  v-model="Wynik_2_UD"
                  :disabled="sending"
                  readonly
                />
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field class="MP2" :class="getValidationClass('Wynik_3_UD')">
                <label for="Wynik_3_UD">Wynik dokładności (%) - 3</label>
                <md-input
                  type="text"
                  name="Wynik_3_UD"
                  id="Wynik_3_UD"
                  autocomplete="Wynik_3_UD"
                  v-model="Wynik_3_UD"
                  :disabled="sending"
                  readonly
                />
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="MP2"
                :class="getValidationClass('Wynik_sprawdzenia')"
              >
                <label for="Wynik_sprawdzenia"
                  >Wynik sprawdzenia wyposażenia</label
                >
                <md-input
                  type="text"
                  name="Wynik_sprawdzenia"
                  id="Wynik_sprawdzenia"
                  autocomplete="Wynik_sprawdzenia"
                  v-model="Wynik_sprawdzenia"
                  :disabled="sending"
                  readonly
                />
              </md-field>
            </div>
          </div>
        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

        <div style="text-align: center">
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            czyJestWTrybieEdycji
              ? "ZATWIERDŹ - EDYTOWANY WPIS"
              : "ZATWIERDŹ - UTWÓRZ WPIS"
          }}</md-button>
        </div>
      </md-card>
    </form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import minLength from "vuelidate/lib/validators/minLength";
import { api } from "../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_przegladu_maszyny",
  mixins: [validationMixin],
  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      Id: null,
      Nazwa_wybor_maszyny: null,
      Nazwa_wybor_maszyny_wzorzec: null,
      Data_przegladu: null,
      Numer_swiadectwa: null,
      Data_nastepnego_przegladu: null,
      Przeglad_wykonal: null,
      Wynik_1_W: null,
      Wynik_2_W: null,
      Wynik_3_W: null,
      Wynik_1_WS: null,
      Wynik_2_WS: null,
      Wynik_3_WS: null,
      plik_PDF: null,
      plik_dane: null,
    },
    IdEdytowanegoElementu: null,
    nazwaPliku: null,
    userSaved: false,
    sending: false,
    lastUser: null,
    Nazwy_maszyn: [],
    Nazwy_maszyn_wzorzec: [],
    Obiekt_generujacy_numer_swiadectwa: null,
  }),

  async created() {
    try {
      const [
        odpowiedz_Nazwa_maszyny,
        odpowiedz_Nazwa_maszyny_wzorzec,
      ] = await Promise.all([
        api.pobierzPomiarowkaSprawdzenie(),
        api.pobierzPomiarowkaWzorcowanie(),
      ]);
      odpowiedz_Nazwa_maszyny,
        (this.Nazwy_maszyn = odpowiedz_Nazwa_maszyny.data.dane.map((el) => ({
          ...el,
          nazwa: ` Wyposażenie: ${el.Nazwa_maszyny} - Nr fabryczny: ${el.Numer_fabryczny} `,
        })));

      odpowiedz_Nazwa_maszyny_wzorzec,
        (this.Nazwy_maszyn_wzorzec = odpowiedz_Nazwa_maszyny_wzorzec.data.dane.map(
          (el) => ({
            ...el,
            nazwa1: ` Wyposażenie: ${el.Nazwa_maszyny} - Nr fabryczny: ${el.Numer_fabryczny} `,
          })
        ));

      if (this.obiektKonfiguracyjny.akcja === "edycja") {
        //edycja
        const odpowiedz = await api.pobieranieWykazSprawdzenPomiarowkaEdycja(
          this.obiektKonfiguracyjny.Id
        );

        const {
          Id,
          Id_maszyny_wybor,
          Id_maszyny_wzorzec,
          Data_przegladu,
          Numer_swiadectwa,
          Data_nastepnego_przegladu,
          Przeglad_wykonal,
          Wynik_1_W,
          Wynik_2_W,
          Wynik_3_W,
          Wynik_1_WS,
          Wynik_2_WS,
          Wynik_3_WS,
          Wynik_1_UD,
          Wynik_2_UD,
          Wynik_3_UD,
          Wynik_sprawdzenia,
        } = odpowiedz.data.dane[0];
        this.IdEdytowanegoElementu = Id;
        this.form.Nazwa_wybor_maszyny = this.Nazwy_maszyn.find(
          ({ Id }) => Id == Id_maszyny_wybor
        ).nazwa;
        this.form.Nazwa_wybor_maszyny_wzorzec = this.Nazwy_maszyn_wzorzec.find(
          ({ Id }) => Id == Id_maszyny_wzorzec
        ).nazwa1;
        this.form.Data_przegladu = Data_przegladu;
        this.form.Numer_swiadectwa = Numer_swiadectwa;
        this.form.Data_nastepnego_przegladu = Data_nastepnego_przegladu;
        this.form.Przeglad_wykonal = Przeglad_wykonal;
        this.form.Wynik_1_W = Wynik_1_W;
        this.form.Wynik_2_W = Wynik_2_W;
        this.form.Wynik_3_W = Wynik_3_W;
        this.form.Wynik_1_WS = Wynik_1_WS;
        this.form.Wynik_2_WS = Wynik_2_WS;
        this.form.Wynik_3_WS = Wynik_3_WS;
        this.form.Wynik_1_UD = Wynik_1_UD;
        this.form.Wynik_2_UD = Wynik_2_UD;
        this.form.Wynik_3_UD = Wynik_3_UD;
        this.form.Wynik_sprawdzenia = Wynik_sprawdzenia;
      }
    } catch (error) {
      console.log(error);
    }
  },

  validations: {
    form: {
      Nazwa_wybor_maszyny: {
        required,
      },
      Nazwa_wybor_maszyny_wzorzec: {
        required,
      },
      Data_przegladu: {
        required,
      },
      Numer_swiadectwa: {
        required,
      },
      Data_nastepnego_przegladu: {
        required,
      },
      Przeglad_wykonal: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(250),
      },
      Wynik_1_W: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(50),
      },
      Wynik_2_W: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(50),
      },
      Wynik_3_W: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(50),
      },
      Wynik_1_WS: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(50),
      },
      Wynik_2_WS: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(50),
      },
      Wynik_3_WS: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(50),
      },
    },
  },
  computed: {
    Id_maszyny_wybor: function() {
      const { Nazwa_wybor_maszyny } = this.form;
      const znalezionyElement = this.Nazwy_maszyn.find(
        ({ nazwa }) => nazwa === Nazwa_wybor_maszyny
      );

      if (znalezionyElement) {
        return znalezionyElement.Id;
      }
      return null;
    },
    zapytaniaMaszyny: function() {
      return this.Nazwy_maszyn.map((el) => el.nazwa);
    },

    Id_maszyny_wzorzec: function() {
      const { Nazwa_wybor_maszyny_wzorzec } = this.form;
      const znalezionyElement = this.Nazwy_maszyn_wzorzec.find(
        ({ nazwa1 }) => nazwa1 === Nazwa_wybor_maszyny_wzorzec
      );

      if (znalezionyElement) {
        return znalezionyElement.Id;
      }
      return null;
    },
    zapytaniaMaszynyWzorzec: function() {
      return this.Nazwy_maszyn_wzorzec.map((el) => el.nazwa1);
    },

    plikZwalidowany: function() {
      return this.form.plik_dane
        ? this.form.plik_dane.size < 2 * 1024 * 1024
        : true;
    },
    czyJestWTrybieEdycji: function() {
      return this.obiektKonfiguracyjny.akcja === "edycja";
    },
    Numer_fabryczny: function() {
      const { Id_maszyny_wybor } = this;
      return Id_maszyny_wybor
        ? this.Nazwy_maszyn.find(({ Id }) => Id === Id_maszyny_wybor)
            .Numer_fabryczny
        : "";
    },
    Rok_produkcji: function() {
      const { Id_maszyny_wybor } = this;
      return Id_maszyny_wybor
        ? this.Nazwy_maszyn.find(({ Id }) => Id === Id_maszyny_wybor)
            .Rok_produkcji
        : "";
    },
    Id_maszyny: function() {
      const { Id_maszyny_wybor } = this;
      return Id_maszyny_wybor
        ? this.Nazwy_maszyn.find(({ Id }) => Id === Id_maszyny_wybor).Id_maszyny
        : "";
    },
    Osoba_odpowiedzialna: function() {
      const { Id_maszyny_wybor } = this;
      return Id_maszyny_wybor
        ? this.Nazwy_maszyn.find(({ Id }) => Id === Id_maszyny_wybor)
            .Osoba_odpowiedzialna
        : "";
    },
    Dokladnosc_plus: function() {
      const { Id_maszyny_wybor } = this;
      return Id_maszyny_wybor
        ? this.Nazwy_maszyn.find(({ Id }) => Id === Id_maszyny_wybor).Dokladnosc_plus
        : "";
    },
    Dokladnosc_minus: function() {
      const { Id_maszyny_wybor } = this;
      return Id_maszyny_wybor
        ? this.Nazwy_maszyn.find(({ Id }) => Id === Id_maszyny_wybor).Dokladnosc_minus
        : "";
    },
    Numer_fabryczny1: function() {
      const { Id_maszyny_wzorzec } = this;
      return Id_maszyny_wzorzec
        ? this.Nazwy_maszyn_wzorzec.find(({ Id }) => Id === Id_maszyny_wzorzec)
            .Numer_fabryczny
        : "";
    },
    Rok_produkcji1: function() {
      const { Id_maszyny_wzorzec } = this;
      return Id_maszyny_wzorzec
        ? this.Nazwy_maszyn_wzorzec.find(({ Id }) => Id === Id_maszyny_wzorzec)
            .Rok_produkcji
        : "";
    },
    Id_maszyny1: function() {
      const { Id_maszyny_wzorzec } = this;
      return Id_maszyny_wzorzec
        ? this.Nazwy_maszyn_wzorzec.find(({ Id }) => Id === Id_maszyny_wzorzec)
            .Id_maszyny
        : "";
    },
    Osoba_odpowiedzialna1: function() {
      const { Id_maszyny_wzorzec } = this;
      return Id_maszyny_wzorzec
        ? this.Nazwy_maszyn_wzorzec.find(({ Id }) => Id === Id_maszyny_wzorzec)
            .Osoba_odpowiedzialna
        : "";
    },
    Wynik_1_WSO: function() {
      if (this.form.Wynik_1_W === null || this.form.Wynik_1_WS === null) {
        return "";
      }
      return parseFloat(
        ((this.form.Wynik_1_W.replace(",", ".") || 0) /
          (this.form.Wynik_1_WS.replace(",", ".") || 0)) *
          100
      ).toFixed(5);
    },
    Wynik_2_WSO: function() {
      if (this.form.Wynik_2_W === null || this.form.Wynik_2_WS === null) {
        return "";
      }
      return parseFloat(
        ((this.form.Wynik_2_W.replace(",", ".") || 0) /
          (this.form.Wynik_2_WS.replace(",", ".") || 0)) *
          100
      ).toFixed(5);
    },
    Wynik_3_WSO: function() {
      if (this.form.Wynik_3_W === null || this.form.Wynik_3_WS === null) {
        return "";
      }
      return parseFloat(
        ((this.form.Wynik_3_W.replace(",", ".") || 0) /
          (this.form.Wynik_3_WS.replace(",", ".") || 0)) *
          100
      ).toFixed(5);
    },

    Wynik_1_UD: function() {
      if (this.Wynik_1_WSO === null) {
        return "";
      }
      return parseFloat(
        (100 || 0) - (this.Wynik_1_WSO.replace(",", ".") || 0)
      ).toFixed(3);
    },
    Wynik_2_UD: function() {
      if (this.Wynik_2_WSO === null) {
        return "";
      }
      return parseFloat(
        (100 || 0) - (this.Wynik_2_WSO.replace(",", ".") || 0)
      ).toFixed(3);
    },
    Wynik_3_UD: function() {
      if (this.Wynik_3_WSO === null) {
        return "";
      }
      return parseFloat(
        (100 || 0) - (this.Wynik_3_WSO.replace(",", ".") || 0)
      ).toFixed(3);
    },

    Wynik_sprawdzenia: function () {


        if (this.Wynik_1_UD === '' || this.Wynik_2_UD === '' || this.Wynik_3_UD === '' || this.Dokladnosc_plus === '' || this.Dokladnosc_minus === '') {
            return "";
        }

        const Dokladnosc_plus = parseFloat(this.Dokladnosc_plus)
        const Dokladnosc_minus = parseFloat(this.Dokladnosc_minus)

        const Wynik_1_UD = parseFloat(this.Wynik_1_UD);
        const Wynik_2_UD = parseFloat(this.Wynik_2_UD);
        const Wynik_3_UD = parseFloat(this.Wynik_3_UD);

        const spełnionyWarunekPierwszy = Wynik_1_UD <= Dokladnosc_plus && Wynik_1_UD >= Dokladnosc_minus;
        const spełnionyWarunekDrugi = Wynik_2_UD <= Dokladnosc_plus && Wynik_2_UD >= Dokladnosc_minus;
        const spełnionyWarunekTrzeci = Wynik_3_UD <= Dokladnosc_plus && Wynik_3_UD >= Dokladnosc_minus;


        return spełnionyWarunekPierwszy && spełnionyWarunekDrugi && spełnionyWarunekTrzeci
            ?
            "WYNIK POZYTYWNY" : "WYNIK NEGATYWNY";
    },
    numerSwiadectwa: function () {
      if (this.Obiekt_generujacy_numer_swiadectwa) {
        const { numer, miesiac, rok } = this.Obiekt_generujacy_numer_swiadectwa;
        return `WKP-SS-${numer}/${miesiac}/${rok}`;
      } else {
        return "";
      }
    },
  },

  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toUpperCase()
          .indexOf(input.toUpperCase()) >= 0
      );
    },
    zapamietajPlik(pliki) {
      if (pliki[0]) {
        this.form.plik_dane = pliki[0];
      }
    },
    pobierzNumerSwiadectwa() {
      if(this.czyJestWTrybieEdycji){
        return
      }else{
      const { Data_przegladu } = this.form;
      const data = new Date(Data_przegladu);
      const rok = data.getFullYear();
      const miesiac = data.getMonth() + 1;

      api.PobierzLpSwiadectwa({ rok, miesiac }).then((odpowiedz) => {
        this.Obiekt_generujacy_numer_swiadectwa = odpowiedz.data.dane;
        this.form.Numer_swiadectwa = this.numerSwiadectwa;
      });
    }
    },
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.Id = null;
      this.form.Data_przegladu = null;
      this.form.Numer_swiadectwa = null;
      this.form.Data_nastepnego_przegladu = null;
      this.form.Przeglad_wykonal = null;
    },

    zapiszFormularz() {
      this.sending = true;

      if (this.czyJestWTrybieEdycji) {
        const daneDoWyslania = {
          Id_maszyny_wybor: this.Id_maszyny_wybor,
          Id_maszyny_wzorzec: this.Id_maszyny_wzorzec,
          Data_przegladu: this.form.Data_przegladu,
          Numer_swiadectwa: this.form.Numer_swiadectwa,
          Data_nastepnego_przegladu: this.form.Data_nastepnego_przegladu,
          Przeglad_wykonal: this.form.Przeglad_wykonal,
          Wynik_1_W: this.form.Wynik_1_W,
          Wynik_2_W: this.form.Wynik_2_W,
          Wynik_3_W: this.form.Wynik_3_W,
          Wynik_1_WS: this.form.Wynik_1_WS,
          Wynik_2_WS: this.form.Wynik_2_WS,
          Wynik_3_WS: this.form.Wynik_3_WS,
          Wynik_1_WSO: this.Wynik_1_WSO,
          Wynik_2_WSO: this.Wynik_2_WSO,
          Wynik_3_WSO: this.Wynik_3_WSO,
          Wynik_1_UD: this.Wynik_1_UD,
          Wynik_2_UD: this.Wynik_2_UD,
          Wynik_3_UD: this.Wynik_3_UD,
          Wynik_sprawdzenia: this.Wynik_sprawdzenia,
        };


        api
          .zapiszWykazSprawdzenPomiarowkaUpdate(daneDoWyslania, this.IdEdytowanegoElementu)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const daneDoWyslania = {
          Id_maszyny_wybor: this.Id_maszyny_wybor,
          Lp: this.Obiekt_generujacy_numer_swiadectwa.numer,
          Id_maszyny_wzorzec: this.Id_maszyny_wzorzec,
          Data_przegladu: this.form.Data_przegladu,
          Numer_swiadectwa: this.form.Numer_swiadectwa,
          Data_nastepnego_przegladu: this.form.Data_nastepnego_przegladu,
          Przeglad_wykonal: this.form.Przeglad_wykonal,
          Wynik_1_W: this.form.Wynik_1_W,
          Wynik_2_W: this.form.Wynik_2_W,
          Wynik_3_W: this.form.Wynik_3_W,
          Wynik_1_WS: this.form.Wynik_1_WS,
          Wynik_2_WS: this.form.Wynik_2_WS,
          Wynik_3_WS: this.form.Wynik_3_WS,
          Wynik_1_WSO: this.Wynik_1_WSO,
          Wynik_2_WSO: this.Wynik_2_WSO,
          Wynik_3_WSO: this.Wynik_3_WSO,
          Wynik_1_UD: this.Wynik_1_UD,
          Wynik_2_UD: this.Wynik_2_UD,
          Wynik_3_UD: this.Wynik_3_UD,
          Wynik_sprawdzenia: this.Wynik_sprawdzenia,
          };


        api
          .zapiszGeneratorPomiarowka(daneDoWyslania)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    naszWalidacjaFormularza() {
      if (this.czyJestWTrybieEdycji) {
        return (
          !this.$v.form.Nazwa_wybor_maszyny.$invalid &&
          !this.$v.form.Nazwa_wybor_maszyny_wzorzec.$invalid &&
          !this.$v.form.Data_przegladu.$invalid &&
          !this.$v.form.Numer_swiadectwa.$invalid &&
          !this.$v.form.Data_nastepnego_przegladu.$invalid &&
          !this.$v.form.Przeglad_wykonal.$invalid &&
          !this.$v.form.Wynik_1_W.$invalid &&
          !this.$v.form.Wynik_2_W.$invalid &&
          !this.$v.form.Wynik_3_W.$invalid &&
          !this.$v.form.Wynik_1_WS.$invalid &&
          !this.$v.form.Wynik_2_WS.$invalid &&
          !this.$v.form.Wynik_3_WS.$invalid 
          // !this.$v.form.Wynik_1_WSO.$invalid &&
          // !this.$v.form.Wynik_2_WSO.$invalid &&
          // !this.$v.form.Wynik_3_WSO.$invalid &&
          // !this.$v.form.Wynik_sprawdzenia.$invalid
        );
      } else {
        return !this.$v.$invalid;
      }
    },
    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>

<style>
.FORM_MD_przeglad .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.FORM_MD_przeglad .md-layout .md-gutter {
  min-width: 55vw !important; /* szerokość modala*/
  max-width: 100% !important;
  min-height: 7vh; /* wysokość pomiędzy mierszami w modalu*/
}
.md-layout {
  padding-right: 0px !important;
}
.FORM_MD_przeglad .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}

.FORM_MD_przeglad .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  margin: 10px 24px !important;
  padding-top: 35px !important;
}
.FORM_MD_przeglad .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}

.FORM_MD_przeglad {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
  overflow: auto !important;
}
.FORM_MD_przeglad .md-primary {
  border: 1px solid !important;

  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}
.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important; /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}

.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}
.FORM_MD_przeglad .md-list-item-text {
  font-size: 1.7vmin !important;
}
.FORM_MD_przeglad .md-card-actions .md-button {
  margin-right: 50px !important;
}
</style>
