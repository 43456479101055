<template>
  <div>
  <TabelaElektronarzedziaKoszty />
  </div>
</template>

<script>
import TabelaElektronarzedziaKoszty from '../../komponenty/strona-tabela-komponenty/WST_elektronarzedzia/tabela_elektronarzedzia_koszty.vue'


export default {
  name: 'StronaTabelaElektronarzedziaKoszty',
  components: {
    TabelaElektronarzedziaKoszty,
  }

}
</script>
