<template>
  <div>
  <TabelaBudynkiPrzegladGaz/>
  </div>
</template>

<script>
import TabelaBudynkiPrzegladGaz from '../../komponenty/strona-tabela-komponenty/WST_budynki/tabela_budynki_przeglady_gaz.vue'


export default {
  name: 'StronaTabelaBudynkiPrzegladGaz',
  components: {
    TabelaBudynkiPrzegladGaz,
  }

}
</script>
