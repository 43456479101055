<template>
  <md-dialog :md-active="true" @md-clicked-outside="funkcjaZamykajacaDialog">
    <FormularzDodawaniePrzegladuKontekstu
      v-if="rodzajFormularza === 'formularz_dodawanie_przegladu_kontekstu'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieLokalizacji
      v-if="rodzajFormularza === 'formularz_dodawanie_lokalizacji'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieZakresu
      v-if="rodzajFormularza === 'formularz_dodawanie_zakresu'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieGranic
      v-if="rodzajFormularza === 'formularz_dodawanie_granic'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieNormy
      v-if="rodzajFormularza === 'formularz_dodawanie_normy'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieWylaczenia
      v-if="rodzajFormularza === 'formularz_dodawanie_wylaczenia'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieProcesyZewnetrzne
      v-if="rodzajFormularza === 'formularz_dodawanie_procesy_zewnetrzne'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieMocneStrony
      v-if="rodzajFormularza === 'formularz_dodawanie_mocne_strony'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieSlabeStrony
      v-if="rodzajFormularza === 'formularz_dodawanie_slabe_strony'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieZSZ
      v-if="rodzajFormularza === 'formularz_dodawanie_ZSZ'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieWSZ
      v-if="rodzajFormularza === 'formularz_dodawanie_WSZ'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
  </md-dialog>
</template>

<script>
import FormularzDodawaniePrzegladuKontekstu from "./SYS_kontekst/formularz_dodawanie_przegladu_kontekstu";
import FormularzDodawanieLokalizacji from "./SYS_kontekst/formularz_dodawanie_lokalizacji";
import FormularzDodawanieZakresu from "./SYS_kontekst/formularz_dodawanie_zakresu";
import FormularzDodawanieGranic from "./SYS_kontekst/formularz_dodawanie_granic";
import FormularzDodawanieNormy from "./SYS_kontekst/formularz_dodawanie_normy";
import FormularzDodawanieWylaczenia from "./SYS_kontekst/formularz_dodawanie_wylaczenia";
import FormularzDodawanieProcesyZewnetrzne from "./SYS_kontekst/formularz_dodawanie_procesy_zewnetrzne";
import FormularzDodawanieMocneStrony from "./SYS_kontekst/formularz_dodawanie_mocne_strony";
import FormularzDodawanieSlabeStrony from "./SYS_kontekst/formularz_dodawanie_slabe_strony";
import FormularzDodawanieZSZ from "./SYS_kontekst/formularz_dodawanie_ZSZ";
import FormularzDodawanieWSZ from "./SYS_kontekst/formularz_dodawanie_WSZ";

export default {
  name: "modalKontekst",
  components: {
    FormularzDodawaniePrzegladuKontekstu,
    FormularzDodawanieLokalizacji,
    FormularzDodawanieZakresu,
    FormularzDodawanieGranic,
    FormularzDodawanieNormy,
    FormularzDodawanieWylaczenia,
    FormularzDodawanieProcesyZewnetrzne,
    FormularzDodawanieMocneStrony,
    FormularzDodawanieSlabeStrony,
    FormularzDodawanieZSZ,
    FormularzDodawanieWSZ,
  },
  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    rodzajFormularza: {
      type: String,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
};
</script>
