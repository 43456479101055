<template>
  <div>
  <tabelaWykazProjektow/>

  </div>
</template>

<script>
import tabelaWykazProjektow from '../../komponenty/strona-tabela-komponenty/SO_projekty/tabela_wykaz_projektow.vue'


export default {
  name: 'StronaTabelaWykazProjektow',
  components: {
       tabelaWykazProjektow,
  }

}
</script>
