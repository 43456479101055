<template >
  <div>
  <TabelaDzialania/>
  </div>
</template>

<script>
import TabelaDzialania from '../../komponenty/strona-tabela-komponenty/SO_realizacja/tabela_wykaz_dzialania.vue'


export default {
  name: 'StronaTabelaDzialania',
  components: {
    TabelaDzialania,
    
  }

}
</script>

