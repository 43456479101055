var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tabela_CeleSZ"},[_c('div',{staticClass:"laczacy"},[_c('div',{staticClass:"menu_strony"},[_c('span',[_c('button',{staticClass:"button",attrs:{"disabled":!_vm.autoryzacja.czyMozeDodawac},on:{"click":function($event){_vm.pokazDialog = true;
            _vm.typAktywnegoFormularza = 'formularz_dodawanie_celu_szczegolowego';
            _vm.obiektKonfiguracyjny = {
              Id: null,
              akcja: 'dodawanie',
            };}}},[_vm._v(" DODAWANIE CELU SZCZEGÓŁOWEGO ")]),_c('md-tooltip',{staticClass:"podpowiedz",attrs:{"md-direction":"top"}},[_vm._v(_vm._s(!_vm.autoryzacja.czyMozeDodawac ? "BRAK UPRAWNIEŃ" : "DODAWANIE CELU SZCZEGÓŁOWEGO"))])],1),_c('span',[_c('button',{staticClass:"button",attrs:{"disabled":!_vm.autoryzacja.czyMozeDodawac},on:{"click":function($event){_vm.pokazDialog = true;
            _vm.typAktywnegoFormularza = 'formularz_dodawanie_zadania';
            _vm.obiektKonfiguracyjny = {
              Id: null,
              akcja: 'dodawanie',
            };}}},[_vm._v(" DODAWANIE ZADANIA DLA CELU SZCZEGÓŁOWEGO ")]),_c('md-tooltip',{staticClass:"podpowiedz",attrs:{"md-direction":"top"}},[_vm._v(_vm._s(!_vm.autoryzacja.czyMozeDodawac ? "BRAK UPRAWNIEŃ" : "DODAWANIE ZADANIA DLA CELU SZCZEGÓŁOWEGO"))])],1)]),_c('div',{staticClass:"menu_strony1"},[_c('b',[_c('b-icon-printer-fill',{staticClass:"ikony",attrs:{"font-scale":"2.1","color":"white"},on:{"click":_vm.generujPdf}}),_c('md-tooltip',{staticClass:"podpowiedz",attrs:{"md-direction":"top"}},[_vm._v("GENERUJ PDF / DRUKUJ")])],1),_c('b',[_c('b-icon-info-circle-fill',{staticClass:"ikony",attrs:{"font-scale":"2.1","color":"white"},on:{"click":_vm.pokazPDF_INFO}}),_c('md-tooltip',{staticClass:"podpowiedz",attrs:{"md-direction":"top"}},[_vm._v("GENERUJ PDF / DRUKUJ")])],1)])]),(_vm.pokazDialog)?_c('modal',{attrs:{"rodzajFormularza":_vm.typAktywnegoFormularza,"funkcjaZamykajacaDialog":_vm.funkcjaZamykajacaDialog,"obiektKonfiguracyjny":_vm.obiektKonfiguracyjny}}):_vm._e(),_c('md-table',{attrs:{"md-height":"45vh","md-sort":"Nazwa_maszyny","md-sort-order":"asc","md-model-id":"Id_maszyny","md-card":"","md-fixed-header":""},on:{"md-selected":_vm.wyborElementu},scopedSlots:_vm._u([{key:"md-table-row",fn:function(ref){
            var item = ref.item;
return _c('md-table-row',{key:item.id,attrs:{"md-selectable":"single"}},[_c('md-table-cell',{attrs:{"md-label":"Lp"}},[_vm._v(" "+_vm._s(item.Lp)+" ")]),_c('md-table-cell',{attrs:{"md-label":"Rok","md-sort-by":"Rok"}},[_vm._v(" "+_vm._s(item.Rok)+" ")]),_c('md-table-cell',{attrs:{"md-label":"Nr celu","md-sort-by":"Nr_celu"}},[_vm._v(" "+_vm._s(item.Nr_celu)+" ")]),_c('md-table-cell',{attrs:{"md-label":"Cel szczegółowy","md-sort-by":"Cel_szczegolowy"}},[_vm._v(" "+_vm._s(item.Cel_szczegolowy)+" ")])],1)}}]),model:{value:(_vm.searched),callback:function ($$v) {_vm.searched=$$v},expression:"searched"}},[_c('md-table-toolbar',[_c('div',{staticClass:"d-flex flex-column w-100"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4"}),_c('div',{staticClass:"col-4"},[_c('h1',{staticClass:"md-title"},[_vm._v("WYKAZ CELÓW SZCZEGÓŁOWYCH")])]),_c('div',{staticClass:"col-4"},[_c('md-field',{staticClass:"ml-auto mr-0",attrs:{"md-clearable":""}},[_c('md-input',{attrs:{"placeholder":"Szukaj..."},on:{"input":_vm.searchOnTable},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]),(_vm.wybranyElementTabeli)?_c('div',{staticClass:"PasekWyboru"},[_c('div',{staticStyle:{"text-align":"right","margin-top":"0.3vh","margin-bottom":"0.3vh"}},[_c('b',[_c('b-button',{attrs:{"disabled":!_vm.autoryzacja.czyMozeUsuwac},on:{"click":function($event){return _vm.usuwanieElementu(_vm.wybranyElementTabeli.Id)}}},[_c('b-icon-trash',{staticClass:"ikony",attrs:{"font-scale":"1.8","color":"white"}})],1),_c('md-tooltip',{staticClass:"podpowiedz",attrs:{"md-direction":"top"}},[_vm._v(_vm._s(_vm.autoryzacja.czyMozeUsuwac ? "USUWANIE ELEMENTU" : "BRAK UPRAWNIEŃ"))])],1),_c('b',[_c('b-button',{attrs:{"disabled":!_vm.autoryzacja.czyMozeEdytowac},on:{"click":function($event){_vm.pokazDialog = true;
                  _vm.typAktywnegoFormularza =
                    'formularz_dodawanie_celu_szczegolowego';
                  _vm.obiektKonfiguracyjny = {
                    Id: _vm.wybranyElementTabeli.Id,
                    akcja: 'edycja',
                  };}}},[_c('b-icon-pencil-square',{staticClass:"ikony",attrs:{"font-scale":"1.80","color":"white"}})],1),_c('md-tooltip',{staticClass:"podpowiedz",attrs:{"md-direction":"top"}},[_vm._v(_vm._s(_vm.autoryzacja.czyMozeUsuwac ? "EDYCJA ELEMENTU" : "BRAK UPRAWNIEŃ"))])],1),_c('b',[_c('b-button',[_c('b-icon-bounding-box',{staticClass:"ikony",attrs:{"font-scale":"1.80","color":"white"},on:{"click":function($event){return _vm.$router.push(
                      ("../zadania_cele_szczegolowe/" + (_vm.wybranyElementTabeli.Id))
                    )}}})],1),_c('md-tooltip',{staticClass:"podpowiedz",attrs:{"md-direction":"top"}},[_vm._v("ZADANIA DLA CELU SZCZEGÓŁOWEGO")])],1)])]):_vm._e()])]),(_vm.czyPobranoDane)?_c('md-table-empty-state',{attrs:{"md-label":"Nie posiadasz wpisów w wykazie celów szczególowych"}},[_c('md-button',{staticClass:"md-primary md-raised",attrs:{"disabled":!_vm.autoryzacja.czyMozeDodawac},on:{"click":function($event){_vm.pokazDialog = true;
          _vm.typAktywnegoFormularza = 'formularz_dodawanie_celu_szczegolowego';
          _vm.obiektKonfiguracyjny = {
            Id: null,
            akcja: 'dodawanie',
          };}}},[_vm._v("Dodawanie nowego celu szczegółowego")]),_c('md-tooltip',{staticClass:"podpowiedz",attrs:{"md-direction":"top"}},[_vm._v(_vm._s(!_vm.autoryzacja.czyMozeDodawac ? "BRAK UPRAWNIEŃ" : "DODAWANIE DODAWANIE CELU SZCZEGÓŁOWEGO"))])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }