<template>

  <TabelaPrzegladRyzykaSzanse />

</template>

<script>
import TabelaPrzegladRyzykaSzanse from '../../komponenty/strona-tabela-komponenty/SYS_ryzyka_szanse/tabela_przeglad_ryzyka_szanse.vue'



export default {
  name: 'StronaTabelaPrzegladRyzykaSzanse',
  components: {
    TabelaPrzegladRyzykaSzanse,

    }

}
</script>
