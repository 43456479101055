<template>
  <div>
  <TabelaPomiarowkaGenerator />
  </div>
</template>

<script>
import TabelaPomiarowkaGenerator from '../../komponenty/strona-tabela-komponenty/WST_generator_sprawdzen/tabela_sprzet_pomiarowy_generator.vue'


export default {
  name: 'StronaTabelaPomiarowkaGenerator',
  components: {
    TabelaPomiarowkaGenerator,
  }

}
</script>
