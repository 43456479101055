<template >
  <div>
  <TabelaWykazPracownicyHistoriaZatrudnienia />
  </div>
</template>

<script>
import TabelaWykazPracownicyHistoriaZatrudnienia from '../../komponenty/strona-tabela-komponenty/HR_pracownicy/tabela_historia_zatrudnienia.vue'


export default {
  name: 'StronaTabelaWykazHistoria',
  components: {
    TabelaWykazPracownicyHistoriaZatrudnienia,
    
  }

}
</script>

