<template >
  <div>
  <TabelaWykazPracownicySzkoleniaStan />
  </div>
</template>

<script>
import TabelaWykazPracownicySzkoleniaStan from '../../komponenty/strona-tabela-komponenty/HR_pracownicy/tabela_wstepne_stanowiskowe.vue'


export default {
  name: 'StronaTabelaWykazBadan',
  components: {
    TabelaWykazPracownicySzkoleniaStan,
    
  }

}
</script>

