<template>
  <div>
  <TabelaMaszynyDozoroweKoszty />
  </div>
</template>

<script>
import TabelaMaszynyDozoroweKoszty from '../../komponenty/strona-tabela-komponenty/WST_maszyny_dozorowe/tabela_maszyny_dozorowe_koszty.vue'


export default {
  name: 'StronaTabelaMaszynyDozoroweKoszty',
  components: {
    TabelaMaszynyDozoroweKoszty,
  }

}
</script>
