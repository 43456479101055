<template >
  <div>
  <TabelaWykazPracownicyZapoznanieIO />
  </div>
</template>

<script>
import TabelaWykazPracownicyZapoznanieIO from '../../komponenty/strona-tabela-komponenty/HR_pracownicy/tabela_zapoznanie_IO.vue'


export default {
  name: 'StronaTabelaWykazBadan',
  components: {
    TabelaWykazPracownicyZapoznanieIO,
    
  }

}
</script>

