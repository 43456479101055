<template>
  <div>
  <tabelaWykazSytuacjeDzialaniaOcena/>

  </div>
</template>

<script>
import tabelaWykazSytuacjeDzialaniaOcena from '../../komponenty/strona-tabela-komponenty/BHP_sytuacje_potencjalnie_wypadkowe/tabela_dzialania_sytuacje_ocena_skutecznosci.vue'


export default {
  name: 'StronaTabelaWykazSytuacjeDzialaniaOcena',
  components: {
       tabelaWykazSytuacjeDzialaniaOcena,

  }

}
</script>
