<template>
  <div>
  <TabelaBudynkiPrzegladElektryka/>
  </div>
</template>

<script>
import TabelaBudynkiPrzegladElektryka from '../../komponenty/strona-tabela-komponenty/WST_budynki/tabela_budynki_przeglady_elektryka.vue'


export default {
  name: 'StronaTabelaBudynkiPrzegladElektryka',
  components: {
    TabelaBudynkiPrzegladElektryka,
  }

}
</script>
