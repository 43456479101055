<template>
<div class="ElementyWyboruWST">
  <b-container fluid class="p-0">
    <b-row>

         <b-col sm="12" md="2">
        <KafelekWyboruWST 
          sciezkaZdjecia="Zdjecia/o-maszyny.jpg"
          opisZdjecia="FajnaFotka"
          linkDoStrony="maszyny_i_urzadzenia"
          podpisPodKafelkiem="MASZYNY I URZĄDZENIA"
        />
      </b-col>
                  <b-col sm="12" md="2">
        <KafelekWyboruWST
          sciezkaZdjecia="Zdjecia/o-dokumentacja.jpg"
          opisZdjecia="FajnaFotka"
          linkDoStrony="generator_przegladow_maszyn"
          podpisPodKafelkiem="GENERATOR PROTOKOŁÓW-MIU"
        />
      </b-col>  
            <b-col sm="12" md="2">
        <KafelekWyboruWST 
          sciezkaZdjecia="Zdjecia/o-maszynydozor.jpeg"
          opisZdjecia="FajnaFotka"
          linkDoStrony="maszyny_i_urzadzenia_dozorowe"
          podpisPodKafelkiem="MASZYNY I URZĄDZENIA DOZOROWE"
        />
      </b-col>
               <b-col sm="12" md="2">
        <KafelekWyboruWST
          sciezkaZdjecia="Zdjecia/o-elektronarzedzia.jpeg"
          opisZdjecia="FajnaFotka"
          linkDoStrony="tabela_elektronarzedzia"
          podpisPodKafelkiem="NARZĘDZIA ELEKTRYCZNE"
        />
      </b-col>
                <b-col sm="12" md="2">
        <KafelekWyboruWST
          sciezkaZdjecia="Zdjecia/o-dokumentacja.jpg"
          opisZdjecia="FajnaFotka"
          linkDoStrony="generator_przegladow_elektronarzedzia"
          podpisPodKafelkiem="GENERATOR PROTOKOŁÓW-NE"
        />
      </b-col>  
                   <b-col sm="12" md="2">
        <KafelekWyboruWST
          sciezkaZdjecia="Zdjecia/o-it.jpeg"
          opisZdjecia="FajnaFotka"
          linkDoStrony="tabela_IT"
          podpisPodKafelkiem="IT"
        />
      </b-col>
                <b-col sm="12" md="2">
        <KafelekWyboruWST
          sciezkaZdjecia="Zdjecia/o-dokumentacja.jpg"
          opisZdjecia="FajnaFotka"
          linkDoStrony="generator_przegladow_IT"
          podpisPodKafelkiem="GENERATOR PROTOKOŁÓW-IT"
        />
      </b-col>  
               <b-col sm="12" md="2">
        <KafelekWyboruWST 
          sciezkaZdjecia="Zdjecia/o-budynki.jpeg"
          opisZdjecia="FajnaFotka"
          linkDoStrony="budynki"
          podpisPodKafelkiem="BUDYNKI"
        />
      </b-col>
            <b-col sm="12" md="2">
        <KafelekWyboruWST 
          sciezkaZdjecia="Zdjecia/o-osobowe.jpeg"
          opisZdjecia="FajnaFotka"
          linkDoStrony="samochody_osobowe"
          podpisPodKafelkiem="SAMOCHODY OSOBOWE"
        />
      </b-col>
               <b-col sm="12" md="2">
        <KafelekWyboruWST
          sciezkaZdjecia="Zdjecia/o-powyzej35t.jpeg"
          opisZdjecia="FajnaFotka"
          linkDoStrony="samochody_ciezarowe"
          podpisPodKafelkiem="SAMOCHODY POWYŻEJ 3.5T"
        />
      </b-col>
                   <b-col sm="12" md="2">
        <KafelekWyboruWST
          sciezkaZdjecia="Zdjecia/o-pomiarowka.jpeg"
          opisZdjecia="FajnaFotka"
          linkDoStrony="sprzet_pomiarowy"
          podpisPodKafelkiem="WYPOSAŻENIE POMIAROWE"
        />
      </b-col>

                 <b-col sm="12" md="2">
        <KafelekWyboruWST
          sciezkaZdjecia="Zdjecia/o-dokumentacja.jpg"
          opisZdjecia="FajnaFotka"
          linkDoStrony="wykaz_sprzetu_pomiarowego"
          podpisPodKafelkiem="GENERATOR ŚWIADECTW SPRAWDZENIA"
        />
      </b-col>   
                   <b-col sm="12" md="2">
        <KafelekWyboruWST
          sciezkaZdjecia="Zdjecia/o-wbhp.jpg"
          opisZdjecia="FajnaFotka"
          linkDoStrony="wyposazenie_BHP"
          podpisPodKafelkiem="WYPOSAŻENIE BHP"
        />
      </b-col>  
                         <b-col sm="12" md="2">
        <KafelekWyboruWST
          sciezkaZdjecia="Zdjecia/o-wppoz.jpg"
          opisZdjecia="FajnaFotka"
          linkDoStrony="wyposazenie_PPOZ"
          podpisPodKafelkiem="WYPOSAŻENIE P-POŻ."
        />
      </b-col>  
 
               <b-col sm="12" md="2">
        <KafelekWyboruWST
          sciezkaZdjecia="Zdjecia/o-aspekty.jpg"
          opisZdjecia="FajnaFotka"
          linkDoStrony="wykaz_emisje"
          podpisPodKafelkiem="EMISJE Z PALIW"
        />
      </b-col>

    </b-row>
  </b-container>
</div>
</template>

<script>
import KafelekWyboruWST from "./Kafelek_wyboru_WST.vue";

export default {
  name: "ElementyWyboruWST",
  components: {
    KafelekWyboruWST,
  },
};
</script>

<style scoped>
.ElementyWyboruWST {
  margin-top: 1%;
  display: flex;
  flex-flow: row wrap;
  padding-bottom: 10%;
}

</style>
