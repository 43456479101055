<template>
  <div>
  <TabelaPomiarowka />
  </div>
</template>

<script>
import TabelaPomiarowka from '../../komponenty/strona-tabela-komponenty/WST_pomiarowka/tabela_sprzet_pomiarowy.vue'


export default {
  name: 'StronaTabelaPomiarowka',
  components: {
    TabelaPomiarowka,
  }

}
</script>
