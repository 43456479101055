var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tabela_zakres"},[_vm._m(0),(_vm.pokazDialog)?_c('modal',{attrs:{"rodzajFormularza":_vm.typAktywnegoFormularza,"funkcjaZamykajacaDialog":_vm.funkcjaZamykajacaDialog,"obiektKonfiguracyjny":_vm.obiektKonfiguracyjny}}):_vm._e(),(_vm.pokazDialog)?_c('modalKontekst',{attrs:{"rodzajFormularza":_vm.typAktywnegoFormularza,"funkcjaZamykajacaDialog":_vm.funkcjaZamykajacaDialog,"obiektKonfiguracyjny":_vm.obiektKonfiguracyjny}}):_vm._e(),_c('md-table',{attrs:{"md-height":"53vh","md-model-id":"Id_maszyny","md-card":"","md-fixed-header":""},on:{"md-selected":_vm.wyborElementu},scopedSlots:_vm._u([{key:"md-table-row",fn:function(ref){
var item = ref.item;
return _c('md-table-row',{key:item.id,attrs:{"md-selectable":"single"}},[_c('md-table-cell',{attrs:{"md-label":"Nazwa_lokalizacji"}},[_vm._v(" "+_vm._s(item.Nazwa_lokalizacji)+" ")]),_c('md-table-cell',{attrs:{"md-label":"Zakres"}},[_vm._v(" "+_vm._s(item.Zakres)+" ")])],1)}}]),model:{value:(_vm.searched),callback:function ($$v) {_vm.searched=$$v},expression:"searched"}},[_c('md-table-toolbar',[_c('div',{staticClass:"d-flex flex-column w-100"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h1',{staticClass:"md-title"},[_vm._v(" ZAKRES CERTYFIKACJI DLA WYBRANEJ LOKALIZACJI ")])])]),(_vm.wybranyElementTabeli)?_c('div',{staticClass:"PasekWyboru"},[_c('div',{staticStyle:{"text-align":"right","margin-top":"0.3vh","margin-bottom":"0.3vh"}},[_c('b',[_c('b-button',{attrs:{"disabled":!_vm.autoryzacja.czyMozeUsuwac},on:{"click":function($event){return _vm.usuwanieElementu(_vm.wybranyElementTabeli.Id)}}},[_c('b-icon-trash',{staticClass:"ikony",attrs:{"font-scale":"1.8","color":"white"}})],1),_c('md-tooltip',{staticClass:"podpowiedz",attrs:{"md-direction":"top"}},[_vm._v(_vm._s(_vm.autoryzacja.czyMozeUsuwac ? "USUWANIE ELEMENTU" : "BRAK UPRAWNIEŃ"))])],1),_c('b',[_c('b-button',{attrs:{"disabled":!_vm.autoryzacja.czyMozeEdytowac},on:{"click":function($event){_vm.pokazDialog = true;
                  _vm.typAktywnegoFormularza = 'formularz_dodawanie_zakresu';
                  _vm.obiektKonfiguracyjny = {
                    Id: _vm.wybranyElementTabeli.Id,
                    akcja: 'edycja',
                  };}}},[_c('b-icon-pencil-square',{staticClass:"ikony",attrs:{"font-scale":"1.80","color":"white"}})],1),_c('md-tooltip',{staticClass:"podpowiedz",attrs:{"md-direction":"top"}},[_vm._v(_vm._s(_vm.autoryzacja.czyMozeUsuwac ? "EDYCJA ELEMENTU" : "BRAK UPRAWNIEŃ"))])],1)])]):_vm._e()])]),(_vm.czyPobranoDane)?_c('md-table-empty-state',{attrs:{"md-label":"Nie posiadasz wpisu dotyczącego zakresu certyfikacji dla wybranej lokalizacji"}},[_c('md-button',{staticClass:"md-primary md-raised",attrs:{"disabled":!_vm.autoryzacja.czyMozeDodawac},on:{"click":function($event){_vm.pokazDialog = true;
          _vm.typAktywnegoFormularza = 'formularz_dodawanie_zakresu';
          _vm.obiektKonfiguracyjny = {
            Id: null,
            akcja: 'dodawanie',
          };}}},[_vm._v("Dodawanie zakresu certyfikacji dla lokalizacji")]),_c('md-tooltip',{staticClass:"podpowiedz",attrs:{"md-direction":"top"}},[_vm._v(_vm._s(!_vm.autoryzacja.czyMozeDodawac ? "BRAK UPRAWNIEŃ" : "DODAWANIE ZAKRESU CERTYFIKACJI DLA LOKALIZACJI"))])],1):_vm._e()],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu_strony"},[_c('button')])}]

export { render, staticRenderFns }