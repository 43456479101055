<template>
  <div class="Form_sam_osobowe">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI SAMOCHODU OSOBOWEGO"
                : "FORMULARZ DODAWANIA SAMOCHODU OSOBOWEGO"
            }}
          </div>
        </md-card-header>

        <md-card-content>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-autocomplete
                :class="getValidationClass('Nazwa_wybor_pojazdu')"
                v-model="form.Nazwa_wybor_pojazdu"
                :md-options="zapytaniaPojazd"
                :disabled="sending || czyJestWTrybieEdycji"
              >
                <label>Wybór rodzaju pojazdu</label>
                <span
                  class="md-error"
                  v-if="!$v.form.Nazwa_wybor_pojazdu.required"
                  >Pole jest wymagane</span
                >
              </md-autocomplete>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M1"
                :class="getValidationClass('Nr_rejestracyjny')"
              >
                <label for="Nr_rejestracyjny">Nr rejestracyjny</label>
                <!--- edycja 7 linia-->
                <md-input
                  type="text"
                  name="Nr_rejestracyjny"
                  id="Nr_rejestracyjny"
                  autocomplete="Nr_rejestracyjny"
                  md-counter="10"
                  v-model="form.Nr_rejestracyjny"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Nr_rejestracyjny.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Nr_rejestracyjny.maxlength"
                  >Wymagana ilość znaków od 1 do 10</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field class="M2" :class="getValidationClass('Nazwa_maszyny')">
                <label for="Nazwa_maszyny">Marka samochodu / Model</label>
                <md-input
                  type="text"
                  name="Nazwa_maszyny"
                  id="Nazwa_maszyny"
                  
                  md-counter="100"
                  v-model="form.Nazwa_maszyny"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Nazwa_maszyny.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Nazwa_maszyny.maxlength"
                  >Wymagana ilość znaków od 1 do 100</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M4" :class="getValidationClass('Numer_VIN')">
                <label for="Numer_VIN">Numer VIN</label>
                <md-input
                  type="text"
                  name="Numer_VIN"
                  id="Numer_VIN"
                  
                  md-counter="50"
                  v-model="form.Numer_VIN"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Numer_VIN.required"
                  >Pole jest wymagane</span
                >
                <span class="md-error" v-else-if="!$v.form.Numer_VIN.maxlength"
                  >Wymagana ilość znaków od 1 do 50</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field class="M4" :class="getValidationClass('Rok_produkcji')">
                <label for="Rok_produkcji">Rok produkcji</label>
                <md-input
                  type="text"
                  name="Rok_produkcji"
                  id="Rok_produkcji"
                  
                  md-counter="4"
                  v-model="form.Rok_produkcji"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Rok_produkcji.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Rok_produkcji.maxlength"
                  >Wymagana ilość znaków 4</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Rok_produkcji.minlength"
                  >Wymagana ilość znaków 4</span
                >
              </md-field>
            </div>


            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M4"
                :class="getValidationClass('Osoba_odpowiedzialna')"
              >
                <label for="Osoba_odpowiedzialna">Osoba odpowiedzialna</label>
                <md-input
                  type="text"
                  name="Osoba_odpowiedzialna"
                  id="Osoba_odpowiedzialna"
                  
                  md-counter="50"
                  v-model="form.Osoba_odpowiedzialna"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Osoba_odpowiedzialna.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Osoba_odpowiedzialna.maxlength"
                  >Wymagana ilość znaków od 1 do 50</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('Status')">
                <label for="Status">Status samochodu</label>

                <md-select
                  v-model="form.Status"
                  :disabled="sending"
                  name="Status"
                  id="Status"
                  autocomplete="Status"
                  md-dense
                >
                  <md-option
                    v-for="(status, index) in Status"
                    :key="index"
                    :value="status.Id"
                    >{{ status.Status_maszyny }}</md-option
                  >
                </md-select>
                <span class="md-error" v-if="!$v.form.Status.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
            
            <div class="md-layout-item md-small-size-100">
            <md-field :class="getValidationClass('Id_paliwo')">
                <label for="Id_paliwo">Rodzaj paliwa</label>

                <md-select
                  v-model="form.Id_paliwo"
                  :disabled="sending"
                  name="Id_paliwo"
                  id="Id_paliwo"
                  autocomplete="Id_paliwo"
                  md-dense
                >
                  <md-option
                    v-for="(paliwo, index) in Paliwa"
                    :key="index"
                    :value="paliwo.Id"
                    >{{ paliwo.Rodzaj }}</md-option
                  >
                </md-select>
                <span class="md-error" v-if="!$v.form.Id_paliwo.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

        <div class="md-layout-item md-small-size-100">
            <md-field :class="getValidationClass('Id_norma_emisji')">
                <label for="Id_norma_emisji">Norma emisji</label>

                <md-select
                  v-model="form.Id_norma_emisji"
                  :disabled="sending"
                  name="Id_norma_emisji"
                  id="Id_norma_emisji"
                  autocomplete="Id_norma_emisji"
                  md-dense
                >
                  <md-option
                    v-for="(norma, index) in Normy"
                    :key="index"
                    :value="norma.Id"
                    >{{ norma.Norma_emisji }}</md-option
                  >
                </md-select>
                <span class="md-error" v-if="!$v.form.Id_norma_emisji.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

          <div v-if="!czyJestWTrybieEdycji" class="md-layout md-gutter">
            <div
              
              class="md-layout-item md-small-size-100"
            >
              <md-field
                class="M5"
                :class="getValidationClass('Data_przegladu')"
              >
                <label for="Data_przegladu">Data przeglądu</label>
                <md-input
                  type="date"
                  name="Data_przegladu"
                  id="Data_przegladu"
                  autocomplete="Data_przegladu"
                  v-model="form.Data_przegladu"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Data_przegladu.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div
              v-if="!czyJestWTrybieEdycji"
              class="md-layout-item md-small-size-100"
            >
              <md-field
                class="M5"
                :class="getValidationClass('Data_nastepnego_przegladu')"
              >
                <label for="Data_nastepnego_przegladu"
                  >Data nast.przeglądu</label
                >
                <md-input
                  type="date"
                  id="Data_nastepnego_przegladu"
                  name="Data_nastepnego_przegladu"
                  autocomplete="Data_nastepnego_przegladu"
                  v-model="form.Data_nastepnego_przegladu"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Data_nastepnego_przegladu.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

           
          </div>

          <div v-if="!czyJestWTrybieEdycji" class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M5"
                :class="getValidationClass('Przeglad_wykonal')"
              >
                <label for="Przeglad_wykonal">Przegląd wykonał</label>
                <md-input
                  name="Przeglad_wykonal"
                  id="Przeglad_wykonal"
                  autocomplete="Przeglad_wykonal"
                  md-counter="100"
                  v-model="form.Przeglad_wykonal"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Przeglad_wykonal.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Przeglad_wykonal.maxlength"
                  >Wymagana ilość znaków od 1 do 100</span
                >
              </md-field>
            </div>
          </div>

          <div v-if="!czyJestWTrybieEdycji" class="md-layout md-gutter">
            <div
              
              class="md-layout-item md-small-size-100"
            >
              <md-field
                class="M5"
                :class="getValidationClass('Data_ubezpieczenia')"
              >
                <label for="Data_ubezpieczenia">Data ubezpieczenia</label>
                <md-input
                  type="date"
                  name="Data_ubezpieczenia"
                  id="Data_ubezpieczenia"
                  autocomplete="Data_ubezpieczenia"
                  v-model="form.Data_ubezpieczenia"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Data_ubezpieczenia.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div
              v-if="!czyJestWTrybieEdycji"
              class="md-layout-item md-small-size-100"
            >
              <md-field
                class="M5"
                :class="getValidationClass('Data_nastepnego_ubezpieczenia')"
              >
                <label for="Data_nastepnego_ubezpieczenia"
                  >Data nast.ubezpieczenia</label
                >
                <md-input
                  type="date"
                  id="Data_nastepnego_ubezpieczenia"
                  name="Data_nastepnego_ubezpieczenia"
                  autocomplete="Data_nastepnego_ubezpieczenia"
                  v-model="form.Data_nastepnego_ubezpieczenia"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Data_nastepnego_ubezpieczenia.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div> 
            
            <div
              v-if="!czyJestWTrybieEdycji"
              class="md-layout-item md-small-size-100"
            >
              <md-field
                class="M5"
                :class="getValidationClass('Nr_polisy')"
              >
                <label for="Nr_polisy">Nr polisy</label>
                <md-input
                  name="Nr_polisy"
                  id="Nr_polisy"
                  autocomplete="Nr_polisy"
                  md-counter="50"
                  v-model="form.Nr_polisy"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Nr_polisy.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Nr_polisy.maxlength"
                  >Wymagana ilość znaków od 1 do 50</span
                >
              </md-field>
            </div>
          </div>

          <div v-if="!czyJestWTrybieEdycji" class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M5"
                :class="getValidationClass('Ubezpieczyciel')"
              >
                <label for="Ubezpieczyciel">Ubezpieczyciel</label>
                <md-input
                  name="Ubezpieczyciel"
                  id="Ubezpieczyciel"
                  autocomplete="Ubezpieczyciel"
                  md-counter="100"
                  v-model="form.Ubezpieczyciel"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Ubezpieczyciel.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Ubezpieczyciel.maxlength"
                  >Wymagana ilość znaków od 1 do 100</span
                >
              </md-field>
            </div>
          </div>

          <div v-if="!czyJestWTrybieEdycji" class="md-layout md-gutter">
            <md-field class="M3" :class="plikZwalidowany ? '' : 'md-invalid'">
              <label>Wstaw plik dotyczący przeglądu technicznego "max-2Mb"</label>
              <md-file v-model="form.plik_PDF" @md-change="zapamietajPlik" />
              <span class="md-error" v-if="!plikZwalidowany"
                >Plik moze miec max 2MB</span
              >
            </md-field>
          </div>

          <div v-if="!czyJestWTrybieEdycji" class="md-layout md-gutter">
            <md-field class="M3" :class="plikZwalidowany ? '' : 'md-invalid'">
              <label>Wstaw plik dotyczący ubezpieczenia "max-2Mb"</label>
              <md-file v-model="form.plik_PDF_1" @md-change="zapamietajPlik_1" />
              <span class="md-error" v-if="!plikZwalidowany"
                >Plik moze miec max 2MB</span
              >
            </md-field>
          </div>
        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

        <div style="text-align: center">
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            czyJestWTrybieEdycji
              ? "ZATWIERDŹ - EDYTOWANY WPIS"
              : "ZATWIERDŹ - UTWÓRZ WPIS"
          }}</md-button>
        </div>
      </md-card>
    </form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import minLength from "vuelidate/lib/validators/minLength";
import { api } from "../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_maszyny",
  mixins: [validationMixin],
  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      Nazwa_wybor_pojazdu: null,
      Nr_rejestracyjny: null,
      Nazwa_maszyny: null,
      Numer_VIN: null,
      Osoba_odpowiedzialna: null,
      Rok_produkcji: null,
      Status: null,
      Id_paliwo: null,
      Id_norma_emisji: null,
      Data_przegladu: null,
      Data_nastepnego_przegladu: null,
      Data_ubezpieczenia: null,
      Data_nastepnego_ubezpieczenia: null,
      Przeglad_wykonal: null,
      Nr_polisy: null,
      Ubezpieczyciel: null,
      plik_PDF: null,
      plik_dane: null,
      plik_PDF_1: null,
      plik_dane_1: null,
    },
    IdEdytowanegoElementu: null,
    userSaved: false,
    sending: false,
    lastUser: null,
    Status: [],
    Paliwa: [],
    Normy:[],
    RodzajePojazdow:[],
  }),

  async created() {
    try {
      const [
          odpowiedz_status, 
          odpowiedz_paliwo, 
          odpowiedz_emisja,
          odpowiedz_rodzaj_pojazdu,
      ] = await Promise.all([
        api.pobierzStatusSamochody(),
        api.pobierzRodzajPaliwa(),
        api.pobierzNormeEmisji(),
        api.pobierzRodzajPojazduOsobowego(),
      ]);
          odpowiedz_status, 
          odpowiedz_paliwo, 
          odpowiedz_emisja,
        (this.RodzajePojazdow = odpowiedz_rodzaj_pojazdu.data.dane.map((el) => ({
          ...el,
          nazwa: `${el.Rodzaj_pojazdu}`,
        })));

        this.Status = odpowiedz_status.data.dane;
        this.Paliwa = odpowiedz_paliwo.data.dane;
        this.Normy = odpowiedz_emisja.data.dane;

      if (this.obiektKonfiguracyjny.akcja === "edycja") {
        //edycja

        const odpowiedz = await api.pobieranieSamochoduOsobowegoEdycja(
          this.obiektKonfiguracyjny.Id
        );

          const {
            Id,
            Nr_rejestracyjny,
            Id_pojazdu,
            Nazwa_maszyny,
            Numer_VIN,
            Rok_produkcji,
            Osoba_odpowiedzialna,
            Status,
            Id_paliwo,
            Id_norma_emisji,
            Data_przegladu,
            Data_nastepnego_przegladu,
            Data_ubezpieczenia,
            Data_nastepnego_ubezpieczenia,
            Przeglad_wykonal,
            Nr_polisy,
            Ubezpieczyciel,
          } = odpowiedz.data.dane[0];
          this.IdEdytowanegoElementu = Id;
          this.form.Nr_rejestracyjny = Nr_rejestracyjny;
          this.form.Nazwa_wybor_pojazdu = this.RodzajePojazdow.find(
          ({ Id }) => Id == Id_pojazdu
           ).nazwa;
          this.form.Nazwa_maszyny = Nazwa_maszyny;
          this.form.Numer_VIN = Numer_VIN;
          this.form.Osoba_odpowiedzialna = Osoba_odpowiedzialna;
          this.form.Rok_produkcji = Rok_produkcji;
          this.form.Status = Status;
          this.form.Id_paliwo = Id_paliwo;
          this.form.Id_norma_emisji = Id_norma_emisji;
          this.form.Data_przegladu = Data_przegladu;
          this.form.Data_nastepnego_przegladu = Data_nastepnego_przegladu;
          this.form.Data_ubezpieczenia = Data_ubezpieczenia;
          this.form.Data_nastepnego_ubezpieczenia = Data_nastepnego_ubezpieczenia;
          this.form.Przeglad_wykonal = Przeglad_wykonal;
          this.form.Nr_polisy = Nr_polisy;
          this.form.Ubezpieczyciel = Ubezpieczyciel;
       }
    } catch (error) {
      console.log(error);
    }
  },
  validations: {
    form: {
      Nr_rejestracyjny: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(10),
      },
      Nazwa_wybor_pojazdu: {
        required,
      },
      Nazwa_maszyny: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(100),
      },
      Numer_VIN: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(50),
      },
      Status: {
        required,
      },
      Id_paliwo: {
        required,
      },
      Id_norma_emisji: {
        required,
      },
      Rok_produkcji: {
        required,
        minLenght: minLength(4),
        maxLength: maxLength(4),
      },
      Osoba_odpowiedzialna: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(50),
      },
      Data_przegladu: {
        required,
      },
      Data_nastepnego_przegladu: {
        required,
      },
      Data_ubezpieczenia: {
        required,
      },
      Data_nastepnego_ubezpieczenia: {
        required,
      },
      Przeglad_wykonal: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(100),
      },
      Nr_polisy: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(50),
      },
      Ubezpieczyciel: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(100),
      },
    },
  },
  computed: {
    Id_pojazdu: function() {
      const { Nazwa_wybor_pojazdu } = this.form;
      const znalezionyElement = this.RodzajePojazdow.find(
        ({ nazwa }) => nazwa === Nazwa_wybor_pojazdu
      );

      if (znalezionyElement) {
        return znalezionyElement.Id;
      }
      return null;
    },
    zapytaniaPojazd: function() {
      return this.RodzajePojazdow.map((el) => el.nazwa);
    },
    plikZwalidowany: function() {
      return this.form.plik_dane
        ? this.form.plik_dane.size < 2 * 1024 * 1024
        : true;
    },
        plikZwalidowany1: function() {
      return this.form.plik_dane_1
        ? this.form.plik_dane_1.size < 2 * 1024 * 1024
        : true;
    },
    czyJestWTrybieEdycji: function() {
      return this.obiektKonfiguracyjny.akcja === "edycja";
    },
  },

  methods: {
    zapamietajPlik(pliki) {
      if (pliki[0]) {
        this.form.plik_dane = pliki[0];
      }
    },
    zapamietajPlik_1(pliki_1) {
      if (pliki_1[0]) {
        this.form.plik_dane_1 = pliki_1[0];
      }
    },
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.Nr_rejestracyjny = null;
      this.form.Nazwa_maszyny = null;
      this.form.Numer_VIN = null;
      this.form.Status = null;
      this.form.Id_paliwo = null;
      this.form.Id_norma_emisji = null;
      this.form.Rok_produkcji = null;
      this.form.Osoba_odpowiedzialna = null;
      this.form.Data_przegladu = null;
      this.form.Data_nastepnego_przegladu = null;
      this.form.Przeglad_wykonal = null;
      this.form.Data_ubezpieczenia = null;
      this.form.Data_nastepnego_ubezpieczenia = null;
      this.form.Nr_polisy = null;
      this.form.Ubezpieczyciel = null;
      this.form.plik_PDF = null;
      this.form.plik_dane = null;
      this.form.plik_PDF_1 = null;
      this.form.plik_dane_1 = null;
    },

    zapiszFormularz() {
      this.sending = true;

      if (this.czyJestWTrybieEdycji) {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Nr_rejestracyjny", this.form.Nr_rejestracyjny);
        dane.append("Id_pojazdu", this.Id_pojazdu);
        dane.append("Nazwa_maszyny", this.form.Nazwa_maszyny);
        dane.append("Numer_VIN", this.form.Numer_VIN);
        dane.append("Status", this.form.Status);
        dane.append("Id_paliwo", this.form.Id_paliwo);
        dane.append("Id_norma_emisji", this.form.Id_norma_emisji);
        dane.append("Rok_produkcji", this.form.Rok_produkcji);
        dane.append("Osoba_odpowiedzialna", this.form.Osoba_odpowiedzialna);


        api
          .zapiszSamochoduOsobowegoUpdate(dane, this.IdEdytowanegoElementu)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Nr_rejestracyjny", this.form.Nr_rejestracyjny);
        dane.append("Id_pojazdu", this.Id_pojazdu);
        dane.append("Nazwa_maszyny", this.form.Nazwa_maszyny);
        dane.append("Numer_VIN", this.form.Numer_VIN);
        dane.append("Status", this.form.Status);
        dane.append("Id_paliwo", this.form.Id_paliwo);
        dane.append("Id_norma_emisji", this.form.Id_norma_emisji);
        dane.append("Rok_produkcji", this.form.Rok_produkcji);
        dane.append("Osoba_odpowiedzialna", this.form.Osoba_odpowiedzialna);
        dane.append("Data_przegladu", this.form.Data_przegladu);
        dane.append(
          "Data_nastepnego_przegladu",
          this.form.Data_nastepnego_przegladu
        );
        dane.append("Data_ubezpieczenia", this.form.Data_ubezpieczenia);
        dane.append(
          "Data_nastepnego_ubezpieczenia",
          this.form.Data_nastepnego_ubezpieczenia
        );
        dane.append("Przeglad_wykonal", this.form.Przeglad_wykonal);
        dane.append("Nr_polisy", this.form.Nr_polisy);
        dane.append("Ubezpieczyciel", this.form.Ubezpieczyciel);
        dane.append("plik_PDF", this.form.plik_dane);
        dane.append("plik_PDF_1", this.form.plik_dane_1);
        

        api
          .zapiszSamochodOsobowy(dane)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    naszWalidacjaFormularza() {
      if (this.czyJestWTrybieEdycji) {
        return (
          !this.$v.form.Nr_rejestracyjny.$invalid &&
          !this.$v.form.Nazwa_wybor_pojazdu.$invalid &&
          !this.$v.form.Nazwa_maszyny.$invalid &&
          !this.$v.form.Numer_VIN.$invalid &&
          !this.$v.form.Rok_produkcji.$invalid &&
          !this.$v.form.Status.$invalid &&
          !this.$v.form.Id_paliwo.$invalid &&
          !this.$v.form.Id_norma_emisji.$invalid &&
          !this.$v.form.Osoba_odpowiedzialna.$invalid &&
          this.plikZwalidowany 
        );
      } else {
        return !this.$v.$invalid && this.plikZwalidowany;
      }
    },
    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>

<style>
.Form_sam_osobowe .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.Form_sam_osobowe .md-layout .md-gutter {
  min-width: 55vw !important; /* szerokość modala*/
  max-width: 100% !important;
  min-height: 7vh; /* wysokość pomiędzy mierszami w modalu*/
}
.md-layout {
  padding-right: 0px !important;
}
.Form_sam_osobowe .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}

.Form_sam_osobowe .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  margin: 10px 24px !important;
  padding-top: 35px !important;
}
.Form_sam_osobowe .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}
.Form_sam_osobowe {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
  overflow: auto !important;
}
.Form_sam_osobowe .md-primary {
  border: 1px solid !important;

  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}
.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important; /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}

.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}
.md-list-item-text {
  font-size: 1.7vmin !important;
}
.Form_sam_osobowe .md-card-actions .md-button {
  margin-right: 50px !important;
}
</style>
