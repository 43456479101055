<template >
  <div>
  <TabelaRozliczenieWZMagazyn/>
  </div>
</template>

<script>
import TabelaRozliczenieWZMagazyn from '../../komponenty/strona-tabela-komponenty/SO_zlecenia_magazyn_zrealizowane/tabela_wykaz_zlecen_magazyn_zrealizowanych.vue'


export default {
  name: 'StronaTabelaRozliczenieWZMagazyn',
  components: {
    TabelaRozliczenieWZMagazyn,
    
  }

}
</script>

