<template >
  <div>
  <TabelaWykazPracownicyZwolnieni />
  </div>
</template>

<script>
import TabelaWykazPracownicyZwolnieni from '../../komponenty/strona-tabela-komponenty/HR_pracownicy/tabela_wykaz_pracownikow_zwolnionych.vue'


export default {
  name: 'StronaTabelaWykazPracownikowZwolnionych',
  components: {
    TabelaWykazPracownicyZwolnieni,
    
  }

}
</script>

