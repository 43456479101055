<template>
  <div>
  <TabelaITKoszty />
  </div>
</template>

<script>
import TabelaITKoszty from '../../komponenty/strona-tabela-komponenty/WST_wyposazenie_IT/tabela_IT_koszty.vue'


export default {
  name: 'StronaTabelaITKoszty',
  components: {
    TabelaITKoszty,
  }

}
</script>
