<template >
  <div>
  <TabelaPrzegladZarzadzania />
  </div>
</template>

<script>
import TabelaPrzegladZarzadzania from '../../komponenty/strona-tabela-komponenty/SYS_przeglad_zarzadzania/tabela_przeglad_zarzadzania.vue'


export default {
  name: 'StronaTabelaPrzegladZarzadzania',
  components: {
    TabelaPrzegladZarzadzania,
    
  }

}
</script>

