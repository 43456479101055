<template>
  <div class="FORM_RSP">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI PRZEGLĄDU WYKAZU PRAC SZCZEGÓLNIE NIEBEZPIECZNYCH"
                : "FORMULARZ DODAWANIA PRZEGLĄDU WYKAZU PRAC SZCZEGÓLNIE NIEBEZPIECZNYCH"
            }}
          </div>
        </md-card-header>

        <md-card-content>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="MP2"
                :class="getValidationClass('Data_przegladu')"
              >
                <label for="Data_przegladu">Data przeglądu</label>
                <md-input
                  type="date"
                  name="Data_przegladu"
                  id="Data_przegladu"
                  autocomplete="Data_przegladu"
                  v-model="form.Data_przegladu"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Data_przegladu.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field
                class="MP2"
                :class="getValidationClass('Data_nastepnego_przegladu')"
              >
                <label for="Data_nastepnego_przegladu"
                  >Data następnego przeglądu</label
                >
                <md-input
                  type="date"
                  id="Data_nastepnego_przegladu"
                  name="Data_nastepnego_przegladu"
                  autocomplete="Data_nastepnego_przegladu"
                  v-model="form.Data_nastepnego_przegladu"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Data_nastepnego_przegladu.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="MP1"
                :class="getValidationClass('Osoba_odpowiedzialna')"
              >
                <label for="Osoba_odpowiedzialna">Osoba odpowiedzialna</label>
                <md-input
                  name="Osoba_odpowiedzialna"
                  id="Osoba_odpowiedzialna"
                  
                  md-counter="50"
                  v-model="form.Osoba_odpowiedzialna"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Osoba_odpowiedzialna.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Osoba_odpowiedzialna.maxlength"
                  >Wymagana ilość znaków od 1 do 50</span
                >
              </md-field>
            </div>
          </div>

           <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="MP1"
                :class="getValidationClass('Uwagi')"
              >
                <label for="Uwagi">Stwierdzone uwagi</label>
                <md-textarea
                  name="Uwagi"
                  id="Uwagi"
                  autocomplete="Uwagi"
                  md-autogrow
                  md-counter="500"
                  v-model="form.Uwagi"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Uwagi.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Uwagi.maxlength"
                  >Wymagana ilość znaków od 1 do 500</span
                >
              </md-field>
            </div>
          </div> 
        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

       <div style="text-align: center">
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            czyJestWTrybieEdycji
              ? "ZATWIERDŹ - EDYTOWANY WPIS"
              : "ZATWIERDŹ - UTWÓRZ WPIS"
          }}</md-button>
        </div>
      </md-card>
    </form>
  </div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import minLength from "vuelidate/lib/validators/minLength";
import { api } from "../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_przegladu_maszyny",
  
  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      Id: null,
      Data_przegladu: null,
      Data_nastepnego_przegladu: null,
      Osoba_odpowiedzialna: null,
      Uwagi: null,
    },
    IdEdytowanegoElementu: null,
    nazwaPliku: null,
    userSaved: false,
    sending: false,
    lastUser: null,
  }),

  created: function () {
    if (this.obiektKonfiguracyjny.akcja === "edycja") {
      //edycja
      api
        .pobieranieDokumentacjaPSZNIEBEZPrzegladEdycja(this.obiektKonfiguracyjny.Id)
        .then((odpowiedz) => {
          const {
            Id,
            Data_przegladu,
            Data_nastepnego_przegladu,
            Osoba_odpowiedzialna,
            Uwagi,
          } = odpowiedz.data.dane[0];
          this.IdEdytowanegoElementu = Id;
          this.form.Data_przegladu = Data_przegladu;
          this.form.Data_nastepnego_przegladu = Data_nastepnego_przegladu;
          this.form.Osoba_odpowiedzialna = Osoba_odpowiedzialna;
          this.form.Uwagi = Uwagi;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  validations: {
    form: {

      Data_przegladu: {
        required,
      },
      Data_nastepnego_przegladu: {
        required,
      },
      Osoba_odpowiedzialna: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(50),
      },
      Uwagi: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(500),
      },
    },
  },
  computed: {

    czyJestWTrybieEdycji: function () {
      return this.obiektKonfiguracyjny.akcja === "edycja";
    },
  },
  
  methods: {

    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.Data_przegladu = null;
      this.form.Data_nastepnego_przegladu = null;
      this.form.Osoba_odpowiedzialna = null;
      this.form.Uwagi = null;
    },

    zapiszFormularz() {
      this.sending = true;

      if (this.czyJestWTrybieEdycji) {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Data_przegladu", this.form.Data_przegladu);
        dane.append(
          "Data_nastepnego_przegladu",
          this.form.Data_nastepnego_przegladu
        );
        dane.append("Osoba_odpowiedzialna", this.form.Osoba_odpowiedzialna);
        dane.append("Uwagi", this.form.Uwagi);

        api
          .zapiszDokumentacjaPSZNIEBEZPrzegladUpdate(dane, this.IdEdytowanegoElementu)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const dane = new FormData();
        dane.append("Data_przegladu", this.form.Data_przegladu);
        dane.append(
          "Data_nastepnego_przegladu",
          this.form.Data_nastepnego_przegladu
        );
        dane.append("Osoba_odpowiedzialna", this.form.Osoba_odpowiedzialna);
         dane.append("Uwagi", this.form.Uwagi);
        
        api
        .zapiszDokumentacjaPSZNIEBEZPrzeglad(dane)
        .then(() => {
          this.clearForm();
          this.sending = false;
          this.funkcjaZamykajacaDialog(true);
        })
        .catch((error) => {
          console.log(error);
        });
      }
     },

    naszWalidacjaFormularza() {
      if (this.czyJestWTrybieEdycji) {
        return !this.$v.form.Data_przegladu.$invalid &&
         !this.$v.form.Data_nastepnego_przegladu.$invalid &&
         !this.$v.form.Osoba_odpowiedzialna.$invalid &&
         !this.$v.form.Uwagi.$invalid
      } else {
        return !this.$v.$invalid;
      }
    },
    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>

<style>
.FORM_RSP .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.FORM_RSP .md-layout .md-gutter {
  min-width: 55vw !important; /* szerokość modala*/
  max-width: 100% !important;
  min-height: 7vh; /* wysokość pomiędzy mierszami w modalu*/
}
.md-layout {
  padding-right: 0px !important;
}
.FORM_RSP .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}

.FORM_RSP .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  margin: 10px 24px !important;
  padding-top: 35px !important;
}
.FORM_RSP .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}

.FORM_RSP {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
}
.FORM_RSP .md-primary {
  border: 1px solid !important;

  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}
.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important; /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}

.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}
.FORM_RSP .md-list-item-text {
  font-size: 1.7vmin !important;
}
.FORM_RSP .md-card-actions .md-button {
  margin-right: 50px !important;
}
</style>