<template >
  <div>
  <TabelaInstrukcjeBHP />
  </div>
</template>

<script>
import TabelaInstrukcjeBHP from '../../komponenty/strona-tabela-komponenty/SYS_instrukcje_BHP/tabela_instrukcje_BHP.vue'


export default {
  name: 'StronaTabelaInstrukcjeBHP',
  components: {
    TabelaInstrukcjeBHP,
    
  }

}
</script>

