<template>
  <div>
  <TabelaSamochodyOsobowePrzeglady />
  </div>
</template>

<script>
import TabelaSamochodyOsobowePrzeglady from '../../komponenty/strona-tabela-komponenty/WST_samochody_osobowe/tabela_samochody_osobowe_przeglady.vue'


export default {
  name: 'StronaTabelaSamochodyOsobowePrzeglady',
  components: {
    TabelaSamochodyOsobowePrzeglady,
  }

}
</script>
