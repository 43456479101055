<template >
  <div>
  <TabelaWykazPracownicy />
  </div>
</template>

<script>
import TabelaWykazPracownicy from '../../komponenty/strona-tabela-komponenty/HR_pracownicy/tabela_wykaz_pracownikow.vue'


export default {
  name: 'StronaTabelaWykazPracownikow',
  components: {
    TabelaWykazPracownicy,
    
  }

}
</script>

