<template>
  <div class="a3_cele_strategiczne" style="border:1px solid;margin-top:1vh;">
    <div class="tabela_CS">
      <div class="laczacy">
        <div class="menu_strony">
          <span>
            <button
              class="button"
              :disabled="!autoryzacja.czyMozeDodawac"
              @click="
                pokazDialog = true;
                typAktywnegoFormularza =
                  'formularz_dodawanie_celow_strategicznych_procesu';
                obiektKonfiguracyjny = {
                  Id: null,
                  akcja: 'dodawanie',
                };
              "
            >
              DODAWANIE CELÓW STRATEGICZNYCH DLA PROCESU
            </button>
            <md-tooltip class="podpowiedz" md-direction="top">{{
              !autoryzacja.czyMozeDodawac
                ? "BRAK UPRAWNIEŃ"
                : "DODAWANIE CELÓW STRATEGICZNYCH DLA PROCESU"
            }}</md-tooltip>
          </span>
        </div>
        <div class="menu_strony1">
          <b>
            <b-icon-printer-fill
              class="ikony"
              font-scale="2.1"
              color="white"
              @click="generujPdf"
            >
            </b-icon-printer-fill>
            <md-tooltip class="podpowiedz" md-direction="top"
              >GENERUJ PDF / DRUKUJ</md-tooltip
            >
          </b>

          <b>
            <b-icon-info-circle-fill
              class="ikony"
              font-scale="2.1"
              color="white"
              @click="pokazPDF_INFO"
            >
            </b-icon-info-circle-fill>
            <md-tooltip class="podpowiedz" md-direction="top"
              >GENERUJ PDF / DRUKUJ</md-tooltip
            >
          </b>
        </div>
      </div>
      <modal
        v-if="pokazDialog"
        :rodzajFormularza="typAktywnegoFormularza"
        :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
        :obiektKonfiguracyjny="obiektKonfiguracyjny"
      />
      <modalKontekst
        v-if="pokazDialog"
        :rodzajFormularza="typAktywnegoFormularza"
        :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
        :obiektKonfiguracyjny="obiektKonfiguracyjny"
      />
      <md-table
        md-height="20vh"
        @md-selected="wyborElementu"
        v-model="searched"
        md-model-id="Id_maszyny"
        md-card
        md-fixed-header
      >
        <md-table-toolbar>
          <div class="d-flex flex-column w-100">
            <div class="row">
              <div class="col-12">
                <h1 class="md-title">
                  CELE STRATEGICZNE DLA PROCESU
                </h1>
              </div>
            </div>

            <div class="PasekWyboru" v-if="wybranyElementTabeli">
              <div
                style="text-align: right; margin-top: 0.5vh; margin-bottom: 0.5vh"
              >
                <b>
                  <b-button
                    :disabled="!autoryzacja.czyMozeUsuwac"
                    @click="usuwanieElementu(wybranyElementTabeli.Id)"
                  >
                    <b-icon-trash class="ikony" font-scale="1.8" color="white">
                    </b-icon-trash
                  ></b-button>
                  <md-tooltip class="podpowiedz" md-direction="top">{{
                    autoryzacja.czyMozeUsuwac
                      ? "USUWANIE ELEMENTU"
                      : "BRAK UPRAWNIEŃ"
                  }}</md-tooltip>
                </b>

                <b>
                  <b-button
                    :disabled="!autoryzacja.czyMozeEdytowac"
                    @click="
                      pokazDialog = true;
                      typAktywnegoFormularza =
                        'formularz_dodawanie_celow_strategicznych_procesu';
                      obiektKonfiguracyjny = {
                        Id: wybranyElementTabeli.Id,
                        akcja: 'edycja',
                      };
                    "
                  >
                    <b-icon-pencil-square
                      class="ikony"
                      font-scale="1.8"
                      color="white"
                    >
                    </b-icon-pencil-square>
                    <md-tooltip class="podpowiedz" md-direction="top"
                      >EDYCJA ELEMENTU</md-tooltip
                    >
                  </b-button>
                  <md-tooltip class="podpowiedz" md-direction="top">{{
                    autoryzacja.czyMozeUsuwac
                      ? "EDYCJA ELEMENTU"
                      : "BRAK UPRAWNIEŃ"
                  }}</md-tooltip>
                </b>

                <b
                  ><b-button>
                    <b-icon-bounding-box
                      class="ikony"
                      font-scale="1.8"
                      color="white"
                      @click="
                        $router.push(
                          `../procesy_rozliczenie_celu/${wybranyElementTabeli.Id}`
                        )
                      "
                    >
                    </b-icon-bounding-box
                  ></b-button>
                  <md-tooltip class="podpowiedz" md-direction="top"
                    >ROZLICZENIE CELU STRATEGICZNEGO</md-tooltip
                  >
                </b>
              </div>
            </div>
          </div>
        </md-table-toolbar>

        <md-table-empty-state
          v-if="czyPobranoDane"
          md-label="Nie posiadasz wpisów w wykazie celów strategicznych dla procesu"
        >
          <md-button
            class="md-primary md-raised"
            :disabled="!autoryzacja.czyMozeDodawac"
            @click="
              pokazDialog = true;
              typAktywnegoFormularza =
                'formularz_dodawanie_celow_strategicznych_procesu';
              obiektKonfiguracyjny = {
                Id: null,
                akcja: 'dodawanie',
              };
            "
            >Dodawanie celu strategicznego dla procesu</md-button
          >
          <md-tooltip class="podpowiedz" md-direction="top">{{
            !autoryzacja.czyMozeDodawac
              ? "BRAK UPRAWNIEŃ"
              : "DODAWANIECELÓW STRATEGICZNYCH DLA PROCESU"
          }}</md-tooltip>
        </md-table-empty-state>

        <md-table-row
          slot="md-table-row"
          slot-scope="{ item }"
          md-selectable="single"
          :key="item.id"
        >
          <md-table-cell md-label="Lp">
            {{ item.Lp }}
          </md-table-cell>

          <md-table-cell md-label="Nr procesu">
            {{ item.Nr_procesu }}
          </md-table-cell>

          <md-table-cell md-label="Cel">
            {{ item.Cel }}
          </md-table-cell>

          <md-table-cell md-label="Opis realizacji">
            {{ item.Opis }}
          </md-table-cell>

          <md-table-cell md-label="Wskaźnik">
            {{ item.Wskaznik }}
          </md-table-cell>

          <md-table-cell md-label="Wartosc oczekiwana">
            {{ item.Wartosc_oczekiwana }}
          </md-table-cell>

          <md-table-cell md-label="Okres rozliczeniowy">
            {{ item.Okres }}
          </md-table-cell>

          <md-table-cell md-label="Okres rozliczeniowy">
            {{ item.Data_oceny }}
          </md-table-cell>

        </md-table-row>
      </md-table>
    </div>
  </div>
</template>

<script>
import modal from "../../strona-formularze/modal.vue";
import modalKontekst from "../../strona-formularze/modalKontekst.vue";
import { authorizationService } from "./../../../serwisy/authorization.servis";
import { api } from "./../../../serwisy/api.serwis";
import { pdfSerwis } from "./../../../serwisy/pdf.serwis";

export default {
  name: "tabela_elektronarzedzia",
  data: () => ({
    czyPobranoDane: false,
    search: null,
    searched: [],
    Maszyny: [],
    pokazDialog: false,
    typAktywnegoFormularza: null,
    obiektKonfiguracyjny: null,
    wybranyElementTabeli: null,
    autoryzacja: {
      czyMozeDodawac: false,
      czyMozeUsuwac: false,
      czyMozeEdytowac: false,
      czyMozePrzegladac: false,
    },
  }),
  components: {
    modal,
    modalKontekst,
  },

  mounted: function() {
    this.pobierzDaneDoTabeli();
    this.pobierzDaneAutoryzacyjne();
  },
  computed: {
    //informacja czy jest w trybie edycji
    czyJestWTrybieEdycji() {
      return this.$router.params.Id ? true : false;
    },
  },
  methods: {
    pobierzDaneAutoryzacyjne() {
      authorizationService
        .getInfo("StronaTabelaProcesyDaneWejsciowe", 4)

        .then((obiektOdpowiedzi) => {
          const dane = obiektOdpowiedzi.data.dostep;
          this.autoryzacja = {
            czyMozeDodawac: Boolean(
              dane.find(({ Id_operacji }) => Id_operacji == "1")
            ),
            czyMozeUsuwac: Boolean(
              dane.find(({ Id_operacji }) => Id_operacji == "2")
            ),
            czyMozeEdytowac: Boolean(
              dane.find(({ Id_operacji }) => Id_operacji == "3")
            ),
            czyMozePrzegladac: Boolean(
              dane.find(({ Id_operacji }) => Id_operacji == "4")
            ),
          };
        })
        .catch((error) => {
          console.log(error);
        });
    },

    generujPdf() {
      const { id } = this.$route.params;
      const idiki_celow = this.Maszyny.map((maszyna) => maszyna.Id);
      pdfSerwis.generujInformacjeOProcesie(id, idiki_celow);
    },
    pobierzDaneDoTabeli() {
      const { id } = this.$route.params;

      console.log(this.$route);
      api
        .pobieranieProcesuCeleStrategiczne(id)
        .then((obiektOdpowiedzi) => {
          console.log(obiektOdpowiedzi);
          this.Maszyny = obiektOdpowiedzi.data.dane.map((maszyna, index) => ({
            ...maszyna,
            Lp: index + 1,
          }));
          this.searched = this.Maszyny;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.czyPobranoDane = true;
        });
    },
    funkcjaZamykajacaDialog(sukces) {
      this.pokazDialog = false;
      this.typAktywnegoFormularza = null;
      this.obiektKonfiguracyjny = null;
      if (sukces) {
        this.pobierzDaneDoTabeli();
      }
    },
    newUser() {
      window.alert("Noop");
    },

    usuwanieElementu(Id) {
      this.pokazDialog = true;
      this.typAktywnegoFormularza = "usuwanie";
      this.obiektKonfiguracyjny = {
        Id,
        typ: "Cele_strategiczne",
      };
    },

    pokazPDF_INFO() {
      //dopisane
      this.pokazDialog = true;
      this.typAktywnegoFormularza = "pokaz_PDF_INFO";
      this.obiektKonfiguracyjny = {
        Nazwa_instrukcji: "INFO-3",
      };
    },

    wyborElementu(element) {
      this.wybranyElementTabeli = element;
    },
  },
};
</script>

<style>
.tabela_CS .md-field {
  max-width: 300px;
}
.tabela_CS {
  width: 98%;
  margin-left: 1%;
  margin-right: 1%;
}
.tabela_CS .md-card {
  box-shadow: none !important;
}
.tabela_CS .md-title {
  font-weight: 900 !important;
  color: black !important;
  font-size: 1.4vh !important;
}
.tabela_CS .md-toolbar {
  height: 6vh;
}
.tabela_CS .md-table-head {
  font-weight: bold;
  color: black !important;
  font-size: 1.1vh !important;
  text-align: left !important;
}
.tabela_CS .md-table-head-label {
  text-align: right !important;
  font-size: 1.1vh !important;
}
.tabela_CS .md-table-cell {
  font-size: 1.1vh !important;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-align: left;
  color: black;
  font-weight: 300;
  margin-top: 200px !important;
}
.tabela_CS .ikony {
  cursor: pointer;
  margin-right: 40px;
}

.tabela_CS .md-table-cell:nth-child(1) {
  width: 7vw;
  text-align: left !important;
}
.tabela_CS .md-table-cell:nth-child(2) {
  width: 12vw;
  text-align: left !important;
}
.tabela_CS .md-table-cell:nth-child(3) {
  width: 13vw;
  text-align: left !important;
}
.tabela_CS .md-table-cell:nth-child(4) {
  width: 13vw;
  text-align: left !important;
}
.tabela_CS .md-table-cell:nth-child(5) {
  width: 13vw;
  text-align: left !important;
}
.tabela_CS .md-table-cell:nth-child(6) {
  width: 13vw;
  text-align: left !important;
}
.tabela_CS .md-table-cell:nth-child(7) {
  width: 13vw;
  text-align: left !important;
}
.tabela_CS .md-table-cell:nth-child(8) {
  width: 11vw;
  text-align: left !important;
}
.tabela_CS .button {
  margin-right: 3px;
  border: 3px white ridge;
  font-weight: bold;
  font-size: 1vmin;
  min-width: 7vw;
}
.tabela_CS .button:hover {
  background-color: #727171;
  text-decoration: none;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  color: white;
}
.tabela_CS .menu_strony {
  border: 1px solid;
  margin-top: 5px;
  padding: 3px;
  background: #939393;
  width: 84.5%;
}
.tabela_CS .menu_strony1 {
  border: 1px solid;
  margin-top: 5px;
  padding: 3px;
  background: #939393;
  width: 15%;
  margin-left: 0.5%;
}
.tabela_CS .laczacy {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.md-table.md-theme-default .md-table-row.md-selected,
.md-table.md-theme-default .md-table-row.md-selected-single {
  background-color: #dddddd !important;
}
.PasekWyboru {
  background: #606060;
}
.tabela_CS .ikony {
  margin-right: 4px;
  margin-left: 4px;
  border: 1px solid !important;
  border-radius: 5px;
  padding: 5px;
}
.tabela_CS .ikony:hover {
  background-color: black;
  text-decoration: none;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
.podpowiedz {
  font-size: 1.5vmin !important;
  font-weight: bold;
  background-color: black !important;
}
.tabela_CS .md-empty-state-label {
  font-size: 1.5vh !important;
  padding: 0 !important;
}
.tabela_CS .md-empty-state {
  padding: 3px !important;
}
.tabela_CS .md-button-content {
  font-size: 1.5vh !important;
}
.tabela_CS .md-button {
  height: auto !important;
  padding: 2px !important;
}
.btn {
  background-color: #606060 !important;
  padding: 0rem 0rem !important;
  border-color: #606060 !important;
}
</style>
