<template>
  <router-link :to="linkDoStrony" class="kafelek">
    <img id="zdjecie" :src="sciezkaZdjecia" :alt="opisZdjecia" />
    <p id="tytul">{{ podpisPodKafelkiem }}</p>
  </router-link>
</template>

<script>
export default {
  name: "KafelekWyboru",
  props: {
    sciezkaZdjecia: {
      type: String,
      required: true,
    },
    opisZdjecia: {
      type: String,
      required: true,
    },
    linkDoStrony: {
      type: String,
      required: true,
    },
    podpisPodKafelkiem: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.kafelek {
  width: 100%;
  height: 90%;
  padding-top: 10px;
  display: block;
  border: 3px ridge silver;
  margin: 15px 0px 15px;
  border-radius: 10px;
  background-color: #f5f5f5;
  float: center;
  flex: auto;
  line-height: 100%;
}
.kafelek:hover {
  background-color: #eee4e4;
  text-decoration: none;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
#zdjecie {
  padding-top: 5%;
  width: 70%;
  height: 80%;
}
#tytul {
  font-size: 1.4vh;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  color: black;
  font-weight: bold;
  margin-top: 0.5vh;
  line-height: 1.1;
}
</style>
