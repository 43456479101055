<template>
  <div>
  <TabelaBudynkiPrzeglad5lat/>
  </div>
</template>

<script>
import TabelaBudynkiPrzeglad5lat from '../../komponenty/strona-tabela-komponenty/WST_budynki/tabela_budynki_przeglady_5lat.vue'


export default {
  name: 'StronaTabelaBudynkiPrzeglad5lat',
  components: {
    TabelaBudynkiPrzeglad5lat,
  }

}
</script>
