<template >
  <div>
  <TabelaWykazKontroliZewnetrznychRealizacja />
  </div>
</template>

<script>
import TabelaWykazKontroliZewnetrznychRealizacja from '../../komponenty/strona-tabela-komponenty/BHP_kontrole_zewnetrzne/tabela_dzialan_do_uwag_kontroli_zewnetrznych.vue'


export default {
  name: 'StronaTabelaWykazKontroliZewnetrznychRealizacja',
  components: {
    TabelaWykazKontroliZewnetrznychRealizacja,
    
  }

}
</script>

