var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tabela_LOG"},[_c('div',{staticClass:"laczacy"},[_c('div',{staticClass:"menu_strony"},[_c('span',[_c('button',{staticClass:"button",on:{"click":function($event){_vm.pokazDialog = true;
          _vm.typAktywnegoFormularza = 'formularz_dodawanie_uzytkownika';
          _vm.obiektKonfiguracyjny = {
            Id: null,
            akcja: 'dodawanie',
            iloscOfert: _vm.Oferty.length,
          };}}},[_vm._v(" DODAWANIE UPRWNIEŃ DLA NOWEGO PRACOWNIKA ")])])]),_c('div',{staticClass:"menu_strony1"},[_c('b',[_c('b-icon-info-circle-fill',{staticClass:"ikony",attrs:{"font-scale":"2.1","color":"white"},on:{"click":_vm.pokazPDF_INFO}}),_c('md-tooltip',{staticClass:"podpowiedz",attrs:{"md-direction":"top"}},[_vm._v("GENERUJ PDF / DRUKUJ")])],1)])]),(_vm.pokazDialog)?_c('modalHR',{attrs:{"rodzajFormularza":_vm.typAktywnegoFormularza,"funkcjaZamykajacaDialog":_vm.funkcjaZamykajacaDialog,"obiektKonfiguracyjny":_vm.obiektKonfiguracyjny}}):_vm._e(),_c('div',[_c('DataTable',{attrs:{"value":_vm.searched,"expandedRows":_vm.expandedRows,"dataKey":"Id","selection":_vm.wybranyElementTabeli,"selectionMode":"single","metaKeySelection":false},on:{"update:expandedRows":function($event){_vm.expandedRows=$event},"update:expanded-rows":function($event){_vm.expandedRows=$event},"row-expand":_vm.onRowExpand,"row-collapse":_vm.onRowCollapse,"update:selection":function($event){_vm.wybranyElementTabeli=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',[_c('h4',[_vm._v("WYKAZ UPRAWNIEŃ DOSTĘPU")]),_c('md-input',{attrs:{"placeholder":"Szukaj..."},on:{"input":_vm.searchOnTable},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.wybranyElementTabeli)?_c('div',{staticClass:"PasekWyboru"},[_c('div',{staticStyle:{"text-align":"right","margin-top":"0.3vh","margin-bottom":"0.3vh"}},[_c('b',[_c('b-icon-trash',{staticClass:"ikony",attrs:{"font-scale":"1.8","color":"white"},on:{"click":function($event){return _vm.usuwanieElementu(_vm.wybranyElementTabeli.Id)}}}),_c('md-tooltip',{staticClass:"podpowiedz",attrs:{"md-direction":"top"}},[_vm._v("USUWANIE ELEMENTU")])],1),_c('b',[_c('b-icon-pencil-square',{staticClass:"ikony",attrs:{"font-scale":"1.8","padding":"10px","color":"white"},on:{"click":function($event){_vm.pokazDialog = true;
                    _vm.typAktywnegoFormularza = 'formularz_dodawanie_uzytkownika';
                    _vm.obiektKonfiguracyjny = {
                      Id: _vm.wybranyElementTabeli.Id,
                      akcja: 'edycja',
                    };}}}),_c('md-tooltip',{staticClass:"podpowiedz",attrs:{"md-direction":"top"}},[_vm._v("EDYCJA ELEMENTU")])],1)])]):_vm._e()],1)]},proxy:true},{key:"expansion",fn:function(slotProps){return [_c('div',{staticClass:"orders-subtable"},[_c('h6',{staticStyle:{"color":"blue","text-align":"left"}},[_vm._v(" SZCZEGÓŁOWE DANE DOSTĘPU DLA PRACOWNIKA ")]),_c('DataTable',{staticStyle:{"height":"33vh","border":"1px solid","overflow":"auto"},attrs:{"value":slotProps.data.Szczegoly}},[_c('Column',{attrs:{"field":"Nazwa","headerStyle":"color:blue; width:15%; font-size:1.1vh; text-align:left","header":"MODUŁ - CZĘŚĆ APLIKACJI","sortable":""}}),_c('Column',{attrs:{"field":"Opis","headerStyle":"color:blue; width:77%; font-size:1.1vh; text-align:left","header":"Opis","sortable":""}}),_c('Column',{attrs:{"headerStyle":"color:blue; width:8%; font-size:1.1vh; text-align:left","header":"Opcje"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_c('b',[_c('b-icon-arrow-right-square',{attrs:{"font-scale":"1.8","color":"black","cursor":"pointer"},on:{"click":function($event){return _vm.$router.push(
                        ("../tabela_dostepu_szczegoly/" + (slotProps.data.Id_uzytkownika) + "/" + (slotProps.data.Id_typu))
                      )}}}),_c('md-tooltip',{staticClass:"podpowiedz",attrs:{"md-direction":"top"}},[_vm._v("MODUŁY")])],1)]}}],null,true)})],1)],1)]}}])},[_c('Column',{attrs:{"expander":true,"headerStyle":"width: 5%"}}),_c('Column',{attrs:{"field":"Lp1","headerStyle":"width:5%; font-size:1.2vh; text-align:left","header":"Lp"}}),_c('Column',{attrs:{"field":"Nazwisko","headerStyle":"width:14%; font-size:1.2vh; text-align:left","header":"Nazwisko","sortable":""}}),_c('Column',{attrs:{"field":"Imie","headerStyle":"width:14%; font-size:1.2vh; text-align:left","header":"Imię","sortable":""}}),_c('Column',{attrs:{"field":"NIP","headerStyle":"width:14%; font-size:1.2vh; text-align:left","header":"Nr NIP","sortable":""}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }