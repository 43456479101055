<template >
  <div>
  <TabelaWykazPracownicyUzupelnienie />
  </div>
</template>

<script>
import TabelaWykazPracownicyUzupelnienie from '../../komponenty/strona-tabela-komponenty/HR_pracownicy/tabela_wykaz_pracownikow_uzupelnienie.vue'


export default {
  name: 'StronaTabelaWykazPracownikowUzupelnienie',
  components: {
    TabelaWykazPracownicyUzupelnienie,
    
  }

}
</script>

