<template>
  <div class="Form_dodawSkierow">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI SKIEROWANIA NA BADANIA LEKARSKIE"
                : "FORMULARZ DODAWANIA SKIEROWANIA NA BADANIA LEKARSKIE"
            }}
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-autocomplete
                :class="getValidationClass('Nazwa_wybor_pracownika')"
                v-model="form.Nazwa_wybor_pracownika"
                :md-options="zapytaniaPracownicy"
                :disabled="sending || czyJestWTrybieEdycji"
              >
                <label>Wybór pracownika</label>
                <span
                  class="md-error"
                  v-if="!$v.form.Nazwa_wybor_pracownika.required"
                  >Pole jest wymagane</span
                >
              </md-autocomplete>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-autocomplete
                :class="getValidationClass('Nazwa_wybor_badan')"
                v-model="form.Nazwa_wybor_badan"
                :md-options="zapytaniaBadania"
                :disabled="sending || czyJestWTrybieEdycji"
              >
                <label>Wybór rodzaju badań</label>
                <span
                  class="md-error"
                  v-if="!$v.form.Nazwa_wybor_badan.required"
                  >Pole jest wymagane</span
                >
              </md-autocomplete>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-autocomplete
                :class="getValidationClass('Nazwa_wybor_status_badan')"
                v-model="form.Nazwa_wybor_status_badan"
                :md-options="zapytaniaStatusBadania"
                :disabled="sending || czyJestWTrybieEdycji"
              >
                <label>Wybór statusu badań</label>
                <span
                  class="md-error"
                  v-if="!$v.form.Nazwa_wybor_status_badan.required"
                  >Pole jest wymagane</span
                >
              </md-autocomplete>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M5"
                :class="getValidationClass('Miejscowosc_sk')"
              >
                <label for="Miejscowosc_sk">Miejscowość</label>
                <md-input
                  name="Miejscowosc_sk"
                  id="Miejscowosc_sk"
                  md-counter="50"
                  autocomplete="Miejscowosc_sk"
                  v-model="form.Miejscowosc_sk"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Miejscowosc_sk.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Miejscowosc_sk.maxlength"
                  >Wymagana ilość znaków od 1 do 50</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('Data')">
                <label for="Data">Data skierowania</label>
                <md-input
                  type="date"
                  name="Data"
                  id="Data"
                  autocomplete="Data"
                  v-model="form.Data"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Data.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-autocomplete
                :class="getValidationClass('Nazwa_wybor_stanowiska')"
                v-model="form.Nazwa_wybor_stanowiska"
                :md-options="zapytaniaStanowiska"
                :disabled="sending || czyJestWTrybieEdycji"
              >
                <label>Wybór stanowiska</label>
                <span
                  class="md-error"
                  v-if="!$v.form.Nazwa_wybor_stanowiska.required"
                  >Pole jest wymagane</span
                >
              </md-autocomplete>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M5"
                :class="getValidationClass('Opis_stanowiska')"
              >
                <label for="Opis_stanowiska">Opis stanowiska pracy</label>
                <md-textarea
                  md-autogrow
                  md-counter="1000"
                  name="Opis_stanowiska"
                  id="Opis_stanowiska"
                  autocomplete="Opis_stanowiska"
                  v-model="form.Opis_stanowiska"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Opis_stanowiska.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Opis_stanowiska.maxlength"
                  >Wymagana ilość znaków od 1 do 1000</span
                >
              </md-field>
            </div>
          </div>

          <div
            style="
              margin-left: 23px;
              margin-top: 20px;
              font-weight: bold;
              font-size: 16px;
            "
          >
            ZAGROŻENIA
          </div>
          <div
            style="
              margin-left: 23px;
              margin-top: 10px;
              font-weight: bold;
              color: red;
            "
          >
            UWAGA: W przypadku braku występowania danego zagrożenia należy
            wpisać "NIE DOTYCZY"
          </div>

          <div
            class="aa"
            v-for="(wiersz, index) in form.wiersze"
            :key="index"
            :set="(v = $v.form.wiersze.$each[index])"
          >
            <div
              style="
                color: blue;
                font-weight: bold;
                font-size: 1.5vh;
                margin-left: 25px;
                margin-top: 10px;
              "
            >
              {{ `POZYCJA NR: ${index + 1} ` }}

              <b-icon-plus-square
                class="ikony"
                font-scale="1.4"
                style="border: 1px black"
                color="black"
                @click="dodajWiersz()"
              >
              </b-icon-plus-square>

              <b-icon-trash
                class="ikony"
                font-scale="1.4"
                color="black"
                @click="usunWiersz(wiersz)"
              >
              </b-icon-trash>
            </div>
            
            <div class="md-layout md-gutter">
              <div class="md-layout-item md-small-size-100">
                <md-autocomplete
                  :class="getValidationClass('Nazwa_wybor_zagrozenia', index)"
                  v-model="wiersz.Nazwa_wybor_zagrozenia"
                  :md-options="zapytaniaZagrozenia"
                  :key="index"
                  :disabled="sending"
                >
                  <label>Wybór zagrożenia</label>
                  <span
                    class="md-error"
                    v-if="!v.Nazwa_wybor_zagrozenia.required"
                    >Pole jest wymagane</span
                  >
                </md-autocomplete>
              </div>
            </div>

            <div class="md-layout md-gutter">
              <div class="md-layout-item md-small-size-100">
                <md-field
                  class="M5"
                  :class="getValidationClass('Opis_zagrozenia', index)"
                >
                  <label for="Opis_zagrozenia">Opis zagrożenia</label>
                  <md-textarea
                    md-autogrow
                    md-counter="1000"
                    name="Opis_zagrozenia"
                    id="Opis_zagrozenia"
                    autocomplete="Opis_zagrozenia"
                    v-model="wiersz.Opis_zagrozenia"
                    :disabled="sending"
                  />
                  <span class="md-error" v-if="!v.Opis_zagrozenia.required"
                    >Pole jest wymagane</span
                  >
                  <span
                    class="md-error"
                    v-else-if="!v.Opis_zagrozenia.maxlength"
                    >Wymagana ilość znaków od 1 do 1000</span
                  >
                </md-field>
              </div>
            </div>
          </div>
        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

        <div style="text-align: center">
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            czyJestWTrybieEdycji
              ? "ZATWIERDŹ - EDYTOWANY WPIS"
              : "ZATWIERDŹ - UTWÓRZ WPIS"
          }}</md-button>
        </div>
      </md-card>
    </form>
  </div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import minLength from "vuelidate/lib/validators/minLength";
import { api } from "./../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_maszyny",

  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      Nazwa_wybor_pracownika: null,
      Nazwa_wybor_badan: null,
      Nazwa_wybor_status_badan: null,
      Miejscowosc_sk: null,
      Data: null,
      Nazwa_wybor_stanowiska: null,
      Opis_stanowiska: null,
      wiersze: [
        {
          Nazwa_wybor_zagrozenia: null,
          Opis_zagrozenia: null,
        },
      ],
    },
    IdEdytowanegoElementu: null,
    userSaved: false,
    sending: false,
    lastUser: null,
    Pracownicy: [],
    Badania: [],
    Stanowiska: [],
    Osoby_zatrudnione: [],
    Zagrozenia: [],
  }),

  async created() {
    try {
      const [
        odpowiedz_pracownik,
        odpowiedz_rodzaj,
        odpowiedz_osoby_zatrudnione,
        odpowiedz_stanowisko,
        odpowiedz_zagrozenia,
      ] = await Promise.all([
        api.pobierzPracownika(),
        api.pobierzRodzajBadan(),
        api.pobierzZatrudnieniePracownika(),
        api.pobieranieStanowiska(),
        api.pobieranieZagrozenia(),
      ]);
      odpowiedz_pracownik,
        (this.Pracownicy = odpowiedz_pracownik.data.dane.map((el) => ({
          ...el,
          nazwa: `${el.Nazwisko} ${el.Imie1} - NIP: ${el.NIP} ; PESEL: ${el.Pesel}`,
        })));

      odpowiedz_rodzaj,
        (this.Badania = odpowiedz_rodzaj.data.dane.map((el) => ({
          ...el,
          nazwa1: `${el.Rodzaj_badania}`,
        })));

      odpowiedz_osoby_zatrudnione,
        (this.Osoby_zatrudnione = odpowiedz_osoby_zatrudnione.data.dane.map(
          (el) => ({
            ...el,
            nazwa2: `${el.Rodzaj_badania_osoby}`,
          })
        ));
      odpowiedz_stanowisko,
        (this.Stanowiska = odpowiedz_stanowisko.data.dane.map((el) => ({
          ...el,
          nazwa3: `${el.Stanowisko}`,
        })));

      odpowiedz_zagrozenia,
        (this.Zagrozenia = odpowiedz_zagrozenia.data.dane.map((el) => ({
          ...el,
          nazwa4: `${el.Zagrozenia}`,
        })));

      if (this.obiektKonfiguracyjny.akcja === "edycja") {
        //edycja
        const odpowiedz = await api.pobieranieSkierowaniaEdycja(
          this.obiektKonfiguracyjny.Id
        );
        const {
          Id,
          Id_pracownika,
          Id_rodzaj_badan,
          Id_okreslenie_badan,
          Miejscowosc_sk,
          Data,
          Id_stanowiska,
          Opis_stanowiska,
          PozycjeCzynikow,
        } = odpowiedz.data.dane[0];
        this.IdEdytowanegoElementu = Id;
        this.form.Nazwa_wybor_pracownika = this.Pracownicy.find(
          ({ Id }) => Id == Id_pracownika
        ).nazwa;
        this.form.Nazwa_wybor_badan = this.Badania.find(
          ({ Id }) => Id == Id_rodzaj_badan
        ).nazwa1;
        this.form.Nazwa_wybor_status_badan = this.Osoby_zatrudnione.find(
          ({ Id }) => Id == Id_okreslenie_badan
        ).nazwa2;
        this.form.Miejscowosc_sk = Miejscowosc_sk;
        this.form.Data = Data;
        this.form.Nazwa_wybor_stanowiska = this.Stanowiska.find(
          ({ Id }) => Id == Id_stanowiska
        ).nazwa3;
        this.form.Opis_stanowiska = Opis_stanowiska;
        if (PozycjeCzynikow.length !== 0) {
          this.form.wiersze = PozycjeCzynikow.map((wiersz) => {
            const znalezionyElement = this.Zagrozenia.find(
              ({ Id }) => Id === wiersz.Id_zagrozenia
            );
            delete wiersz.Id_zagrozenia;
            return {
              ...wiersz,
              Nazwa_wybor_zagrozenia: znalezionyElement.Zagrozenia,
            };
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  },
  validations: {
    form: {
      Nazwa_wybor_pracownika: {
        required,
      },
      Nazwa_wybor_badan: {
        required,
      },
      Nazwa_wybor_status_badan: {
        required,
      },
      Miejscowosc_sk: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(50),
      },
      Data: {
        required,
      },
      Nazwa_wybor_stanowiska: {
        required,
      },
      Opis_stanowiska: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(1000),
      },
      wiersze: {
        $each: {
          Nazwa_wybor_zagrozenia: {
            required,
          },
          Opis_zagrozenia: {
            required,
            minLenght: minLength(1),
            maxLength: maxLength(1000),
          },
        },
      },
    },
  },
  computed: {
    Id_pracownika: function() {
      const { Nazwa_wybor_pracownika } = this.form;
      const znalezionyElement = this.Pracownicy.find(
        ({ nazwa }) => nazwa === Nazwa_wybor_pracownika
      );

      if (znalezionyElement) {
        return znalezionyElement.Id;
      }
      return null;
    },
    zapytaniaPracownicy: function() {
      return this.Pracownicy.map((el) => el.nazwa);
    },

    Id_rodzaj_badan: function() {
      const { Nazwa_wybor_badan } = this.form;
      const znalezionyElement = this.Badania.find(
        ({ nazwa1 }) => nazwa1 === Nazwa_wybor_badan
      );

      if (znalezionyElement) {
        return znalezionyElement.Id;
      }
      return null;
    },
    zapytaniaBadania: function() {
      return this.Badania.map((el) => el.nazwa1);
    },

    Id_okreslenie_badan: function() {
      const { Nazwa_wybor_status_badan } = this.form;
      const znalezionyElement = this.Osoby_zatrudnione.find(
        ({ nazwa2 }) => nazwa2 === Nazwa_wybor_status_badan
      );

      if (znalezionyElement) {
        return znalezionyElement.Id;
      }
      return null;
    },
    zapytaniaStatusBadania: function() {
      return this.Osoby_zatrudnione.map((el) => el.nazwa2);
    },

    Id_stanowiska: function() {
      const { Nazwa_wybor_stanowiska } = this.form;
      const znalezionyElement = this.Stanowiska.find(
        ({ nazwa3 }) => nazwa3 === Nazwa_wybor_stanowiska
      );

      if (znalezionyElement) {
        return znalezionyElement.Id;
      }
      return null;
    },
    zapytaniaStanowiska: function() {
      return this.Stanowiska.map((el) => el.nazwa3);
    },

    zapytaniaZagrozenia: function() {
      return this.Zagrozenia.map((el) => el.nazwa4);
    },

    plikZwalidowany: function() {
      return this.form.plik_dane
        ? this.form.plik_dane.size < 2 * 1024 * 1024
        : true;
    },
    czyJestWTrybieEdycji: function() {
      return this.obiektKonfiguracyjny.akcja === "edycja";
    },
  },

  methods: {
    dodajWiersz() {
      console.log("robi");
      const nowyWiersz = {};
      Object.keys(this.form.wiersze[0]).forEach((key) => {
        nowyWiersz[key] = null;
      });
      const noweWiersze = [...this.form.wiersze, nowyWiersz];
      this.form.wiersze = noweWiersze;
    },
    usunWiersz(wiersz) {
      if (this.form.wiersze.length <= 1) {
        const nowyWiersz = {};
        Object.keys(this.form.wiersze[0]).forEach((key) => {
          nowyWiersz[key] = null;
        });
        this.form.wiersze = [nowyWiersz];
        return;
      }

      this.form.wiersze = this.form.wiersze.filter((el) => el !== wiersz);
    },
    getValidationClass(fieldName, index) {
      const field = this.$v.form[fieldName] || this.$v.form.wiersze.$each[index][fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },

    clearForm() {
      this.$v.$reset();
      this.form.Id_pracownika = null;
      this.form.Id_rodzaj_badan = null;
      this.form.Id_okreslenie_badan = null;
      this.form.Miejscowosc_sk = null;
      this.form.Data = null;
      this.form.Id_stanowiska = null;
      this.form.Opis_stanowiska = null;
      this.form.Id_zagrozenia = null;
      this.form.wiersze = [];
    },

    zapiszFormularz() {
      this.sending = true;

      if (this.czyJestWTrybieEdycji) {
        const daneDoWyslania = {
          Id_pracownika: this.Id_pracownika,
          Id_rodzaj_badan: this.Id_rodzaj_badan,
          Id_okreslenie_badan: this.Id_okreslenie_badan,
          Miejscowosc_sk: this.form.Miejscowosc_sk,
          Data: this.form.Data,
          Id_stanowiska: this.Id_stanowiska,
          Opis_stanowiska: this.form.Opis_stanowiska,
          PozycjeCzynikow: this.form.wiersze.map((wiersz) => {
            const znalezionyElement = this.Zagrozenia.find(
              ({ Zagrozenia }) => Zagrozenia === wiersz.Nazwa_wybor_zagrozenia
            );
            return { ...wiersz, Id_zagrozenia: znalezionyElement.Id };
          }),
        };

        api
          .zapiszSkierowaniaUpdate(daneDoWyslania, this.IdEdytowanegoElementu)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const daneDoWyslania = {
          Id_pracownika: this.Id_pracownika,
          Id_rodzaj_badan: this.Id_rodzaj_badan,
          Id_okreslenie_badan: this.Id_okreslenie_badan,
          Miejscowosc_sk: this.form.Miejscowosc_sk,
          Data: this.form.Data,
          Id_stanowiska: this.Id_stanowiska,
          Opis_stanowiska: this.form.Opis_stanowiska,
          PozycjeCzynikow: this.form.wiersze.map((wiersz) => {
            const znalezionyElement = this.Zagrozenia.find(
              ({ Zagrozenia }) => Zagrozenia === wiersz.Nazwa_wybor_zagrozenia
            );
            return { ...wiersz, Id_zagrozenia: znalezionyElement.Id };
          }),
        };

        api
          .zapiszSkierowania(daneDoWyslania)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    naszWalidacjaFormularza() {
      if (this.czyJestWTrybieEdycji) {
        return (
          !this.$v.form.Nazwa_wybor_pracownika.$invalid &&
          !this.$v.form.Nazwa_wybor_badan.$invalid &&
          !this.$v.form.Nazwa_wybor_status_badan.$invalid &&
          !this.$v.form.Miejscowosc_sk.$invalid &&
          !this.$v.form.Data.$invalid &&
          !this.$v.form.Nazwa_wybor_stanowiska.$invalid &&
          !this.$v.form.Opis_stanowiska.$invalid &&
          !this.$v.form.wiersze.$invalid
        );
      } else {
        return !this.$v.$invalid;
      }
    },

    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>

<style>
.Form_dodawSkierow .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
.Form_dodawSkierow {
  overflow: auto;
}
.Form_dodawSkierow .md-layout .md-gutter {
  min-width: 55vw !important; /* szerokość modala*/
  max-width: 100% !important;
  min-height: 7vh; /* wysokość pomiędzy mierszami w modalu*/
}
.md-layout {
  padding-right: 0px !important;
}
.Form_dodawSkierow .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}

.Form_dodawSkierow .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  margin: 10px 24px !important;
  padding-top: 35px !important;
}
.Form_dodawSkierow .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}

.Form_dodawSkierow {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
  overflow: auto;
}
.Form_dodawSkierow .md-primary {
  border: 1px solid !important;

  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}
.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important; /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}

.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}
.md-list-item-text {
  font-size: 1.7vmin !important;
}
.Form_dodawSkierow .md-card-actions .md-button {
  margin-right: 4vw !important;
}
</style>
