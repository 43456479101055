<template>
  <div>
  <TabelaEmisje/>
  </div>
</template>

<script>
import TabelaEmisje from '../../komponenty/strona-tabela-komponenty/WST_emisje/tabela_emisje.vue'


export default {
  name: 'StronaTabelaEmisje',
  components: {
    TabelaEmisje,
  }

}
</script>
