<template>
  <div class="Form_DZA">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI DZIAŁANIA STWIERDZONEGO W TRAKCIE AUDITU"
                : "FORMULARZ DODAWANIA DZIAŁANIA STWIERDZONEGO W TRAKCIE AUDITU"
            }}
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M4" :class="getValidationClass('Id_auditu')">
                <label for="Id_auditu">Wybor auditu</label>

                <md-select
                  v-model="form.Id_auditu"
                  :disabled="sending"
                  name="Id_auditu"
                  id="Id_auditu"
                  autocomplete="Id_auditu"
                  md-dense
                >
                  <md-option
                    v-for="(audit, index) in Audity"
                    :key="index"
                    :value="audit.Id"
                    >{{
                      ` Audit numer : ${audit.Nr_auditu} z dnia : ${audit.Data_zakonczenia} `
                    }}</md-option
                  >
                </md-select>
                <span class="md-error" v-if="!$v.form.Id_auditu.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">

                      <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('Nr_dzialania')">
                <label for="Nr_dzialania">Nr dzialania</label>
                <md-input
                  name="Nr_dzialania"
                  id="Nr_dzialania"
                  autocomplete="Nr_dzialania"
                  v-model="form.Nr_dzialania"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Nr_dzialania.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>



            <div class="md-layout-item md-small-size-100">
              <md-field class="M4" :class="getValidationClass('Id_dzialania')">
                <label for="Id_dzialania">Wybor działania</label>

                <md-select
                  v-model="form.Id_dzialania"
                  :disabled="sending"
                  name="Id_dzialania"
                  id="Id_dzialania"
                  autocomplete="Id_dzialania"
                  md-dense
                >
                  <md-option
                    v-for="(dzialanie, index) in Dzialania"
                    :key="index"
                    :value="dzialanie.Id"
                    >{{ `${dzialanie.Rodzaj_dzialania} ` }}</md-option
                  >
                </md-select>
                <span class="md-error" v-if="!$v.form.Id_dzialania.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('Dzialanie')">
                <label for="Dzialanie">Dzialanie</label>
                <md-textarea md-autogrow md-counter="500"
                  name="Dzialanie"
                  id="Dzialanie"
                  autocomplete="Dzialanie"
                  v-model="form.Dzialanie"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Dzialanie.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Dzialanie.maxlength"
                  >Wymagana ilość znaków od 1 do 500</span
                >
              </md-field>
            </div>
          </div>
        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

      <div style="text-align:center">
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            czyJestWTrybieEdycji
              ? "ZATWIERDŹ - EDYTOWANY WPIS"
              : "ZATWIERDŹ - UTWÓRZ WPIS"
          }}</md-button>
      </div>
      </md-card>
    </form>
  </div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import minLength from "vuelidate/lib/validators/minLength";
import { api } from "./../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_maszyny",

  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      Id_auditu: null,
      Nr_dzialania: null,
      Id_dzialania: null,
      Dzialanie: null,
    },
    IdEdytowanegoElementu: null,
    userSaved: false,
    sending: false,
    lastUser: null,
    Audity: [],
    Dzialania: [],
  }),
  created: function () {
    if (this.obiektKonfiguracyjny.akcja === "edycja") {
      //edycja
      api
        .pobieranieDzialaniaAuditEdycja(this.obiektKonfiguracyjny.Id)
        .then((odpowiedz) => {
          const {
            Id,
            Id_auditu,
            Nr_dzialania,
            Id_dzialania,
            Dzialanie,
          } = odpowiedz.data.dane[0];
          this.IdEdytowanegoElementu = Id;
          this.form.Id = Id_auditu;
          this.form.Id_auditu = Id_auditu;
          this.form.Nr_dzialania = Nr_dzialania;
          this.form.Id_dzialania = Id_dzialania;
          this.form.Dzialanie = Dzialanie;
        })
        .catch((error) => {
          console.log(error);
        });
    }

    Promise.all([api.pobierzNumerAuditu(), api.PobierzRodzajDzialan()])
      .then(([odpowiedz_audity, odpowiedz_dzialania]) => {
        this.Audity = odpowiedz_audity.data.dane;
        this.Dzialania = odpowiedz_dzialania.data.dane;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  validations: {
    form: {
      Id_auditu: {
        required,
      },
      Nr_dzialania: {
        required,
      },
      Id_dzialania: {
        required,
      },
      Dzialanie: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(500),
      },
    },
  },
  computed: {
    czyJestWTrybieEdycji: function () {
      return this.obiektKonfiguracyjny.akcja === "edycja";
    },
  },

  methods: {
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.Id_auditu = null;
      this.form.Id_dzialania = null;
      this.form.Nr_dzialania = null;
      this.form.Dzialanie = null;
    },

    zapiszFormularz() {
      this.sending = true;

      if (this.czyJestWTrybieEdycji) {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Id_auditu", this.form.Id_auditu);
        dane.append("Id_dzialania", this.form.Id_dzialania);
        dane.append("Nr_dzialania", this.form.Nr_dzialania);
        dane.append("Dzialanie", this.form.Dzialanie);

        api
          .zapiszDzialaniaAuditUpdate(dane, this.IdEdytowanegoElementu)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Id_auditu", this.form.Id_auditu);
        dane.append("Id_dzialania", this.form.Id_dzialania);
        dane.append("Nr_dzialania", this.form.Nr_dzialania);
        dane.append("Dzialanie", this.form.Dzialanie);

        api
          .zapiszDzialaniaAudit(dane)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    naszWalidacjaFormularza() {
      if (this.czyJestWTrybieEdycji) {
        return (
          !this.$v.form.Id_auditu.$invalid &&
          !this.$v.form.Id_dzialania.$invalid &&
          !this.$v.form.Nr_dzialania.$invalid &&
          !this.$v.form.Dzialanie.$invalid
        );
      } else {
        return !this.$v.$invalid;
      }
    },

    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>


<style>
.Form_DZA .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.Form_DZA .md-layout .md-gutter {
  min-width: 55vw !important; /* szerokość modala*/
  max-width: 100% !important;
  min-height: 7vh; /* wysokość pomiędzy mierszami w modalu*/
}
.md-layout {
  padding-right: 0px !important;
}
.Form_DZA .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}


.Form_DZA .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  margin: 10px 24px !important;
  padding-top: 35px !important;
}
.Form_DZA .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}


.Form_DZA {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
  overflow: auto;
}
.Form_DZA .md-primary {
  border: 1px solid !important;

  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}
.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important; /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}

.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}
.Form_DZA .md-list-item-text {
  font-size: 1.7vmin !important;
}
.Form_DZA .Form_DZA .md-card-actions .md-button {
  margin-right: 4vw !important;
}

</style>