import { render, staticRenderFns } from "./formularz_dodawanie_dzialania_choroby_ocena.vue?vue&type=template&id=070bb560&"
import script from "./formularz_dodawanie_dzialania_choroby_ocena.vue?vue&type=script&lang=js&"
export * from "./formularz_dodawanie_dzialania_choroby_ocena.vue?vue&type=script&lang=js&"
import style0 from "./formularz_dodawanie_dzialania_choroby_ocena.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports