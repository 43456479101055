<template >
  <div>
  <TabelaRealizacjaMagazyn/>
  </div>
</template>

<script>
import TabelaRealizacjaMagazyn from '../../komponenty/strona-tabela-komponenty/SO_realizacja_magazyn/tabela_wykaz_realizacja_magazyn.vue'


export default {
  name: 'StronaTabelaRealizacjaMagazyn',
  components: {
    TabelaRealizacjaMagazyn,
    
  }

}
</script>

