<template>
  <div>
  <tabelaWykazProjektowSzczegoly/>

  </div>
</template>

<script>
import tabelaWykazProjektowSzczegoly from '../../komponenty/strona-tabela-komponenty/SO_projekty/tabela_wykaz_projektow_szczegoly.vue'


export default {
  name: 'StronaTabelaWykazProjektowSzczegoly',
  components: {
       tabelaWykazProjektowSzczegoly,
  }

}
</script>
