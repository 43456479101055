<template>
  <div class="s">
    <h2
      style="font-weight: bold; margin-bottom:30px; color: blue; margin-top:30px; text-align:center"
    >
      OSTRZEŻENIE 🔥
    </h2>
    <p
      style="margin:20px; font-weight:bold; font-size:16px; text-align:center;"
    >
      CZY JESTEŚ PEWIEN, ŻE CHCESZ USUNĄĆ TEN ELEMENT?
    </p>
    <p
      style="margin:20px; font-weight:bold; font-size:16px; text-align:center;"
    >
      TA OPERACJA JEST NIEODWRACALNA
    </p>
    <br />
    <div>
      <button class="button1" @click="funkcjaUsuwajacaElement">
        ZATWIERDŹ
      </button>
      <button class="button2" @click="funkcjaZamykajacaDialog">
        ANULUJ
      </button>
    </div>
  </div>
</template>

<script>
import { api } from "./../../serwisy/api.serwis";

export default {
  name: "Formularz_usuwanie",
  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  methods: {
    funkcjaUsuwajacaElement() {
      const { typ } = this.obiektKonfiguracyjny;
      switch (typ) {
        // SYS_______________________________________________________________________________________________________

        case "przeglad_kontekst":
          this.usuwanieElementu(api.usuwaniePrzegladuKontekst);
          break;
        case "lokalizacja_kontekst":
          this.usuwanieElementu(api.usuwanieLokalizacjaKontekst);
          break;
        case "zakres_kontekst":
          this.usuwanieElementu(api.usuwanieZakresKontekst);
          break;
        case "granice_kontekst":
          this.usuwanieElementu(api.usuwanieGraniceKontekst);
          break;
        case "normy_kontekst":
          this.usuwanieElementu(api.usuwanieNormyKontekst);
          break;
        case "wylaczenia_kontekst":
          this.usuwanieElementu(api.usuwanieWylaczeniaKontekst);
          break;
        case "procesy_zewnetrzne_kontekst":
          this.usuwanieElementu(api.usuwanieProcesyZewnetrzneKontekst);
          break;
        case "mocne_strony_kontekst":
          this.usuwanieElementu(api.usuwanieMocnychStronKontekst);
          break;
        case "slabe_strony_kontekst":
          this.usuwanieElementu(api.usuwanieSlabychStronKontekst);
          break;
        case "ZSZ_kontekst":
          this.usuwanieElementu(api.usuwanieZSZKontekst);
          break;
        case "WSZ_kontekst":
          this.usuwanieElementu(api.usuwanieWSZKontekst);
          break;

        case "procesy":
          this.usuwanieElementu(api.usuwanieProcesy);
          break;
        case "Przeglady_procesu":
          this.usuwanieElementu(api.usuwanieProcesuPrzeglad);
          break;
        case "Dane_wejsciowe":
          this.usuwanieElementu(api.usuwanieProcesuDaneWejsciowe);
          break;
        case "Dane_wyjsciowe":
          this.usuwanieElementu(api.usuwanieProcesuDaneWyjsciowe);
          break;
        case "Cele_strategiczne":
          this.usuwanieElementu(api.usuwanieProcesuCeleStrategiczne);
          break;
        case "Cele_strategiczne_rozliczenie":
          this.usuwanieElementu(api.usuwanieProcesuRozliczenieCeleStrategiczne);
          break;

        case "dokumentacja":
          this.usuwanieElementu(api.usuwanieDokumentacja);
          break;
        case "Przeglady_dokumentacja":
          this.usuwanieElementu(api.usuwanieDokumentacjaPrzeglad);
          break;

        case "instrukcje_IO":
          this.usuwanieElementu(api.usuwanieIO);
          break;
        case "instrukcje_IO_przeglady":
          this.usuwanieElementu(api.usuwanieIOPrzeglad);
          break;

        case "instrukcje_IS":
          this.usuwanieElementu(api.usuwanieIS);
          break;
        case "instrukcje_IS_przeglady":
          this.usuwanieElementu(api.usuwanieISPrzeglad);
          break;

        case "instrukcje_IBHP":
          this.usuwanieElementu(api.usuwanieIBHP);
          break;
        case "instrukcje_IBHP_przeglady":
          this.usuwanieElementu(api.usuwanieIBHPPrzeglad);
          break;

        case "ryzyka_szanse":
          this.usuwanieElementu(api.usuwanieRyzykoSzanse);
          break;

        case "ryzyka_szanse_przeglady":
          this.usuwanieElementu(api.usuwaniePrzegladuRyzykSzans);
          break;

        case "zagrozenia_bhp":
          this.usuwanieElementu(api.usuwanieZagrozeniaBHP);
          break;

        case "zagrozenia_bhp_przeglady":
          this.usuwanieElementu(api.usuwaniePrzegladuZagrozeniaBHP);
          break;

        case "wymagania_prawne":
          this.usuwanieElementu(api.usuwanieWymaganiaPrawne);
          break;

        case "wymagania_prawne_przeglady":
          this.usuwanieElementu(api.usuwaniePrzegladuWymaganiaPrawne);
          break;

        case "okres_cele":
          this.usuwanieElementu(api.usuwanieRokCele);
          break;

        case "cele_szczegolowe":
          this.usuwanieElementu(api.usuwanieCeleSzczegolowe);
          break;

        case "cele_zadania":
          this.usuwanieElementu(api.usuwanieZadania);
          break;

        case "cele_przeglady":
          this.usuwanieElementu(api.usuwanieCelePrzeglady);
          break;

        case "przeglad_wykaz":
          this.usuwanieElementu(api.usuwaniePrzegladZarzadzania);
          break;

        case "program_auditow":
          this.usuwanieElementu(api.usuwanieAudity);
          break;

        case "plan_auditu":
          this.usuwanieElementu(api.usuwaniePlanAudity);
          break;

        case "raport_z_auditu":
          this.usuwanieElementu(api.usuwanieRaportuZAudity);
          break;

        case "przeglad_protokol":
          this.usuwanieElementu(api.usuwanieProtokolPrzeglad);
          break;

        case "dzialania_audit":
          this.usuwanieElementu(api.usuwanieDzialaniaAudit);
          break;

        case "dzialania_audit_ocena":
          this.usuwanieElementu(api.usuwanieDzialaniaAuditOcena);
          break;

        case "dzialania_audit_ocena_skutecznosc":
          this.usuwanieElementu(api.usuwanieDzialaniaAuditOcenaSkutecznosci);
          break;

        case "dzialania_poza_audit":
          this.usuwanieElementu(api.usuwanieDzialaniaPozaAudit);
          break;

        case "dzialania_poza_audit_ocena":
          this.usuwanieElementu(api.usuwanieDzialaniaPozaAuditOcena);
          break;

        case "dzialania_poza_audit_ocena_skutecznosc":
          this.usuwanieElementu(
            api.usuwanieDzialaniaPozaAuditOcenaSkutecznosci
          );
          break;

        case "aspekty_przeglady":
          this.usuwanieElementu(api.usuwaniePrzegladAspekty);
          break;

        case "usuwanie_aspekt":
          this.usuwanieElementu(api.usuwanieAspekty);
          break;

        case "usuwanie_wymagania_aspekt":
          this.usuwanieElementu(api.usuwanieWymaganiaAspekty);
          break;

        case "usuwanie_monitorowanie_aspekt":
          this.usuwanieElementu(api.usuwanieMonitorowaniaAspekty);
          break;

        case "usuwanie_sprawozdania_srod":
          this.usuwanieElementu(api.usuwanieSprawozdawczosc);
          break;

        case "usuwanie_zadowolenia_klienta":
          this.usuwanieElementu(api.usuwanieZadowolenie);
          break;

        // WST_______________________________________________________________________________________________________

        case "maszyny":
          this.usuwanieElementu(api.usuwanieMaszyn);
          break;

        case "przeglady_maszyny":
          this.usuwanieElementu(api.usuwaniePrzegladuMaszyny);
          break;

        case "generator_maszyny":
          this.usuwanieElementu(api.usuwanieMaszynGenerator);
          break;

        case "ubezpieczenie_maszyny":
          this.usuwanieElementu(api.usuwanieUbezpieczeniaMaszyny);
          break;

        case "maszyny_paliwo":
          this.usuwanieElementu(api.usuwaniePaliwoMaszyny);
          break;

        case "maszyny_koszty":
          this.usuwanieElementu(api.usuwanieKosztyMaszyny);
          break;

        case "maszyny_dozorowe":
          this.usuwanieElementu(api.usuwanieMaszynyDozorowe);
          break;

        case "przeglady_maszyn_dozor":
          this.usuwanieElementu(api.usuwaniePrzegladuMaszynyDozorowePrzeglad);
          break;

        case "ubezpieczenie_maszyny_dozorowe":
          this.usuwanieElementu(
            api.usuwaniePrzegladuMaszynyDozoroweUbezpieczenia
          );
          break;

        case "maszyny_dozorowe_paliwo":
          this.usuwanieElementu(api.usuwaniePrzegladuMaszynyDozorowePaliwo);
          break;

        case "maszyny_dozorowe_koszty":
          this.usuwanieElementu(api.usuwaniePrzegladuMaszynyDozoroweKoszty);
          break;

        case "elektronarzedzia":
          this.usuwanieElementu(api.usuwanieElektronarzedzia);
          break;
        
        case "elektronarzedziaGenerator":
          this.usuwanieElementu(api.usuwanieElektronarzedziaGenerator);
          break;

        case "elektronarzedzia_przeglady":
          this.usuwanieElementu(api.usuwanieElektronarzedziaPrzeglad);
          break;

        case "ubezpieczenie_elektronarzedzia":
          this.usuwanieElementu(api.usuwanieElektronarzedziaUbezpieczenia);
          break;

        case "koszty_elektronarzedzia":
          this.usuwanieElementu(api.usuwanieElektronarzedziaKoszty);
          break;

        case "IT":
          this.usuwanieElementu(api.usuwanieIT);
          break;
        
        case "ITGenerator":
          this.usuwanieElementu(api.usuwanieITGenerator);
          break;

        case "IT_przeglady":
          this.usuwanieElementu(api.usuwanieITPrzeglad);
          break;
        case "IT_ubezpieczenia":
          this.usuwanieElementu(api.usuwanieITUbezpieczenia);
          break;
        case "IT_koszty":
          this.usuwanieElementu(api.usuwanieITKoszty);
          break;

        case "Budynki":
          this.usuwanieElementu(api.usuwanieBudynek);
          break;

        case "budynek_przeglady":
          this.usuwanieElementu(api.usuwaniePrzegladyBudynek);
          break;

        case "Budynki_koszty":
          this.usuwanieElementu(api.usuwanieKosztyBudynek);
          break;

        case "Budynki_media":
          this.usuwanieElementu(api.usuwanieMediaBudynek);
          break;

        case "Budynki_ubezpieczenia":
          this.usuwanieElementu(api.usuwanieUbezpieczenieBudynek);
          break;

        case "usuwanie_samochody_osobowe":
          this.usuwanieElementu(api.usuwanieSamochoduOsobowego);
          break;

        case "usuwanie_samochody_osobowe_przeglad":
          this.usuwanieElementu(api.usuwaniePrzegladuSamochodyOsobowe);
          break;

        case "usuwanie_samochody_osobowe_ubezpieczenie":
          this.usuwanieElementu(api.usuwanieUbezpieczeniaSamochodyOsobowe);
          break;

        case "usuwanie_samochody_osobowe_paliwo":
          this.usuwanieElementu(api.usuwaniePaliwoSamochodyOsobowe);
          break;

        case "usuwanie_samochody_osobowe_koszty":
          this.usuwanieElementu(api.usuwanieKosztySamochodyOsobowe);
          break;

        case "usuwanie_samochody_ciezarowe":
          this.usuwanieElementu(api.usuwanieSamochoduCiezarowego);
          break;

        case "usuwanie_samochody_ciezarowe_przeglad":
          this.usuwanieElementu(api.usuwaniePrzegladuSamochodyCiezarowe);
          break;

        case "usuwanie_samochody_ciezarowe_ubezpieczenie":
          this.usuwanieElementu(api.usuwanieUbezpieczeniaSamochodyCiezarowe);
          break;

        case "usuwanie_samochody_ciezarowe_paliwo":
          this.usuwanieElementu(api.usuwaniePaliwoSamochodyCiezarowe);
          break;

        case "usuwanie_samochody_ciezarowe_koszty":
          this.usuwanieElementu(api.usuwanieKosztySamochodyCiezarowe);
          break;

        case "usuwanie_samochody_ciezarowe_tacho":
          this.usuwanieElementu(api.usuwanieTachoSamochodyCiezarowe);
          break;

        case "usuwanie_samochody_ciezarowe_chlodnie":
          this.usuwanieElementu(api.usuwanieChlodnieSamochodyCiezarowe);
          break;

        case "usuwanie_samochody_ciezarowe_termograf":
          this.usuwanieElementu(api.usuwanieTermografSamochodyCiezarowe);
          break;

        case "WyposazeniePomiaroweUsuwanie":
          this.usuwanieElementu(api.usuwaniePomiarowka);
          break;

        case "przeglady_WKP":
          this.usuwanieElementu(api.usuwaniePrzegladuSPomiarowka);
          break;

        case "ubezpieczenie_WKP":
          this.usuwanieElementu(api.usuwanieUbezpieczeniaPomiarowka);
          break;

        case "WKP_koszty":
          this.usuwanieElementu(api.usuwanieKosztyPomiarowka);
          break;

        case "wyposazenieBHP":
          this.usuwanieElementu(api.usuwanieWyposazenieBHP);
          break;

        case "wyposazeniePrzegladyBHP":
          this.usuwanieElementu(api.usuwanieWyposazeniePrzegladyBHP);
          break;

        case "wyposazeniePPOZ":
          this.usuwanieElementu(api.usuwanieWyposazeniePPOZ);
          break;

        case "wyposazeniePrzegladyPPOZ":
          this.usuwanieElementu(api.usuwanieWyposazeniePrzegladyPPOZ);
          break;

        case "Swiadectwo_sprawdzenia_Genrator":
          this.usuwanieElementu(api.usuwanieWykazSprawdzenPomiarowka);
          break;

        // HRB_______________________________________________________________________________________________________

        case "usun_pracownika":
          this.usuwanieElementu(api.usuwanieWykazPracownicyZatrudnieni);
          break;

        case "daneZatrudnienia":
          this.usuwanieElementu(api.usuwanieDaneZatrudnienia);
          break;

        case "ZakresUsuwanie":
          this.usuwanieElementu(api.usuwanieZakresuPracownicy);
          break;

        case "BadaniaUsuwanie":
          this.usuwanieElementu(api.usuwanieBadaniaPracownicy);
          break;

        case "BadaniaSzkolenieWST":
          this.usuwanieElementu(api.usuwanieBSzkoleniaWSPPracownicy);
          break;

        case "BadaniaSzkolenieOkresowe":
          this.usuwanieElementu(api.usuwanieSzkoleniaBHPPracownicy);
          break;

        case "BadaniaZapoznaniaORZ":
          this.usuwanieElementu(api.usuwanieapoznanieORZPracownicy);
          break;

        case "BadaniaZapoznaniaIO":
          this.usuwanieElementu(api.usuwanieapoznanieIOPracownicy);
          break;

        case "BadaniaZapoznaniaIS":
          this.usuwanieElementu(api.usuwanieapoznanieISPracownicy);
          break;

        case "BadaniaZapoznaniaIBHP":
          this.usuwanieElementu(api.usuwanieapoznanieIBHPPracownicy);
          break;

        case "UsuwanieKompetencje":
          this.usuwanieElementu(api.usuwanieKompetencjePracownicy);
          break;

        case "UsuwanieHistoriaZatrudnienia":
          this.usuwanieElementu(api.usuwanieHistoriaPracownicy);
          break;

        case "UsuwanieZakres":
          this.usuwanieElementu(api.usuwanieZOOIU);
          break;

        case "UsuwanieZakresKatalog":
          this.usuwanieElementu(api.usuwanieKatalogZOOIU);
          break;

        case "UsuwanieStanowisko":
          this.usuwanieElementu(api.usuwanieStanowiska);
          break;

        case "UsuwaniePrzegladStanowisko":
          this.usuwanieElementu(api.usuwanieStanowiskaPrzeglad);
          break;

        case "usuwaniePlanuSzkolenia":
          this.usuwanieElementu(api.usuwaniePlanuSzkolen);
          break;

        case "usuwanieWykazSzkolenia":
          this.usuwanieElementu(api.usuwanieWykazSzkolen);
          break;

        case "usuwanieRealizacjaSzkolenia":
          this.usuwanieElementu(api.usuwanieRealizacjaSzkolen);
          break;

        case "usuwanieUczestnikSzkolenia":
          this.usuwanieElementu(api.usuwanieUczestnikazSzkolen);
          break;

        case "usuwanieSkierowanie":
          this.usuwanieElementu(api.usuwanieSkierowania);
          break;

        case "usuwanieMedycynaPracy":
          this.usuwanieElementu(api.usuwanieMP);
          break;

        // BHP_______________________________________________________________________________________________________

        case "DokumnetacjaORZ":
          this.usuwanieElementu(api.usuwanieDokumentacjaORZ);
          break;

        case "DokumnetacjaORZprzeglady":
          this.usuwanieElementu(api.usuwanieDokumentacjaORZPrzeglad);
          break;

        case "dokumentacja_BSP":
          this.usuwanieElementu(api.usuwanieDokumentacjaBSP);
          break;

        case "wykaz_substancjiN":
          this.usuwanieElementu(api.usuwanieDokumentacjaSN);
          break;

        case "SN_przeglady":
          this.usuwanieElementu(api.usuwanieDokumentacjaSNPrzeglad);
          break;

        case "Ocena_stanu_BHP":
          this.usuwanieElementu(api.usuwanieDokumentacjaOSBHP);
          break;
        
        case "Posiedzenie_BHP":
          this.usuwanieElementu(api.usuwanieDokumentacjaPosiedzenie);
          break;

        case "wypadkiWPIZP":
          this.usuwanieElementu(api.usuwanieDokumentacjaWWP);
          break;

        case "ChorobyZawodowe":
          this.usuwanieElementu(api.usuwanieDokumentacjaCHZAW);
          break;

        case "SytuacjePotencjalnieNiebezpieczne":
          this.usuwanieElementu(api.usuwanieDokumentacjaSPW);
          break;

        case "KontrolaWewnetrzna":
          this.usuwanieElementu(api.usuwanieDokumentacjaKONTWEW);
          break;

        case "KontrolaZewnetrzna":
          this.usuwanieElementu(api.usuwanieDokumentacjaKONTZEW);
          break;

        case "PracePSZN":
          this.usuwanieElementu(api.usuwanieDokumentacjaPSZNIEBEZ);
          break;

        case "PracePSZNPRZEG":
          this.usuwanieElementu(api.usuwanieDokumentacjaPSZNIEBEZPrzeglad);
          break;

        //SO_______________________________________________________________________________________________________

        case "usuwanieWyrobuUslugi":
          this.usuwanieElementu(api.usuwanieWyrobyUslugi);
          break;

        case "usuwanieGrupyWyrobuUslugi":
          this.usuwanieElementu(api.usuwanieGrupyWyrobow);
          break;

        case "kontrahentUsuwanie":
          this.usuwanieElementu(api.usuwanieKontrahenta);
          break;
        case "kontrahentKontakt":
          this.usuwanieElementu(api.usuwanieKontrahentaKontakty);
          break;

        case "dostawcyUsuwanie":
          this.usuwanieElementu(api.usuwanieZakupy);
          break;
        case "dostawcyUsuwaniePrzeglad":
          this.usuwanieElementu(api.usuwaniePrzegladZakupy);
          break;
        case "dostawcyUsuwanieZakupy":
          this.usuwanieElementu(api.usuwanieZakupyZrealizowane);
          break;

        case "usuwanieProjektu":
          this.usuwanieElementu(api.usuwanieProjektu);
          break;
        case "usuwanieSzczegolowProjektu":
          this.usuwanieElementu(api.usuwanieProjektuSzczegóły);
          break;

        case "usuwanieZapytaniaOfertowego":
          this.usuwanieElementu(api.usuwanieZapytaniaOfertowego);
          break;

        case "usuwanieOferty":
          this.usuwanieElementu(api.usuwanieOferty);
          break;

        case "usuwanieZamowienia":
          this.usuwanieElementu(api.usuwanieZamowienia);
          break;

        case "usuwanieRaportDoZamowienia":
          this.usuwanieElementu(api.usuwanieRaportDoZamowienia);
          break;

        case "usuwanieZleceniaMagazyn":
          this.usuwanieElementu(api.usuwanieZleceniaMagazyn);
          break;

        case "usuwanieWZMagazyn":
          this.usuwanieElementu(api.usuwanieWZ);
          break;

        case "usuwanieRaportyMagazyn":
          this.usuwanieElementu(api.usuwanieRaportyMagazyn);
          break;

        case "usuwanieReklamacje":
          this.usuwanieElementu(api.usuwanieReklamacje);
          break;

        case "usuwanieUzytkownika":
          this.usuwanieElementu(api.usuwanieOsobLogowanie);
          break;
      }
    },
    usuwanieElementu(funkcja) {
      const { Id } = this.obiektKonfiguracyjny;
      const idJakoNumber = +Id;
      const obiektId = {
        Id: idJakoNumber,
      };

      funkcja(obiektId)
        .then(() => {
          this.funkcjaZamykajacaDialog(true);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style>
.s {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
}
.button1 {
  border: 3px silver inset;
  margin-right: 10px;
  margin-left: 135px;
  width: 100px;
  margin-bottom: 30px;
  font-weight: bold;
}
.button1:hover {
  background-color: rgb(128, 126, 126);
  text-decoration: none;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  border: 3px whitesmoke inset;
  color: whitesmoke;
}
.button2 {
  border: 3px silver inset;
  width: 100px;
  font-weight: bold;
}
.button2:hover {
  background-color: rgb(128, 126, 126);
  text-decoration: none;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  border: 3px whitesmoke inset;
  color: whitesmoke;
}
</style>
