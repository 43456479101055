<template>
  <div class="stopka">
    <p>Aplikacja QMD_SYSTEM chroniona prawem autorskim. © Wszystkie prawa zastrzeżone. WERSJA QMD_1.002</p>
  </div>
</template>

<script>


export default {
  name: 'Stopka',
  components: {

  }
}
</script>

<style scoped>
.stopka
{
  background-color: #606060;
  color: white;
  margin: 5px;
  font-weight: bold;
  font-size: 1.5vh;
}
</style>