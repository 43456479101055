<template >
  <div>
  <TabelaDzialaniaPozaAudit />
  </div>
</template>

<script>
import TabelaDzialaniaPozaAudit from '../../komponenty/strona-tabela-komponenty/SYS_dzialania_poza_audit/tabela_dzialania_poza_audit.vue'


export default {
  name: 'StronaTabelaDzialaniaPozaAudit',
  components: {
    TabelaDzialaniaPozaAudit,
    
  }

}
</script>

