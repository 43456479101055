<template>
  <div>
  <TabelaMaszyny />
  </div>
</template>

<script>
import TabelaMaszyny from '../../komponenty/strona-tabela-komponenty/WST_maszyny/tabela_maszyny.vue'


export default {
  name: 'StronaTabelaMaszyny',
  components: {
    TabelaMaszyny,
  }

}
</script>
