<template>
  <div>
  <TabelaBudynkiUbezpieczenia/>
  </div>
</template>

<script>
import TabelaBudynkiUbezpieczenia from '../../komponenty/strona-tabela-komponenty/WST_budynki/tabela_budynki_ubezpieczenia.vue'


export default {
  name: 'StronaTabelaBudynkiUbezpieczenia',
  components: {
    TabelaBudynkiUbezpieczenia,
  }

}
</script>
