var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tabela_EMISJA"},[_c('div',{staticClass:"laczacy"},[_vm._m(0),_c('div',{staticClass:"menu_strony1"},[_c('b',[_c('b-icon-printer-fill',{staticClass:"ikony",attrs:{"font-scale":"2.1","color":"white"},on:{"click":_vm.generujPdf}}),_c('md-tooltip',{staticClass:"podpowiedz",attrs:{"md-direction":"top"}},[_vm._v("GENERUJ PDF / DRUKUJ")])],1),_c('b',[_c('b-icon-info-circle-fill',{staticClass:"ikony",attrs:{"font-scale":"2.1","color":"white"},on:{"click":_vm.pokazPDF_INFO}}),_c('md-tooltip',{staticClass:"podpowiedz",attrs:{"md-direction":"top"}},[_vm._v("GENERUJ PDF / DRUKUJ")])],1)])]),(_vm.pokazDialog)?_c('modal',{attrs:{"rodzajFormularza":_vm.typAktywnegoFormularza,"funkcjaZamykajacaDialog":_vm.funkcjaZamykajacaDialog,"obiektKonfiguracyjny":_vm.obiektKonfiguracyjny}}):_vm._e(),_c('div',{staticClass:"md-layout md-gutter"},[_c('div',{staticClass:"md-layout-item md-small-size-100"},[_c('md-field',[_c('label',{attrs:{"for":"Rok"}},[_vm._v("Wybór roku ")]),_c('md-select',{attrs:{"id":"Rok"},on:{"input":_vm.pobierzDaneDoTabeli},model:{value:(_vm.Rok),callback:function ($$v) {_vm.Rok=$$v},expression:"Rok"}},_vm._l((_vm.Lata),function(Rok,index){return _c('md-option',{key:index,attrs:{"value":Rok.Rok}},[_vm._v(_vm._s((" " + (Rok.Rok) + " ")))])}),1)],1)],1)]),_c('md-table',{attrs:{"md-height":"45vh","md-sort":"Nazwa_maszyny","md-sort-order":"asc","md-model-id":"Id","md-card":"","md-fixed-header":""},on:{"md-selected":_vm.wyborElementu},scopedSlots:_vm._u([{key:"md-table-row",fn:function(ref){
var item = ref.item;
return _c('md-table-row',{key:item.id,attrs:{"md-selectable":"single"}},[_c('md-table-cell',{attrs:{"md-label":"Id","md-sort-by":"Id"}},[_vm._v(" "+_vm._s(item.Id)+" ")]),_c('md-table-cell',{attrs:{"md-label":"Rodzaj emisji","md-sort-by":"Rodzaj_emisji"}},[_vm._v(" "+_vm._s(item.Rodzaj_emisji)+" ")]),_c('md-table-cell',{attrs:{"md-label":"Paliwo","md-sort-by":"Rodzaj"}},[_vm._v(" "+_vm._s(item.Rodzaj)+" ")]),_c('md-table-cell',{attrs:{"md-label":"Ilosc","md-sort-by":"Ilosc"}},[_vm._v(" "+_vm._s(item.Ilosc)+" ")])],1)}}]),model:{value:(_vm.searched),callback:function ($$v) {_vm.searched=$$v},expression:"searched"}},[_c('md-table-toolbar',[_c('div',{staticClass:"d-flex flex-column w-100"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4"}),_c('div',{staticClass:"col-4"},[_c('h1',{staticClass:"md-title"},[_vm._v("WYKAZ - ZESTAWIENIE EMISJE")])]),_c('div',{staticClass:"col-4"},[_c('md-field',{staticClass:"ml-auto mr-0",attrs:{"md-clearable":""}},[_c('md-input',{attrs:{"placeholder":"Szukaj..."},on:{"input":_vm.searchOnTable},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)])])]),(_vm.czyPobranoDane)?_c('md-table-empty-state',{attrs:{"md-label":"Nie posiadasz wpisów w wykazie wymagań prawnych i innych"}},[_c('md-button',{staticClass:"md-primary md-raised",on:{"click":function($event){_vm.pokazDialog = true;
          _vm.typAktywnegoFormularza = 'formularz_dodawanie_wymagania_prawne';
          _vm.obiektKonfiguracyjny = {
            Id: null,
            akcja: 'dodawanie',
          };}}},[_vm._v("Dodawanie nowego wymagania prawnego lub innego")])],1):_vm._e()],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu_strony"},[_c('button',[_vm._v("- ")])])}]

export { render, staticRenderFns }