<template>
<div class="ElementyWyboruHRB">
  <b-container fluid class="p-0">
    <b-row>

         <b-col sm="12" md="2">
        <KafelekWyboruHRB
          sciezkaZdjecia="Zdjecia/o-zadowolenie_klienta.jpg"
          opisZdjecia="FajnaFotka"
          linkDoStrony="pracownicy_zatrudnieni"
          podpisPodKafelkiem="PRACOWNICY ZATRUDNIENI"
        />
      </b-col>
            <b-col sm="12" md="2">
        <KafelekWyboruHRB
          sciezkaZdjecia="Zdjecia/o-dzialania.jpg"
          opisZdjecia="FajnaFotka"
          linkDoStrony="pracownicy_zwolnieni"
          podpisPodKafelkiem="PRACOWNICY ZWOLNIENI"
        />
      </b-col>
      <b-col sm="12" md="2">
        <KafelekWyboruHRB
          sciezkaZdjecia="Zdjecia/o-generator_zakresów_obowiązków.jpg"
          opisZdjecia="FajnaFotka"
          linkDoStrony="wykaz_zakresow"
          podpisPodKafelkiem="GENERATOR ZAKRESÓW OBOWIĄZKÓW"
        />
      </b-col>
                     <!-- <b-col sm="12" md="2">
        <KafelekWyboruHRB
          sciezkaZdjecia="Zdjecia/aspekty.jpg"
          opisZdjecia="FajnaFotka"
          linkDoStrony="pracownicy_absencja"
          podpisPodKafelkiem="ABSENCJA"
        />
      </b-col> -->
  
               <b-col sm="12" md="2">
        <KafelekWyboruHRB
          sciezkaZdjecia="Zdjecia/o-dokumentacja.jpg"
          opisZdjecia="FajnaFotka"
          linkDoStrony="stanowiska"
          podpisPodKafelkiem="STANOWISKA PRACY"
        />
      </b-col>
                   <b-col sm="12" md="2">
        <KafelekWyboruHRB
          sciezkaZdjecia="Zdjecia/o-Szkolenia.jpeg"
          opisZdjecia="FajnaFotka"
          linkDoStrony="szkolenia"
          podpisPodKafelkiem="SZKOLENIA"
        />
      </b-col>
       
                     <b-col sm="12" md="2">
        <KafelekWyboruHRB
          sciezkaZdjecia="Zdjecia/o-skierowania_na_badania.jpg"
          opisZdjecia="FajnaFotka"
          linkDoStrony="wykaz_skierowan"
          podpisPodKafelkiem="SKIEROWANIA BADANIA LEKARSKIE"
        />
      </b-col>
                           <b-col sm="12" md="2">
        <KafelekWyboruHRB
          sciezkaZdjecia="Zdjecia/o-medycyna_pracy.jpg"
          opisZdjecia="FajnaFotka"
          linkDoStrony="medycyna_pracy"
          podpisPodKafelkiem="MEDYCYNA PRACY"
        />
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import KafelekWyboruHRB from "./Kafelek_wyboru_HRB.vue";

export default {
  name: "ElementyWyboruHRB",
  components: {
    KafelekWyboruHRB,
  },
};
</script>

<style scoped>
.ElementyWyboruHRB {
  margin-top: 1%;
  display: flex;
  flex-flow: row wrap;
  padding-bottom: 10%;
}

</style>
