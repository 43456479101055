<template>
  <div>
  <TabelaSamochodyCiezaroweTermograf />
  </div>
</template>

<script>
import TabelaSamochodyCiezaroweTermograf from '../../komponenty/strona-tabela-komponenty/WST_samochody_ciezarowe/tabela_samochody_ciezarowe_termografy.vue'


export default {
  name: 'StronaTabelaSamochodyCiezaroweTermograf',
  components: {
    TabelaSamochodyCiezaroweTermograf,
  }

}
</script>
