<template >
  <div>
  <TabelaCeleSzczegolowe />
  </div>
</template>

<script>
import TabelaCeleSzczegolowe from '../../komponenty/strona-tabela-komponenty/SYS_cele/tabela_cele_szczegolowe.vue'


export default {
  name: 'StronaTabelaCeleSzczegolowe',
  components: {
    TabelaCeleSzczegolowe,
    
  }

}
</script>

