<template >
  <div>
  <TabelaWykazPracownicyKompetencje />
  </div>
</template>

<script>
import TabelaWykazPracownicyKompetencje from '../../komponenty/strona-tabela-komponenty/HR_pracownicy/tabela_kompetencje.vue'


export default {
  name: 'StronaTabelaWykazKompetencje',
  components: {
    TabelaWykazPracownicyKompetencje,
    
  }

}
</script>

