<template>
  <div class="Form_Dod_bud">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI BUDYNKU"
                : "FORMULARZ DODAWANIA BUDYNKU"
            }}
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('Id_budynku')">
                <label for="Id_budynku">ID budynku</label>
                <!--- edycja 7 linia-->
                <md-input
                  type="text"
                  name="Id_budynku"
                  id="Id_budynku"
                  
                  md-counter="10"
                  v-model="form.Id_budynku"
                  :disabled="sending || czyJestWTrybieEdycji"
                  readonly
                />
                <span class="md-error" v-if="!$v.form.Id_budynku.required"
                  >Pole jest wymagane</span
                >
                <span class="md-error" v-else-if="!$v.form.Id_budynku.maxlength"
                  >Wymagana ilość znaków od 1 do 10</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('Nazwa_budynku')">
                <label for="Nazwa_budynku">Nazwa budynku</label>
                <md-input
                  type="text"
                  name="Nazwa_budynku"
                  id="Nazwa_budynku"
                  
                  md-counter="100"
                  v-model="form.Nazwa_budynku"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Nazwa_budynku.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Nazwa_budynku.maxlength"
                  >Wymagana ilość znaków od 1 do 100</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('Powierzchnia')">
                <label for="Powierzchnia">Powierzchnia</label>
                <md-input
                  type="text"
                  name="Powierzchnia"
                  id="Powierzchnia"
                  
                  md-counter="30"
                  v-model="form.Powierzchnia"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Powierzchnia.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Powierzchnia.maxlength"
                  >Wymagana ilość znaków od 1 do 30</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('Kubatura')">
                <label for="Kubatura">Kubatura</label>
                <md-input
                  type="text"
                  name="Kubatura"
                  id="Kubatura"
                  
                  md-counter="30"
                  v-model="form.Kubatura"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Kubatura.required"
                  >Pole jest wymagane</span
                >
                <span class="md-error" v-else-if="!$v.form.Kubatura.maxlength"
                  >Wymagana ilość znaków od 1 do 30</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('Rok_oddania')">
                <label for="Rok_oddania">Rok oddania</label>
                <md-input
                  type="text"
                  name="Rok_oddania"
                  id="Rok_oddania"
                  
                  md-counter="4"
                  v-model="form.Rok_oddania"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Rok_oddania.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Rok_oddania.maxlength"
                  >Wymagana ilość znaków 4</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Rok_oddania.minlength"
                  >Wymagana ilość znaków 4</span
                >
              </md-field>
            </div>
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M4"
                :class="getValidationClass('Osoba_odpowiedzialna')"
              >
                <label for="Osoba_odpowiedzialna">Osoba odpowiedzialna</label>
                <md-input
                  type="text"
                  name="Osoba_odpowiedzialna"
                  id="Osoba_odpowiedzialna"
                  
                  md-counter="50"
                  v-model="form.Osoba_odpowiedzialna"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Osoba_odpowiedzialna.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Osoba_odpowiedzialna.maxlength"
                  >Wymagana ilość znaków od 1 do 50</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('Status')">
                <label for="Status">Status budynku</label>

                <md-select
                  v-model="form.Status"
                  :disabled="sending"
                  name="Status"
                  id="Status"
                  autocomplete="Status"
                  md-dense
                >
                  <md-option
                    v-for="(status, index) in Statusy"
                    :key="index"
                    :value="status.Id"
                    >{{ status.Status_maszyny }}</md-option
                  >
                </md-select>
                <span class="md-error" v-if="!$v.form.Status.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>
        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

        <div style="text-align: center">
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            czyJestWTrybieEdycji
              ? "ZATWIERDŹ - EDYTOWANY WPIS"
              : "ZATWIERDŹ - UTWÓRZ WPIS"
          }}</md-button>
        </div>
      </md-card>
    </form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import minLength from "vuelidate/lib/validators/minLength";
import { api } from "../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_maszyny",
  mixins: [validationMixin],
  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      Id_budynku: null,
      Nazwa_budynku: null,
      Powierzchnia: null,
      Kubatura: null,
      Rok_oddania: null,
      Osoba_odpowiedzialna: null,
      Status: null,
    },
    IdEdytowanegoElementu: null,
    userSaved: false,
    sending: false,
    lastUser: null,
    Statusy: [],
  }),
  created: function() {
    if (this.obiektKonfiguracyjny.akcja === "edycja") {
      //edycja
      api
        .pobieranieBudynekEdycja(this.obiektKonfiguracyjny.Id)
        .then((odpowiedz) => {
          const {
            Id,
            Id_budynku,
            Nazwa_budynku,
            Powierzchnia,
            Kubatura,
            Rok_oddania,
            Osoba_odpowiedzialna,
            StatusWartosc,
          } = odpowiedz.data.dane[0];
          this.IdEdytowanegoElementu = Id;
          this.form.Id_budynku = Id_budynku;
          this.form.Nazwa_budynku = Nazwa_budynku;
          this.form.Powierzchnia = Powierzchnia;
          this.form.Kubatura = Kubatura;
          this.form.Rok_oddania = Rok_oddania;
          this.form.Osoba_odpowiedzialna = Osoba_odpowiedzialna;
          this.form.Status = StatusWartosc;
        })
        .catch((error) => {
          console.log(error);
        });
    }

    api
      .pobierzStatusBudynku()
      .then((odpowiedz) => {
        this.Statusy = odpowiedz.data.dane;
      })
      .catch((error) => {
        console.log(error);
      });
      this.pobierzNumerDlaMaszyny();
  },
  validations: {
    form: {
      Id_budynku: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(10),
      },
      Nazwa_budynku: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(100),
      },
      Powierzchnia: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(30),
      },
      Kubatura: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(30),
      },
      Rok_oddania: {
        required,
        minLenght: minLength(4),
        maxLength: maxLength(4),
      },
      Osoba_odpowiedzialna: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(50),
      },
      Status: {
        required,
      },
    },
  },
  computed: {
    czyJestWTrybieEdycji: function() {
      return this.obiektKonfiguracyjny.akcja === "edycja";
    },
  },

  methods: {
    generujNumerMaszyny(generujNumer) {
      if (generujNumer) {
        const { numer } = generujNumer;
        return `BUD - ${numer}`;
      } else {
        return "";
      }
    },

    pobierzNumerDlaMaszyny() {
      if (this.czyJestWTrybieEdycji) {
        return;
      }

      api.PobierzLpBudynku().then((odpowiedz) => {
        this.generujNumer = odpowiedz.data.dane;
        this.form.Id_budynku = this.generujNumerMaszyny(odpowiedz.data.dane);
      });
    },
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.Id_budynku = null;
      this.form.Nazwa_budynku = null;
      this.form.Powierzchnia = null;
      this.form.Kubatura = null;
      this.form.Rok_oddania = null;
      this.form.Osoba_odpowiedzialna = null;
      this.form.Status = null;
    },

    zapiszFormularz() {
      this.sending = true;

      if (this.czyJestWTrybieEdycji) {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Id_budynku", this.form.Id_budynku);
        dane.append("Nazwa_budynku", this.form.Nazwa_budynku);
        dane.append("Powierzchnia", this.form.Powierzchnia);
        dane.append("Kubatura", this.form.Kubatura);
        dane.append("Rok_oddania", this.form.Rok_oddania);
        dane.append("Osoba_odpowiedzialna", this.form.Osoba_odpowiedzialna);
        dane.append("Status", this.form.Status);

        api
          .zapiszBudynekUpdate(dane, this.IdEdytowanegoElementu)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Id_budynku", this.form.Id_budynku);
        dane.append("Nazwa_budynku", this.form.Nazwa_budynku);
        dane.append("Powierzchnia", this.form.Powierzchnia);
        dane.append("Kubatura", this.form.Kubatura);
        dane.append("Rok_oddania", this.form.Rok_oddania);
        dane.append("Osoba_odpowiedzialna", this.form.Osoba_odpowiedzialna);
        dane.append("Status", this.form.Status);
        dane.append("Lp", this.generujNumer.numer);

        api
          .zapiszBudynek(dane)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    naszWalidacjaFormularza() {
      if (this.czyJestWTrybieEdycji) {
        return (
          !this.$v.form.Id_budynku.$invalid &&
          !this.$v.form.Nazwa_budynku.$invalid &&
          !this.$v.form.Powierzchnia.$invalid &&
          !this.$v.form.Kubatura.$invalid &&
          !this.$v.form.Rok_oddania.$invalid &&
          !this.$v.form.Osoba_odpowiedzialna.$invalid &&
          !this.$v.form.Status.$invalid
        );
      }else {
        return !this.$v.$invalid;
      }
    },
    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>

<style>
.Form_Dod_bud .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.Form_Dod_bud .md-layout .md-gutter {
  min-width: 55vw !important; /* szerokość modala*/
  max-width: 100% !important;
  min-height: 7vh; /* wysokość pomiędzy mierszami w modalu*/
}
.md-layout {
  padding-right: 0px !important;
}
.Form_Dod_bud .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}

.Form_Dod_bud .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  margin: 10px 24px !important;
  padding-top: 35px !important;
}
.Form_Dod_bud .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}
.Form_Dod_bud {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
  overflow: auto !important;
}
.Form_Dod_bud .md-primary {
  border: 1px solid !important;

  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}
.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important; /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}

.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}

.md-list-item-text {
  font-size: 1.7vmin !important;
}
.Form_Dod_bud .md-card-actions .md-button {
  margin-right: 50px !important;
}
</style>
