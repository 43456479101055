<template >
  <div>
  <TabelaGrupy/>
  </div>
</template>

<script>
import TabelaGrupy from '../../komponenty/strona-tabela-komponenty/SO_wyroby/tabela_grupy.vue'


export default {
  name: 'StronaTabelaGrupy',
  components: {
    TabelaGrupy,
    
  }

}
</script>

