<template>
  <div class="Form_REKL">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI REKLAMACJI"
                : "FORMULARZ DODAWANIA REKLAMACJI"
            }}
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M1"
                :class="getValidationClass('Data_otrzymania')"
              >
                <label for="Data_otrzymania">Data otrzymania</label>
                <md-input
                  type="date"
                  name="Data_otrzymania"
                  id="Data_otrzymania"
                  
                  v-model="form.Data_otrzymania"
                  :disabled="sending"
                  @input="pobierzNumerReklamacji"
                />
                <span class="md-error" v-if="!$v.form.Data_otrzymania.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field class="M1" :class="getValidationClass('Nr_reklamacji')">
                <label for="Nr_reklamacji">Nr reklamacji</label>
                <md-input
                  name="Nr_reklamacji"
                  id="Nr_reklamacji"
                  
                  v-model="form.Nr_reklamacji"
                  :disabled="sending"
                  readonly
                />
                <span class="md-error" v-if="!$v.form.Nr_reklamacji.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Nr_reklamacji.maxlength"
                  >Wymagana ilość znaków od 1 do 50</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('Id_reklamacji')">
                <label for="Id_reklamacji">Rodzaj reklamacji</label>

                <md-select
                  v-model="form.Id_reklamacji"
                  :disabled="sending"
                  name="Id_reklamacji"
                  id="Id_reklamacji"
                  autocomplete="Id_reklamacji"
                  md-dense
                >
                  <md-option
                    v-for="(rodzaj_reklamacji, index) in Rodzaje_reklamacji"
                    :key="index"
                    :value="rodzaj_reklamacji.Id"
                    >{{ ` ${rodzaj_reklamacji.Rodzaj_reklamacji} ` }}</md-option
                  >
                </md-select>
                <span class="md-error" v-if="!$v.form.Id_reklamacji.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('Id_status')">
                <label for="Id_status">Określ status reklamacji</label>

                <md-select
                  v-model="form.Id_status"
                  :disabled="sending"
                  name="Id_status"
                  id="Id_status"
                  autocomplete="Id_status"
                  md-dense
                >
                  <md-option
                    v-for="(status_reklamacji, index) in Statusy_reklamacji"
                    :key="index"
                    :value="status_reklamacji.Id"
                    >{{ ` ${status_reklamacji.Status_reklamacji} ` }}</md-option
                  >
                </md-select>
                <span class="md-error" v-if="!$v.form.Id_status.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('Id_reklamacja_dotyczy')">
                <label for="Id_status">Reklamacja dotyczy</label>

                <md-select
                  v-model="form.Id_reklamacja_dotyczy"
                  :disabled="sending"
                  name="Id_reklamacja_dotyczy"
                  id="Id_reklamacja_dotyczy"
                  autocomplete="Id_reklamacja_dotyczy"
                  md-dense
                >
                  <md-option
                    v-for="(jaka_reklamacja, index) in Jakie_reklamacje"
                    :key="index"
                    :value="jaka_reklamacja.Id"
                    >{{ ` ${jaka_reklamacja.Jaka_reklamacja} ` }}</md-option
                  >
                </md-select>
                <span
                  class="md-error"
                  v-if="!$v.form.Id_reklamacja_dotyczy.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

          <div
            v-if="
              form.Id_reklamacja_dotyczy !== '2' &&
                form.Id_reklamacja_dotyczy !== '3' &&
                form.Id_reklamacja_dotyczy !== null
            "
            class="md-layout md-gutter"
          >
            <div class="md-layout-item md-small-size-100">
              <md-autocomplete
                v-model="form.Nazwa_wybor_kontrahenta1"
                :md-options="kontrahentWartosc1"
                @md-selected="pobierzRealizacje1"
              >
                <label>Wybor kontrahenta</label>
              </md-autocomplete>
            </div>
          </div>

          <div
            v-if="
              form.Id_reklamacja_dotyczy !== '2' &&
                form.Id_reklamacja_dotyczy !== '3' &&
                form.Id_reklamacja_dotyczy !== null
            "
            class="md-layout md-gutter"
          >
            <div class="md-layout-item md-small-size-100">
              <md-autocomplete
                v-model="form.Nazwa_wybor_realizacji1"
                :md-options="realizacjaWartosc1"
              >
                <label>Wybor zamówienia do reklamacji</label>
              </md-autocomplete>
            </div>
          </div>

          <div
            v-if="
              form.Id_reklamacja_dotyczy !== '1' &&
                form.Id_reklamacja_dotyczy !== '3' &&
                form.Id_reklamacja_dotyczy !== null
            "
            class="md-layout md-gutter"
          >
            <div class="md-layout-item md-small-size-100">
              <md-autocomplete
                v-model="form.Nazwa_wybor_kontrahenta2"
                :md-options="kontrahentWartosc2"
                @md-selected="pobierzRealizacje2"
              >
                <label>Wybor kontrahenta</label>
              </md-autocomplete>
            </div>
          </div>

          <div
            v-if="
              form.Id_reklamacja_dotyczy !== '1' &&
                form.Id_reklamacja_dotyczy !== '3' &&
                form.Id_reklamacja_dotyczy !== null
            "
            class="md-layout md-gutter"
          >
            <div class="md-layout-item md-small-size-100">
              <md-autocomplete
                v-model="form.Nazwa_wybor_realizacji2"
                :md-options="realizacjaWartosc2"
              >
                <label>Wybor dokumentu RW do reklamacji</label>
              </md-autocomplete>
            </div>
          </div>

          <div
            v-if="
              form.Id_reklamacja_dotyczy !== '1' &&
                form.Id_reklamacja_dotyczy !== '2' &&
                form.Id_reklamacja_dotyczy !== null
            "
            class="md-layout md-gutter"
          >
            <div class="md-layout-item md-small-size-100">
              <md-autocomplete
                v-model="form.Nazwa_wybor_kontrahenta3"
                :md-options="kontrahentWartosc3"
                @md-selected="pobierzRealizacje3"
              >
                <label>Wybor kontrahenta</label>
              </md-autocomplete>
            </div>
          </div>

          <div
            v-if="
              form.Id_reklamacja_dotyczy !== '1' &&
                form.Id_reklamacja_dotyczy !== '2' &&
                form.Id_reklamacja_dotyczy !== null
            "
            class="md-layout md-gutter"
          >
            <div class="md-layout-item md-small-size-100">
              <md-autocomplete
                v-model="form.Nazwa_wybor_realizacji3"
                :md-options="realizacjaWartosc3"
              >
                <label>Wybor dokumentu zakupowego</label>
              </md-autocomplete>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M1" :class="getValidationClass('Opis')">
                <label for="Opis">Opis reklamacji</label>
                <md-textarea
                  name="Opis"
                  id="Opis"
                  
                  v-model="form.Opis"
                  md-autogrow
                  md-counter="1000"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Opis.required"
                  >Pole jest wymagane</span
                >
                <span class="md-error" v-else-if="!$v.form.Opis.maxlength"
                  >Wymagana ilość znaków od 1 do 1000</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M1"
                :class="getValidationClass('Osoba_odpowiedzialna')"
              >
                <label for="Osoba_odpowiedzialna">Osoba odpowiedzialna</label>
                <md-input
                  name="Osoba_odpowiedzialna"
                  id="Osoba_odpowiedzialna"
                  md-counter="50"
                  
                  v-model="form.Osoba_odpowiedzialna"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Osoba_odpowiedzialna.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Osoba_odpowiedzialna.maxlength"
                  >Wymagana ilość znaków od 1 do 50</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="plikZwalidowany ? '' : 'md-invalid'">
                <label
                  >Wstaw plik - reklamacja "max-2Mb"
                  <span v-if="nazwaPliku">
                    - obecnie wstawiony plik to {{ nazwaPliku }}</span
                  ></label
                >
                <md-file
                  v-model="form.plik_PDF"
                  @md-change="zapamietajPlik"
                  accept="application/pdf"
                />
                <span class="md-error" v-if="!plikZwalidowany"
                  >Plik moze miec max 2MB</span
                >
              </md-field>
            </div>
          </div>
        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

        <div style="text-align: center">
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            czyJestWTrybieEdycji
              ? "ZATWIERDŹ - EDYTOWANY WPIS"
              : "ZATWIERDŹ - UTWÓRZ WPIS"
          }}</md-button>
        </div>
      </md-card>
    </form>
  </div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import minLength from "vuelidate/lib/validators/minLength";
import { api } from "../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_faktury",

  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      Nazwa_wybor_kontrahenta1: null,
      Nazwa_wybor_realizacji1: null,
      Nazwa_wybor_kontrahenta2: null,
      Nazwa_wybor_realizacji2: null,
      Nazwa_wybor_kontrahenta3: null,
      Nazwa_wybor_realizacji3: null,
      Data_otrzymania: null,
      Nr_reklamacji: null,
      Id_reklamacji: null,
      Id_status: null,
      Id_reklamacja_dotyczy: null,
      Opis: null,
      Osoba_odpowiedzialna: null,
      plik_PDF: null,
      plik_dane: null,
      Id_zlecenia1: null,
      Id_zlecenia2: null,
      Id_zlecenia3: null,
    },
    IdEdytowanegoElementu: null,
    userSaved: false,
    sending: false,
    lastUser: null,
    nazwaPliku: null,
    Rodzaje_reklamacji: [],
    Statusy_reklamacji: [],
    Jakie_reklamacje: [],
    Kontrahenci1: [],
    Realizacje1: [],
    Kontrahenci2: [],
    Realizacje2: [],
    Kontrahenci3: [],
    Realizacje3: [],
    Obiekt_generujacy_numer_reklamacji: null,
  }),

  async created() {
    try {
      const [
        odpowiedz_Rodzaj,
        odpowiedz_Status,
        odpowiedz_JakaReklamacja,
        odpowiedz_Kontrahenci1,
        odpowiedz_Kontrahenci2,
        odpowiedz_Kontrahenci3,
      ] = await Promise.all([
        api.pobierzRodzajReklamacji(),
        api.pobierzStatusReklamacji(),
        api.pobierzJakaReklamacja(),
        api.pobierzKontrahencta1(),
        api.pobierzKontrahencta2(),
        api.pobierzKontrahencta3(),
      ]);

      odpowiedz_Rodzaj, odpowiedz_Status, odpowiedz_JakaReklamacja;

      this.Rodzaje_reklamacji = odpowiedz_Rodzaj.data.dane;
      this.Statusy_reklamacji = odpowiedz_Status.data.dane;
      this.Jakie_reklamacje = odpowiedz_JakaReklamacja.data.dane;
      odpowiedz_Kontrahenci1,
        (this.Kontrahenci1 = odpowiedz_Kontrahenci1.data.dane.map((el) => ({
          ...el,
          nazwa1: `NIP: ${el.NIP} - ${el.Nazwa_kontrahenta}, ${el.Kod} ${el.Miejscowosc} ${el.Ulica} ${el.Nr}`,
        })));
      odpowiedz_Kontrahenci2,
        (this.Kontrahenci2 = odpowiedz_Kontrahenci2.data.dane.map((el) => ({
          ...el,
          nazwa2: `NIP: ${el.NIP} - ${el.Nazwa_kontrahenta}, ${el.Kod} ${el.Miejscowosc} ${el.Ulica} ${el.Nr}`,
        })));
      odpowiedz_Kontrahenci3,
        (this.Kontrahenci3 = odpowiedz_Kontrahenci3.data.dane.map((el) => ({
          ...el,
          nazwa3: `NIP: ${el.NIP} - ${el.Nazwa_firmy}`,
        })));

      if (this.obiektKonfiguracyjny.akcja === "edycja") {
        //edycja
        const odpowiedz = await api.pobieranieReklamacjeEdycja(
          this.obiektKonfiguracyjny.Id
        );
        const {
          Id,
          Data_otrzymania,
          Nr_reklamacji,
          Id_reklamacji,
          Id_status,
          Id_kontrahent1,
          Id_kontrahent2,
          Id_kontrahent3,
          Id_zlecenia1,
          Id_zlecenia2,
          Id_zlecenia3,
          Id_reklamacja_dotyczy,
          Opis,
          Osoba_odpowiedzialna,
          Nazwa_zalacznika,
        } = odpowiedz.data.dane[0];
        this.IdEdytowanegoElementu = Id;
        this.form.Data_otrzymania = Data_otrzymania;
        this.form.Nr_reklamacji = Nr_reklamacji;
        this.form.Id_reklamacji = Id_reklamacji;
        this.form.Id_zlecenia1 = Id_zlecenia1;
        this.form.Id_zlecenia2 = Id_zlecenia2;
        this.form.Id_zlecenia3 = Id_zlecenia3;
        if (Id_kontrahent1 !=0) {
          this.form.Nazwa_wybor_kontrahenta1 = this.Kontrahenci1.find(
            ({ Id }) => Id == Id_kontrahent1
          ).nazwa1;
          this.pobierzRealizacje1();
        }
        if (Id_kontrahent2 !=0) {
          this.form.Nazwa_wybor_kontrahenta2 = this.Kontrahenci2.find(
            ({ Id_zamowienia }) => Id_zamowienia == Id_kontrahent2
          ).nazwa2;
          this.pobierzRealizacje2();
        }
        if (Id_kontrahent3 !=0) {
          this.form.Nazwa_wybor_kontrahenta3 = this.Kontrahenci3.find(
            ({ Id }) => Id == Id_kontrahent3
          ).nazwa3;
          this.pobierzRealizacje3();
        }

        this.form.Id_reklamacja_dotyczy = Id_reklamacja_dotyczy;
        this.form.Id_status = Id_status;
        this.form.Opis = Opis;
        this.form.Osoba_odpowiedzialna = Osoba_odpowiedzialna;
        this.nazwaPliku = Nazwa_zalacznika;
      }
    } catch (error) {
      console.log(error);
    }
  },
  validations: {
    form: {
      Data_otrzymania: {
        required,
      },
      Nr_reklamacji: {
        required,
      },
      Id_reklamacji: {
        required,
      },
      Id_status: {
        required,
      },
      Id_reklamacja_dotyczy: {
        required,
      },
      Opis: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(1000),
      },
      Osoba_odpowiedzialna: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(50),
      },
      Nazwa_wybor_kontrahenta1: {
        required: required(false),
      },
      Nazwa_wybor_realizacji1: {
        required: required(false),
      },
      Nazwa_wybor_kontrahenta2: {
        required: required(false),
      },
      Nazwa_wybor_realizacji2: {
        required: required(false),
      },
      Nazwa_wybor_kontrahenta3: {
        required: required(false),
      },
      Nazwa_wybor_realizacji3: {
        required: required(false),
      },
    },
  },
  computed: {
    Id_kontrahent1: function() {
      const { Nazwa_wybor_kontrahenta1 } = this.form;
      const znalezionyElement = this.Kontrahenci1.find(
        ({ nazwa1 }) => nazwa1 === Nazwa_wybor_kontrahenta1
      );

      if (znalezionyElement) {
        return znalezionyElement.Id;
      }
      return null;
    },
    kontrahentWartosc1: function() {
      return this.Kontrahenci1.map((el) => el.nazwa1);
    },

    Id_kontrahent2: function() {
      const { Nazwa_wybor_kontrahenta2 } = this.form;
      const znalezionyElement = this.Kontrahenci2.find(
        ({ nazwa2 }) => nazwa2 === Nazwa_wybor_kontrahenta2
      );

      if (znalezionyElement) {
        return znalezionyElement.Id_kontrahenta;
      }
      return null;
    },
    kontrahentWartosc2: function() {
      return this.Kontrahenci2.map((el) => el.nazwa2);
    },

    Id_kontrahent3: function() {
      const { Nazwa_wybor_kontrahenta3 } = this.form;
      const znalezionyElement = this.Kontrahenci3.find(
        ({ nazwa3 }) => nazwa3 === Nazwa_wybor_kontrahenta3
      );

      if (znalezionyElement) {
        return znalezionyElement.Id;
      }
      return null;
    },
    kontrahentWartosc3: function() {
      return this.Kontrahenci3.map((el) => el.nazwa3);
    },

    Id_realizacja1: function() {
      const { Nazwa_wybor_realizacji1 } = this.form;

      const znalezionyElement = this.Realizacje1.find(
        ({ nazwa4 }) => nazwa4 === Nazwa_wybor_realizacji1
      );

      if (znalezionyElement) {
        return znalezionyElement.Id;
      }
      return null;
    },
    realizacjaWartosc1: function() {
      return this.Realizacje1.map((el) => el.nazwa4);
    },

    Id_realizacja2: function() {
      const { Nazwa_wybor_realizacji2 } = this.form;
      const znalezionyElement = this.Realizacje2.find(
        ({ nazwa5 }) => nazwa5 === Nazwa_wybor_realizacji2
      );

      if (znalezionyElement) {
        return znalezionyElement.Id;
      }
      return null;
    },
    realizacjaWartosc2: function() {
      return this.Realizacje2.map((el) => el.nazwa5);
    },

    Id_realizacja3: function() {
      const { Nazwa_wybor_realizacji3 } = this.form;
      const znalezionyElement = this.Realizacje3.find(
        ({ nazwa6 }) => nazwa6 === Nazwa_wybor_realizacji3
      );

      if (znalezionyElement) {
        return znalezionyElement.Id;
      }
      return null;
    },
    realizacjaWartosc3: function() {
      return this.Realizacje3.map((el) => el.nazwa6);
    },

    plikZwalidowany: function() {
      return this.form.plik_dane
        ? this.form.plik_dane.size < 2 * 1024 * 1024
        : true;
    },
    czyJestWTrybieEdycji: function() {
      return this.obiektKonfiguracyjny.akcja === "edycja";
    },

    numerReklamacji: function() {
      if (this.Obiekt_generujacy_numer_reklamacji) {
        const { numer, miesiac, rok } = this.Obiekt_generujacy_numer_reklamacji;
        return `REK-${numer}/${miesiac}/${rok}`;
      } else {
        return "";
      }
    },
  },

  methods: {
    zapamietajPlik(pliki) {
      if (pliki[0]) {
        this.form.plik_dane = pliki[0];
      }
    },
    pobierzNumerReklamacji() {
      const { Data_otrzymania } = this.form;
      const data = new Date(Data_otrzymania);
      const rok = data.getFullYear();
      const miesiac = data.getMonth() + 1;
      if (this.czyJestWTrybieEdycji) {
        return;
      }

      api
        .PobierzLpReklamacji({
          rok,
          miesiac,
        })
        .then((odpowiedz) => {
          this.Obiekt_generujacy_numer_reklamacji = odpowiedz.data.dane;
          this.form.Nr_reklamacji = this.numerReklamacji;
        });
    },

    pobierzRealizacje1() {
      const { Id_kontrahent1 } = this;
      api.PobierzRealizacjeZapytania(Id_kontrahent1).then((Realizacje1) => {
        this.Realizacje1 = Realizacje1.data.dane.map((el) => ({
          ...el,
          nazwa4: `Numer zamówienia: ${el.Numer_zamowienia} - Data zamówienia ${el.Data_zamowienia}`,
        }));
        if (this.czyJestWTrybieEdycji) {
          this.form.Nazwa_wybor_realizacji1 = this.Realizacje1.find(
            ({ Id }) => Id == this.form.Id_zlecenia1
          ).nazwa4;
        }
      });
    },

    pobierzRealizacje2() {
      const { Id_kontrahent2 } = this;
      api
        .PobierzRealizacjeZlecenieWewnetrzne(Id_kontrahent2)
        .then((Realizacje2) => {
          this.Realizacje2 = Realizacje2.data.dane.map((el) => ({
            ...el,
            nazwa5: `Numer RW: ${el.Numer_WZ} - Data RW ${el.Data_WZ}`,
          }));
          if (this.czyJestWTrybieEdycji) {
            this.form.Nazwa_wybor_realizacji2 = this.Realizacje2.find(
              ({ Id }) => Id == this.form.Id_zlecenia2
            ).nazwa5;
          }
        });
    },

    pobierzRealizacje3() {
      const { Id_kontrahent3 } = this;
      api.PobierzRealizacjeZakupy(Id_kontrahent3).then((Realizacje3) => {
        this.Realizacje3 = Realizacje3.data.dane.map((el) => ({
          ...el,
          nazwa6: `Numer faktury: ${el.Nr_faktury} - Data zakupu ${el.Data_zakupu}`,
        }));
        if (this.czyJestWTrybieEdycji) {
            this.form.Nazwa_wybor_realizacji3 = this.Realizacje3.find(
              ({ Id }) => Id == this.form.Id_zlecenia3
            ).nazwa6;
          }
      });
    },

    getValidationClass(fieldName, index) {
      const field =
        this.$v.form[fieldName] || this.$v.form.wiersze.$each[index][fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.Data_otrzymania = null;
      this.form.Nr_reklamacji = null;
      this.form.Id_reklamacji = null;
      this.form.Id_status = null;
      this.form.Id_reklamacja_dotyczy = null;
      this.form.Id_kontrahent1 = null;
      this.form.Id_realizacja1 = null;
      this.form.Id_kontrahent2 = null;
      this.form.Id_realizacja2 = null;
      this.form.Id_kontrahent3 = null;
      this.form.Id_realizacja3 = null;
      this.form.Opis = null;
      this.form.Osoba_odpowiedzialna = null;
      this.form.plik_PDF = null;
      this.form.plik_dane = null;
      this.nazwaPliku = null;
    },

    async zapiszFormularz() {
      this.sending = true;

      let file = null;
      if (this.form.plik_dane) {
        file = await new Promise((resolve) => {
          const fileReader = new FileReader();
          fileReader.onload = () => {
            resolve(fileReader.result);
          };
          fileReader.readAsDataURL(this.form.plik_dane);
        });
      }

      if (this.czyJestWTrybieEdycji) {
        const daneDoWyslania = {
          Data_otrzymania: this.form.Data_otrzymania,
          Nr_reklamacji: this.form.Nr_reklamacji,
          Id_reklamacji: this.form.Id_reklamacji,
          Id_reklamacja_dotyczy: this.form.Id_reklamacja_dotyczy,
          Id_kontrahent1: this.Id_kontrahent1,
          Id_realizacja1: this.Id_realizacja1,
          Id_kontrahent2: this.Id_kontrahent2,
          Id_realizacja2: this.Id_realizacja2,
          Id_kontrahent3: this.Id_kontrahent3,
          Id_realizacja3: this.Id_realizacja3,
          Id_status: this.form.Id_status,
          Opis: this.form.Opis,
          Osoba_odpowiedzialna: this.form.Osoba_odpowiedzialna,
          plik_PDF: file,
          PlikNazwa: this.form.plik_dane ? this.form.plik_dane.name : null,
        };

        api
          .zapiszReklamacjeUpdate(daneDoWyslania, this.IdEdytowanegoElementu)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        let file = null;
        if (this.form.plik_dane) {
          file = await new Promise((resolve) => {
            const fileReader = new FileReader();
            fileReader.onload = () => {
              resolve(fileReader.result);
            };
            fileReader.readAsDataURL(this.form.plik_dane);
          });
        }

        const daneDoWyslania = {
          Data_otrzymania: this.form.Data_otrzymania,
          Nr_reklamacji: this.form.Nr_reklamacji,
          Id_reklamacja_dotyczy: this.form.Id_reklamacja_dotyczy,
          Id_reklamacji: this.form.Id_reklamacji,
          Id_kontrahent1: this.Id_kontrahent1,
          Id_realizacja1: this.Id_realizacja1,
          Id_kontrahent2: this.Id_kontrahent2,
          Id_realizacja2: this.Id_realizacja2,
          Id_kontrahent3: this.Id_kontrahent3,
          Id_realizacja3: this.Id_realizacja3,
          Lp: this.Obiekt_generujacy_numer_reklamacji.numer,
          Opis: this.form.Opis,
          Id_status: this.form.Id_status,
          Osoba_odpowiedzialna: this.form.Osoba_odpowiedzialna,
          plik_PDF: file,
          PlikNazwa: this.form.plik_dane ? this.form.plik_dane.name : null,
        };

        api
          .zapiszReklamacje(daneDoWyslania)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    naszWalidacjaFormularza() {
      if (this.czyJestWTrybieEdycji) {
        return (
          !this.$v.form.Data_otrzymania.$invalid &&
          !this.$v.form.Nr_reklamacji.$invalid &&
          !this.$v.form.Id_reklamacji.$invalid &&
          !this.$v.form.Id_reklamacja_dotyczy.$invalid &&
          !this.$v.form.Id_status.$invalid &&
          !this.$v.form.Opis.$invalid &&
          !this.$v.form.Osoba_odpowiedzialna.$invalid &&
          !this.$v.form.Nazwa_wybor_kontrahenta1.$invalid &&
          !this.$v.form.Nazwa_wybor_realizacji1.$invalid &&
          !this.$v.form.Nazwa_wybor_kontrahenta2.$invalid &&
          !this.$v.form.Nazwa_wybor_realizacji2.$invalid &&
          !this.$v.form.Nazwa_wybor_kontrahenta3.$invalid &&
          !this.$v.form.Nazwa_wybor_realizacji3.$invalid &&
          this.plikZwalidowany
        );
      } else {
        return !this.$v.$invalid && this.plikZwalidowany;
      }
    },
    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>

<style>
.Form_REKL .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.Form_REKL .md-layout .md-gutter {
  min-width: 73vw !important;
  /* szerokość modala*/
  max-width: 100% !important;
  min-height: 7vh !important;
  /* wysokość pomiędzy mierszami w modalu*/
  margin-right: 20px !important;
}

.md-layout {
  padding-right: 0px !important;
}

.Form_REKL .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}

.Form_REKL .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  margin: 10px 24px !important;
  padding-top: 35px !important;
}

.Form_REKL .A03 .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 90px !important;
  margin: 10px 10px 10px 10px !important;
  padding-top: 35px !important;
}

.Form_REKL .A02 .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 90px !important;
  margin: 10px 10px 10px 10px !important;
  padding-top: 35px !important;
}

.Form_REKL .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}

.Form_REKL {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
  overflow: auto;
}

.Form_REKL .md-primary {
  border: 1px solid !important;
  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}

.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important;
  /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}

.md-layout .md-gutter {
  min-width: 1100px !important;
}

.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}

.md-list-item-text {
  font-size: 1.7vmin !important;
}

.Form_REKL .md-card-actions .md-button {
  margin-right: 50px !important;
}

.aa {
  border-bottom: 1px solid;
  margin-top: 10px;
  padding-bottom: 20px;
  margin-left: 25px;
  margin-right: 20px;
}

.Form_REKL .ikony {
  cursor: pointer;
  margin-right: 5px;
  margin-left: 15px;
}

.md-field.md-has-textarea:not(.md-autogrow):after,
.md-field.md-has-textarea:not(.md-autogrow):before {
  border: none !important;
  border-bottom: 0.5px solid !important;
  height: 90px !important;
  font-size: 12px !important;
}

.md-field.md-has-textarea:not(.md-autogrow) .md-textarea {
  min-height: 40px !important;
  padding: 0 0px !important;
  resize: vertical !important;
}

.md-input {
  width: 100% !important;
}

.md-menu-content-bottom-start.md-menu-content-small.md-menu-content.md-theme-default {
  z-index: 300;
  color: black;
}
</style>
