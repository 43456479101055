<template>
  <div class="FORM_grupyWyr">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI GRUPY WYROBÓW/USŁUG"
                : "FORMULARZ DODAWANIA GRUPY WYROBÓW/USŁUG"
            }}
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M1" :class="getValidationClass('Rodzaj')">
                <label for="Rodzaj">Grupa wyrobów / usług</label>
                <!--- edycja 7 linia-->
                <md-input
                  md-counter="200"
                  name="Rodzaj"
                  id="Rodzaj"
                  
                  v-model="form.Rodzaj"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Rodzaj.required"
                  >Pole jest wymagane</span
                >
                <span class="md-error" v-else-if="!$v.form.Rodzaj.maxlength"
                  >Wymagana ilość znaków od 1 do 200</span
                >
              </md-field>
            </div>
          </div>
        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

        <div style="text-align: center">
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            czyJestWTrybieEdycji
              ? "ZATWIERDŹ - EDYTOWANY WPIS"
              : "ZATWIERDŹ - UTWÓRZ WPIS"
          }}</md-button>
        </div>
      </md-card>
    </form>
  </div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import minLength from "vuelidate/lib/validators/minLength";
import { api } from "../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_maszyny",

  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      Rodzaj: null,
    },
    IdEdytowanegoElementu: null,
    userSaved: false,
    sending: false,
    lastUser: null,
  }),
  created: function() {
    if (this.obiektKonfiguracyjny.akcja === "edycja") {
      //edycja
      api
        .pobieranieGrupyWyrobowEdycja(this.obiektKonfiguracyjny.Id)
        .then((odpowiedz) => {
          const { Id, Rodzaj } = odpowiedz.data.dane[0];
          this.IdEdytowanegoElementu = Id;
          this.form.Rodzaj = Rodzaj;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  validations: {
    form: {
      Rodzaj: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(200),
      },
    },
  },
  computed: {
    czyJestWTrybieEdycji: function() {
      return this.obiektKonfiguracyjny.akcja === "edycja";
    },
  },

  methods: {
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.Rodzaj = null;
    },

    zapiszFormularz() {
      this.sending = true;

      if (this.czyJestWTrybieEdycji) {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Rodzaj", this.form.Rodzaj);

        api
          .zapiszGrupyWyrobowUpdate(dane, this.IdEdytowanegoElementu)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Rodzaj", this.form.Rodzaj);

        api
          .zapiszGrupyWyrobow(dane)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    naszWalidacjaFormularza() {
      if (this.czyJestWTrybieEdycji) {
        return !this.$v.form.Rodzaj.$invalid;
      } else {
        return !this.$v.$invalid;
      }
    },
    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>

<style>
.FORM_grupyWyr .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
.FORM_grupyWyr .md-layout .md-gutter {
  min-width: 55vw !important; /* szerokość modala*/
  max-width: 100% !important;
  min-height: 7vh !important; /* wysokość pomiędzy mierszami w modalu*/
}
.md-layout {
  padding-right: 0px !important;
}
.FORM_grupyWyr .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}

.FORM_grupyWyr .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  margin: 10px 24px !important;
  padding-top: 35px !important;
}
.FORM_grupyWyr .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}

.FORM_grupyWyr {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
  overflow: auto;
}
.FORM_grupyWyr .md-primary {
  border: 1px solid !important;

  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}
.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important; /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}

.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}
.md-list-item-text {
  font-size: 1.7vmin !important;
}
.FORM_grupyWyr .md-card-actions .md-button {
  margin-right: 4vw !important;
}
</style>
