<template>
  <div>
  
  <tabelaAbsencja/>

  </div>
</template>

<script>

import TabelaAbsencja from '../../komponenty/strona-tabela-komponenty/HR_absencja/tabela_absencja.vue'


export default {
  name: 'StronaTabelaAbsencja',
  components: {
    
      TabelaAbsencja,
  }

}
</script>
