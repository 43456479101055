<template>
  <div>
  <TabelaProcesyPrzeglady />
  </div>
</template>

<script>
import TabelaProcesyPrzeglady from '../../komponenty/strona-tabela-komponenty/SYS_procesy/tabela_procesy_przeglady.vue'


export default {
  name: 'StronaTabelaProcesyPrzeglady',
  components: {
    TabelaProcesyPrzeglady,
    
  }

}
</script>
