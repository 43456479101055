<template>
  <div>
  <TabelaSamochodyOsobowe />
  </div>
</template>

<script>
import TabelaSamochodyOsobowe from '../../komponenty/strona-tabela-komponenty/WST_samochody_osobowe/tabela_samochody_osobowe.vue'


export default {
  name: 'StronaTabelaSamochodyOsobowe',
  components: {
    TabelaSamochodyOsobowe,
  }

}
</script>
