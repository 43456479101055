<template >
  <div>
  <TabelaPrzegladyDokumenty />
  </div>
</template>

<script>
import TabelaPrzegladyDokumenty from '../../komponenty/strona-tabela-komponenty/SYS_dokumentacja/tabela_dokumentacja_przeglady.vue'


export default {
  name: 'StronaTabelaPrzegladyDokumenty',
  components: {
    TabelaPrzegladyDokumenty,
    
  }

}
</script>

