<template>
  <div>
  <tabelaSkierowanie/>

  </div>
</template>

<script>
import tabelaSkierowanie from '../../komponenty/strona-tabela-komponenty/HR_badania/skierowanie.vue'


export default {
  name: 'StronaTabelaSkierowanie',
  components: {
       tabelaSkierowanie,

  }

}
</script>
