<template >
  <div>
  <TabelaAspektyWymagania />
  </div>
</template>

<script>
import TabelaAspektyWymagania from '../../komponenty/strona-tabela-komponenty/SYS_aspekty/tabela_aspekty_wymagania.vue'


export default {
  name: 'StronaTabelaAspektyWymagania',
  components: {
    TabelaAspektyWymagania,
    
  }

}
</script>

