<template>
  <div class="tabela_raporty_realizacja">
    <div class="laczacy">
      <div class="menu_strony">
        <button>- 
        </button>


      </div>

      <div class="menu_strony1">
      
      </div>
    </div>

    <modalHR
      v-if="pokazDialog"
      :rodzajFormularza="typAktywnegoFormularza"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />

    <div>
      <DataTable
        :value="searched"
        :expandedRows.sync="expandedRows"

        @row-expand="onRowExpand"
        @row-collapse="onRowCollapse"
        :selection.sync="wybranyElementTabeli"
        selectionMode="single"
        :metaKeySelection="false"
      >
        <template #header>
          <div>
            <h4>WYKAZ UPRAWNIEŃ UŻYTKOWNIKA SZCZEGÓŁY</h4>
            <md-input
              placeholder="Szukaj..."
              v-model="search"
              @input="searchOnTable"
            />
            
                <!-- <b>
                  <b-icon-trash
                    class="ikony"
                    font-scale="1.8"
                    color="white"
                    @click="usuwanieElementu(wybranyElementTabeli.Id)"
                  >
                  </b-icon-trash>
                  <md-tooltip class="podpowiedz" md-direction="top"
                    >USUWANIE ELEMENTU</md-tooltip
                  >
                </b> -->
                <b>
                  <b-icon-pencil-square
                    class="ikony"
                    font-scale="1.8"
                    padding="10px"
                    color="white"
                    @click="
                      pokazDialog = true;
                      typAktywnegoFormularza = 'formularz_dodawanie_zamowienia';
                      obiektKonfiguracyjny = {
                        Id: wybranyElementTabeli.Id,
                        akcja: 'edycja',
                      };
                    "
                  >
                  </b-icon-pencil-square>
                  <md-tooltip class="podpowiedz" md-direction="top"
                    >EDYCJA ELEMENTU</md-tooltip
                  >
                </b>

                <b>
                  <b-icon-file-earmark-arrow-down
                    class="ikony"
                    font-scale="1.8"
                    color="white"
                    @click="pokazPDF(wybranyElementTabeli.Id)"
                  >
                  </b-icon-file-earmark-arrow-down>
                  <md-tooltip class="podpowiedz" md-direction="top"
                    >PODGLĄD PLIKU</md-tooltip
                  >
                </b>
              </div>
       
        </template>

        <Column
          field="Lp1"
          headerStyle="width:5%; font-size:1.1vh; text-align:left"
          header="Lp"
        >
        </Column>
        <Column
          field="nazwa"
          headerStyle="width:29%; font-size:1.1vh; text-align:left"
          header="Część systemu"
        >
        </Column>
        <Column
          field="nazwisko"
          headerStyle="width:11%; font-size:1.1vh; text-align:left"
          header="Nazwisko"
        >
        </Column>
        <Column
          field="imie"
          headerStyle="width:11%; font-size:1.1vh; text-align:left"
          header="Imię"
        >
        </Column>

        <Column
          field="dodawanie"
          headerStyle="width:11%; font-size:1.1vh; text-align:left"
          header="DODAWANIE"
        >
>
        </Column>
        <Column
          field="usuwanie"
          headerStyle="width:11%; font-size:1.1vh; text-align:left"
          header="USUWANIE"
        >
>
        </Column>
        <Column
          field="edycja"
          headerStyle="width:11%; font-size:1.1vh; text-align:left"
          header="EDYCJA"
        >

        </Column>

        <Column
          field="przegladanie"
          headerStyle="width:11%; font-size:1.1vh; text-align:left"
          header="PRZEGLĄDANIE"
        >
 
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import modalHR from "../../strona-formularze/modalHR.vue";
import { api } from "../../../serwisy/api.serwis";
import jsPDF from "jspdf";
import "jspdf-autotable";

const toLower = (text) => {
  return text.toString().toLowerCase();
};

const search = (items, term) => {
  if (term) {
    return items.filter((item) => {
      const elementWyszukiwany = toLower(term);
      const Nazwa = toLower(item.Nazwa);

      return Nazwa.includes(elementWyszukiwany);
    });
  }

  return items;
};

export default {
  name: "tabela_wykaz_procesow",
  data: () => ({
    czyPobranoDane: false,
    search: null,
    searched: [],
    Procesy: [],
    pokazDialog: false,
    typAktywnegoFormularza: null,
    obiektKonfiguracyjny: null,
    wybranyElementTabeli: null,
    expandedRows: [],
    staraWartoscWybranyegolementTabeli: null,
  }),
  components: {
    modalHR,
  },
  mounted: function () {
    this.pobierzDaneDoTabeli();
  },
  computed: {
    //informacja czy jest w trybie edycji
    czyJestWTrybieEdycji() {
      return this.$router.params.Id ? true : false;
    },
  },

  methods: {
    onRowExpand() {
      // this.$toast.add({severity: 'info', summary: 'Product Expanded', detail: event.data.name, life: 3000});
    },
    onRowCollapse() {
      // this.$toast.add({severity: 'success', summary: 'Product Collapsed', detail: event.data.name, life: 3000});
    },
    expandAll() {
      this.expandedRows = this.products.filter((p) => p.id);
      // this.$toast.add({severity: 'success', summary: 'All Rows Expanded', life: 3000});
    },
    collapseAll() {
      this.expandedRows = null;
      //this.$toast.add({severity: 'success', summary: 'All Rows Collapsed', life: 3000});
    },
    formatCurrency(value) {
      return value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },

    generujPdf() {
      const doc = new jsPDF("l", "mm", "a4");

      doc.setFont("Aleo-Regular");

      doc.text("Wykaz okresów celów systemu zarządzania", 14, 10);
      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [["Nr oferty"]],
        body: this.searched.map((Oferty) => {
          return [Oferty.Cena_jednostkowa];
        }),
      });

      doc.save("wykaz_okresow_celow.pdf");
    },

    pobierzDaneDoTabeli() {
      const { id, id_typu } = this.$route.params;

      console.log(this.$route);

      api
        .pobieranieUzytkownikSzczegoly(id, id_typu)
        .then((obiektOdpowiedzi) => {
          console.log(obiektOdpowiedzi);
            const dane = {};

        obiektOdpowiedzi.data.dane.forEach((proces) => {
          if(!dane[proces.Id_szczegolowe]){
            dane[proces.Id_szczegolowe] = {
              nazwa: proces.Nazwa, 
              imie: proces.Imie,
              nazwisko: proces.Nazwisko
             
            }
          }

          switch(proces.Id_typu){
              case '1': 
              dane[proces.Id_szczegolowe].dodawanie = 'TAK' 
              break;
                case '2': 
              dane[proces.Id_szczegolowe].usuwanie = 'TAK'
              break;
              case '3': 
              dane[proces.Id_szczegolowe].edycja = 'TAK'
              break;
              case '4': 
              dane[proces.Id_szczegolowe].przegladanie = 'TAK'
              break;
            }
        })

         this.Procesy = Object.values(dane).map((proces, index) => ({...proces, Lp1: index +1}))
          this.searched = this.Procesy;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.czyPobranoDane = true;
        });
    },
    funkcjaZamykajacaDialog(sukces) {
      this.pokazDialog = false;
      this.typAktywnegoFormularza = null;
      this.obiektKonfiguracyjny = null;
      if (sukces) {
        this.pobierzDaneDoTabeli();
      }
    },
    newUser() {
      window.alert("Noop");
    },
    searchOnTable() {
      this.searched = search(this.Procesy, this.search);
    },
    usuwanieElementu(Id) {
      this.pokazDialog = true;
      this.typAktywnegoFormularza = "usuwanie";
      this.obiektKonfiguracyjny = {
        Id,
        typ: "wymagania_prawne",
      };
    },
    pokazPDF(Id) {
      //dopisane
      this.pokazDialog = true;
      this.typAktywnegoFormularza = "pokaz_PDF";
      this.obiektKonfiguracyjny = {
        Id,
        Klucz: "test_PDF",
      };
    },
    wyborElementu(element) {
      this.wybranyElementTabeli = element;
    },
  },
};
</script>

<style>
.tabela_raporty_realizacja {
  height: 78vh;
}
.tab-content mt-3 {
  width: 100%;
  height: 10vh !important;
}
.p-datatable-wrapper {
  overflow: auto !important;
  height: 60vh;
}
.tabela_raporty_realizacja .md-card {
  box-shadow: none !important;
}
.tabela_raporty_realizacja .md-title {
  font-weight: 900 !important;
  color: black !important;
  font-size: 1.4vh !important;
  padding: 10px;
}
.tabela_raporty_realizacja .md-toolbar {
  height: 14vh;
}
.tabela_raporty_realizacja .md-table-head {
  font-weight: bold;
  color: black !important;
  font-size: 1.1vh !important;
  text-align: left !important;
}
.tabela_raporty_realizacja .md-table-head-label {
  text-align: left !important;
  font-size: 1.1vh !important;
}
.tabela_raporty_realizacja .md-table-cell {
  font-size: 1.1vh !important;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-align: left;
  color: black;
  font-weight: 300;
  margin-top: 200px !important;
}
.tabela_raporty_realizacja .ikony {
  cursor: pointer;
  margin-right: 40px;
}

.tabela_raporty_realizacja .button {
  margin-right: 5px;
  border: 3px white ridge;
  font-weight: bold;
  font-size: 1.1vmin;
  min-width: 9vw;
}
.tabela_raporty_realizacja .button:hover {
  background-color: #727171;
  text-decoration: none;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  color: white;
}
.tabela_raporty_realizacja .menu_strony {
  border: 1px solid;
  margin-top: 5px;
  padding: 3px;
  background: #939393;
  width: 84.5%;
}
.tabela_raporty_realizacja .menu_strony1 {
  border: 1px solid;
  margin-top: 5px;
  padding: 3px;
  background: #939393;
  width: 15%;
  margin-left: 0.5%;
}
.tabela_raporty_realizacja .laczacy {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.md-table.md-theme-default .md-table-row.md-selected,
.md-table.md-theme-default .md-table-row.md-selected-single {
  background-color: #dddddd !important;
}
.PasekWyboru {
  background: #606060;
  padding: 2px;
}
.tabela_raporty_realizacja .ikony {
  margin-right: 10px;
  border: 1px solid !important;
  border-radius: 5px;
  padding: 5px;
}
.tabela_raporty_realizacja .ikony:hover {
  background-color: black;
  text-decoration: none;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
.podpowiedz {
  font-size: 1.5vmin !important;
  font-weight: bold;
  background-color: black !important;
}
.tabela_raporty_realizacja .nav-item {
  font-weight: bold;
  font-size: 1.5vh;
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: silver !important;
  color: black !important;
}
.tabela_raporty_realizacja .md-input {
  width: 20vw !important;
  margin-bottom: 10px;
}
.tabela_raporty_realizacja .p-datatable .p-datatable-tbody > tr > td {
  text-align: left !important;
  font-size: 1.1vh !important;
}
</style>