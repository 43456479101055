<template>
  <TabelaPrzegladWymaganiaPrawne />
</template>

<script>
import TabelaPrzegladWymaganiaPrawne from '../../komponenty/strona-tabela-komponenty/SYS_wymagania_prawne/tabela_przeglad_wymagania_prawne.vue'



export default {
  name: 'StronaTabelaPrzegladWymaganiaPrawne',
  components: {
    TabelaPrzegladWymaganiaPrawne,
  }

}
</script>
