<template>
  <div class="ElementyWyboruBHP">
    <b-container fluid class="p-0">
      <b-row>
        <b-col sm="12" md="2">
          <KafelekWyboruBHP
            sciezkaZdjecia="Zdjecia/o-orz.jpg"
            opisZdjecia="FajnaFotka"
            linkDoStrony="dokumentacja_ORZ"
            podpisPodKafelkiem="OCENA RYZYKA ZAWODOWEGO"
          />
        </b-col>
        <b-col sm="12" md="2">
          <KafelekWyboruBHP
            sciezkaZdjecia="Zdjecia/o-badania_środowiska_pracy.jpg"
            opisZdjecia="FajnaFotka"
            linkDoStrony="dokumentacja_BSP"
            podpisPodKafelkiem="BADANIA ŚRODOWISKA PRACY"
          />
        </b-col>

        <b-col sm="12" md="2">
          <KafelekWyboruBHP
            sciezkaZdjecia="Zdjecia/o-sn.jpg"
            opisZdjecia="FajnaFotka"
            linkDoStrony="dokumentacja_substancje_niebezpieczne"
            podpisPodKafelkiem="SUBSTANCJE NIEBEZPIECZNE"
          />
        </b-col>
        <b-col sm="12" md="2">
          <KafelekWyboruBHP
            sciezkaZdjecia="Zdjecia/o-ocena_stanu_BHP.jpg"
            opisZdjecia="FajnaFotka"
            linkDoStrony="ocena_stanu_BHP"
            podpisPodKafelkiem="OCENA STANU BHP - ZESPÓŁ DS BHP"
          />
        </b-col>
        <b-col sm="12" md="2">
          <KafelekWyboruBHP
            sciezkaZdjecia="Zdjecia/o-wypadki_przy_pracy.jpg"
            opisZdjecia="FajnaFotka"
            linkDoStrony="wypadki_w_pracy"
            podpisPodKafelkiem="WYPADKI PRACOWNICZE"
          />
        </b-col>
        <b-col sm="12" md="2">
          <KafelekWyboruBHP
            sciezkaZdjecia="Zdjecia/o-choroby_zawodowe.jpg"
            opisZdjecia="FajnaFotka"
            linkDoStrony="choroby_zawodowe"
            podpisPodKafelkiem="CHOROBY ZAWODOWE"
          />
        </b-col>
        <b-col sm="12" md="2">
          <KafelekWyboruBHP
            sciezkaZdjecia="Zdjecia/o-prace_niebezpieczne2.jpg"
            opisZdjecia="FajnaFotka"
            linkDoStrony="sytuacje_potencjalnie_niebezpieczne"
            podpisPodKafelkiem="ZDARZENIA POTENCJALNIE NIEBEZPIECZNE"
          />
        </b-col>
        <b-col sm="12" md="2">
          <KafelekWyboruBHP
            sciezkaZdjecia="Zdjecia/o-kontrole_BHP.jpg"
            opisZdjecia="FajnaFotka"
            linkDoStrony="wykaz_kontroli_wewnetrznych"
            podpisPodKafelkiem="KONTROLE WEWNĘTRZNE BHP"
          />
        </b-col>
        <b-col sm="12" md="2">
          <KafelekWyboruBHP
            sciezkaZdjecia="Zdjecia/o-przeglad.jpg"
            opisZdjecia="FajnaFotka"
            linkDoStrony="wykaz_kontroli_zewnetrznych"
            podpisPodKafelkiem="KONTROLE ZEWNĘTRZNE BHP"
          />
        </b-col>
        <b-col sm="12" md="2">
          <KafelekWyboruBHP
            sciezkaZdjecia="Zdjecia/o-prace_niebezpieczne.jpg"
            opisZdjecia="FajnaFotka"
            linkDoStrony="dokumentacja_prace_szczegolnie_niebezpieczne"
            podpisPodKafelkiem="WYKAZ PRAC SZCZEGÓLNIE NIEBEZPIECZNYCH"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import KafelekWyboruBHP from "./Kafelek_wyboru_BHP.vue";

export default {
  name: "ElementyWyboruBHP",
  components: {
    KafelekWyboruBHP,
  },
};
</script>

<style scoped>
.ElementyWyboruBHP {
  margin-top: 1%;
  display: flex;
  flex-flow: row wrap;

}
</style>
