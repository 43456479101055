<template >
  <div>
  <TabelaWykazPracownicyBadania />
  </div>
</template>

<script>
import TabelaWykazPracownicyBadania from '../../komponenty/strona-tabela-komponenty/HR_pracownicy/tabela_badania.vue'


export default {
  name: 'StronaTabelaWykazBadan',
  components: {
    TabelaWykazPracownicyBadania,
    
  }

}
</script>

