<template>
  <div>
  <TabelaBudynkiPrzegladInstrukcja/>
  </div>
</template>

<script>
import TabelaBudynkiPrzegladInstrukcja from '../../komponenty/strona-tabela-komponenty/WST_budynki/tabela_budynki_przeglady_IBP.vue'


export default {
  name: 'StronaTabelaBudynkiPrzegladInstrukcja',
  components: {
    TabelaBudynkiPrzegladInstrukcja,
  }

}
</script>
