<template>
  <div>
  <TabelaFaktury/>
  </div>
</template>

<script>
import TabelaFaktury from '../../komponenty/strona-tabela-komponenty/FAK_faktury/tabela_faktury.vue'


export default {
  name: 'StronaTabelaFaktury',
  components: {
    TabelaFaktury,
  }

}
</script>
