<template >
  <div>
  <TabelaDokumentyORZ />
  </div>
</template>

<script>
import TabelaDokumentyORZ from '../../komponenty/strona-tabela-komponenty/BHP_orz/tabela_dokumentacja_orz.vue'


export default {
  name: 'StronaTabelaDokumentyORZ',
  components: {
    TabelaDokumentyORZ,
    
  }

}
</script>

