<template >
  <div>
  <TabelaPrzegladyInstrukcjeSrodowiskowe />
  </div>
</template>

<script>
import TabelaPrzegladyInstrukcjeSrodowiskowe from '../../komponenty/strona-tabela-komponenty/SYS_instrukcje_srodowiskowe/tabela_instrukcje_srodowiskowe_przeglady.vue'


export default {
  name: 'StronaTabelaPrzegladyInstrukcjeSrodowiskowe',
  components: {
    TabelaPrzegladyInstrukcjeSrodowiskowe,
    
  }

}
</script>

