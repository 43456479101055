<template>
  <div>
  <TabelaSamochodyCiezaroweTacho />
  </div>
</template>

<script>
import TabelaSamochodyCiezaroweTacho from '../../komponenty/strona-tabela-komponenty/WST_samochody_ciezarowe/tabela_samochody_ciezarowe_tachografy.vue'


export default {
  name: 'StronaTabelaSamochodyCiezaroweTacho',
  components: {
    TabelaSamochodyCiezaroweTacho,
  }

}
</script>
