<template>
  <div class='pasek_dolny'>
    <Stopka />
  </div>
</template>

<script>

import Stopka from './pasek-dolny-komponenty/Stopka.vue'

export default {
  name: 'PasekDolny',
  components: {
    Stopka
  }
}
</script>

<style scoped>
    .pasek_dolny {
        width: 90%;
        height: 30px;
        background-color: #606060;
        margin: 10px auto;
        position: absolute;
        bottom: 10px;
    }
    @media print {
  .pasek_dolny {
    display: none;
  }
}
</style>
