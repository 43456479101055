<template >
  <div>
  <TabelaDzialaniaMagazyn/>
  </div>
</template>

<script>
import TabelaDzialaniaMagazyn from '../../komponenty/strona-tabela-komponenty/SO_realizacja_magazyn/tabela_wykaz_dzialania_magazyn.vue'


export default {
  name: 'StronaTTabelaDzialaniaMagazyn',
  components: {
    TabelaDzialaniaMagazyn,
    
  }

}
</script>

