<template >
  <div class="Cala_strona_dane_wejsciowe">
  <TabelaCeleStrategiczne />
  <TabelaDaneWejsciowe />
  <TabelaDaneWyjsciowe />
  </div>
</template>

<script>

import TabelaCeleStrategiczne from '../../komponenty/strona-tabela-komponenty/SYS_procesy/tabela_procesy_cele_strategiczne.vue'
import TabelaDaneWejsciowe from '../../komponenty/strona-tabela-komponenty/SYS_procesy/tabela_procesy_dane_wejsciowe.vue'
import TabelaDaneWyjsciowe from '../../komponenty/strona-tabela-komponenty/SYS_procesy/tabela_procesy_dane_wyjsciowe.vue'


export default {
  name: 'StronaTabelaProcesyDaneWejsciowe',
  components: {
    TabelaCeleStrategiczne,
    TabelaDaneWejsciowe,
    TabelaDaneWyjsciowe,

    
  }

}
</script>

<style scoped>
.Cala_strona_dane_wejsciowe
{
  height: 75vh;
  overflow: auto;
}
.a1_dane_wejsciowe {
display: block;
float: left;
height: 40vh;
overflow: auto;
}
.a2_dane_wyjsciowe {
display: block;
float: right;
height: 40vh;
overflow: auto;
}
.a3_cele_strategiczne{
height: 50vh;
overflow: auto;
}
</style>