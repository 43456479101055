<template >
  <div>
  <TabelaZapytaniaPrzyjete/>
  </div>
</template>

<script>
import TabelaZapytaniaPrzyjete from '../../komponenty/strona-tabela-komponenty/SO_zapytania_ofertowe/tabela_wykaz_zapytan_ofertowych_przyjetych.vue'


export default {
  name: 'StronaTabelaZapytaniaPrzyjete',
  components: {
    TabelaZapytaniaPrzyjete,
    
  }

}
</script>

