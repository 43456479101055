<template>
  <div class="Cala_strona">
  
  <TabelaPrzegladKontekstuHistoria />
  

 
  </div>
</template>

<script>
import TabelaPrzegladKontekstuHistoria from '../../komponenty/strona-tabela-komponenty/SYS_kontekst/tabela_przeglad_kontekstu_historia.vue'


export default {
  name: 'StronaTabelaPrzegladKontekstu',
  components: {
    TabelaPrzegladKontekstuHistoria,

    

  }

}
</script>
