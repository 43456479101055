const { default: axios } = require("axios");

// export const podstawaLinku = "http://localhost/projekt-1/projekt-1-backend/api";
export const podstawaLinku = "https://qmdsystem.pl/api";

export const api = {
  // LOGOWANIE
  pobieranieFirmy: NIP => {
    return axios.post(`${podstawaLinku}/00_00_HASZOWANIE/01_01_01_haszowanie_logowanie.php?NIP=${NIP}`)
},

  logowanie: (dane) => {
    return axios.post(
      `${podstawaLinku}/00_00_LOGOWANIE/01_01_02_logowanie.php`,
      dane,
      { withCredentials: true }
    );
  },
  logowanieZmianaHasla: (dane) => {
    return axios.post(
      `${podstawaLinku}/00_00_LOGOWANIE/01_01_03_logowanie_zmiana_hasla.php`,
      dane,
      { withCredentials: true }
    );
  },
  logowanieStatus: () => {
    return axios.get(
      `${podstawaLinku}/00_00_LOGOWANIE/01_01_04_status_logowania.php`,
      { withCredentials: true }
    );
  },
  wylogowanie: () => {
    return axios.get(
      `${podstawaLinku}/00_00_LOGOWANIE/01_01_05_wylogowanie.php`,
      { withCredentials: true }
    );
  },

  sprawdzenieDostepnosci: (dane) => {
    return axios.post(
      `${podstawaLinku}/00_00_LOGOWANIE/01_01_04_status_dostepnosci.php`,
      dane,
      { withCredentials: true }
    );
  },

  autoryzacjaPoziom1: (dane) => {
    return axios.post(
      `${podstawaLinku}/00_00_LOGOWANIE/01_01_04_status_autoryzacja_poziom1.php`,
      dane,
      { withCredentials: true }
    );
  },
  autoryzacjaPoziom2: (dane) => {
    return axios.post(
      `${podstawaLinku}/00_00_LOGOWANIE/01_01_04_status_autoryzacja_poziom2.php`,
      dane,
      { withCredentials: true }
    );
  },
  autoryzacjaPoziom3: (dane) => {
    return axios.post(
      `${podstawaLinku}/00_00_LOGOWANIE/01_01_04_status_autoryzacja_poziom3.php`,
      dane,
      { withCredentials: true }
    );
  },
  autoryzacjaPoziom4: (dane) => {
    return axios.post(
      `${podstawaLinku}/00_00_LOGOWANIE/01_01_04_status_autoryzacja_poziom4.php`,
      dane,
      { withCredentials: true }
    );
  },

  // LOGOWANIE-UZYTKOWNIK

  pobieranieOsobLogowanie: (Id) => {
    return axios.get(
      `${podstawaLinku}/00_00_LOGOWANIE/01_01_01_wykaz_osob_logowanie.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszOsobLogowanie: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/00_00_LOGOWANIE/01_02_02_dodawanie_lokalizacji.php`,
      elementyFormularza
    );
  },
  usuwanieOsobLogowanie: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/00_00_USTALANIE_DANYCH/01_03_delete_uzytkownika.php`,
      obiektId
    );
  },
  pobieranieOsobLogowanieEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/00_00_LOGOWANIE/01_02_04_lokalizacje_edycja.php?Id=${Id}`
    );
  },
  zapiszOsobLogowanieUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/00_00_LOGOWANIE/01_02_05_dodawanie_lokalizacji_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  pobieranieUzytkownikSzczegoly: (Id, id_typu) => {
    return axios.get(
      `${podstawaLinku}/00_00_LOGOWANIE/02_01_01_Wykaz_logowanie_szczegoly.php?Id=${Id}&Id_typu=${id_typu}`
    );
  },

  // UŻYTTKOWNICY

  pobierzWyborUprawnien: () => {
    return axios.get(`${podstawaLinku}/funkcje_wyboru/api_wybor_uprawnien.php`);
  },
  pobierzNazwa_organizacji: () => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_wybor_uprawnien_firma.php`
    );
  },
  pobierzNazwa_uzytkownika: () => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_wybor_uzytkownika.php`
    );
  },

  zapiszNoweUprawnienia: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/00_00_USTALANIE_DANYCH/01_02_dodawanie_uzytkownika.php`,
      elementyFormularza
    );
  },

  pobieranieUprawnieniaEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/00_00_USTALANIE_DANYCH/01_04_edycja_uzytkownika.php?Id=${Id}`
    );
  },
  zapiszUprawnieniaUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/00_00_USTALANIE_DANYCH/01_05_update_uzytkownika.php?Id=${Id}`,
      elementyFormularza
    );
  },
   
  //KONTEKST_______________________________________________________________________________________________________________________________________________________________

  pobierzLokalizacje: () => {
    return axios.get(
      `${podstawaLinku}/01_01_kontekst/00_01_wybor_lokalizacji.php`
    );
  },

  // KONTEKST PRZEGLADY

  pobieraniePrzegladuKontekstu: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_01_kontekst/01_01_00_przeglady_kontekstu.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieraniePrzegladuKontekstuHistoria: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_01_kontekst/01_01_01_przeglady_kontekstu_historia.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszPrzegladKontekstu: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/01_01_kontekst/01_01_02_dodawanie_przegladu_kontekstu.php`,
      elementyFormularza
    );
  },
  usuwaniePrzegladuKontekst: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_01_kontekst/01_01_03_delete_przegladu_kontekst.php`,
      obiektId
    );
  },
  pobieraniePrzegladuKontekstuEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_01_kontekst/01_01_04_przeglad_kontekstu_edycja.php?Id=${Id}`
    );
  },
  zapiszPrzegladuKontekstuUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_01_kontekst/01_01_05_dodawanie_przegladu_kontekstu_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // KONTEKST - LOKALIZACJA

  pobieranieLokalizacjiKontekstu: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_01_kontekst/01_02_01_lokalizacja_kontekstu.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszLokalizacje: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/01_01_kontekst/01_02_02_dodawanie_lokalizacji.php`,
      elementyFormularza
    );
  },
  usuwanieLokalizacjaKontekst: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_01_kontekst/01_02_03_delete_lokalizacja_kontekst.php`,
      obiektId
    );
  },
  pobieranieLokalizacjiEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_01_kontekst/01_02_04_lokalizacje_edycja.php?Id=${Id}`
    );
  },
  zapiszLokalizacjeUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_01_kontekst/01_02_05_dodawanie_lokalizacji_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // KONTEKST - ZAKRES

  pobieranieZakres: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_01_kontekst/01_03_01_zakres.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszZakres: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/01_01_kontekst/01_03_02_dodawanie_zakresu.php`,
      elementyFormularza
    );
  },
  usuwanieZakresKontekst: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_01_kontekst/01_03_03_delete_zakres_kontekst.php`,
      obiektId
    );
  },
  pobieranieZakresuEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_01_kontekst/01_03_04_zakres_edycja.php?Id=${Id}`
    );
  },
  zapiszZakresUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_01_kontekst/01_03_05_dodawanie_zakresu_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // KONTEKST - GRANICE

  pobieranieGranice: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_01_kontekst/01_11_01_granice.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszGranice: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/01_01_kontekst/01_11_02_dodawanie_granic.php`,
      elementyFormularza
    );
  },
  usuwanieGraniceKontekst: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_01_kontekst/01_11_03_delete_granice_kontekst.php`,
      obiektId
    );
  },
  pobieranieGraniceEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_01_kontekst/01_11_04_granice_edycja.php?Id=${Id}`
    );
  },
  zapiszGraniceUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_01_kontekst/01_11_05_dodawanie_granice_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // KONTEKST - NORMY

  pobieranieNormy: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_01_kontekst/01_04_01_normy.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszNormy: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/01_01_kontekst/01_04_02_dodawanie_normy.php`,
      elementyFormularza
    );
  },
  usuwanieNormyKontekst: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_01_kontekst/01_04_03_delete_normy_kontekst.php`,
      obiektId
    );
  },
  pobieranieNormyEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_01_kontekst/01_04_04_normy_edycja.php?Id=${Id}`
    );
  },
  zapiszNormyUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_01_kontekst/01_04_05_dodawanie_normy_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // KONTEKST - WYŁĄCZENIA

  pobieranieWylaczenia: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_01_kontekst/01_05_01_wylaczenia.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszWylaczenia: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/01_01_kontekst/01_05_02_dodawanie_wylaczenia.php`,
      elementyFormularza
    );
  },
  usuwanieWylaczeniaKontekst: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_01_kontekst/01_05_03_delete_wylaczenia_kontekst.php`,
      obiektId
    );
  },
  pobieranieWylaczeniaEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_01_kontekst/01_05_04_wylaczenia_edycja.php?Id=${Id}`
    );
  },
  zapiszWylaczeniaUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_01_kontekst/01_05_05_dodawanie_wylaczenia_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // KONTEKST - PROCESY ZEWNETRZNE

  pobieranieProcesowZewnetrznych: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_01_kontekst/01_10_01_procesy_zewnetrzne.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszProcesyZewnetrzne: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/01_01_kontekst/01_10_02_dodawanie_procesy_zewnetrzne.php`,
      elementyFormularza
    );
  },
  usuwanieProcesyZewnetrzneKontekst: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_01_kontekst/01_10_03_delete_procesy_zewnetrzne_kontekst.php`,
      obiektId
    );
  },
  pobieranieProcesyZewnetrzneEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_01_kontekst/01_10_04_procesy_zewnetrzne_edycja.php?Id=${Id}`
    );
  },
  zapiszProcesyZewnetrzneUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_01_kontekst/01_10_05_dodawanie_procesy_zewnetrzne_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // KONTEKST - MOCNE STRONY

  pobieranieMocnychStron: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_01_kontekst/01_06_01_mocne_strony.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszMocnychStron: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/01_01_kontekst/01_06_02_dodawanie_mocne_strony.php`,
      elementyFormularza
    );
  },
  usuwanieMocnychStronKontekst: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_01_kontekst/01_06_03_delete_mocne_strony_kontekst.php`,
      obiektId
    );
  },
  pobieranieMocnychStronEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_01_kontekst/01_06_04_mocne_strony_edycja.php?Id=${Id}`
    );
  },
  zapiszMocnychStronUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_01_kontekst/01_06_05_dodawanie_mocne_strony_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // KONTEKST - SŁABE STRONY

  pobieranieSlabychStron: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_01_kontekst/01_07_01_slabe_strony.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszSlabychStron: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/01_01_kontekst/01_07_02_dodawanie_slabe_strony.php`,
      elementyFormularza
    );
  },
  usuwanieSlabychStronKontekst: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_01_kontekst/01_07_03_delete_slabe_strony_kontekst.php`,
      obiektId
    );
  },
  pobieranieSlabychStronEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_01_kontekst/01_07_04_slabe_strony_edycja.php?Id=${Id}`
    );
  },
  zapiszSlabychStronUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_01_kontekst/01_07_05_dodawanie_slabe_strony_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // KONTEKST - WSZ

  pobieranieWSZ: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_01_kontekst/01_08_01_WSZ.php${Id ? "?Id=" + Id : ""}`
    );
  },
  zapiszWSZ: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/01_01_kontekst/01_08_02_dodawanie_WSZ.php`,
      elementyFormularza
    );
  },
  usuwanieWSZKontekst: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_01_kontekst/01_08_03_delete_WSZ_kontekst.php`,
      obiektId
    );
  },
  pobieranieWSZEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_01_kontekst/01_08_04_WSZ_edycja.php?Id=${Id}`
    );
  },
  zapiszWSZUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_01_kontekst/01_08_05_dodawanie_WSZ_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // KONTEKST - ZSZ

  pobieranieZSZ: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_01_kontekst/01_09_01_ZSZ.php${Id ? "?Id=" + Id : ""}`
    );
  },
  zapiszZSZ: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/01_01_kontekst/01_09_02_dodawanie_ZSZ.php`,
      elementyFormularza
    );
  },
  usuwanieZSZKontekst: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_01_kontekst/01_09_03_delete_ZSZ_kontekst.php`,
      obiektId
    );
  },
  pobieranieZSZEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_01_kontekst/01_09_04_ZSZ_edycja.php?Id=${Id}`
    );
  },
  zapiszZSZUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_01_kontekst/01_09_05_dodawanie_ZSZ_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //PROCESY _________________________________________________________________________________________________________________________________________________

  //Pobieranie procesu

  pobierzProces: () => {
    return axios.get(`${podstawaLinku}/01_02_procesy/00_01_wybor_procesu.php`);
  },
  pobierzCeleStrategiczne: (id) => {
    return axios.get(
      `${podstawaLinku}/01_02_procesy/00_01_wybor_celu_strategicznego.php?Id=${id}`
    );
  },

  // Procesy

  pobieranieProcesu: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_02_procesy/01_01_01_wykaz_procesow.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieranieProcesu1: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_02_procesy/01_01_01_wykaz_procesow1.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },

  zapiszProces: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/01_02_procesy/01_01_02_dodawanie_procesu.php`,
      elementyFormularza
    );
  },
  usuwanieProcesy: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_02_procesy/01_01_03_delete_procesu.php`,
      obiektId
    );
  },
  pobieranieProcesuEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_02_procesy/01_01_04_procesy_edycja.php?Id=${Id}`
    );
  },
  zapiszProcesUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_02_procesy/01_01_05_dodawanie_procesu_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // Procesy Przeglad

  pobieranieProcesuPrzeglad: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_02_procesy/01_02_01_wykaz_przegladow_procesow.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszProcesPrzeglad: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/01_02_procesy/01_02_02_dodawanie_przegladu_procesu.php`,
      elementyFormularza
    );
  },
  usuwanieProcesuPrzeglad: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_02_procesy/01_02_03_delete_przeglad_procesu.php`,
      obiektId
    );
  },
  pobieranieProcesuPrzegladEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_02_procesy/01_02_04_procesy_przeglad_edycja.php?Id=${Id}`
    );
  },
  zapiszProcesPrzegladUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_02_procesy/01_02_05_dodawanie_przegladu_procesu_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // Procesy Szczegóły dane wejściowe

  pobieranieProcesuDaneWejsciowe: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_02_procesy/01_03_01_dane_wejsciowe_procesow.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszProcesDaneWejsciowe: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/01_02_procesy/01_03_02_dodawanie_dane_wejsciowe_procesu.php`,
      elementyFormularza
    );
  },
  usuwanieProcesuDaneWejsciowe: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_02_procesy/01_03_03_delete_dane_wejsciowe.php`,
      obiektId
    );
  },
  pobieranieProcesuDaneWejscioweEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_02_procesy/01_03_04_dane_wejsciowe_edycja.php?Id=${Id}`
    );
  },
  zapiszProcesDaneWejscioweUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_02_procesy/01_03_05_dodawanie_dane_wejsciowe_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // Procesy Szczegóły dane wyściowe

  pobieranieProcesuDaneWyjsciowe: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_02_procesy/01_04_01_dane_wyjsciowe_procesow.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszProcesDaneWyjsciowe: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/01_02_procesy/01_04_02_dodawanie_dane_wyjsciowe_procesu.php`,
      elementyFormularza
    );
  },
  usuwanieProcesuDaneWyjsciowe: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_02_procesy/01_04_03_delete_dane_wyjsciowe.php`,
      obiektId
    );
  },
  pobieranieProcesuDaneWyjscioweEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_02_procesy/01_04_04_dane_wyjsciowe_edycja.php?Id=${Id}`
    );
  },
  zapiszProcesDaneWyjscioweUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_02_procesy/01_04_05_dodawanie_dane_wyjsciowe_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // Procesy Szczegóły cele strategiczne
  PobierzLpCeluSzczegolowego: (konfiguracja) => {
    return axios.post(
      `${podstawaLinku}/funkcje_wyboru/api_pobierz_lp_cele.php`,
      konfiguracja
    );
  },

  pobieranieProcesuCeleStrategiczne: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_02_procesy/01_05_01_cele_strategiczne_procesow.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszProcesCeleStrategiczne: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/01_02_procesy/01_05_02_dodawanie_cele_strategiczne_procesu.php`,
      elementyFormularza
    );
  },
  usuwanieProcesuCeleStrategiczne: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_02_procesy/01_05_03_delete_cele_strategiczne.php`,
      obiektId
    );
  },
  pobieranieProcesuCeleStrategiczneEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_02_procesy/01_05_04_cele_strategiczne_edycja.php?Id=${Id}`
    );
  },
  zapiszProcesCeleStrategiczneUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_02_procesy/01_05_05_dodawanie_cele_strategiczne_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // Procesy Szczegóły cele strategiczne - Rozliczenie

  pobieranieProcesuRozliczenieCeleStrategiczne: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_02_procesy/01_06_01_wykaz_rozliczenia_celow.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },

  zapiszProcesRozliczenieCeleStrategiczne: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/01_02_procesy/01_06_02_dodawanie_rozliczenie_cele_strategiczne_procesu.php`,
      elementyFormularza
    );
  },
  usuwanieProcesuRozliczenieCeleStrategiczne: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_02_procesy/01_06_03_delete_rozliczenie_cele_strategiczne.php`,
      obiektId
    );
  },
  pobieranieProcesuRozliczenieCeleStrategiczneEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_02_procesy/01_06_04_rozliczenie_cele_strategiczne_edycja.php?Id=${Id}`
    );
  },
  zapiszProcesRozliczenieCeleStrategiczneUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_02_procesy/01_06_05_dodawanie_rozliczenie_cele_strategiczne_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //DOKUMENTACJA _________________________________________________________________________________________________________________________________________________

  //Pobieranie dokumentacji

  pobierzDokumentacje: () => {
    return axios.get(
      `${podstawaLinku}/01_03_dokumentacja/00_01_wybor_dokumentacji.php`
    );
  },

  // Dokumentacja

  pobieranieDokumentacja: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_03_dokumentacja/01_01_01_wykaz_dokumentacja.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszDokumentacja: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/01_03_dokumentacja/01_01_02_dodawanie_dokumentacja.php`,
      elementyFormularza
    );
  },
  usuwanieDokumentacja: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_03_dokumentacja/01_01_03_delete_dokumentacja.php`,
      obiektId
    );
  },
  pobieranieDokumentacjaEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_03_dokumentacja/01_01_04_dokumentacja_edycja.php?Id=${Id}`
    );
  },
  zapiszDokumentacjaUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_03_dokumentacja/01_01_05_dodawanie_dokumentacja_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // Dokumentacja Przeglad

  pobieranieDokumentacjaPrzeglad: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_03_dokumentacja/01_02_01_wykaz_przegladow_dokumentacja.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszDokumentacjaPrzeglad: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/01_03_dokumentacja/01_02_02_dodawanie_przegladu_dokumentacja.php`,
      elementyFormularza
    );
  },
  usuwanieDokumentacjaPrzeglad: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_03_dokumentacja/01_02_03_delete_przeglad_dokumentacja.php`,
      obiektId
    );
  },
  pobieranieDokumentacjaPrzegladEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_03_dokumentacja/01_02_04_dokumentacja_przeglad_edycja.php?Id=${Id}`
    );
  },
  zapiszDokumentacjaPrzegladUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_03_dokumentacja/01_02_05_dodawanie_przegladu_dokumentacja_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // INSTRUKCJE OPERACYJNE _________________________________________________________________________________________________________________________________________________

  //Pobieranie instrukcji Operacyjne

  pobierzIO: () => {
    return axios.get(
      `${podstawaLinku}/01_04_instrukcje_operacyjne/00_01_wybor_dokumentacji.php`
    );
  },

  // Instrukcja Operacyjne

  pobieranieIO: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_04_instrukcje_operacyjne/01_01_01_wykaz_dokumentacja.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszIO: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/01_04_instrukcje_operacyjne/01_01_02_dodawanie_dokumentacja.php`,
      elementyFormularza
    );
  },
  usuwanieIO: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_04_instrukcje_operacyjne/01_01_03_delete_dokumentacja.php`,
      obiektId
    );
  },
  pobieranieIOEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_04_instrukcje_operacyjne/01_01_04_dokumentacja_edycja.php?Id=${Id}`
    );
  },
  zapiszIOUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_04_instrukcje_operacyjne/01_01_05_dodawanie_dokumentacja_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // Instrukcje Przeglad Operacyjne

  pobieranieIOPrzeglad: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_04_instrukcje_operacyjne/01_02_01_wykaz_przegladow_dokumentacja.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszIOPrzeglad: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/01_04_instrukcje_operacyjne/01_02_02_dodawanie_przegladu_dokumentacja.php`,
      elementyFormularza
    );
  },
  usuwanieIOPrzeglad: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_04_instrukcje_operacyjne/01_02_03_delete_przeglad_dokumentacja.php`,
      obiektId
    );
  },
  pobieranieIOPrzegladEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_04_instrukcje_operacyjne/01_02_04_dokumentacja_przeglad_edycja.php?Id=${Id}`
    );
  },
  zapiszIOPrzegladUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_04_instrukcje_operacyjne/01_02_05_dodawanie_przegladu_dokumentacja_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // INSTRUKCJE ŚRODOWISKOWE _________________________________________________________________________________________________________________________________________________

  //Pobieranie instrukcji ŚrodowiskoweJ

  pobierzIS: () => {
    return axios.get(
      `${podstawaLinku}/01_05_instrukcje_srodowiskowe/00_01_wybor_dokumentacji.php`
    );
  },

  // Instrukcja Środowiskowe

  pobieranieIS: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_05_instrukcje_srodowiskowe/01_01_01_wykaz_dokumentacja.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszIS: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/01_05_instrukcje_srodowiskowe/01_01_02_dodawanie_dokumentacja.php`,
      elementyFormularza
    );
  },
  usuwanieIS: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_05_instrukcje_srodowiskowe/01_01_03_delete_dokumentacja.php`,
      obiektId
    );
  },
  pobieranieISEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_05_instrukcje_srodowiskowe/01_01_04_dokumentacja_edycja.php?Id=${Id}`
    );
  },
  zapiszISUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_05_instrukcje_srodowiskowe/01_01_05_dodawanie_dokumentacja_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // Instrukcje Przeglad Środowiskowe

  pobieranieISPrzeglad: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_05_instrukcje_srodowiskowe/01_02_01_wykaz_przegladow_dokumentacja.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszISPrzeglad: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/01_05_instrukcje_srodowiskowe/01_02_02_dodawanie_przegladu_dokumentacja.php`,
      elementyFormularza
    );
  },
  usuwanieISPrzeglad: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_05_instrukcje_srodowiskowe/01_02_03_delete_przeglad_dokumentacja.php`,
      obiektId
    );
  },
  pobieranieISPrzegladEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_05_instrukcje_srodowiskowe/01_02_04_dokumentacja_przeglad_edycja.php?Id=${Id}`
    );
  },
  zapiszISPrzegladUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_05_instrukcje_srodowiskowe/01_02_05_dodawanie_przegladu_dokumentacja_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // INSTRUKCJE BHP _________________________________________________________________________________________________________________________________________________

  //Pobieranie instrukcji BHP

  pobierzIBHP: () => {
    return axios.get(
      `${podstawaLinku}/01_06_instrukcje_BHP/00_01_wybor_dokumentacji.php`
    );
  },

  // Instrukcja BHP

  pobieranieIBHP: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_06_instrukcje_BHP/01_01_01_wykaz_dokumentacja.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszIBHP: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/01_06_instrukcje_BHP/01_01_02_dodawanie_dokumentacja.php`,
      elementyFormularza
    );
  },
  usuwanieIBHP: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_06_instrukcje_BHP/01_01_03_delete_dokumentacja.php`,
      obiektId
    );
  },
  pobieranieIBHPEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_06_instrukcje_BHP/01_01_04_dokumentacja_edycja.php?Id=${Id}`
    );
  },
  zapiszIBHPUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_06_instrukcje_BHP/01_01_05_dodawanie_dokumentacja_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // Instrukcje Przeglad BHP

  pobieranieIBHPPrzeglad: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_06_instrukcje_BHP/01_02_01_wykaz_przegladow_dokumentacja.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszIBHPPrzeglad: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/01_06_instrukcje_BHP/01_02_02_dodawanie_przegladu_dokumentacja.php`,
      elementyFormularza
    );
  },
  usuwanieIBHPPrzeglad: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_06_instrukcje_BHP/01_02_03_delete_przeglad_dokumentacja.php`,
      obiektId
    );
  },
  pobieranieIBHPPrzegladEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_06_instrukcje_BHP/01_02_04_dokumentacja_przeglad_edycja.php?Id=${Id}`
    );
  },
  zapiszIBHPPrzegladUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_06_instrukcje_BHP/01_02_05_dodawanie_przegladu_dokumentacja_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // RYZYKA SZANSE_________________________________________________________________________________________________________________________________________________

  pobierzRodzaj: () => {
    return axios.get(
      `${podstawaLinku}/01_07_ryzyka_szanse/00_01_wybor_rodzaju.php`
    );
  },
  pobierzPoziom: () => {
    return axios.get(
      `${podstawaLinku}/01_07_ryzyka_szanse/00_02_wybor_poziomu.php`
    );
  },
  pobierzStrategie: () => {
    return axios.get(
      `${podstawaLinku}/01_07_ryzyka_szanse/00_03_wybor_strategi.php`
    );
  },

  // Ryzyka Szanse

  pobieranieRS: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_07_ryzyka_szanse/01_01_01_wykaz_ryzyk_szans.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszRyzykoSzanse: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/01_07_ryzyka_szanse/01_01_02_dodawanie_ryzyk_szans.php`,
      elementyFormularza
    );
  },
  usuwanieRyzykoSzanse: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_07_ryzyka_szanse/01_01_03_delete_ryzyka_szanse.php`,
      obiektId
    );
  },
  pobieranieRyzykoSzanseEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_07_ryzyka_szanse/01_01_04_ryzyka_szanse_edycja.php?Id=${Id}`
    );
  },
  zapiszRyzykoSzanseUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_07_ryzyka_szanse/01_01_05_dodawanie_ryzyk_szans_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // RYZYKA SZANSE PRZEGLADY

  pobieraniePrzegladuRyzykSzans: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_07_ryzyka_szanse/01_02_01_przeglady_ryzyk_szans.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszPrzegladRyzykSzans: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/01_07_ryzyka_szanse/01_02_03_dodawanie_przegladu_ryzyk_szans.php`,
      elementyFormularza
    );
  },
  usuwaniePrzegladuRyzykSzans: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_07_ryzyka_szanse/01_02_04_delete_przegladu_ryzyk_szans.php`,
      obiektId
    );
  },
  pobieraniePrzegladuRyzykSzansEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_07_ryzyka_szanse/01_02_05_przeglad_ryzyk_szans_edycja.php?Id=${Id}`
    );
  },
  zapiszPrzegladuRyzykSzansUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_07_ryzyka_szanse/01_04_06_dodawanie_przegladu_ryzyk_szans_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // ZAGROZENIA BHP_________________________________________________________________________________________________________________________________________________

  // Ryzyka Szanse

  pobieranieZagrozeniaBHP: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_08_zagrozenia_bhp/01_01_01_wykaz_zagrozen_bhp.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszZagrozeniaBHP: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/01_08_zagrozenia_bhp/01_01_02_dodawanie_zagrozenia_bhp.php`,
      elementyFormularza
    );
  },
  usuwanieZagrozeniaBHP: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_08_zagrozenia_bhp/01_01_03_delete_zagrozenia_bhp.php`,
      obiektId
    );
  },
  pobieranieZagrozeniaBHPEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_08_zagrozenia_bhp/01_01_04_zagrozenia_bhp_edycja.php?Id=${Id}`
    );
  },
  zapiszZagrozeniaBHPUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_08_zagrozenia_bhp/01_01_05_dodawanie_zagrozenia_bhp_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // ZAGROŻENIA BHP PRZEGLADY

  pobieraniePrzegladuZagrozeniaBHP: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_08_zagrozenia_bhp/01_02_01_przeglady_zagrozen_bhp.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszPrzegladZagrozeniaBHP: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/01_08_zagrozenia_bhp/01_02_03_dodawanie_przegladu_zagrozenia_bhp.php`,
      elementyFormularza
    );
  },
  usuwaniePrzegladuZagrozeniaBHP: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_08_zagrozenia_bhp/01_02_04_delete_przegladu_zagrozenia_bhp.php`,
      obiektId
    );
  },
  pobieraniePrzegladuZagrozeniaBHPEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_08_zagrozenia_bhp/01_02_05_przeglad_zagrozenia_bhp_edycja.php?Id=${Id}`
    );
  },
  zapiszPrzegladuZagrozeniaBHPUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_08_zagrozenia_bhp/01_04_06_dodawanie_przegladu_zagrozenia_bhp_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // CELE_________________________________________________________________________________________________________________________________________________

  pobierzStatusCelow: () => {
    return axios.get(`${podstawaLinku}/01_09_cele/00_01_status_celow.php`);
  },
  pobierzWybórRoku: () => {
    return axios.get(`${podstawaLinku}/01_09_cele/00_02_wybor_roku_celow.php`);
  },
  pobierzCeleSzczegolowe: (id) => {
    return axios.get(
      `${podstawaLinku}/01_09_cele/00_03_wybor_celu_szczegolowego.php?Id=${id}`
    );
  },

  // Cele - okres

  pobieranieRokCele: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_09_cele/01_01_01_wykaz_roku_celow.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieranieRokCele1: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_09_cele/01_01_01_wykaz_roku_celow1.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszRokCele: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/01_09_cele/01_01_02_dodawanie_roku_celow.php`,
      elementyFormularza
    );
  },
  usuwanieRokCele: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_09_cele/01_01_03_delete_rok_cele.php`,
      obiektId
    );
  },
  pobieranieRokCeleEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_09_cele/01_01_04_wykaz_rok_celow_edycja.php?Id=${Id}`
    );
  },
  zapiszRokCeleUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_09_cele/01_01_05_dodawanie_rok_celow_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // Cele - szczegolowe

  pobieranieCeleSzczegolowe: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_09_cele/01_02_01_wykaz_celow_szczegolowych.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszCeleSzczegolowe: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/01_09_cele/01_02_02_dodawanie_celu_szczegolowego.php`,
      elementyFormularza
    );
  },
  usuwanieCeleSzczegolowe: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_09_cele/01_02_03_delete_cele_szczegolowe.php`,
      obiektId
    );
  },
  pobieranieCeleSzczegoloweEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_09_cele/01_02_04_wykaz_cele_szczegolowe_edycja.php?Id=${Id}`
    );
  },
  zapiszCeleSzczegoloweUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_09_cele/01_02_05_dodawanie_cele_szczegolowe_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // Cele - zadania

  PobierzLpZadaniaDlaCeluSzczegolowego: (konfiguracja) => {
    return axios.post(
      `${podstawaLinku}/funkcje_wyboru/api_pobierz_lp_cele_zadania.php`,
      konfiguracja
    );
  },

  pobieranieCeleZadania: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_09_cele/01_03_01_wykaz_celow_zadania.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszCeleZadania: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/01_09_cele/01_03_02_dodawanie_celu_zadania.php`,
      elementyFormularza
    );
  },
  usuwanieZadania: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_09_cele/01_03_03_delete_zadania.php`,
      obiektId
    );
  },
  pobieranieZadaniaEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_09_cele/01_03_04_wykaz_zadania_edycja.php?Id=${Id}`
    );
  },
  zapiszZadaniaeUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_09_cele/01_03_05_dodawanie_zadania_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // Cele - przeglady

  pobieranieCelePrzeglady: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_09_cele/01_04_01_cele_przeglady.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszCelePrzeglady: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/01_09_cele/01_04_02_dodawanie_przegladu_cele.php`,
      elementyFormularza
    );
  },
  usuwanieCelePrzeglady: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_09_cele/01_04_03_delete_przeglady_cele.php`,
      obiektId
    );
  },
  pobieranieCelePrzegladyEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_09_cele/01_04_04_wykaz_przeglady_edycja.php?Id=${Id}`
    );
  },
  zapiszCelePrzegladyUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_09_cele/01_04_05_dodawanie_przeglady_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // ASPEKTY_________________________________________________________________________________________________________________________________________________

  pobierzStatusAspekty: () => {
    return axios.get(`${podstawaLinku}/funkcje_wyboru/api_status_aspekty.php`);
  },

  // Aspekty - wykaz

  pobieranieAspekty: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_11_aspekty/01_01_01_wykaz_aspektow.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszAspekty: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/01_11_aspekty/01_01_02_dodawanie_aspektu.php`,
      elementyFormularza
    );
  },
  usuwanieAspekty: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_11_aspekty/01_01_03_delete_aspektu.php`,
      obiektId
    );
  },
  pobieranieAspektyEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_11_aspekty/01_01_04_wykaz_aspektow_edycja.php?Id=${Id}`
    );
  },
  zapiszAspektyUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_11_aspekty/01_01_05_dodawanie_aspektu_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // Aspekty - wykaz wymagań

  pobieranieAspektyWymagania: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_11_aspekty/01_02_01_wykaz_wymagan_aspekt.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieranieAspektyWymagania1: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_11_aspekty/01_02_01_wykaz_wymagan_aspekt1.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszWymaganiaDoAspektu: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/01_11_aspekty/01_02_02_dodawanie_wymagania_aspekt.php`,
      elementyFormularza
    );
  },
  usuwanieWymaganiaAspekty: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_11_aspekty/01_02_03_delete_wymagan_aspekty.php`,
      obiektId
    );
  },
  pobieranieWymaganiaDoAspektuEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_11_aspekty/01_02_04_wymagania_aspekt_edycja.php?Id=${Id}`
    );
  },
  zapiszWymaganiaAspektyUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_11_aspekty/01_02_05_dodawanie_wymagania_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // Aspekty - wykaz monitorowanie

  pobieranieAspektyMonitorowanie: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_11_aspekty/01_03_01_wykaz_monitorowania_aspekt.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieranieAspektyMonitorowanie1: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_11_aspekty/01_03_01_wykaz_monitorowania_aspekt1.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszMonitorowanieDoAspektu: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/01_11_aspekty/01_03_02_dodawanie_monitorowania.php`,
      elementyFormularza
    );
  },
  usuwanieMonitorowaniaAspekty: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_11_aspekty/01_03_03_delete_monitorowania_aspekty.php`,
      obiektId
    );
  },
  pobieranieMonitorowaniaAspektyEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_11_aspekty/01_03_04_monitorowanie_edycja.php?Id=${Id}`
    );
  },
  zapiszMonitorowanieAspektyUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_11_aspekty/01_03_05_dodawanie_monitorowanie_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // Aspekty - wykaz przeglądy

  pobieranieAspektyprzeglady: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_11_aspekty/01_04_01_przeglady_aspekty.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszPrzegladAspektu: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/01_11_aspekty/01_04_02_dodawanie_przegladu_aspekty.php`,
      elementyFormularza
    );
  },
  usuwaniePrzegladAspekty: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_11_aspekty/01_04_03_delete_przegladu_aspektu.php`,
      obiektId
    );
  },
  pobieraniePrzegladAspektyEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_11_aspekty/01_04_04_przeglad_aspekty_edycja.php?Id=${Id}`
    );
  },
  zapiszPrzegladAspektyUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_11_aspekty/01_04_05_dodawanie_przegladu_aspekty_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // AUDITY_________________________________________________________________________________________________________________________________________________

  PobierzNormy: () => {
    return axios.get(`${podstawaLinku}/01_12_audity/00_01_wybor_normy.php`);
  },
  pobierzNrAuditu: () => {
    return axios.get(
      `${podstawaLinku}/01_12_audity/00_01_wybor_numeru_auditu.php`
    );
  },
  pobierzNorme9001: () => {
    return axios.get(`${podstawaLinku}/01_12_audity/00_01_norma_9001.php`);
  },
  pobierzNorme14001: () => {
    return axios.get(`${podstawaLinku}/01_12_audity/00_01_norma_14001.php`);
  },
  pobierzNorme45001: () => {
    return axios.get(`${podstawaLinku}/01_12_audity/00_01_norma_45001.php`);
  },
  pobierzWynikAuditu: () => {
    return axios.get(`${podstawaLinku}/01_12_audity/00_01_wyniku_auditu.php`);
  },
  pobierzPunktyNormy: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_12_audity/00_01_dostarczenie_punktu_normy.php?Id=${Id}`
    );
  },

  // Audity - wykaz
  PobierzLpAuditu: (konfiguracja) => {
    return axios.post(
      `${podstawaLinku}/funkcje_wyboru/api_pobierz_lp_auditu.php`,
      konfiguracja
    );
  },

  pobieranieAudity: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_12_audity/01_01_01_wykaz_auditow.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieranieAudity1: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_12_audity/01_01_01_wykaz_auditow1.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszAudit: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/01_12_audity/01_01_02_dodawanie_auditu.php`,
      elementyFormularza
    );
  },
  usuwanieAudity: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_12_audity/01_01_03_delete_auditu.php`,
      obiektId
    );
  },
  pobieranieAudityEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_12_audity/01_01_04_dodawanie_auditu_edycja.php?Id=${Id}`
    );
  },
  zapiszAudityUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_12_audity/01_01_05_dodawanie_auditu_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  pobieranieAudityEdycjaPDF: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_12_audity/00_01_dostarczenie_punktu_normy_edycja.php?Id=${Id}`
    );
  },

  // Audity - plan

  pobieraniePlanAudity: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_12_audity/01_02_01_plan_auditu.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszPlanAudity: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/01_12_audity/01_02_02_dodawanie_planuauditu.php`,
      elementyFormularza
    );
  },
  usuwaniePlanAudity: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_12_audity/01_02_03_delete_planu_auditu.php`,
      obiektId
    );
  },
  pobieraniePlanAudityEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_12_audity/01_02_04_plan_auditu_edycja.php?Id=${Id}`
    );
  },
  zapiszPlanAudityUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_12_audity/01_02_05_dodawanie_planuauditu_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // Audity raport
  pobieranieRaportyAudity: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_12_audity/01_03_01_wykaz_raportow_auditu.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszRaport: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_12_audity/01_03_02_dodawanie_raport_auditu.php?Id=${Id}`,
      elementyFormularza
    );
  },
  usuwanieRaportuZAudity: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_12_audity/01_03_03_delete_raportu_auditu.php`,
      obiektId
    );
  },

  pobieranieRaportAudityEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_12_audity/00_01_dostarczenie_punktu_normy_edycja.php?Id=${Id}`
    );
  },
  zapiszRaportUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_12_audity/01_03_02_dodawanie_raport_auditu_edycja.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // Audity - wykaz kometencje auditorów

  pobieranieAudityKompetencje: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_12_audity/01_04_01_wykaz_auditorow.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszAuditKompetencje: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/01_12_audity/01_04_02_dodawanie_kompetencji.php`,
      elementyFormularza
    );
  },
  usuwanieAudityKompetencje: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_12_audity/01_04_03_delete_kompetencje.php`,
      obiektId
    );
  },
  pobieranieAudityKompetencjeEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_12_audity/01_04_04_dokumentacja_kompetencje_edycja.php?Id=${Id}`
    );
  },
  zapiszAudityKompetencjeUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_12_audity/01_04_05_dodawanie_komeptencje_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // Działania auditowe_______________________________________________________________________________________________________
  pobierzNumerAuditu: () => {
    return axios.get(
      `${podstawaLinku}/01_13_1_dzialania_auditowe/00_01_numer_auditu.php`
    );
  },

  PobierzRodzajDzialan: () => {
    return axios.get(
      `${podstawaLinku}/01_13_1_dzialania_auditowe/00_01_rodzaj_dzialan.php`
    );
  },

  //Dzialania auditowe

  pobieranieDzialaniaAudit: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_13_1_dzialania_auditowe/01_01_01_wykaz_dzialan_audit.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszDzialaniaAudit: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/01_13_1_dzialania_auditowe/01_01_02_dodawanie_dzialania_audit.php`,
      elementyFormularza
    );
  },
  usuwanieDzialaniaAudit: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_13_1_dzialania_auditowe/01_01_03_delete_dzialania_audit.php`,
      obiektId
    );
  },
  pobieranieDzialaniaAuditEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_13_1_dzialania_auditowe/01_01_04_dzialania_audit_edycja.php?Id=${Id}`
    );
  },
  zapiszDzialaniaAuditUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_13_1_dzialania_auditowe/01_01_05_dodawanie_dzialanie_audit_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //Dzialania auditowe ocena
  PobierzNumerDzialania: (id) => {
    return axios.get(
      `${podstawaLinku}/01_13_1_dzialania_auditowe/00_01_wybor_dzialania.php?Id=${id}`
    );
  },

  pobieranieDzialaniaAuditOcena: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_13_1_dzialania_auditowe/01_02_01_wykaz_dzialan_audit_ocena.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieranieDzialaniaAuditOcena1: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_13_1_dzialania_auditowe/01_02_01_wykaz_dzialan_audit_ocena1.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszDzialaniaAuditOcena: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/01_13_1_dzialania_auditowe/01_02_02_dodawanie_dzialania_audit_ocena.php`,
      elementyFormularza
    );
  },
  usuwanieDzialaniaAuditOcena: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_13_1_dzialania_auditowe/01_02_03_delete_dzialania_audit_ocena.php`,
      obiektId
    );
  },
  pobieranieDzialaniaAuditEdycjaOcena: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_13_1_dzialania_auditowe/01_02_04_dzialania_audit_edycja_ocena.php?Id=${Id}`
    );
  },
  zapiszDzialaniaAuditOcenaUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_13_1_dzialania_auditowe/01_02_05_dodawanie_dzialanie_audit_ocenaupdate.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //Dzialania auditowe ocena skutecznoscu
  pobieranieDzialaniaAuditOcenaSkutecznosci: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_13_1_dzialania_auditowe/01_03_01_wykaz_dzialan_audit_ocena_skutecznosci.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieranieDzialaniaAuditOcenaSkutecznosci1: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_13_1_dzialania_auditowe/01_03_01_wykaz_dzialan_audit_ocena_skutecznosci1.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  usuwanieDzialaniaAuditOcenaSkutecznosci: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_13_1_dzialania_auditowe/01_03_03_delete_dzialania_audit_ocena_skutecznosci.php`,
      obiektId
    );
  },
  pobieranieDzialaniaAuditEdycjaOcenaSkutecznosci: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_13_1_dzialania_auditowe/01_03_04_dzialania_audit_edycja_ocena_skutecznosci.php?Id=${Id}`
    );
  },
  zapiszDzialaniaAuditOcenaUpdateSkutecznosci: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_13_1_dzialania_auditowe/01_03_05_dodawanie_dzialanie_audit_skutecznosci_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //DZIAŁANIA POZA AUDITEM______________________________________________________________________________________________________________
  //Dzialania poza auditowe
  PobierzLpDzialania: (konfiguracja) => {
    return axios.post(
      `${podstawaLinku}/funkcje_wyboru/api_pobierz_lp_dzialania_poza_audit.php`,
      konfiguracja
    );
  },

  PobierzRodzajDzialanPozaAudit: () => {
    return axios.get(
      `${podstawaLinku}/01_13_2_dzialania_poza_auditowe/00_01_wybor_dzialania.php`
    );
  },

  pobieranieDzialaniaPozaAudit: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_13_2_dzialania_poza_auditowe/01_01_01_wykaz_dzialan_poza_audit.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszDzialaniaPozaAudit: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/01_13_2_dzialania_poza_auditowe/01_01_02_dodawanie_dzialania_poza_audit.php`,
      elementyFormularza
    );
  },
  usuwanieDzialaniaPozaAudit: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_13_2_dzialania_poza_auditowe/01_01_03_delete_dzialania_poza_audit.php`,
      obiektId
    );
  },
  pobieranieDzialaniaPozaAuditEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_13_2_dzialania_poza_auditowe/01_01_04_dzialania_poza_audit_edycja.php?Id=${Id}`
    );
  },
  zapiszDzialaniaPozaAuditUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_13_2_dzialania_poza_auditowe/01_01_05_dodawanie_dzialanie_poza_audit_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //Dzialania poza auditowe ocena
  PobierzNumerDzialaniaPozaAudit: () => {
    return axios.get(
      `${podstawaLinku}/01_13_2_dzialania_poza_auditowe/00_01_wybor_dzialania.php`
    );
  },

  PobierzRodzajDzialaniaPozaAudit: () => {
    return axios.get(
      `${podstawaLinku}/01_13_2_dzialania_poza_auditowe/00_01_wybor_rodzaju_dzialania.php`
    );
  },

  pobieranieDzialaniaPozaAuditOcena: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_13_2_dzialania_poza_auditowe/01_02_01_wykaz_dzialan_poza_audit_ocena.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieranieDzialaniaPozaAuditOcena1: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_13_2_dzialania_poza_auditowe/01_02_01_wykaz_dzialan_poza_audit_ocena1.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszDzialaniaPozaAuditOcena: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/01_13_2_dzialania_poza_auditowe/01_02_02_dodawanie_dzialania_poza_audit_ocena.php`,
      elementyFormularza
    );
  },
  usuwanieDzialaniaPozaAuditOcena: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_13_2_dzialania_poza_auditowe/01_02_03_delete_dzialania_poza_audit_ocena.php`,
      obiektId
    );
  },
  pobieranieDzialaniaPozaAuditEdycjaOcena: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_13_2_dzialania_poza_auditowe/01_02_04_dzialania_poza_audit_edycja_ocena.php?Id=${Id}`
    );
  },
  zapiszDzialaniaPozaAuditOcenaUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_13_2_dzialania_poza_auditowe/01_02_05_dodawanie_dzialanie_poza_audit_ocenaupdate.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //Dzialania poza auditowe ocena skutecznoscu
  pobieranieDzialaniaPozaAuditOcenaSkutecznosci: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_13_2_dzialania_poza_auditowe/01_03_01_wykaz_dzialan_poza_audit_ocena_skutecznosci.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieranieDzialaniaPozaAuditOcenaSkutecznosci1: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_13_2_dzialania_poza_auditowe/01_03_01_wykaz_dzialan_poza_audit_ocena_skutecznosci1.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  usuwanieDzialaniaPozaAuditOcenaSkutecznosci: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_13_2_dzialania_poza_auditowe/01_03_03_delete_dzialania_poza_audit_ocena_skutecznosci.php`,
      obiektId
    );
  },
  pobieranieDzialaniaPozaAuditEdycjaOcenaSkutecznosci: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_13_2_dzialania_poza_auditowe/01_03_04_dzialania_poza_audit_edycja_ocena_skutecznosci.php?Id=${Id}`
    );
  },
  zapiszDzialaniaPozaAuditOcenaUpdateSkutecznosci: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_13_2_dzialania_poza_auditowe/01_03_05_dodawanie_dzialanie_poza_audit_skutecznosci_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // Przeglad Zarzadzania_______________________________________________________________________________________________________

  pobierzPrzegladyWybor: () => {
    return axios.get(
      `${podstawaLinku}/01_14_przeglad_zarzadzania/00_01_wybor_przegladu.php`
    );
  },
  pobierzPrzegladyWyborEdycja: () => {
    return axios.get(
      `${podstawaLinku}/01_14_przeglad_zarzadzania/00_01_wybor_przegladu_edycja.php`
    );
  },

  // Wykaz przeglądu zarządzania

  pobieraniePrzegladZarzadzaniaOgolne: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_14_przeglad_zarzadzania/01_01_01_wykaz_przeglad_zarzadzania.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszPrzegladZarzadzania: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/01_14_przeglad_zarzadzania/01_01_02_dodawanie_przeglad.php`,
      elementyFormularza
    );
  },
  usuwaniePrzegladZarzadzania: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_14_przeglad_zarzadzania/01_01_03_delete_przeglad.php`,
      obiektId
    );
  },
  pobieraniePrzegladZarzadzaniaEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_14_przeglad_zarzadzania/01_01_04_przeglad_edycja.php?Id=${Id}`
    );
  },
  zapiszPrzegladZarzadzaniaUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_14_przeglad_zarzadzania/01_01_05_dodawanie_przeglad_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // Protokół z przeglądu zarządzania

  pobieraniePrzegladZarzadzania: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_14_przeglad_zarzadzania/01_02_01_wykaz_danych_przegladu.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszProtokolPrzeglad: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/01_14_przeglad_zarzadzania/01_02_02_dodawanie_protokolu_przeglad.php`,
      elementyFormularza
    );
  },
  usuwanieProtokolPrzeglad: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_14_przeglad_zarzadzania/01_02_03_delete_protokol_przeglad.php`,
      obiektId
    );
  },
  pobieranieProtokolPrzegladEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_14_przeglad_zarzadzania/01_02_04_protokol_przeglad_edycja.php?Id=${Id}`
    );
  },
  zapiszProtokolPrzegladUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_14_przeglad_zarzadzania/01_02_05_dodawanie_protokol_przeglad_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // WYMAGANIA PRAWNE_________________________________________________________________________________________________________________________________________________

  pobierzRodzajWymagania: () => {
    return axios.get(
      `${podstawaLinku}/01_10_wymagania_prawne/00_01_rodzaj_wymagania.php`
    );
  },
  pobierzWymaganieDotyczy: () => {
    return axios.get(
      `${podstawaLinku}/01_10_wymagania_prawne/00_02_wymaganie_dotyczy.php`
    );
  },

  // Wymagania prawne

  pobieranieWymaganiaPrawne: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_10_wymagania_prawne/01_01_01_wykaz_wymagania_prawne.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszWymaganiaPrawne: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/01_10_wymagania_prawne/01_01_02_dodawanie_wymagania_prawne.php`,
      elementyFormularza
    );
  },
  usuwanieWymaganiaPrawne: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_10_wymagania_prawne/01_01_03_delete_wymagania_prawne.php`,
      obiektId
    );
  },
  pobieranieWymaganiaPrawneEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_10_wymagania_prawne/01_01_04_wymagania_prawne_edycja.php?Id=${Id}`
    );
  },
  zapiszWymaganiaPrawneUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_10_wymagania_prawne/01_01_05_dodawanie_wymagania_prawne_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // WYMAGANIA PRAWNE PRZEGLADY

  pobieranieWymaganiaPrawneBHP: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_10_wymagania_prawne/01_02_01_przeglady_wymagania_prawne.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszPrzegladWymaganiaPrawne: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/01_10_wymagania_prawne/01_02_03_dodawanie_przegladu_wymagania_prawne.php`,
      elementyFormularza
    );
  },
  usuwaniePrzegladuWymaganiaPrawne: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_10_wymagania_prawne/01_02_04_delete_przegladu_wymagania_prawne.php`,
      obiektId
    );
  },
  pobieraniePrzegladuWymaganiaPrawneEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_10_wymagania_prawne/01_02_05_przeglad_wymagania_prawne_edycja.php?Id=${Id}`
    );
  },
  zapiszPrzegladuWymaganiaPrawneUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_10_wymagania_prawne/01_04_06_dodawanie_przegladu_wymagania_prawne_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //MASZYNY ______________________________________________________________________________________________________________________________________________________

  //Pobieranie maszyn

  pobierzNazwa_maszyny: () => {
    return axios.get(`${podstawaLinku}/02_01_maszyny/00_01_wybor_maszyny.php`);
  },

  PobierzLpMaszyny: () => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_pobierz_lp_maszyny.php`
    );
  },

  //GENERATOR
  pobieranieMaszynGenerator: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_01_maszyny/06_01_maszyny_generator.php${Id ? "?Id=" + Id : ""}`
    );
  },
  zapiszMaszyneGenerator: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/02_01_maszyny/06_02_dodawanie_generator_maszyny.php`,
      elementyFormularza
    );
  },
  usuwanieMaszynGenerator: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/02_01_maszyny/06_03_delete_protokol.php`,
      obiektId
    );
  },
  pobieranieMaszynEdycjaGenerator: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_01_maszyny/06_04_maszyny_generator_edycja.php?Id=${Id}`
    );
  },
  zapiszMaszyneUpdateGenerator: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/02_01_maszyny/06_05_dodawanie_maszyny_generator_update.php?Id=${Id}`,
      elementyFormularza
    );
  },
  pobieranieGeneratorPrzegladowMaszynProtokol: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_01_maszyny/06_00_maszyny_generator_protokol.php${Id ? "?Id=" + Id : ""}`
    );
  },
  

  //Maszyny wykaz

  pobieranieMaszyn: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_01_maszyny/01_01_maszyny.php${Id ? "?Id=" + Id : ""}`
    );
  },
  zapiszMaszyne: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/02_01_maszyny/01_02_dodawanie_maszyny.php`,
      elementyFormularza
    );
  },
  usuwanieMaszyn: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/02_01_maszyny/01_03_delete_maszyny.php`,
      obiektId
    );
  },
  pobieranieMaszynEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_01_maszyny/01_04_maszyny_edycja.php?Id=${Id}`
    );
  },
  zapiszMaszyneUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/02_01_maszyny/01_05_dodawanie_maszyny_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //Maszyny przeglad

  pobieranieMaszynPrzeglady: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_01_maszyny/02_01_maszyny_przeglady.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszPrzegladMaszyny: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/02_01_maszyny/02_02_dodawanie_przegladu.php`,
      elementyFormularza
    );
  },
  usuwaniePrzegladuMaszyny: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/02_01_maszyny/02_03_delete_przeglad_maszyny.php`,
      obiektId
    );
  },
  pobieranieMaszynPrzegladyEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_01_maszyny/02_04_maszyny_przeglady_edycja.php?Id=${Id}`
    );
  },
  zapiszMaszynePrzegladyUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/02_01_maszyny/02_05_dodawanie_maszyny_przeglad_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //Maszyny ubezpieczenia

  pobieranieMaszynUbezpieczenia: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_01_maszyny/03_01_maszyny_ubezpieczenia.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszUbezpieczenieMaszyny: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/02_01_maszyny/03_02_dodawanie_ubezpieczenia.php`,
      elementyFormularza
    );
  },
  usuwanieUbezpieczeniaMaszyny: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/02_01_maszyny/03_03_delete_ubezpieczenie_maszyny.php`,
      obiektId
    );
  },
  pobieranieMaszynUbezpieczenieEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_01_maszyny/03_04_maszyny_ubezpieczenia_edycja.php?Id=${Id}`
    );
  },
  zapiszUbezpieczenieMaszynyUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/02_01_maszyny/03_05_dodawanie_maszyny_ubezpieczenia_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //Maszyny Paliwo

  pobieranieMaszynPaliwo: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_01_maszyny/04_01_maszyny_paliwo.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszPaliwoMaszyny: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/02_01_maszyny/04_02_dodawanie_paliwa.php`,
      elementyFormularza
    );
  },
  usuwaniePaliwoMaszyny: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/02_01_maszyny/04_03_delete_paliwo_maszyny.php`,
      obiektId
    );
  },
  pobieranieMaszynyPaliwoEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_01_maszyny/04_04_maszyny_paliwo_edycja.php?Id=${Id}`
    );
  },
  zapiszPaliwoMaszynyUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/02_01_maszyny/04_05_dodawanie_maszyny_paliwo_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //Maszyny Koszty

  pobieranieMaszynKoszty: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_01_maszyny/05_01_maszyny_koszty.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszKosztyMaszyny: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/02_01_maszyny/05_02_dodawanie_koszty.php`,
      elementyFormularza
    );
  },
  usuwanieKosztyMaszyny: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/02_01_maszyny/05_03_delete_koszty_maszyny.php`,
      obiektId
    );
  },
  pobieranieMaszynyKosztyEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_01_maszyny/05_04_maszyny_koszty_edycja.php?Id=${Id}`
    );
  },
  zapiszKosztyMaszynyUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/02_01_maszyny/05_05_dodawanie_maszyny_koszty_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // MASZYNY DOZOROWE ________________________________________________________________________________________________________________________________

  //Pobieranie maszyn

  pobierzNazwa_maszyny_dozorowej: () => {
    return axios.get(
      `${podstawaLinku}/02_02_maszyny_dozorowe/00_01_wybor_maszyny_dozorowej.php`
    );
  },

  PobierzLpMaszynyDozorowej: () => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_pobierz_lp_maszyny_dozorowej.php`
    );
  },

  // Maszyny dozorowe

  pobieranieMaszynDozorowych: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_02_maszyny_dozorowe/01_01_maszyny_dozorowe.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszMaszyneDozor: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/02_02_maszyny_dozorowe/01_02_dodawanie_maszyny.php`,
      elementyFormularza
    );
  },
  usuwanieMaszynyDozorowe: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/02_02_maszyny_dozorowe/01_03_delete_maszyny_dozorowe.php`,
      obiektId
    );
  },
  pobieranieMaszynDozorowychEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_02_maszyny_dozorowe/01_04_maszyny_dozorowe_edycja.php?Id=${Id}`
    );
  },
  zapiszMaszyneDozorowaUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/02_02_maszyny_dozorowe/01_05_dodawanie_maszyny_dozorowe_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // Przeglądu maszyny dozorowe

  pobieranieMaszynDozorowychPrzeglady: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_02_maszyny_dozorowe/02_01_maszyny_dozorowe_przeglady.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszMaszyneDozorPrzeglad: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/02_02_maszyny_dozorowe/02_02_dodawanie_maszyny_przeglad.php`,
      elementyFormularza
    );
  },
  usuwaniePrzegladuMaszynyDozorowePrzeglad: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/02_02_maszyny_dozorowe/02_03_delete_maszyny_dozorowe_przeglad.php`,
      obiektId
    );
  },
  pobieranieMaszynDozorowychPrzegladEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_02_maszyny_dozorowe/02_04_maszyny_dozorowe_przeglad_edycja.php?Id=${Id}`
    );
  },
  zapiszMaszyneDozorowaPrzegladUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/02_02_maszyny_dozorowe/02_05_dodawanie_maszyny_dozorowe_przeglad_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // Ubezpieczenia maszyny dozorowe

  pobieranieMaszynDozorowychUbezpieczenia: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_02_maszyny_dozorowe/03_01_maszyny_dozorowe_ubezpieczenia.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszMaszyneDozorUbezpieczenia: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/02_02_maszyny_dozorowe/03_02_dodawanie_maszyny_ubezpieczenia.php`,
      elementyFormularza
    );
  },
  usuwaniePrzegladuMaszynyDozoroweUbezpieczenia: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/02_02_maszyny_dozorowe/03_03_delete_maszyny_dozorowe_ubezpieczenia.php`,
      obiektId
    );
  },
  pobieranieMaszynDozorowychUbezpieczeniaEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_02_maszyny_dozorowe/03_04_maszyny_dozorowe_ubezpieczenia_edycja.php?Id=${Id}`
    );
  },
  zapiszMaszyneDozorowaUbezpieczeniaUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/02_02_maszyny_dozorowe/03_05_dodawanie_maszyny_dozorowe_ubezpieczenia_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // maszyny dozorowe paliwo

  pobieranieMaszynDozorowychPaliwo: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_02_maszyny_dozorowe/04_01_maszyny_dozorowe_paliwo.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszMaszyneDozorPaliwo: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/02_02_maszyny_dozorowe/04_02_dodawanie_maszyny_paliwo.php`,
      elementyFormularza
    );
  },
  usuwaniePrzegladuMaszynyDozorowePaliwo: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/02_02_maszyny_dozorowe/04_03_delete_maszyny_dozorowe_paliwo.php`,
      obiektId
    );
  },
  pobieranieMaszynDozorowychPaliwoEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_02_maszyny_dozorowe/04_04_maszyny_dozorowe_paliwo_edycja.php?Id=${Id}`
    );
  },
  zapiszMaszyneDozorowaPaliwoUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/02_02_maszyny_dozorowe/04_05_dodawanie_maszyny_dozorowe_paliwo_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // maszyny dozorowe koszty

  pobieranieMaszynDozorowychKoszty: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_02_maszyny_dozorowe/05_01_maszyny_dozorowe_koszty.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszMaszyneDozorKoszty: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/02_02_maszyny_dozorowe/05_02_dodawanie_maszyny_koszty.php`,
      elementyFormularza
    );
  },
  usuwaniePrzegladuMaszynyDozoroweKoszty: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/02_02_maszyny_dozorowe/05_03_delete_maszyny_dozorowe_koszty.php`,
      obiektId
    );
  },
  pobieranieMaszynDozorowychKosztyEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_02_maszyny_dozorowe/05_04_maszyny_dozorowe_koszty_edycja.php?Id=${Id}`
    );
  },
  zapiszMaszyneDozorowaKosztyUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/02_02_maszyny_dozorowe/05_05_dodawanie_maszyny_dozorowe_koszty_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // ELEKTRONARZEDZIA ____________________________________________________________________________________________________________________________________________

  //Pobieranie elektronarzedzia

  pobierzNazwa_elektronarzedzia: () => {
    return axios.get(
      `${podstawaLinku}/02_03_elektronarzedzia/00_01_wybor_elektronarzedzia.php`
    );
  },

  PobierzLpElektronarzedzia: () => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_pobierz_lp_elektronarzedzia.php`
    );
  },
  //GENERATOR
  pobieranieElektronarzedziaGenerator: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_03_elektronarzedzia/06_01_elektronarzedzia_generator.php${Id ? "?Id=" + Id : ""}`
    );
  },
  zapiszElektronarzedziaGenerator: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/02_03_elektronarzedzia/06_02_dodawanie_generator_elektronarzedzia.php`,
      elementyFormularza
    );
  },
  usuwanieElektronarzedziaGenerator: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/02_03_elektronarzedzia/06_03_delete_elektronarzedzia_protokol.php`,
      obiektId
    );
  },
  pobieranieElektronarzedziaEdycjaGenerator: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_03_elektronarzedzia/06_04_elektronarzedzia_generator_edycja.php?Id=${Id}`
    );
  },
  zapiszElektronarzedziaUpdateGenerator: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/02_03_elektronarzedzia/06_05_dodawanie_elektronarzedzia_generator_update.php?Id=${Id}`,
      elementyFormularza
    );
  },
  pobieranieGeneratorPrzegladowElektronarzedziaProtokol: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_03_elektronarzedzia/06_00_elektronarzedzia_generator_protokol.php${Id ? "?Id=" + Id : ""}`
    );
  },
  // Elektronarzędzia

  pobieranieElektronarzedzia: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_03_elektronarzedzia/01_01_elektronarzedzia.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszElektronarzedzia: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/02_03_elektronarzedzia/01_02_dodawanie_elektronarzedzia.php`,
      elementyFormularza
    );
  },
  usuwanieElektronarzedzia: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/02_03_elektronarzedzia/01_03_delete_elektronarzedzia.php`,
      obiektId
    );
  },
  pobieranieElektronarzedziaEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_03_elektronarzedzia/01_04_elektronarzedzia_edycja.php?Id=${Id}`
    );
  },
  zapiszElektronarzedziaUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/02_03_elektronarzedzia/01_05_dodawanie_elektronarzedzia_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // Przeglądu elektronarzedzie

  pobieranieElektronarzedziaPrzeglady: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_03_elektronarzedzia/02_01_elektronarzedzia_przeglady.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszElektronarzedziaPrzeglad: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/02_03_elektronarzedzia/02_02_dodawanie_elektronarzedzia_przeglad.php`,
      elementyFormularza
    );
  },
  usuwanieElektronarzedziaPrzeglad: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/02_03_elektronarzedzia/02_03_delete_elektronarzedzia_przeglad.php`,
      obiektId
    );
  },
  pobieranieElektronarzedziaPrzegladEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_03_elektronarzedzia/02_04_elektronarzedzia_przeglad_edycja.php?Id=${Id}`
    );
  },
  zapiszElektronarzedziaPrzegladUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/02_03_elektronarzedzia/02_05_dodawanie_elektronarzedzia_przeglad_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // Ubezpieczenia elektronarzedzie

  pobieranieElektronarzedziaUbezpieczenia: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_03_elektronarzedzia/03_01_elektronarzedzia_ubezpieczenia.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszElektronarzedziaUbezpieczenia: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/02_03_elektronarzedzia/03_02_dodawanie_elektronarzedzia_ubezpieczenia.php`,
      elementyFormularza
    );
  },
  usuwanieElektronarzedziaUbezpieczenia: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/02_03_elektronarzedzia/03_03_delete_elektronarzedzia_ubezpieczenia.php`,
      obiektId
    );
  },
  pobieranieElektronarzedziaUbezpieczeniaEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_03_elektronarzedzia/03_04_elektronarzedzia_ubezpieczenia_edycja.php?Id=${Id}`
    );
  },
  zapiszElektronarzedziaUbezpieczeniaUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/02_03_elektronarzedzia/03_05_dodawanie_elektronarzedzia_ubezpieczenia_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // Koszty elektronarzedzie

  pobieranieElektronarzedziaKoszty: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_03_elektronarzedzia/05_01_elektronarzedzia_koszty.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszElektronarzedziaKoszty: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/02_03_elektronarzedzia/05_02_dodawanie_elektronarzedzia_koszty.php`,
      elementyFormularza
    );
  },
  usuwanieElektronarzedziaKoszty: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/02_03_elektronarzedzia/05_03_delete_elektronarzedzia_koszty.php`,
      obiektId
    );
  },
  pobieranieElektronarzedziaKosztyEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_03_elektronarzedzia/05_04_elektronarzedzia_koszty_edycja.php?Id=${Id}`
    );
  },
  zapiszElektronarzedziaKosztyUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/02_03_elektronarzedzia/05_05_dodawanie_elektronarzedzia_koszty_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // IT _______________________________________________________________________________________________________________________________________________________

  //Pobieranie IT

  pobierzNazwa_IT: () => {
    return axios.get(`${podstawaLinku}/02_04_IT/00_01_wybor_IT.php`);
  },

  PobierzLpIT: () => {
    return axios.get(`${podstawaLinku}/funkcje_wyboru/api_pobierz_lp_IT.php`);
  },
  //GENERATOR
  pobieranieITGenerator: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_04_IT/06_01_IT_generator.php${Id ? "?Id=" + Id : ""}`
    );
  },
  zapiszITGenerator: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/02_04_IT/06_02_dodawanie_generator_IT.php`,
      elementyFormularza
    );
  },
  usuwanieITGenerator: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/02_04_IT/06_03_delete_IT_protokol.php`,
      obiektId
    );
  },
  pobieranieITEdycjaGenerator: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_04_IT/06_04_IT_generator_edycja.php?Id=${Id}`
    );
  },
  zapiszITUpdateGenerator: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/02_04_IT/06_05_dodawanie_IT_generator_update.php?Id=${Id}`,
      elementyFormularza
    );
  },
  pobieranieGeneratorPrzegladowITProtokol: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_04_IT/06_00_IT_generator_protokol.php${Id ? "?Id=" + Id : ""}`
    );
  },
  // IT

  pobieranieIT: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_04_IT/01_01_IT.php${Id ? "?Id=" + Id : ""}`
    );
  },
  zapiszIT: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/02_04_IT/01_02_dodawanie_IT.php`,
      elementyFormularza
    );
  },
  usuwanieIT: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/02_04_IT/01_03_delete_IT.php`,
      obiektId
    );
  },
  pobieranieITEdycja: (Id) => {
    return axios.get(`${podstawaLinku}/02_04_IT/01_04_IT_edycja.php?Id=${Id}`);
  },
  zapiszITUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/02_04_IT/01_05_dodawanie_IT_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // Przeglądu IT

  pobieranieITPrzeglady: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_04_IT/02_01_IT_przeglady.php${Id ? "?Id=" + Id : ""}`
    );
  },
  zapiszITPrzeglad: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/02_04_IT/02_02_dodawanie_IT_przeglad.php`,
      elementyFormularza
    );
  },
  usuwanieITPrzeglad: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/02_04_IT/02_03_delete_IT_przeglad.php`,
      obiektId
    );
  },
  pobieranieITPrzegladEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_04_IT/02_04_IT_przeglad_edycja.php?Id=${Id}`
    );
  },
  zapiszITPrzegladUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/02_04_IT/02_05_dodawanie_IT_przeglad_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // Ubezpieczenia IT

  pobieranieITUbezpieczenia: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_04_IT/03_01_IT_ubezpieczenia.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszITUbezpieczenia: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/02_04_IT/03_02_dodawanie_IT_ubezpieczenia.php`,
      elementyFormularza
    );
  },
  usuwanieITUbezpieczenia: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/02_04_IT/03_03_delete_IT_ubezpieczenia.php`,
      obiektId
    );
  },
  pobieranieITUbezpieczeniaEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_04_IT/03_04_IT_ubezpieczenia_edycja.php?Id=${Id}`
    );
  },
  zapiszITUbezpieczeniaUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/02_04_IT/03_05_dodawanie_IT_ubezpieczenia_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // Koszty IT

  pobieranieITKoszty: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_04_IT/05_01_IT_koszty.php${Id ? "?Id=" + Id : ""}`
    );
  },
  zapiszITKoszty: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/02_04_IT/05_02_dodawanie_IT_koszty.php`,
      elementyFormularza
    );
  },
  usuwanieITKoszty: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/02_04_IT/05_03_delete_IT_koszty.php`,
      obiektId
    );
  },
  pobieranieITKosztyEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_04_IT/05_04_IT_koszty_edycja.php?Id=${Id}`
    );
  },
  zapiszITKosztyUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/02_04_IT/05_05_dodawanie_IT_koszty_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // BUDYNKI _____________________________________________________________________________________________________________________________
  //Pobieranie budynku

  pobierzBudynki: () => {
    return axios.get(`${podstawaLinku}/02_05_budynki/00_01_wybor_budynku.php`);
  },

  PobierzLpBudynku: () => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_pobierz_lp_budynku.php`
    );
  },

  //Wykaz budynkow

  pobieranieBudynkow: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_05_budynki/01_01_budynki.php${Id ? "?Id=" + Id : ""}`
    );
  },
  zapiszBudynek: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/02_05_budynki/01_02_dodawanie_budynku.php`,
      elementyFormularza
    );
  },
  usuwanieBudynek: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/02_05_budynki/01_03_delete_budynku.php`,
      obiektId
    );
  },
  pobieranieBudynekEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_05_budynki/01_04_budynek_edycja.php?Id=${Id}`
    );
  },
  zapiszBudynekUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/02_05_budynki/01_05_dodawanie_budynek_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //Przeglady budynkow

  pobieraniePrzeglady5latBudynkow: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_05_budynki/02_01_budynki_przeglady_5lat.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieraniePrzegladyRoczneBudynkow: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_05_budynki/02_01_budynki_przeglady_roczne.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieraniePrzegladyPolroczneBudynkow: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_05_budynki/02_01_budynki_przeglady_polroczne.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieraniePrzegladyElektrykaBudynkow: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_05_budynki/02_01_budynki_przeglady_elektryczne.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieraniePrzegladyGazBudynkow: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_05_budynki/02_01_budynki_przeglady_gaz.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieraniePrzegladyHydrantBudynkow: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_05_budynki/02_01_budynki_przeglady_hydrant.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieraniePrzegladyIbwBudynkow: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_05_budynki/02_01_budynki_przeglady_instrukcja.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieraniePrzegladyKominyBudynkow: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_05_budynki/02_01_budynki_przeglady_kominowe.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieraniePrzegladyWylacznikBudynkow: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_05_budynki/02_01_budynki_przeglady_wylacznik.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },

  zapiszPrzegladyBudynek: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/02_05_budynki/02_02_dodawanie_budynek_przeglad.php`,
      elementyFormularza
    );
  },
  usuwaniePrzegladyBudynek: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/02_05_budynki/02_03_delete_budynek_przeglad.php`,
      obiektId
    );
  },
  pobieraniePrzegladyBudynekEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_05_budynki/02_04_budynek_przeglad_edycja.php?Id=${Id}`
    );
  },
  zapiszPrzegladyBudynekUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/02_05_budynki/02_05_dodawanie_budynku_przeglad_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //Ubezpieczenia budynkow

  pobieranieUbezpieczenieBudynkow: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_05_budynki/03_01_budynki_ubezpieczenia.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszUbezpieczenieBudynek: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/02_05_budynki/03_02_dodawanie_budynek_ubezpieczenia.php`,
      elementyFormularza
    );
  },
  usuwanieUbezpieczenieBudynek: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/02_05_budynki/03_03_delete_budynek_ubezpieczenia.php`,
      obiektId
    );
  },
  pobieranieUbezpieczenieBudynekEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_05_budynki/03_04_budynek_ubezpieczenia_edycja.php?Id=${Id}`
    );
  },
  zapiszUbezpieczenieBudynekUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/02_05_budynki/03_05_dodawanie_budynek_ubezpieczenia_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //Koszty budynkow

  pobieranieKosztyBudynkow: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_05_budynki/05_01_budynki_koszty.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszKosztyBudynek: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/02_05_budynki/05_02_dodawanie_budynek_koszty.php`,
      elementyFormularza
    );
  },
  usuwanieKosztyBudynek: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/02_05_budynki/05_03_delete_budynki_koszty.php`,
      obiektId
    );
  },
  pobieranieKosztyBudynekEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_05_budynki/05_04_budynki_koszty_edycja.php?Id=${Id}`
    );
  },
  zapiszUKosztyBudynekUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/02_05_budynki/05_05_dodawanie_budynki_koszty_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //Media budynkow

  pobieranieMediaBudynkow: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_05_budynki/04_01_budynki_media.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszMediaBudynek: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/02_05_budynki/04_02_dodawanie_budynek_mediow.php`,
      elementyFormularza
    );
  },
  usuwanieMediaBudynek: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/02_05_budynki/04_03_delete_budynki_media.php`,
      obiektId
    );
  },
  pobieranieMediaBudynekEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_05_budynki/04_04_budynki_media_edycja.php?Id=${Id}`
    );
  },
  zapiszMediaBudynekUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/02_05_budynki/04_05_dodawanie_budynki_media_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // SAMOCHODY OSOBOWE _____________________________________________________________________________________________________________________________
  //Pobieranie maszyn

  pobierzSamochodyOsobowe: () => {
    return axios.get(
      `${podstawaLinku}/02_06_samochody_osobowe/00_01_wybor_samochodu.php`
    );
  },

  //Maszyny samochodów

  pobieranieSamochodowosobowych: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_06_samochody_osobowe/01_01_maszyny.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszSamochodOsobowy: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/02_06_samochody_osobowe/01_02_dodawanie_maszyny.php`,
      elementyFormularza
    );
  },
  usuwanieSamochoduOsobowego: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/02_06_samochody_osobowe/01_03_delete_maszyny.php`,
      obiektId
    );
  },
  pobieranieSamochoduOsobowegoEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_06_samochody_osobowe/01_04_maszyny_edycja.php?Id=${Id}`
    );
  },
  zapiszSamochoduOsobowegoUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/02_06_samochody_osobowe/01_05_dodawanie_maszyny_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //Samochody przeglad

  pobieranieSamochodyOsobowePrzeglady: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_06_samochody_osobowe/02_01_maszyny_przeglady.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszPrzegladSamochodyOsobowe: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/02_06_samochody_osobowe/02_02_dodawanie_przegladu.php`,
      elementyFormularza
    );
  },
  usuwaniePrzegladuSamochodyOsobowe: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/02_06_samochody_osobowe/02_03_delete_przeglad_maszyny.php`,
      obiektId
    );
  },
  pobieranieSamochodyOsobowePrzegladyEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_06_samochody_osobowe/02_04_maszyny_przeglady_edycja.php?Id=${Id}`
    );
  },
  zapiszSamochodyOsobowePrzegladyUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/02_06_samochody_osobowe/02_05_dodawanie_maszyny_przeglad_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //Samochody ubezpieczenia

  pobieranieSamochodyOsoboweUbezpieczenia: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_06_samochody_osobowe/03_01_maszyny_ubezpieczenia.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszUbezpieczenieSamochodyOsobowe: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/02_06_samochody_osobowe/03_02_dodawanie_ubezpieczenia.php`,
      elementyFormularza
    );
  },
  usuwanieUbezpieczeniaSamochodyOsobowe: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/02_06_samochody_osobowe/03_03_delete_ubezpieczenie_maszyny.php`,
      obiektId
    );
  },
  pobieranieSamochodyOsoboweUbezpieczenieEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_06_samochody_osobowe/03_04_maszyny_ubezpieczenia_edycja.php?Id=${Id}`
    );
  },
  zapiszUbezpieczenieSamochodyOsoboweUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/02_06_samochody_osobowe/03_05_dodawanie_maszyny_ubezpieczenia_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //Samochody osobowe Paliwo

  pobieranieSamochodyOsobowePaliwo: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_06_samochody_osobowe/04_01_maszyny_paliwo.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszPaliwoSamochodyOsobowe: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/02_06_samochody_osobowe/04_02_dodawanie_paliwa.php`,
      elementyFormularza
    );
  },
  usuwaniePaliwoSamochodyOsobowe: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/02_06_samochody_osobowe/04_03_delete_paliwo_maszyny.php`,
      obiektId
    );
  },
  pobieranieSamochodyOsobowePaliwoEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_06_samochody_osobowe/04_04_maszyny_paliwo_edycja.php?Id=${Id}`
    );
  },
  zapiszPaliwoSamochodyOsoboweUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/02_06_samochody_osobowe/04_05_dodawanie_maszyny_paliwo_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //Samochody osobowe Koszty

  pobieranieSamochodyOsoboweKoszty: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_06_samochody_osobowe/05_01_maszyny_koszty.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszKosztySamochodyOsobowe: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/02_06_samochody_osobowe/05_02_dodawanie_koszty.php`,
      elementyFormularza
    );
  },
  usuwanieKosztySamochodyOsobowe: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/02_06_samochody_osobowe/05_03_delete_koszty_maszyny.php`,
      obiektId
    );
  },
  pobieranieSamochodyOsoboweKosztyEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_06_samochody_osobowe/05_04_maszyny_koszty_edycja.php?Id=${Id}`
    );
  },
  zapiszKosztySamochodyOsoboweUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/02_06_samochody_osobowe/05_05_dodawanie_maszyny_koszty_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // SAMOCHODY CIĘŻAROWE _____________________________________________________________________________________________________________________________
  //Pobieranie saochodow cizarowych

  pobierzSamochodyCiezarowe: () => {
    return axios.get(
      `${podstawaLinku}/02_07_samochody_ciezarowe/00_01_wybor_samochodu.php`
    );
  },
  pobierzWyborTacho: () => {
    return axios.get(`${podstawaLinku}/funkcje_wyboru/api_tachograf.php`);
  },
  pobierzWyborChlodnia: () => {
    return axios.get(`${podstawaLinku}/funkcje_wyboru/api_chlodnia.php`);
  },

  //Samochody cięzarowe

  pobieranieSamochodowoCiezarowe: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_07_samochody_ciezarowe/01_01_maszyny.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszSamochodCiezarowe: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/02_07_samochody_ciezarowe/01_02_dodawanie_maszyny.php`,
      elementyFormularza
    );
  },
  usuwanieSamochoduCiezarowego: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/02_07_samochody_ciezarowe/01_03_delete_maszyny.php`,
      obiektId
    );
  },
  pobieranieSamochoduCiezarowegoEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_07_samochody_ciezarowe/01_04_maszyny_edycja.php?Id=${Id}`
    );
  },
  zapiszSamochoduCiezarowegoUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/02_07_samochody_ciezarowe/01_05_dodawanie_maszyny_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //Samochody cięzarowe przeglad

  pobieranieSamochodyCiezarowePrzeglady: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_07_samochody_ciezarowe/02_01_maszyny_przeglady.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszPrzegladSamochodyCiezarowe: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/02_07_samochody_ciezarowe/02_02_dodawanie_przegladu.php`,
      elementyFormularza
    );
  },
  usuwaniePrzegladuSamochodyCiezarowe: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/02_07_samochody_ciezarowe/02_03_delete_przeglad_maszyny.php`,
      obiektId
    );
  },
  pobieranieSamochodyCiezarowePrzegladyEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_07_samochody_ciezarowe/02_04_maszyny_przeglady_edycja.php?Id=${Id}`
    );
  },
  zapiszSamochodyCiezarowePrzegladyUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/02_07_samochody_ciezarowe/02_05_dodawanie_maszyny_przeglad_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //Samochody cięzarowe ubezpieczenia

  pobieranieSamochodyCiezaroweUbezpieczenia: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_07_samochody_ciezarowe/03_01_maszyny_ubezpieczenia.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszUbezpieczenieSamochodyCiezarowe: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/02_07_samochody_ciezarowe/03_02_dodawanie_ubezpieczenia.php`,
      elementyFormularza
    );
  },
  usuwanieUbezpieczeniaSamochodyCiezarowe: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/02_07_samochody_ciezarowe/03_03_delete_ubezpieczenie_maszyny.php`,
      obiektId
    );
  },
  pobieranieSamochodyCiezaroweUbezpieczenieEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_07_samochody_ciezarowe/03_04_maszyny_ubezpieczenia_edycja.php?Id=${Id}`
    );
  },
  zapiszUbezpieczenieSamochodyCiezaroweUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/02_07_samochody_ciezarowe/03_05_dodawanie_maszyny_ubezpieczenia_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //Samochody cięzarowe Paliwo

  pobieranieSamochodyCiezarowePaliwo: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_07_samochody_ciezarowe/04_01_maszyny_paliwo.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszPaliwoSamochodyCiezarowe: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/02_07_samochody_ciezarowe/04_02_dodawanie_paliwa.php`,
      elementyFormularza
    );
  },
  usuwaniePaliwoSamochodyCiezarowe: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/02_07_samochody_ciezarowe/04_03_delete_paliwo_maszyny.php`,
      obiektId
    );
  },
  pobieranieSamochodyCiezarowePaliwoEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_07_samochody_ciezarowe/04_04_maszyny_paliwo_edycja.php?Id=${Id}`
    );
  },
  zapiszPaliwoSamochodyCiezaroweUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/02_07_samochody_ciezarowe/04_05_dodawanie_maszyny_paliwo_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //Samochody cięzarowe Koszty

  pobieranieSamochodyCiezaroweKoszty: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_07_samochody_ciezarowe/05_01_maszyny_koszty.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszKosztySamochodyCiezarowe: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/02_07_samochody_ciezarowe/05_02_dodawanie_koszty.php`,
      elementyFormularza
    );
  },
  usuwanieKosztySamochodyCiezarowe: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/02_07_samochody_ciezarowe/05_03_delete_koszty_maszyny.php`,
      obiektId
    );
  },
  pobieranieSamochodyCiezaroweKosztyEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_07_samochody_ciezarowe/05_04_maszyny_koszty_edycja.php?Id=${Id}`
    );
  },
  zapiszKosztySamochodyCiezaroweUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/02_07_samochody_ciezarowe/05_05_dodawanie_maszyny_koszty_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //Samochody cięzarowe tachografy

  pobieranieSamochodyCiezaroweTacho: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_07_samochody_ciezarowe/06_01_maszyny_tachograf.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszTachoSamochodyCiezarowe: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/02_07_samochody_ciezarowe/06_02_dodawanie_tachogaf.php`,
      elementyFormularza
    );
  },
  usuwanieTachoSamochodyCiezarowe: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/02_07_samochody_ciezarowe/06_03_delete_tachograf_maszyny.php`,
      obiektId
    );
  },
  pobieranieSamochodyCiezaroweTachoEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_07_samochody_ciezarowe/06_04_maszyny_tachograf_edycja.php?Id=${Id}`
    );
  },
  zapiszSamochodyCiezaroweTachoUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/02_07_samochody_ciezarowe/06_05_dodawanie_maszyny_tachograf_update.php?Id=${Id}`,
      elementyFormularza
    );
  },
  //Samochody cięzarowe chłodnie 

  pobieranieSamochodyCiezaroweChlodnie: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_07_samochody_ciezarowe/07_01_maszyny_chlodnia.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszChlodnieSamochodyCiezarowe: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/02_07_samochody_ciezarowe/07_02_dodawanie_chlodnia.php`,
      elementyFormularza
    );
  },
  usuwanieChlodnieSamochodyCiezarowe: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/02_07_samochody_ciezarowe/07_03_delete_chlodnia_maszyny.php`,
      obiektId
    );
  },
  pobieranieSamochodyCiezaroweChlodnieEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_07_samochody_ciezarowe/07_04_maszyny_chlodnia_edycja.php?Id=${Id}`
    );
  },
  zapiszSamochodyCiezaroweChlodnieUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/02_07_samochody_ciezarowe/07_05_dodawanie_maszyny_chlodnia_update.php?Id=${Id}`,
      elementyFormularza
    );
  },





  //Samochody cięzarowe termografy 

  pobieranieSamochodyCiezaroweTermograf: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_07_samochody_ciezarowe/08_01_maszyny_termograf.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszTermografSamochodyCiezarowe: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/02_07_samochody_ciezarowe/08_02_dodawanie_termograf.php`,
      elementyFormularza
    );
  },
  usuwanieTermografSamochodyCiezarowe: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/02_07_samochody_ciezarowe/08_03_delete_termograf_maszyny.php`,
      obiektId
    );
  },
  pobieranieSamochodyCiezaroweTermografEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_07_samochody_ciezarowe/08_04_maszyny_termograf_edycja.php?Id=${Id}`
    );
  },
  zapiszSamochodyCiezaroweTermografUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/02_07_samochody_ciezarowe/08_05_dodawanie_maszyny_termograf_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

   
  //POMIARWKA _____________________________________________________________________________________________________________________________
  //Pobieranie pomiarówka

  pobierzPomiarowkaWszystkie: () => {
    return axios.get(
      `${podstawaLinku}/02_08_pomiarowka/00_01_wybor_pomiarowka.php`
    );
  },
  PobierzLpWKP: () => {
    return axios.get(`${podstawaLinku}/funkcje_wyboru/api_pobierz_lp_WKP.php`);
  },
  //Pomiarowka

  pobieraniePomiarowka: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_08_pomiarowka/01_01_wyposazenie_pomiarowe.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszPomiarowka: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/02_08_pomiarowka/01_02_dodawanie_pomiarowka.php`,
      elementyFormularza
    );
  },
  usuwaniePomiarowka: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/02_08_pomiarowka/01_03_delete_pomiarowka.php`,
      obiektId
    );
  },
  pobieraniePomiarowkaEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_08_pomiarowka/01_04_pomiarowka_edycja.php?Id=${Id}`
    );
  },
  zapiszPomiarowkaUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/02_08_pomiarowka/01_05_dodawanie_pomiarowka_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //Pomiarowkaprzeglad

  pobieraniePomiarowkaPrzeglady: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_08_pomiarowka/02_01_pomiarowka_przeglady.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszPrzegladPomiarowka: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/02_08_pomiarowka/02_02_dodawanie_pomiarowka_przeglad.php`,
      elementyFormularza
    );
  },
  usuwaniePrzegladuSPomiarowka: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/02_08_pomiarowka/02_03_delete_pomiarowka_przeglad.php`,
      obiektId
    );
  },
  pobieraniePomiarowkaPrzegladyEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_08_pomiarowka/02_04_pomiarowka_przeglad_edycja.php?Id=${Id}`
    );
  },
  zapiszPomiarowkaPrzegladyUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/02_08_pomiarowka/02_05_dodawanie_pomiarowka_przeglad_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //Pomiarowka ubezpieczenia

  pobieraniePomiarowkaUbezpieczenia: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_08_pomiarowka/03_01_pomiarowka_ubezpieczenia.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszUbezpieczeniaPomiarowka: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/02_08_pomiarowka/03_02_dodawanie_maszyny_ubezpieczenia.php`,
      elementyFormularza
    );
  },
  usuwanieUbezpieczeniaPomiarowka: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/02_08_pomiarowka/03_03_delete_pomiarowka_ubezpieczenia.php`,
      obiektId
    );
  },
  pobieraniePomiarowkUbezpieczeniaEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_08_pomiarowka/03_04_pomiarowka_ubezpieczenia_edycja.php?Id=${Id}`
    );
  },
  zapiszPomiarowkaUbezpieczeniaUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/02_08_pomiarowka/03_05_dodawanie_pomiarowka_ubezpieczenia_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //Pomiarowka koszty

  pobieraniePomiarowkaKoszty: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_08_pomiarowka/05_01_pomiarowka_koszty.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszKosztyPomiarowka: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/02_08_pomiarowka/05_02_dodawanie_pomiarowka_koszty.php`,
      elementyFormularza
    );
  },
  usuwanieKosztyPomiarowka: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/02_08_pomiarowka/05_03_delete_pomiarowka_koszty.php`,
      obiektId
    );
  },
  pobieraniePomiarowkKosztyEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_08_pomiarowka/05_04_pomiarowka_koszty_edycja.php?Id=${Id}`
    );
  },
  zapiszPomiarowkaKosztyUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/02_08_pomiarowka/05_05_dodawanie_pomiarowka_koszty_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //GENERATOR POMIARWKA _____________________________________________________________________________________________________________________________
  //Pobieranie pomiarówka
  pobierzPomiarowkaSprawdzenie: () => {
    return axios.get(
      `${podstawaLinku}/02_11_generaor_pomiarowka/00_01_wybor_pomiarowka_sprawdzenie.php`
    );
  },
  pobierzPomiarowkaWzorcowanie: () => {
    return axios.get(
      `${podstawaLinku}/02_11_generaor_pomiarowka/00_01_wybor_pomiarowka_wzorcowanie.php`
    );
  },
  PobierzLpSwiadectwa: (konfiguracja) => {
    return axios.post(
      `${podstawaLinku}/funkcje_wyboru/api_pobierz_lp_swiadectwa.php`,
      konfiguracja
    );
  },

  //Pomiarowka generator wykaz

  pobieranieGeneratorPomiarowka: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_11_generaor_pomiarowka/01_01_wyposazenie_pomiarowe.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },

  zapiszGeneratorPomiarowka: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/02_11_generaor_pomiarowka/01_02_dodawanie_pomiarowka.php`,
      elementyFormularza
    );
  },
  pobieranieGeneratorPomiarowkaEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_11_generaor_pomiarowka/01_04_pomiarowka_edycja.php?Id=${Id}`
    );
  },
  zapiszGeneratorPomiarowkaUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/02_11_generaor_pomiarowka/01_05_dodawanie_pomiarowka_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //wykaz sprawdzen

  pobieranieWykazSprawdzenPomiarowka: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_11_generaor_pomiarowka/01_01_wyposazenie_wykaz_sprawdzen.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },

  zapiszWykazSprawdzenPomiarowka: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/02_11_generaor_pomiarowka/01_02_dodawanie_pomiarowka.php`,
      elementyFormularza
    );
  },
  usuwanieWykazSprawdzenPomiarowka: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/02_11_generaor_pomiarowka/01_03_delete_pomiar.php`,
      obiektId
    );
  },
  pobieranieWykazSprawdzenPomiarowkaEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_11_generaor_pomiarowka/01_04_pomiarowka_edycja.php?Id=${Id}`
    );
  },
  zapiszWykazSprawdzenPomiarowkaUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/02_11_generaor_pomiarowka/01_05_dodawanie_pomiarowka_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //Pomiarowka generator świadectwa

  pobieranieGeneratorPomiarowkaSwiadectwo: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_11_generaor_pomiarowka/01_01_wyposazenie_generator_sprawdzen.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },

  //WYPOSAŻENIE BHP ____________________________________________________________________________________________________________________________________________

  //Pobieranie wyposazenia

  pobierzNazwa_wyposazeniaBHP: () => {
    return axios.get(
      `${podstawaLinku}/02_09_wyposazenieBHP/00_01_wybor_wyposazenia.php`
    );
  },
  PobierzLpWBHP: () => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_pobierz_lp_wyposazenie_BHP.php`
    );
  },
  pobierzWyposBHP: () => {
    return axios.get(
      `${podstawaLinku}/02_09_wyposazenieBHP/00_01_wybor_wyposazenia.php`
    );
  },

  // Wyposazenie BHP

  pobieranieWyposazeniaBHP: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_09_wyposazenieBHP/01_01_wyposazenieBHP.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszWyposazenieBhp: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/02_09_wyposazenieBHP/01_02_dodawanie_wyposazeniaBHP.php`,
      elementyFormularza
    );
  },
  usuwanieWyposazenieBHP: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/02_09_wyposazenieBHP/01_03_delete_wyposazeniaBHP.php`,
      obiektId
    );
  },
  pobieranieWyposazeniaBhpEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_09_wyposazenieBHP/01_04_wyposazenie_edycja.php?Id=${Id}`
    );
  },
  zapiszWyposazenieBhpUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/02_09_wyposazenieBHP/01_05_dodawanie_wyposazenia_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // Przeglady Wyposazenie BHP

  pobieranieMaszynPrzegladyBHP: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_09_wyposazenieBHP/02_01_wyposazenieBHPprzeglady.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszWyposazeniePrzegladyBhp: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/02_09_wyposazenieBHP/02_02_dodawanie_wyposazeniaPrzegladyBHP.php`,
      elementyFormularza
    );
  },
  usuwanieWyposazeniePrzegladyBHP: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/02_09_wyposazenieBHP/02_03_delete_wyposazeniaPrzegladyBHP.php`,
      obiektId
    );
  },
  pobieranieWyposazeniaPrzegladyBhpEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_09_wyposazenieBHP/02_04_wyposazenie_przeglad_edycja.php?Id=${Id}`
    );
  },
  zapiszWyposazeniePrzegladyBhpUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/02_09_wyposazenieBHP/02_05_dodawanie_wyposazenie_przeglad_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //WYPOSAŻENIE PPOZ ________________________________________________________________________________________________________________________________________________

  //Pobieranie wyposazenia

  pobierzNazwa_wyposazeniaPPOZ: () => {
    return axios.get(
      `${podstawaLinku}/02_10_wyposazeniePPOZ/00_01_wybor_wyposazenia.php`
    );
  },
  PobierzLpWPPOZ: () => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_pobierz_lp_wyposazenie_PPOZ.php`
    );
  },

  // Wyposazenie PPOZ

  pobieranieWyposazeniaPPOZ: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_10_wyposazeniePPOZ/01_01_wyposazeniePPOZ.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszWyposazeniePPOZ: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/02_10_wyposazeniePPOZ/01_02_dodawanie_wyposazeniaPPOZ.php`,
      elementyFormularza
    );
  },
  usuwanieWyposazeniePPOZ: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/02_10_wyposazeniePPOZ/01_03_delete_wyposazeniaPPOZ.php`,
      obiektId
    );
  },
  pobieranieWyposazeniaPPOZEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_10_wyposazeniePPOZ/01_04_wyposazenie_edycja.php?Id=${Id}`
    );
  },
  zapiszWyposazeniePPOZUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/02_10_wyposazeniePPOZ/01_05_dodawanie_wyposazenia_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // Przeglady Wyposazenie PPOZ

  pobieranieMaszynPrzegladyPPOZ: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_10_wyposazeniePPOZ/02_01_wyposazeniePPOZprzeglady.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszWyposazeniePrzegladyPPOZ: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/02_10_wyposazeniePPOZ/02_02_dodawanie_wyposazeniaPrzegladyPPOZ.php`,
      elementyFormularza
    );
  },
  usuwanieWyposazeniePrzegladyPPOZ: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/02_10_wyposazeniePPOZ/02_03_delete_wyposazeniaPrzegladyPPOZ.php`,
      obiektId
    );
  },
  pobieranieWyposazeniaPrzegladyPPOZEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_10_wyposazeniePPOZ/02_04_wyposazenie_przeglad_edycja.php?Id=${Id}`
    );
  },
  zapiszWyposazeniePrzegladyPPOZUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/02_10_wyposazeniePPOZ/02_05_dodawanie_wyposazenie_przeglad_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //EMISJE ____________________________________________________________________________________________________________________________________________

  // Wykaz emisji
  PobierzLataEmisje: () => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_pobierz_dane_emisja.php`
    );
  },

  pobieranieEmisje: (Rok) => {
    return axios.get(
      `${podstawaLinku}/02_12_emisje/01_01_wykaz_emisji.php?Rok=${Rok}`
    );
  },
  zapiszEmisje: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/02_09_wyposazenieBHP/01_02_dodawanie_wyposazeniaBHP.php`,
      elementyFormularza
    );
  },
  usuwanieEmisje: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/02_09_wyposazenieBHP/01_03_delete_wyposazeniaBHP.php`,
      obiektId
    );
  },
  pobieranieEmisjeEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/02_09_wyposazenieBHP/01_04_wyposazenie_edycja.php?Id=${Id}`
    );
  },
  zapiszEmisjeUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/02_09_wyposazenieBHP/01_05_dodawanie_wyposazenia_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // ZADOWOLENIA KLIENTA

  pobieranieZadowolenie: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_15_zadowolenie_klienta/01_01_01_wykaz_zadowolenia_klienta.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszZadowolenie: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/01_15_zadowolenie_klienta/01_01_02_dodawanie_zadowolenia.php`,
      elementyFormularza
    );
  },
  usuwanieZadowolenie: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_15_zadowolenie_klienta/01_01_03_delete_przeglad.php`,
      obiektId
    );
  },
  pobieranieZadowolenieEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_15_zadowolenie_klienta/01_01_04_zadowolenie_edycja.php?Id=${Id}`
    );
  },
  zapiszZadowolenieUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_15_zadowolenie_klienta/01_01_05_dodawanie_zadowolenie_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // SPRAWOZDAWCZOSC SRODWISKOWA

  pobieranieSprawozdawczosc: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_16_sprawozdawczosc_srodowiskowa/01_01_01_wykaz_sprawozdan.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszSprawozdawczosc: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/01_16_sprawozdawczosc_srodowiskowa/01_01_02_dodawanie_sprawozdania.php`,
      elementyFormularza
    );
  },
  usuwanieSprawozdawczosc: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/01_16_sprawozdawczosc_srodowiskowa/01_01_03_delete_sprawozdan.php`,
      obiektId
    );
  },
  pobieranieSprawozdawczoscEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/01_16_sprawozdawczosc_srodowiskowa/01_01_04_sprawozdawczosc_edycja.php?Id=${Id}`
    );
  },
  zapiszSprawozdawczoscUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/01_16_sprawozdawczosc_srodowiskowa/01_01_05_dodawanie_sprawozdawczosc_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // PRACOWNICY ZATRUDNIENI ___________________________________________________________________________________________

  pobierzPracownika: () => {
    return axios.get(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/00_00_01_pobierz_pracownika.php`
    );
  },

  // WYKAZ PRACOWNIKÓW

  pobieranieWykazPracownicyZatrudnieni: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_01_01_wykaz_pracownicy_zatrudnieni.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },

  pobieranieWykazPracownicyZatrudnieniDrukuj: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_01_01_wykaz_pracownicy_zatrudnieni_drukuj.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },

  pobieranieWykazPracownicyZwolnieni: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_01_01_wykaz_pracownicy_zwolnieni.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },

  pobieranieWykazPracownicyZatrudnieniUzupełnienie: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_01_01_wykaz_pracownicy_zatrudnieni_uzupelnienie.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszPracownika: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_01_02_dodawanie_pracownika.php`,
      elementyFormularza
    );
  },
  usuwanieWykazPracownicyZatrudnieni: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_01_03_delete_pracownik_zatrudniony.php`,
      obiektId
    );
  },
  pobieranieDaneZatrudnieniaPracownicyEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_01_04_dodawanie_pracownika_edycja.php?Id=${Id}`
    );
  },
  zapiszDaneZatrudnieniaPracownicyUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_01_05_dodawanie_pracownika_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //PRACOWNICY - ADRES ZAMIESZKANIA

  pobieranieWykazPracownicyZatrudnieniAdresZamieszkania: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_02_01_wykaz_pracownicy_adres_zamieszkania.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieranieDaneAdresZamieszkaniaEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_02_04_dodawanie_adresu_zamieszkania_edycja.php?Id=${Id}`
    );
  },
  zapiszDaneAdresZamieszkaniaUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_02_05_dodawanie_adres_zamieszkania_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //PRACOWNICY - ADRES DO KORESPONDENCJI

  pobieranieWykazPracownicyZatrudnieniAdresKorespondencja: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_03_01_wykaz_pracownicy_adres_korespondencja.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieranieDaneAdresKorespondencjaEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_03_04_dodawanie_adresu_korespondencja_edycja.php?Id=${Id}`
    );
  },
  zapiszDaneAdresKorespondencjaUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_03_05_dodawanie_adres_korespondencja_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //PRACOWNICY DANE ZATRUDNIENIA

  pobieranieDaneZatrudnieniaPracownicy: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_04_01_wykaz_danych_zatrudnienia_pracownicy.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszDaneZatrudnienia: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_04_02_dodawanie_dane_zatrudnienia.php`,
      elementyFormularza
    );
  },
  usuwanieDaneZatrudnienia: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_04_03_delete_dane_zatrudnienia.php`,
      obiektId
    );
  },
  pobieranieDaneZatrudnieniaEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_04_04_wykaz_danych_zatrudnienia_pracownicy_edycja.php?Id=${Id}`
    );
  },
  zapiszDaneZatrudnieniaUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_04_05_wykaz_danych_zatrudnienia_pracownicy_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //PRACOWNICY ZAKRESY

  pobieranieZakresuPracownicy: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_05_01_wykaz_zakresow_pracownika.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszZakresuPracownicy: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_05_02_dodawanie_zakresu_pracownika.php`,
      elementyFormularza
    );
  },
  usuwanieZakresuPracownicy: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_05_03_delete_zakres.php`,
      obiektId
    );
  },
  pobieranieZakresuPracownicyEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_05_04_wykaz_zakresow_pracownicy_edycja.php?Id=${Id}`
    );
  },
  zapiszZakresuPracownicyUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_05_05_wykaz_zakresow_pracownicy_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //PRACOWNICY BADANIA

  pobieranieBadaniaPracownicy: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_06_01_wykaz_badan_pracownika.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszBadaniaPracownicy: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_06_02_dodawanie_badan_pracownika.php`,
      elementyFormularza
    );
  },
  usuwanieBadaniaPracownicy: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_06_03_delete_badania.php`,
      obiektId
    );
  },
  pobieranieBadaniaPracownicyEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_06_04_wykaz_badan_pracownicy_edycja.php?Id=${Id}`
    );
  },
  zapiszBadaniaPracownicyUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_06_05_wykaz_badan_pracownicy_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //PRACOWNICY SZKOLENIA WSTĘPNE I STANOWISKOWE

  pobieranieSzkoleniaWSPracownicy: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_07_01_wykaz_szkolenia_WS_pracownika.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszSzkoleniaWSPPracownicy: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_07_02_01_dodawanie_szkolenieWSTSTAN_pracownika.php`,
      elementyFormularza
    );
  },
  pobieranieSzkoleniaWSPPracownicyEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_07_04_01_wykaz_szkolenieWSTSTAN_pracownicy_edycja.php?Id=${Id}`
    );
  },
  usuwanieBSzkoleniaWSPPracownicy: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_07_03_delete_szkolenia_WS.php`,
      obiektId
    );
  },
  zapiszSzkoleniaWSPPracownicyUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_07_05_01_wykaz_szkoleniaWSTSTAN_pracownicy_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  zapiszSzkoleniaSTANPracownicy: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_07_02_02_dodawanie_szkolenieSTAN_pracownika.php`,
      elementyFormularza
    );
  },
  pobieranieSzkoleniaSTANPracownicyEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_07_04_02_wykaz_szkolenieSTAN_pracownicy_edycja.php?Id=${Id}`
    );
  },
  zapiszSzkoleniaSTANPracownicyUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_07_05_02_wykaz_szkoleniaSTAN_pracownicy_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //PRACOWNICY SZKOLENIA BHP

  pobieranieSzkoleniaBHPPracownicy: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_08_01_wykaz_szkolen_BHP_pracownika.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszSzkoleniaBHPPracownicy: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_08_02_dodawanie_szkolenia_BHP_pracownika.php`,
      elementyFormularza
    );
  },
  usuwanieSzkoleniaBHPPracownicy: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_08_03_delete_szkolenia_BHP.php`,
      obiektId
    );
  },
  pobieranieSzkoleniaBHPPracownicyEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_08_04_wykaz_szkolen_BHP_pracownicy_edycja.php?Id=${Id}`
    );
  },
  zapiszSzkoleniaBHPPracownicyUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_08_05_wykaz_szkolen_BHP_pracownicy_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //PRACOWNICY ZAPOZNANIE ORZ

  pobieranieZapoznanieORZPracownicy: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_09_01_wykaz_zapoznania_z_ORZ.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszZapoznanieORZPracownicy: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_09_02_dodawanie_zapoznania_ORZ.php`,
      elementyFormularza
    );
  },
  usuwanieapoznanieORZPracownicy: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_09_03_delete_zapoznanie_ORZ.php`,
      obiektId
    );
  },
  pobieranieZapoznanieORZPracownicyEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_09_04_wykaz_zapoznanie_ORZ_edycja.php?Id=${Id}`
    );
  },
  zapiszZapoznanieORZPracownicyUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_09_05_wykaz_zapoznan_ORZ_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //PRACOWNICY ZAPOZNANIE IO

  pobieranieZapoznanieIOPracownicy: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_10_01_wykaz_zapoznania_z_IO.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszZapoznanieIOPracownicy: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_10_02_dodawanie_zapoznania_IO.php`,
      elementyFormularza
    );
  },
  usuwanieapoznanieIOPracownicy: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_10_03_delete_zapoznanie_IO.php`,
      obiektId
    );
  },
  pobieranieZapoznanieIOPracownicyEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_10_04_wykaz_zapoznanie_IO_edycja.php?Id=${Id}`
    );
  },
  zapiszZapoznanieIOPracownicyUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_10_05_wykaz_zapoznan_IO_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //PRACOWNICY ZAPOZNANIE IS

  pobieranieZapoznanieISPracownicy: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_11_01_wykaz_zapoznania_z_IS.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszZapoznanieISPracownicy: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_11_02_dodawanie_zapoznania_IS.php`,
      elementyFormularza
    );
  },
  usuwanieapoznanieISPracownicy: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_11_03_delete_zapoznanie_IS.php`,
      obiektId
    );
  },
  pobieranieZapoznanieISPracownicyEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_11_04_wykaz_zapoznanie_IS_edycja.php?Id=${Id}`
    );
  },
  zapiszZapoznanieISPracownicyUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_11_05_wykaz_zapoznan_IS_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //PRACOWNICY ZAPOZNANIE IBHP

  pobieranieZapoznanieIBHPPracownicy: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_12_01_wykaz_zapoznania_z_IBHP.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszZapoznanieIBHPPracownicy: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_12_02_dodawanie_zapoznania_IBHP.php`,
      elementyFormularza
    );
  },
  usuwanieapoznanieIBHPPracownicy: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_12_03_delete_zapoznanie_IBHP.php`,
      obiektId
    );
  },
  pobieranieZapoznanieIBHPPracownicyEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_12_04_wykaz_zapoznanie_IBHP_edycja.php?Id=${Id}`
    );
  },
  zapiszZapoznanieIBHPPracownicyUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_12_05_wykaz_zapoznan_IBHP_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //PRACOWNICY KOMPETENCJE

  pobieranieKompetencjePracownicy: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_13_01_wykaz_kompetencji.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszKompetencjePracownicy: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_13_02_dodawanie_kompetencji.php`,
      elementyFormularza
    );
  },
  usuwanieKompetencjePracownicy: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_13_03_delete_kompetencje.php`,
      obiektId
    );
  },
  pobieranieKompetencjePracownicyEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_13_04_wykaz_kompetencje_edycja.php?Id=${Id}`
    );
  },
  zapiszKompetencjePracownicyUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_13_05_wykaz_kompetencje_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //PRACOWNICY HISTORIA ZATRUDNIENIA

  pobieranieHistoriaPracownicy: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_14_01_wykaz_historia_zatrudnienia.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszHistoriaPracownicy: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_14_02_dodawanie_historii_zatrudnienia.php`,
      elementyFormularza
    );
  },
  usuwanieHistoriaPracownicy: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_14_03_delete_historia_zatrudnienia.php`,
      obiektId
    );
  },
  pobieranieHistoriaPracownicyEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_14_04_wykaz_historia_edycja.php?Id=${Id}`
    );
  },
  zapiszHistoriaPracownicyUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/03_01_pracownicy_zatrudnieni/01_14_05_wykaz_historia_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //PRACOWNICY ABSENCJA

  PobierzRodzajAbsencji: () => {
    return axios.get(`${podstawaLinku}/funkcje_wyboru/api_rodzaj_absencji.php`);
  },

  pobieranieAbsencjaPracownicy: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_02_HR_absercja/01_01_01_wykaz_absencji_pracownicy.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszAbsencjaPracownicy: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/03_02_HR_absercja/01_01_02_dodawanie_absencji.php`,
      elementyFormularza
    );
  },
  pobieranieAbsencjaPracownicyEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_02_HR_absercja/01_01_04_wykaz_absencji_edycja.php?Id=${Id}`
    );
  },
  zapiszAbsencjaPracownicyUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/03_02_HR_absercja/01_01_05_wykaz_absencji_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //STANOWISKA_______________________________________________________________________________________________________________________

  pobierzStanowiskoPracy: () => {
    return axios.get(
      `${podstawaLinku}/03_03_HR_stanowiska/00_00_01_pobierz_stanowisko.php`
    );
  },

  pobieranieStanowiska: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_03_HR_stanowiska/01_01_01_wykaz_stanowisk.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszStanowiska: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/03_03_HR_stanowiska/01_01_02_dodawanie_stanowiska.php`,
      elementyFormularza
    );
  },
  usuwanieStanowiska: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/03_03_HR_stanowiska/01_01_03_delete_stanowiska.php`,
      obiektId
    );
  },
  pobieranieStanowiskaEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_03_HR_stanowiska/01_01_04_stanowiska_edycja.php?Id=${Id}`
    );
  },
  zapiszStanowiskaUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/03_03_HR_stanowiska/01_01_05_dodawanie_stanowiska_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // STANOWISKA PRZEGLADY

  pobieranieStanowiskaPrzeglad: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_03_HR_stanowiska/01_02_01_przeglady_stanowisk.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszStanowiskaPrzeglad: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/03_03_HR_stanowiska/01_02_03_dodawanie_przegladu_stanowisk.php`,
      elementyFormularza
    );
  },
  usuwanieStanowiskaPrzeglad: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/03_03_HR_stanowiska/01_02_04_delete_przegladu_stanowisk.php`,
      obiektId
    );
  },
  pobieranieStanowiskaPrzegladEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_03_HR_stanowiska/01_02_05_przeglad_stanowiska_edycja.php?Id=${Id}`
    );
  },
  zapiszStanowiskaPrzegladUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/03_03_HR_stanowiska/01_02_06_dodawanie_przegladu_stanowiska_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //SZKOLENIA_______________________________________________________________________________________________________________________
  pobierzStatusuPlanuSzkolen: () => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_status_planu_szkolen.php`
    );
  },
  pobierzMiesiac: () => {
    return axios.get(`${podstawaLinku}/funkcje_wyboru/api_miesiac.php`);
  },
  pobierzRok: () => {
    return axios.get(`${podstawaLinku}/funkcje_wyboru/api_rok_szkolen.php`);
  },
  pobierzSzkolenia: (id) => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_wybor_szkolenia.php?Id=${id}`
    );
  },

  //PLANY SZKOLEN

  pobieraniePlanuSzkolen: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_04_HR_szkolenia/01_01_01_wykaz_planow.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieraniePlanuSzkolen1: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_04_HR_szkolenia/01_01_01_wykaz_planow1.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },

  zapiszPlanuSzkolen: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/03_04_HR_szkolenia/01_01_02_dodawanie_planu.php`,
      elementyFormularza
    );
  },
  usuwaniePlanuSzkolen: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/03_04_HR_szkolenia/01_01_03_delete_planow.php`,
      obiektId
    );
  },
  pobieraniePlanuSzkolenEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_04_HR_szkolenia/01_01_04_planow_edycja.php?Id=${Id}`
    );
  },
  zapiszPlanuSzkolenUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/03_04_HR_szkolenia/01_01_05_dodawanie_planow_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //SZKOLENIA
  pobieranieWykazSzkolen: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_04_HR_szkolenia/01_02_01_wykaz_szkolen.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszWykazSzkolen: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/03_04_HR_szkolenia/01_02_02_dodawanie_szkolenia.php`,
      elementyFormularza
    );
  },
  usuwanieWykazSzkolen: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/03_04_HR_szkolenia/01_02_03_delete_szkolenia.php`,
      obiektId
    );
  },
  pobieranieWykazSzkolenEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_04_HR_szkolenia/01_02_04_szkolenia_edycja.php?Id=${Id}`
    );
  },
  zapiszWykazSzkolenUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/03_04_HR_szkolenia/01_02_05_dodawanie_szkolenia_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //SZKOLENIA REALIZACJA
  pobieranieRealizacjaSzkolen: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_04_HR_szkolenia/01_03_01_wykaz_realizacji_szkolenia.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieranieRealizacjaSzkolen1: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_04_HR_szkolenia/01_03_01_wykaz_realizacji_szkolenia1.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },


  
  zapiszRealizacjaSzkolen: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/03_04_HR_szkolenia/01_03_02_dodawanie_realizacji_szkolenia.php`,
      elementyFormularza
    );
  },
  usuwanieRealizacjaSzkolen: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/03_04_HR_szkolenia/01_03_03_delete_realizacji_szkolenia.php`,
      obiektId
    );
  },
  pobieranieRealizacjaSzkolenEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_04_HR_szkolenia/01_03_04_realizacji_szkolenia_edycja.php?Id=${Id}`
    );
  },
  zapiszRealizacjaSzkolenUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/03_04_HR_szkolenia/01_03_05_dodawanie_realizacji_szkolenia_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //SZKOLENIA UCZSTNICY

  pobieranieUczestnikaSzkolen: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_04_HR_szkolenia/01_04_01_wykaz_uczestnikow_szkolenia.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieranieUczestnikaSzkolen1: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_04_HR_szkolenia/01_04_01_wykaz_uczestnikow_szkolenia1.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszUczestnikaSzkolen: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/03_04_HR_szkolenia/01_04_02_dodawanie_uczestnika_szkolenia.php`,
      elementyFormularza
    );
  },
  usuwanieUczestnikazSzkolen: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/03_04_HR_szkolenia/01_04_03_delete_uczestnik_szkolenia.php`,
      obiektId
    );
  },
  pobieranieUczestnikaSzkolenEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_04_HR_szkolenia/01_04_04_uczestnik_szkolenia_edycja.php?Id=${Id}`
    );
  },
  zapiszUczestnikaSzkolenUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/03_04_HR_szkolenia/01_04_05_dodawanie_uczestnik_szkolenia_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // SKIEROWANIA NA BADANIA
  pobierzRodzajBadan: (Id) => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_rodzaj_badan.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobierzZatrudnieniePracownika: (Id) => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_okreslenie_badan.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },

  pobieranieSkierowania: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_05_HR_skierowania/01_01_01_wykaz_skierowan.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszSkierowania: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/03_05_HR_skierowania/01_01_02_dodawanie_skierowania.php`,
      elementyFormularza
    );
  },
  usuwanieSkierowania: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/03_05_HR_skierowania/01_01_03_delete_skierowanie.php`,
      obiektId
    );
  },
  pobieranieSkierowaniaEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_05_HR_skierowania/01_01_04_skierowania_edycja.php?Id=${Id}`
    );
  },
  zapiszSkierowaniaUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/03_05_HR_skierowania/01_01_05_dodawanie_skierowania_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // MEDYCYNA PRACY _________________________________________________________________________________________________________________________________________________

  // Medycyna pracy

  pobieranieMP: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_05_medycyna_pracy/01_01_01_wykaz_dokumentacja.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszMP: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/03_05_medycyna_pracy/01_01_02_dodawanie_dokumentacja.php`,
      elementyFormularza
    );
  },
  usuwanieMP: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/03_05_medycyna_pracy/01_01_03_delete_dokumentacja.php`,
      obiektId
    );
  },
  pobieranieMPEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_05_medycyna_pracy/01_01_04_dokumentacja_edycja.php?Id=${Id}`
    );
  },
  zapiszMPUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/03_05_medycyna_pracy/01_01_05_dodawanie_dokumentacja_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // ZAKRESY OBOWIAZKOW _________________________________________________________________________________________________________________________________________________

  pobierzRodzajZakresu: (id) => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_rodzaj_zakresu.php?Id=${id}`
    );
  },

  pobierzCzescZakresu: (Id) => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_czesci_zakresu.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobierzWyborZakresu: (Id) => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_wybor_zakres.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },

  // ZAKRESY

  pobieranieZOOIU: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_06_zakresy/01_01_01_wykaz_dokumentacja.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },

  zapiszZOOIU: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/03_06_zakresy/01_01_02_dodawanie_dokumentacja.php`,
      elementyFormularza
    );
  },
  usuwanieZOOIU: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/03_06_zakresy/01_01_03_delete_dokumentacja.php`,
      obiektId
    );
  },
  pobieranieZOOIUEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_06_zakresy/01_01_04_dokumentacja_edycja.php?Id=${Id}`
    );
  },
  zapiszZOOIUUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/03_06_zakresy/01_01_05_dodawanie_dokumentacja_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // ZAKRES KATALOG

  pobierzKatalogZakres: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_06_zakresy/01_02_01_wykaz_katalog.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszKatalogZOOIU: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/03_06_zakresy/01_02_02_dodawanie_zakres_katalog.php`,
      elementyFormularza
    );
  },
  usuwanieKatalogZOOIU: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/03_06_zakresy/01_02_03_delete_katalog.php`,
      obiektId
    );
  },
  pobieranieZOOIUKatalogEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/03_06_zakresy/01_02_04_katalog_edycja.php?Id=${Id}`
    );
  },
  zapiszZOOIUKatalogUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/03_06_zakresy/01_02_05_katalog_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // FAKTURY ___________________________________________________________________________________________

  //Pobieranie faktury

  // Faktury

  pobieranieFaktury: (Id) => {
    return axios.get(
      `${podstawaLinku}/07_01_faktury/01_01_Faktury.php${Id ? "?Id=" + Id : ""}`
    );
  },
  zapiszFakture: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/07_01_faktury/01_02_dodawanie_Faktury.php`,
      elementyFormularza
    );
  },
  usuwanieFaktury: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/02_04_IT/01_03_delete_IT.php`,
      obiektId
    );
  },
  pobieranieFakturyEdycja: (Id) => {
    return axios.get(`${podstawaLinku}/02_04_IT/01_04_IT_edycja.php?Id=${Id}`);
  },
  zapiszFaktureUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/02_04_IT/01_05_dodawanie_IT_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // Kontrahenci

  pobierzKontrahentaZBazy: () => {
    return axios.get(
      `${podstawaLinku}/07_02_kontrahenci/00_01_wybor_kontrahenta.php`
    );
  },

  pobieranieKontrahenta: (Id) => {
    return axios.get(
      `${podstawaLinku}/07_02_kontrahenci/01_01_Kontrahenci.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszKontrahenta: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/07_02_kontrahenci/01_02_dodawanie_Kontrahenta.php`,
      elementyFormularza
    );
  },
  usuwanieKontrahenta: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/07_02_kontrahenci/01_03_delete_Kontrahenta.php`,
      obiektId
    );
  },
  pobieranieKontrahentaEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/07_02_kontrahenci/01_04_Kontrahenci_edycja.php?Id=${Id}`
    );
  },
  zapiszKontrahentaUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/07_02_kontrahenci/01_05_dodawanie_Kontrahenta_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // Kontrahenci kontakty

  pobieranieKontrahentaKontakty: (Id) => {
    return axios.get(
      `${podstawaLinku}/07_02_kontrahenci/02_01_Kontrahenci_kontakt.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszKontrahentaKontakty: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/07_02_kontrahenci/02_02_dodawanie_Kontrahenci_kontakt.php`,
      elementyFormularza
    );
  },
  usuwanieKontrahentaKontakty: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/07_02_kontrahenci/02_03_delete_Kontrahenta_Kontakt.php`,
      obiektId
    );
  },
  pobieranieKontrahentaKontaktyEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/07_02_kontrahenci/02_04_Kontrahenci_kontakt_edycja.php?Id=${Id}`
    );
  },
  zapiszKontrahentaKontaktyUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/07_02_kontrahenci/02_05_dodawanie_Kontrahenci_kontakt_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //BHP ORZ _________________________________________________________________________________________________________________________________________________

  //Pobieranie dokumentacji ORZ

  pobierzStatusORZ: () => {
    return axios.get(`${podstawaLinku}/funkcje_wyboru/api_status_ORZ.php`);
  },
  pobierzORZ: () => {
    return axios.get(
      `${podstawaLinku}/04_01_BHP_orz/00_01_wybor_dokumentacji_ORZ.php`
    );
  },

  // Dokumentacja ORZ

  pobieranieDokumentacjaORZ: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_01_BHP_orz/01_01_01_wykaz_dokumentacja_ORZ.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszDokumentacjaORZ: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/04_01_BHP_orz/01_01_02_dodawanie_dokumentacja_ORZ.php`,
      elementyFormularza
    );
  },
  usuwanieDokumentacjaORZ: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/04_01_BHP_orz/01_01_03_delete_dokumentacja_ORZ.php`,
      obiektId
    );
  },
  pobieranieDokumentacjaORZEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_01_BHP_orz/01_01_04_dokumentacja_ORZ_edycja.php?Id=${Id}`
    );
  },
  zapiszDokumentacjaORZUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/04_01_BHP_orz/01_01_05_dodawanie_dokumentacja_ORZ_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // Dokumentacja ORZ Przeglad

  pobieranieDokumentacjaORZPrzeglad: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_01_BHP_orz/01_02_01_wykaz_przegladow_dokumentacja_ORZ.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszDokumentacjaORZPrzeglad: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/04_01_BHP_orz/01_02_02_dodawanie_przegladu_dokumentacja_ORZ.php`,
      elementyFormularza
    );
  },
  usuwanieDokumentacjaORZPrzeglad: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/04_01_BHP_orz/01_02_03_delete_przeglad_dokumentacja_ORZ.php`,
      obiektId
    );
  },
  pobieranieDokumentacjaORZPrzegladEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_01_BHP_orz/01_02_04_dokumentacja_ORZ_przeglad_edycja.php?Id=${Id}`
    );
  },
  zapiszDokumentacjaORZPrzegladUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/04_01_BHP_orz/01_02_05_dodawanie_przegladu_dokumentacja_ORZ_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  //BHP BSP _________________________________________________________________________________________________________________________________________________

  //Pobieranie dokumentacji ORZ

  pobierzBSP: () => {
    return axios.get(
      `${podstawaLinku}/04_02_BHP_bsp/00_01_wybor_dokumentacji_ORZ.php`
    );
  },

  // Dokumentacja BSP

  pobieranieDokumentacjaBSP: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_02_BHP_bsp/01_01_01_wykaz_dokumentacja_BSP.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszDokumentacjaBSP: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/04_02_BHP_bsp/01_01_02_dodawanie_dokumentacja_BSP.php`,
      elementyFormularza
    );
  },
  usuwanieDokumentacjaBSP: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/04_02_BHP_bsp/01_01_03_delete_dokumentacja_BSP.php`,
      obiektId
    );
  },
  pobieranieDokumentacjaBSPEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_02_BHP_bsp/01_01_04_dokumentacja_BSP_edycja.php?Id=${Id}`
    );
  },
  zapiszDokumentacjaBSPUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/04_02_BHP_bsp/01_01_05_dodawanie_dokumentacja_BSP_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // Dokumentacja BSP Szczegoly

  pobieranieDokumentacjaBSPPrzeglad: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_02_BHP_bsp/01_02_01_wykaz_szczegolow.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },

  // SUBSTANCJE NIEBEZPIECZNYCH

  pobieranieDokumentacjaSN: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_03_substancje_niebezpieczne/01_01_01_wykaz_substancji_niebezpiecznych.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszDokumentacjaSN: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/04_03_substancje_niebezpieczne/01_01_02_dodawanie_substancji.php`,
      elementyFormularza
    );
  },
  usuwanieDokumentacjaSN: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/04_03_substancje_niebezpieczne/01_01_03_delete_substancje_niebezpieczne.php`,
      obiektId
    );
  },
  pobieranieDokumentacjaSNEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_03_substancje_niebezpieczne/01_01_04_substancje_niebezpieczne_edycja.php?Id=${Id}`
    );
  },
  zapiszDokumentacjaSNUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/04_03_substancje_niebezpieczne/01_01_05_dodawanie_substancji_niebezpiecznej_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // Substancje niebezpieczne Przeglad

  pobieranieDokumentacjaSNPrzeglad: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_03_substancje_niebezpieczne/01_02_01_przeglady_substancji_niebezpiecznych.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszDokumentacjaSNPrzeglad: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/04_03_substancje_niebezpieczne/01_02_03_dodawanie_przegladu_substancji_niebezpiecznych.php`,
      elementyFormularza
    );
  },
  usuwanieDokumentacjaSNPrzeglad: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/04_03_substancje_niebezpieczne/01_02_04_delete_przegladu_substancji_niebezpiecznych.php`,
      obiektId
    );
  },
  pobieranieDokumentacjaSNPrzegladEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_03_substancje_niebezpieczne/01_02_05_przeglad_substancji_niebezpiecznych_edycja.php?Id=${Id}`
    );
  },
  zapiszDokumentacjaSNPrzegladUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/04_03_substancje_niebezpieczne/01_04_06_dodawanie_przegladu_substancji_niebezpiecznych_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // OCENA STANU BHP

  pobieranieDokumentacjaOSBHP: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_04_ocena_stanu_BHP/01_01_01_wykaz_dokumentacja.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszDokumentacjaOSBHP: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/04_04_ocena_stanu_BHP/01_01_02_dodawanie_dokumentacja.php`,
      elementyFormularza
    );
  },
  usuwanieDokumentacjaOSBHP: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/04_04_ocena_stanu_BHP/01_01_03_delete_dokumentacja.php`,
      obiektId
    );
  },

  pobieranieDokumentacjaOSBHPEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_04_ocena_stanu_BHP/01_01_04_dokumentacja_edycja.php?Id=${Id}`
    );
  },
  zapiszDokumentacjaOSBHPpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/04_04_ocena_stanu_BHP/01_01_05_dodawanie_dokumentacja_update.php?Id=${Id}`,
      elementyFormularza
    );
  },


  // ZESPOŁ BHP
  pobieranieDokumentacjaPosiedzenie: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_04_ocena_stanu_BHP/02_01_01_wykaz_dokumentacja_posiedzenie.php?Id=${Id}`
    );
  },
  zapiszDokumentacjaPosiedzenie: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/04_04_ocena_stanu_BHP/02_01_02_dodawanie_posiedzenia.php`,
      elementyFormularza
    );
  },
  usuwanieDokumentacjaPosiedzenie: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/04_04_ocena_stanu_BHP/02_01_03_delete_posiedzenie.php`,
      obiektId
    );
  },

  pobieranieDokumentacjaPosiedzenieEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_04_ocena_stanu_BHP/02_01_04_posiedzenie_edycja.php?Id=${Id}`
    );
  },
  zapiszDokumentacjaPosiedzenieUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/04_04_ocena_stanu_BHP/02_01_05_dodawanie_posiedzenia_update.php?Id=${Id}`,
      elementyFormularza
    );
  },
  PobierzLpPosiedzenia: (konfiguracja) => {
    return axios.post(
      `${podstawaLinku}/funkcje_wyboru/api_pobierz_lp_posiedzenia.php`,
      konfiguracja
    );
  },


  pobieranieDokumentacjaPosiedzenie1: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_04_ocena_stanu_BHP/02_01_01_wykaz_dokumentacja_posiedzenie1.php?Id=${Id}`
    );
  },
  pobieranieDokumentacjaPosiedzenieSzczegoly1: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_04_ocena_stanu_BHP/02_01_01_wykaz_dokumentacja_posiedzenie2.php?Id=${Id}`
    );
  },








  // WYPADKI W PRACY

  PobierzLpWypadku: (konfiguracja) => {
    return axios.post(
      `${podstawaLinku}/funkcje_wyboru/api_pobierz_lp_wypadku.php`,
      konfiguracja
    );
  },

  pobieranieDokumentacjaWWP: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_05_wypadki_w_pracy/01_01_01_wykaz_wypadkow.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieranieDokumentacjaWWP1: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_05_wypadki_w_pracy/01_01_01_wykaz_wypadkow1.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszDokumentacjaWWP: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/04_05_wypadki_w_pracy/01_01_02_dodawanie_wypadku.php`,
      elementyFormularza
    );
  },
  usuwanieDokumentacjaWWP: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/04_05_wypadki_w_pracy/01_01_03_delete_dokumentacja.php`,
      obiektId
    );
  },
  pobieranieDokumentacjaWWPEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_05_wypadki_w_pracy/01_01_04_wypadki_edycja.php?Id=${Id}`
    );
  },
  zapiszDokumentacjaWWPUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/04_05_wypadki_w_pracy/01_01_05_dodawanie_wypadkow_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // POBIERANIE DZIAŁAŃ WYPADKI

  PobierzNumerWypadku: () => {
    return axios.get(
      `${podstawaLinku}/04_05_wypadki_w_pracy/00_01_wybor_wypadku.php`
    );
  },

  pobieranieDokumentacjaOW: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_05_wypadki_w_pracy/01_02_01_wykaz_dzialan_wypadki_ocena.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieranieDokumentacjaOW1: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_05_wypadki_w_pracy/01_02_01_wykaz_dzialan_wypadki_ocena1.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszDokumentacjaOW: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/04_05_wypadki_w_pracy/01_02_02_dodawanie_dzialania_wypadki_ocena.php`,
      elementyFormularza
    );
  },
  pobieranieDokumentacjaOWEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_05_wypadki_w_pracy/01_02_04_dzialania_wypadki_edycja_ocena.php?Id=${Id}`
    );
  },
  zapiszDokumentacjaOWUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/04_05_wypadki_w_pracy/01_02_05_dodawanie_dzialanie_wypadki_ocena_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // POBIERANIE DZIAŁAŃ WYPADKI OCENA SKUTECZNOSCI

  pobieranieDokumentacjaOWOS: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_05_wypadki_w_pracy/01_03_01_wykaz_dzialan_wypadki_ocena_skutecznosci.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieranieDokumentacjaOWOS1: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_05_wypadki_w_pracy/01_03_01_wykaz_dzialan_wypadki_ocena_skutecznosci1.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieranieDokumentacjaOWOSEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_05_wypadki_w_pracy/01_03_04_dzialania_wypadki_edycja_ocena_skutecznosci.php?Id=${Id}`
    );
  },
  zapiszDokumentacjaOWOSUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/04_05_wypadki_w_pracy/01_03_05_dodawanie_dzialanie_wypadki_skutecznosci_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // CHOROBY ZAWODOWE

  pobieranieDokumentacjaCHZAW: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_06_choroby_zawodowe/01_01_01_wykaz_chorob.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieranieDokumentacjaCHZAW1: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_06_choroby_zawodowe/01_01_01_wykaz_chorob1.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszDokumentacjaCHZAW: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/04_06_choroby_zawodowe/01_01_02_dodawanie_choroby.php`,
      elementyFormularza
    );
  },
  usuwanieDokumentacjaCHZAW: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/04_06_choroby_zawodowe/01_01_03_delete_dokumentacja.php`,
      obiektId
    );
  },
  pobieranieDokumentacjaCHZAWEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_06_choroby_zawodowe/01_01_04_choroby_edycja.php?Id=${Id}`
    );
  },
  zapiszDokumentacjaCHZAWUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/04_06_choroby_zawodowe/01_01_05_dodawanie_choroby_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // POBIERANIE DZIAŁAŃ CHOROBY ZAWODOWE

  pobieranieDokumentacjaSKCHZ: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_06_choroby_zawodowe/01_02_01_wykaz_dzialan_choroby.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieranieDokumentacjaSKCHZ1: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_06_choroby_zawodowe/01_02_01_wykaz_dzialan_choroby1.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszDokumentacjaSKCHZ: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/04_06_choroby_zawodowe/01_02_02_dodawanie_dzialania_wypadki_ocena.php`,
      elementyFormularza
    );
  },

  pobieranieDokumentacjaSKCHZEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_06_choroby_zawodowe/01_02_04_dzialania_wypadki_edycja_ocena.php?Id=${Id}`
    );
  },
  zapiszDokumentacjaSKCHZUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/04_06_choroby_zawodowe/01_02_05_dodawanie_dzialanie_choroby_ocena_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // POBIERANIE SYTUACJI POTENCJALNIE WYPADKOWYCH

  PobierzNumerSPW: () => {
    return axios.get(
      `${podstawaLinku}/04_05_sytuacje_potencjalnie_niebezpieczne/00_01_wybor_sytuacji.php`
    );
  },
  PobierzLpDzialaniaSPW: (konfiguracja) => {
    return axios.post(
      `${podstawaLinku}/funkcje_wyboru/api_pobierz_lp_sytuacji.php`,
      konfiguracja
    );
  },

  pobieranieDokumentacjaSPW: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_05_sytuacje_potencjalnie_niebezpieczne/01_01_01_wykaz_sytuacji.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieranieDokumentacjaSPW1: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_05_sytuacje_potencjalnie_niebezpieczne/01_01_01_wykaz_sytuacji1.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszDokumentacjaSPW: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/04_05_sytuacje_potencjalnie_niebezpieczne/01_01_02_dodawanie_sytuacji.php`,
      elementyFormularza
    );
  },
  usuwanieDokumentacjaSPW: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/04_05_sytuacje_potencjalnie_niebezpieczne/01_01_03_delete_dokumentacja.php`,
      obiektId
    );
  },
  pobieranieDokumentacjaSPWEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_05_sytuacje_potencjalnie_niebezpieczne/01_01_04_sytuacje_edycja.php?Id=${Id}`
    );
  },
  zapiszDokumentacjaSPWUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/04_05_sytuacje_potencjalnie_niebezpieczne/01_01_05_dodawanie_sytuacje_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // POBIERANIE DZIAŁAŃ POTENCJALNIE WYPADKOWYCH

  pobieranieDokumentacjaSPWDZ: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_05_sytuacje_potencjalnie_niebezpieczne/01_02_01_wykaz_dzialan_sytuacji_ocena.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieranieDokumentacjaSPWDZ1: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_05_sytuacje_potencjalnie_niebezpieczne/01_02_01_wykaz_dzialan_sytuacji_ocena1.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszDokumentacjaSPWDZ: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/04_05_sytuacje_potencjalnie_niebezpieczne/01_02_02_dodawanie_dzialania_wypadki_ocena.php`,
      elementyFormularza
    );
  },
  pobieranieDokumentacjaSPWDZEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_05_sytuacje_potencjalnie_niebezpieczne/01_02_04_dzialania_wypadki_edycja_ocena.php?Id=${Id}`
    );
  },
  zapiszDokumentacjaSPWDZUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/04_05_sytuacje_potencjalnie_niebezpieczne/01_02_05_dodawanie_dzialanie_sytuacje_ocena_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // POBIERANIE DZIAŁAŃ POTENCJALNIE WYPADKOWYCH OCENA

  pobieranieDokumentacjaSPWDZO: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_05_sytuacje_potencjalnie_niebezpieczne/01_03_01_wykaz_dzialan_sytuacje_ocena_skutecznosci.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieranieDokumentacjaSPWDZO1: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_05_sytuacje_potencjalnie_niebezpieczne/01_03_01_wykaz_dzialan_sytuacje_ocena_skutecznosci1.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszDokumentacjaSPWDZO: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/04_05_sytuacje_potencjalnie_niebezpieczne/01_02_02_dodawanie_dzialania_wypadki_ocena.php`,
      elementyFormularza
    );
  },
  pobieranieDokumentacjaSPWDZOEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_05_sytuacje_potencjalnie_niebezpieczne/01_03_04_dzialania_sytuacji_edycja_ocena_skutecznosci.php?Id=${Id}`
    );
  },
  zapiszDokumentacjaSPWDZOUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/04_05_sytuacje_potencjalnie_niebezpieczne/01_03_05_dodawanie_dzialanie_sytuacji_skutecznosci_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // KONTROLE WENĘTRZNE BHP

  pobieranieWykazuKontroli: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_07_kontrole_wewnetrzne/01_01_01_wykaz_kontroli_wewnetrznych.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieranieWykazuKontroli1: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_07_kontrole_wewnetrzne/01_01_01_wykaz_kontroli_wewnetrznych1.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszDokumentacjaKontroli: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/04_07_kontrole_wewnetrzne/01_01_02_dodawanie_kontroli.php`,
      elementyFormularza
    );
  },
  usuwanieDokumentacjaKONTWEW: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/04_07_kontrole_wewnetrzne/01_01_03_delete_dokumentacja.php`,
      obiektId
    );
  },
  pobieranieKontroliEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_07_kontrole_wewnetrzne/01_01_04_kontrole_edycja.php?Id=${Id}`
    );
  },
  zapiszKontroliUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/04_07_kontrole_wewnetrzne/01_01_05_dodawanie_kontroli_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  pobieranieWykazuKontroliDzialania: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_07_kontrole_wewnetrzne/01_02_01_wykaz_uwag.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszDokumentacjaKontroliUwaga: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/04_07_kontrole_wewnetrzne/01_02_02_dodawanie_uwag.php`,
      elementyFormularza
    );
  },




  
  pobieranieKontroliDzialaniaEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_07_kontrole_wewnetrzne/01_02_04_dzialania_kontrole_edycja_ocena.php?Id=${Id}`
    );
  },
  zapiszKontroliDzialaniaUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/04_07_kontrole_wewnetrzne/01_02_05_dodawanie_dzialanie_kontrola_ocena_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  pobieranieWykazuKontroliDzialaniaOcena: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_07_kontrole_wewnetrzne/01_03_04_dzialania_kontrole_edycja_ocena_skutecznosci.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieranieWykazuKontroliDzialaniaOcena1: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_07_kontrole_wewnetrzne/01_03_04_dzialania_kontrole_edycja_ocena_skutecznosci1.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieranieKontroliDzialaniaOcenaEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_07_kontrole_wewnetrzne/01_03_04_dzialania_kontrole_edycja_ocena_skutecznosci.php?Id=${Id}`
    );
  },
  zapiszKontroliDzialaniaOcenaUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/04_07_kontrole_wewnetrzne/01_03_05_dodawanie_dzialanie_kontrola_ocena_skutecznosci_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // KONTROLE ZENĘTRZNE BHP

  pobieranieWykazuKontroliZew: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_08_kontrole_zewnetrzne/01_01_01_wykaz_kontroli_zewnetrznych.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieranieWykazuKontroliZew1: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_08_kontrole_zewnetrzne/01_01_01_wykaz_kontroli_zewnetrznych.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszDokumentacjaKontroliZew: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/04_08_kontrole_zewnetrzne/01_01_02_dodawanie_kontroli.php`,
      elementyFormularza
    );
  },
  usuwanieDokumentacjaKONTZEW: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/04_08_kontrole_zewnetrzne/01_01_03_delete_dokumentacja.php`,
      obiektId
    );
  },
  pobieranieKontroliZewEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_08_kontrole_zewnetrzne/01_01_04_kontrole_edycja.php?Id=${Id}`
    );
  },
  zapiszKontroliZewUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/04_08_kontrole_zewnetrzne/01_01_05_dodawanie_kontroli_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  pobieranieWykazuKontroliZewDzialania: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_08_kontrole_zewnetrzne/01_02_01_wykaz_uwag.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },




  
  pobieranieKontroliZewDzialaniaEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_08_kontrole_zewnetrzne/01_02_04_dzialania_kontrole_edycja_ocena.php?Id=${Id}`
    );
  },
  zapiszKontroliZewDzialaniaUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/04_08_kontrole_zewnetrzne/01_02_05_dodawanie_dzialanie_kontrola_ocena_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  pobieranieWykazuKontroliZewDzialaniaOcena: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_08_kontrole_zewnetrzne/01_03_04_dzialania_kontrole_edycja_ocena_skutecznosci.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieranieWykazuKontroliZewDzialaniaOcena1: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_08_kontrole_zewnetrzne/01_03_04_dzialania_kontrole_edycja_ocena_skutecznosci1.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieranieKontroliZewDzialaniaOcenaEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_08_kontrole_zewnetrzne/01_03_04_dzialania_kontrole_edycja_ocena_skutecznosci.php?Id=${Id}`
    );
  },
  zapiszKontroliZewDzialaniaOcenaUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/04_08_kontrole_zewnetrzne/01_03_05_dodawanie_dzialanie_kontrola_ocena_skutecznosci_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // PRACE SZCZEGÓLNIE NIEBEZPIECZNE

  pobieranieDokumentacjaPSZNIEBEZ: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_09_prace_szczegolnie_niebezpieczne/01_01_01_wykaz_prac.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszDokumentacjaPSZNIEBEZ: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/04_09_prace_szczegolnie_niebezpieczne/01_01_02_dodawanie_prac.php`,
      elementyFormularza
    );
  },
  usuwanieDokumentacjaPSZNIEBEZ: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/04_09_prace_szczegolnie_niebezpieczne/01_01_03_delete_dokumentacja.php`,
      obiektId
    );
  },
  pobieranieDokumentacjaPSZNIEBEZEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_09_prace_szczegolnie_niebezpieczne/01_01_04_prace_edycja.php?Id=${Id}`
    );
  },
  zapiszDokumentacjaPSZNIEBEZUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/04_09_prace_szczegolnie_niebezpieczne/01_01_05_dodawanie_prace_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // Substancje niebezpieczne Przeglad

  pobieranieDokumentacjaPSZNIEBEZPrzeglad: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_09_prace_szczegolnie_niebezpieczne/01_02_01_przeglady_prace.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszDokumentacjaPSZNIEBEZPrzeglad: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/04_09_prace_szczegolnie_niebezpieczne/01_02_03_dodawanie_przegladu_prace.php`,
      elementyFormularza
    );
  },
  usuwanieDokumentacjaPSZNIEBEZPrzeglad: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/04_09_prace_szczegolnie_niebezpieczne/01_02_04_delete_przegladu_prace.php`,
      obiektId
    );
  },
  pobieranieDokumentacjaPSZNIEBEZPrzegladEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/04_09_prace_szczegolnie_niebezpieczne/01_02_05_przeglad_prace_edycja.php?Id=${Id}`
    );
  },
  zapiszDokumentacjaPSZNIEBEZPrzegladUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/04_09_prace_szczegolnie_niebezpieczne/01_04_06_dodawanie_przegladu_prace_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // STEROWANIE OPERACYJNE

  // WYROBY

  pobieranieWyrobyUslugi: (Id) => {
    return axios.get(
      `${podstawaLinku}/05_01_wyroby/01_01_01_wykaz_wyrobow.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszWyrobyUslugi: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/05_01_wyroby/01_01_02_dodawanie_wyrobu.php`,
      elementyFormularza
    );
  },
  usuwanieWyrobyUslugi: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/05_01_wyroby/01_01_03_delete_wyroby.php`,
      obiektId
    );
  },
  pobieranieWyrobyUslugiEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/05_01_wyroby/01_01_04_wyroby_edycja.php?Id=${Id}`
    );
  },

  zapiszWyrobyUslugiUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/05_01_wyroby/01_01_05_dodawanie_wyrobow_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // GRUPY WYROBóW

  pobieranieGrupyWyrobow: (Id) => {
    return axios.get(
      `${podstawaLinku}/05_01_wyroby/01_02_01_grupy_wyrobow.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszGrupyWyrobow: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/05_01_wyroby/01_02_02_dodawanie_grupy.php`,
      elementyFormularza
    );
  },
  usuwanieGrupyWyrobow: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/05_01_wyroby/01_02_03_delete_grupy.php`,
      obiektId
    );
  },
  pobieranieGrupyWyrobowEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/05_01_wyroby/01_02_04_grupy_edycja.php?Id=${Id}`
    );
  },
  zapiszGrupyWyrobowUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/05_01_wyroby/01_02_05_dodawanie_grupy_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // ZAKUPY

  pobieranieZakupy: (Id) => {
    return axios.get(
      `${podstawaLinku}/07_03_zakupy/01_01_01_wykaz_dostawcow.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszZakupy: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/07_03_zakupy/01_01_02_dodawanie_dostawcy.php`,
      elementyFormularza
    );
  },
  usuwanieZakupy: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/07_03_zakupy/01_01_03_delete_dostawcy.php`,
      obiektId
    );
  },
  pobieranieZakupyEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/07_03_zakupy/01_01_04_wykaz_dostawcow_edycja.php?Id=${Id}`
    );
  },
  zapiszZakupyUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/07_03_zakupy/01_01_05_dodawanie_dostawcow_update.php?Id=${Id}`,
      elementyFormularza
    );
  },
  // ZAKUPY
  pobieranieZakupyZrealizowane: (Id) => {
    return axios.get(
      `${podstawaLinku}/07_03_zakupy/01_02_01_wykaz_zakupow.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszZakupyZrealizowane: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/07_03_zakupy/01_02_02_dodawanie_zakupu.php`,
      elementyFormularza
    );
  },
  usuwanieZakupyZrealizowane: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/07_03_zakupy/01_02_03_delete_dostawcy_zakupy.php`,
      obiektId
    );
  },
  pobieranieZakupyEdycjaZrealizowane: (Id) => {
    return axios.get(
      `${podstawaLinku}/07_03_zakupy/01_02_04_wykaz_zakupy_edycja.php?Id=${Id}`
    );
  },
  zapiszZakupyUpdateZrealizowane: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/07_03_zakupy/01_02_05_dodawanie_zakupu_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // ZAKUPY - wykaz przeglądy

  pobieranieZakupyprzeglady: (Id) => {
    return axios.get(
      `${podstawaLinku}/07_03_zakupy/01_04_01_przeglady_dostawcow.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszPrzegladZakupy: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/07_03_zakupy/01_04_02_dodawanie_przegladu_dostawcow.php`,
      elementyFormularza
    );
  },
  usuwaniePrzegladZakupy: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/07_03_zakupy/01_04_03_delete_przegladu_dostawcow.php`,
      obiektId
    );
  },
  pobieraniePrzegladZakupyEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/07_03_zakupy/01_04_04_przeglad_dostawcow_edycja.php?Id=${Id}`
    );
  },
  zapiszPrzegladZakupyUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/07_03_zakupy/01_04_05_dodawanie_przegladu_dostawcow_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // PROJEKTOWANIE



  pobieranieMenuProjektow: () => {
    return axios.get(
      `${podstawaLinku}/07_04_projektowanie/00_01_wybor_projektu.php`
    );
  },

  pobieranieWykazuProjektow: (Id) => {
    return axios.get(
      `${podstawaLinku}/07_04_projektowanie/01_01_01_wykaz_projektow.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieranieWykazuProjektow1: (Id) => {
    return axios.get(
      `${podstawaLinku}/07_04_projektowanie/01_01_01_wykaz_projektow1.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieranieSzczegolyProjektu: (Id) => {
    return axios.get(
      `${podstawaLinku}/07_04_projektowanie/01_02_01_wykaz_projektow_szczegoly.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieranieSzczegolyProjektu1: (Id) => {
    return axios.get(
      `${podstawaLinku}/07_04_projektowanie/01_02_01_wykaz_projektow_szczegoly1.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },


  PobierzLpProjektu: (konfiguracja) => {
    return axios.post(
      `${podstawaLinku}/funkcje_wyboru/api_pobierz_lp_projektu.php`,
      konfiguracja
    );
  },

  pobierzStatusyRealizacjiProjektu: (konfiguracja) => {
    return axios.post(
      `${podstawaLinku}/funkcje_wyboru/api_status_realizacji_projektu.php`,
      konfiguracja
    );
  },

  zapiszProjekt: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/07_04_projektowanie/01_01_02_dodawanie_projektow.php`,
      elementyFormularza
    );
  },
  usuwanieProjektu: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/07_04_projektowanie/01_01_03_delete_projektow.php`,
      obiektId
    );
  },
  pobieranieProjektEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/07_04_projektowanie/01_01_04_wykaz_projektow_edycja.php?Id=${Id}`
    );
  },
  zapiszProjektUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/07_04_projektowanie/01_01_05_dodawanie_projektow_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  zapiszSzczegolyProjekt: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/07_04_projektowanie/01_02_02_dodawanie_projekt_szczegoly.php`,
      elementyFormularza
    );
  },
  usuwanieProjektuSzczegóły: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/07_04_projektowanie/01_02_03_delete_czesci_projektu.php`,
      obiektId
    );
  },
  pobieranieSzczegolyProjektEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/07_04_projektowanie/01_02_04_wykaz_szczegoly_projektu_edycja.php?Id=${Id}`
    );
  },
  zapiszSzczegolyProjektUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/07_04_projektowanie/01_02_05_dodawanie_szczegoly_projektow_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // Zapytania ofertowe

  pobierzStatusZapytania: () => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_status_zapytania.php`
    );
  },
  pobierzStatusOfert: () => {
    return axios.get(`${podstawaLinku}/funkcje_wyboru/api_status_ofert.php`);
  },
  pobierzStatusZamowienia: () => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_status_zamowienia.php`
    );
  },
  pobierzStatusUwag: () => {
    return axios.get(`${podstawaLinku}/funkcje_wyboru/api_status_uwag.php`);
  },

  pobieranieZapytaniaOfertowego: (Id) => {
    return axios.get(
      `${podstawaLinku}/08_01_zapytania_ofertowe/01_01_Zapytania_wszystkie.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },

  pobieranieZapytaniaOfertowego1: (Id) => {
    return axios.get(
      `${podstawaLinku}/08_01_zapytania_ofertowe/01_01_Zapytania_wszystkie1.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },

  pobieranieZapytaniaOfertowegoSzczegoly1: (Id) => {
    return axios.get(
      `${podstawaLinku}/08_01_zapytania_ofertowe/01_01_Szczegoly_zapytania_wszystkie1.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },




  
  pobieranieZapytaniaOfertowegoOdrzucone: (Id) => {
    return axios.get(
      `${podstawaLinku}/08_01_zapytania_ofertowe/01_01a_Zapytania_odrzucone.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieranieZapytaniaOfertowegoPrzyjete: (Id) => {
    return axios.get(
      `${podstawaLinku}/08_01_zapytania_ofertowe/01_01b_Zapytania_przyjete.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  PobierzLpZaytania: (konfiguracja) => {
    return axios.post(
      `${podstawaLinku}/funkcje_wyboru/api_pobierz_lp_zapytania.php`,
      konfiguracja
    );
  },

  zapiszZapytaniaOfertowego: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/08_01_zapytania_ofertowe/01_02_dodawanie_zapytania.php`,
      elementyFormularza
    );
  },
  usuwanieZapytaniaOfertowego: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/08_01_zapytania_ofertowe/01_03_delete_zapytania.php`,
      obiektId
    );
  },
  pobieranieZapytaniaOfertowegoEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/08_01_zapytania_ofertowe/01_04_zapytania_edycja.php?Id=${Id}`
    );
  },
  zapiszZapytaniaOfertowegoUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/08_01_zapytania_ofertowe/01_05_dodawanie_zapytania_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // Oferty

  pobieranieOferty: (Id) => {
    return axios.get(
      `${podstawaLinku}/08_02_oferty/01_01_Oferty_wszystkie.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieranieOfertyOdrzucone: (Id) => {
    return axios.get(
      `${podstawaLinku}/08_02_oferty/01_01a_Oferty_odrzucone.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieranieOfertyPrzyjete: (Id) => {
    return axios.get(
      `${podstawaLinku}/08_02_oferty/01_01_Oferty_przyjete.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieranieOferty1: (Id) => {
    return axios.get(
      `${podstawaLinku}/08_02_oferty/01_01_Oferty_wszystkie1.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieranieSzczegolyOferty1: (Id) => {
    return axios.get(
      `${podstawaLinku}/08_02_oferty/01_01_Oferty_szczegoly_wszystkie1.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  PobierzLpOferty: (konfiguracja) => {
    return axios.post(
      `${podstawaLinku}/funkcje_wyboru/api_pobierz_lp_oferty.php`,
      konfiguracja
    );
  },

  zapiszOferty: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/08_02_oferty/01_02_dodawanie_oferty.php`,
      elementyFormularza
    );
  },
  usuwanieOferty: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/08_02_oferty/01_03_delete_oferty.php`,
      obiektId
    );
  },
  pobieranieOfertyEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/08_02_oferty/01_04_oferta_edycja.php?Id=${Id}`
    );
  },
  zapiszOfertyUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/08_02_oferty/01_05_dodawanie_oferty_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // ZAMóWIENIA

  pobieranieZamowienia: (Id) => {
    return axios.get(
      `${podstawaLinku}/08_03_zamowienia/01_01_Wykaz_zamowienia.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieranieZamowienia1: (Id) => {
    return axios.get(
      `${podstawaLinku}/08_03_zamowienia/01_01_Wykaz_zamowienia1.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieranieSzczegolyZamowienia1: (Id) => {
    return axios.get(
      `${podstawaLinku}/08_03_zamowienia/01_01_Wykaz_szczegoly_zamowienia1.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszZamowienie: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/08_03_zamowienia/01_02_dodawanie_zamowienia.php`,
      elementyFormularza
    );
  },
  usuwanieZamowienia: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/08_03_zamowienia/01_03_delete_zamowienia.php`,
      obiektId
    );
  },

  pobieranieZamowienieEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/08_03_zamowienia/01_04_zamowienia_edycja.php?Id=${Id}`
    );
  },
  zapiszZamowienieUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/08_03_zamowienia/01_05_dodawanie_zamowienia_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  PobierzLpZamowienia: (konfiguracja) => {
    return axios.post(
      `${podstawaLinku}/funkcje_wyboru/api_pobierz_lp_zamowienia.php`,
      konfiguracja
    );
  },

  // REALIZACJA

  PobierzLpRaportu: (konfiguracja) => {
    return axios.post(
      `${podstawaLinku}/funkcje_wyboru/api_pobierz_lp_raportu.php`,
      konfiguracja
    );
  },

  pobieranieZamowieniaWTrakcie: (Id) => {
    return axios.get(
      `${podstawaLinku}/08_03_zamowienia/01_01_Wykaz_zamowienia_w_trakcie.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieranieZamowieniaZakonczone: (Id) => {
    return axios.get(
      `${podstawaLinku}/08_03_zamowienia/01_01_Wykaz_zamowienia_zakonczone.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },

  pobieranieDzialaniaOperacyjne: (Id) => {
    return axios.get(
      `${podstawaLinku}/08_04_realizacja/01_01_01_Wykaz_dzialania.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszDzialaniaOperacyjne: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/08_04_realizacja/01_01_02_dodawanie_dzialania.php`,
      elementyFormularza
    );
  },
  usuwanieDzialaniaOperacyjne: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/08_04_realizacja/01_01_03_delete_dokumentacja.php`,
      obiektId
    );
  },
  pobieranieDzialaniaOperacyjneEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/08_04_realizacja/01_01_04_dzialania_edycja.php?Id=${Id}`
    );
  },
  zapiszDzialaniaOperacyjneUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/08_04_realizacja/01_01_05_dodawanie_dzialania_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  pobieranieRaporty: (Id, id_wyrobu) => {
    return axios.get(
      `${podstawaLinku}/08_04_realizacja/02_01_Wykaz_raportow.php?Id=${Id}&Id_wyrobu=${id_wyrobu}`
    );
  },
  pobieranieRaportyDoZamowienia1: (Id) => {
    return axios.get(
      `${podstawaLinku}/08_04_realizacja/02_01_Wykaz_raportow1.php?Id=${Id}`
    );
  },
  pobieranieRaportyDoZamowienia2: (Id) => {
    return axios.get(
      `${podstawaLinku}/08_04_realizacja/02_01_Wykaz_raportow2.php?Id=${Id}`
    );
  },
  pobieranieRaportySzczegolyDoZamowienia2: (Id) => {
    return axios.get(
      `${podstawaLinku}/08_04_realizacja/02_01_Wykaz_szczegoly_raportow2.php?Id=${Id}`
    );
  },
 
  zapiszRaportProdukcyjny: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/08_04_realizacja/02_02_dodawanie_raportu.php`,
      elementyFormularza
    );
  },
  usuwanieRaportDoZamowienia: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/08_04_realizacja/02_03_delete_raportu.php`,
      obiektId
    );
  },

  
  pobieranieRaportyProdukcyjneEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/08_04_realizacja/02_04_raport_edycja.php?Id=${Id}`
    );
  },
  zapiszRaportyProdukcyjneUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/08_04_realizacja/02_05_dodawanie_raportu_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  pobieranieMagazynWZ: (Id) => {
    return axios.get(
      `${podstawaLinku}/08_04_realizacja/03_01_Mazagazyn_WZ.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },

  //-----------------------------------------------------------------------------------------------

  // Zlecenia na magazyn

  pobierzStatusZleceniaMazgazyn: () => {
    return axios.get(`${podstawaLinku}/funkcje_wyboru/api_status_zlecenia.php`);
  },
  PobierzLpZleceniaMagazyn: (konfiguracja) => {
    return axios.post(
      `${podstawaLinku}/funkcje_wyboru/api_pobierz_lp_zlecenia_magazyn.php`,
      konfiguracja
    );
  },

  pobieranieZleceniaMagazyn: (Id) => {
    return axios.get(
      `${podstawaLinku}/08_05_zlecenia_magazyn/01_01_Zlecenia_wszystkie.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieranieZleceniaMagazyn1: (Id) => {
    return axios.get(
      `${podstawaLinku}/08_05_zlecenia_magazyn/01_01_Zlecenia_wszystkie1.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieranieZleceniaMagazynSzczegoly1: (Id) => {
    return axios.get(
      `${podstawaLinku}/08_05_zlecenia_magazyn/01_01_Zlecenia_szczegoly_wszystkie1.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszZleceniaMagazyn: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/08_05_zlecenia_magazyn/01_02_dodawanie_zlecenia.php`,
      elementyFormularza
    );
  },
  usuwanieZleceniaMagazyn: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/08_05_zlecenia_magazyn/01_03_delete_zlecenia.php`,
      obiektId
    );
  },
  pobieranieZleceniaMagazynEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/08_05_zlecenia_magazyn/01_04_zlecenie_edycja.php?Id=${Id}`
    );
  },
  zapiszZleceniaMagazynUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/08_05_zlecenia_magazyn/01_05_dodawanie_zlecenia_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // REALIZACJA MAGAZYN

  PobierzLpRaportuMagazyn: (konfiguracja) => {
    return axios.post(
      `${podstawaLinku}/funkcje_wyboru/api_pobierz_lp_raportu_magazyn.php`,
      konfiguracja
    );
  },

  pobieranieZamowieniaMagazynWTrakcie: (Id) => {
    return axios.get(
      `${podstawaLinku}/08_05_zlecenia_magazyn/01_01_Zlecenia_wszystkie_w_trakcie.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieranieZamowieniaMagazynZakonczone: (Id) => {
    return axios.get(
      `${podstawaLinku}/08_05_zlecenia_magazyn/01_01_Zlecenia_wszystkie_zakonczone.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieranieDzialaniaMagazynOperacyjne: (Id) => {
    return axios.get(
      `${podstawaLinku}/08_04_realizacja/01_01_Wykaz_dzialania.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },

  zapiszDzialaniaOperacyjneMagazyn: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/08_04_realizacja/01_02_dodawanie_dzialania.php`,
      elementyFormularza
    );
  },

  pobieranieRaportyMagazyn: (Id, id_wyrobu) => {
    return axios.get(
      `${podstawaLinku}/08_06_realizacja_magazyn/01_01_Wykaz_raportow_magazyn.php?Id=${Id}&Id_wyrobu=${id_wyrobu}`
    );
  },
  pobieranieRaportyMagazyn1: (Id) => {
    return axios.get(
      `${podstawaLinku}/08_06_realizacja_magazyn/01_01_Wykaz_raportow_magazyn1.php?Id=${Id}`
    );
  },
  pobieranieRaportyMagazyn2: (Id) => {
    return axios.get(
      `${podstawaLinku}/08_06_realizacja_magazyn/01_01_Wykaz_raportow_magazyn2.php?Id=${Id}`
    );
  },
  pobieranieRaportySzczegolyMagazyn2: (Id) => {
    return axios.get(
      `${podstawaLinku}/08_06_realizacja_magazyn/01_01_Wykaz_raportow_szczegoly_magazyn2.php?Id=${Id}`
    );
  },

  zapiszRaportProdukcyjnyMagazyn: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/08_06_realizacja_magazyn/01_02_dodawanie_raportu_magazyn.php`,
      elementyFormularza
    );
  },
  usuwanieRaportyMagazyn: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/08_06_realizacja_magazyn/01_03_delete_raportu_magazyn.php`,
      obiektId
    );
  },
  pobieranieRaportProdukcyjnyMagazynEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/08_06_realizacja_magazyn/01_04_raport_magazyn_edycja.php?Id=${Id}`
    );
  },
  zapiszRaportProdukcyjnyMagazynUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/08_06_realizacja_magazyn/01_05_dodawanie_raportu_magazyn_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // REALIZACJA MAGAZYN SPRZEDAZ

  PobierzLpWZZamowienia: (konfiguracja) => {
    return axios.post(
      `${podstawaLinku}/funkcje_wyboru/api_pobierz_lp_WZ.php`,
      konfiguracja
    );
  },

  pobieranieZamowieniaWZ: (Id) => {
    return axios.get(
      `${podstawaLinku}/08_07_realizacja_magazyn_WZ/01_01_Wykaz_raportow_magazyn.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieranieZamowieniaWZ1: (Id) => {
    return axios.get(
      `${podstawaLinku}/08_07_realizacja_magazyn_WZ/01_01_Wykaz_raportow_magazyn1.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  pobieranieZamowieniaSzczegolyWZ1: (Id) => {
    return axios.get(
      `${podstawaLinku}/08_07_realizacja_magazyn_WZ/01_01_Wykaz_raportow_szczegoly_magazyn1.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
 
  zapiszZamowieniaWZ: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/08_07_realizacja_magazyn_WZ/01_02_dodawanie_zamowienie_WZ.php`,
      elementyFormularza
    );
  },
  usuwanieWZ: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/08_07_realizacja_magazyn_WZ/01_03_delete_WZ.php`,
      obiektId
    );
  },
 
  pobieranieZamowieniaWZEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/08_07_realizacja_magazyn_WZ/01_04_raport_magazyn_edycja.php?Id=${Id}`
    );
  },
  zapiszZamowieniaWZUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/08_07_realizacja_magazyn_WZ/01_05_dodawanie_raportu_magazyn_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  zapiszZamowieniaWZ1: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/08_07_realizacja_magazyn_WZ/01_02_dodawanie_zamowienie_WZ.php`,
      elementyFormularza
    );
  },
  usuwanieZamowieniaWZn1: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/08_07_realizacja_magazyn_WZ/01_01_03_delete_dokumentacja.php`,
      obiektId
    );
  },
  pobieranieZamowieniaWZEdycja1: (Id) => {
    return axios.get(
      `${podstawaLinku}/08_07_realizacja_magazyn_WZ/01_04_raport_magazyn_edycja.php?Id=${Id}`
    );
  },
  zapiszZamowieniaWZUpdate1: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/08_07_realizacja_magazyn_WZ/01_05_dodawanie_zlecenia_update.php?Id=${Id}`,
      elementyFormularza
    );
  },
  // Reklamacje____________________________________________________________________________________

  PobierzLpReklamacji: (konfiguracja) => {
    return axios.post(
      `${podstawaLinku}/funkcje_wyboru/api_pobierz_lp_reklamacji.php`,
      konfiguracja
    );
  },
  pobieranieReklamacje: (Id) => {
    return axios.get(
      `${podstawaLinku}/08_08_reklamacje/01_01_01_wykaz_reklamacji.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  
  zapiszReklamacje: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/08_08_reklamacje/01_01_02_dodawanie_reklamacji.php`,
      elementyFormularza
    );
  },
  usuwanieReklamacje: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/08_08_reklamacje/01_01_03_delete_reklamacje.php`,
      obiektId
    );
  },
  pobieranieReklamacjeEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/08_08_reklamacje/01_01_04_reklamacje_edycja.php?Id=${Id}`
    );
  },
  zapiszReklamacjeUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/08_08_reklamacje/01_01_05_dodawanie_reklamacji_update.php?Id=${Id}`,
      elementyFormularza
    );
  },

  // Reklamacje dzialania____________________________________________________________________________________


  pobieranieReklamacjeDzialania: (Id) => {
    return axios.get(
      `${podstawaLinku}/08_08_reklamacje/01_02_01_dzialania_reklamacja.php${
        Id ? "?Id=" + Id : ""
      }`
    );
  },
  zapiszReklamacjeDzialania: (elementyFormularza) => {
    return axios.post(
      `${podstawaLinku}/08_08_reklamacje/01_01_02_dodawanie_reklamacji.php`,
      elementyFormularza
    );
  },
  usuwanieReklamacjeDzialania: (obiektId) => {
    return axios.post(
      `${podstawaLinku}/08_08_reklamacje/01_01_03_delete_dokumentacja.php`,
      obiektId
    );
  },
  pobieranieReklamacjeDzialaniaEdycja: (Id) => {
    return axios.get(
      `${podstawaLinku}/08_08_reklamacje/01_02_04_reklamacje_dzialania_edycja.php?Id=${Id}`
    );
  },
  zapiszReklamacjeDzialaniaUpdate: (elementyFormularza, Id) => {
    return axios.post(
      `${podstawaLinku}/08_08_reklamacje/01_02_05_dodawanie_dzialania_update.php?Id=${Id}`,
      elementyFormularza
    );
  },


// USTALANIE DANYCH
  pobierzDIM1: () => {
    return axios.get(`${podstawaLinku}/00_00_USTALANIE_DANYCH/01_01_01_wykaz_dane_dostepowe_1.php`);
  },
  pobierzDIM2: () => {
    return axios.get(`${podstawaLinku}/00_00_USTALANIE_DANYCH/01_01_01_wykaz_dane_dostepowe_2.php`);
  },
  pobierzDIM3: () => {
    return axios.get(`${podstawaLinku}/00_00_USTALANIE_DANYCH/01_01_01_wykaz_dane_dostepowe_3.php`);
  },
  pobierzDIM4: () => {
    return axios.get(`${podstawaLinku}/00_00_USTALANIE_DANYCH/01_01_01_wykaz_dane_dostepowe_4.php`);
  },




  //Pobieranie do SELECTÓW ___________________________________________________________________________________________

  pobierzStatus: () => {
    return axios.get(`${podstawaLinku}/funkcje_wyboru/api_status_maszyny.php`);
  },
  pobierzStatusSamochody: () => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_status_samochody.php`
    );
  },
  pobierzRodzajPaliwa: () => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_status_rodzaje_paliwa.php`
    );
  },
  pobierzNormeEmisji: () => {
    return axios.get(`${podstawaLinku}/funkcje_wyboru/api_emisja_spalin.php`);
  },
  pobierzRodzajPojazdu: () => {
    return axios.get(`${podstawaLinku}/funkcje_wyboru/api_rodzaj_pojazdu.php`);
  },
  pobierzRodzajPojazduOsobowego: () => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_rodzaj_pojazdu_osobowego.php`
    );
  },
  pobierzCzynnoscMaszyny: () => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_rodzaj_czesci_przeglad.php`
    );
  },
  pobierzCzynnoscOdbioru: () => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_rodzaj_czesci_przeglad_odbior.php`
    );
  },
  pobierzCzynnoscOdbioruEL: () => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_rodzaj_czesci_przeglad_odbiorEL.php`
    );
  },
  pobierzCzynnoscOdbioruIT: () => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_rodzaj_czesci_przeglad_odbiorIT.php`
    );
  },


  pobierzJednostki: () => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_status_jednostki_paliwo.php`
    );
  },
  pobierzKontrahenta: () => {
    return axios.get(`${podstawaLinku}/funkcje_wyboru/api_kontrahent.php`);
  },
  pobierzRodzajPlatnosc: () => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_rodzaj_platnosci.php`
    );
  },
  pobierzRodzajPodzielnoscuPlatnosci: () => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_rodzaj_podzielnosci_platnosci.php`
    );
  },
  pobierzRachunekBankowy: () => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_rachunki_bankowe.php`
    );
  },
  pobierzJednostki_sprzedazy: () => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_status_jednostki_sprzedazy.php`
    );
  },
  pobierzWojewodztwo: () => {
    return axios.get(`${podstawaLinku}/funkcje_wyboru/api_wojewodztwa.php`);
  },
  pobierzMedia: () => {
    return axios.get(`${podstawaLinku}/funkcje_wyboru/api_media.php`);
  },

  pobierzRodzajUmowy: () => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_rodzaj_zatrudnienia.php`
    );
  },

  pobierzPomiarowkaWybor: () => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_sprawdzenie_pomiarowka.php`
    );
  },
  pobierzCzasZatrudnienia: () => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_czas_zatrudnienia.php`
    );
  },
  pobierzPlec: () => {
    return axios.get(`${podstawaLinku}/funkcje_wyboru/api_plec.php`);
  },
  pobierzStawkiVAT: () => {
    return axios.get(`${podstawaLinku}/funkcje_wyboru/api_stawki_VAT.php`);
  },
  pobierzRodzajPrzegladuBudynku: () => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_rodzaj_przegladu_budowlanego.php`
    );
  },

  PobierzZapytanieOfertowe: () => {
    return axios.get(`${podstawaLinku}/funkcje_wyboru/api_zapytanie.php`);
  },
  PobierzZapytanieOfertoweEdycja: () => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_zapytanie_edycja.php`
    );
  },
  PobierzZamowienia: () => {
    return axios.get(`${podstawaLinku}/funkcje_wyboru/api_zamowienia.php`);
  },
  PobierzZamowienia1: () => {
    return axios.get(`${podstawaLinku}/funkcje_wyboru/api_zamowienia1.php`);
  },
  PobierzZlecenieMAG: () => {
    return axios.get(`${podstawaLinku}/funkcje_wyboru/api_zlecenia.php`);
  },
  PobierzZlecenieMAG1: () => {
    return axios.get(`${podstawaLinku}/funkcje_wyboru/api_zlecenia1.php`);
  },
  PobierzWyrob: (id) => {
    return axios.get(`${podstawaLinku}/funkcje_wyboru/api_wyrob.php?Id=${id}`);
  },
  PobierzWyrobOferty: (id) => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_wyrob_oferty.php?Id=${id}`
    );
  },

  PobierzWyrob1: (id) => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_wyrob_zamowienia.php?Id=${id}`
    );
  },
  PobierzWyrob2: (id) => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_wyrob_raport.php?Id=${id}`
    );
  },

  PobierzRealizacjeZapytania: (id) => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_reklamacje_realizacja1.php?Id=${id}`
    );
  },
  PobierzRealizacjeZlecenieWewnetrzne: (id) => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_reklamacje_realizacja2.php?Id=${id}`
    );
  },

  PobierzRealizacjeZakupy: (id) => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_reklamacje_realizacja3.php?Id=${id}`
    );
  },

  
  PobierzRaportyDlaWyrobu: (id_wyrobu) => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_wyrob_raport_rozliczenie.php?Id_wyrobu=${id_wyrobu}`
    );
  },
  PobierzWyrobMagazyn: (id) => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_wyrob_raport_magazyn.php?Id=${id}`
    );
  },
  PobierzRodzajUwag: (id) => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_uwagi.php?Id=${id}`
    );
  },
  
  pobieranieZagrozenia: () => {
    return axios.get(`${podstawaLinku}/funkcje_wyboru/api_zagrozenia.php`);
  },

  pobierzOperacje: (id) => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_operacje.php?Id=${id}`
    );
  },
  pobierzStatus_pomiary: (id) => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_status_pomiary.php?Id=${id}`
    );
  },
  pobierzAspekty: (id) => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_aspekty.php?Id=${id}`
    );
  },
  pobierzMonitorowanie: () => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_monitorowanie_aspektow.php`
    );
  },

  pobierzWymagania: () => {
    return axios.get(`${podstawaLinku}/funkcje_wyboru/api_wymaganie.php`);
  },

  ponierzStosunekPracy: () => {
    return axios.get(`${podstawaLinku}/funkcje_wyboru/api_stosunek_pracy.php`);
  },

  pobierzOcenaSkutecznosci: () => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_ocena_skutecznosci.php`
    );
  },

  pobierzStatusMedycyna: () => {
    return axios.get(`${podstawaLinku}/funkcje_wyboru/api_medycyna_pracy.php`);
  },

  pobierzAkredytacje: () => {
    return axios.get(`${podstawaLinku}/funkcje_wyboru/api_akredytacja.php`);
  },
  pobierzStatusBSP: () => {
    return axios.get(`${podstawaLinku}/funkcje_wyboru/api_status_BSP.php`);
  },
  pobierzGrupyWyrobow: () => {
    return axios.get(`${podstawaLinku}/funkcje_wyboru/api_grupy_wyrobow.php`);
  },
  pobierzStatusSN: () => {
    return axios.get(`${podstawaLinku}/funkcje_wyboru/api_status_SN.php`);
  },
  pobierzWyroby: () => {
    return axios.get(`${podstawaLinku}/funkcje_wyboru/api_wyrob_wyrobu.php`);
  },
  PobierzRaportyWZ: () => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_wyrob_rozliczenie.php`
    );
  },

  PobierzRaportyWZEdycja: (id) => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_wyrob_rozliczenie_edycja.php?Id=${id}`
    );
  },


  // PobierzRaportyWZ:(id, id_wyrobu) => {
  //     return axios.get(`${podstawaLinku}/funkcje_wyboru/api_wyrob_raport_WZ.php?Id=${id}&Id_wyrobu=${id_wyrobu}`)
  // },

  pobierzZwolnieniaWyrobu: () => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_zwolenienie_wyrobu.php`
    );
  },
  PobierzZakwalifikowanegoDostawce: () => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_kontrahent_zakwalifikowany.php`
    );
  },

  PobierzOferte: () => {
    return axios.get(`${podstawaLinku}/funkcje_wyboru/api_oferty.php`);
  },
  PobierzOferteEdycja: () => {
    return axios.get(`${podstawaLinku}/funkcje_wyboru/api_oferty_edycja.php`);
  },
  PobierzRodzajDostaw: () => {
    return axios.get(`${podstawaLinku}/funkcje_wyboru/api_rodzaje_dostaw.php`);
  },
  PobierzRodzajZadowolenia: () => {
    return axios.get(`${podstawaLinku}/funkcje_wyboru/api_referencje.php`);
  },
PobierzCzyJestNIP: () => {
    return axios.get(`${podstawaLinku}/funkcje_wyboru/api_czy_NIP.php`);
  },

  
  PobierzRodzajSprawozdan: () => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_sprawozdania_srodowiskowe.php`
    );
  },

  pobierzWyborRoku: () => {
    return axios.get(`${podstawaLinku}/funkcje_wyboru/api_wybor_roku.php`);
  },

  pobierzStatusProcesu: () => {
    return axios.get(`${podstawaLinku}/funkcje_wyboru/api_status_procesu.php`);
  },
  pobierzStatusBudynku: () => {
    return axios.get(`${podstawaLinku}/funkcje_wyboru/api_status_budynku.php`);
  },

  pobierzStatusProjektu: () => {
    return axios.get(`${podstawaLinku}/funkcje_wyboru/api_status_projektu.php`);
  },
  pobierzCzesciProjektu: () => {
    return axios.get(`${podstawaLinku}/funkcje_wyboru/api_czesci_projektu.php`);
  },
  pobierzRodzajReklamacji: () => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_rodzaj_reklamacji.php`
    );
  },
  pobierzJakaReklamacja: () => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_jaka_reklamacja.php`
    );
  },
  pobierzKontrahencta1: () => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_kontrahenci1.php`
    );
  },
  pobierzKontrahencta2: () => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_kontrahenci2.php`
    );
  },
  pobierzKontrahencta3: () => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_kontrahenci3.php`
    );
  },
  pobierzDecyzjeReklamacje: () => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_decyzja_reklamacje.php`
    );
  },

  pobieranieKontroleWewnetrzne: () => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_kontrole_wewnetrzne.php`
    );
  },

  pobieranieKontroleZewnetrzne: () => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_kontrole_zewnetrzne.php`
    );
  }, 

  pobierzStatusReklamacji: () => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_status_reklamacji.php`
    );
  },

  PobierzStatusWypadekWPracy: () => {
    return axios.get(
      `${podstawaLinku}/funkcje_wyboru/api_status_wypadki_w_pracy.php`
    );
  },

  sumowaniePaliwa: (Id, data_od, data_do) => {
    return axios.get(
      `${podstawaLinku}/02_01_maszyny/api_sumowanie_paliwa.php${
        Id ? "?Id=" + Id : ""
      }${data_od ? "&data_od=" + data_od : ""}${
        data_do ? "&data_do=" + data_do : ""
      }`
    );
  },
  sumowaniePaliwaMaszynyDozorowe: (Id, data_od, data_do) => {
    return axios.get(
      `${podstawaLinku}/02_02_maszyny_dozorowe/api_sumowanie_paliwa.php${
        Id ? "?Id=" + Id : ""
      }${data_od ? "&data_od=" + data_od : ""}${
        data_do ? "&data_do=" + data_do : ""
      }`
    );
  },
  sumowaniePaliwaSamochodyOsobowe: (Id, data_od, data_do) => {
    return axios.get(
      `${podstawaLinku}/02_06_samochody_osobowe/api_sumowanie_paliwa.php${
        Id ? "?Id=" + Id : ""
      }${data_od ? "&data_od=" + data_od : ""}${
        data_do ? "&data_do=" + data_do : ""
      }`
    );
  },
  sumowaniePaliwaSamochodyCiezarowe: (Id, data_od, data_do) => {
    return axios.get(
      `${podstawaLinku}/02_07_samochody_ciezarowe/api_sumowanie_paliwa.php${
        Id ? "?Id=" + Id : ""
      }${data_od ? "&data_od=" + data_od : ""}${
        data_do ? "&data_do=" + data_do : ""
      }`
    );
  },

  sumowanieIlosciAbsencji: (data_od, data_do, wartoscSzukana) => {
    return axios.get(
      `${podstawaLinku}/03_02_HR_absercja/api_sumowanie_absencja.php?data_od=${data_od}&data_do=${data_do}&wartoscSzukana=${wartoscSzukana}`
    );
  },
};
