<template>
  <div class="ass">
    <table class="table_01_ZAKRES">
      <tr>
        <td
          style="width: 100%; height:70px; background: #adadad; font-size:24px; font-weight: 700"
          colspan="2"
        >
          ZAKRES OBOWIĄZKÓW, ODPOWIEDZIALNOŚCI I UPRAWNIEŃ PRACOWNIKA
        </td>
      </tr>

      <tr>
        <td style="width: 50%; background: #e3e3e3; font-weight: 500">
          Rodzaj zakresu obowiązków, odpowiedzialności i uprawnień
        </td>
        <td style="width: 50%">
          <span>{{ info.Rodzaj_zakresu }}</span>
        </td>
      </tr>
      <tr>
        <td style="width: 50%; background: #e3e3e3; font-weight: 500">
          Data i miejscowość opracowania zakresu obowiązków, odpowiedzialności i
          uprawnień
        </td>
        <td style="width: 50%">
          <span>{{ info.Data_zakresu }}</span>
        </td>
      </tr>

      <tr>
        <td style="width: 50%; background: #e3e3e3; font-weight: 500">
          Imię i zazwisko
        </td>
        <td style="width: 50%">
          <span>{{ info.Imie1 }} {{ info.Nazwisko }}</span>
        </td>
      </tr>

      <tr>
        <td style="width: 50%; background: #e3e3e3; font-weight: 500">
          Adres zamieszkania
        </td>
        <td style="width: 50%">
          <span
            >{{ info.Kod }} {{ info.Miejscowosc }} ul.{{ info.Ulica }}
            {{ info.Nr }}</span
          >
        </td>
      </tr>

      <tr>
        <td style="width: 50%; background: #e3e3e3; font-weight: 500">
          Stanowisko
        </td>
        <td style="width: 50%">
          <span>{{ info.Stanowisko }}</span>
        </td>
      </tr>

      <tr>
        <td
          style="width: 100%; height:40px; background: #adadad; font-size:14px; font-weight: 700"
          colspan="2"
        >
          Podległość służbowa
        </td>
      </tr>

      <tr>
        <td style="width: 50%; background: #e3e3e3; font-weight: 500">
          Bezpośredni przełożony
        </td>
        <td style="width: 50%">
          <span>{{ info.Bezposredni_przelozony }}</span>
        </td>
      </tr>

      <tr>
        <td
          style="width: 100%; height:40px; background: #adadad; font-size:14px; font-weight: 700"
          colspan="2"
        >
          Zakres obowiązków wynikających z art.100 Kodeksu pracy
        </td>
      </tr>

      <tr>
        <td
          style="width: 100%; font-size:14px; text-align:left; padding-left: 20px"
          colspan="2"
        >
          <p>
            Pracownik jest obowiązany wykonywać pracę sumiennie i starannie oraz
            stosować się do poleceń przełożonych, które dotyczą pracy, jeżeli
            nie są sprzeczne z przepisami prawa lub umową o pracę.
          </p>
          <p>
            Pracownik jest obowiązany do przestrzegania czasu pracy ustalonego w
            zakładzie pracy.
          </p>
          <p>
            Pracownik jest obowiązany do przestrzegania regulaminu pracy i
            ustalonego w zakładzie pracy porządku.
          </p>
          <p>
            Pracownik jest obowiązany do przestrzegania przepisów oraz zasad
            bezpieczeństwa i higieny, a także przepisów przeciwpożarowych.
          </p>
          <p>
            Pracownik jest obowiązany do dbania o dobro zakładu pracy, chronić
            jego mienie oraz zachować w tajemnicy informacje, których ujawnienie
            mogłoby narazić pracodawcę na szkodę.
          </p>
          <p>
            Pracownik jest obowiązany do przestrzegania tajemnicy określonej w
            odrębnych przepisach.
          </p>
          <p>
            Pracownik jest obowiązany do przestrzegania w zakładzie pracy zasad
            współżycia społecznego.
          </p>
        </td>
      </tr>

      <tr>
        <td
          style="width: 100%; height:40px; background: #adadad; font-size:14px; font-weight: 700"
          colspan="2"
        >
          Zakres obowiązków wynikających z art. 211 Kodeksu pracy
        </td>
      </tr>

      <tr>
        <td
          style="width: 100%; font-size:14px; text-align:left; padding-left: 20px"
          colspan="2"
        >
          <p>
            Pracownik jest obowiązany Przestrzeganie przepisów i zasad
            bezpieczeństwa i higieny pracy jest podstawowym obowiązkiem
            pracownika.
          </p>
          <p>
            Pracownik jest obowiązany znać przepisy i zasady bezpieczeństwa i
            higieny pracy, brać udział w szkoleniu i instruktażu z tego zakresu
            oraz poddawać się wymaganym egzaminom sprawdzającym.
          </p>
          <p>
            Pracownik jest obowiązany wykonywać pracę w sposób zgodny z
            przepisami i zasadami bezpieczeństwa i higieny pracy oraz stosować
            się do wydawanych w tym zakresie poleceń i wskazówek przełożonych.
          </p>
          <p>
            Pracownik jest obowiązany dbać o należyty stan maszyn, urządzeń,
            narzędzi i sprzętu oraz o porządek i ład w miejscu pracy.
          </p>
          <p>
            Pracownik jest obowiązany stosować środki ochrony zbiorowej, a także
            używać przydzielone środki ochrony indywidualnej oraz odzież i
            obuwie robocze, zgodnie z ich przeznaczeniem.
          </p>
          <p>
            Pracownik jest obowiązany poddawać się wstępnym, okresowym i
            kontrolnym oraz innym zaleconym badaniom lekarskim i stosować się do
            wskazań lekarskich.
          </p>
          <p>
            Pracownik jest obowiązany niezwłocznie zawiadomić przełożonego o
            zauważonym w zakładzie pracy wypadku albo zagrożeniu życia lub
            zdrowia ludzkiego oraz ostrzec współpracowników, a także inne osoby
            znajdujące się w rejonie zagrożenia, o grożącym im
            niebezpieczeństwie.
          </p>
          <p>
            Pracownik jest obowiązany współdziałać z pracodawcą i przełożonymi w
            wypełnianiu obowiązków dotyczących bezpieczeństwa i higieny pracy.
          </p>

        </td>
      </tr>

      <tr>
        <td
          style="width: 100%; height:40px; background: #adadad; font-size:14px; font-weight: 700"
          colspan="2"
        >
          Zakres obowiązków wynikających z regulaminów i instrukcji wewnętrznych
        </td>
      </tr>

      <tr>
        <td
          style="width: 100%; height: auto; font-size:14px; text-align:left; padding-left: 20px"
          colspan="2"
        >
          <p v-for="(czynnik2, index) in info.Obowiazki" :key="index">
            {{ czynnik2 }}
          </p>
        </td>
      </tr>

      <tr>
        <td
          style="width: 100%; height:40px; background: #adadad; font-size:14px; font-weight: 700"
          colspan="2"
        >
          Zakres uprawnień
        </td>
      </tr>

      <tr>
        <td
          style="width: 100%; height: auto; font-size:14px; text-align:left; padding-left: 20px"
          colspan="2"
        >
          <p v-for="(czynnik3, index) in info.Uprawnienia" :key="index">
            {{ czynnik3 }}
          </p>
        </td>
      </tr>

      <tr>
        <td
          style="width: 100%; height:40px; background: #adadad; font-size:14px; font-weight: 700"
          colspan="2"
        >
          Zakres odpowiedzialności
        </td>
      </tr>

      <tr>
        <td
          style="width: 100%; height: auto; font-size:14px; text-align:left; padding-left: 20px"
          colspan="2"
        >
          <p v-for="(czynnik1, index) in info.Odpowiedzialnosc" :key="index">
            {{ czynnik1 }}
          </p>
        </td>
      </tr>

      <tr>
        <td style="width: 50%; background: #e3e3e3; font-weight: 500">
          DATA i PODPIS PRACODAWCY :
        </td>
        <td style="width: 50%; height: 80px"></td>
      </tr>

      <tr>
        <td style="width: 50%; background: #e3e3e3; font-weight: 500">
          DATA i PODPIS PRACOWNIKA :
        </td>
        <td style="width: 50%; height: 80px"></td>
      </tr>
    </table>
  </div>
</template>

<script>
import { api } from "../../../serwisy/api.serwis";
export default {
  name: "Skierowanie",
  data: () => ({
    info: {
      Data: null,
      Id: null,
      Imie1: null,
      Nazwisko: null,
      Odpowiedzialnosc: null,
      Uprawnienia: null,
      Obowiazki: null,
    },
  }),
  created() {
    this.pobierzZakres();
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  methods: {
    async pobierzZakres() {
      try {
        const {
          data: { dane },
        } = await api.pobieranieZOOIU(this.id);
        const info = dane.map((row) => {
          row.Odpowiedzialnosc = row.PozycjeCzynikow.filter((pozycja) => {
            return pozycja.Id_rodzaj_czesci_zakresu == 1;
          }).map((pozycja) => {
            return pozycja.Opis_zakresu_1 === null || pozycja.Opis_zakresu_1 === '' ? (pozycja.Opis || ''): pozycja.Opis_zakresu_1 ;
          });

          row.Obowiazki = row.PozycjeCzynikow.filter((pozycja) => {
            return pozycja.Id_rodzaj_czesci_zakresu == 2;
          }).map((pozycja) => {
            return pozycja.Opis_zakresu_1 === null || pozycja.Opis_zakresu_1 === '' ? (pozycja.Opis || ''): pozycja.Opis_zakresu_1;
          });
          
          row.Uprawnienia = row.PozycjeCzynikow.filter((pozycja) => {
            return pozycja.Id_rodzaj_czesci_zakresu == 3;
          }).map((pozycja) => {
            return pozycja.Opis_zakresu_1 === null || pozycja.Opis_zakresu_1 === '' ? (pozycja.Opis || ''): pozycja.Opis_zakresu_1;
          });
          return row;
        })[0];

        this.info = info;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
.ass {
  width: 100%;
  height: auto;
  overflow: auto;
}
.table_01_ZAKRES {
  width: 100%;
  height: auto;
  font-size: 16px;
  margin-top: 40px;
  color: black;
  vertical-align: middle;
  margin-bottom: 100px;
}
.table_01_ZAKRES td {
  border: 1px solid black;
}

.input {
  width: 100%;
  height: 100%;

  text-align: center;
}
.table_01_ZAKRES p {
  margin-top: 0;
  margin-bottom: 0.1rem !important;
}
.table_01_ZAKRES .textarea {
  width: 100%;
  min-height: 30px;
  max-height: 200px;
  text-align: center;
}
</style>
