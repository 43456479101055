<template>
  <div class="Form_BUD_koszty">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI ZUŻYCIA MEDIÓW DLA BUDYNKU"
                : "FORMULARZ DODAWANIA ZUŻYCIA MEDIÓW BUDYNKU"
            }}
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-autocomplete
                :class="getValidationClass('Nazwa_wybor_budynku')"
                v-model="form.Nazwa_wybor_budynku"
                :md-options="zapytaniaBudynki"
                :disabled="sending || czyJestWTrybieEdycji"
              >
                <label>Wybór budynku</label>
                <span
                  class="md-error"
                  v-if="!$v.form.Nazwa_wybor_budynku.required"
                  >Pole jest wymagane</span
                >
              </md-autocomplete>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-autocomplete
                :class="getValidationClass('Nazwa_wybor_mediow')"
                v-model="form.Nazwa_wybor_mediow"
                :md-options="zapytaniaMedia"
                :disabled="sending || czyJestWTrybieEdycji"
              
              >
                <label>Wybór mediów dla budynku</label>
                <span
                  class="md-error"
                  v-if="!$v.form.Nazwa_wybor_mediow.required"
                  >Pole jest wymagane</span
                >
              </md-autocomplete>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('Id_jednostka')">
                <label for="Id_jednostka">Jednostka</label>
                <md-input
                :value="Id_jednostka"
                  type="text"
                  name="Id_jednostka"
                  id="Id_jednostka"           
                  :disabled="sending"
                  readonly
                />
                
              
              </md-field>
            </div>
          </div>


          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="MU2" :class="getValidationClass('Data_kosztow')">
                <label for="Data_kosztow">Data kosztu</label>
                <md-input
                  type="date"
                  name="Data_kosztow"
                  id="Data_kosztow"
                  autocomplete="Data_kosztow"
                  v-model="form.Data_kosztow"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Data_kosztow.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field class="MU2" :class="getValidationClass('Ilosc')">
                <label for="Ilosc">Ilość</label>
                <md-input
                  type="text"
                  id="Ilosc"
                  name="Ilosc"
                  autocomplete="Ilosc"
                  md-counter="30"
                  v-model="form.Ilosc"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Ilosc.required"
                  >Pole jest wymagane</span
                >
                <span class="md-error" v-else-if="!$v.form.Ilosc.maxlength"
                  >Wymagana ilość znaków od 1 do 30</span
                >
              </md-field>
            </div>

          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="MU1"
                :class="getValidationClass('Rodzaj_kosztow')"
              >
                <label for="Rodzaj_kosztow">Rodzaj mediów</label>
                <md-input
                  :value="Rodzaj_kosztow"
                  type="text"
                  name="Rodzaj_kosztow"
                  id="Rodzaj_kosztow"           
                  :disabled="sending"
                  readonly
                />
                
                
                
              </md-field>
            </div>
          </div>

        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

        <div style="text-align: center">
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            czyJestWTrybieEdycji
              ? "ZATWIERDŹ - EDYTOWANY WPIS"
              : "ZATWIERDŹ - UTWÓRZ WPIS"
          }}</md-button>
        </div>
      </md-card>
    </form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import minLength from "vuelidate/lib/validators/minLength";
import { api } from "../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_kosztow_maszyny",
  mixins: [validationMixin],
  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      Id: null,
      Nazwa_wybor_budynku: null,
      Nazwa_wybor_mediow: null,
      Id_jednostka: null,
      Data_kosztow: null,
      Ilosc: null,
      Rodzaj_kosztow: null,
    },
    IdEdytowanegoElementu: null,
    userSaved: false,
    sending: false,
    lastUser: null,
    Nazwy_budynkow: [],
    Media: [],
  }),

  async created() {
    try {
      const [
        odpowiedz_Nazwa_budynku,
        odpowiedz_Media,

      ] = await Promise.all([api.pobierzBudynki(), api.pobierzMedia()]);
      odpowiedz_Nazwa_budynku,
        (this.Nazwy_budynkow = odpowiedz_Nazwa_budynku.data.dane.map((el) => ({
          ...el,
          nazwa: `Id budynku: ${el.Id_budynku} ; Budynek: ${el.Nazwa_budynku} `,
        })));

      this.Media = odpowiedz_Media.data.dane.map((el) => ({
        ...el,
        nazwa2: `RODZAJ: ${el.Rodzaj_ogrzewania} ; JEDNOSTKA: ${el.Jednostka} `,
      }));


      if (this.obiektKonfiguracyjny.akcja === "edycja") {
        //edycja
        const odpowiedz = await api.pobieranieMediaBudynekEdycja(
          this.obiektKonfiguracyjny.Id
        );
        const {
          Id,
          Id_budynku,
          Id_media,
          Data_kosztow,
          Ilosc,
 
          Nazwa_zalacznika,
        } = odpowiedz.data.dane[0];
        this.IdEdytowanegoElementu = Id;
        this.form.Nazwa_wybor_budynku = this.Nazwy_budynkow.find(
          ({ Id }) => Id == Id_budynku
        ).nazwa;
        this.form.Nazwa_wybor_mediow = this.Media.find(
          ({ Id }) => Id == Id_media
        ).nazwa2;
        this.form.Data_kosztow = Data_kosztow;
        this.form.Ilosc = Ilosc;
        this.nazwaPliku = Nazwa_zalacznika;
      }
    } catch (error) {
      console.log(error);
    }
  },

  validations: {
    form: {
      Nazwa_wybor_budynku: {
        required,
      },
      Nazwa_wybor_mediow: {
        required,
      },
      Data_kosztow: {
        required,
      },
      Ilosc: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(30),
      },
    },
  },
  computed: {
    Id_budynku: function() {
      const { Nazwa_wybor_budynku } = this.form;
      const znalezionyElement = this.Nazwy_budynkow.find(
        ({ nazwa }) => nazwa === Nazwa_wybor_budynku
      );

      if (znalezionyElement) {
        return znalezionyElement.Id;
      }
      return null;
    },
    zapytaniaBudynki: function() {
      return this.Nazwy_budynkow.map((el) => el.nazwa);
    },

    Id_media: function() {
      const { Nazwa_wybor_mediow } = this.form;
      const znalezionyElement = this.Media.find(
        ({ nazwa2 }) => nazwa2 === Nazwa_wybor_mediow
      );

      if (znalezionyElement) {
        return znalezionyElement.Id;
      }
      return null;
    },
    zapytaniaMedia: function() {
      return this.Media.map((el) => el.nazwa2);
    },


    czyJestWTrybieEdycji: function() {
      return this.obiektKonfiguracyjny.akcja === "edycja";
    },
    Id_jednostka: function(){
      const {Id_media} = this
      return  Id_media ? this.Media.find(({Id}) => Id === Id_media).Jednostka : '';
    },
    Rodzaj_kosztow: function(){
      const {Id_media} = this
      return  Id_media ? this.Media.find(({Id}) => Id === Id_media).Rodzaj_ogrzewania : '';
    },
  },

  

  methods: {
    
 
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.Id = null;
      this.form.Id_media = null;
      this.form.Id_jednostka = null;
      this.form.Data_kosztow = null;
      this.form.Ilosc = null;
      this.form.Rodzaj_kosztow = null;

    },

    zapiszFormularz() {
      this.sending = true;

      if (this.czyJestWTrybieEdycji) {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Id_budynku", this.Id_budynku);
        dane.append("Id_media", this.Id_media);
        dane.append("Id_jednostka", this.Id_jednostka);
        dane.append("Data_kosztow", this.form.Data_kosztow);
        dane.append("Ilosc", this.form.Ilosc);
        dane.append("Rodzaj_kosztow", this.Rodzaj_kosztow);
   

        api
          .zapiszMediaBudynekUpdate(dane, this.IdEdytowanegoElementu)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const dane = new FormData();
        dane.append("Id_budynku", this.Id_budynku);
        dane.append("Id_media", this.Id_media);
        dane.append("Id_jednostka", this.Id_jednostka);
        dane.append("Data_kosztow", this.form.Data_kosztow);
        dane.append("Ilosc", this.form.Ilosc);
        dane.append("Rodzaj_kosztow", this.Rodzaj_kosztow);


        api
          .zapiszMediaBudynek(dane)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    naszWalidacjaFormularza() {
      if (this.czyJestWTrybieEdycji) {
        return (
          !this.$v.form.Nazwa_wybor_budynku.$invalid &&
          !this.$v.form.Nazwa_wybor_mediow.$invalid &&
          !this.$v.form.Data_kosztow.$invalid &&
          !this.$v.form.Ilosc.$invalid 
        );
      } else {
        return !this.$v.$invalid 
      }
    },

    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>

<style>
.Form_BUD_koszty .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.Form_BUD_koszty .md-layout .md-gutter {
  min-width: 55vw !important; /* szerokość modala*/
  max-width: 100% !important;
  min-height: 7vh; /* wysokość pomiędzy mierszami w modalu*/
}

.md-layout {
  padding-right: 0px !important;
}

.Form_BUD_koszty .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}
.Form_BUD_koszty .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  margin: 10px 24px !important;
  padding-top: 35px !important;
}

.Form_BUD_koszty .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}
.Form_BUD_koszty {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
  overflow: auto !important;
}

.Form_BUD_koszty .md-primary {
  border: 1px solid !important;

  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}

.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important;
  /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}

.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}

.Form_BUD_koszty .md-list-item-text {
  font-size: 1.7vmin !important;
}

.Form_BUD_koszty .md-card-actions .md-button {
  margin-right: 50px !important;
}
</style>
