<template>
  <div class="Form_PRACZIBHP">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI ZAPOZNANIA PRACOWNIKA Z INSTRUKCJĄ BHP"
                : "FORMULARZ DODAWANIA ZAPOZNANIA PRACOWNIKA Z INSTRUKCJĄ BHP"
            }}
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-autocomplete
                :class="getValidationClass('Nazwa_wybor_pracownika')"
                v-model="form.Nazwa_wybor_pracownika"
                :md-options="zapytaniaPracownicy"
                :disabled="sending || czyJestWTrybieEdycji"
              >
                <label>Wybór pracownika</label>
                <span
                  class="md-error"
                  v-if="!$v.form.Nazwa_wybor_pracownika.required"
                  >Pole jest wymagane</span
                >
              </md-autocomplete>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-autocomplete
                :class="getValidationClass('Nazwa_wybor_instrukcji')"
                v-model="form.Nazwa_wybor_instrukcji"
                :md-options="zapytaniaInstrukcjaOperacyjna"
                :disabled="sending || czyJestWTrybieEdycji"
              >
                <label>Wybór instrukcji BHP</label>
                <span
                  class="md-error"
                  v-if="!$v.form.Nazwa_wybor_instrukcji.required"
                  >Pole jest wymagane</span
                >
              </md-autocomplete>
            </div>
          </div>
          

          <div class="md-layout md-gutter">
           
              <div class="md-layout-item md-small-size-100"
            >
              <md-field class="M5" :class="getValidationClass('Data_zapoznania_IBHP')">
                <label for="Data_zapoznania_IBHP">Data zapoznania pracownika z instrukcją</label>

                <md-input
                  type="date"
                  name="Data_zapoznania_IBHP"
                  id="Data_zapoznania_IBHP"
                  autocomplete="Data_zapoznania_IBHP"
                  v-model="form.Data_zapoznania_IBHP"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Data_zapoznania_IBHP.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

                    <div class="md-layout md-gutter">
            <md-field class="MP3" :class="plikZwalidowany ? '' : 'md-invalid'">
              <label>Wstaw plik "max-2Mb"
                <span v-if="nazwaPliku">
                  - obecnie wstawiony plik to {{ nazwaPliku }}</span
                ></label
              >
              <md-file
                v-model="form.plik_PDF"
                @md-change="zapamietajPlik"
                accept="application/pdf"
              />
              <span class="md-error" v-if="!plikZwalidowany"
                >Plik moze miec max 2MB</span
              >
            </md-field>
          </div>

        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

        <div style="text-align: center">
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            czyJestWTrybieEdycji
              ? "ZATWIERDŹ - EDYTOWANY WPIS"
              : "ZATWIERDŹ - UTWÓRZ WPIS"
          }}</md-button>
        </div>
      </md-card>
    </form>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { api } from "../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_maszyny",

  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      Nazwa_wybor_pracownika: null,
      Nazwa_wybor_instrukcji: null,
      Data_zapoznania_IBHP: null,
      plik_PDF: null,
      plik_dane: null,
    },
    IdEdytowanegoElementu: null,
    userSaved: false,
    nazwaPliku: null,
    sending: false,
    lastUser: null,
    Pracownicy: [],
    InstrukcjeIO: [],
  }),

async created() {
    try {
      const [
        odpowiedz_Pracownik,
        odpowiedz_Instrukcje

      ] = await Promise.all([
        api.pobierzPracownika(),
        api.pobierzIBHP(),
       
      ]);
      odpowiedz_Pracownik,
        (this.Pracownicy = odpowiedz_Pracownik.data.dane.map((el) => ({
          ...el,
          nazwa: `${el.Nazwisko} ${el.Imie1} - NIP: ${el.NIP} ; PESEL: ${el.Pesel}`,
        })));

        odpowiedz_Instrukcje,
        (this.InstrukcjeIO = odpowiedz_Instrukcje.data.dane.map((el) => ({
          ...el,
          nazwa1: `Nr instrukcji: ${el.Nr_dokumentu} - Nazwa instrukcji: ${el.Nazwa_dokumentu}`,
        })));


      if (this.obiektKonfiguracyjny.akcja === "edycja") {
        //edycja
        const odpowiedz = await api.pobieranieZapoznanieIBHPPracownicyEdycja(
          this.obiektKonfiguracyjny.Id
        );
          const {
            Id,
            Id_pracownika,
            Id_instrukcja,
            Data_zapoznania_IBHP,
            Nazwa_zalacznika,

          } = odpowiedz.data.dane[0];
          this.IdEdytowanegoElementu = Id;
          this.form.Nazwa_wybor_pracownika = this.Pracownicy.find(
          ({ Id }) => Id == Id_pracownika
          ).nazwa;
          this.form.Nazwa_wybor_instrukcji = this.InstrukcjeIO.find(
          ({ Id }) => Id == Id_instrukcja
          ).nazwa1;
          this.form.Data_zapoznania_IBHP = Data_zapoznania_IBHP;
          this.nazwaPliku = Nazwa_zalacznika;
     }
    } catch (error) {
      console.log(error);
    }
  },

  validations: {
    form: {
      Nazwa_wybor_pracownika: {
        required,
      },
      Nazwa_wybor_instrukcji: {
        required,
      },
      Data_zapoznania_IBHP: {
        required,
      },
    },
  },
  computed: {
        Id_pracownika: function() {
      const { Nazwa_wybor_pracownika } = this.form;
      const znalezionyElement = this.Pracownicy.find(
        ({ nazwa }) => nazwa === Nazwa_wybor_pracownika
      );

      if (znalezionyElement) {
        return znalezionyElement.Id;
      }
      return null;
    },
    zapytaniaPracownicy: function() {
      return this.Pracownicy.map((el) => el.nazwa);
    },
    Id_instrukcja: function() {
      const { Nazwa_wybor_instrukcji } = this.form;
      const znalezionyElement = this.InstrukcjeIO.find(
        ({ nazwa1 }) => nazwa1 === Nazwa_wybor_instrukcji
      );

      if (znalezionyElement) {
        return znalezionyElement.Id;
      }
      return null;
    },
    zapytaniaInstrukcjaOperacyjna: function() {
      return this.InstrukcjeIO.map((el) => el.nazwa1);
    },
    plikZwalidowany: function () {
      return this.form.plik_dane
        ? this.form.plik_dane.size < 2 * 1024 * 1024
        : true;
    },

    czyJestWTrybieEdycji: function () {
      return this.obiektKonfiguracyjny.akcja === "edycja";
    },
  },

  methods: {
    zapamietajPlik(pliki) {
      if (pliki[0]) {
        this.form.plik_dane = pliki[0];
      }
    },

    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.Id_pracownika = null;
      this.form.Id_instrukcja = null;
      this.form.Data_zapoznania_IBHP = null;
      this.form.plik_PDF = null;
      this.form.plik_dane = null;
      this.nazwaPliku = null
    },

    zapiszFormularz() {
      this.sending = true;

      if (this.czyJestWTrybieEdycji) {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Id_pracownika", this.Id_pracownika);
        dane.append("Id_instrukcja", this.Id_instrukcja);
        dane.append("Data_zapoznania_IBHP", this.form.Data_zapoznania_IBHP);
        dane.append("plik_PDF", this.form.plik_dane);

        api
          .zapiszZapoznanieIBHPPracownicyUpdate(dane, this.IdEdytowanegoElementu)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Id_pracownika", this.Id_pracownika);
        dane.append("Id_instrukcja", this.Id_instrukcja);
        dane.append("Data_zapoznania_IBHP", this.form.Data_zapoznania_IBHP);
        dane.append("plik_PDF", this.form.plik_dane);

        api
          .zapiszZapoznanieIBHPPracownicy(dane)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    naszWalidacjaFormularza() {
      if (this.czyJestWTrybieEdycji) {
        return !this.$v.form.Nazwa_wybor_pracownika.$invalid &&
          !this.$v.form.Nazwa_wybor_instrukcji.$invalid &&
          !this.$v.form.Data_zapoznania_IBHP.$invalid &&
          this.plikZwalidowany
      } else {
        return !this.$v.$invalid && this.plikZwalidowany;
      }
    },

    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>


<style>
.Form_PRACZIBHP .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.Form_PRACZIBHP .md-layout .md-gutter {
  min-width: 55vw!important; /* szerokość modala*/
  max-width: 100%!important;
  min-height: 7vh; /* wysokość pomiędzy mierszami w modalu*/
}
.md-layout {
  padding-right: 0px !important;
}
.Form_PRACZIBHP .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}

.Form_PRACZIBHP .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  margin: 10px 24px !important;
  padding-top: 35px !important;
}
.Form_PRACZIBHP .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}


.Form_PRACZIBHP {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
  overflow: auto;
}
.Form_PRACZIBHP .md-primary {
  border: 1px solid !important;

  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}
.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important; /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}

.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}
.md-list-item-text {
  font-size: 1.7vmin !important;
}
.Form_PRACZIBHP .md-card-actions .md-button {
  margin-right: 4vw !important;
}
.Form_PRACZIBHP .md-field.md-has-textarea:not(.md-autogrow) label {
  top: 16px;
  left: 2px;
}
</style>