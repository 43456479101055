<template>
  <div>
  <TabelaSamochodyOsoboweKoszty />
  </div>
</template>

<script>
import TabelaSamochodyOsoboweKoszty from '../../komponenty/strona-tabela-komponenty/WST_samochody_osobowe/tabela_samochody_osobowe_koszty.vue'


export default {
  name: 'StronaTabelaSamochodyOsoboweKoszty',
  components: {
    TabelaSamochodyOsoboweKoszty,
  }

}
</script>
