<template>
  <div class="Form_sam_osobowe">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI SAMOCHODY CIĘŻAROWE/PRZYCZEPY/NACZEPY"
                : "FORMULARZ DODAWANIA SAMOCHODY CIĘŻAROWE/PRZYCZEPY/NACZEPY"
            }}
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-autocomplete
                :class="getValidationClass('Nazwa_wybor_pojazdu')"
                v-model="form.Nazwa_wybor_pojazdu"
                :md-options="zapytaniaPojazd"
                :disabled="sending || czyJestWTrybieEdycji"
              >
                <label>Wybór rodzaju pojazdu</label>
                <span
                  class="md-error"
                  v-if="!$v.form.Nazwa_wybor_pojazdu.required"
                  >Pole jest wymagane</span
                >
              </md-autocomplete>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M1"
                :class="getValidationClass('Nr_rejestracyjny')"
              >
                <label for="Nr_rejestracyjny">Nr rejestracyjny</label>
                <!--- edycja 7 linia-->
                <md-input
                  type="text"
                  name="Nr_rejestracyjny"
                  id="Nr_rejestracyjny"
                  autocomplete="Nr_rejestracyjny"
                  md-counter="10"
                  v-model="form.Nr_rejestracyjny"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Nr_rejestracyjny.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Nr_rejestracyjny.maxlength"
                  >Wymagana ilość znaków od 1 do 10</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field class="M2" :class="getValidationClass('Nazwa_maszyny')">
                <label for="Nazwa_maszyny">Marka samochodu / Model</label>
                <md-input
                  type="text"
                  name="Nazwa_maszyny"
                  id="Nazwa_maszyny"
                  
                  md-counter="100"
                  v-model="form.Nazwa_maszyny"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Nazwa_maszyny.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Nazwa_maszyny.maxlength"
                  >Wymagana ilość znaków od 1 do 100</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M4" :class="getValidationClass('Numer_VIN')">
                <label for="Numer_VIN">Numer VIN</label>
                <md-input
                  type="text"
                  name="Numer_VIN"
                  id="Numer_VIN"
                  
                  md-counter="50"
                  v-model="form.Numer_VIN"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Numer_VIN.required"
                  >Pole jest wymagane</span
                >
                <span class="md-error" v-else-if="!$v.form.Numer_VIN.maxlength"
                  >Wymagana ilość znaków od 1 do 50</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field class="M4" :class="getValidationClass('Rok_produkcji')">
                <label for="Rok_produkcji">Rok produkcji</label>
                <md-input
                  type="text"
                  name="Rok_produkcji"
                  id="Rok_produkcji"
                  
                  md-counter="4"
                  v-model="form.Rok_produkcji"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Rok_produkcji.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Rok_produkcji.maxlength"
                  >Wymagana ilość znaków 4</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Rok_produkcji.minlength"
                  >Wymagana ilość znaków 4</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M4"
                :class="getValidationClass('Osoba_odpowiedzialna')"
              >
                <label for="Osoba_odpowiedzialna">Osoba odpowiedzialna</label>
                <md-input
                  type="text"
                  name="Osoba_odpowiedzialna"
                  id="Osoba_odpowiedzialna"
                  
                  md-counter="50"
                  v-model="form.Osoba_odpowiedzialna"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Osoba_odpowiedzialna.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Osoba_odpowiedzialna.maxlength"
                  >Wymagana ilość znaków od 1 do 50</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('Status')">
                <label for="Status">Status samochodu</label>

                <md-select
                  v-model="form.Status"
                  :disabled="sending"
                  name="Status"
                  id="Status"
                  autocomplete="Status"
                  md-dense
                >
                  <md-option
                    v-for="(status, index) in Status"
                    :key="index"
                    :value="status.Id"
                    >{{ status.Status_maszyny }}</md-option
                  >
                </md-select>
                <span class="md-error" v-if="!$v.form.Status.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div
              v-if="form.Id_pojazdu !== '4' && form.Id_pojazdu !== '5'"
              class="md-layout-item md-small-size-100"
            >
              <md-field :class="getValidationClass('Id_paliwo')">
                <label for="Id_paliwo">Rodzaj paliwa</label>

                <md-select
                  v-model="form.Id_paliwo"
                  :disabled="sending"
                  name="Id_paliwo"
                  id="Id_paliwo"
                  autocomplete="Id_paliwo"
                  md-dense
                >
                  <md-option
                    v-for="(paliwo, index) in Paliwa"
                    :key="index"
                    :value="paliwo.Id"
                    >{{ paliwo.Rodzaj }}</md-option
                  >
                </md-select>
                <span class="md-error" v-if="!$v.form.Id_paliwo.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div
              v-if="form.Id_pojazdu !== '4' && form.Id_pojazdu !== '5'"
              class="md-layout-item md-small-size-100"
            >
              <md-field :class="getValidationClass('Id_norma_emisji')">
                <label for="Id_norma_emisji">Norma emisji</label>

                <md-select
                  v-model="form.Id_norma_emisji"
                  :disabled="sending"
                  name="Id_norma_emisji"
                  id="Id_norma_emisji"
                  autocomplete="Id_norma_emisji"
                  md-dense
                >
                  <md-option
                    v-for="(norma, index) in Normy"
                    :key="index"
                    :value="norma.Id"
                    >{{ norma.Norma_emisji }}</md-option
                  >
                </md-select>
                <span class="md-error" v-if="!$v.form.Id_norma_emisji.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

          <div v-if="!czyJestWTrybieEdycji" class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('Id_czy_jest_tacho')">
                <label for="Id_czy_jest_tacho"
                  >Czy pojazd wyposażony jest w tachograf</label
                >

                <md-select
                  v-model="form.Id_czy_jest_tacho"
                  :disabled="sending"
                  name="Id_czy_jest_tacho"
                  id="Id_czy_jest_tacho"
                  autocomplete="Id_czy_jest_tacho"
                  md-dense
                >
                  <md-option
                    v-for="(tacho, index) in Tachografy"
                    :key="index"
                    :value="tacho.Id"
                    >{{ tacho.Wybor }}</md-option
                  >
                </md-select>
                <span
                  class="md-error"
                  v-if="!$v.form.Id_czy_jest_tacho.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

          <div
            v-if="
              !czyJestWTrybieEdycji &&
                form.Id_czy_jest_tacho !== '2' &&
                form.Id_czy_jest_tacho !== null
            "
            class="md-layout md-gutter"
          >
            <div class="md-layout-item md-small-size-100">
              <md-field class="M4" :class="getValidationClass('Nr_tachografu')">
                <label for="Nr_tachografu">Nr tachografu</label>
                <md-input
                  type="text"
                  name="Nr_tachografu"
                  id="Nr_tachografu"
                  
                  md-counter="50"
                  v-model="form.Nr_tachografu"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Nr_tachografu.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Nr_tachografu.maxlength"
                  >Wymagana ilość znaków od 1 do 50</span
                >
              </md-field>
            </div>
          </div>

          <div
            v-if="
              !czyJestWTrybieEdycji &&
                form.Id_czy_jest_tacho !== '2' &&
                form.Id_czy_jest_tacho !== null
            "
            class="md-layout md-gutter"
          >
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M5"
                :class="getValidationClass('Data_legalizacji')"
              >
                <label for="Data_legalizacji"
                  >Data legalizacji tachografu</label
                >
                <md-input
                  type="date"
                  name="Data_legalizacji"
                  id="Data_legalizacji"
                  autocomplete="Data_legalizacji"
                  v-model="form.Data_legalizacji"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Data_legalizacji.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div
              v-if="
                !czyJestWTrybieEdycji &&
                  form.Id_czy_jest_tacho !== '2' &&
                  form.Id_czy_jest_tacho !== null
              "
              class="md-layout-item md-small-size-100"
            >
              <md-field
                class="M5"
                :class="getValidationClass('Data_nastepnej_legalizacji')"
              >
                <label for="Data_nastepnej_legalizacji"
                  >Data nast.legalizaji tachografu</label
                >
                <md-input
                  type="date"
                  id="Data_nastepnej_legalizacji"
                  name="Data_nastepnej_legalizacji"
                  autocomplete="Data_nastepnej_legalizacji"
                  v-model="form.Data_nastepnej_legalizacji"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Data_nastepnej_legalizacji.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

          <div
            v-if="
              !czyJestWTrybieEdycji &&
                form.Id_czy_jest_tacho !== '2' &&
                form.Id_czy_jest_tacho !== null
            "
            class="md-layout md-gutter"
          >
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M5"
                :class="getValidationClass('Legalizacje_wykonal')"
              >
                <label for="Legalizacje_wykonal">Legalizacje wykonał</label>
                <md-input
                  name="Legalizacje_wykonal"
                  id="Legalizacje_wykonal"
                  autocomplete="Legalizacje_wykonal"
                  md-counter="100"
                  v-model="form.Legalizacje_wykonal"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Legalizacje_wykonal.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Legalizacje_wykonal.maxlength"
                  >Wymagana ilość znaków od 1 do 100</span
                >
              </md-field>
            </div>
          </div>

          <!-- ________________________ -->

          <div v-if="!czyJestWTrybieEdycji" class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('Id_czy_jest_chlodnia')">
                <label for="Id_czy_jest_chlodnia"
                  >Czy pojazd wyposażony jest w chłodnię</label
                >

                <md-select
                  v-model="form.Id_czy_jest_chlodnia"
                  :disabled="sending"
                  name="Id_czy_jest_chlodnia"
                  id="Id_czy_jest_chlodnia"
                  autocomplete="Id_czy_jest_chlodnia"
                  md-dense
                >
                  <md-option
                    v-for="(chlodnia, index) in Chlodnie"
                    :key="index"
                    :value="chlodnia.Id"
                    >{{ chlodnia.Wybor }}</md-option
                  >
                </md-select>
                <span
                  class="md-error"
                  v-if="!$v.form.Id_czy_jest_chlodnia.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

          <div
            v-if="
              !czyJestWTrybieEdycji &&
                form.Id_czy_jest_chlodnia !== '2' &&
                form.Id_czy_jest_chlodnia !== null
            "
            class="md-layout md-gutter"
          >
            <div class="md-layout-item md-small-size-100">
              <md-field class="M4" :class="getValidationClass('Nr_chlodni')">
                <label for="Nr_chlodni">Nr chlodni</label>
                <md-input
                  type="text"
                  name="Nr_chlodni"
                  id="Nr_chlodni"
                  
                  md-counter="50"
                  v-model="form.Nr_chlodni"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Nr_chlodni.required"
                  >Pole jest wymagane</span
                >
                <span class="md-error" v-else-if="!$v.form.Nr_chlodni.maxlength"
                  >Wymagana ilość znaków od 1 do 50</span
                >
              </md-field>
            </div>
          </div>

          <div
            v-if="
              !czyJestWTrybieEdycji &&
                form.Id_czy_jest_chlodnia !== '2' &&
                form.Id_czy_jest_chlodnia !== null
            "
            class="md-layout md-gutter"
          >
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M5"
                :class="getValidationClass('Data_mapowania')"
              >
                <label for="Data_mapowania">Data mapowania chłodni</label>
                <md-input
                  type="date"
                  name="Data_mapowania"
                  id="Data_mapowania"
                  autocomplete="Data_mapowania"
                  v-model="form.Data_mapowania"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Data_mapowania.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div
              v-if="
                !czyJestWTrybieEdycji &&
                  form.Id_czy_jest_chlodnia !== '2' &&
                  form.Id_czy_jest_chlodnia !== null
              "
              class="md-layout-item md-small-size-100"
            >
              <md-field
                class="M5"
                :class="getValidationClass('Data_nastepnego_mapowania')"
              >
                <label for="Data_nastepnego_mapowania"
                  >Data nast.mapowania chłodni</label
                >
                <md-input
                  type="date"
                  id="Data_nastepnego_mapowania"
                  name="Data_nastepnego_mapowania"
                  autocomplete="Data_nastepnego_mapowania"
                  v-model="form.Data_nastepnego_mapowania"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Data_nastepnego_mapowania.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

          <div
            v-if="
              !czyJestWTrybieEdycji &&
                form.Id_czy_jest_chlodnia !== '2' &&
                form.Id_czy_jest_chlodnia !== null
            "
            class="md-layout md-gutter"
          >
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M5"
                :class="getValidationClass('Mapowanie_wykonal')"
              >
                <label for="Mapowanie_wykonal">Mapowanie wykonał</label>
                <md-input
                  name="Mapowanie_wykonal"
                  id="Mapowanie_wykonal"
                  autocomplete="Mapowanie_wykonal"
                  md-counter="100"
                  v-model="form.Mapowanie_wykonal"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Mapowanie_wykonal.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Mapowanie_wykonal.maxlength"
                  >Wymagana ilość znaków od 1 do 100</span
                >
              </md-field>
            </div>
          </div>

          <!-- _____________________ -->

                   <div
            v-if="
              !czyJestWTrybieEdycji &&
                form.Id_czy_jest_chlodnia !== '2' &&
                form.Id_czy_jest_chlodnia !== null
            "
            class="md-layout md-gutter"
          >
            <div class="md-layout-item md-small-size-100">
              <md-field class="M4" :class="getValidationClass('Nr_termografu')">
                <label for="Nr_termografu">Nr termografu</label>
                <md-input
                  type="text"
                  name="Nr_termografu"
                  id="Nr_termografu"
                  
                  md-counter="50"
                  v-model="form.Nr_termografu"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Nr_termografu.required"
                  >Pole jest wymagane</span
                >
                <span class="md-error" v-else-if="!$v.form.Nr_termografu.maxlength"
                  >Wymagana ilość znaków od 1 do 50</span
                >
              </md-field>
            </div>
          </div>

          <div
            v-if="
              !czyJestWTrybieEdycji &&
                form.Id_czy_jest_chlodnia !== '2' &&
                form.Id_czy_jest_chlodnia !== null
            "
            class="md-layout md-gutter"
          >
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M5"
                :class="getValidationClass('Data_kalibracji')"
              >
                <label for="Data_kalibracji">Data wzorcowania</label>
                <md-input
                  type="date"
                  name="Data_kalibracji"
                  id="Data_kalibracji"
                  autocomplete="Data_kalibracji"
                  v-model="form.Data_kalibracji"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Data_kalibracji.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div
              v-if="
                !czyJestWTrybieEdycji &&
                  form.Id_czy_jest_chlodnia !== '2' &&
                  form.Id_czy_jest_chlodnia !== null
              "
              class="md-layout-item md-small-size-100"
            >
              <md-field
                class="M5"
                :class="getValidationClass('Data_nastepnej_kalibracji')"
              >
                <label for="Data_nastepnej_kalibracji"
                  >Data nast.wzorcowania</label
                >
                <md-input
                  type="date"
                  id="Data_nastepnej_kalibracji"
                  name="Data_nastepnej_kalibracji"
                  autocomplete="Data_nastepnej_kalibracji"
                  v-model="form.Data_nastepnej_kalibracji"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Data_nastepnej_kalibracji.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

          <div
            v-if="
              !czyJestWTrybieEdycji &&
                form.Id_czy_jest_chlodnia !== '2' &&
                form.Id_czy_jest_chlodnia !== null
            "
            class="md-layout md-gutter"
          >
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M5"
                :class="getValidationClass('Kalibracje_wykonal')"
              >
                <label for="Kalibracje_wykonal">Wzorcowanie wykonał</label>
                <md-input
                  name="Kalibracje_wykonal"
                  id="Kalibracje_wykonal"
                  autocomplete="Kalibracje_wykonal"
                  md-counter="100"
                  v-model="form.Kalibracje_wykonal"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Kalibracje_wykonal.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Kalibracje_wykonal.maxlength"
                  >Wymagana ilość znaków od 1 do 100</span
                >
              </md-field>
            </div>
          </div> 
<!-- --------------- -->
          <div v-if="!czyJestWTrybieEdycji" class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M5"
                :class="getValidationClass('Data_przegladu')"
              >
                <label for="Data_przegladu">Data przeglądu</label>
                <md-input
                  type="date"
                  name="Data_przegladu"
                  id="Data_przegladu"
                  autocomplete="Data_przegladu"
                  v-model="form.Data_przegladu"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Data_przegladu.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div
              v-if="!czyJestWTrybieEdycji"
              class="md-layout-item md-small-size-100"
            >
              <md-field
                class="M5"
                :class="getValidationClass('Data_nastepnego_przegladu')"
              >
                <label for="Data_nastepnego_przegladu"
                  >Data nast.przeglądu</label
                >
                <md-input
                  type="date"
                  id="Data_nastepnego_przegladu"
                  name="Data_nastepnego_przegladu"
                  autocomplete="Data_nastepnego_przegladu"
                  v-model="form.Data_nastepnego_przegladu"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Data_nastepnego_przegladu.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

          <div v-if="!czyJestWTrybieEdycji" class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M5"
                :class="getValidationClass('Przeglad_wykonal')"
              >
                <label for="Przeglad_wykonal">Przegląd wykonał</label>
                <md-input
                  name="Przeglad_wykonal"
                  id="Przeglad_wykonal"
                  autocomplete="Przeglad_wykonal"
                  md-counter="100"
                  v-model="form.Przeglad_wykonal"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Przeglad_wykonal.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Przeglad_wykonal.maxlength"
                  >Wymagana ilość znaków od 1 do 100</span
                >
              </md-field>
            </div>
          </div>

          <div v-if="!czyJestWTrybieEdycji" class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M5"
                :class="getValidationClass('Data_ubezpieczenia')"
              >
                <label for="Data_ubezpieczenia">Data ubezpieczenia</label>
                <md-input
                  type="date"
                  name="Data_ubezpieczenia"
                  id="Data_ubezpieczenia"
                  autocomplete="Data_ubezpieczenia"
                  v-model="form.Data_ubezpieczenia"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Data_ubezpieczenia.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div
              v-if="!czyJestWTrybieEdycji"
              class="md-layout-item md-small-size-100"
            >
              <md-field
                class="M5"
                :class="getValidationClass('Data_nastepnego_ubezpieczenia')"
              >
                <label for="Data_nastepnego_ubezpieczenia"
                  >Data nast.ubezpieczenia</label
                >
                <md-input
                  type="date"
                  id="Data_nastepnego_ubezpieczenia"
                  name="Data_nastepnego_ubezpieczenia"
                  autocomplete="Data_nastepnego_ubezpieczenia"
                  v-model="form.Data_nastepnego_ubezpieczenia"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Data_nastepnego_ubezpieczenia.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div
              v-if="!czyJestWTrybieEdycji"
              class="md-layout-item md-small-size-100"
            >
              <md-field class="M5" :class="getValidationClass('Nr_polisy')">
                <label for="Nr_polisy">Nr polisy</label>
                <md-input
                  name="Nr_polisy"
                  id="Nr_polisy"
                  autocomplete="Nr_polisy"
                  md-counter="50"
                  v-model="form.Nr_polisy"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Nr_polisy.required"
                  >Pole jest wymagane</span
                >
                <span class="md-error" v-else-if="!$v.form.Nr_polisy.maxlength"
                  >Wymagana ilość znaków od 1 do 50</span
                >
              </md-field>
            </div>
          </div>

          <div v-if="!czyJestWTrybieEdycji" class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M5"
                :class="getValidationClass('Ubezpieczyciel')"
              >
                <label for="Ubezpieczyciel">Ubezpieczyciel</label>
                <md-input
                  name="Ubezpieczyciel"
                  id="Ubezpieczyciel"
                  autocomplete="Ubezpieczyciel"
                  md-counter="100"
                  v-model="form.Ubezpieczyciel"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Ubezpieczyciel.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Ubezpieczyciel.maxlength"
                  >Wymagana ilość znaków od 1 do 100</span
                >
              </md-field>
            </div>
          </div>

          <div
            v-if="
              !czyJestWTrybieEdycji &&
                form.Id_pojazdu !== '4' &&
                form.Id_pojazdu !== '5'
            "
            class="md-layout md-gutter"
          >
            <md-field class="M3" :class="plikZwalidowany2 ? '' : 'md-invalid'">
              <label
                >Wstaw plik dotyczący legalizacji tachografu "max-2Mb"</label
              >
              <md-file
                v-model="form.plik_PDF_2"
                @md-change="zapamietajPlik_2"
              />
              <span class="md-error" v-if="!plikZwalidowany2"
                >Plik moze miec max 2MB</span
              >
            </md-field>
          </div>

          <div v-if="!czyJestWTrybieEdycji" class="md-layout md-gutter">
            <md-field class="M3" :class="plikZwalidowany ? '' : 'md-invalid'">
              <label
                >Wstaw plik dotyczący przeglądu technicznego "max-2Mb"</label
              >
              <md-file v-model="form.plik_PDF" @md-change="zapamietajPlik" />
              <span class="md-error" v-if="!plikZwalidowany"
                >Plik moze miec max 2MB</span
              >
            </md-field>
          </div>

          <div v-if="!czyJestWTrybieEdycji" class="md-layout md-gutter">
            <md-field class="M3" :class="plikZwalidowany1 ? '' : 'md-invalid'">
              <label>Wstaw plik dotyczący ubezpieczenia "max-2Mb"</label>
              <md-file
                v-model="form.plik_PDF_1"
                @md-change="zapamietajPlik_1"
              />
              <span class="md-error" v-if="!plikZwalidowany1"
                >Plik moze miec max 2MB</span
              >
            </md-field>
          </div>

           <div v-if="!czyJestWTrybieEdycji" class="md-layout md-gutter">
            <md-field class="M3" :class="plikZwalidowany3 ? '' : 'md-invalid'">
              <label>Wstaw plik dotyczący mapowania "max-2Mb"</label>
              <md-file
                v-model="form.plik_PDF_3"
                @md-change="zapamietajPlik_3"
              />
              <span class="md-error" v-if="!plikZwalidowany3"
                >Plik moze miec max 2MB</span
              >
            </md-field>
          </div>

            <div v-if="!czyJestWTrybieEdycji" class="md-layout md-gutter">
            <md-field class="M3" :class="plikZwalidowany4 ? '' : 'md-invalid'">
              <label>Wstaw plik dotyczący wzorcowania termografu "max-2Mb"</label>
              <md-file
                v-model="form.plik_PDF_4"
                @md-change="zapamietajPlik_4"
              />
              <span class="md-error" v-if="!plikZwalidowany4"
                >Plik moze miec max 2MB</span
              >
            </md-field>
          </div>
        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

        <div style="text-align: center">
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            czyJestWTrybieEdycji
              ? "ZATWIERDŹ - EDYTOWANY WPIS"
              : "ZATWIERDŹ - UTWÓRZ WPIS"
          }}</md-button>
        </div>
      </md-card>
    </form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import minLength from "vuelidate/lib/validators/minLength";
import { api } from "../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_maszyny",
  mixins: [validationMixin],
  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      Nr_rejestracyjny: null,
      Nazwa_wybor_pojazdu: null,
      Nazwa_maszyny: null,
      Numer_VIN: null,
      Osoba_odpowiedzialna: null,
      Rok_produkcji: null,
      Status: null,
      Id_paliwo: null,
      Id_norma_emisji: null,
      Id_czy_jest_tacho: null,
      Nr_tachografu: null,
      Data_legalizacji: null,
      Data_nastepnej_legalizacji: null,
      Legalizacje_wykonal: null,
      Id_czy_jest_chlodnia: null,
      Nr_chlodni: null,
      Data_mapowania: null,
      Data_nastepnego_mapowania: null,
      Mapowanie_wykonal: null,
       Nr_termografu: null,
      Data_kalibracji: null,
      Data_nastepnej_kalibracji: null,
      Kalibracje_wykonal: null,

      Data_przegladu: null,
      Data_nastepnego_przegladu: null,
      Data_ubezpieczenia: null,
      Data_nastepnego_ubezpieczenia: null,
      Przeglad_wykonal: null,
      Nr_polisy: null,
      Ubezpieczyciel: null,
      plik_PDF: null,
      plik_dane: null,
      plik_PDF_1: null,
      plik_dane_1: null,
      plik_PDF_2: null,
      plik_dane_2: null,
      plik_PDF_3: null,
      plik_dane_3: null,
      plik_PDF_4: null,
      plik_dane_4: null,
    },
    IdEdytowanegoElementu: null,
    userSaved: false,
    sending: false,
    lastUser: null,
    Status: [],
    Tachografy: [],
    Chlodnie: [],
    Paliwa: [],
    Normy: [],
    RodzajePojazdow: [],
  }),

  async created() {
    try {
      const [
        odpowiedz_status,
        odpowiedz_paliwo,
        odpowiedz_emisja,
        odpowiedz_tacho,
        odpowiedz_chlodnia,
        odpowiedz_rodzaj_pojazdu,
      ] = await Promise.all([
        api.pobierzStatusSamochody(),
        api.pobierzRodzajPaliwa(),
        api.pobierzNormeEmisji(),
        api.pobierzWyborTacho(),
        api.pobierzWyborChlodnia(),
        api.pobierzRodzajPojazdu(),
      ]);
      odpowiedz_status,
        odpowiedz_paliwo,
        odpowiedz_emisja,
        (this.RodzajePojazdow = odpowiedz_rodzaj_pojazdu.data.dane.map(
          (el) => ({
            ...el,
            nazwa: `${el.Rodzaj_pojazdu}`,
          })
        ));

      this.Status = odpowiedz_status.data.dane;
      this.Paliwa = odpowiedz_paliwo.data.dane;
      this.Normy = odpowiedz_emisja.data.dane;
      this.Tachografy = odpowiedz_tacho.data.dane;
      this.Chlodnie = odpowiedz_chlodnia.data.dane;

      if (this.obiektKonfiguracyjny.akcja === "edycja") {
        //edycja

        const odpowiedz = await api.pobieranieSamochoduCiezarowegoEdycja(
          this.obiektKonfiguracyjny.Id
        );

        const {
          Id,
          Nr_rejestracyjny,
          Id_pojazdu,
          Nazwa_maszyny,
          Numer_VIN,
          Rok_produkcji,
          Osoba_odpowiedzialna,
          Status,
          Id_paliwo,
          Id_norma_emisji,
          Id_czy_jest_tacho,
          Nr_tachografu,
          Data_legalizacji,
          Data_nastepnej_legalizacji,
          Legalizacje_wykonal,
          Id_czy_jest_chlodnia,
          Nr_chlodni,
          Data_mapowania,
          Data_nastepnego_mapowania,
          Mapowanie_wykonal,
          Nr_termografu,
          Data_kalibracji,
          Data_nastepnej_kalibracji,
          Kalibracje_wykonal,
          Data_przegladu,
          Data_nastepnego_przegladu,
          Data_ubezpieczenia,
          Data_nastepnego_ubezpieczenia,
          Przeglad_wykonal,
          Nr_polisy,
          Ubezpieczyciel,
        } = odpowiedz.data.dane[0];
        this.IdEdytowanegoElementu = Id;
        this.form.Nr_rejestracyjny = Nr_rejestracyjny;
        this.form.Nazwa_wybor_pojazdu = this.RodzajePojazdow.find(
          ({ Id }) => Id == Id_pojazdu
        ).nazwa;
        this.form.Nazwa_maszyny = Nazwa_maszyny;
        this.form.Numer_VIN = Numer_VIN;
        this.form.Osoba_odpowiedzialna = Osoba_odpowiedzialna;
        this.form.Rok_produkcji = Rok_produkcji;
        this.form.Status = Status;
        this.form.Id_paliwo = Id_paliwo;
        this.form.Id_norma_emisji = Id_norma_emisji;
        this.form.Data_przegladu = Data_przegladu;
        this.form.Data_nastepnego_przegladu = Data_nastepnego_przegladu;
        this.form.Data_ubezpieczenia = Data_ubezpieczenia;
        this.form.Data_nastepnego_ubezpieczenia = Data_nastepnego_ubezpieczenia;
        this.form.Przeglad_wykonal = Przeglad_wykonal;
        this.form.Nr_polisy = Nr_polisy;
        this.form.Id_czy_jest_tacho = Id_czy_jest_tacho;
        this.form.Nr_tachografu = Nr_tachografu;
        this.form.Data_legalizacji = Data_legalizacji;
        this.form.Data_nastepnej_legalizacji = Data_nastepnej_legalizacji;
        this.form.Legalizacje_wykonal = Legalizacje_wykonal;
        this.form.Nr_chlodni = Nr_chlodni;
        this.form.Data_mapowania = Data_mapowania;
        this.form.Data_nastepnego_mapowania = Data_nastepnego_mapowania;
        this.form.Mapowanie_wykonal = Mapowanie_wykonal;
        this.form.Nr_termografu = Nr_termografu;
        this.form.Data_kalibracji = Data_kalibracji;
        this.form.Data_nastepnej_kalibracji = Data_nastepnej_kalibracji;
        this.form.Kalibracje_wykonal = Kalibracje_wykonal;
        this.form.Id_czy_jest_chlodnia = Id_czy_jest_chlodnia;
        this.form.Ubezpieczyciel = Ubezpieczyciel;
      }
    } catch (error) {
      console.log(error);
    }
  },
  validations: {
    form: {
      Nr_rejestracyjny: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(10),
      },
      Nazwa_wybor_pojazdu: {
        required,
      },
      Nazwa_maszyny: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(100),
      },
      Numer_VIN: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(50),
      },
      Status: {
        required,
      },
      Id_paliwo: {
        required: false,
      },
      Id_norma_emisji: {
        required: false,
      },
      Rok_produkcji: {
        required,
        minLenght: minLength(4),
        maxLength: maxLength(4),
      },
      Osoba_odpowiedzialna: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(50),
      },
      Id_czy_jest_tacho: {
        required,
      },
      Nr_tachografu: {
        required: false,
        maxLength: maxLength(50),
      },
      Data_legalizacji: {
        required: false,
      },
      Data_nastepnej_legalizacji: {
        required: false,
      },
      Legalizacje_wykonal: {
        required: false,
        maxLength: maxLength(100),
      },
      Id_czy_jest_chlodnia: {
        required,
      },
      Nr_chlodni: {
        required: false,
        maxLength: maxLength(50),
      },
      Data_mapowania: {
        required: false,
      },
      Data_nastepnego_mapowania: {
        required: false,
      },
      Mapowanie_wykonal: {
        required: false,
        maxLength: maxLength(100),
      },
      Nr_termografu: {
        required: false,
        maxLength: maxLength(50),
      },
      Data_kalibracji: {
        required: false,
      },
      Data_nastepnej_kalibracji: {
        required: false,
      },
      Kalibracje_wykonal: {
        required: false,
        maxLength: maxLength(100),
      },
      Data_przegladu: {
        required,
      },
      Data_nastepnego_przegladu: {
        required,
      },
      Data_ubezpieczenia: {
        required,
      },
      Data_nastepnego_ubezpieczenia: {
        required,
      },
      Przeglad_wykonal: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(100),
      },
      Nr_polisy: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(50),
      },
      Ubezpieczyciel: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(100),
      },
    },
  },
  computed: {
    Id_pojazdu: function() {
      const { Nazwa_wybor_pojazdu } = this.form;
      const znalezionyElement = this.RodzajePojazdow.find(
        ({ nazwa }) => nazwa === Nazwa_wybor_pojazdu
      );

      if (znalezionyElement) {
        return znalezionyElement.Id;
      }
      return null;
    },
    zapytaniaPojazd: function() {
      return this.RodzajePojazdow.map((el) => el.nazwa);
    },
    plikZwalidowany: function() {
      return this.form.plik_dane
        ? this.form.plik_dane.size < 2 * 1024 * 1024
        : true;
    },
    plikZwalidowany1: function() {
      return this.form.plik_dane_1
        ? this.form.plik_dane_1.size < 2 * 1024 * 1024
        : true;
    },
    plikZwalidowany2: function() {
      return this.form.plik_dane_2
        ? this.form.plik_dane_2.size < 2 * 1024 * 1024
        : true;
    },
     plikZwalidowany3: function() {
      return this.form.plik_dane_3
        ? this.form.plik_dane_3.size < 2 * 1024 * 1024
        : true;
    },
     plikZwalidowany4: function() {
      return this.form.plik_dane_4
        ? this.form.plik_dane_4.size < 2 * 1024 * 1024
        : true;
    },
    czyJestWTrybieEdycji: function() {
      return this.obiektKonfiguracyjny.akcja === "edycja";
    },
    Id_pojazdu_emisja: function() {
      const { Id_pojazdu } = this;
      return Id_pojazdu
        ? this.RodzajePojazdow.find(({ Id }) => Id === Id_pojazdu)
            .Id_pojazdu_emisja
        : "";
    },
  },

  methods: {
    zapamietajPlik(pliki) {
      if (pliki[0]) {
        this.form.plik_dane = pliki[0];
      }
    },
    zapamietajPlik_1(pliki_1) {
      if (pliki_1[0]) {
        this.form.plik_dane_1 = pliki_1[0];
      }
    },
    zapamietajPlik_2(pliki_2) {
      if (pliki_2[0]) {
        this.form.plik_dane_2 = pliki_2[0];
      }
    },
    zapamietajPlik_3(pliki_3) {
      if (pliki_3[0]) {
        this.form.plik_dane_3 = pliki_3[0];
      }
    },
    zapamietajPlik_4(pliki_4) {
      if (pliki_4[0]) {
        this.form.plik_dane_4 = pliki_4[0];
      }
    },
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.Nr_rejestracyjny = null;
      this.form.Nazwa_maszyny = null;
      this.form.Numer_VIN = null;
      this.form.Status = null;
      this.form.Id_paliwo = null;
      this.form.Id_norma_emisji = null;
      this.form.Rok_produkcji = null;
      this.form.Osoba_odpowiedzialna = null;
      this.form.Id_czy_jest_tacho = null;
      this.form.Nr_tachografu = null;
      this.form.Data_legalizacji = null;
      this.form.Data_nastepnej_legalizacji = null;
      this.form.Legalizacje_wykonal = null;
      this.form.Nr_chlodni = null;
      this.form.Data_mapowania = null;
      this.form.Data_nastepnego_mapowania = null;
      this.form.Mapowanie_wykonal = null;
      this.form.Nr_termografu= null;
      this.form.Data_kalibracji = null;
      this.form.Data_nastepnej_kalibracji = null;
      this.form.Kalibracje_wykonal = null;
      this.form.Id_czy_jest_chlodnia = null;
      this.form.Data_przegladu = null;
      this.form.Data_nastepnego_przegladu = null;
      this.form.Przeglad_wykonal = null;
      this.form.Data_ubezpieczenia = null;
      this.form.Data_nastepnego_ubezpieczenia = null;
      this.form.Nr_polisy = null;
      this.form.Ubezpieczyciel = null;
      this.form.plik_PDF = null;
      this.form.plik_dane = null;
      this.form.plik_PDF_1 = null;
      this.form.plik_dane_1 = null;
      this.form.plik_PDF_2 = null;
      this.form.plik_dane_2 = null;
      this.form.plik_PDF_3 = null;
      this.form.plik_dane_3 = null;
      this.form.plik_PDF_4 = null;
      this.form.plik_dane_4 = null;
    },

    zapiszFormularz() {
      this.sending = true;

      if (this.czyJestWTrybieEdycji) {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Nr_rejestracyjny", this.form.Nr_rejestracyjny);
        dane.append("Id_pojazdu", this.Id_pojazdu);
        dane.append("Nazwa_maszyny", this.form.Nazwa_maszyny);
        dane.append("Numer_VIN", this.form.Numer_VIN);
        dane.append("Status", this.form.Status);
        dane.append("Id_paliwo", this.form.Id_paliwo);
        dane.append("Id_norma_emisji", this.form.Id_norma_emisji);
        dane.append("Rok_produkcji", this.form.Rok_produkcji);
        dane.append("Osoba_odpowiedzialna", this.form.Osoba_odpowiedzialna);

        api
          .zapiszSamochoduCiezarowegoUpdate(dane, this.IdEdytowanegoElementu)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Nr_rejestracyjny", this.form.Nr_rejestracyjny);
        dane.append("Id_pojazdu", this.Id_pojazdu);
        dane.append("Nazwa_maszyny", this.form.Nazwa_maszyny);
        dane.append("Numer_VIN", this.form.Numer_VIN);
        dane.append("Status", this.form.Status);
        dane.append("Id_paliwo", this.form.Id_paliwo);
        dane.append("Id_norma_emisji", this.form.Id_norma_emisji);
        dane.append("Id_czy_jest_tacho", this.form.Id_czy_jest_tacho);
        dane.append("Nr_tachografu", this.form.Nr_tachografu);
        dane.append("Data_legalizacji", this.form.Data_legalizacji);
        dane.append(
          "Data_nastepnej_legalizacji",
          this.form.Data_nastepnej_legalizacji
        );
        dane.append("Legalizacje_wykonal", this.form.Legalizacje_wykonal);
        dane.append("Id_czy_jest_chlodnia", this.form.Id_czy_jest_chlodnia);
        dane.append("Nr_chlodni", this.form.Nr_chlodni);
        dane.append("Data_mapowania", this.form.Data_mapowania);
        dane.append(
          "Data_nastepnego_mapowania",
          this.form.Data_nastepnego_mapowania
        );
        dane.append("Mapowanie_wykonal", this.form.Mapowanie_wykonal);

        dane.append("Nr_termografu", this.form.Nr_termografu);
        dane.append("Data_kalibracji", this.form.Data_kalibracji);
        dane.append(
          "Data_nastepnej_kalibracji",
          this.form.Data_nastepnej_kalibracji
        );
        dane.append("Kalibracje_wykonal", this.form.Kalibracje_wykonal);
        dane.append("Rok_produkcji", this.form.Rok_produkcji);
        dane.append("Osoba_odpowiedzialna", this.form.Osoba_odpowiedzialna);
        dane.append("Data_przegladu", this.form.Data_przegladu);
        dane.append(
          "Data_nastepnego_przegladu",
          this.form.Data_nastepnego_przegladu
        );
        dane.append("Data_ubezpieczenia", this.form.Data_ubezpieczenia);
        dane.append(
          "Data_nastepnego_ubezpieczenia",
          this.form.Data_nastepnego_ubezpieczenia
        );
        dane.append("Przeglad_wykonal", this.form.Przeglad_wykonal);

        dane.append("Nr_polisy", this.form.Nr_polisy);
        dane.append("Ubezpieczyciel", this.form.Ubezpieczyciel);
        dane.append("plik_PDF", this.form.plik_dane);
        dane.append("plik_PDF_1", this.form.plik_dane_1);
        dane.append("plik_PDF_2", this.form.plik_dane_2);
        dane.append("plik_PDF_3", this.form.plik_dane_3);
        dane.append("plik_PDF_4", this.form.plik_dane_4);
        dane.append("Id_pojazdu_emisja", this.Id_pojazdu_emisja);

        api
          .zapiszSamochodCiezarowe(dane)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    naszWalidacjaFormularza() {
      if (this.czyJestWTrybieEdycji) {
        return (
          !this.$v.form.Nr_rejestracyjny.$invalid &&
          !this.$v.form.Nazwa_wybor_pojazdu.$invalid &&
          !this.$v.form.Nazwa_maszyny.$invalid &&
          !this.$v.form.Numer_VIN.$invalid &&
          !this.$v.form.Rok_produkcji.$invalid &&
          !this.$v.form.Status.$invalid &&
          !this.$v.form.Id_paliwo.$invalid &&
          !this.$v.form.Id_norma_emisji.$invalid

          // !this.$v.form.Nr_tachografu.$invalid &&
          // !this.$v.form.Data_legalizacji.$invalid &&
          // !this.$v.form.Data_nastepnej_legalizacji.$invalid &&
          // !this.$v.form.Legalizacje_wykonal.$invalid &&
          // !this.$v.form.Id_pojazdu_emisja.$invalid &&

          // !this.$v.form.Osoba_odpowiedzialna.$invalid &&
          // this.plikZwalidowany &&
          // this.plikZwalidowany1 &&
          // this.plikZwalidowany2
        );
      } else {
        return !this.$v.$invalid;
        // this.plikZwalidowany &&
        // this.plikZwalidowany1 &&
        // this.plikZwalidowany2
      }
    },
    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>

<style>
.Form_sam_osobowe .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.Form_sam_osobowe .md-layout .md-gutter {
  min-width: 55vw !important; /* szerokość modala*/
  max-width: 100% !important;
  min-height: 7vh; /* wysokość pomiędzy mierszami w modalu*/
}
.md-layout {
  padding-right: 0px !important;
}
.Form_sam_osobowe .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}

.Form_sam_osobowe .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  margin: 10px 24px !important;
  padding-top: 35px !important;
}
.Form_sam_osobowe .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}
.Form_sam_osobowe {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
  overflow: auto !important;
}
.Form_sam_osobowe .md-primary {
  border: 1px solid !important;

  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}
.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important; /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}

.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}
.md-list-item-text {
  font-size: 1.7vmin !important;
}
.Form_sam_osobowe .md-card-actions .md-button {
  margin-right: 50px !important;
}
</style>
