<template>
<div style="margin-left:10%; margin-right:10%; margin-top:7%">
<div class="ElementyWyboru">
  <b-container fluid class="p-0">
    <b-row>

         <b-col sm="12" md="4">
        <KafelekWyboru 
          sciezkaZdjecia="Zdjecia/o-kontekst.jpg"
          opisZdjecia="FajnaFotka"
          linkDoStrony="strona_glowna_modul_SYS"
          podpisPodKafelkiem="QMD_SYS - SYSTEMOWE"
        />
      </b-col>
            <b-col sm="12" md="4">
        <KafelekWyboru 
          sciezkaZdjecia="Zdjecia/o-maszyny.jpg"
          opisZdjecia="FajnaFotka"
          linkDoStrony="strona_glowna_modul_WST"
          podpisPodKafelkiem="QMD_WST - INFRASTRUKTURA"
        />
      </b-col>
               <b-col sm="12" md="4">
        <KafelekWyboru 
          sciezkaZdjecia="Zdjecia/o-pracownicy.jpg"
          opisZdjecia="FajnaFotka"
          linkDoStrony="strona_glowna_modul_HRB"
          podpisPodKafelkiem="QMD_HRB - HR"
        />
      </b-col>
            <b-col sm="12" md="4">
        <KafelekWyboru 
          sciezkaZdjecia="Zdjecia/o-bhp.jpg"
          opisZdjecia="FajnaFotka"
          linkDoStrony="strona_glowna_modul_BHP"
          podpisPodKafelkiem="QMD_BHP - BHP"
        />
      </b-col>


               <b-col sm="12" md="4">
        <KafelekWyboru 
          sciezkaZdjecia="Zdjecia/o-realizacja2.jpg"
          opisZdjecia="FajnaFotka"
          linkDoStrony="strona_glowna_modul_DOP"
          podpisPodKafelkiem="QMD_DOP - OPERACYJNE"
        />
      </b-col>

      <b-col sm="12" md="4">
        <KafelekWyboru 
          sciezkaZdjecia="Zdjecia/o-ustawienia.jpeg"
          opisZdjecia="FajnaFotka"
          linkDoStrony="tabela_dostepu"
          podpisPodKafelkiem="USTAWIENIA"
        />
      </b-col>
     
    </b-row>
  </b-container>
</div></div>
</template>

<script>
import KafelekWyboru from "./Kafelek_wyboru.vue";

export default {
  name: "ElementyWyboru",
  components: {
    KafelekWyboru,
  },
};
</script>

<style scoped>
.ElementyWyboru {
  margin-top: 1%;
  display: flex;
  flex-flow: row wrap;
}

</style>
