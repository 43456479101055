<template>
  <div>
  <TabelaSamochodyCiezarowePrzeglady />
  </div>
</template>

<script>
import TabelaSamochodyCiezarowePrzeglady from '../../komponenty/strona-tabela-komponenty/WST_samochody_ciezarowe/tabela_samochody_ciezarowe_przeglady.vue'


export default {
  name: 'StronaTabelaSamochodyCiezarowePrzeglady',
  components: {
    TabelaSamochodyCiezarowePrzeglady,
  }

}
</script>
