<template>
  <md-dialog :md-active="true" @md-clicked-outside="funkcjaZamykajacaDialog">

    <FormularzSumowaniaPaliwa
      v-if="rodzajFormularza === 'formularz_sumowania_paliwa'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzSumowaniaPaliwaMaszynyDozorowe
      v-if="rodzajFormularza === 'formularz_sumowania_paliwa_maszyny_dozorowe'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzSumowaniaPaliwaSamochodyOsobowe
      v-if="rodzajFormularza === 'formularz_sumowania_paliwa_samochody_osobowe'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzSumowaniaPaliwaSamochodyCiezarowe
      v-if="rodzajFormularza === 'formularz_sumowania_paliwa_samochody_ciezarowe'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
  </md-dialog>
</template>

<script>
import FormularzSumowaniaPaliwa from "./WST_maszyny/formularz_sumowania_paliwa";
import FormularzSumowaniaPaliwaMaszynyDozorowe from "./WST_maszyny_dozorowe/formularz_sumowania_paliwa_maszyny_dozorowe";
import FormularzSumowaniaPaliwaSamochodyOsobowe from "./WST_samochody_osobowe/formularz_sumowania_paliwa_samochody_osobowe";
import FormularzSumowaniaPaliwaSamochodyCiezarowe from "./WST_samochody_ciezarowe/formularz_sumowania_paliwa_samochody_ciezarowe";

export default {
  name: "modalPaliwo", // do zmiany
  components: {
    FormularzSumowaniaPaliwa,
    FormularzSumowaniaPaliwaMaszynyDozorowe,
    FormularzSumowaniaPaliwaSamochodyOsobowe,
    FormularzSumowaniaPaliwaSamochodyCiezarowe
  },
  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    rodzajFormularza: {
      type: String,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
};
</script>



