<template>
  <div class="Logowanie">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            FORMULARZ OBOWIĄZKOWEJ ZMIANY HASŁA
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('Login')">
                <label for="Login">LOGIN</label>
                <md-input
                  md-counter="50"
                  name="Login"
                  id="Login"
                  v-model="form.Login"
                />
                <span class="md-error" v-if="!$v.form.Login.required"
                  >Pole jest wymagane</span
                >
                <span class="md-error" v-else-if="!$v.form.Login.maxlength"
                  >Wymagana ilość znaków od 1 do 50</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M2" :class="getValidationClass('Haslo')">
                <label for="Haslo">STARE HASŁO</label>
                <md-input
                  md-counter="15"
                  type="password"
                  name="Haslo"
                  id="Haslo"
                  v-model="form.Haslo"
                />
                <span class="md-error" v-if="!$v.form.Haslo.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M2" :class="getValidationClass('Haslo_nowe')">
                <label for="Haslo_nowe">NOWE HASŁO</label>
                <md-input
                  md-counter="15"
                  type="password"
                  name="Haslo_nowe"
                  id="Haslo_nowe"
                  v-model="form.Haslo_nowe"
                />
                <span class="md-error" v-if="!$v.form.Haslo_nowe.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M2" :class="getValidationClass('Haslo_nowe2')">
                <label for="Haslo_nowe2">POWTÓRZ NOWE HASŁO</label>
                <md-input
                  md-counter="15"
                  type="password"
                  name="Haslo_nowe2"
                  id="Haslo_nowe2"
                  v-model="form.Haslo_nowe2"
                />
                <span class="md-error" v-if="!$v.form.Haslo_nowe2.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>
        </md-card-content>

        <div v-if="errors" style="text-align:center; margin-left:-10px">
          <p v-for="(blad, index) in errors.bledy"  :key="index" :class="md-error" >{{ blad }}</p>
        </div>

        <div style="text-align: center">
          <md-button type="submit" class="md-primary">{{
            "ZALOGUJ"
          }}</md-button>
        </div>
      </md-card>
    </form>
  </div>
</template>

<script>
const passwordPattern = (password) => {
  console.log(password);
  return new RegExp(
    "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
  ).test(password);
};

import { required, sameAs } from "vuelidate/lib/validators";
import { api } from "../../../serwisy/api.serwis";
import {userService} from "./../../../serwisy/user.serwis";

export default {
  name: "Formularz_dodawanie_maszyny",

  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      Login: null,
      Haslo: null,
      Haslo_nowe: null,
      Haslo_nowe2: null,
    },
    errors: null,
  }),

  validations() {
    return {
      form: {
        Login: {
          required,
        },
        Haslo: {
          required,
        },
        Haslo_nowe: {
          required,
          passwordPattern,
        },
        Haslo_nowe2: {
          required,
          passwordPattern,
          sameAsPassword: sameAs(function() {
            return this.form.Haslo_nowe;
          }) 
        },
      },
    };
  },

  methods: {
    przejdz() {
      this.$router.push("/strona_glowna");
    },
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.Login = null;
      this.form.Haslo = null;
      this.form.Haslo_nowe = null;
    },

    zapiszFormularz() {
      const dane = new FormData(); // dane typu FormData;
      dane.append("Login", this.form.Login);
      dane.append("Haslo", this.form.Haslo);
      dane.append("Haslo_nowe", this.form.Haslo_nowe);
      dane.append("Haslo_nowe2", this.form.Haslo_nowe2);

      api
        .logowanieZmianaHasla(dane)
        .then((response) => {
          if (response.data.sukces === true) {
            this.clearForm();
            userService.getUserInfo();
            this.przejdz();
            this.funkcjaZamykajacaDialog(true);
          } else {
            this.errors = { bledy: response.data.bledy };
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    naszWalidacjaFormularza() {
      return !this.$v.$invalid;
    },
    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>

<style>
.Logowanie .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
.Logowanie .md-layout .md-gutter {
  min-width: 20vw !important; /* szerokość modala*/
  max-width: 40vw !important;
  min-height: 7vh !important; /* wysokość pomiędzy mierszami w modalu*/
}
.md-layout {
  padding-right: 0px !important;
}
.Logowanie .M-Tytul {
  min-width: 20vw;
  max-width: 40vw;
  font-size: 2vh;
  font-weight: bold;
  text-align: center;
  color: blue;
}

.Logowanie .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  padding-top: 35px !important;
}
.Logowanie .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}

.Logowanie {
  border: 1px solid;
  margin: 10px 10px 10px 10px;
  overflow: auto;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(104, 55, 55, 0.19);
}
.Logowanie .md-primary {
  border: 1px solid !important;

  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}
.md-card-actions,
.md-alignment-right {
  min-width: 20vw !important; /* szerokość modala*/
  max-width: 40vw !important;
  margin-right: 30px;
}

.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}
.md-list-item-text {
  font-size: 1.7vmin !important;
}
.Logowanie .md-card-actions .md-button {
  margin-right: 4vw !important;
}
</style>
