<template>
  <div>
  <ElementyWyboruSYS />
  </div>
</template>

<script>
import ElementyWyboruSYS from '../komponenty/strona-glowna-sys/Elementy_wyboru_SYS.vue'


export default {
  name: 'StronaGlownaSYS',
  components: {
    ElementyWyboruSYS
  }
}
</script>
