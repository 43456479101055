<template >
  <div>
  <TabelaPrzegladyInstrukcjeOperacyjne />
  </div>
</template>

<script>
import TabelaPrzegladyInstrukcjeOperacyjne from '../../komponenty/strona-tabela-komponenty/SYS_instrukcje_operacyjne/tabela_instrukcje_operacyjne_przeglady.vue'


export default {
  name: 'StronaTabelaPrzegladyInstrukcjeOperacyjne',
  components: {
    TabelaPrzegladyInstrukcjeOperacyjne,
    
  }

}
</script>

