var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tabela_PracU"},[_c('div',{staticClass:"menu_strony"},[_c('span',[_c('button',{staticClass:"button",attrs:{"disabled":!_vm.autoryzacja.czyMozeDodawac},on:{"click":function($event){_vm.pokazDialog = true;
          _vm.typAktywnegoFormularza = 'formularz_dodawanie_pracownika';
          _vm.obiektKonfiguracyjny = {
            Id: null,
            akcja: 'dodawanie',
          };}}},[_vm._v(" DODAWANIE PRACOWNIKA ")]),_c('md-tooltip',{staticClass:"podpowiedz",attrs:{"md-direction":"top"}},[_vm._v(_vm._s(!_vm.autoryzacja.czyMozeDodawac ? "BRAK UPRAWNIEŃ" : "DODAWANIE PRACOWNIKA "))])],1),_c('button',{staticClass:"button",on:{"click":function($event){return _vm.$router.push("../pracownicy_zatrudnieni")}}},[_vm._v(" POWRÓT DO WYKAZU PRACOWNIKÓW ")])]),(_vm.pokazDialog)?_c('modalHR',{attrs:{"rodzajFormularza":_vm.typAktywnegoFormularza,"funkcjaZamykajacaDialog":_vm.funkcjaZamykajacaDialog,"obiektKonfiguracyjny":_vm.obiektKonfiguracyjny}}):_vm._e(),_c('md-table',{attrs:{"md-height":"45vh","md-sort":"Nazwa_maszyny","md-sort-order":"asc","md-model-id":"Id_maszyny","md-card":"","md-fixed-header":""},on:{"md-selected":_vm.wyborElementu},scopedSlots:_vm._u([{key:"md-table-row",fn:function(ref){
          var item = ref.item;
return _c('md-table-row',{key:item.id,attrs:{"md-selectable":"single"}},[_c('md-table-cell',{attrs:{"md-label":"Nazwisko","md-sort-by":"Nazwisko"}},[_vm._v(" "+_vm._s(item.Nazwisko)+" ")]),_c('md-table-cell',{attrs:{"md-label":"Imię-pierwsze","md-sort-by":"Imie1"}},[_vm._v(" "+_vm._s(item.Imie1)+" ")]),_c('md-table-cell',{attrs:{"md-label":"Data urodz.","md-sort-by":"Data_urodzenia"}},[_vm._v(" "+_vm._s(item.Data_urodzenia)+" ")]),_c('md-table-cell',{attrs:{"md-label":"Miejsce urodz.","md-sort-by":"Miejsce_urodzenia"}},[_vm._v(" "+_vm._s(item.Miejsce_urodzenia)+" ")]),_c('md-table-cell',{attrs:{"md-label":"Imię ojca","md-sort-by":"Imie_ojca"}},[_vm._v(" "+_vm._s(item.Imie_ojca)+" ")]),_c('md-table-cell',{attrs:{"md-label":"Imię matki","md-sort-by":"Imie_matki"}},[_vm._v(" "+_vm._s(item.Imie_matki)+" ")]),_c('md-table-cell',{attrs:{"md-label":"Płeć","md-sort-by":"Rodzaj_plci"}},[_vm._v(" "+_vm._s(item.Rodzaj_plci)+" ")])],1)}}]),model:{value:(_vm.searched),callback:function ($$v) {_vm.searched=$$v},expression:"searched"}},[_c('md-table-toolbar',[_c('div',{staticClass:"d-flex flex-column w-100"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4"}),_c('div',{staticClass:"col-4"},[_c('h1',{staticClass:"md-title"},[_vm._v(" WYKAZ ZATRUDNIONYCH"),_c('br'),_vm._v("PRACOWNIKÓW DANE UZUPEŁNIAJĄCE ")])])]),(_vm.wybranyElementTabeli)?_c('div',{staticClass:"PasekWyboru"},[_c('div',{staticStyle:{"text-align":"right","margin-top":"1vh","margin-bottom":"1vh"}},[_c('b',[_c('b-button',{attrs:{"disabled":!_vm.autoryzacja.czyMozeEdytowac},on:{"click":function($event){_vm.pokazDialog = true;
                  _vm.typAktywnegoFormularza = 'formularz_dodawanie_pracownika';
                  _vm.obiektKonfiguracyjny = {
                    Id: _vm.wybranyElementTabeli.Id,
                    akcja: 'edycja',
                  };}}},[_c('b-icon-pencil-square',{staticClass:"ikony",attrs:{"font-scale":"1.80","color":"white"}})],1),_c('md-tooltip',{staticClass:"podpowiedz",attrs:{"md-direction":"top"}},[_vm._v(_vm._s(_vm.autoryzacja.czyMozeUsuwac ? "EDYCJA ELEMENTU" : "BRAK UPRAWNIEŃ"))])],1)])]):_vm._e()])]),(_vm.czyPobranoDane)?_c('md-table-empty-state',{attrs:{"md-label":"Nie posiadasz wpisów w wykazie pracowników"}},[_c('md-button',{staticClass:"md-primary md-raised",attrs:{"disabled":!_vm.autoryzacja.czyMozeDodawac},on:{"click":function($event){_vm.pokazDialog = true;
          _vm.typAktywnegoFormularza = 'formularz_dodawanie_pracownika';
          _vm.obiektKonfiguracyjny = {
            Id: null,
            akcja: 'dodawanie',
          };}}},[_vm._v("Dodawanie nowego pracownika")]),_c('md-tooltip',{staticClass:"podpowiedz",attrs:{"md-direction":"top"}},[_vm._v(_vm._s(!_vm.autoryzacja.czyMozeDodawac ? "BRAK UPRAWNIEŃ" : "DODAWANIE PRACOWNIKA"))])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }