<template>
  <div>
  <tabelaWykazChorobyZawodoweSkutki/>

  </div>
</template>

<script>
import tabelaWykazChorobyZawodoweSkutki from '../../komponenty/strona-tabela-komponenty/BHP_choroby_zawodowe/tabela_dzialan_na_skutki.vue'


export default {
  name: 'StronaTabelaWykazChorobyZawodoweSkutki',
  components: {
       tabelaWykazChorobyZawodoweSkutki,

  }

}
</script>
