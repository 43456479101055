<template>
  <div class="ass">
    <table class="table_01_ZAKRES">
      <tr>
        <td
          style="width: 100%; height:70px; background: #adadad; font-size:24px; font-weight: 700"
          colspan="2"
        >
          PROTOKÓŁ Z PRZEGLĄDU ELEKTRONARZĘDZIA
        </td>
      </tr>

      <tr>
        <td style="width: 50%; background: #e3e3e3; font-weight: 500">
          Data wykonanego przeglądu
        </td>
        <td style="width: 50%; height: 50px">
          <span>{{ info.Data_przegladu }}</span>
        </td>
      </tr>

      <tr>
        <td style="width: 50%; background: #e3e3e3; font-weight: 500">
          Numer identyfikacyjny elektronarzędzia
        </td>
        <td style="width: 50%; height: 50px">
          <span>{{ info.Id_maszyny }}</span>
        </td>
      </tr>
      <tr>
        <td style="width: 50%; background: #e3e3e3; font-weight: 500">
          Nazwa elektronarzędzia
        </td>
        <td style="width: 50%; height: 50px">
          <span>{{ info.Nazwa_maszyny }}</span>
        </td>
      </tr>

      <tr>
        <td style="width: 50%; background: #e3e3e3; font-weight: 500">
          Numer fabryczny elektronarzędzia
        </td>
        <td style="width: 50%; height: 50px">
          <span>{{ info.Numer_fabryczny }}</span>
        </td>
      </tr>

      <tr>
        <td style="width: 50%; background: #e3e3e3; font-weight: 500">
          Rok produkcji
        </td>
        <td style="width: 50%; height: 50px">
          <span>{{ info.Rok_produkcji }}</span>
        </td>
      </tr>

      <tr>
        <td style="width: 50%; background: #e3e3e3; font-weight: 500">
          Przegląd wykonał
        </td>
        <td style="width: 50%; height: 50px">
          <span>{{ info.Przeglad_wykonal }}</span>
        </td>
      </tr>

      <tr>
        <td
          style="width: 100%; height:40px; background: #adadad; font-size:14px; font-weight: 700"
          colspan="2"
        >
          WYKONANE CZYNNOŚCI KONTROLNE DLA ELEKTRONARZĘDZIA 
        </td>
      </tr>

      <tr>
        <td style="width: 50%; background: #e3e3e3; font-weight: 500">
          Oględziny zewnętrzne
        </td>
        <td style="width: 50%; height: 50px">
          <span>{{ info.W1 }}</span>
        </td>
      </tr>

      <tr>
        <td style="width: 50%; background: #e3e3e3; font-weight: 500">
          Oględziny wewnętrzne wymagające częściowego demontażu
        </td>
        <td style="width: 50%; height: 50px">
          <span>{{ info.W2 }}</span>
        </td>
      </tr>

      <tr>
        <td style="width: 50%; background: #e3e3e3; font-weight: 500">
          Sprawdzenie biegu jałowego
        </td>
        <td style="width: 50%; height: 50px">
          <span>{{ info.W3 }}</span>
        </td>
      </tr>

      <tr>
        <td style="width: 50%; background: #e3e3e3; font-weight: 500">
          Pomiar rezystancji izolacji
        </td>
        <td style="width: 50%; height: 50px">
          <span>{{ info.W4 }}</span>
        </td>
      </tr>

      <tr>
        <td style="width: 50%; background: #e3e3e3; font-weight: 500">
          Pomiar obwodu ochronnego (przewodu PE)
        </td>
        <td style="width: 50%; height: 50px">
          <span>{{ info.W5 }}</span>
        </td>
      </tr>

      <tr>
        <td style="width: 50%; background: #e3e3e3; font-weight: 500">
          Inne czynności sprawdzające
        </td>
        <td style="width: 50%; height: 50px">
          <span>{{ info.W6 }}</span>
        </td>
      </tr>

      <tr>
        <td
          style="width: 100%; height:40px; background: #adadad; font-size:14px; font-weight: 700"
          colspan="2"
        >
          WNIOSKI Z PRZEPROWADZONEGO PRZEGLĄDU ELEKTRONARZĘDZIA
        </td>
      </tr>
      <tr>
        <td style="width: 50%; background: #e3e3e3; font-weight: 500">
          Na podstawie przeprowadzego przeglądu
        </td>
        <td style="width: 50%; height: 50px">
          <span>{{ info.Czynnosc }}</span>
        </td>
      </tr>

      <tr>
        <td style="width: 50%; background: #e3e3e3; font-weight: 500">
          Data następnego przeglądu technicznego elektronarzędzia
        </td>
        <td style="width: 50%; height: 50px">
          <span>{{ info.Data_nastepnego_przegladu }}</span>
        </td>
      </tr>
      <tr>
        <td
          style="width: 100%; height:40px; background: #adadad; font-size:14px; font-weight: 700"
          colspan="2"
        >
          POTWIERDZENIE WYKONANIA PRZEGLĄDU DLA ELEKTRONARZĘDZIA
        </td>
      </tr>

      <tr>
        <td style="width: 50%; background: #e3e3e3; font-weight: 500">
          DATA i PODPIS OSOBY WYKONUJĄCEJ PRZEGLĄD :
        </td>
        <td style="width: 50%; height: 80px"></td>
      </tr>
    </table>
  </div>
</template>

<script>
import { api } from "../../../serwisy/api.serwis";
export default {
  name: "Skierowanie",
  data: () => ({
    info: {
      Data: null,
      Id: null,
      Imie1: null,
      Nazwisko: null,
      Odpowiedzialnosc: null,
      Uprawnienia: null,
      Obowiazki: null,
      Dane: [],
    },
  }),
  created() {
    this.pobierzZakres();
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  methods: {
    async pobierzZakres() {
      try {
        const {
          data: { dane },
        } = await api.pobieranieGeneratorPrzegladowElektronarzedziaProtokol(this.id);
        const info = dane[0];
        this.info = info;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
.ass {
  width: 100%;
  height: auto;
  overflow: auto;
}
.table_01_ZAKRES {
  width: 100%;
  height: auto;
  font-size: 16px;
  margin-top: 40px;
  color: black;
  vertical-align: middle;
  margin-bottom: 100px;
}
.table_01_ZAKRES td {
  border: 1px solid black;
}

.input {
  width: 100%;
  height: 100%;

  text-align: center;
}
.table_01_ZAKRES p {
  margin-top: 0;
  margin-bottom: 0.1rem !important;
}
.table_01_ZAKRES .textarea {
  width: 100%;
  min-height: 30px;
  max-height: 200px;
  text-align: center;
}
</style>
