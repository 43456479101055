<template >
  <div>
  <TabelaZakupy />
  </div>
</template>

<script>
import TabelaZakupy from '../../komponenty/strona-tabela-komponenty/SO_zakupy/tabela_zakupy.vue'


export default {
  name: 'StronaTabelaZakupy',
  components: {
    TabelaZakupy,
    
  }

}
</script>

