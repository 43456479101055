<template>
  <div>
  <tabelaStanowiska/>

  </div>
</template>

<script>
import tabelaStanowiska from '../../komponenty/strona-tabela-komponenty/HR_stanowiska/tabela_stanowiska.vue'


export default {
  name: 'StronaTabelaStanowiska',
  components: {
       tabelaStanowiska,
  }

}
</script>
