<template>

  <TabelaPrzegladPraceSzczegolnieNiebezpieczne />

</template>

<script>
import TabelaPrzegladPraceSzczegolnieNiebezpieczne from '../../komponenty/strona-tabela-komponenty/BHP_prace_szczegolnie_niebezpieczne/tabela_przeglad_prac_szczegolnie_niebezpiecznych.vue'



export default {
  name: 'StronaTabelaPrzegladPraceSzczegolnieNiebezpieczne',
  components: {
    TabelaPrzegladPraceSzczegolnieNiebezpieczne,

    }

}
</script>
