<template>
  <div>
  <TabelaMaszynyDozoroweUbezpieczenia />
  </div>
</template>

<script>
import TabelaMaszynyDozoroweUbezpieczenia from '../../komponenty/strona-tabela-komponenty/WST_maszyny_dozorowe/tabela_maszyny_dozorowe_ubezpieczenia.vue'


export default {
  name: 'StronaTabelaMaszynyDozoroweUbezpieczenia',
  components: {
    TabelaMaszynyDozoroweUbezpieczenia,
  }

}
</script>
