var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tabela_DZAUOSkut"},[_c('div',{staticClass:"laczacy"},[_c('div',{staticClass:"menu_strony"},[_c('button',{staticClass:"button",on:{"click":function($event){return _vm.$router.push("../dzialania_poza_auditowe")}}},[_vm._v(" POWRÓT DO WYKAZU NIEZGODNOŚCI I SPOSTRZEŻEŃ ")])]),_c('div',{staticClass:"menu_strony1"},[_c('b',[_c('b-icon-printer-fill',{staticClass:"ikony",attrs:{"font-scale":"2.1","color":"white"},on:{"click":_vm.generujPdf}}),_c('md-tooltip',{staticClass:"podpowiedz",attrs:{"md-direction":"top"}},[_vm._v("GENERUJ PDF / DRUKUJ")])],1),_c('b',[_c('b-icon-info-circle-fill',{staticClass:"ikony",attrs:{"font-scale":"2.1","color":"white"},on:{"click":_vm.pokazPDF_INFO}}),_c('md-tooltip',{staticClass:"podpowiedz",attrs:{"md-direction":"top"}},[_vm._v("INFO")])],1)])]),(_vm.pokazDialog)?_c('modal',{attrs:{"rodzajFormularza":_vm.typAktywnegoFormularza,"funkcjaZamykajacaDialog":_vm.funkcjaZamykajacaDialog,"obiektKonfiguracyjny":_vm.obiektKonfiguracyjny}}):_vm._e(),_c('md-table',{attrs:{"md-height":"45vh","md-sort":"Nazwa_maszyny","md-sort-order":"asc","md-model-id":"Id_maszyny","md-card":"","md-fixed-header":""},on:{"md-selected":_vm.wyborElementu},scopedSlots:_vm._u([{key:"md-table-row",fn:function(ref){
var item = ref.item;
return _c('md-table-row',{key:item.id,attrs:{"md-selectable":"single"}},[_c('md-table-cell',{attrs:{"md-label":"Lp"}},[_vm._v(" "+_vm._s(item.Lp1)+" ")]),_c('md-table-cell',{attrs:{"md-label":"Nr N/S","md-sort-by":"Nr_dzialania"}},[_vm._v(" "+_vm._s(item.Nr_dzialania)+" ")]),_c('md-table-cell',{attrs:{"md-label":"Data zakończ.","md-sort-by":"Data_zakonczenia"}},[_vm._v(" "+_vm._s(item.Data_zakonczenia)+" ")]),_c('md-table-cell',{attrs:{"md-label":"Data oceny","md-sort-by":"Data_oceny"}},[_vm._v(" "+_vm._s(item.Data_oceny)+" ")]),_c('md-table-cell',{attrs:{"md-label":"Ocena","md-sort-by":"Ocena"}},[_vm._v(" "+_vm._s(item.Ocena)+" ")]),_c('md-table-cell',{attrs:{"md-label":"Ocenę przeprowadził","md-sort-by":"Ocene_przeprowadzil"}},[_vm._v(" "+_vm._s(item.Ocene_przeprowadzil)+" ")])],1)}}]),model:{value:(_vm.searched),callback:function ($$v) {_vm.searched=$$v},expression:"searched"}},[_c('md-table-toolbar',[_c('div',{staticClass:"d-flex flex-column w-100"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4"}),_c('div',{staticClass:"col-4"},[_c('h1',{staticClass:"md-title"},[_vm._v(" OCENA PODJĘTYCH DZIAŁAŃ DO STWIERDZONYCH"),_c('br'),_vm._v(" UWAG POZA AUDITAMI WEWNĘTRZNYMI ")])])]),(_vm.wybranyElementTabeli)?_c('div',{staticClass:"PasekWyboru"},[_c('div',{staticStyle:{"text-align":"right","margin-top":"0.3vh","margin-bottom":"0.3vh"}},[_c('b',[_c('b-button',{attrs:{"disabled":!_vm.autoryzacja.czyMozeEdytowac},on:{"click":function($event){_vm.pokazDialog = true;
                  _vm.typAktywnegoFormularza =
                    'formularz_dodawanie_dzialania_poza_audit_ocena_skutecznosci';
                  _vm.obiektKonfiguracyjny = {
                    Id: _vm.wybranyElementTabeli.Id,
                    akcja: 'edycja',
                  };}}},[_c('b-icon-pencil-square',{staticClass:"ikony",attrs:{"font-scale":"1.80","color":"white"}})],1),_c('md-tooltip',{staticClass:"podpowiedz",attrs:{"md-direction":"top"}},[_vm._v(_vm._s(_vm.autoryzacja.czyMozeUsuwac ? "EDYCJA ELEMENTU" : "BRAK UPRAWNIEŃ"))])],1)])]):_vm._e()])]),(_vm.czyPobranoDane)?_c('md-table-empty-state',{attrs:{"md-label":"Nie posiadasz wpisów w wykazie podjętych działań do uwagi"}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }