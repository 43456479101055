<template>
  <div class="Stanowiska">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI STANOWISKA PRACY"
                : "FORMULARZ DODAWANIA STANOWISKA PRACY"
            }}
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M1"
                :class="getValidationClass('Stanowisko')"
              >
                <label for="Stanowisko">Stanowisko</label>
                <!--- edycja 7 linia-->
                <md-input
                  md-counter="100" 
                  name="Stanowisko"
                  id="Stanowisko"
                  
                  v-model="form.Stanowisko"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Stanowisko.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Stanowisko.maxlength"
                  >Wymagana ilość znaków od 1 do 100</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M2"
                :class="getValidationClass('Wymagania')"
              >
                <label for="Wymagania">Wymagania</label>
                <md-textarea md-autogrow md-counter="1000"

                  name="Wymagania"
                  id="Wymagania"
                  
                  v-model="form.Wymagania"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Wymagania.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Wymagania.maxlength"
                  >Wymagana ilość znaków od 1 do 1000</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M4"
                :class="getValidationClass('Kompetencje')"
              >
                <label for="Kompetencje">Kompetencje</label>
                <md-textarea md-autogrow md-counter="1000"

                  name="Kompetencje"
                  id="Kompetencje"
                  
                  v-model="form.Kompetencje"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Kompetencje.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Kompetencje.maxlength"
                  >Wymagana ilość znaków od 1 do 1000</span
                >
              </md-field>
            </div>
          </div>
   <div style="color: red; margin-top: 30px; text-align: center; font-weight:bold">
            PAMIĘTAJ O USTANOWIENIU DATY PRZEGLĄDU STANOWISK PRACY ORAZ WYMAGANYCH KOMPETENCJI
          </div>

        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

         <div style="text-align: center">
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            czyJestWTrybieEdycji
              ? "ZATWIERDŹ - EDYTOWANY WPIS"
              : "ZATWIERDŹ - UTWÓRZ WPIS"
          }}</md-button>
         </div>
      </md-card>

    </form>
  </div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import minLength from "vuelidate/lib/validators/minLength";
import { api } from "./../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_maszyny",

  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      Stanowisko: null,
      Wymagania: null,
      Kompetencje: null,
    },
    IdEdytowanegoElementu: null,
    userSaved: false,
    sending: false,
    lastUser: null,

  }),
  created: function () {
    if (this.obiektKonfiguracyjny.akcja === "edycja") {
      //edycja
      api
        .pobieranieStanowiskaEdycja(this.obiektKonfiguracyjny.Id)
        .then((odpowiedz) => {
          const {
            Id,
            Stanowisko,
            Wymagania,
            Kompetencje,
         } = odpowiedz.data.dane[0];
          this.IdEdytowanegoElementu = Id;
          this.form.Stanowisko = Stanowisko;
          this.form.Wymagania = Wymagania;
          this.form.Kompetencje = Kompetencje;
        })
        .catch((error) => {
          console.log(error);
        });
    }

  },
  validations: {
    form: {
      Stanowisko: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(100),
      },
      Wymagania: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(1000),
      },
      Kompetencje: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(1000),
      },
    },
  },
  computed: {

    czyJestWTrybieEdycji: function () {
      return this.obiektKonfiguracyjny.akcja === "edycja";
    },
  },

  methods: {

    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.Stanowisko = null;
      this.form.Wymagania = null;
      this.form.Kompetencje = null;
    },

    zapiszFormularz() {
      this.sending = true;

      if (this.czyJestWTrybieEdycji) {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Stanowisko", this.form.Stanowisko);
        dane.append("Wymagania", this.form.Wymagania);
        dane.append("Kompetencje", this.form.Kompetencje);

        api
          .zapiszStanowiskaUpdate(dane, this.IdEdytowanegoElementu)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Stanowisko", this.form.Stanowisko);
        dane.append("Wymagania", this.form.Wymagania);
        dane.append("Kompetencje", this.form.Kompetencje);

        api
          .zapiszStanowiska(dane)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    naszWalidacjaFormularza() {
      if (this.czyJestWTrybieEdycji) {
        return (
          !this.$v.form.Stanowisko.$invalid &&
          !this.$v.form.Wymagania.$invalid &&
          !this.$v.form.Kompetencje.$invalid 
        );
      } else {
        return !this.$v.$invalid;
      }
    },
    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>


<style>
.Stanowiska .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
.Stanowiska .md-layout .md-gutter {
  min-width: 55vw !important; /* szerokość modala*/
  max-width: 100% !important;
  min-height: 7vh !important; /* wysokość pomiędzy mierszami w modalu*/
}
.md-layout {
  padding-right: 0px !important;
}
.Stanowiska .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}

.Stanowiska .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  margin: 10px 24px !important;
  padding-top: 35px !important;
}
.Stanowiska .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}

.Stanowiska {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
  overflow: auto;
}
.Stanowiska .md-primary {
  border: 1px solid !important;

  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}
.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important; /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}

.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}
.md-list-item-text {
  font-size: 1.7vmin !important;
}
.Stanowiska .md-card-actions .md-button {
  margin-right: 4vw !important;
}
</style>