<template>
  <div class="Form_DZPAO">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI DZIAŁANIA DO STWIERDZONEJ NIEZGODNOŚCI/SPOSTRZEŻENIA"
                : "FORMULARZ DODAWANIA DZIAŁANIA DO STWIERDZONEJ NIEZGODNOŚCI/SPOSTRZEŻENIA"
            }}
          </div>
        </md-card-header>

        <md-card-content>


          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M4" :class="getValidationClass('Id_uwagi')">
                <label for="Id_uwagi">Wybor uwagi</label>

                <md-select
                  v-model="form.Id_uwagi"
                  :disabled="sending || czyJestWTrybieEdycji" 
                  name="Id_uwagi"
                  id="Id_uwagi"
                  autocomplete="Id_uwagi"
                  md-dense
                >
                  <md-option
                    v-for="(dzialanie, index) in Dzialania"
                    :key="index"
                    :value="dzialanie.Id"
                    >{{
                      ` ${dzialanie.Nr_dzialania}`
                    }}</md-option
                  >
                </md-select>
                <span class="md-error" v-if="!$v.form.Id_uwagi.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">

                      <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('Przyczyna')">
                <label  for="Przyczyna">Przyczyna</label>
                <md-textarea v-model="form.Przyczyna" md-autogrow md-counter="500"
                  name="Przyczyna"
                  id="Przyczyna"
                  autocomplete="Przyczyna"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Przyczyna.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Przyczyna.maxlength"
                  >Wymagana ilość znaków od 1 do 500</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">

                      <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('Korekcja')">
                <label for="Korekcja">Działanie korekcyjne</label>
                <md-textarea v-model="form.Korekcja" md-autogrow md-counter="500"
                  name="Korekcja"
                  id="Korekcja"
                  autocomplete="Korekcja"
                  :disabled="sending"
                />
                <span style="margin-bottom:1px" class="md-error" v-if="!$v.form.Korekcja.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Korekcja.maxlength"
                  >Wymagana ilość znaków od 1 do 500</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">

                      <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('Korygujace')">
                <label for="Korygujace">Działanie korygujące</label>
                <md-textarea v-model="form.Korygujace" md-autogrow md-counter="500"
                  name="Korygujace"
                  id="Korygujace"
                  autocomplete="Korygujace"
           
                  :disabled="sending"
                />
                <span style="margin-bottom:1px" class="md-error" v-if="!$v.form.Korygujace.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Korygujace.maxlength"
                  >Wymagana ilość znaków od 1 do 500</span
                >
              </md-field>
            </div>
          </div>
    
          <div class="md-layout md-gutter">

                      <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('Data_zakonczenia')">
                <label for="Data_zakonczenia">Planowana data zakończenia</label>
                <md-input
                  type="date"
                  name="Data_zakonczenia"
                  id="Data_zakonczenia"
                  autocomplete="Data_zakonczenia"
                  v-model="form.Data_zakonczenia"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Data_zakonczenia.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />


        <div style="text-align:center">
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            czyJestWTrybieEdycji
              ? "ZATWIERDŹ - EDYTOWANY WPIS"
              : "ZATWIERDŹ - UTWÓRZ WPIS"
          }}</md-button>
        </div>
      </md-card>
    </form>
  </div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import minLength from "vuelidate/lib/validators/minLength";
import { api } from "./../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_maszyny",

  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      Id_uwagi: null,
      Przyczyna: null,
      Korekcja: null,
      Korygujace: null,
      Data_zakonczenia: null,
    },
    IdEdytowanegoElementu: null,
    userSaved: false,
    sending: false,
    lastUser: null,
    Dzialania: [],
  }),
  created: function () {
    if (this.obiektKonfiguracyjny.akcja === "edycja") {
      //edycja
      api
        .pobieranieDzialaniaPozaAuditEdycjaOcena(this.obiektKonfiguracyjny.Id)
        .then((odpowiedz) => {
          const {
            Id,
            Id_uwagi,
            Przyczyna,
            Korekcja,
            Korygujace,
            Data_zakonczenia,
          } = odpowiedz.data.dane[0];
          this.IdEdytowanegoElementu = Id;
          this.form.Id = Id_uwagi;
          this.form.Id_uwagi = Id_uwagi;
          this.form.Przyczyna = Przyczyna;
          this.form.Korekcja = Korekcja;
          this.form.Korygujace = Korygujace;
          this.form.Data_zakonczenia = Data_zakonczenia;
        })
        .catch((error) => {
          console.log(error);
        });
    }

        api
      .PobierzNumerDzialaniaPozaAudit()
      .then((odpowiedz) => {
        this.Dzialania = odpowiedz.data.dane;
      })
      .catch((error) => {
        console.log(error);
      });

  },
  validations: {
    form: {
      Id_uwagi: {
        required,
      },
      Przyczyna: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(500),
      },
      Korekcja: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(500),
      },
      Korygujace: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(500),
      },
      Data_zakonczenia: {
        required,
      },
    },
  },
  computed: {
    czyJestWTrybieEdycji: function () {
      return this.obiektKonfiguracyjny.akcja === "edycja";
    },
  },

  methods: {
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },


    clearForm() {
      this.$v.$reset();
      this.form.Id_uwagi = null;
      this.form.Przyczyna = null;
      this.form.Korekcja = null;
      this.form.Korygujace = null;
      this.form.Data_zakonczenia = null;

    },

    zapiszFormularz() {
      this.sending = true;

      if (this.czyJestWTrybieEdycji) {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Id_uwagi", this.form.Id_uwagi);
        dane.append("Przyczyna", this.form.Przyczyna);
        dane.append("Korekcja", this.form.Korekcja);
        dane.append("Korygujace", this.form.Korygujace);
        dane.append("Data_zakonczenia", this.form.Data_zakonczenia);

        api
          .zapiszDzialaniaPozaAuditOcenaUpdate(dane, this.IdEdytowanegoElementu)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const dane = new FormData(); // dane typu FormData;
        dane.append("Id_uwagi", this.form.Id_uwagi);
        dane.append("Przyczyna", this.form.Przyczyna);
        dane.append("Korekcja", this.form.Korekcja);
        dane.append("Korygujace", this.form.Korygujace);
        dane.append("Data_zakonczenia", this.form.Data_zakonczenia);

        api
          .zapiszDzialaniaPozaAuditOcena(dane)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    naszWalidacjaFormularza() {
      if (this.czyJestWTrybieEdycji) {
        return (
          !this.$v.form.Id_uwagi.$invalid &&
          !this.$v.form.Przyczyna.$invalid &&
          !this.$v.form.Korekcja.$invalid &&
          !this.$v.form.Korygujace.$invalid &&
          !this.$v.form.Data_zakonczenia.$invalid
        );
      } else {
        return !this.$v.$invalid;
      }
    },

    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>


<style>
.Form_DZPAO .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
.Form_DZPAO
{
  overflow:auto;
}
.Form_DZPAO .md-layout .md-gutter {
  min-width: 55vw !important; /* szerokość modala*/
  max-width: 100% !important;
  min-height: 7vh; /* wysokość pomiędzy mierszami w modalu*/
}
.md-layout {
  padding-right: 0px !important;
}
.Form_DZPAO .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}

.Form_DZPAO .md-field {
  /*opisy w formularzu*/
  width: 100%!important;
  min-height: 58px !important;
  margin: 10px 24px !important;
  padding-top: 35px !important;
}
.Form_DZPAO .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}


.Form_DZPAO {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
  overflow: auto;
}
.Form_DZPAO .md-primary {
  border: 1px solid !important;

  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}
.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important; /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}

.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}
.Form_DZPAO .md-list-item-text {
  font-size: 1.7vmin !important;
}
.Form_DZPAO .md-card-actions .md-button {
  margin-right: 50px !important;
}
.Form_DZPAO .md-field.md-has-textarea:not(.md-autogrow) label {
    top: 16px;
    left: 0px;
}
</style>