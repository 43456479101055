<template>
  <md-dialog :md-active="true" @md-clicked-outside="funkcjaZamykajacaDialog">

    <FormularzDodawaniePracownika
      v-if="rodzajFormularza === 'formularz_dodawanie_pracownika'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieAdresuZamieszkania
      v-if="rodzajFormularza === 'formularz_dodawanie_adresu_zamieszkania'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieAdresuKorespondencja
      v-if="rodzajFormularza === 'formularz_dodawanie_adresu_korespondencja'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieDanychZatrudnienia
      v-if="rodzajFormularza === 'formularz_dodawanie_dane_zatrudnienia'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzEdycjiDanychZatrudnienia
      v-if="rodzajFormularza === 'formularz_edycji_dane_zatrudnienia'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieZakresu
      v-if="rodzajFormularza === 'formularz_dodawanie_zakresu'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieBadan
      v-if="rodzajFormularza === 'formularz_dodawanie_badan'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieZapoznaniaIO
      v-if="rodzajFormularza === 'formularz_dodawanie_zapoznania_IO'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieZapoznaniaIS
      v-if="rodzajFormularza === 'formularz_dodawanie_zapoznania_IS'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieZapoznaniaIBHP
      v-if="rodzajFormularza === 'formularz_dodawanie_zapoznania_IBHP'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieZapoznaniaORZ
      v-if="rodzajFormularza === 'formularz_dodawanie_zapoznania_ORZ'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieKompetencji
      v-if="rodzajFormularza === 'formularz_dodawanie_kompetencji'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieHistoriiZatrudnienia
      v-if="rodzajFormularza === 'formularz_dodawanie_historii_zatrudnienia'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieAbsencji
      v-if="rodzajFormularza === 'formularz_dodawanie_absencji'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieMedycynyPracy
      v-if="rodzajFormularza === 'formularz_dodawanie_medycyny_pracy'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieZakresuOOIU
      v-if="rodzajFormularza === 'formularz_dodawanie_zakresu_obowiazkow'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieZakresuKatalog
      v-if="rodzajFormularza === 'formularz_dodawanie_zakresu_katalog'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />



    <FormularzSodawanieSWS
      v-if="rodzajFormularza === 'formularz_dodawanie_SWS'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieSS
      v-if="rodzajFormularza === 'formularz_dodawanie_SS'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieSzkoleniaBHP
      v-if="rodzajFormularza === 'formularz_dodawanie_szkolenia_BHP'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
   <FormularzDodawanieStanowiska
      v-if="rodzajFormularza === 'formularz_dodawanie_stanowiska'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <FormularzDodawanieStanowiskaPrzeglad
      v-if="rodzajFormularza === 'formularz_dodawanie_przegladu_stanowiska'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    /> 
    <FormularzDodawaniePlanuSzkolen
      v-if="rodzajFormularza === 'formularz_dodawanie_planu_szkolen'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />   
    <FormularzDodawanieSzkolenia
      v-if="rodzajFormularza === 'formularz_dodawanie_szkolenia'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    /> 
    <FormularzDodawanieRealizacji
      v-if="rodzajFormularza === 'formularz_dodawanie_realizacji'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />   
    <FormularzDodawanieUczestnika
      v-if="rodzajFormularza === 'formularz_dodawanie_uczestnika'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />   
    <FormularzDodawanieSkierowania
      v-if="rodzajFormularza === 'formularz_dodawanie_skierowania'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />   
    <FormularzSumowaniaAbsencji
      v-if="rodzajFormularza === 'formularz_sumowania_absencji'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />   
    <FormularzLogowania
      v-if="rodzajFormularza === 'formularz_logowanie'"
      :funkcjaWlaczajacaDialogEdycjiHasla="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />  
    <FormularzLogowaniaZmianaHasla
      v-if="rodzajFormularza === 'formularz_logowanie_zmiana_hasla'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />  
    <FormularzDodawanieUprawnien
      v-if="rodzajFormularza === 'formularz_dodawanie_uzytkownika'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />  
    <FormularzDodawanieProjektu
      v-if="rodzajFormularza === 'formularz_dodawanie_projektu'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />  
    <FormularzDodawanieProjektuSzczegoly
      v-if="rodzajFormularza === 'formularz_dodawanie_szczegolu_projektu'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
       :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />  
 
    <Usuwanie
      v-else-if="rodzajFormularza === 'usuwanie'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <PokazPDF
      v-else-if="rodzajFormularza === 'pokaz_PDF'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <PokazPDFINFO
      v-else-if="rodzajFormularza === 'pokaz_PDF_INFO'"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    
  </md-dialog>
</template>

<script>
import FormularzDodawaniePracownika from "./HR_pracownicy/formularz_dodawanie_pracownika";
import FormularzDodawanieAdresuZamieszkania from "./HR_pracownicy/formularz_dodawanie_adresu_zamieszkania";
import FormularzDodawanieAdresuKorespondencja from "./HR_pracownicy/formularz_dodawanie_adresu_korespondencja";
import FormularzDodawanieDanychZatrudnienia from "./HR_pracownicy/formularz_dodawanie_dane_zatrudnienia";
import FormularzEdycjiDanychZatrudnienia from "./HR_pracownicy/formularz_edycji_dane_zatrudnienia";
import FormularzDodawanieZakresu from "./HR_pracownicy/formularz_dodawanie_zakresu"
import FormularzDodawanieBadan from "./HR_pracownicy/formularz_dodawanie_badan"
import FormularzDodawanieZapoznaniaIO from "./HR_pracownicy/formularz_dodawanie_zapoznania_IO"
import FormularzDodawanieZapoznaniaIS from "./HR_pracownicy/formularz_dodawanie_zapoznania_IS"
import FormularzDodawanieZapoznaniaIBHP from "./HR_pracownicy/formularz_dodawanie_zapoznania_IBHP"
import FormularzDodawanieZapoznaniaORZ from "./HR_pracownicy/formularz_dodawanie_zapoznania_ORZ"
import FormularzDodawanieKompetencji from "./HR_pracownicy/formularz_dodawanie_kompetencji" 
import FormularzDodawanieHistoriiZatrudnienia from "./HR_pracownicy/formularz_dodawanie_historii_zatrudnienia" 
import FormularzDodawanieAbsencji from "./HR_absencja/formularz_dodawanie_absencji" 

import FormularzSodawanieSWS from "./HR_pracownicy/formularz_dodawanie_SWS"
import FormularzDodawanieSS from "./HR_pracownicy/formularz_dodawanie_SS"
import FormularzDodawanieSzkoleniaBHP from "./HR_pracownicy/formularz_dodawanie_szkolenia_BHP"
import FormularzDodawanieStanowiska from "./HR_stanowiska/formularz_dodawanie_stanowiska"
import FormularzDodawanieStanowiskaPrzeglad from "./HR_stanowiska/formularz_dodawanie_przegladu_stanowiska"
import FormularzDodawaniePlanuSzkolen from "./HR_szkolenia/formularz_dodawanie_planu_szkolen"
import FormularzDodawanieSzkolenia from "./HR_szkolenia/formularz_dodawanie_szkolenia"
import FormularzDodawanieRealizacji from "./HR_szkolenia/formularz_dodawanie_realizacji" 
import FormularzDodawanieUczestnika from "./HR_szkolenia/formularz_dodawanie_uczestnika"

import FormularzDodawanieSkierowania from "./HR_badania/formularz_dodawanie_skierowania"

import FormularzDodawanieMedycynyPracy from "./HR_medycyna_pracy/formularz_dodawanie_medycyny_pracy"

import FormularzSumowaniaAbsencji from "./HR_absencja/formularz_sumowania_absencji" 

import FormularzDodawanieZakresuOOIU from "./HR_zakresy/formularz_dodawanie_zakresu_obowiazkow" 
import FormularzDodawanieZakresuKatalog from "./HR_zakresy/formularz_dodawanie_zakresu_katalog.vue"

import FormularzDodawanieProjektu from "./SO_projektowanie/formularz_dodawanie_projektu.vue"
import FormularzDodawanieProjektuSzczegoly from "./SO_projektowanie/formularz_dodawanie_szczegolu_projektu.vue"

import Usuwanie from "./formularz_usuwanie";
import PokazPDF from "./formularz_pokaz_pdf";
import PokazPDFINFO from "./formularz_pokaz_pdf_INFO";

import FormularzLogowania from "./LOGOWANIE/formularz_logowania" 
import FormularzLogowaniaZmianaHasla from "./LOGOWANIE/formularz_logowanie_zmiana_hasla" 
import FormularzDodawanieUprawnien from "./LOGOWANIE/formularz_dodawanie_uzytkownika" 

export default {
  name: "modalHR",
  components: {
    FormularzDodawaniePracownika,
    FormularzDodawanieAdresuZamieszkania,
    FormularzDodawanieAdresuKorespondencja,
    FormularzDodawanieDanychZatrudnienia,
    FormularzEdycjiDanychZatrudnienia,
    FormularzDodawanieZakresu,
    FormularzDodawanieZakresuKatalog,
    FormularzDodawanieBadan,
    FormularzDodawanieZapoznaniaIO,
    FormularzDodawanieZapoznaniaIS,
    FormularzDodawanieZapoznaniaIBHP,
    FormularzDodawanieZapoznaniaORZ,
    FormularzDodawanieKompetencji,
    FormularzDodawanieHistoriiZatrudnienia,
    FormularzDodawanieAbsencji,
    FormularzDodawanieMedycynyPracy,

    FormularzSodawanieSWS,
    FormularzDodawanieSS,
    FormularzDodawanieSzkoleniaBHP,
    FormularzDodawanieStanowiska,
    FormularzDodawanieStanowiskaPrzeglad,
    FormularzDodawaniePlanuSzkolen,
    FormularzDodawanieSzkolenia,
    FormularzDodawanieRealizacji,
    FormularzDodawanieUczestnika,
    FormularzDodawanieSkierowania,
    FormularzSumowaniaAbsencji,
    FormularzDodawanieZakresuOOIU,
    FormularzLogowania,
    FormularzLogowaniaZmianaHasla,
    FormularzDodawanieUprawnien,
    FormularzDodawanieProjektu,
    FormularzDodawanieProjektuSzczegoly,

    Usuwanie,
    PokazPDF,
    PokazPDFINFO,
  },
  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    rodzajFormularza: {
      type: String,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
};
</script>



