<template>
  <div>
  <TabelaITPrzeglady />
  </div>
</template>

<script>
import TabelaITPrzeglady from '../../komponenty/strona-tabela-komponenty/WST_wyposazenie_IT/tabela_IT_przeglady.vue'


export default {
  name: 'StronaTabelaITPrzeglady',
  components: {
    TabelaITPrzeglady,
  }

}
</script>
