<template >
  <div>
  <TabelaWykazKontroliZewnetrznych />
  </div>
</template>

<script>
import TabelaWykazKontroliZewnetrznych from '../../komponenty/strona-tabela-komponenty/BHP_kontrole_zewnetrzne/tabela_wykaz_kontroli_zewnetrznych.vue'


export default {
  name: 'StronaTabelaWykazKontroliZewnetrznych',
  components: {
    TabelaWykazKontroliZewnetrznych,
    
  }

}
</script>

