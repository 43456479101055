<template>
  <div>
  <TabelaITGenerator />
  </div>
</template>

<script>
import TabelaITGenerator from '../../komponenty/strona-tabela-komponenty/WST_wyposazenie_IT/tabela_generator.vue'


export default {
  name: 'StronaTabelaITGenerator',
  components: {
    TabelaITGenerator,
  }

}
</script>
