<template>
  <div class="Form_AS_Monitorowanie">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI WYMAGAŃ PRAWNYCH LUB INNYCH DLA ASPEKTU ŚRODOWISKOWEGO"
                : "FORMULARZ DODAWANIA ZAMÓWIENIA"
            }}
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('Id_procesu')">
                <label for="Id_procesu">Wybór procesu</label>

                <md-select
                  v-model="form.Id_procesu"
                  :disabled="sending || czyJestWTrybieEdycji"
                  name="Id_procesu"
                  id="Id_procesu"
                  autocomplete="Id_procesu"
                  md-dense
                  @input="pobierzAspekt()"
                >
                  <md-option
                    v-for="(proces, index) in Procesy"
                    :key="index"
                    :value="proces.Id"
                    >{{
                      ` ${proces.Nr_procesu} - ${proces.Nazwa_procesu} `
                    }}</md-option
                  >
                </md-select>
                <span class="md-error" v-if="!$v.form.Id_procesu.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
             
              <md-autocomplete :class="getValidationClass('Nazwa_Id_aspekt')"
                v-model="form.Nazwa_Id_aspekt"
                :disabled="sending || czyJestWTrybieEdycji"
                :md-options="aspektWartosc"
              >
                <label>Wybór aspektu środowiskowego</label> 
                <span class="md-error" v-if="!$v.form.Nazwa_Id_aspekt.required"
                >Pole jest wymagane</span
              >
              </md-autocomplete>
             
         
            </div>
          </div>

          <div
            style="
              margin-top: 40px;
              margin-left: 15px;
              font-weight: bold;
              font-size: 16px;
            "
          >
            IDENTYFIKACJA SPOSOBU MONITOROWANIA ASPEKTU ŚRODOWISKOWEGO
          </div>

          <div
            v-for="(wiersz, index) in form.wiersze"
            :key="index"
            :set="(v = $v.form.wiersze.$each[index])"
          >
            <div
              v-if="!czyJestWTrybieEdycji"
              style="
                color: blue;
                font-weight: bold;
                font-size: 1.5vh;
                margin-top: 10px;
                margin-left: 25px;
              "
            >
              {{ `POZYCJA NR: ${index + 1} ` }}
              <b-icon-plus-square
                class="ikony"
                font-scale="1.7"
                style="border: 1px black"
                color="black"
                @click="dodajWiersz()"
              >
              </b-icon-plus-square>

              <b-icon-trash
                class="ikony"
                font-scale="1.7"
                color="black"
                @click="usunWiersz(wiersz)"
              >
              </b-icon-trash>
            </div>

            <div class="md-layout md-gutter">
              <div class="md-layout-item md-small-size-100">
                <md-field
                  :class="getValidationClass('Id_monitorowania', index)"
                >
                  <label for="Id_monitorowania"
                    >Wybór sposobu monitorowania</label
                  >

                  <md-select
                    v-model="wiersz.Id_monitorowania"
                    :disabled="sending"
                    name="Id_monitorowania"
                    autocomplete="Id_monitorowania"
                    md-dense
                  >
                    <md-option
                      v-for="(monitorowanie, index) in Monitorowania"
                      :key="index"
                      :value="monitorowanie.Id"
                      >{{ monitorowanie.Sposob_monitorowania }}
                    </md-option>
                  </md-select>
                  <span class="md-error" v-if="!v.Id_monitorowania.required"
                    >Pole jest wymagane</span
                  >
                </md-field>
              </div>
            </div>
          </div>
        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

        <div style="text-align: center">
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            czyJestWTrybieEdycji
              ? "ZATWIERDŹ - EDYTOWANY WPIS"
              : "ZATWIERDŹ - UTWÓRZ WPIS"
          }}</md-button>
        </div>
      </md-card>
    </form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { api } from "./../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_faktury",
  mixins: [validationMixin],
  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      Id_procesu: null,
      Nazwa_Id_aspekt: null,
      wiersze: [
        {
          Id_monitorowania: null,
        },
      ],
    },
    IdEdytowanegoElementu: null,
    userSaved: false,
    sending: false,
    lastUser: null,
    Procesy: [],
    Aspekty: [],
    Monitorowania: [],
  }),
  async created() {
    try {
      const [odpowiedz_procesy, odpowiedz_monitorowania] = await Promise.all([
        api.pobierzProces(),
        api.pobierzMonitorowanie(),
      ]);
      odpowiedz_procesy,
        odpowiedz_monitorowania,
        (this.Procesy = odpowiedz_procesy.data.dane);
      this.Monitorowania = odpowiedz_monitorowania.data.dane;

      if (this.obiektKonfiguracyjny.akcja === "edycja") {
        //edycja
        const odpowiedz = await api.pobieranieMonitorowaniaAspektyEdycja(
          this.obiektKonfiguracyjny.Id
        );

        const {
          Id,
          Id_procesu,
          Id_aspekt,
          Id_monitorowania,
        } = odpowiedz.data.dane[0];

        this.IdEdytowanegoElementu = Id;
        this.form.Id_procesu = Id_procesu;

        this.pobierzAspekt().then(() => {
          this.form.Nazwa_Id_aspekt = this.Aspekty.find(
            ({ Id }) => Id == Id_aspekt
          ).Aspekt;
        });

        this.form.wiersze[0].Id_monitorowania = Id_monitorowania;
      }
    } catch (error) {
      console.log(error);
    }
  },

  validations: {
    form: {
      Id_procesu: {
        required,
      },
      Nazwa_Id_aspekt: {
        required,
      },
      wiersze: {
        $each: {
          Id_monitorowania: {
            required,
          },
        },
      },
    },
  },

  computed: {
    Id_aspekt: function() {
      const { Nazwa_Id_aspekt } = this.form;
      const znalezionyElement = this.Aspekty.find(
        ({ Aspekt }) => Aspekt === Nazwa_Id_aspekt
      );

      if (znalezionyElement) {
        return znalezionyElement.Id;
      }
      return null;
    },
    aspektWartosc: function() {
      return this.Aspekty.map((el) => el.Aspekt);
    },
    czyJestWTrybieEdycji: function() {
      return this.obiektKonfiguracyjny.akcja === "edycja";
    },
  },

  methods: {
    pobierzAspekt() {
      const { Id_procesu } = this.form;
      return api.pobierzAspekty(Id_procesu).then((Aspekty) => {
        this.Aspekty = Aspekty.data.dane;
      });
    },

    dodajWiersz() {
      console.log("robi");
      const nowyWiersz = {};
      Object.keys(this.form.wiersze[0]).forEach((key) => {
        nowyWiersz[key] = null;
      });
      const noweWiersze = [...this.form.wiersze, nowyWiersz];
      this.form.wiersze = noweWiersze;
    },
    usunWiersz(wiersz) {
      if (this.form.wiersze.length <= 1) {
        const nowyWiersz = {};
        Object.keys(this.form.wiersze[0]).forEach((key) => {
          nowyWiersz[key] = null;
        });
        this.form.wiersze = [nowyWiersz];
        return;
      }

      this.form.wiersze = this.form.wiersze.filter((el) => el !== wiersz);
    },

    getValidationClass(fieldName, index) {
      const field =
        this.$v.form[fieldName] || this.$v.form.wiersze.$each[index][fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.Id_procesu = null;
      this.form.Id_aspekt = null;
      this.form.wiersze = [];
    },

    async zapiszFormularz() {
      this.sending = true;

      if (this.czyJestWTrybieEdycji) {
        const daneDoWyslania = {
          Id_procesu: this.form.Id_procesu,
          Id_aspekt: this.Id_aspekt,
          PozycjeZapytania: this.form.wiersze,
        };

        api
          .zapiszMonitorowanieAspektyUpdate(
            daneDoWyslania,
            this.IdEdytowanegoElementu
          )
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const daneDoWyslania = {
          Id_procesu: this.form.Id_procesu,
          Id_aspekt: this.Id_aspekt,
          PozycjeZapytania: this.form.wiersze,
        };

        api
          .zapiszMonitorowanieDoAspektu(daneDoWyslania)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    naszWalidacjaFormularza() {
      if (this.czyJestWTrybieEdycji) {
        return (
          !this.$v.form.Id_procesu.$invalid &&
          !this.$v.form.Nazwa_Id_aspekt.$invalid &&
          !this.$v.form.wiersze.$invalid
        );
      } else {
        return !this.$v.$invalid;
      }
    },
    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>
<style>
.Form_AS_Monitorowanie .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.Form_AS_Monitorowanie .md-layout .md-gutter {
  min-width: 73vw !important; /* szerokość modala*/
  max-width: 100% !important;
  min-height: 7vh !important; /* wysokość pomiędzy mierszami w modalu*/
  margin-right: 20px !important;
}
.md-layout {
  padding-right: 0px !important;
}
.Form_AS_Monitorowanie .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}

.Form_AS_Monitorowanie .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  margin: 10px 24px !important;
  padding-top: 35px !important;
}

.Form_AS_Monitorowanie .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}

.Form_AS_Monitorowanie {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
  overflow: auto;
}
.Form_AS_Monitorowanie .md-primary {
  border: 1px solid !important;
  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}
.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important; /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}
.md-layout .md-gutter {
  min-width: 1100px !important;
}
.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}
.md-list-item-text {
  font-size: 1.7vmin !important;
}
.Form_AS_Monitorowanie .md-card-actions .md-button {
  margin-right: 50px !important;
}

.Form_AS_Monitorowanie .ikony {
  cursor: pointer;
  margin-right: 5px;
  margin-left: 15px;
}
.md-field.md-has-textarea:not(.md-autogrow):after,
.md-field.md-has-textarea:not(.md-autogrow):before {
  border: none !important;
  border-bottom: 0.5px solid !important;
  height: 90px !important;
  font-size: 12px !important;
}
.md-field.md-has-textarea:not(.md-autogrow) .md-textarea {
  min-height: 40px !important;
  padding: 0 0px !important;
  resize: vertical !important;
}
.md-input {
  width: 100% !important;
}
.md-menu-content-bottom-start.md-menu-content-small.md-menu-content.md-theme-default {
  z-index: 300;
  color: black;
}
</style>
