<template>
  <div>
  <TabelaPomiarowkaWykazSprawdzen />
  </div>
</template>

<script>
import TabelaPomiarowkaWykazSprawdzen from '../../komponenty/strona-tabela-komponenty/WST_generator_sprawdzen/tabela_wykaz_sprawdzen.vue'


export default {
  name: 'StronaTabelaPomiarowkaWykazSprawdzen',
  components: {
    TabelaPomiarowkaWykazSprawdzen,
  }

}
</script>
