<template>
  <div class="tabela_granice">
    <div class="menu_strony">
      <button></button>
    </div>

    <modal
      v-if="pokazDialog"
      :rodzajFormularza="typAktywnegoFormularza"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <modalKontekst
      v-if="pokazDialog"
      :rodzajFormularza="typAktywnegoFormularza"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />
    <md-table
      md-height="53vh"
      @md-selected="wyborElementu"
      v-model="searched"
      md-model-id="Id_maszyny"
      md-card
      md-fixed-header
    >
      <md-table-toolbar>
        <div class="d-flex flex-column w-100">
          <div class="row">
            <div class="col-12">
              <h1 class="md-title">
                GRANICE CERTYFIKACJI DLA WYBRANEJ LOKALIZACJI
              </h1>
            </div>
          </div>

          <div class="PasekWyboru" v-if="wybranyElementTabeli">
            <div
              style="text-align: right; margin-top: 0.3vh; margin-bottom: 0.3vh"
            >
              <b>
                <b-button
                  :disabled="!autoryzacja.czyMozeUsuwac"
                  @click="usuwanieElementu(wybranyElementTabeli.Id)"
                >
                  <b-icon-trash class="ikony" font-scale="1.8" color="white">
                  </b-icon-trash
                ></b-button>
                <md-tooltip class="podpowiedz" md-direction="top">{{
                  autoryzacja.czyMozeUsuwac
                    ? "USUWANIE ELEMENTU"
                    : "BRAK UPRAWNIEŃ"
                }}</md-tooltip>
              </b>
              <b>
                <b-button
                  :disabled="!autoryzacja.czyMozeEdytowac"
                  @click="
                    pokazDialog = true;
                    typAktywnegoFormularza = 'formularz_dodawanie_granic';
                    obiektKonfiguracyjny = {
                      Id: wybranyElementTabeli.Id,
                      akcja: 'edycja',
                    };
                  "
                >
                  <b-icon-pencil-square
                    class="ikony"
                    font-scale="1.80"
                    color="white"
                  >
                  </b-icon-pencil-square>
                </b-button>
                <md-tooltip class="podpowiedz" md-direction="top">{{
                  autoryzacja.czyMozeUsuwac
                    ? "EDYCJA ELEMENTU"
                    : "BRAK UPRAWNIEŃ"
                }}</md-tooltip>
              </b>
            </div>
          </div>
        </div>
      </md-table-toolbar>

      <md-table-empty-state
        v-if="czyPobranoDane"
        md-label="Nie posiadasz wpisu dotyczącego granic dla zakresu certyfikacji w wybranej lokalizacji"
      >
        <md-button
          class="md-primary md-raised"
          :disabled="!autoryzacja.czyMozeDodawac"
          @click="
            pokazDialog = true;
            typAktywnegoFormularza = 'formularz_dodawanie_granic';
            obiektKonfiguracyjny = {
              Id: null,
              akcja: 'dodawanie',
            };
          "
          >Dodawanie granic dla zakresu certyfikacji w lokalizacji</md-button
        >
        <md-tooltip class="podpowiedz" md-direction="top">{{
          !autoryzacja.czyMozeDodawac
            ? "BRAK UPRAWNIEŃ"
            : "DODAWANIE GRANIC DLA ZAKRESU CERTYFIKACJI W LOKALIZACJI"
        }}</md-tooltip>
      </md-table-empty-state>

      <md-table-row
        slot="md-table-row"
        slot-scope="{ item }"
        md-selectable="single"
        :key="item.id"
      >
        <md-table-cell md-label="Nazwa_lokalizacji">
          {{ item.Nazwa_lokalizacji }}
        </md-table-cell>

        <md-table-cell md-label="Granice">
          {{ item.Granice }}
        </md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
import modal from "../../strona-formularze/modal.vue";
import modalKontekst from "../../strona-formularze/modalKontekst.vue";
import { authorizationService } from "./../../../serwisy/authorization.servis";
import { api } from "./../../../serwisy/api.serwis";

export default {
  name: "tabela_elektronarzedzia",
  data: () => ({
    czyPobranoDane: false,
    search: null,
    searched: [],
    Maszyny: [],
    pokazDialog: false,
    typAktywnegoFormularza: null,
    obiektKonfiguracyjny: null,
    wybranyElementTabeli: null,
    autoryzacja: {
      czyMozeDodawac: false,
      czyMozeUsuwac: false,
      czyMozeEdytowac: false,
      czyMozePrzegladac: false,
    },
  }),
  components: {
    modal,
    modalKontekst,
  },

  mounted: function() {
    this.pobierzDaneDoTabeli();
    this.pobierzDaneAutoryzacyjne();
  },
  computed: {
    //informacja czy jest w trybie edycji
    czyJestWTrybieEdycji() {
      return this.$router.params.Id ? true : false;
    },
  },
  methods: {
    pobierzDaneAutoryzacyjne() {
      authorizationService
        .getInfo("StronaTabelaGranice", 4)

        .then((obiektOdpowiedzi) => {
          const dane = obiektOdpowiedzi.data.dostep;
          this.autoryzacja = {
            czyMozeDodawac: Boolean(
              dane.find(({ Id_operacji }) => Id_operacji == "1")
            ),
            czyMozeUsuwac: Boolean(
              dane.find(({ Id_operacji }) => Id_operacji == "2")
            ),
            czyMozeEdytowac: Boolean(
              dane.find(({ Id_operacji }) => Id_operacji == "3")
            ),
            czyMozePrzegladac: Boolean(
              dane.find(({ Id_operacji }) => Id_operacji == "4")
            ),
          };
        })
        .catch((error) => {
          console.log(error);
        });
    },
    pobierzDaneDoTabeli() {
      const { id } = this.$route.params;

      console.log(this.$route);
      api
        .pobieranieGranice(id)
        .then((obiektOdpowiedzi) => {
          console.log(obiektOdpowiedzi);
          this.Maszyny = obiektOdpowiedzi.data.dane;
          this.searched = this.Maszyny;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.czyPobranoDane = true;
        });
    },
    funkcjaZamykajacaDialog(sukces) {
      this.pokazDialog = false;
      this.typAktywnegoFormularza = null;
      this.obiektKonfiguracyjny = null;
      if (sukces) {
        this.pobierzDaneDoTabeli();
      }
    },
    newUser() {
      window.alert("Noop");
    },

    usuwanieElementu(Id) {
      this.pokazDialog = true;
      this.typAktywnegoFormularza = "usuwanie";
      this.obiektKonfiguracyjny = {
        Id,
        typ: "granice_kontekst",
      };
    },

    wyborElementu(element) {
      this.wybranyElementTabeli = element;
    },
  },
};
</script>

<style>
.tabela_granice .md-field {
  max-width: 300px;
}
.tabela_granice .tabela {
  width: 100%;
}
.tabela_granice .md-card {
  box-shadow: none !important;
}
.tabela_granice .md-title {
  font-weight: 900 !important;
  color: black !important;
  font-size: 1.4vh !important;
  padding: 10px;
}
.tabela_granice .md-toolbar {
  height: 14vh;
}
.tabela_granice .md-table-head {
  font-weight: bold;
  color: black !important;
  font-size: 1.2vh !important;
  text-align: center !important;
}
.tabela_granice .md-table-head-label {
  text-align: center !important;
  font-size: 1.2vh !important;
}
.tabela_granice .md-table-cell {
  font-size: 1.2vh !important;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-align: center;
  color: black;
  font-weight: 300;
  margin-top: 200px !important;
}
.tabela_granice .ikony {
  cursor: pointer;
  margin-right: 40px;
}

.tabela_granice .md-table-cell:nth-child(1) {
  width: 45vw;
  text-align: center !important;
}
.tabela_granice .md-table-cell:nth-child(2) {
  width: 45vw;
  text-align: center !important;
}

.tabela_granice .button {
  margin-right: 5px;
  border: 3px white ridge;
  font-weight: bold;
  font-size: 1.1vmin;
  min-width: 9vw;
}

.tabela_granice .button:hover {
  background-color: #727171;
  text-decoration: none;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  color: white;
}
.tabela_granice .menu_strony {
  border: 1px solid;
  margin-top: 5px;
  padding: 3px;
  background: #939393;
}
.md-table.md-theme-default .md-table-row.md-selected,
.md-table.md-theme-default .md-table-row.md-selected-single {
  background-color: #dddddd !important;
}
.PasekWyboru {
  background: #606060;
}
.tabela_granice .ikony {
  margin-right: 4px;
  margin-left: 4px;
  border: 1px solid !important;
  border-radius: 5px;
  padding: 5px;
}
.tabela_granice .ikony:hover {
  background-color: black;
  text-decoration: none;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
.podpowiedz {
  font-size: 1.5vmin !important;
  font-weight: bold;
  background-color: black !important;
}
.btn {
  background-color: #606060 !important;
  padding: 0rem 0rem !important;
  border-color: #606060 !important;
}
</style>
