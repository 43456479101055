<template >
  <div>
  <TabelaInstrukcjeSrodowiskowe />
  </div>
</template>

<script>
import TabelaInstrukcjeSrodowiskowe from '../../komponenty/strona-tabela-komponenty/SYS_instrukcje_srodowiskowe/tabela_instrukcje_srodowiskowe.vue'


export default {
  name: 'StronaTabelaInstrukcjeSrodowiskowe',
  components: {
    TabelaInstrukcjeSrodowiskowe,
    
  }

}
</script>

