<template>
  <div class="nawigacja">
    <md-menu md-size="medium" md-align-trigger :mdCloseOnClick="true">
      <b-icon-menu-button-wide-fill
        class="aaaa"
        md-menu-trigger
        font-scale="3.2"
        color="white"
      ></b-icon-menu-button-wide-fill>

      <md-menu-content>
        <md-menu-item v-for="(elementMenu, index) in elementy" :key="index">
          <router-link :to="elementMenu.sciezka">{{
            elementMenu.nazwa
          }}</router-link>
        </md-menu-item>
      </md-menu-content>
    </md-menu>

  </div>
</template>
<script>
import { elementyNawigacyjne } from "./elementyNawigacyjne";
export default {
  name: "Nawigacja",
  data: () => ({
    aktywneMenu: "menuGlowne",
  }),
  created() {
    const { pathname } = window.location;
    this.ustawManu(pathname);
  },
  watch: {
    $route: function(nowaSciezka) {
      this.ustawManu(nowaSciezka.path);
    },
  },
  methods: {
    ustawManu(sciezka) {
      setTimeout(() => {
        if (sciezka === "/") {
          this.aktywneMenu = "menuStartowe";
        } else if (sciezka === "/strona_glowna") {
          this.aktywneMenu = "menuGlowne";
        } else if (sciezka === "/strona_glowna_modul_SYS") {
          this.aktywneMenu = "menuSYS";

          //kontekst
        } else if (sciezka === "/kontekst_organizacji") {
          this.aktywneMenu = "menuSYSkontekst";
        } else if (sciezka.startsWith("/tabela_zakres")) {
          this.aktywneMenu = "menuSYSkontekstLokalizacja";
        } else if (sciezka.startsWith("/tabela_granice")) {
          this.aktywneMenu = "menuSYSkontekstLokalizacja1";

          //procesy
        } else if (sciezka === "/procesy") {
          this.aktywneMenu = "menuSYSprocesy";
        } else if (sciezka.startsWith("/procesy_przeglady")) {
          this.aktywneMenu = "menuSYSprocesyPrzeglady";
        } else if (sciezka.startsWith("/procesy_dane_wejsciowe_wyjsciowe")) {
          this.aktywneMenu = "menuSYSprocesySzczegolyProcesu";
        } else if (sciezka.startsWith("/procesy_rozliczenie_celu")) {
          this.aktywneMenu = "menuSYSprocesySzczegolyRozliczenieProces";

          //dokumentacja
        } else if (sciezka === "/dokumentacja") {
          this.aktywneMenu = "menuSYSdokumentacja";
        } else if (sciezka.startsWith("/dokumentacja_przeglady")) {
          this.aktywneMenu = "menuSYSdokumentacjaPrzeglady";

          //instrukcje operacyjne
        } else if (sciezka === "/instrukcje_operacyjne") {
          this.aktywneMenu = "menuSYSisntrukcjeoperacyjne";
        } else if (sciezka.startsWith("/instrukcje_operacyjne_przeglady")) {
          this.aktywneMenu = "menuSYSinstrukcjeoperacyjnePrzeglady";

          //instrukcje środowiskowe
        } else if (sciezka === "/instrukcje_srodowiskowe") {
          this.aktywneMenu = "menuSYSinstrukcjesrodowiskowe";
        } else if (sciezka.startsWith("/instrukcje_srodowiskowe_przeglady")) {
          this.aktywneMenu = "menuSYSinstrukcjesrodowiskowePrzeglady";

          //instrukcje BHP
        } else if (sciezka === "/instrukcje_BHP") {
          this.aktywneMenu = "menuSYSinstrukcjebhp";
        } else if (sciezka.startsWith("/instrukcje_BHP_przeglady")) {
          this.aktywneMenu = "menuSYSinstrukcjebhpPrzeglady";

          //ryzyka szanse
        } else if (sciezka === "/ryzyka_szanse") {
          this.aktywneMenu = "menuSYSRyzykaSzanse";
        } else if (sciezka === "/tabela_przeglad_ryzyka_szanse") {
          this.aktywneMenu = "menuSYSRyzykaSzansePrzeglady";

          //zagrozenia BHP
        } else if (sciezka === "/zagrozenia_bhp") {
          this.aktywneMenu = "menuSYSZagrozeniaBHP";
        } else if (sciezka === "/tabela_przeglad_zagrozenia_bhp") {
          this.aktywneMenu = "menuSYSZagrozeniaBHPPrzeglady";

          //Cele
        } else if (sciezka === "/cele_okres_realizacji") {
          this.aktywneMenu = "menuSYSCeleOkresy";
        } else if (sciezka.startsWith("/cele_przeglady")) {
          this.aktywneMenu = "menuSYSCelePrzeglady";
        } else if (sciezka.startsWith("/cele_szczegolowe")) {
          this.aktywneMenu = "menuSYSCeleSzczegolowe";
        } else if (sciezka.startsWith("/zadania_cele_szczegolowe")) {
          this.aktywneMenu = "menuSYSCeleZadaniaDlaCelow";

          //wymagania Prawne
        } else if (sciezka === "/wymagania_prawne") {
          this.aktywneMenu = "menuSYSWymaganiaPrawne";
        } else if (sciezka === "/tabela_przeglad_wymagania_prawne") {
          this.aktywneMenu = "menuSYSWymaganiaPrawnePrzeglady";

          //aspekty środowiskowe
        } else if (sciezka === "/aspekty") {
          this.aktywneMenu = "menuSYSAspektySrodowiskowe";
        } else if (sciezka === "/aspekty_przeglady") {
          this.aktywneMenu = "menuSYSPrzegladyAspektySrodowiskowe";

          //Działania audit
        } else if (sciezka === "/dzialania_audit") {
          this.aktywneMenu = "menuSYSDzaialaniaAudit";
        } else if (sciezka.startsWith("/dzialania_audit_ocena")) {
          this.aktywneMenu = "menuSYSDzaialaniaAuditOcena";
        } else if (sciezka.startsWith("/dzialania_audit_ocena_skutecznosci")) {
          this.aktywneMenu = "menuSYSDzaialaniaAuditOcenaSkutecznosci";

          //Działania poza auditowe
        } else if (sciezka === "/dzialania_poza_auditowe") {
          this.aktywneMenu = "menuSYSDzaialaniaPozaAudit";
        } else if (sciezka.startsWith("/dzialania_poza_audit_ocena")) {
          this.aktywneMenu = "menuSYSDzaialaniaPozaAuditOcena";
        } else if (
          sciezka.startsWith("/dzialania_poza_audit_ocena_skutecznosci")
        ) {
          this.aktywneMenu = "menuSYSDzaialaniaPozaAuditOcenaSkutecznosci";

          //Sprawozdawczosc srodowiskowa
        } else if (sciezka === "/sprawozdawczosc_srodowiskowa") {
          this.aktywneMenu = "menuSYSSprawozdawczoscSrodowiskowa";

          //Zaodowolenie klienta
        } else if (sciezka === "/zadowolenie_klienta") {
          this.aktywneMenu = "menuSYSZadowolenieKlienta";

          //Audity wewnętrzne
        } else if (sciezka === "/audity") {
          this.aktywneMenu = "menuSYSAudityWewnetrzne";
        } else if (sciezka.startsWith("/audity_raporty")) {
          this.aktywneMenu = "menuSYSAudityWewnetrzneRaporty";
        } else if (sciezka.startsWith("/audity_raport/dodawanie")) {
          this.aktywneMenu = "menuSYSAudityWewnetrzneRaportyDodawanie";
        } else if (sciezka.startsWith("/audity_raport/edycja")) {
          this.aktywneMenu = "menuSYSAudityWewnetrzneRaportyDodawanieEdycja";
        } else if (sciezka.startsWith("/audity_kompetencje")) {
          this.aktywneMenu = "menuSYSAudityWewnetrzneKompetencje";
        } else if (sciezka.startsWith("/audity_plan")) {
          this.aktywneMenu = "menuSYSAudityPlan";

          //Przeglad zarządzania
        } else if (sciezka === "/przeglad_zarzadzania") {
          this.aktywneMenu = "menuSYSPrzegladZarzadzania";
        } else if (sciezka.startsWith("/przeglad_zarzadzania_protokol")) {
          this.aktywneMenu = "menuSYSPrzegladZarzadzaniaProtokol";

          //MODUŁ BHP
        } else if (sciezka === "/strona_glowna_modul_BHP") {
          this.aktywneMenu = "menuBHP";

          //orz
        } else if (sciezka === "/dokumentacja_ORZ") {
          this.aktywneMenu = "menuBHPORZ";
        } else if (sciezka.startsWith("/ORZ_dokumentacja_przeglady")) {
          this.aktywneMenu = "menuBHPORZszczegoly";

          //BSP
        } else if (sciezka === "/dokumentacja_BSP") {
          this.aktywneMenu = "menuBHPBSP";
        } else if (sciezka.startsWith("/BSP_dokumentacja_szczegoly")) {
          this.aktywneMenu = "menuBHPBSPszczegoly";

          //SN
        } else if (sciezka === "/dokumentacja_substancje_niebezpieczne") {
          this.aktywneMenu = "menuBHPSN";
        } else if (
          sciezka.startsWith("/substancje_niebezpieczne_dokumentacja_przeglady")
        ) {
          this.aktywneMenu = "menuBHPSNszczegoly";

          //OSB
        } else if (sciezka === "/ocena_stanu_BHP") {
          this.aktywneMenu = "menuBHPOSB";

          //WYPADKI
        } else if (sciezka === "/wypadki_w_pracy") {
          this.aktywneMenu = "menuBHPWYPADKI";
        } else if (sciezka.startsWith("/dzialania_wypadki_w_pracy")) {
          this.aktywneMenu = "menuBHPWYPDZIAŁANIA";
        } else if (
          sciezka.startsWith("/ocena_skutecznosci_dzialania_wypadki_w_pracy")
        ) {
          this.aktywneMenu = "menuBHPWYPDZIAŁANIAOCENA";

          //CHOROBY
        } else if (sciezka === "/choroby_zawodowe") {
          this.aktywneMenu = "menuBHPCHOROBY";
        } else if (sciezka.startsWith("/skutki_choroby_zawodowe_dzialania")) {
          this.aktywneMenu = "menuBHPCHORDZIAŁANIA";

          //SYTUACJE POTENCJALNIE NIEBEZPIECZNE
        } else if (sciezka === "/sytuacje_potencjalnie_niebezpieczne") {
          this.aktywneMenu = "menuBHPZPN";
        } else if (
          sciezka.startsWith("/sytuacje_potencjalnie_niebezpieczne_dzialania")
        ) {
          this.aktywneMenu = "menuBHPZPNDZIAŁANIA";
        } else if (
          sciezka.startsWith(
            "/ocena_sytuacje_potencjalnie_niebezpieczne_dzialania"
          )
        ) {
          this.aktywneMenu = "menuBHPZPNDZIAŁANIAOCENA";

          //KONTROLE WEWNĘTRZNE
        } else if (sciezka === "/wykaz_kontroli_wewnetrznych") {
          this.aktywneMenu = "menuBHKW";
        } else if (
          sciezka.startsWith("/wykaz_kontroli_wewnetrznych_realizacja_uwag")
        ) {
          this.aktywneMenu = "menuBHPKWDZIAŁANIA";
        } else if (
          sciezka.startsWith(
            "/ocena_wykaz_kontroli_wewnetrznych_realizacja_uwag"
          )
        ) {
          this.aktywneMenu = "menuBHPKWOCENA";

          //KONTROLE ZEWNĘTRZNE
        } else if (sciezka === "/wykaz_kontroli_zewnetrznych") {
          this.aktywneMenu = "menuBHKZ";
        } else if (
          sciezka.startsWith("/wykaz_kontroli_zewnetrznych_realizacja_uwag")
        ) {
          this.aktywneMenu = "menuBHPKZDZIAŁANIA";
        } else if (
          sciezka.startsWith(
            "/ocena_uwai_wykaz_kontroli_zewnetrznych_realizacja"
          )
        ) {
          this.aktywneMenu = "menuBHPKZOCENA";

          //PRACE SZCZEGÓLNIE NIEBEZPIECZNE
        } else if (
          sciezka === "/dokumentacja_prace_szczegolnie_niebezpieczne"
        ) {
          this.aktywneMenu = "menuBHPWPSN";
        } else if (
          sciezka.startsWith(
            "/przeglady_dokumentacja_prace_szczegolnie_niebezpieczne"
          )
        ) {
          this.aktywneMenu = "menuBHPWPSNszczegoly";

          //MODUŁ FAK
        } else if (sciezka === "/strona_glowna_modul_DOP") {
          this.aktywneMenu = "menuFAK";

          //kontrahenci
        } else if (sciezka === "/tabela_kontrahenci") {
          this.aktywneMenu = "menuFAKkontrahenci";
        } else if (sciezka.startsWith("/tabela_kontrahenci_kontakt")) {
          this.aktywneMenu = "menuFAKkontrahenciKontakt";

          //Wyroby
        } else if (sciezka === "/wykaz_wyrobow") {
          this.aktywneMenu = "menuFAKWyroby";
        } else if (sciezka.startsWith("/grupy_wyrobow")) {
          this.aktywneMenu = "menuFAKGrupyWyrobow";

          //Zakupy
        } else if (sciezka === "/kwalifikowanie_dostawcy") {
          this.aktywneMenu = "menuFAKKwalifikowanieDostawcow";
        } else if (sciezka.startsWith("/zakupy")) {
          this.aktywneMenu = "menuFAKZakupy";
        } else if (sciezka.startsWith("/kwalifikowanie_dostawcy_przeglad")) {
          this.aktywneMenu = "menuFAKPrzegladyZakupy";

          //Projektowanie
        } else if (sciezka === "/wykaz_projektow") {
          this.aktywneMenu = "menuFAKProjektowania";
        } else if (sciezka.startsWith("/wykaz_projektow_szczegoly")) {
          this.aktywneMenu = "menuFAKSzczegolyProjektowania";
          
          //Zzapytania ofertowe
        } else if (sciezka === "/zapytania_ofertowe") {
          this.aktywneMenu = "menuFAKWszystkieZapytaniaOfertowe";
        } else if (sciezka.startsWith("/zapytania_ofertowe_przyjete")) {
          this.aktywneMenu = "menuFAKPrzyjeteZapytaniaOfertowe";
        } else if (sciezka.startsWith("/zapytania_ofertowe_odrzucone")) {
          this.aktywneMenu = "menuFAKOdrzuconeZapytaniaOfertowe";

          //Oferty
        } else if (sciezka === "/oferty") {
          this.aktywneMenu = "menuFAKWszystkieOferty";
        } else if (sciezka.startsWith("/oferty_przyjete")) {
          this.aktywneMenu = "menuFAKPrzyjeteOferty";
        } else if (sciezka.startsWith("/oferty_odrzucone")) {
          this.aktywneMenu = "menuFAKOdrzuconeOferty";

          //Zamówienia
        } else if (sciezka === "/zamowienia") {
          this.aktywneMenu = "menuFAKZamowienia";

          //Realizacja do zamówienia
        } else if (sciezka === "/pr1_raport_produkcyjny") {
          this.aktywneMenu = "menuFAKWTrakcieProdukcjaZamowienia";
        } else if (sciezka.startsWith("/pr3_raport_produkcyjny_zakonczone")) {
          this.aktywneMenu = "menuFAKZakonczoneProdukcjaZamowienia";
        } else if (sciezka.startsWith("/pr2_raporty_produkcyjne")) {
          this.aktywneMenu = "menuFAKRaportyProdukcyjne";
        } else if (sciezka.startsWith("/pr4_raporty_produkcyjne_zakonczone")) {
          this.aktywneMenu = "menuFAKRaportyZakonczoneProdukcyjne";


         //Zlecenia wewnetrzne
        } else if (sciezka === "/rp5_raport_produkcyjny_magazyn") {
          this.aktywneMenu = "menuFAKWTrakcieZleceniaWewnetrzne";
        } else if (sciezka.startsWith("/rp7_raport_produkcyjny_zakonczone_magazyn")) {
          this.aktywneMenu = "menuFAKZakonczoneZleceniaWewnetrzne"; 
        } else if (sciezka.startsWith("/rp6_raporty_produkcyjne_magazyn")) {
          this.aktywneMenu = "menuFAKMagazynRaportyProdukcyjne";
        } else if (sciezka.startsWith("/rp8_raporty_produkcyjne_magazyn_zakonczone")) {
          this.aktywneMenu = "menuFAKMagazynRaportyZakonczoneProdukcyjne";

        
        //Dzialania operacyjne
        } else if (sciezka === "/dzialania_operacyjne") {
          this.aktywneMenu = "menuFAKDzialaniaOperacyjne";


         //Wydanie z magazynu
        } else if (sciezka === "/realizacja_WZ_magazyn") {
          this.aktywneMenu = "menuFAKWydanieZMagazynu";


          //Zlecenia wewnetrzne
        } else if (sciezka === "/zlecenie_magazyn") {
          this.aktywneMenu = "menuFAKZleceniaWewnetrzne";

         //Zlecenia wewnetrzne RW
        } else if (sciezka === "/realizacja_WZ_magazyn") {
          this.aktywneMenu = "menuFAKRWZleceniaWewnetrzne";
        
        //Reklamacje
        } else if (sciezka === "/tabela_reklamacje") {
          this.aktywneMenu = "menuFAKReklamacjeWykaz";
        } else if (sciezka.startsWith("/tabela_reklamacje_dzialania")) {
          this.aktywneMenu = "menuFAKReklamacjeDzialania";

          //MODUŁ WST
        } else if (sciezka === "/strona_glowna_modul_WST") {
          this.aktywneMenu = "menuWST";
          //maszyny
        } else if (sciezka === "/maszyny_i_urzadzenia") {
          this.aktywneMenu = "menuWSTmaszyny";
        } else if (sciezka.startsWith("/tabela_wykaz_przeglady_maszyn")) {
          this.aktywneMenu = "menuWSTmaszynyPrzeglady";
        } else if (sciezka.startsWith("/tabela_wykaz_ubezpieczen_maszyn")) {
          this.aktywneMenu = "menuWSTmaszynyUbezpieczenia";
        } else if (sciezka.startsWith("/tabela_maszyny_paliwo")) {
          this.aktywneMenu = "menuWSTmaszynyPaliwo";
        } else if (sciezka.startsWith("/tabela_wykaz_kosztow_maszyn")) {
          this.aktywneMenu = "menuWSTmaszynyKoszty";
        } else if (sciezka.startsWith("/generator_przegladow_maszyn")) {
          this.aktywneMenu = "menuWSTGeneratorMaszyny";

          //maszyny dozorowe
        } else if (sciezka === "/maszyny_i_urzadzenia_dozorowe") {
          this.aktywneMenu = "menuWSTmaszynydozorowe";
        } else if (
          sciezka.startsWith("/maszyny_i_urzadzenia_dozorowe_przeglady")
        ) {
          this.aktywneMenu = "menuWSTmaszynyPrzegladyDozorowe";
        } else if (
          sciezka.startsWith("/maszyny_i_urzadzenia_dozorowe_ubezpieczenia")
        ) {
          this.aktywneMenu = "menuWSTmaszynyUbezpieczeniaDozorowe";
        } else if (
          sciezka.startsWith("/maszyny_i_urzadzenia_dozorowe_paliwo")
        ) {
          this.aktywneMenu = "menuWSTmaszynyDozorowePaliwa";
        } else if (
          sciezka.startsWith("/maszyny_i_urzadzenia_dozorowe_koszty")
        ) {
          this.aktywneMenu = "menuWSTmaszynyDozoroweKoszty";

          //samochody osobowe
        } else if (sciezka === "/samochody_osobowe") {
          this.aktywneMenu = "menuWSTsamochodyosobowe";
        } else if (
          sciezka.startsWith("/tabela_wykaz_przeglady_samochody_osobowe")
        ) {
          this.aktywneMenu = "menuWSTsamoachodyosobowePrzeglady";
        } else if (
          sciezka.startsWith("/tabela_wykaz_ubezpieczen_samochody_osobowe")
        ) {
          this.aktywneMenu = "menuWSTsamoachodyosoboweUbezpieczenia";
        } else if (sciezka.startsWith("/tabela_samochody_osobowe_paliwo")) {
          this.aktywneMenu = "menuWSTsamoachodyosobowePaliwo";
        } else if (
          sciezka.startsWith("/tabela_wykaz_kosztow_samochody_osobowe")
        ) {
          this.aktywneMenu = "menuWSTsamoachodyosoboweKoszty";

          //samochody ciezarowe
        } else if (sciezka === "/samochody_ciezarowe") {
          this.aktywneMenu = "menuWSTsamochodyciezarowe";
        } else if (
          sciezka.startsWith("/tabela_wykaz_przeglady_samochody_ciezarowe")
        ) {
          this.aktywneMenu = "menuWSTsamoachodyciezarowePrzeglady";
        } else if (
          sciezka.startsWith("/tabela_wykaz_ubezpieczen_samochody_ciezarowe")
        ) {
          this.aktywneMenu = "menuWSTsamoachodyciezaroweUbezpieczenia";
        } else if (sciezka.startsWith("/tabela_samochody_ciezarowe_paliwo")) {
          this.aktywneMenu = "menuWSTsamoachodyciezarowePaliwo";
        } else if (
          sciezka.startsWith("/tabela_wykaz_kosztow_samochody_ciezarowe")
        ) {
          this.aktywneMenu = "menuWSTsamoachodyciezaroweKoszty";
        } else if (
          sciezka.startsWith("/tabela_wykaz_tachografow_samochody_ciezarowe")
        ) {
          this.aktywneMenu = "menuWSTsamoachodyciezaroweTachografy";
        } else if (
          sciezka.startsWith("/tabela_wykaz_chlodni_samochody_ciezarowe")
        ) {
          this.aktywneMenu = "menuWSTsamoachodyciezaroweChlodnie";
        } else if (
          sciezka.startsWith("/tabela_wykaz_termografy_samochody_ciezarowe")
        ) {
          this.aktywneMenu = "menuWSTsamoachodyciezaroweTermografy";

          //elektronarzedzia
        } else if (sciezka === "/tabela_elektronarzedzia") {
          this.aktywneMenu = "menuWSTelektronarzedzia";
        } else if (sciezka.startsWith("/tabela_elektronarzedzia_przeglady")) {
          this.aktywneMenu = "menuWSTelektronarzedziaPrzeglady";
        } else if (
          sciezka.startsWith("/tabela_elektronarzedzia_ubezpieczenia")
        ) {
          this.aktywneMenu = "menuWSTelektronarzedziaUbezpieczenia";
        } else if (sciezka.startsWith("/tabela_elektronarzedzia_koszty")) {
          this.aktywneMenu = "menuWSTelektronarzedziaKoszty";
        } else if (sciezka.startsWith("/generator_przegladow_elektronarzedzia")) {
          this.aktywneMenu = "menuWSTGeneratorElektronarzedzia";

          //IT
        } else if (sciezka === "/tabela_IT") {
          this.aktywneMenu = "menuWSTIT";
        } else if (sciezka.startsWith("/tabela_IT_przeglady")) {
          this.aktywneMenu = "menuWSTITPrzeglady";
        } else if (sciezka.startsWith("/tabela_IT_ubezpieczenia")) {
          this.aktywneMenu = "menuWSTITUbezpieczenia";
        } else if (sciezka.startsWith("/tabela_IT_koszty")) {
          this.aktywneMenu = "menuWSTITKoszty";
        } else if (sciezka.startsWith("/generator_przegladow_IT")) {
          this.aktywneMenu = "menuWSTGeneratorIT";

          //POMIAROWKA
        } else if (sciezka === "/sprzet_pomiarowy") {
          this.aktywneMenu = "menuWSTPomiarowka";
        } else if (sciezka.startsWith("/sprzet_pomiarowy_przeglady")) {
          this.aktywneMenu = "menuWSTPomiarowkaPrzeglady";
        } else if (sciezka.startsWith("/sprzet_pomiarowy_ubezpieczenia")) {
          this.aktywneMenu = "menuWSTPomiarowkaUbezpieczenia";
        } else if (sciezka.startsWith("/sprzet_pomiarowy_koszty")) {
          this.aktywneMenu = "menuWSTPomiarowkaKoszty";

          //GENERATOR POMIAROWKA
        } else if (sciezka === "/wykaz_sprzetu_pomiarowego") {
          this.aktywneMenu = "menuWSTGeneratorPomiarowka";
        } else if (sciezka.startsWith("/wykaz_sprawdzen")) {
          this.aktywneMenu = "menuWSTGeneratorPomiarowkaSwiadectwa";
        } else if (sciezka.startsWith("/swiadectwo_sprawdzenia")) {
          this.aktywneMenu = "menuWSTGeneratorPomiarowkaSwiadectwo";

          //EMISJA PALIW
        } else if (sciezka === "/wykaz_emisje") {
          this.aktywneMenu = "menuWSTEmisjaZPaliw";

          //wyposazenie BHP
        } else if (sciezka === "/wyposazenie_BHP") {
          this.aktywneMenu = "menuWSTwyposazenieBHP";
        } else if (sciezka.startsWith("/wyposazenie_BHP_przeglady")) {
          this.aktywneMenu = "menuWSTwyposazeniePrzegladyBHP";
          //Budynki
        } else if (sciezka === "/budynki") {
          this.aktywneMenu = "menuWSTbudynki";
        } else if (sciezka.startsWith("/budynki_przeglady_5lat")) {
          this.aktywneMenu = "menuWSTbudynkiPrzeglady";
        } else if (sciezka.startsWith("/budynki_przeglady_roczne")) {
          this.aktywneMenu = "menuWSTbudynkiPrzeglady";
        } else if (sciezka.startsWith("/budynki_przeglady_polroczne")) {
          this.aktywneMenu = "menuWSTbudynkiPrzeglady";
        } else if (sciezka.startsWith("/budynki_przeglady_elektryczne")) {
          this.aktywneMenu = "menuWSTbudynkiPrzeglady";
        } else if (sciezka.startsWith("/budynki_przeglady_gazowe")) {
          this.aktywneMenu = "menuWSTbudynkiPrzeglady";
        } else if (sciezka.startsWith("/budynki_przeglady_hydrantow")) {
          this.aktywneMenu = "menuWSTbudynkiPrzeglady";
        } else if (sciezka.startsWith("/budynki_przeglady_instrukcja_ppoz")) {
          this.aktywneMenu = "menuWSTbudynkiPrzeglady";
        } else if (sciezka.startsWith("/budynki_przeglady_kominow")) {
          this.aktywneMenu = "menuWSTbudynkiPrzeglady";
        } else if (sciezka.startsWith("/budynki_przeglady_wylacznika")) {
          this.aktywneMenu = "menuWSTbudynkiPrzeglady";
        } else if (sciezka.startsWith("/budynki_ubezpieczenia")) {
          this.aktywneMenu = "menuWSTbudynkiUbezpieczenia";
        } else if (sciezka.startsWith("/budynki_koszty")) {
          this.aktywneMenu = "menuWSTmaszynyKoszty";
        } else if (sciezka.startsWith("/budynki_media")) {
          this.aktywneMenu = "menuWSTbudynkiMedia";

          //wyposazenie PPOZ
        } else if (sciezka === "/wyposazenie_PPOZ") {
          this.aktywneMenu = "menuWSTwyposazeniePPOZ";
        } else if (sciezka.startsWith("/wyposazenie_PPOZ_przeglady")) {
          this.aktywneMenu = "menuWSTwyposazeniePrzegladyPPOZ";

          // MODUŁ HRB
        } else if (sciezka === "/strona_glowna_modul_HRB") {
          this.aktywneMenu = "menuHRB";

          //PRACOWNICY ZATRUDNIENI
        } else if (sciezka === "/pracownicy_zatrudnieni") {
          this.aktywneMenu = "menuHRBPZATRUD";
          //PRACOWNICY ZWOLNIENI
        } else if (sciezka === "/pracownicy_zwolnieni") {
          this.aktywneMenu = "menuHRBPZWOL";
          //PRACOWNICY ZWOLNIENI
        } else if (sciezka === "/pracownicy_zatrudnieni") {
          this.aktywneMenu = "menuHRBPZATRUD";
        } else if (
          sciezka.startsWith("/pracownicy_zatrudnieni_adres_zamieszkania")
        ) {
          this.aktywneMenu = "menuHRBPracownik";
        } else if (
          sciezka.startsWith("/pracownicy_zatrudnieni_adres_korespondencja")
        ) {
          this.aktywneMenu = "menuHRBPracownik";
        } else if (sciezka.startsWith("/pracownicy_dane_zatrudnienia")) {
          this.aktywneMenu = "menuHRBPracownik";
        } else if (sciezka.startsWith("/pracownicy_zakres_obowiazkow")) {
          this.aktywneMenu = "menuHRBPracownik";
        } else if (sciezka.startsWith("/pracownicy_badania")) {
          this.aktywneMenu = "menuHRBPracownik";
        } else if (sciezka.startsWith("/pracownicy_szkolenia_WS")) {
          this.aktywneMenu = "menuHRBPracownik";
        } else if (sciezka.startsWith("/pracownicy_szkolenia_okresowe_BHP")) {
          this.aktywneMenu = "menuHRBPracownik";
        } else if (sciezka.startsWith("/pracownicy_zapoznanie_ORZ")) {
          this.aktywneMenu = "menuHRBPracownik";
        } else if (sciezka.startsWith("/pracownicy_zapoznanie_IO")) {
          this.aktywneMenu = "menuHRBPracownik";
        } else if (sciezka.startsWith("/pracownicy_zapoznanie_IO")) {
          this.aktywneMenu = "menuHRBPracownik";
        } else if (sciezka.startsWith("/pracownicy_zapoznanie_IS")) {
          this.aktywneMenu = "menuHRBPracownik";
        } else if (sciezka.startsWith("/pracownicy_zapoznanie_IBHP")) {
          this.aktywneMenu = "menuHRBPracownik";
        } else if (sciezka.startsWith("/pracownicy_kompetencje")) {
          this.aktywneMenu = "menuHRBPracownik";
        } else if (sciezka.startsWith("/pracownicy_historia_zatrudnienia")) {
          this.aktywneMenu = "menuHRBPracownik";

          // GENERATOR ZAKRESÓW
        } else if (sciezka.startsWith("/wykaz_zakresow")) {
          this.aktywneMenu = "menuHRBGeneratorZakresow";
        } else if (sciezka.startsWith("/zakres_katalog")) {
          this.aktywneMenu = "menuHRBGeneratorZakresow";
        } else if (sciezka.startsWith("/zakres_pracownik")) {
          this.aktywneMenu = "menuHRBGeneratorZakresow";
        } else if (sciezka.startsWith("/zakres_kierujacy_pracownikami")) {
          this.aktywneMenu = "menuHRBGeneratorZakresow";

          //STANOWISKA
        } else if (sciezka === "/stanowiska") {
          this.aktywneMenu = "menuHRBStanowiska";
        } else if (sciezka.startsWith("/stanowiska_przeglad")) {
          this.aktywneMenu = "menuHRBStanowiska";
          //SZKOLENIA
        } else if (sciezka === "/szkolenia") {
          this.aktywneMenu = "menuHRBSzkolenia";
        } else if (sciezka.startsWith("/szkolenia_wykaz")) {
          this.aktywneMenu = "menuHRBSzkolenia";
        } else if (sciezka.startsWith("/realizacja_szkolenia")) {
          this.aktywneMenu = "menuHRBSzkolenia";
        } else if (sciezka.startsWith("/wykaz_uczestnikow")) {
          this.aktywneMenu = "menuHRBSzkolenia";
          //SKIEROWANIA
        } else if (sciezka === "/wykaz_skierowan") {
          this.aktywneMenu = "menuHRBSkierowania";
        } else if (sciezka.startsWith("/skierowanie")) {
          this.aktywneMenu = "menuHRBSkierowania";
        
         //USTAWIENIA
        } else if (sciezka === "/tabela_dostepu") {
          this.aktywneMenu = "menuUstawienia";
        } else if (sciezka.startsWith("/tabela_dostepu_szczegoly")) {
          this.aktywneMenu = "menuUstawieniaSzczegoly";

          //MEDYCYNA PRACY
        } else if (sciezka === "/medycyna_pracy") {
          this.aktywneMenu = "menuHRBMedycynaPracy";
        } else if (sciezka === "/strona_glowna_modul_BHP") {
          this.aktywneMenu = "menuBHP";
        } else {
          this.aktywneMenu = "menuZPoziomuFormularza";
        }
      }, 1000);
    },
  },
  computed: {
    elementy: function() {
      return elementyNawigacyjne[this.aktywneMenu];
    },
  },
};
</script>

<style scoped>
.nawigacja {
  background-color: #606060;
  margin: 13px;
  cursor: pointer;
}

.md-menu-content {
  max-height: 80vh !important;
  max-width: 1000px !important;
}
.md-menu {
  display: inline-block;
  min-width: auto !important;
}
.md-theme-default a:not(.md-button) {
  color: black;
  font-weight: bold;
}
.aaaa:hover {
  color: black;
  text-decoration: none;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
.aaaa {
  margin-left: 10px;
}
</style>
