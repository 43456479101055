<template>
  <div>
  <TabelaSamochodyCiezarowePaliwo />
  </div>
</template>

<script>
import TabelaSamochodyCiezarowePaliwo from '../../komponenty/strona-tabela-komponenty/WST_samochody_ciezarowe/tabela_samochody_ciezarowe_paliwo.vue'


export default {
  name: 'StronaTabelaSamochodyCiezarowePaliwo',
  components: {
    TabelaSamochodyCiezarowePaliwo,
  }

}
</script>
