<template>
  <table class="table_01">

    <tr>
      <td style="width: 100%; height:70px; background: #adadad; font-size:24px; font-weight: 700" colspan="4">ŚWIADECTWO PRZEPROWADZENIA SPRAWDZENIA WYPOSAŻENIA KONTROLNO-POMIAROWEGO</td>
    </tr>

    <tr>
      <td style="width: 100%; background: #c7c7c7; font-weight: 700" colspan="4">DANE ŚWIADECTWA SPRAWDZENIA</td>
    </tr>

    <tr>
      <td style="width: 50%; background: #e3e3e3; font-weight: 500" colspan="2">Numer świadectwa</td>
      <td style="width: 50%" colspan="2">{{ info.Numer_swiadectwa }}</td>
    </tr>

    <tr>
      <td style="width: 50%; background: #e3e3e3; font-weight: 500" colspan="2">Data przeprowadzenia sprawdzenia</td>
      <td style="width: 50%" colspan="2">{{ info.Data_przegladu }}</td>
    </tr>

    <tr>
      <td style="width: 50%; background: #e3e3e3; font-weight: 500" colspan="2">Data następnego sprawdzenia</td>
      <td style="width: 50%" colspan="2">{{ info.Data_nastepnego_przegladu }}</td>
    </tr>

    <tr>
      <td style="width: 50%; background: #e3e3e3; font-weight: 500" colspan="2">Osoba wykonująca sprawdzenie</td>
      <td style="width: 50%" colspan="2">{{ info.Przeglad_wykonal }}</td>
    </tr>

    <tr>
      <td style="width: 100%; background: #c7c7c7; font-weight: 700" colspan="4">DANE WYPOSAŻENIA SPRAWDZANEGO</td>
    </tr>

    <tr>
      <td style="width: 50%; background: #e3e3e3; font-weight: 500" colspan="2">Numer ID wyposażenia sprawdzanego</td>
      <td style="width: 50%" colspan="2">{{ info.Id_maszyny_wybor}}</td>
    </tr>

    <tr>
      <td style="width: 50%; background: #e3e3e3; font-weight: 500" colspan="2">Nazwa wyposażenia sprawdzanego</td>
      <td style="width: 50%" colspan="2">{{ info.Nazwa_maszyny_wybor }}</td>
    </tr>

    <tr>
      <td style="width: 50%; background: #e3e3e3; font-weight: 500" colspan="2">Numer fabryczny/seryjny wyposażenia sprawdzanego</td>
      <td style="width: 50%" colspan="2">{{ info.Numer_fabryczny_wybor }}</td>
    </tr>

    <tr>
      <td style="width: 50%; background: #e3e3e3; font-weight: 500" colspan="2">Rok produkcji wyposażenia sprawdzanego</td>
      <td style="width: 50%" colspan="2">{{ info.Rok_produkcji_wybor }}</td>
    </tr>

    <tr>
      <td style="width: 50%; background: #e3e3e3; font-weight: 500" colspan="2">Osoba odpowiedzialna za wyposażenie sprawdzane</td>
      <td style="width: 50%" colspan="2">{{ info.Osoba_odpowiedzialna_wybor}}</td>
    </tr>

    <tr>
      <td style="width: 25%; background: #e3e3e3; font-weight: 500">Wymagana dokładność (+)</td>
      <td style="width: 25%">{{ info.Dokladnosc_plus_wybor }} %</td>
      <td style="width: 25%; background: #e3e3e3; font-weight: 500">Wymagana dokładność (-)</td>
      <td style="width: 25%">{{ info.Dokladnosc_minus_wybor }} %</td>
    </tr>

    <tr>
      <td style="width: 100%; background: #c7c7c7; font-weight: 600" colspan="4">DANE WYPOSAŻENIA WZORCOWEGO</td>
    </tr>

    <tr>
      <td style="width: 50%; background: #e3e3e3; font-weight: 500" colspan="2">Numer ID wyposażenia wzorcowego</td>
      <td style="width: 50%" colspan="2">{{ info.Id_maszyny_wzorzec }}</td>
    </tr>

    <tr>
      <td style="width: 50%; background: #e3e3e3; font-weight: 500" colspan="2">Nazwa wyposażenia wzorcowego</td>
      <td style="width: 50%" colspan="2">{{ info.Nazwa_maszyny_wzorzec }}</td>
    </tr>

    <tr>
      <td style="width: 50%; background: #e3e3e3; font-weight: 500" colspan="2">Numer fabryczny/seryjny wyposażenia wzorcowego</td>
      <td style="width: 50%" colspan="2">{{ info.Numer_fabryczny_wzorzec }}</td>
    </tr>

    <tr>
      <td style="width: 50%; background: #e3e3e3; font-weight: 500" colspan="2">Rok produkcji wyposażenia wzorcowego</td>
      <td style="width: 50%" colspan="2">{{ info.Rok_produkcji_wzorzec }}</td>
    </tr>

    <tr>
      <td style="width: 50%; background: #e3e3e3; font-weight: 500" colspan="2">Osoba odpowiedzialna za wyposażenie wzorcowe</td>
      <td style="width: 50%" colspan="2">{{ info.Osoba_odpowiedzialna_wzorzec }}</td>
    </tr>

    <tr>
      <td style="width: 100%; background: #c7c7c7; font-weight: 600" colspan="4">WYNIKI POMIARU WYPOSAŻENIA SPRAWDZANEGO</td>
    </tr>

    <tr>
      <td style="width: 25%; background: #e3e3e3; font-weight: 500" rowspan="2">Wyniki pomiaru</td>
      <td style="width: 25%; background: #e3e3e3; font-weight: 500">Wartość wyniku POMIAR-1</td>
      <td style="width: 25%; background: #e3e3e3; font-weight: 500">Wartość wyniku POMIAR-2</td>
      <td style="width: 25%; background: #e3e3e3; font-weight: 500">Wartość wyniku POMIAR-3</td>
    </tr>

    <tr>
      <td style="width: 25%">{{info.Wynik_1_W}}</td>
      <td style="width: 25%">{{info.Wynik_2_W}}</td>
      <td style="width: 25%">{{info.Wynik_3_W}}</td>
    </tr>

    <tr>
      <td style="width: 100%; background: #c7c7c7; font-weight: 600" colspan="4">WYNIKI POMIARU WYPOSAŻENIA WZORCOWEGO</td>
    </tr>

    <tr>
      <td style="width: 25%; background: #e3e3e3; font-weight: 500" rowspan="2">Wyniki pomiaru</td>
      <td style="width: 25%; background: #e3e3e3; font-weight: 500">Wartość wyniku POMIAR-1</td>
      <td style="width: 25%; background: #e3e3e3; font-weight: 500">Wartość wyniku POMIAR-2</td>
      <td style="width: 25%; background: #e3e3e3; font-weight: 500">Wartość wyniku POMIAR-3</td>
    </tr>

    <tr>
      <td style="width: 25%">{{info.Wynik_1_WS}}</td>
      <td style="width: 25%">{{info.Wynik_2_WS}}</td>
      <td style="width: 25%">{{info.Wynik_3_WS}}</td>
    </tr>

    <tr>
      <td style="width: 100%; background: #c7c7c7; font-weight: 600" colspan="4">WSKAŹNIK PRZELICZENIOWY</td>
    </tr>

    <tr>
      <td style="width: 25%; background: #e3e3e3; font-weight: 500" rowspan="2">Wyniki pomiaru</td>
      <td style="width: 25%; background: #e3e3e3; font-weight: 500">Wartość wyniku POMIAR-1</td>
      <td style="width: 25%; background: #e3e3e3; font-weight: 500">Wartość wyniku POMIAR-2</td>
      <td style="width: 25%; background: #e3e3e3; font-weight: 500">Wartość wyniku POMIAR-3</td>
    </tr>

    <tr>
      <td style="width: 25%">{{info.Wynik_1_WSO}} %</td>
      <td style="width: 25%">{{info.Wynik_2_WSO}} %</td>
      <td style="width: 25%">{{info.Wynik_3_WSO}} %</td>
    </tr>
    <tr>
      <td style="width: 100%; background: #c7c7c7; font-weight: 600" colspan="4">UZYSKANA DOKŁADNOŚĆ</td>
    </tr>

    <tr>
      <td style="width: 25%; background: #e3e3e3; font-weight: 500" rowspan="2">Wyniki pomiaru</td>
      <td style="width: 25%; background: #e3e3e3; font-weight: 500">Wartość wyniku POMIAR-1</td>
      <td style="width: 25%; background: #e3e3e3; font-weight: 500">Wartość wyniku POMIAR-2</td>
      <td style="width: 25%; background: #e3e3e3; font-weight: 500">Wartość wyniku POMIAR-3</td>
    </tr>

    <tr>
      <td style="width: 25%">{{info.Wynik_1_UD}} %</td>
      <td style="width: 25%">{{info.Wynik_2_UD}} %</td>
      <td style="width: 25%">{{info.Wynik_3_UD}} %</td>
    </tr>

    <tr>
      <td style="width: 100%; background: #c7c7c7; font-weight: 600" colspan="4">WNIOSKI KOŃCOWE DLA ŚWIADECTWA SPRAWDZENIA</td>
    </tr>

    <tr>
      <td style="width: 50%; background: #e3e3e3; font-weight: 500" colspan="2">UZYSKANY WYNIK SPRAWDZENIA</td>
      <td style="width: 50%; font-weight: 900" colspan="2">{{ info.Wynik_sprawdzenia }}</td>
    </tr>

    <tr>
      <td style="width: 50%; background: #e3e3e3; font-weight: 500" colspan="2">Data zatwierdzenia</td>
      <td style="width: 50%; height:auto" colspan="2">{{ info.Data_przegladu }}</td>
    </tr>

    <tr>
      <td style="width: 50%; background: #e3e3e3; font-weight: 500; height:80px" colspan="2">Podpis osoby wykonującej</td>
      <td style="width: 50%" colspan="2">{{ info.Przeglad_wykonal }}</td>
    </tr>



  </table>
  
</template>



<script>
import { api } from "../../../serwisy/api.serwis";

// import Cos from './coc.vue'
export default {
  name: "Swiadectwo_sprawdzenia",
//   components:{
// Cos
//   },
  data: () => ({
    cos: {
      Id: 3,
      Nazwa: "przykład",
      Dane: [
        {
          Data: '12-12-2021',
          Wykonal: 'Jan'
        },

      ]
    },
    info: {
      Id: null,
      DataPrzegladu: null,
      Data_nastepnego_przegladu: null,
      Przeglad_wykonal: null,
    },
  }),
  created() {
    this.pobierzSprawdzenie();
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  methods: {
    async pobierzSprawdzenie() {
      try {
        const {
          data: { dane },
          } = await api.pobieranieGeneratorPomiarowkaSwiadectwo(this.id);
        const info = dane[0];
        this.info = info;
      } catch (error) {
        console.log(error);
      }
    },
  },
};


</script>

<style>
.table_01 {
  width: 100%;
  height: 40px;
  font-size: 16px;
  margin-top: 40px;
  color: black;
  vertical-align: middle;
}
.table_01 td{
  border: 1px solid black;
}

.input {
  width: 100%;
  height: 100%;

  text-align: center;
}
.textarea {
  width: 100%;
  min-height: 30px;
  max-height: 200px;
  text-align: center;
}


</style>