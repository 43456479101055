<template>
  <div class="FORM_PZBHP">
    <form novalidate class="md-layout" @submit.prevent="walidujFormularz">
      <md-card class="md-layout-item">
        <md-card-header>
          <div class="M-Tytul">
            {{
              czyJestWTrybieEdycji
                ? "FORMULARZ EDYCJI USTALEŃ Z POSIEDZENIA ZESPOŁU DS BHP"
                : "FORMULARZ USTALEŃ Z POSIEDZENIA ZESPOŁU DS BHP"
            }}
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field class="M5" :class="getValidationClass('Data')">
                <label for="Data">Data oceny</label>
                <md-input
                  type="date"
                  name="Data"
                  id="Data"
                  autocomplete="Data"
                  v-model="form.Data"
                  @input="pobierzNumerPosiedzenia"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.Data.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field class="MP2" :class="getValidationClass('Numer')">
                <label for="Numer">Nr posiedzenia</label>
                <md-input
                  name="Numer"
                  id="Numer"
                  autocomplete="Numer"
                  md-counter="15"
                  v-model="form.Numer"
                  :disabled="sending"
                  readonly
                />
                <span class="md-error" v-if="!$v.form.Numer.required"
                  >Pole jest wymagane</span
                >
                <span class="md-error" v-else-if="!$v.form.Numer.maxlength"
                  >Wymagana ilość znaków od 1 do 15</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M5"
                :class="getValidationClass('Data_nastepnego_posiedzenia')"
              >
                <label for="Data_nastepnego_posiedzenia"
                  >Data nast.posiedzenia</label
                >
                <md-input
                  type="date"
                  name="Data_nastepnego_posiedzenia"
                  id="Data_nastepnego_posiedzenia"
                  autocomplete="Data_nastepnego_posiedzenia"
                  v-model="form.Data_nastepnego_posiedzenia"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Data_nastepnego_posiedzenia.required"
                  >Pole jest wymagane</span
                >
              </md-field>
            </div>
          </div>


          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                class="M2"
                :class="getValidationClass('Osoba_odpowiedzialna')"
              >
                <label for="Osoba_odpowiedzialna">Osoba odpowiedzialna</label>
                <md-input
                  name="Osoba_odpowiedzialna"
                  id="Osoba_odpowiedzialna"
                  md-counter="50"
                  v-model="form.Osoba_odpowiedzialna"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.Osoba_odpowiedzialna.required"
                  >Pole jest wymagane</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.Osoba_odpowiedzialna.maxlength"
                  >Wymagana ilość znaków od 1 do 50</span
                >
              </md-field>
            </div>
          </div>

        

          <div class="md-layout md-gutter">
            <md-field class="MP3" :class="plikZwalidowany ? '' : 'md-invalid'">
              <label
                >Wstaw plik - "max-2Mb"
                <span v-if="nazwaPliku">
                  - obecnie wstawiony plik to {{ nazwaPliku }}</span
                ></label
              >
              <md-file
                v-model="form.plik_PDF"
                @md-change="zapamietajPlik"
                accept="application/pdf"
              />
              <span class="md-error" v-if="!plikZwalidowany"
                >Plik moze miec max 2MB</span
              >
            </md-field>
          </div>


 <div
            style="
              margin-top: 40px;
              margin-left: 15px;
              font-weight: bold;
              font-size: 16px;
            "
          >
            SZCZEGÓŁY I USTALENIA Z POSIEDZENIA
          </div>
          <div
            v-for="(wiersz, index) in form.wiersze"
            :key="index"
            :set="(v = $v.form.wiersze.$each[index])"
          >
            <div
              style="
                color: blue;
                font-weight: bold;
                font-size: 1.5vh;
                margin-left: 15px;
                margin-top: 10px;
              "
            >
              {{ `POZYCJA NR: ${index + 1} ` }}

              <b-icon-plus-square
                class="ikony"
                font-scale="1.4"
                style="border: 1px black"
                color="black"
                @click="dodajWiersz()"
              >
              </b-icon-plus-square>

              <b-icon-trash
                class="ikony"
                font-scale="1.4"
                color="black"
                @click="usunWiersz(wiersz)"
              >
              </b-icon-trash>
            </div>


                    <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
                <md-field :class="getValidationClass('Uwagi',index)">
                  <label for="Uwagi">Uwagi do pozycji</label>
                  <md-textarea
                    name="Uwagi"
                    id="Uwagi"
                    autocomplete="Uwagi"
                    md-autogrow
                    md-counter="1000"
                    v-model="wiersz.Uwagi"
                    :disabled="sending"
                  />
                  <span class="md-error" v-if="!v.Uwagi.required"
                    >Pole jest wymagane</span
                  >
                  <span class="md-error" v-else-if="!v.Uwagi.maxlength"
                    >Wymagana ilość znaków od 1 do 1000</span
                  >
              </md-field>
            </div>
          </div>
          </div>
        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

        <div style="text-align: center">
          <md-button type="submit" class="md-primary" :disabled="sending">{{
            czyJestWTrybieEdycji
              ? "ZATWIERDŹ - EDYTOWANY WPIS"
              : "ZATWIERDŹ - UTWÓRZ WPIS"
          }}</md-button>
        </div>
      </md-card>
    </form>
  </div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import minLength from "vuelidate/lib/validators/minLength";
import { api } from "../../../serwisy/api.serwis";

export default {
  name: "Formularz_dodawanie_maszyny",

  props: {
    funkcjaZamykajacaDialog: {
      type: Function,
    },
    obiektKonfiguracyjny: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      Data: null,
      Numer: null,
      Data_nastepnego_posiedzenia: null,
      Osoba_odpowiedzialna: null,
      plik_PDF: null,
      plik_dane: null,
     wiersze: [
        {
          Uwagi: null,
        },
      ],
    },
    IdEdytowanegoElementu: null,
    userSaved: false,
    nazwaPliku: null,
    sending: false,
    lastUser: null,
    Obiekt_generujacy_numer_posiedzenia: null,
  }),
  created: function() {
    if (this.obiektKonfiguracyjny.akcja === "edycja") {
      //edycja
      api
        .pobieranieDokumentacjaPosiedzenieEdycja(this.obiektKonfiguracyjny.Id)
        .then((odpowiedz) => {
          const {
            Id,
            Numer,
            Data,
            Data_nastepnego_posiedzenia,
            Osoba_odpowiedzialna,
            Nazwa_zalacznika,
            PozycjePosiedzenia
          } = odpowiedz.data.dane[0];
          this.IdEdytowanegoElementu = Id;
          this.form.Data = Data;
          this.form.Numer = Numer;
          this.form.Data_nastepnego_posiedzenia = Data_nastepnego_posiedzenia;
          this.form.Osoba_odpowiedzialna = Osoba_odpowiedzialna;
          this.nazwaPliku = Nazwa_zalacznika;
          if (PozycjePosiedzenia.length !== 0) {
          this.form.wiersze = PozycjePosiedzenia;
        }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  validations: {
    form: {
      Data: {
        required,
      },
      Numer: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(15),
      },
      Data_nastepnego_posiedzenia: {
        required,
      },
      Osoba_odpowiedzialna: {
        required,
        minLenght: minLength(1),
        maxLength: maxLength(50),
      },
    wiersze: {
        $each: {
          Uwagi: {
            required,
            minLenght: minLength(1),
            maxLength: maxLength(1000),
          },
        },
      },
    },
  },
  computed: {
    plikZwalidowany: function() {
      return this.form.plik_dane
        ? this.form.plik_dane.size < 2 * 1024 * 1024
        : true;
    },
     numerPosiedzenia: function () {
      if (this.Obiekt_generujacy_numer_posiedzenia) {
        const { numer, miesiac, rok } = this.Obiekt_generujacy_numer_posiedzenia;
        return `PZBHP-${numer}/${miesiac}/${rok}`;
      } else {
        return "";
      }
    },
    czyJestWTrybieEdycji: function() {
      return this.obiektKonfiguracyjny.akcja === "edycja";
    },
  },

  methods: {
    zapamietajPlik(pliki) {
      if (pliki[0]) {
        this.form.plik_dane = pliki[0];
      }
    },
    pobierzNumerPosiedzenia() {
      const { Data } = this.form;
      const data = new Date(Data);
      const rok = data.getFullYear();
      const miesiac = data.getMonth() + 1;
      if (this.czyJestWTrybieEdycji) {
        return;
      }

      api.PobierzLpPosiedzenia({ rok, miesiac }).then((odpowiedz) => {
        this.Obiekt_generujacy_numer_posiedzenia = odpowiedz.data.dane;
        this.form.Numer = this.numerPosiedzenia;
      });
    },
     dodajWiersz() {
      console.log("robi");
      const nowyWiersz = {};
      Object.keys(this.form.wiersze[0]).forEach((key) => {
        nowyWiersz[key] = null;
      });
      const noweWiersze = [...this.form.wiersze, nowyWiersz];
      this.form.wiersze = noweWiersze;
    },
    usunWiersz(wiersz) {
      if (this.form.wiersze.length <= 1) {
        const nowyWiersz = {};
        Object.keys(this.form.wiersze[0]).forEach((key) => {
          nowyWiersz[key] = null;
        });
        this.form.wiersze = [nowyWiersz];
        return;
      }

      this.form.wiersze = this.form.wiersze.filter((el) => el !== wiersz);
    },
    getValidationClass(fieldName, index) {
      const field = this.$v.form[fieldName] || this.$v.form.wiersze.$each[index][fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.Data = null;
      this.form.Numer = null;
      this.form.Data_nastepnego_posiedzenia = null;
      this.form.Osoba_odpowiedzialna = null;     
      this.form.plik_PDF = null;
      this.form.plik_dane = null;
      this.nazwaPliku = null; 
      this.form.wiersze = [];
    },

    async zapiszFormularz() {
      this.sending = true;
      const PozycjePosiedzenia = this.form.wiersze.map((el) => ({
        ...el,

      }));
      let file = null;
      if (this.form.plik_dane) {
        file = await new Promise((resolve) => {
          const fileReader = new FileReader();
          fileReader.onload = () => {
            resolve(fileReader.result);
          };
          fileReader.readAsDataURL(this.form.plik_dane);
        });
      }

      if (this.czyJestWTrybieEdycji) {
        const daneDoWyslania = {
          Numer: this.form.Numer,
          Data: this.form.Data,
          Data_nastepnego_posiedzenia: this.form.Data_nastepnego_posiedzenia,
          Osoba_odpowiedzialna: this.form.Osoba_odpowiedzialna,
          plik_PDF: file,
          PlikNazwa: this.form.plik_dane ? this.form.plik_dane.name : null,
          PozycjePosiedzenia,
        };

        api
          .zapiszDokumentacjaPosiedzenieUpdate(
            daneDoWyslania,
            this.IdEdytowanegoElementu
          )
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const daneDoWyslania = {
          Numer: this.form.Numer,
          Lp: this.Obiekt_generujacy_numer_posiedzenia.numer,
           Data: this.form.Data,
          Data_nastepnego_posiedzenia: this.form.Data_nastepnego_posiedzenia,
          Osoba_odpowiedzialna: this.form.Osoba_odpowiedzialna,
          plik_PDF: file,
          PlikNazwa: this.form.plik_dane ? this.form.plik_dane.name : null,
          PozycjePosiedzenia,

        };

        api
          .zapiszDokumentacjaPosiedzenie(daneDoWyslania)
          .then(() => {
            this.clearForm();
            this.sending = false;
            this.funkcjaZamykajacaDialog(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    naszWalidacjaFormularza() {
      if (this.czyJestWTrybieEdycji) {
        return (
          !this.$v.form.Numer.$invalid &&
          !this.$v.form.Data.$invalid &&
          !this.$v.form.Data_nastepnego_posiedzenia.$invalid &&
          !this.$v.form.Osoba_odpowiedzialna.$invalid &&
          !this.$v.form.wiersze.$invalid &&
          this.plikZwalidowany
        );
      } else {
        return !this.$v.$invalid && this.plikZwalidowany;
      }
    },
    walidujFormularz() {
      this.$v.$touch();
      if (this.naszWalidacjaFormularza()) {
        this.zapiszFormularz();
      }
    },
  },
};
</script>

<style>
.FORM_PZBHP .md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.FORM_PZBHP .md-layout .md-gutter {
  min-width: 55vw !important; /* szerokość modala*/
  max-width: 100% !important;
  min-height: 7vh !important; /* wysokość pomiędzy mierszami w modalu*/
   margin-right: 20px !important;
}
.md-layout {
  padding-right: 0px !important;
}
.FORM_PZBHP .M-Tytul {
  min-width: 58vw;
  max-width: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin-left: 20px;
  color: blue;
}

.FORM_PZBHP .md-field {
  /*opisy w formularzu*/
  width: 100%;
  min-height: 58px !important;
  margin: 10px 24px !important;
  padding-top: 35px !important;
}
.FORM_PZBHP .md-error {
  color: red !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-left: 10px !important;
}
.FORM_PZBHP {
  border: 1px solid;
  margin: 5px 5px 5px 5px;
  overflow: auto;
}
.FORM_PZBHP .md-primary {
  border: 1px solid !important;
  background: rgb(228, 227, 227) !important;
  color: black !important;
  font-weight: bold !important;
}
.md-card-actions,
.md-alignment-right {
  min-width: 40vw !important; /* szerokość modala*/
  max-width: 42vw !important;
  margin-right: 30px;
}
.md-layout .md-gutter {
  min-width: 1100px !important;
}
.md-menu-content {
  max-height: 80vh !important;
  width: auto !important;
  max-width: 75vw !important;
}
.md-list-item-text {
  font-size: 1.7vmin !important;
}
.FORM_PZBHP .md-card-actions .md-button {
  margin-right: 4vw !important;
}
.FORM_PZBHP .ikony {
  cursor: pointer;
  margin-right: 5px;
  margin-left: 15px;
}
.md-field.md-has-textarea:not(.md-autogrow):after,
.md-field.md-has-textarea:not(.md-autogrow):before {
  border: none !important;
  border-bottom: 0.5px solid !important;
  height: 90px !important;
  font-size: 12px !important;
}
.md-field.md-has-textarea:not(.md-autogrow) .md-textarea {
  min-height: 40px !important;
  padding: 0 0px !important;
  resize: vertical !important;
}
.md-input {
  width: 100% !important;
}
</style>
