<template >
  <div>
  <TabelaReklamacjeDzialania/>
  </div>
</template>

<script>
import TabelaReklamacjeDzialania from '../../komponenty/strona-tabela-komponenty/SO_reklamacje/tabela_reklamacje_dzialania.vue'


export default {
  name: 'StronaTabelaReklamacjeDzialania',
  components: {
    TabelaReklamacjeDzialania,
    
  }

}
</script>

