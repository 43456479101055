<template>
<div class="strona">
  <div class="wylogowywania">
    <p>ZOSTAŁEŚ BEZPIECZNIE WYLOGOWANY</p>

    <md-button class="button" @click="$router.push(`./`)">ZALOGUJ PONOWNIE</md-button>

  </div>
</div>
</template>

<script>
import { api } from "../../../serwisy/api.serwis";
import {userService} from "../../../serwisy/user.serwis";

export default {
  name: "tabela_wylogowanie",
 
  mounted: function() {
    this.pobierzDaneDoWylogowania();
  },

  methods: {
    pobierzDaneDoWylogowania() {
      const { wylogowanie } = this.$route.params;

      console.log(this.$route);

      api
        .wylogowanie(wylogowanie)
        .then(() => {
          userService.getUserInfo()
          
          
        })
        .catch((error) => {
          console.log(error);
        });
        
    },

  },
};
</script>

<style>
.strona {
  width: 100%;
  padding-left: 27%;
  padding-right: 27%;
  padding-top: 10%;

}
.wylogowywania {
  max-width: 100%;
  min-height: 15vh;
  max-height: 50vh;
  padding: 10%;
  display: block;
  border: 3px ridge silver;
  margin: 15px 0px 15px;
  border-radius: 10px;
  background-color: #f5f5f5;
  float: center;
  font-weight: bold;
  font-size: 3vh;
  flex: auto;
  line-height: 100%;
  box-shadow: 0 12px 36px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
.wylogowywania .button {
  background-color: silver;
  margin-right: 5px;
  border: 3px white ridge;
  font-weight: bold;
  font-size: 1.6vh;
  min-width: 9vw;
}
.wylogowywania .button:hover {
  background-color: #727171;
  text-decoration: none;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  color: white;
}


</style>
