<template>
  <div id="app">
    <PasekGorny />
    <router-view/>
    <PasekDolny/>
  </div>
</template>

<script>

import PasekGorny from './komponenty/PasekGorny.vue'
import PasekDolny from './komponenty/PasekDolny.vue'
import {userService} from "./serwisy/user.serwis";

export default {
  name: 'App',
  components: {
    PasekGorny,
    PasekDolny
  },
  mounted(){
    userService.getUserInfo()
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  max-width: 90%;
  margin: 0 auto;
}


</style>
