<template>
  <div>
  <TabelaWyposazeniePrzegladyBHP />
  </div>
</template>

<script>
import TabelaWyposazeniePrzegladyBHP from '../../komponenty/strona-tabela-komponenty/WST_wyposazenieBHP/tabela_wyposazenieBHP_przeglady.vue'


export default {
  name: 'StronaTabelaWyposazeniePrzegladyBHP',
  components: {
    TabelaWyposazeniePrzegladyBHP,
    
  }

}
</script>
