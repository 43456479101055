<template>
  <div>
  <TabelaSamochodyCiezaroweUbezpieczenia />
  </div>
</template>

<script>
import TabelaSamochodyCiezaroweUbezpieczenia from '../../komponenty/strona-tabela-komponenty/WST_samochody_ciezarowe/tabela_samochody_ciezarowe_ubezpieczenia.vue'


export default {
  name: 'StronaTabelaSamochodyCiezaroweUbezpieczenia',
  components: {
    TabelaSamochodyCiezaroweUbezpieczenia,
  }

}
</script>
