<template >
  <div>
  <TabelaWykazPracownicyZakres />
  </div>
</template>

<script>
import TabelaWykazPracownicyZakres from '../../komponenty/strona-tabela-komponenty/HR_pracownicy/tabela_zakres_obowiazkow.vue'


export default {
  name: 'StronaTabelaWykazZakresowObowiazkow',
  components: {
    TabelaWykazPracownicyZakres,
    
  }

}
</script>

