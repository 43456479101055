<template>
  <div class="tabela_PracKOMPETEN">
    <div class="laczacy">
      <div class="menu_strony">
        <button
          class="button"
          @click="$router.push(`../pracownicy_zatrudnieni`)"
        >
          POWRÓT DO WYKAZU PRACOWNIKÓW ZATRUDNIONYCH
        </button>

        <button class="button" @click="$router.push(`../pracownicy_zwolnieni`)">
          POWRÓT DO WYKAZU PRACOWNIKÓW ZWOLNIONYCH
        </button>

        <span>
          <button
            class="button"
            :disabled="!autoryzacja.czyMozeDodawac"
            @click="
              pokazDialog = true;
              typAktywnegoFormularza = 'formularz_dodawanie_kompetencji';
              obiektKonfiguracyjny = {
                Id: null,
                akcja: 'dodawanie',
              };
            "
          >
            DODAWANIE KOMPETENCJI
          </button>
          <md-tooltip class="podpowiedz" md-direction="top">{{
            !autoryzacja.czyMozeDodawac
              ? "BRAK UPRAWNIEŃ"
              : "DODAWANIE KOMPETENCJI"
          }}</md-tooltip>
        </span>
      </div>
      <div class="menu_strony1">
        <b>
          <b-icon-printer-fill
            class="ikony"
            font-scale="2.1"
            color="white"
            @click="generujPdf"
          >
          </b-icon-printer-fill>
          <md-tooltip class="podpowiedz" md-direction="top"
            >GENERUJ PDF / DRUKUJ</md-tooltip
          >
        </b>

        <b>
          <b-icon-info-circle-fill
            class="ikony"
            font-scale="2.1"
            color="white"
            @click="pokazPDF_INFO"
          >
          </b-icon-info-circle-fill>
          <md-tooltip class="podpowiedz" md-direction="top">INFO</md-tooltip>
        </b>
      </div>
    </div>
    <modalHR
      v-if="pokazDialog"
      :rodzajFormularza="typAktywnegoFormularza"
      :funkcjaZamykajacaDialog="funkcjaZamykajacaDialog"
      :obiektKonfiguracyjny="obiektKonfiguracyjny"
    />

    <md-table
      md-height="45vh"
      @md-selected="wyborElementu"
      v-model="searched"
      md-sort="Nazwa_maszyny"
      md-sort-order="asc"
      md-model-id="Id_maszyny"
      md-card
      md-fixed-header
    >
      <md-table-toolbar>
        <div class="d-flex flex-column w-100">
          <div class="row">
            <div class="col-4"></div>
            <div class="col-4">
              <h1 class="md-title">WYKAZ KOMPETENCJI PRACOWNIKA</h1>
            </div>
          </div>

          <div class="PasekWyboru" v-if="wybranyElementTabeli">
            <div
              style="text-align: right; margin-top: 0.3vh; margin-bottom: 0.3vh"
            >
              <b>
                <b-button
                  :disabled="!autoryzacja.czyMozeUsuwac"
                  @click="usuwanieElementu(wybranyElementTabeli.Id)"
                >
                  <b-icon-trash class="ikony" font-scale="1.8" color="white">
                  </b-icon-trash
                ></b-button>
                <md-tooltip class="podpowiedz" md-direction="top">{{
                  autoryzacja.czyMozeUsuwac
                    ? "USUWANIE ELEMENTU"
                    : "BRAK UPRAWNIEŃ"
                }}</md-tooltip>
              </b>
              <b>
                <b-button
                  :disabled="!autoryzacja.czyMozeEdytowac"
                  @click="
                    pokazDialog = true;
                    typAktywnegoFormularza = 'formularz_dodawanie_kompetencji';
                    obiektKonfiguracyjny = {
                      Id: wybranyElementTabeli.Id,
                      akcja: 'edycja',
                    };
                  "
                >
                  <b-icon-pencil-square
                    class="ikony"
                    font-scale="1.80"
                    color="white"
                  >
                  </b-icon-pencil-square>
                </b-button>
                <md-tooltip class="podpowiedz" md-direction="top">{{
                  autoryzacja.czyMozeUsuwac
                    ? "EDYCJA ELEMENTU"
                    : "BRAK UPRAWNIEŃ"
                }}</md-tooltip>
              </b>

              <b
                ><b-button>
                  <b-icon-file-earmark-arrow-down
                    class="ikony"
                    font-scale="1.80"
                    color="white"
                    @click="pokazPDF(wybranyElementTabeli.Id)"
                  >
                  </b-icon-file-earmark-arrow-down
                ></b-button>
                <md-tooltip class="podpowiedz" md-direction="top"
                  >PODGLĄD PLIKU</md-tooltip
                >
              </b>
            </div>
          </div>
        </div>
      </md-table-toolbar>

      <md-table-empty-state
        v-if="czyPobranoDane"
        md-label="Nie posiadasz wpisów w wykazie kompetencji pracownika"
      >
        <md-button
          class="md-primary md-raised"
          :disabled="!autoryzacja.czyMozeDodawac"
          @click="
            pokazDialog = true;
            typAktywnegoFormularza = 'formularz_dodawanie_kompetencji';
            obiektKonfiguracyjny = {
              Id: null,
              akcja: 'dodawanie',
            };
          "
          >Dodawanie nowych kompetencji dla pracownika</md-button
        >
        <md-tooltip class="podpowiedz" md-direction="top">{{
          !autoryzacja.czyMozeDodawac
            ? "BRAK UPRAWNIEŃ"
            : "DODAWANIE KOMPETENCJI"
        }}</md-tooltip>
      </md-table-empty-state>

      <md-table-row
        slot="md-table-row"
        slot-scope="{ item }"
        md-selectable="single"
        :key="item.id"
      >
        <md-table-cell md-label="Lp">
          {{ item.Lp1 }}
        </md-table-cell>

        <md-table-cell md-label="Nazwisko" md-sort-by="Nazwisko">
          {{ item.Nazwisko }}
        </md-table-cell>

        <md-table-cell md-label="Imię-pierwsze" md-sort-by="Imie1">
          {{ item.Imie1 }}
        </md-table-cell>

        <md-table-cell md-label="Nr NIP" md-sort-by="NIP">
          {{ item.NIP }}
        </md-table-cell>

        <md-table-cell md-label="Kompetencje" md-sort-by="Kompetencje">
          {{ item.Kompetencje }}
        </md-table-cell>

        <md-table-cell md-label="Data uzysk." md-sort-by="Data_uzyskania">
          {{ item.Data_uzyskania }}
        </md-table-cell>

        <md-table-cell md-label="Numer" md-sort-by="Numer">
          {{ item.Numer }}
        </md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
import modalHR from "../../strona-formularze/modalHR.vue";
import { api } from "./../../../serwisy/api.serwis";
import { authorizationService } from "./../../../serwisy/authorization.servis";
import jsPDF from "jspdf";
import "jspdf-autotable";

const toLower = (text) => {
  return text.toString().toLowerCase();
};

const search = (items, term) => {
  if (term) {
    return items.filter((item) => {
      const elementWyszukiwany = toLower(term);
      const Nazwisko = toLower(item.Nazwisko);
      const Imie1 = toLower(item.Imie1);
      const NIP = toLower(item.NIP);
      const Pesel = toLower(item.Pesel);
      return (
        Nazwisko.includes(elementWyszukiwany) ||
        Imie1.includes(elementWyszukiwany) ||
        NIP.includes(elementWyszukiwany) ||
        Pesel.includes(elementWyszukiwany)
      );
    });
  }

  return items;
};

export default {
  name: "tabela_wykaz_procesow",
  data: () => ({
    czyPobranoDane: false,
    search: null,
    searched: [],
    Procesy: [],
    pokazDialog: false,
    typAktywnegoFormularza: null,
    obiektKonfiguracyjny: null,
    wybranyElementTabeli: null,
    autoryzacja: {
      czyMozeDodawac: false,
      czyMozeUsuwac: false,
      czyMozeEdytowac: false,
      czyMozePrzegladac: false,
    },
  }),
  components: {
    modalHR,
  },
  mounted: function() {
    this.pobierzDaneDoTabeli();
    this.pobierzDaneAutoryzacyjne();
  },
  computed: {
    //informacja czy jest w trybie edycji
    czyJestWTrybieEdycji() {
      return this.$router.params.Id ? true : false;
    },
  },
  methods: {
    generujPdf() {
      const doc = new jsPDF("l", "mm", "a4");

      doc.setFont("Aleo-Regular");

      doc.text("Wykaz kompetencji pracownika", 14, 10);
      doc.autoTable({
        headStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "silver",
          fontStyle: "bold",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },
        bodyStyles: {
          overflow: "linebreak",
          fontSize: 9,
          font: "Aleo-Regular",
          fillColor: "#e3e7e6",
          textColor: "black",
          lineColor: "black",
          lineWidth: 0.1,
        },

        head: [
          [
            "Lp",
            "Nazwisko i imię",
            "NIP",
            "Kompetencje",
            "Data uzyskania",
            "Numer",
          ],
        ],
        body: this.searched.map((Procesy) => {
          return [
            Procesy.Lp1,
            Procesy.Nazwisko + " " + Procesy.Imie1,
            Procesy.NIP,
            Procesy.Kompetencje,
            Procesy.Data_uzyskania,
            Procesy.Numer,
          ];
        }),
      });

      doc.save("wykaz_kompetencji.pdf");
    },
    pobierzDaneAutoryzacyjne() {
      authorizationService
        .getInfo("StronaTabelaWykazKompetencje", 4)

        .then((obiektOdpowiedzi) => {
          const dane = obiektOdpowiedzi.data.dostep;
          this.autoryzacja = {
            czyMozeDodawac: Boolean(
              dane.find(({ Id_operacji }) => Id_operacji == "1")
            ),
            czyMozeUsuwac: Boolean(
              dane.find(({ Id_operacji }) => Id_operacji == "2")
            ),
            czyMozeEdytowac: Boolean(
              dane.find(({ Id_operacji }) => Id_operacji == "3")
            ),
            czyMozePrzegladac: Boolean(
              dane.find(({ Id_operacji }) => Id_operacji == "4")
            ),
          };
        })
        .catch((error) => {
          console.log(error);
        });
    },
    pobierzDaneDoTabeli() {
      const { id } = this.$route.params;

      console.log(this.$route);

      api
        .pobieranieKompetencjePracownicy(id)
        .then((obiektOdpowiedzi) => {
          console.log(obiektOdpowiedzi);
          this.Procesy = obiektOdpowiedzi.data.dane.map((maszyna, index) => ({
            ...maszyna,
            Lp1: index + 1,
          }));
          this.searched = this.Procesy;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.czyPobranoDane = true;
        });
    },
    funkcjaZamykajacaDialog(sukces) {
      this.pokazDialog = false;
      this.typAktywnegoFormularza = null;
      this.obiektKonfiguracyjny = null;
      if (sukces) {
        this.pobierzDaneDoTabeli();
      }
    },
    newUser() {
      window.alert("Noop");
    },
    searchOnTable() {
      this.searched = search(this.Procesy, this.search);
    },
    usuwanieElementu(Id) {
      this.pokazDialog = true;
      this.typAktywnegoFormularza = "usuwanie";
      this.obiektKonfiguracyjny = {
        Id,
        typ: "UsuwanieKompetencje",
      };
    },
    pokazPDF(Id) {
      //dopisane
      this.pokazDialog = true;
      this.typAktywnegoFormularza = "pokaz_PDF";
      this.obiektKonfiguracyjny = {
        Id,
        Klucz: "prac_kompetencje",
      };
    },
    pokazPDF_INFO() {
      //dopisane
      this.pokazDialog = true;
      this.typAktywnegoFormularza = "pokaz_PDF_INFO";
      this.obiektKonfiguracyjny = {
        Nazwa_instrukcji: "INFO-3",
      };
    },
    wyborElementu(element) {
      this.wybranyElementTabeli = element;
    },
  },
};
</script>

<style>
.tabela_PracKOMPETEN .md-field {
  max-width: 300px;
}
.tabela_PracKOMPETEN .tabela {
  width: 100%;
}
.tabela_PracKOMPETEN .md-card {
  box-shadow: none !important;
}
.tabela_PracKOMPETEN .md-title {
  font-weight: 900 !important;
  color: black !important;
  font-size: 1.8vh !important;
  padding: 10px;
}
.tabela_PracKOMPETEN .md-toolbar {
  height: 14vh;
}
.tabela_PracKOMPETEN .md-table-head {
  font-weight: bold;
  color: black !important;
  font-size: 1.2vh !important;
  text-align: left !important;
}
.tabela_PracKOMPETEN .md-table-head-label {
  text-align: left !important;
  font-size: 1.2vh !important;
}
.tabela_PracKOMPETEN .md-table-cell {
  font-size: 1.2vh !important;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-align: left;
  color: black;
  font-weight: 300;
  margin-top: 200px !important;
}
.tabela_PracKOMPETEN .ikony {
  cursor: pointer;
  margin-right: 40px;
}
.tabela_PracKOMPETEN .md-table-cell:nth-child(1) {
  width: 5vw;
  text-align: left !important;
}
.tabela_PracKOMPETEN .md-table-cell:nth-child(2) {
  width: 12vw;
  text-align: left !important;
}
.tabela_PracKOMPETEN .md-table-cell:nth-child(3) {
  width: 12vw;
  text-align: left !important;
}
.tabela_PracKOMPETEN .md-table-cell:nth-child(4) {
  width: 12vw;
  text-align: left !important;
}
.tabela_PracKOMPETEN .md-table-cell:nth-child(5) {
  width: 27vw;
  text-align: left !important;
}
.tabela_PracKOMPETEN .md-table-cell:nth-child(6) {
  width: 12vw;
  text-align: left !important;
}
.tabela_PracKOMPETEN .md-table-cell:nth-child(7) {
  width: 15vw;
  text-align: left !important;
}

.tabela_PracKOMPETEN .button {
  margin-right: 5px;
  border: 3px white ridge;
  font-weight: bold;
  font-size: 1.1vmin;
  min-width: 9vw;
}
.tabela_PracKOMPETEN .button:hover {
  background-color: #727171;
  text-decoration: none;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  color: white;
}
.tabela_PracKOMPETEN .menu_strony {
  border: 1px solid;
  margin-top: 5px;
  padding: 3px;
  background: #939393;
  width: 84.5%;
}
.tabela_PracKOMPETEN .menu_strony1 {
  border: 1px solid;
  margin-top: 5px;
  padding: 3px;
  background: #939393;
  width: 15%;
  margin-left: 0.5%;
}
.tabela_PracKOMPETEN .laczacy {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.md-table.md-theme-default .md-table-row.md-selected,
.md-table.md-theme-default .md-table-row.md-selected-single {
  background-color: #dddddd !important;
}
.PasekWyboru {
  background: #606060;
}
.tabela_PracKOMPETEN .ikony {
  margin-right: 4px;
  margin-left: 4px;
  border: 1px solid !important;
  border-radius: 5px;
  padding: 5px;
}
.tabela_PracKOMPETEN .ikony:hover {
  background-color: black;
  text-decoration: none;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.podpowiedz {
  font-size: 1.5vmin !important;
  font-weight: bold;
  background-color: black !important;
}
.btn {
  background-color: #606060 !important;
  padding: 0rem 0rem !important;
  border-color: #606060 !important;
}
</style>
