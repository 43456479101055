<template >
  <div>
  <TabelaDzialaniaPozaAuditOcena />
  </div>
</template>

<script>
import TabelaDzialaniaPozaAuditOcena from '../../komponenty/strona-tabela-komponenty/SYS_dzialania_poza_audit/tabela_dzialania_poza_audit_ocena.vue'


export default {
  name: 'StronaTabelaDzialaniaPozaAudit',
  components: {
    TabelaDzialaniaPozaAuditOcena,
    
  }

}
</script>

