<template >
  <div>
  <TabelaSprawozdawczosc />
  </div>
</template>

<script>
import TabelaSprawozdawczosc from '../../komponenty/strona-tabela-komponenty/SYS_sprawozdania_srodowiskowe/tabela_sprawozdania.vue'


export default {
  name: 'StronaSprawozdawczosc',
  components: {
    TabelaSprawozdawczosc,
    
  }

}
</script>

