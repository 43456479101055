<template >
  <div>
  <TabelaRaporty/>
  </div>
</template>

<script>
import TabelaRaporty from '../../komponenty/strona-tabela-komponenty/SO_realizacja/tabela_wykaz_raportow.vue'


export default {
  name: 'StronaTabelaRaporty',
  components: {
    TabelaRaporty,
    
  }

}
</script>

