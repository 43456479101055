<template >
  <div>
  <TabelaWykazKontroliWewnetrznych />
  </div>
</template>

<script>
import TabelaWykazKontroliWewnetrznych from '../../komponenty/strona-tabela-komponenty/BHP_kontrole_wewnetrzne/tabela_wykaz_kontroli.vue'


export default {
  name: 'StronaTabelaWykazKontroliWewnetrznych',
  components: {
    TabelaWykazKontroliWewnetrznych,
    
  }

}
</script>

