<template>
  <div>
  <TabelaSamochodyOsoboweUbezpieczenia />
  </div>
</template>

<script>
import TabelaSamochodyOsoboweUbezpieczenia from '../../komponenty/strona-tabela-komponenty/WST_samochody_osobowe/tabela_samochody_osobowe_ubezpieczenia.vue'


export default {
  name: 'StronaTabelaSamochodyOsoboweUbezpieczenia',
  components: {
    TabelaSamochodyOsoboweUbezpieczenia,
  }

}
</script>
