<template>
  <div>
  <TabelaITUbezpieczenia />
  </div>
</template>

<script>
import TabelaITUbezpieczenia from '../../komponenty/strona-tabela-komponenty/WST_wyposazenie_IT/tabela_iT_ubezpieczenia.vue'


export default {
  name: 'StronaTabelaITUbezpieczenia',
  components: {
    TabelaITUbezpieczenia,
  }

}
</script>
