<template>
  <div>
  <TabelaElektronarzedziaGeneratorPrzeglad/>
  </div>
</template>

<script>
import TabelaElektronarzedziaGeneratorPrzeglad from '../../komponenty/strona-tabela-komponenty/WST_elektronarzedzia/protokol_przeglad.vue'


export default {
  name: 'StronaTabelaElektronarzedziaGeneratorPrzeglad',
  components: {
    TabelaElektronarzedziaGeneratorPrzeglad,
  }

}
</script>
