<template>
  <div>
  <tabelaWykazZespolBHP/>

  </div>
</template>

<script>
import tabelaWykazZespolBHP from '../../komponenty/strona-tabela-komponenty/BHP_ocena_stanu_BHP/tabela_zespol_bhp.vue'


export default {
  name: 'StronaTabelaWykazZespolBHP',
  components: {
       tabelaWykazZespolBHP,

  }

}
</script>
